import api from "../axios_service.js";

export async function fetchEmpresa(id) {
  const response = await api.get(`/empresas/${id}`);
  return response.data.result;
}

export function putEmpresa(id, empresa) {
  return api.post(`/empresas/${id}`, empresa);
}

export function authDesconto(auth) {
  return api.post("/empresas-authdesconto", auth);
}

export async function fetchEmpresas(filtros) {
  const response = await api.get(`/empresas${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchEmpresaAdmin(id) {
  const response = await api.get(`/empresas-admin/${id}`);
  return response.data.result;
}

export function putEmpresaAdmin(id, empresa) {
  return api.post(`/empresas-admin/${id}`, empresa);
}

export function postEmpresa(empresa) {
  return api.post("/empresas/add", empresa);
}
