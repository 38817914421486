<template>
  <v-container fluid>
    <template v-if="!loading">
      <v-card class="mt-4 pb-10">
        <v-tabs class="" v-model="tab">
          <v-tab class="caption font-weight-bold">
            {{ $tc("global.informacoes") }}
          </v-tab>
          <v-tab class="caption font-weight-bold">
            {{ $tc("global.moeda", 2) }}
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item class="pt-6">
            <v-card-title class="subtitle-1">
              {{ $tc("global.empresa") }}
              <v-spacer></v-spacer>
              <v-btn
                @click="updateEmpresa"
                :disabled="!validForm"
                color="button_1"
                class="white--text"
              >
                <v-icon left>mdi-check</v-icon>
                {{ $tc("global.salvar") }}
              </v-btn>
            </v-card-title>
            <v-container fluid class="py-0">
              <v-form
                @submit="false"
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" md="2" class="pt-8 mb-6">
                    <v-card
                      outlined
                      class="
                        fill-height
                        d-flex
                        align-center
                        justify-center
                        pa-3
                      "
                      style="position: relative"
                    >
                      <v-card flat>
                        <v-img v-if="logo" :src="logo" contain></v-img>
                        <v-img
                          v-else
                          :src="require('@/assets/pagina-default.png')"
                          contain
                        ></v-img>
                        <v-btn
                          fab
                          dark
                          x-small
                          absolute
                          bottom
                          right
                          @click="onButtonClick"
                        >
                          <input
                            accept="image/*"
                            type="file"
                            class="d-none"
                            ref="uploader"
                            @change="onFileChanged"
                          />
                          <v-icon>mdi-image</v-icon>
                        </v-btn>
                      </v-card>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="10">
                    <!-- Dados Gerais -->
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.lazy="empresa.nome"
                            :label="$tc('global.nome')"
                            outlined
                            dense
                            :rules="formRules"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.lazy="empresa.telefone"
                            :label="$tc('global.telefone')"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model.lazy="empresa.endereco"
                            :label="$tc('global.endereco')"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.lazy="empresa.documento"
                            label="RUC"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="5">
                          <v-text-field
                            v-model.lazy="empresa.email"
                            :label="$tc('global.email')"
                            :rules="empresa.email ? emailRules : undefined"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.lazy="empresa.responsavel"
                            label="Responsável"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model.lazy="empresa.cidade"
                            label="Cidade"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model.lazy="empresa.estado"
                            label="Estado"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model.lazy="empresa.pais"
                            label="País"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="11">
                          <!-- <v-text-field
                            v-model.lazy="empresa.descricao"
                            label="Descrição da Empresa"
                            outlined
                            dense
                          ></v-text-field> -->
                          <v-textarea
                            v-model.lazy="empresa.descricao"
                            label="Descrição da Empresa"
                            outlined
                            dense
                            :rules="[rules.max]"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <MoedasEmpresa />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { fetchEmpresa, putEmpresa } from "@/api/empresas/empresas.js";
import { mapState } from "vuex";

export default {
  name: "Empresa",

  components: {
    MoedasEmpresa: () => import("./components/MoedasEmpresa.vue"),
  },

  data() {
    return {
      tab: 0,
      empresa: {},
      formRules: [inputRequired],
      emailRules: [validaEmail],
      validForm: true,
      loading: false,
      empresa_original: {},
      logo: null,
      logoUpload: null,
      rules: {
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        max: (v) => (v && v.length <= 250) || "Max 250 characters",
      },
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      empresa_ativa: (state) => state.usuario.empresa_ativa,
    }),
    empresa_id() {
      return this.$route.params.empresa_id;
    },
  },

  watch: {},

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.logoUpload = file;
      this.empresa.logo = !this.empresa.logo ? "logo" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },

    updateEmpresa() {
      if (
        JSON.stringify(this.empresa) === JSON.stringify(this.empresa_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const empresa = new FormData();

        if (this.logoUpload) {
          empresa.append("logo", this.logoUpload, this.logoUpload.name);
          empresa.append("logo64", this.logo);
        }

        let empresa_2 = {};

        empresa_2.nome = this.empresa.nome;
        empresa_2.endereco = this.empresa.endereco;
        empresa_2.telefone = this.empresa.telefone;
        empresa_2.documento = this.empresa.documento;
        empresa_2.email = this.empresa.email;
        empresa_2.responsavel = this.empresa.responsavel;
        empresa_2.cidade = this.empresa.cidade;
        empresa_2.estado = this.empresa.estado;
        empresa_2.pais = this.empresa.pais;
        empresa_2.descricao = this.empresa.descricao;

        for (let key in empresa_2) {
          if (
            empresa_2[key] !== null &&
            empresa_2[key] !== undefined &&
            empresa_2[key] !== ""
          ) {
            empresa.append(key, empresa_2[key]);
          }
        }

        putEmpresa(this.empresa_ativa, empresa)
          .then(() => {
            this.empresa_original = { ...this.empresa };
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getEmpresa() {
      this.loading = true;

      return fetchEmpresa(this.empresa_ativa)
        .then((response) => {
          this.empresa = response;
          this.empresa_original = { ...response };
          this.logo = this.empresa.logo;

          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.empresa.nome
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.empresa.nome);
          }
        })
        .catch((error) => {
          // console.error(error);
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.loading = true;
    await this.getEmpresa();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
