import api from "../axios_service.js";

export async function fetchContasReceber(filtros) {
  const response = await api.get(
    `/cliente-contas-receber${filtros ? filtros : ""}`
  );
  return response.data.result;
}

// Busca uma conta receber
export async function fetchContaReceber(id) {
  const response = await api.get(`/cliente-contas-receber/${id}`);
  return response.data.result;
}

export async function contasReceberCliente(id, filtros) {
  const response = await api.get(
    `/contas-receber-cliente/${id}${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function postContaReceber(body) {
  return api.post("/cliente-contas-receber/add", body);
}

export async function putContaReceber(id, body) {
  return api.put(`/cliente-contas-receber/${id}`, body);
}

export async function checkContasClientes(id, body) {
  return api.post(`/check-contas-clientes/${id}`, body);
}

export async function fetchContasReceberDia(filtros) {
  const response = await api.get(
    `/contas-receber-dia${filtros ? filtros : ""}`
  );
  return response.data.result;
}
