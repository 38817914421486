<template>
  <v-card :loading="loading" height="100%">
    <v-card-title class="tocs_gray_1 ">
      <v-icon class="mr-2">mdi-tag-outline</v-icon>
      {{ $tc("global.aReceber") }}
      <v-spacer></v-spacer>
      {{ $tc("global.totalreceber") }}: &nbsp;
      <span v-if="moeda.sigla === 'G$'">
        {{ moeda.sigla }}{{ totalGeral | guarani }}
      </span>
      <span v-else> {{ moeda.sigla }}{{ totalGeral | currency }} </span>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="vendas"
        :items-per-page="5"
        :sort-by="['data_vencimento']"
        class="data-tables data-tables__row-click"
        :hide-default-footer="vendas.length > 5 ? false : true"
      >
        <template v-slot:item.data_vencimento="{ item }">
          {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
        </template>

        <template v-slot:item.data="{ item }">
          {{ item.data | dateFormat("dd/MM/yyyy") }}
        </template>

        <template v-slot:item.total="{ item }">
          <div v-if="item.moeda_sigla === 'G$'">
            {{ item.moeda_sigla }} {{ item.total | guarani }}
          </div>
          <div v-else>{{ item.moeda_sigla }} {{ item.total | currency }}</div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getVendas } from "@/api/vendas/vendas.js";
import { mapState } from "vuex";

export default {
  name: "BoxVendasReceber",

  data() {
    return {
      loading: false,
      vendas: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.vencimento"),
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.data"),
          value: "data",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.numero"),
          value: "numero",
        },
        {
          text: "total",
          align: "right",
          value: "total",
        },
      ];
    },

    totalGeral() {
      let result = 0;
      if (this.vendas.length) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + Number(currentValue.total);
        };
        result = this.vendas.reduce(reducer, 0);
      }
      return result;
    },
    ...mapState("Moedas", {
      moeda: (state) => state.moedas[0],
    }),
  },

  methods: {},

  async mounted() {
    this.loading = true;
    this.vendas = await getVendas(`?status_filter=1&condicao_pagamento=2`);
    this.loading = false;
  },
};
</script>

<style></style>
