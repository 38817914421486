<template>
  <v-dialog
    v-model="dialogProdutosFaltando"
    @click:outside="$emit('update:dialogProdutosFaltando', false)"
    @keydown.esc="$emit('update:dialogProdutosFaltando', false)"
    width="700px"
  >
    <v-card class="">
      <v-card-title class="d-flex">
        {{ $tc("global.produtoSemEstoque") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogProdutosFaltando'), false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-2">
        <v-data-table
          :headers="venda_items_headers"
          :items="produtos"
          :items-per-page="-1"
          disable-sort
          fixed-header
          hide-default-footer
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogProdutosFaltando",
  props: {
    dialogProdutosFaltando: {
      type: Boolean,
      default: false,
    },
    produtos: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    venda_items_headers() {
      let headers = [
        {
          text: "id",
          divider: true,
          value: "produto_id",
        },
        {
          text: this.$tc("global.produto"),
          divider: true,
          value: "descricao",
        },
        {
          text: "Qtde",
          divider: true,
          width: "150px",
          value: "qtde",
          align: "center",
        },
        {
          text: this.$tc("global.disponivel"),
          divider: true,
          width: "150px",
          value: "saldo_disponivel",
          align: "center",
        },
      ];

      return headers;
    },
  },
};
</script>

<style></style>
