import { all, cambioDia, update, add, del } from "@/api/cambio/cambios.js";

const initialState = () => ({
  cambios: null,
  cambio: null,
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  async getAll({ commit }) {
    return new Promise((resolve, reject) => {
      all()
        .then((response) => {
          commit("UPDATE_CAMBIOS", response);
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  },
  getCambioDia({ commit }) {
    return new Promise((resolve, reject) => {
      cambioDia()
        .then((response) => {
          commit("UPDATE_CAMBIO", response);
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  },
  postCambio(context, cambio) {
    return new Promise((resolve, reject) => {
      add(cambio)
        .then((response) => {
          if (response.status === 201) {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  },
  updateCambio(context, { id, cambio }) {
    return new Promise((resolve, reject) => {
      update(id, cambio)
        .then((response) => {
          if (response.status === 201) {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  },
  delCambio(context, id) {
    return new Promise((resolve, reject) => {
      del(id)
        .then((response) => {
          if (response.status === 200) {
            resolve();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  },
  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  UPDATE_CAMBIOS(state, payload) {
    state.cambios = payload;
  },
  UPDATE_CAMBIO(state, payload) {
    state.cambio = payload;
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
