<template>
  <v-dialog
    v-model="dialogBaixarGiros"
    @click:outside="$emit('update:dialogBaixarGiros', false)"
    @keydown.esc="$emit('update:dialogBaixarGiros', false)"
    max-width="850px"
    scrollable
  >
    <v-card flat>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.baixar") }} GIROS
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogBaixarGiros', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card flat>
        <v-card-text class="pa-6">
          <v-card
            width="100%"
            outlined
            class="d-flex justify-space-between align-center pa-4"
          >
            <div>
              <span class="text-h6 font-weight-bold">
                {{ $tc("global.cliente") }}:
              </span>
              <span class="subtitle-1 ml-2">{{ giro.cliente_nome }}</span>
            </div>

<div>
              <span class="text-h6 font-weight-bold">
                {{ $tc("global.telefone") }}:
              </span>
              <span class="subtitle-1 ml-2">{{ giro.telefone }}</span>
            </div>


            <div>
              <span class="subtitle-1">
                <span class="text-h6 font-weight-bold"
                  >{{ $tc("global.tipo") }}:
                </span>
                {{ giro.tipo }}
              </span>
            </div>
            <div>
              <span class="subtitle-1">
                <span class="text-h6 font-weight-bold"
                  >{{ $tc("global.valor") }}:
                </span>
                <span v-if="giro.moeda_sigla === 'G$'">
                  {{ giro.moeda_sigla }} {{ giro.valor | guarani }}
                </span>
                <span v-else>
                  {{ giro.moeda_sigla }} {{ giro.valor | currency }}
                </span>
              </span>
            </div>
          </v-card>

          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense class="pt-6">
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectCaixa"
                  :items="caixas"
                  item-text="descricao"
                  item-value="id"
                  :label="$tc('global.caixa')"
                  :rules="formRules"
                  dense
                  outlined
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="valorTaxa"
                  ref="valorTaxa"
                  :label="$tc('global.valor') + ' ' + $tc('global.taxa')"
                  :rules="formRules"
                  dense
                  outlined
                  v-currency="vCurrencyOptions"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="baixarGiro"
          :loading="loading"
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
        >
          {{ $tc("global.baixar") }}

          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchCaixas } from "@/api/caixas/caixas.js";
import { inputRequired } from "@/helpers/utils.js";
import { mapState } from "vuex";
import { baixarGiro } from "@/api/giros/giros.js";

export default {
  name: "DialogBaixarGiros",

  components: {
    // SearchClientes: () => import("@/components/fields/SearchClientes.vue"),
  },

  props: {
    dialogBaixarGiros: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      giro: {},
      valor: 0,
      validForm: true,
      loading: true,
      formRules: [inputRequired],
      caixas: [],
      valorTaxa: null,
      taxa: 0,
      selectCaixa: null,
      caixa_id: null,
      selectedMoeda: null,
      selectedCliente: null,
      selectedTipo: null,
      tipos: [
        { nome: "GIROS TIGO" },
        { nome: "BILHETERIA PERSONAL" },
        { nome: "GIROS CLARO" },
        { nome: "VISIÓN GIROS" },
      ],
    };
  },

  watch: {
    valorTaxa() {
      if (this.valorTaxa) {
        this.taxa = this.$ci.parse(this.valorTaxa, this.vCurrencyOptions);
      }
    },
    selectCaixa() {
      if (this.selectCaixa) {
        this.caixa_id = this.selectCaixa;
      }
    },
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision:
          this.selectedMoeda && this.selectedMoeda.sigla == "G$"
            ? 0
            : undefined,

        distractionFree: false,
      };
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  methods: {
    baixarGiro() {
      this.validForm = this.$refs.form.validate();
      if (this.validForm) {
        let request = {
          caixa_id: this.caixa_id,
          taxa: this.taxa,
        };
        baixarGiro(this.giro.id, request)
          .then(() => {
            this.$emit("update:dialogBaixarGiros", false);
            this.$emit("fetch-giros");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getCaixas() {
      let filtro = "?exclude_tipo_caixa=PDV";

      fetchCaixas(filtro)
        .then((response) => {
          this.caixas = response.map((item) => {
            if ((item.moeda_id == 2) | (item.id == 1)) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }

            return item;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  async mounted() {
    this.getCaixas();
    this.selectedMoeda = this.moedas.find((moeda) => moeda.sigla == "G$");
    this.giro = { ...this.item };

    this.selectedTipo = this.tipos.find((tipo) => tipo.nome == this.giro.tipo);

    this.loading = false;
  },
};
</script>

<style></style>
