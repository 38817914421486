<template>
  <v-card :loading="loading" outlined>
    <v-card-title class="text-body-1 lightgray">
      {{ $tc("global.servico", 2).toUpperCase() }}
      <v-spacer></v-spacer>
      <v-btn
        small
        color="button_1"
        class="white--text"
        depressed
        @click="openDialogAddServico()"
      >
        <v-icon left>mdi-plus</v-icon>{{ $tc("global.add") }}
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="fornecedorServicos"
      :items-per-page="-1"
      :sort-desc="true"
      hide-default-footer
      class="data-tables data-tables__row-click"
      @click:row="openDialogEditServico"
    >
      <template v-slot:item.valor="{ item }">
        <div v-if="item.moeda_sigla == 'G$'">
          {{ item.valor | guarani }}
        </div>
        <div v-else>
          {{ item.valor | currency }}
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-if="dialogAddEditServico"
      v-model="dialogAddEditServico"
      @click:outside="closeDialogAddEdit()"
      @keydown.esc="closeDialogAddEdit()"
      width="700"
    >
      <v-card :loading="loadingServico">
        <v-card-title class="tocs_gray_1 px-4 py-3">
          {{ $tc("global.servico").toUpperCase() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialogAddEdit()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            @submit.prevent
            class="d-flex"
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row class="pt-6">
              <!-- Busca Produto -->
              <v-col cols="12">
                <SearchProdutos
                  v-if="!loadingServico"
                  :produto_sync.sync="selectedProduto"
                  @clear-add-item="clearSearchProduto"
                  ref="buscaProduto"
                  tipo="piscina"
                  typeComp="fornecedor_servico"
                />
              </v-col>

              <!-- Valor -->
              <v-col cols="12" md="12">
                <v-combobox
                  v-model="selectedMoeda"
                  :label="$tc('global.moeda', 2)"
                  :items="moedas"
                  dense
                  outlined
                  required
                  :rules="formRules"
                  item-text="sigla"
                  item-value="id"
                ></v-combobox>
                <v-text-field
                  ref="servicoValor"
                  v-model="servicoValor"
                  :label="$tc('global.valor')"
                  dense
                  required
                  :rules="formRules"
                  :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                  outlined
                  height="40px"
                  :disabled="!selectedMoeda"
                  style="display: flex"
                  class="remove-underline input-font-size input-right"
                  v-currency="vCurrencyOptions"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="edit ? editFornecedorServico() : addFornecedorServico()"
            :loading="loadingServico"
            :disabled="!validForm"
            class="white--text"
            color="button_1"
            depressed
          >
            <div v-if="edit">
              <v-icon left>mdi-check</v-icon>
              {{ $tc("global.salvar") }}
            </div>
            <div v-else>
              <v-icon left>mdi-plus</v-icon>{{ $tc("global.add") }}
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  getFornecedorServicos,
  getFornecedorServico,
  putFornecedorServico,
  postFornecedorServico,
} from "@/api/fornecedores/fornecedor_servicos.js";
import { inputRequired } from "@/helpers/utils.js";
import { mapState } from "vuex";

export default {
  name: "ListaFornecedorServicos",

  components: {
    SearchProdutos: () => import("@/components/fields/SearchProdutos.vue"),
  },

  props: {
    fornecedor_id: {
      type: [String, Number],
      required: true,
    },
    moeda_fornecedor: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      loadingServico: false,
      validForm: true,
      formRules: [inputRequired],
      dialogAddEditServico: false,
      edit: false,
      fornecedorServicos: [],
      selectedProduto: null,
      servicoValor: null,
      servico_id: null,
      selectedMoeda: null,
    };
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    headers() {
      return [
        {
          text: this.$tc("global.produto"),
          value: "produto_descricao",
          divider: true,
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          align: "center",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
          sortable: false,
        },
      ];
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  methods: {
    clearSearchProduto() {
      this.selectedProduto = null;
    },

    closeDialogAddEdit() {
      this.edit = false;
      this.selectedProduto = null;
      this.servicoValor = null;
      this.servico_id = null;
      this.dialogAddEditServico = false;
    },

    openDialogAddServico() {
      this.edit = false;
      this.selectedMoeda = this.moedas.find(
        (moeda) => moeda.id_moeda === this.moeda_fornecedor.id_moeda
      );
      this.dialogAddEditServico = true;
    },

    async openDialogEditServico(item) {
      this.loadingServico = true;
      this.edit = true;
      this.dialogAddEditServico = true;
      await getFornecedorServico(item.id).then((response) => {
        this.servico_id = response.id;
        this.selectedProduto = { id: response.produto_id };
        this.$ci.setValue(this.$refs.servicoValor, response.valor);
        this.selectedMoeda = this.moedas.find(
          (moeda) => moeda.id_moeda === response.moeda_id
        );
      });

      this.loadingServico = false;
    },

    addFornecedorServico() {
      if (this.$refs.form.validate()) {
        if (!this.selectedProduto) {
          this.$toast.error(this.$tc("global.selecioneProduto"));
          return;
        }
        this.loadingServico = true;
        let servico = {};
        servico.fornecedor_id = this.fornecedor_id;
        servico.moeda_id = this.selectedMoeda.id_moeda;
        servico.valor = this.$ci.parse(
          this.servicoValor,
          this.vCurrencyOptions
        );
        servico.produto_id = this.selectedProduto.id;

        postFornecedorServico(servico, this.servico_id).then((response) => {
          if (response.status === 201) {
            this.selectedProduto = null;
            this.servicoValor = 0;
            this.edit = false;
            this.servico_id = null;
            this.loadingServico = false;
            this.dialogAddEditServico = false;
            this.fetchFornecedorServicos();
          }
        });
      }
    },

    editFornecedorServico() {
      if (this.$refs.form.validate()) {
        this.loadingServico = true;
        let servico = {};
        servico.fornecedor_id = this.fornecedor_id;
        servico.valor = this.$ci.parse(
          this.servicoValor,
          this.vCurrencyOptions
        );
        if (this.selectedProduto) {
          servico.produto_id = this.selectedProduto.id;
        }

        putFornecedorServico(this.servico_id, servico).then((response) => {
          if (response.status === 200) {
            this.selectedProduto = null;
            this.servicoValor = 0;
            this.edit = false;
            this.loadingServico = false;
            this.dialogAddEditServico = false;
            this.fetchFornecedorServicos();
          }
        });
      }
    },

    fetchFornecedorServicos() {
      this.loading = true;
      return getFornecedorServicos(this.fornecedor_id)
        .then((response) => {
          this.fornecedorServicos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.fetchFornecedorServicos();
  },
};
</script>

<style></style>
