<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.antecipo')" icon="mdi-cash-plus" dark />
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>

        <v-btn-toggle mandatory v-model="tipo_antecipo" class="ml-3">
          <v-btn value="cliente" class="text-caption" height="40px">
            {{ $tc("global.cliente") }}
          </v-btn>
          <v-btn value="fornecedor" class="text-caption" height="40px">
            {{ $tc("global.fornecedor") }}
          </v-btn>
        </v-btn-toggle>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          v-if="AntecipoAdicionar"
          @click="addAntecipo"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="antecipos"
          :items-per-page="15"
          class="data-tables"
          :class="{ 'data-tables__row-click': AnteciposVisualizar }"
          disable-sort
          @click:row="goToAntecipo"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.valor="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.valor | guarani }}
            </div>
            <div v-else>
              {{ item.valor | currency }}
            </div>
          </template>
          <template v-slot:item.valor_utilizado="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.valor_utilizado | guarani }}
            </div>
            <div v-else>
              {{ item.valor_utilizado | currency }}
            </div>
          </template>
          <template v-slot:item.saldo="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.saldo | guarani }}
            </div>
            <div v-else>
              {{ item.saldo | currency }}
            </div>
          </template>
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <div
              v-if="item.recibo_id"
              class="d-flex align-center justify-center"
            >
              <v-btn
                @click.stop="openReciboAntecipoPdf(item.recibo_id)"
                icon
                class="mr-2"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogAddEditAntecipo
      v-if="dialogAddEditAntecipo"
      :dialogAddEditAntecipo.sync="dialogAddEditAntecipo"
      :edit="edit"
      :item="antecipo"
      @fetch-antecipos="getAtencipos"
    />
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="RECIBO"
    />
  </v-container>
</template>

<script>
import { fetchAntecipos } from "@/api/antecipos";
import { mapGetters } from "vuex";
const generateReciboCredito = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboAntecipoPdf.js"
  );
export default {
  name: "Antecipos",

  components: {
    DialogAddEditAntecipo: () =>
      import("./components/DialogAddEditAntecipo.vue"),

    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      search: "",
      loading: false,
      antecipos: [],
      antecipo: {},
      edit: false,
      dialogAddEditAntecipo: false,
      tipo_antecipo: "cliente",
      pdfDialog: false,
      pdfData: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    AnteciposVisualizar() {
      return this.getAccess("Antecipos", "visualizar");
    },
    AntecipoAdicionar() {
      return this.getAccess("Antecipos", "adicionar");
    },

    headers() {
      return [
        {
          text: "id",
          value: "id",
          width: "70px",
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
          width: "100px",
        },
        {
          text:
            this.tipo_antecipo == "cliente"
              ? this.$tc("global.cliente")
              : this.$tc("global.fornecedor"),
          value: this.tipo_antecipo == "cliente" ? "cliente" : "fornecedor",
        },
        {
          text: this.$tc("global.formapagamento"),
          value: "forma_pagamento",
          width: "180px",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda",
          align: "right",
          width: "100px",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
          width: "100px",
        },
        {
          text: this.$tc("global.valorutilizado"),
          value: "valor_utilizado",
          align: "right",
          width: "150px",
        },
        {
          text: this.$tc("global.saldo"),
          value: "saldo",
          align: "right",
          width: "100px",
        },
        {
          text: "",
          value: "actions",
          width: "75px",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    tipo_antecipo() {
      this.getAtencipos();
    },
  },

  methods: {
    goToAntecipo(item) {
      if (this.AnteciposVisualizar) {
        this.antecipo = { ...item };
        this.dialogAddEditAntecipo = true;
        this.edit = true;
      }
    },

    addAntecipo() {
      this.edit = false;
      this.dialogAddEditAntecipo = true;
    },

    async getAtencipos() {
      this.loading = true;
      this.antecipos = await fetchAntecipos(
        `?tipo_antecipo=${this.tipo_antecipo}`
      );
      this.loading = false;
    },

    async openReciboAntecipoPdf(recibo_id) {
      this.loading = true;
      await generateReciboCredito().then((module) => {
        let generate = module.generateRecibo;
        generate(recibo_id.id)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },
  },

  mounted() {
    this.getAtencipos();
  },
};
</script>

<style></style>
