<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.empresa', 2)" icon="mdi-domain" dark />

    <v-card>
      <v-card-title class="pa-3 subtitle-1 tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          :to="{ path: '/super-admin/empresas/adicionar' }"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="empresas"
          :items-per-page="15"
          :search="search.length >= 3 ? search : ''"
          class="data-tables data-tables__row-click"
          @click:row="goToEmpresa"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
          </template>
          <template v-slot:item.status="{ item }">
            <span size="28" v-if="item.status === 0">inativo</span>
            <span v-if="item.status === 1">
              {{ $tc("global.ativo") }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchEmpresas } from "@/api/empresas/empresas.js";

export default {
  name: "SA_Empresas",

  data() {
    return {
      search: "",
      loading: false,
      empresas: [],
    };
  },

  computed: {
    headers() {
      let headers = [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$tc("global.empresa"),
          value: "nome",
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
        {
          text: "status",
          value: "status",
        },
      ];
      return headers;
    },
  },

  methods: {
    goToEmpresa(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.name);
      this.$router.push({ path: `/super-admin/empresa/${item.id}` });
    },
    getEmpresas() {
      this.loading = true;

      fetchEmpresas()
        .then((response) => {
          this.empresas = response;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getEmpresas();
  },
};
</script>

<style></style>
