<template>
  <v-card class="mx-4 mb-3" outlined>
    <v-card-title class="tocs_gray_1 px-3 py-2">
      <v-text-field
        style="max-width: 250px"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$tc('global.buscar')"
        hide-details
        outlined
        solo
        flat
        dense
      ></v-text-field>

      <v-spacer></v-spacer>
      <span class="pr-8">Total: G$ {{ totalValor | guarani }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <v-data-table
        v-if="!loading"
        v-model="contasSelected"
        :headers="headers"
        :search="search.length >= 3 ? search : ''"
        :items="contas"
        item-key="id"
        sort-by="id"
        :show-select="true"
        :items-per-page="5"
        class="data-tables"
        @item-selected="contaSelected"
      >
        <template v-slot:item.data_vencimento="{ item }">
          <div class="d-flex align-center">
            <div v-if="item.data_vencimento">
              {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
            </div>
          </div>
        </template>

        <template v-slot:item.moeda="{ item }">
          <div v-if="item.moeda">
            {{ item.moeda }}
          </div>
        </template>

        <template v-slot:item.valor="{ item }">
          <div v-if="item.moeda == 'G$' && item.valor">
            {{ item.valor | guarani }}
          </div>
          <div v-else-if="item.valor">
            {{ item.valor | currency }}
          </div>
        </template>
        <template v-slot:item.desconto="{ item }">
          <div v-if="item.moeda == 'G$' && item.desconto">
            {{ item.desconto | guarani }}
          </div>
          <div v-else-if="item.desconto">
            {{ item.desconto | currency }}
          </div>
        </template>
        <template v-slot:item.juros="{ item }">
          <div v-if="item.moeda == 'G$' && item.juros">
            {{ item.juros | guarani }}
          </div>
          <div v-else-if="item.juros">
            {{ item.juros | currency }}
          </div>
        </template>
        <template v-slot:item.saldo="{ item }">
          <div v-if="item.moeda == 'G$' && item.saldo">
            {{ item.saldo | guarani }}
          </div>
          <div v-else-if="item.saldo">
            {{ item.saldo | currency }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { contasReceberCliente } from "@/api/contas-receber/contas_receber.js";
export default {
  name: "SelectContasGiros",

  props: {
    selectedCliente: {
      type: Object,
      required: true,
    },
    totalValor: {
      type: Number,
    },
    setContas: {
      type: Array,
    },
  },

  data() {
    return {
      loading: false,
      search: "",
      contasSelectedCliente: [],
      contasSelected: [],
      cliente: null,
      contas: [],
      soma: 0,

      //   formRules: [inputRequired],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          width: "40px",
          value: "id",
        },
        {
          text: this.$tc("global.vencimento"),
          width: "90px",
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.descricao"),
          width: "150px",
          value: "descricao",
        },

        {
          text: this.$tc("global.moeda"),
          width: "80px",
          align: "center",
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.valor"),
          width: "100px",
          align: "right",
          value: "valor",
        },

        {
          text: this.$tc("global.desconto"),
          width: "100px",
          align: "right",
          value: "desconto",
        },
        {
          text: this.$tc("global.juros"),
          width: "100px",
          align: "right",
          value: "juros",
        },
        {
          text: this.$tc("global.saldo"),
          width: "100px",
          align: "right",
          value: "saldo",
        },
      ];
    },
  },

  watch: {
    contasSelected() {
      if (this.contasSelected) {
        this.setContaSelected();
      }
    },
  },

  methods: {
    async getContas() {
      this.loading = true;
      await contasReceberCliente(this.cliente.id, `?moeda_id=2`)
        .then((response) => {
          this.contas = response;
          this.contasSelected = [];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    setContasSelecionadasCliente() {
      let contas_existem = null;
      contas_existem = this.contasSelectedCliente.some((conta_cliente) => {
        return conta_cliente.cliente_id === this.cliente.id;
      });

      if (contas_existem) {
        let index = null;

        index = this.contasSelectedCliente.findIndex((conta_cliente) => {
          return conta_cliente.cliente_id === this.cliente.id;
        });

        if (this.contas.length) {
          this.contasSelectedCliente[index].contas.forEach((conta) => {
            return this.contasSelected.push(conta);
          });
        }
      }
    },

    setContaSelected() {
      let contas = [];
      contas = this.contasSelected;

      this.$emit("update:setContas", contas);
    },

    contaSelected({ item, value }) {
      if (value) {
        let result_moeda = false;
        let result_valor = false;

        this.contasSelected.some((conta, index) => {
          if (conta.moeda_sigla != item.moeda_sigla) {
            result_moeda = true;
          }
          if (conta.valor != item.valor) {
            result_valor = true;
          }
        });

        if (result_moeda) {
          this.$nextTick(() => {
            const index = this.contasSelected.findIndex(
              (conta) => conta.id === item.id
            );
            if (index !== -1) this.contasSelected.splice(index, 1);
            this.$toast.error(this.$tc("global.ContasDevemSerDoMesmoMoeda"));
          });
        }
      }
    },
  },

  mounted() {
    if (this.selectedCliente) {
      this.cliente = this.selectedCliente;
      this.getContas();
      this.setContasSelecionadasCliente();
    }
  },
};
</script>

<style></style>
