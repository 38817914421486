import api from "../axios_service";

export async function listaDeVendas(filtros) {
  const response = await api.get(
    `/relatorios/vendas${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function listaDeVendasPorMes(filtros) {
  const response = await api.get(
    `/relatorios/vendas-por-mes${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function listaDeVendasPorMesVendedor(filtros) {
  const response = await api.get(
    `/relatorios/vendas-por-mes-vendedor${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function listaDeVendasPrazoRecebidas(filtros) {
  const response = await api.get(
    `/relatorios/vendas-a-prazo-recebidas${filtros ? filtros : ""}`
  );
  return response.data.result;
}
