<template>
  <v-dialog
    v-model="dialogCaixasAfItem"
    @click:outside="$emit('update:dialogCaixasAfItem', false)"
    @keydown.esc="$emit('update:dialogCaixasAfItem', false)"
    scrollable
    max-width="1020px"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.add") + " " + $tc("global.lancamento") }}
        <v-spacer></v-spacer>
        <div class="pr-4">{{ $tc("global.possuifatura?") }}</div>
        <v-switch class="pr-6" v-model="possui_fatura"></v-switch>
        <v-btn icon @click="$emit('update:dialogCaixasAfItem', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-divider></v-divider>

      <v-card flat style="overflow: auto">
        <v-card-text
          :style="possui_fatura || hasRecibo ? 'height: 600px' : 'height: auto'"
          class="py-3"
        >
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense>
              <v-col class="pt-2 d-flex justify-center" cols="12">
                <div class="text-center"></div>
              </v-col>

              <!-- DOCUMENTO --->
              <v-col class="pt-5" cols="12" md="6">
                <v-text-field
                  v-model="caixa_af_item.documento"
                  :label="
                    possui_fatura
                      ? $tc('global.numero') + ' ' + $tc('global.fatura')
                      : $tc('global.documento')
                  "
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <!--- HISTORICO --->
              <v-col class="pt-5" cols="12" md="6">
                <v-text-field
                  v-model="caixa_af_item.historico"
                  :label="$tc('global.historico')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <!-- FORMA DE PAGAMENTO -->
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="selectedFormaPagamento"
                  :label="$tc('global.formapagamento')"
                  :items="formas_pagamentos"
                  dense
                  required
                  disabled
                  item-text="descricao"
                  item-value="id"
                  :rules="formRules"
                  outlined
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="6">
                <DialogSelectPlanoContas
                  v-if="dialogSelectPlanoContas"
                  :dialogSelectPlanoContas.sync="dialogSelectPlanoContas"
                  @select-plano-contas="selectPlanoConta"
                  filtro_tipo
                />

                <v-card
                  v-if="!selectedPlanoConta"
                  outlined
                  width="100%"
                  class="d-flex align-center justify-space-between"
                  height="40px"
                  @click="dialogSelectPlanoContas = true"
                >
                  <span
                    class="pl-4 text-subtitle-1 tocs_gray_3--text"
                    :class="
                      notPlanocontas && !contaSelected
                        ? 'red--text'
                        : 'lightgrey--text'
                    "
                  >
                    {{ $tc("global.planocontas") }}
                  </span>
                  <v-icon class="mr-2">mdi-plus</v-icon>
                </v-card>

                <div class="d-flex">
                  <v-combobox
                    v-if="selectedPlanoConta"
                    v-model="selectedPlanoConta"
                    :label="$tc('global.planocontas', 2)"
                    dense
                    outlined
                    item-text="descricao"
                    item-value="id"
                  ></v-combobox>

                  <v-btn
                    v-if="selectedPlanoConta"
                    text
                    fab
                    small
                    @click="dialogSelectPlanoContas = true"
                  >
                    <v-icon size="18">mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <!-- MOEDA -->
              <v-col v-if="!possui_fatura" cols="12" md="2">
                <v-combobox
                  v-model="selectedMoeda"
                  :label="$tc('global.moeda', 2)"
                  :items="moedas"
                  dense
                  outlined
                  required
                  :rules="formRules"
                  item-text="sigla"
                  item-value="id"
                ></v-combobox>
              </v-col>

              <!-- MOEDA INPUT --->
              <v-col v-show="!possui_fatura" cols="12" md="4">
                <v-text-field
                  ref="valorCaixaMov"
                  v-model="valorCaixaMov"
                  dense
                  required
                  :rules="formRules"
                  :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                  outlined
                  height="40px"
                  :disabled="!selectedMoeda"
                  style="display: flex"
                  class="remove-underline input-font-size input-right"
                  v-currency="vCurrencyOptions"
                ></v-text-field>
              </v-col>

              <v-col cols="12" :md="caixa_af_item.tipo === 'S' ? '3' : '6'">
                <v-select
                  return-object
                  v-model="selectedTipo"
                  :label="$tc('global.tipo') + ' ' + $tc('global.caixa')"
                  :items="tipo_transacao"
                  dense
                  required
                  :rules="formRules"
                  outlined
                ></v-select>
              </v-col>

              <v-col
                v-if="caixa_af_item.tipo === 'S'"
                class="justify-center"
                cols="12"
                md="3"
              >
                <v-text-field
                  dense
                  outlined
                  readonly
                  :placeholder="$tc('global.recibo')"
                >
                  <template v-slot:append>
                    <v-switch
                      v-model="hasRecibo"
                      :false-value="false"
                      :true-value="true"
                      class="mt-0 pt-0"
                      hide-details
                    >
                    </v-switch>
                  </template>
                </v-text-field>
              </v-col>

              <FaturaGastos
                v-if="possui_fatura"
                :fatura.sync="fatura"
                :selectedMoeda.sync="selectedMoeda"
                @set-valor-fatura="setValorFatura"
              />
              <v-card
                v-show="hasRecibo"
                width="100%"
                flat
                outlined
                class="mb-6"
              >
                <v-card-title class="text-body-2 py-2 font-weight-bold"
                  >{{ $tc("global.dados") }}
                  {{ $tc("global.recibo") }}</v-card-title
                >
                <v-divider></v-divider>

                <!-- DATA RECIBO -->
                <v-row dense d-flex class="pt-4 px-3 pb-2">
                  <v-col class="pb-5" cols="12" md="5">
                    <DataField
                      :data_sync.sync="recibo.data_recibo"
                      :label="$tc('global.data') + ' ' + $tc('global.recibo')"
                      hide-details
                      disabled
                    />
                  </v-col>

                  <!-- MOEDA -->
                  <v-col class="pb-5" cols="12" md="3">
                    <v-combobox
                      v-model="selectedMoedaRecibo"
                      :label="$tc('global.moeda', 2)"
                      :items="moedas"
                      dense
                      outlined
                      required
                      hide-details
                      disabled
                      item-text="sigla"
                      item-value="id"
                    ></v-combobox>
                  </v-col>

                  <!-- VALOR -->
                  <v-col class="pb-5" cols="12" md="4">
                    <v-text-field
                      ref="valorRecibo"
                      v-model="valorRecibo"
                      :label="$tc('global.valor')"
                      dense
                      outlined
                      hide-details
                      disabled
                      v-currency="vCurrencyOptions"
                    ></v-text-field>
                  </v-col>

                  <!-- DESCRICÃO -->
                  <v-col class="pb-5" cols="12">
                    <v-textarea
                      v-model="recibo.concepto_descricao"
                      :label="$tc('global.descricao')"
                      dense
                      rows="2"
                      hide-details
                      outlined
                    ></v-textarea>
                  </v-col>

                  <!-- QUEM ASSINA -->
                  <v-col class="pb-4" cols="12">
                    <v-text-field
                      v-model="recibo.quem_assina"
                      :label="$tc('global.quemassina')"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-col cols="12">
                <v-card flat class="mb-5">
                  <v-file-input
                    suffix="Max 100MB"
                    :messages="
                      $tc('global.formatosPermitidos') +
                      ' : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt.'
                    "
                    v-model="files"
                    :placeholder="$tc('global.anexos')"
                    accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
                    multiple
                    class="mb-2 mx-2"
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        small
                        label
                        close
                        close-icon="mdi-delete"
                        color="primary"
                        @click:close="remove(text)"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogCaixasAfItem', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="createCaixaMov"
        >
          {{ $tc("global.criar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils/";
import {
  fetchPlanosContasTree,
  fetchPlanosContas,
} from "@/api/plano-contas/plano_contas.js";
import { mapState } from "vuex";
import { postCaixaAfItem } from "@/api/caixas/caixas_af.js";
import { fetchFormasPagamentosAtivo } from "@/api/formas-pagamento/formas_pagamento.js";

export default {
  name: "dialogCaixasAfItem",

  props: {
    dialogCaixasAfItem: {
      type: Boolean,
      required: true,
    },

    caixa: {
      type: Object,
    },
  },

  data() {
    return {
      validForm: true,
      formRules: [inputRequired],
      loading: false,
      selectedFormaPagamento: null,
      selectedMoeda: null,
      selectedMoedaRecibo: null,
      selectedTipo: null,
      tipo_transacao: [
        {
          text: "Entrada",
          value: "E",
        },
        {
          text: "Saída",
          value: "S",
        },
      ],
      selectedPlanoConta: null,
      caixa_af_item: {
        isento: false,
      },
      possui_fatura: false,
      planocontastree: [],
      planocontas: [],
      dialogSelectPlanoContas: false,
      valorCaixaMov: 0,
      valorRecibo: null,
      notPlanocontas: false,
      files: [],
      hasRecibo: false,
      recibo: {},
      formas_pagamentos: [],
      fatura: {},
      valor_fatura: 0,
    };
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    FaturaGastos: () => import("@/components/global/FaturaGastos.vue"),
    DialogSelectPlanoContas: () =>
      import(
        "@/views/configuracoes/caixas/components/DialogSelectPlanoContas.vue"
      ),
  },

  computed: {
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    hasRecibo() {
      if (this.hasRecibo) {
        this.recibo.data_recibo = new Date();
        this.selectedMoedaRecibo = this.selectedMoeda;
        if (this.possui_fatura && this.valor_fatura > 0) {
          this.$ci.setValue(this.$refs.valorCaixaMov, this.valor_fatura);
        }
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.caixa_af_item.moeda_id = this.selectedMoeda.id_moeda;
        if (this.hasRecibo) {
          this.selectedMoedaRecibo = this.selectedMoeda;
        }
      }
    },
    selectedMoedaRecibo() {
      if (this.selectedMoedaRecibo) {
        this.recibo.moeda_id = this.selectedMoeda.id_moeda;
      }
      if (this.possui_fatura && this.fatura && this.fatura.moeda_id) {
        this.selectedMoedaRecibo = this.moedas.find((moeda) => {
          return moeda.id_moeda === this.fatura.moeda_id;
        });
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.caixa_af_item.tipo = this.selectedTipo.value;
        if (this.selectedTipo.value == "E") {
          this.hasRecibo = false;
        }
      }
    },

    valorRecibo() {
      this.recibo.valor_recibo = this.$ci.parse(
        this.valorRecibo,
        this.vCurrencyOptions
      );
    },

    valorCaixaMov() {
      this.caixa_af_item.valor = this.$ci.parse(
        this.valorCaixaMov,
        this.vCurrencyOptions
      );
      this.$ci.setValue(this.$refs.valorRecibo, this.caixa_af_item.valor);
    },

    selectedFormaPagamento() {
      if (this.selectedFormaPagamento) {
        this.caixa_af_item.forma_pagamento_id = this.selectedFormaPagamento.id;
      }
    },
  },

  methods: {
    setValorFatura(valor) {
      this.valor_fatura = valor;
      this.$ci.setValue(this.$refs.valorCaixaMov, valor);
    },
    async getFormasPagamentos() {
      this.loading = true;

      await fetchFormasPagamentosAtivo()
        .then((response) => {
          this.formas_pagamentos = response;
          this.selectedFormaPagamento = this.formas_pagamentos.find(
            (forma) => forma.id === 1
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getPlanosContas() {
      await fetchPlanosContas().then((response) => {
        this.planocontas = response;
      });
    },

    async getPlanosContasTree() {
      await fetchPlanosContasTree().then((response) => {
        this.planocontastree = response;
      });
    },

    selectPlanoConta(plano_selecionado) {
      this.selectedPlanoConta = plano_selecionado;
      if (this.selectedPlanoConta) {
        this.caixa_af_item.plano_conta_id = this.selectedPlanoConta.id;
      }
    },

    createCaixaMov() {
      if (
        this.caixa_af_item.plano_conta_id === null ||
        this.caixa_af_item.plano_conta_id === "" ||
        this.caixa_af_item.plano_conta_id === undefined
      ) {
        this.notPlanocontas = true;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let block = false;

        if (this.files.length) {
          this.files.forEach((file) => {
            if (file.size >= 104857600) {
              this.$toast.error(
                "O tamanho maximo do arquivo permitido é 100MB"
              );
              block = true;
              return;
            } else if (
              file.type.substr(-5) != "/jpeg" &&
              file.type.substr(-4) != "/png" &&
              file.type.substr(-4) != "/txt" &&
              file.type.substr(-4) != "/xls" &&
              file.type.substr(-6) != ".sheet" &&
              file.type.substr(-4) != "/pdf" &&
              file.type.substr(-4) != "/doc" &&
              file.type.substr(-5) != ".text" &&
              file.type.substr(-9) != ".document"
            ) {
              this.$toast.error("Formato não permitido");
              block = true;
              return;
            }
          });
        }

        if (!block) {
          const caixa_af_item = new FormData();

          let caixa_af_item_2 = {};
          caixa_af_item_2.moeda_id = this.caixa_af_item.moeda_id;
          caixa_af_item_2.caixa_af_id = this.caixa_af.id;
          caixa_af_item_2.historico = this.caixa_af_item.historico;
          caixa_af_item_2.documento = this.caixa_af_item.documento;
          caixa_af_item_2.valor = this.caixa_af_item.valor;
          caixa_af_item_2.tipo = this.caixa_af_item.tipo;
          caixa_af_item_2.possui_fatura = this.possui_fatura;
          caixa_af_item_2.plano_conta_id = this.caixa_af_item.plano_conta_id;
          caixa_af_item_2.forma_pagamento_id =
            this.caixa_af_item.forma_pagamento_id;

          for (let key in caixa_af_item_2) {
            if (
              caixa_af_item_2[key] !== null &&
              caixa_af_item_2[key] !== undefined &&
              caixa_af_item_2[key] !== ""
            ) {
              caixa_af_item.append(key, caixa_af_item_2[key]);
            }
          }

          if (this.possui_fatura) {
            caixa_af_item_2.fatura = this.fatura;
            caixa_af_item.append(
              "fatura",
              JSON.stringify(caixa_af_item_2.fatura)
            );
          }
          if (this.hasRecibo) {
            let recibo_2 = {};

            recibo_2.quem_assina = this.recibo.quem_assina;
            recibo_2.data_recibo = this.recibo.data_recibo;
            recibo_2.concepto_descricao = this.recibo.concepto_descricao;
            recibo_2.moeda_id = this.selectedMoeda.id_moeda;
            recibo_2.valor_recibo = this.recibo.valor_recibo;

            caixa_af_item_2.recibo = recibo_2;
            caixa_af_item.append(
              "recibo",
              JSON.stringify(caixa_af_item_2.recibo)
            );
          }

          if (this.files.length) {
            for (var i = 0; i < this.files.length; i++) {
              let files = this.files[i];
              caixa_af_item.append("files[" + i + "]", files);
            }
          }

          postCaixaAfItem(caixa_af_item)
            .then(() => {
              this.$emit("update:dialogCaixasAfItem", false);
              this.$emit("fetch-caixas-items");
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    remove(item) {
      this.files.splice(
        this.files.findIndex((file) => file.name === item),
        1
      );
    },
  },

  async mounted() {
    await this.getFormasPagamentos();
    await this.getPlanosContasTree();
    await this.getPlanosContas();
  },
};
</script>

<style></style>
