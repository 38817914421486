<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid class="fill-height pa-0">
        <v-row class="fill-height" align="center" justify="center">
          <v-col
            class="fill-height d-flex justify-center tocs_gray_1 pa-6"
            :class="{
              'align-center': $vuetify.breakpoint.mdAndUp,
              'align-start': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card
              class="pa-4 pa-md-8"
              width="100%"
              max-width="450px"
              color="tocs_gray_1"
              flat
            >
              <v-card-title
                class="pt-0 px-0 pb-3 justify-center flex-column"
                style="width: 100%"
              >
                <v-img src="@/assets/icon_1.png" max-width="80"></v-img>
                <div class="text-md-h4 text-h5 tocs_blue_2--text mt-2">
                  {{ $tc("global.bem-vindo") }}
                  <strong style="letter-spacing: 4px !important">TOCS</strong>
                </div>
              </v-card-title>
              <v-card-text class="pa-0 mt-4">
                <v-form v-model="valid" ref="form">
                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-inner-icon="mdi-account-outline"
                    solo
                    flat
                    full-width
                    type="email"
                    v-model="login.email"
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-text-field
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    name="senha"
                    label="Senha"
                    prepend-inner-icon="mdi-lock"
                    full-width
                    solo
                    flat
                    v-model="login.senha"
                    class="input-group--focused mt-1"
                    @click:append="show = !show"
                    @keyup.enter="logar"
                  ></v-text-field>

                  <div>
                    <v-checkbox
                      class="ma-0"
                      color="tocs_blue_3"
                      v-model="checkbox"
                    >
                      <template v-slot:label>
                        <div
                          class="body-2 font-weight-bold tocs_blue_3--text"
                          style="width: 100%"
                        >
                          Lembrar Username
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </v-form>
              </v-card-text>

              <template v-if="erros.length > 0">
                <v-card-text class="pa-0 mb-4 red--text">
                  <div class="py-1" v-for="(erro, index) in erros" :key="index">
                    <v-icon class="mr-2 red--text">mdi-alert-circle</v-icon>
                    <span>{{ erro }}</span>
                  </div>
                </v-card-text>
              </template>

              <v-card-actions class="d-flex justify-end px-0">
                <v-btn
                  rounded
                  @click.prevent="logar"
                  x-large
                  max-height="50px"
                  color="tocs_blue_3"
                  class="mt-1 px-8 white--text"
                  :disabled="!valid || loading"
                  :loading="loading"
                >
                  {{ $tc("global.login") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            class="fill-height tocs_blue_2 d-flex justify-center align-start flex-column pl-8"
          >
            <h1
              class="tocs_blue_3--text text-h1 font-weight-bold"
              style="letter-spacing: 8px !important"
            >
              <span class="tocs_blue_4--text">T</span>ask,
            </h1>
            <h1
              class="tocs_blue_3--text text-h1 font-weight-bold"
              style="letter-spacing: 8px !important"
            >
              <span class="tocs_blue_4--text">O</span>rganizer,
            </h1>
            <h1
              class="tocs_blue_3--text text-h1 font-weight-bold"
              style="letter-spacing: 8px !important"
            >
              <span class="tocs_blue_4--text">C</span>ontrol,
            </h1>
            <h1
              class="tocs_blue_3--text text-h1 font-weight-bold"
              style="letter-spacing: 8px !important"
            >
              <span class="tocs_blue_4--text">S</span>cheduler.
            </h1>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="empresasDialog" max-width="800px" persistent>
        <v-card tile :loading="loadingEmpresa">
          <v-card-title> Selectione uma empresa para carregar! </v-card-title>
          <!-- <v-divider></v-divider> -->
          <v-card-text class="px-5 py-6">
            <v-row>
              <v-col
                cols="auto"
                v-for="(empresa, index) in usuario.empresas"
                :key="index + `_${empresa.empresa_id}`"
              >
                <v-btn depressed x-large @click="escolherEmpresa(empresa)">
                  {{ empresa.nome }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { loadLanguageAsync } from "@/plugins/i18n.js";
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      show: false,
      valid: false,
      loading: false,
      loadingLanguage: false,
      checkbox: false,
      login: {
        email: "",
        senha: "",
      },
      erros: [],
      empresasDialog: false,
      loadingEmpresa: false,
    };
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$tc("global.obrigatorio"),
        min: (v) => v.length >= 3 || this.$tc("global.mincaracteres"),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$tc("global.validEmail");
        },
      };
    },
    ...mapState("Usuario", {
      auto_redirect: (state) => state.auto_redirect,
      usuario: (state) => state.usuario,
      empresa: (state) => state.empresa,
    }),
    ...mapState("Componentes", {
      componentes: (state) => state.componentes,
    }),
  },
  methods: {
    async pushToRoutes() {
      let current_route = this.$ls.get("current_route");
      if (this.auto_redirect && current_route) {
        this.$router.push({
          path: current_route,
        });
      } else {
        this.$router.push({ path: "/" });
      }
    },
    async logar() {
      try {
        this.loading = true;
        this.erros = [];

        const email = this.$ls.get("email");

        if (email && !this.checkbox) {
          this.$ls.remove("email");
        }
        if (this.checkbox) {
          this.$ls.set("email", this.login.email);
        }

        await this.$store.dispatch("Usuario/logarUsuario", this.login);

        if (!this.usuario.empresas.length) {
          this.$toast.error("Usuario nao tem empresa vinculada.");
        }

        if (this.usuario.empresas.length > 1) {
          this.empresasDialog = true;
        } else {
          await this.escolherEmpresa(this.usuario.empresas[0]);
        }
        this.loading = false;
      } catch (error) {
        if (error.response && error.response.status === 406) {
          this.$toast.error("Usuario Desativado");
        }
        if (error) this.erros.push(error);
        this.loading = false;
      }
    },
    async escolherEmpresa(empresa) {
      this.loadingEmpresa = true;
      await this.$store.dispatch("Usuario/setarEmpresa", empresa);
      await this.$store.dispatch("Usuario/updateEmpresaAtiva", empresa);
      if (this.empresa) {
        await this.$store.dispatch("Moedas/getMoedas");
        await this.$store.dispatch("ListasPreco/getListas");
        await this.$store.dispatch("Componentes/getComponentes");
        await this.pushToRoutes();
      }
      this.loadingEmpresa = false;
    },
  },

  async mounted() {
    const email = this.$ls.get("email");
    if (email) {
      this.login.email = email;
      this.checkbox = true;
    }

    if (process.env.VUE_APP_SENHA_MICHEL) {
      this.login.senha = process.env.VUE_APP_SENHA_MICHEL;
    }

    this.loadingLanguage = true;

    const locale = this.$ls.get(
      "locale",
      window.navigator.language.substr(0, 2)
    );

    await loadLanguageAsync(locale);
    this.$store.commit("UPDATE_LOCALE", locale);
    this.$vuetify.lang.current = locale;
  },
};
</script>

<style lang="scss" scoped></style>
