<template>
  <v-dialog
    v-model="dialogAddEditMarcas"
    @click:outside="$emit('update:dialogAddEditMarcas', false)"
    @keydown.esc="$emit('update:dialogAddEditMarcas', false)"
    width="700px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.nova") + " " + $tc("global.marca")
            : $tc("global.atualizar") + " " + $tc("global.marca")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddEditMarcas', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="4" class="">
              <div class="d-flex justify-center align-center">
                <!--editando -->
                <v-card
                  outlined
                  class="fill-height d-flex align-center justify-center"
                  style="position:relative;"
                  v-if="edit"
                >
                  <v-card flat>
                    <v-img v-if="logo" :src="logo" contain></v-img>
                    <v-img
                      v-else
                      :src="require('@/assets/pagina-default.png')"
                      contain
                    ></v-img>
                    <v-btn
                      fab
                      dark
                      x-small
                      absolute
                      bottom
                      right
                      @click="onButtonClick"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        class="d-none"
                        ref="uploader"
                        @change="onFileChanged"
                      />
                      <v-icon>mdi-image</v-icon>
                    </v-btn>
                  </v-card>
                </v-card>

                <!--adicionando -->
                <v-card
                  v-if="adding"
                  flat
                  class="fill-height d-flex align-center justify-center"
                >
                  <v-card flat v-if="logo">
                    <v-img :src="logo" contain></v-img>
                  </v-card>
                  <div v-else class="fill-height" style="width: 100%;">
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :class="{ hoverFile: hoverFile }"
                      :use-custom-dropzone-options="true"
                      :options="dropzoneOptions"
                      @vdropzone-file-added="fileAdded"
                      @vdro-pzone-dragenter="hoverFile = true"
                      @vdropzone-drag-over="hoverFile = true"
                      @vdropzone-drag-leave="hoverFile = false"
                      :useCustomSlot="true"
                      :include-styling="false"
                    >
                      <div class="text-center">
                        <v-icon size="80" color="info">
                          mdi-image-plus
                        </v-icon>
                        <h3 class="text-subtitle-1 ">
                          {{ $tc("global.arrastesolte") }}
                        </h3>
                        <div class="text-caption">
                          {{ $tc("global.cliqueselecionar") }}
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="marca.nome"
                :label="$tc('global.nome')"
                dense
                outlined
                :rules="formRules"
                required
              ></v-text-field>
              <v-card
                v-if="edit"
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Status
                  <span class="pl-1" v-if="marca.status === 1">
                    {{ $tc("global.ativo") }}
                  </span>
                  <span class="pl-1" v-else>{{ $tc("global.inativo") }}</span>
                </v-subheader>
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  v-model="marca.status"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogAddEditMarcas', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createMarca() : updateMarca()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { inputRequired } from "@/helpers/utils.js";
import { postMarca, putMarca } from "@/api/marcas/marcas.js";
export default {
  name: "DialogAddEditMarcas",

  props: {
    dialogAddEditMarcas: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    adding: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      validForm: true,
      hoverFile: false,
      logo: "",
      logoUpload: "",
      formRules: [inputRequired],
      marca: {},
      editItem: null,
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },
    fileAdded(file) {
      this.logoUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      this.logoUpload = file;
      this.marca.logo = !this.marca.logo ? "logo" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
        // vue.editFornecedor();
      };
    },
    updateMarca() {
      if (JSON.stringify(this.marca) === JSON.stringify(this.marca_original)) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const marca = new FormData();

        if (this.logoUpload) {
          marca.append("logo", this.logoUpload, this.logoUpload.name);
          marca.append("logo64", this.logo);
        }
        let marca_2 = {};
        marca_2.nome = this.marca.nome;
        marca_2.status = this.marca.status;

        for (let key in marca_2) {
          if (
            marca_2[key] !== null &&
            marca_2[key] !== undefined &&
            marca_2[key] !== ""
          ) {
            marca.append(key, marca_2[key]);
          }
        }

        putMarca(this.marca.id, marca)
          .then(() => {
            // this.marca_original = { ...this.marca };
            this.$emit("fetch-marcas");
            this.$emit("update:dialogAddEditMarcas", false);
            
          })
          .catch((error) => {
            console.log(error);
            
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    createMarca() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const marca = new FormData();

        if (this.logoUpload) {
          marca.append("logo", this.logoUpload, this.logoUpload.name);
          marca.append("logo64", this.logo);
        }
        let marca_2 = {};
        marca_2.nome = this.marca.nome;
        marca_2.status = this.marca.status;

        for (let key in marca_2) {
          if (
            marca_2[key] !== null &&
            marca_2[key] !== undefined &&
            marca_2[key] !== ""
          ) {
            marca.append(key, marca_2[key]);
          }
        }

        postMarca(marca)
          .then(() => {
            this.$emit("fetch-marcas");
            this.$emit("update:dialogAddEditMarcas", false);
            // this.dia;
          })
          .catch((error) => {
            console.log(error.response);
          })
          .finally(() => {
            this.loading = false;
            this.isAdding = false;
          });
      }
    },
  },

  mounted() {
    if (this.edit) {
      this.marca = { ...this.item };
      this.marca_original = { ...this.item };
      this.logo = this.marca.logo;
    }
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
