<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.pdf_layout', 2)"
      icon="mdi-format-page-break"
      dark
    />
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          color="button_1"
          class="white--text mt-4 mt-sm-0"
          :to="{ path: '/configuracoes/pdf-layouts/adicionar' }"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="pdfLayouts"
          :items-per-page="-1"
          class="data-tables data-tables__row-click"
          @click:row="goToPdfLayout"
          hide-default-footer
        >
          <!--  :class="{ 'data-tables__row-click': PListaPrecoVisualizar }" -->
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchPdfLayouts } from "@/api/pdf-layouts/pdf_layouts.js";

export default {
  name: "PdfLayouts",

  data() {
    return {
      loading: false,
      pdfLayouts: [],
      search: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipo",
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
      ];
    },
  },

  methods: {
    goToPdfLayout(item) {
      this.$router.push({ path: `/configuracoes/pdf-layouts/${item.id}` });
    },
    getPdfLayouts() {
      this.loading = true;
      fetchPdfLayouts().then((response) => {
        this.pdfLayouts = response;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getPdfLayouts();
  },
};
</script>

<style></style>
