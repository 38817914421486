import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { listaDeCompras } from "@/api/relatorios/relatorio_compras.js";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();
  const filtros = bodyPDF.queryString;
  let compras = await listaDeCompras(filtros);
  let compras_info = compras.compras;
  const filtros_info = compras.filtros;
  const agrupamento = compras.agrupamento;
  let text_filtros = "";

  for (const [key, value] of Object.entries(filtros_info)) {
    if (value){ 
      text_filtros += key.toUpperCase() + ": " + value + "   ";
    }
  }
  const cabecalho = bodyPDF.cabecalho;
  const valor_grupo_compras = agrupamento;
  
  const periodo = bodyPDF.periodo;

  return new Promise((resolve, reject) => {
    let cabecalho_data = {
      margin: [0, 5, 0, 10],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: " COMPRAS - " + periodo,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let cabecalho_filtros = {
      margin: [0, 0, 0, -3],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: text_filtros,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              fontSize: 10,
            },
          ],
        ],
      },
    };

    const arrayItensAgrupados = [];
    const map = new Map();

    compras_info.forEach(item => {
      const { [valor_grupo_compras] : valor } = item;
      if (!map.has(valor)) {
        map.set(valor, arrayItensAgrupados.length);
        arrayItensAgrupados.push([item]);
      } else {
        arrayItensAgrupados[map.get(valor)].push(item);
      }
    });

    let array_tables = [];

    for (const table_info of arrayItensAgrupados) {

      array_tables.push({
        margin:[0, 0, 0, -1],
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: valor_grupo_compras.toUpperCase() + ": " + table_info[0][valor_grupo_compras],
                fillColor: "#E0E0E0",
                border: [true, true, true, false],
                fontSize: 8,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
        },
      });

      let table_compras = {
        margin: [0, 0, 0, 5],
        table: {
          widths: ["auto", "*", 105, "auto", 50, "auto", 50, 50, 50],
          body: [
            [
              {
                text: "COD",
                border: [true, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "PRODUTO",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "FORNECEDOR",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "U/M",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "QUANTIDADE",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "MOEDA",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "CUSTO",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "DESCONTO",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
              {
                text: "TOTAL",
                border: [false, true, true, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
          fillColor: function (rowIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      };

      const row_font_size = 8;
      table_info.forEach((compra, index) => {
          let table_row = [
            {
              text: compra.produto_id,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                true,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: compra.descricao,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: compra.fornecedor,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: Vue.filter("excerpt")(compra.produto_und, 15),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: Vue.filter("excerpt")(compra.qtde, 15),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: compra.moeda_sigla,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text: (
                  compra.moeda_sigla == "G$"
                    ? Vue.filter("guarani")(compra.valor)
                    : Vue.filter("currency")(compra.valor)
                ),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text:(
                  compra.moeda_sigla == "G$"
                    ? Vue.filter("guarani")(compra.desconto)
                    : Vue.filter("currency")(compra.desconto)
                ),
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                false,
                index != table_info.length - 1 ? false : true,
              ],
            },
            {
              text:compra.total,            
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [
                false,
                true,
                true,
                index != table_info.length - 1 ? false : true,
              ],
            }
          ];

          table_compras.table.body.push(table_row);
        
      });


      array_tables.push(table_compras);
    }

    let content = [cabecalho, cabecalho_data, array_tables];

    if (!Object.values(filtros_info).every(value => value === null)) {
      content.splice(1, 0, cabecalho_filtros);
    }

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
