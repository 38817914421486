import Vue from "vue";
import router from "./../router";
import axios from "axios";
import store from "@/store/index.js";
import { i18n } from "@/plugins/i18n";

const url = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: url + "/api",
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = Vue.ls.get("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// interceptor to catch errors
const errorInterceptor = (error) => {
  // all the error responses
  if (!error.response) {
    Vue.prototype.$toast.error(i18n.tc("global.semConexao"));
  } else {
    switch (error.response.status) {
      case 400:
        Vue.prototype.$toast.error(i18n.tc("global.error400"));
        break;
      case 401: // authentication error, logout the user
        Vue.prototype.$toast.error(i18n.tc("global.error401"));
        if (store.state.Usuario.auto_redirect === false) {
          store.commit("Usuario/UPDATE_AUTO_REDIRECT", true);
          store.dispatch("Usuario/deslogarUsuario");
        }

        if (router.currentRoute.path != "/login") {
          router.push({ path: "/login" });
        }

        break;
      case 403:
        Vue.prototype.$toast.error("Acesso não autorizado!");
        router.push({ path: "/home" });
        break;
      case 422:
        break;
      case 404: {
        break;
      }
      case 406: {
        break;
      }
      case 409: {
        break;
      }

      default:
        Vue.prototype.$toast.error(i18n.tc("global.errorGeral"));
    }
  }

  return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      if (response.data.action === "update" && !response.data.no_toast) {
        Vue.prototype.$toast.success(i18n.tc("global.atualizadosucesso"));
      }

      if (response.data.action === "delete" && !response.data.no_toast) {
        Vue.prototype.$toast.success(i18n.tc("global.delsucesso"));
      }
      break;
    case 201:
      if (response.data.action === "create" && !response.data.no_toast) {
        Vue.prototype.$toast.success(i18n.tc("global.criadosucesso"));
      }
      break;
    default:
    // default case
  }

  return response;
};

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
};

export default api;
