<template>
  <GmapMap
    :center="center"
    :zoom="15"
    map-type-id="roadmap"
    style="width: 100%; height: 400px"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }"
  >
    <GmapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      :clickable="true"
      @click="center = m.position"
    />
  </GmapMap>
</template>

<script>
export default {
  name: "GoogleMap",

  props: ["center", "markers"],
};
</script>

<style></style>
