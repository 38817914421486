<template>
  <v-container fluid>
    <PageToolbar title="Giros" icon="mdi-sim" dark />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          append-icon="mdi-magnify"
          v-model="search"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-btn-toggle mandatory v-model="tipo_giro" class="ml-3">
          <v-btn value="pendente" class="text-caption" height="40px">
            {{ $tc("global.pendentes", 2) }}
          </v-btn>
          <v-btn value="identificado" class="text-caption" height="40px">
            {{ $tc("global.identificado", 2) }}
          </v-btn>
          <v-btn value="baixado" class="text-caption" height="40px">
            {{ $tc("global.baixado", 2) }}
          </v-btn>
        </v-btn-toggle>

        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          @click="openDialog()"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="giros"
          :search="search"
          :items-per-page="10"
          class="data-table data-tables__row-click"
          @click:row="goToGiro"
        >
          <template v-slot:item.cliente_nome="{ item }">
            <span v-if="item.cliente_nome">{{ item.cliente_nome }}</span>
            <span v-else class="h4">
              {{ $tc("global.nao") }} {{ $tc("global.vinculado") }}
            </span>
          </template>

          <template v-slot:item.valor="{ item }">
            G$ {{ item.valor | guarani }}
          </template>

          <template v-slot:item.taxa="{ item }">
            G$ {{ item.taxa | guarani }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              v-if="item.status === 0"
              @click="goToEditGiro(item)"
              class="mr-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogAddEditGiros
      v-if="dialogAddEditGiros"
      :dialogAddEditGiros.sync="dialogAddEditGiros"
      :editar.sync="editar"
      :prepararGiro.sync="prepararGiro"
      :item="giro"
      @fetch-giros="getGiros"
      origem="MANUAL"
    />
    <DialogBaixarGiros
      v-if="dialogBaixarGiros"
      :dialogBaixarGiros.sync="dialogBaixarGiros"
      :item="giro"
      @fetch-giros="getGiros"
    />
  </v-container>
</template>

<script>
import { fetchGiros } from "@/api/giros/giros.js";

export default {
  name: "Giros",

  components: {
    DialogAddEditGiros: () => import("./components/DialogAddEditGiros.vue"),
    DialogBaixarGiros: () => import("./components/DialogBaixarGiros.vue"),
  },

  data() {
    return {
      loading: false,
      search: "",
      giros: [],
      giro: {},
      tipo_giro: "pendente",
      dialogAddEditGiros: false,
      dialogBaixarGiros: false,
      editar: false,
      prepararGiro: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "center",
          value: "id",

          sortable: false,
        },
        {
          text: this.$tc("global.cliente"),
          align: "center",
          value: "cliente_nome",
          // sortable: false,
        },
        {
          text: this.$tc("global.telefone"),
          align: "center",
          value: "telefone",
          sortable: false,
        },
        {
          text: this.$tc("global.origem"),
          value: "origem",
          sortable: false,
        },
        {
          text: this.$tc("global.comprovante"),
          value: "historico",
          sortable: false,
        },
        {
          text: this.$tc("global.tipo") + " Giro",
          value: "tipo",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          sortable: false,
        },

        {
          text: this.$tc("global.taxa"),
          value: "taxa",
          sortable: false,
        },

        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    tipo_giro() {
      this.getGiros();
    },
  },

  methods: {
    goToGiro(giro) {
      if (giro.status === 0) {
        this.prepararGiro = true;
        this.dialogAddEditGiros = true;
        this.giro = { ...giro };
      } else if (giro.status === 1) {
        this.dialogBaixarGiros = true;
        this.giro = { ...giro };
      }
    },

    openDialog() {
      this.dialogAddEditGiros = true;
    },

    goToEditGiro(giro) {
      this.editar = true;
      this.dialogAddEditGiros = true;
      this.giro = { ...giro };
    },

    getGiros() {
      this.loading = true;
      this.giros = [];
      fetchGiros(`?tipo_giro=${this.tipo_giro}`)
        .then((response) => {
          this.giros = response;
          this.giro = {};
        })
        .catch((error) => {});
    },
  },

  mounted() {
    this.getGiros();
  },
};
</script>

<style lang="scss" scoped></style>
