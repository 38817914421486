<template>
  <v-container fluid>
    <PageToolbar title="Audits" icon="mdi-security" dark />

    <ListaAudits />
  </v-container>
  <!-- <NaoAutorizado v-else /> -->
</template>

<script>
export default {
  name: "Audits",
  components: {
    ListaAudits: () => import("./components/ListaAudits.vue"),
  },
  data: () => ({}),
  computed: {},
  mounted() {},
};
</script>

<style></style>
