const routes = [
  {
    path: "/financeiro/compensacao-cartoes",
    component: () =>
      import("@/views/dashboard/compensacao-cartoes/CompensacaoCartoes.vue"),
    meta: {
      menu: "dashboard",

      title: { text: "global.cartao", plural: true },
      icon: "mdi-credit-card-check-outline",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.compensacaoCartao" },
      ],

      permission: { componente: "CompensacaoCartao" },
    },
  },
];

export default routes;
