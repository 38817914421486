<template>
  <v-card :loading="loading" height="100%">
    <v-card-title class="tocs_gray_1">
      <v-icon class="mr-2">mdi-balloon</v-icon>
      {{ $tc("global.aniversarios") }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="clientes"
        :items-per-page="10"
        class="data-tables data-tables__row-click"
        :hide-default-footer="clientes.length > 10 ? false : true"
        @click:row="goToCliente"
      >
        <template v-slot:item.data_nascimento="{ item }">
          {{ item.data_nascimento | dateFormat("dd/MM") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getBoxClientesAniversariantes } from "@/api/clientes/clientes.js";

export default {
  name: "BoxAniversariantes",

  data() {
    return {
      loading: false,
      clientes: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.cliente"),
          value: "nome",
        },
        {
          text: this.$tc("global.data"),
          value: "data_nascimento",
        },
      ];
    },
  },

  methods: {
    goToCliente(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.nome);
      this.$router.push({ path: `/clientes/${item.id}` });
    },

    async fetchClientes() {
      this.loading = true;
      await getBoxClientesAniversariantes()
        .then((response) => {
          this.loading = false;
          this.clientes = response;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(error.message);
        });
    },
  },

  async mounted() {
    this.loading = true;
    this.fetchClientes();
    this.loading = false;
  },
};
</script>

<style></style>
