<template>
  <v-dialog
    v-model="dialogHistoricoCliente"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    max-width="1000px"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5 font-weight-medium secondary--text">{{
          $tc("global.informacoes") + " " + $tc("global.cliente")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs class="" v-model="tab">
        <v-tab class="caption font-weight-bold">
          {{ $tc("global.informacoes") }}
        </v-tab>
        <v-tab class="caption font-weight-bold">
          {{ $tc("global.historico") }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div
            class="pa-6 d-flex flex-column subtitle-2"
            :style="
              tipo_venda === 'PISCINA'
                ? `height: calc(100vh - 236px); overflow: auto`
                : ''
            "
          >
            <v-form
              @submit.prevent
              ref="form"
              v-model="validForm"
              lazy-validation
            >
              <v-row>
                <!-- Nome -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.nome"
                    :label="$tc('global.nome')"
                    dense
                    outlined
                    :readonly="true"
                    :rules="formRules"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- Documento CI -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.doc_ci"
                    :label="$tc('global.documento') + ` CI`"
                    dense
                    outlined
                    :rules="cliente.doc_ruc ? [] : formRules"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- Documento RUC -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.doc_ruc"
                    :label="$tc('global.documento') + ` RUC`"
                    dense
                    outlined
                    :rules="cliente.doc_ci ? [] : formRules"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.celular"
                    :label="$tc('global.celular')"
                    dense
                    :rules="cliente.telefone ? [] : formRules"
                    required
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.telefone"
                    :label="$tc('global.telefone')"
                    :rules="cliente.celular ? [] : formRules"
                    required
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- LOCAL DE TRABALHO -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.local_trabalho"
                    :label="$tc('global.localTrabalho')"
                    :rules="formRules"
                    required
                    dense
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- ENDERECO -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="selectedEndereco"
                    :label="$tc('global.endereco')"
                    :items="cliente_enderecos"
                    :rules="formRules"
                    @click:append="adicionarEnderecoCliente"
                    append-icon="mdi-plus"
                    clearable
                    dense
                    item-text="rua"
                    item-value="rua"
                    hide-details
                    outlined
                    return-object
                  >
                    <template v-slot:item="{ item }">
                      <div class="d-flex align-center justify-space-between">
                        {{ item.rua }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click.stop="editarEnderecoCliente(item)">
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <!-- Credito Cliente -->
                <v-col cols="12" md="6">
                  <v-text-field
                    ref="valorCreditoCliente"
                    v-model="valorCreditoCliente"
                    label="Credito"
                    dense
                    required
                    :rules="formRules"
                    :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                    outlined
                    hide-details
                    height="40px"
                    :disabled="!selectedMoeda"
                    style="display: flex"
                    class="remove-underline input-font-size input-right"
                    v-currency="vCurrencyOptions"
                  >
                  </v-text-field>
                </v-col>

                <v-col v-if="tipo_venda === 'PISCINA'">
                  <v-form
                    @submit.prevent
                    ref="formReferenciaCliente"
                    v-model="validForm"
                    lazy-validation
                  >
                    <v-card outlined>
                      <v-card-title>
                        <span
                          class="
                            text-subtitle-1
                            font-weight-medium
                            secondary--text
                          "
                          >{{
                            $tc("global.informacoes") +
                            " " +
                            $tc("global.referencia") +
                            " do " +
                            $tc("global.cliente")
                          }}</span
                        >
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row>
                          <!-- Nome -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="referencia.nome_conjugue"
                              :label="$tc('global.nome')"
                              dense
                              outlined
                              :rules="formRules"
                              required
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <!-- Documento num_cedula_conjugue -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="referencia.num_cedula_conjugue"
                              :label="$tc('global.documento')"
                              dense
                              outlined
                              :rules="formRules"
                              required
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <!-- tel_conjugue -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="referencia.tel_conjugue"
                              :label="$tc('global.telefone')"
                              :rules="formRules"
                              required
                              dense
                              outlined
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <!-- LOCAL DE end_trabalho -->
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="referencia.end_trabalho"
                              :label="$tc('global.localTrabalho')"
                              :rules="formRules"
                              required
                              dense
                              hide-details
                              outlined
                            ></v-text-field>
                          </v-col>

                          <!-- LOCAL DE end_conjugue -->
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="referencia.end_conjugue"
                              :label="$tc('global.endereco')"
                              :rules="formRules"
                              required
                              dense
                              hide-details
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-divider></v-divider>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="updateClienteDadosVenda"
              :disabled="!validForm"
              color="button_1"
              class="white--text"
              small
            >
              <v-icon left>mdi-check</v-icon>
              {{ $tc("global.salvar") }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            v-if="!loading"
            :headers="headers"
            :search="search.length >= 3 ? search : ''"
            :items="vendas"
            :item-key="vendas.items"
            show-expand
            :items-per-page="10"
            :sort-desc="true"
            style="height: calc(100vh - 214px); overflow: auto"
            class="data-tables data-tables__row-click"
          >
            <template v-slot:item.data="{ item }">
              {{ item.data | dateFormat("dd/MM/yyyy") }}
            </template>

            <template v-slot:item.status="{ item }">
              <span v-if="item.status === -1">Cancelada</span>
              <span v-if="item.status === 0"> Em Andamento </span>
              <span v-if="item.status === 1"> Pendente </span>
              <span v-if="item.status === 2"> Finalizada </span>
            </template>
            <template v-slot:item.valor="{ item }">
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.moeda_sigla }} {{ item.valor | guarani }}
              </span>
              <span v-else
                >{{ item.moeda_sigla }}{{ item.valor | currency }}
              </span>
            </template>

            <template v-slot:item.desconto="{ item }">
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.moeda_sigla }} {{ item.desconto | guarani }}
              </span>
              <span v-else
                >{{ item.moeda_sigla }}{{ item.desconto | currency }}
              </span>
            </template>

            <template v-slot:item.total="{ item }">
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.moeda_sigla }} {{ item.total | guarani }}
              </span>
              <span v-else
                >{{ item.moeda_sigla }}{{ item.total | currency }}
              </span>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td :colspan="12" class="px-5">
                <v-data-table
                  v-if="!loading && item.items.length"
                  :headers="headersItems"
                  :items="item.items"
                  :items-per-page="15"
                  hide-default-footer
                >
                  <!-- <td :colspan="headers.length">More info about {{ item }}</td> -->
                  <template v-slot:item.desconto="{ item }">
                    <span v-if="item.moeda_sigla === 'G$'">
                      {{ item.moeda_sigla }} {{ item.desconto | guarani }}
                    </span>
                    <span v-else
                      >{{ item.moeda_sigla }}{{ item.desconto | currency }}
                    </span>
                  </template>
                  <template v-slot:item.cif="{ item }">
                    <span v-if="item.moeda_sigla === 'G$'">
                      {{ item.moeda_sigla }} {{ item.cif | guarani }}
                    </span>
                    <span v-else
                      >{{ item.moeda_sigla }}{{ item.cif | currency }}
                    </span>
                  </template>
                </v-data-table>
                <h3 v-else class="text-subtitle-1 text-center">
                  Nenhum item nessa venda
                </h3>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <DialogEnderecos
      v-if="dialogEnderecos"
      :dialogEnderecos.sync="dialogEnderecos"
      :edit="edit"
      :item="enderecoEditar"
      :cliente_id="cliente_id"
      @fetch-enderecos="fetchClienteEnderecos"
    />
  </v-dialog>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import {
  postClienteReferencias,
  // putClienteReferencias,
  fetchClienteReferencias,
} from "@/api/clientes/clientes_refencias.js";
import {
  getHistoricoVendas,
  getCliente,
  putCliente,
} from "@/api/clientes/clientes.js";
import {
  fetchEnderecosClientes,
  putEnderecoFavorito,
} from "@/api/clientes/clientes_enderecos.js";

import { mapState } from "vuex";
export default {
  name: "dialogHistoricoCliente",

  props: {
    dialogHistoricoCliente: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: Number,
    },
    tipo_venda: {
      type: String,
    },
  },

  components: {
    // DataField: () => import("@/components/fields/DataField.vue"),
    DialogEnderecos: () =>
      import("@/views/dashboard/clientes/components/DialogEnderecos.vue"),
  },

  data() {
    return {
      tab: 0,
      loading: false,
      search: "",
      vendas: [],
      cliente: {},
      referencia: {},
      referencia_original: [],
      cliente_enderecos: [],
      selectedEndereco: null,
      formRules: [inputRequired],
      emailRules: [validaEmail],
      valorCreditoCliente: 0,
      selectedMoeda: null,
      dialogEnderecos: false,
      enderecoEditar: {},
      edit: false,
      validForm: true,
    };
  },

  watch: {
    valorCreditoCliente() {
      if (this.valorCreditoCliente) {
        this.cliente.credito = this.$ci.parse(
          this.valorCreditoCliente,
          this.vCurrencyOptions
        );
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Numero",
          value: "numero",
        },
        {
          text: "Data",
          value: "data",
        },
        // {
        //   text: "Cliente",
        //   value: "cliente_nome",
        // },
        {
          text: "Valor",
          value: "valor",
        },
        {
          text: "Desconto",
          value: "desconto",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },

    headersItems() {
      return [
        {
          text: "Descrição",
          value: "descricao",
          sortable: false,
        },
        {
          text: "Quantidade",
          value: "qtde",
          sortable: false,
        },

        {
          text: "Desconto",
          value: "desconto",
          sortable: false,
        },
        {
          text: "custo",
          value: "cif",
          sortable: false,
        },
      ];
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  methods: {
    async fetchHistoricoVendas() {
      this.loading = true;
      return getHistoricoVendas(`?cliente_id=${this.cliente_id}`)
        .then((response) => {
          this.vendas = response;
          this.loading = false;
        })
        .catch(() => {});
    },

    fetchCliente() {
      this.loading = true;
      return getCliente(this.cliente_id).then((response) => {
        this.cliente = response;
        this.selectedMoeda = this.moedas.find((moeda) => {
          return moeda.id_moeda === this.cliente.moeda_id;
        });
        this.$ci.setValue(this.$refs.valorCreditoCliente, this.cliente.credito);
        this.loading = false;
      });
    },

    fetchClienteEnderecos() {
      this.loading = true;
      return fetchEnderecosClientes(this.cliente_id).then((response) => {
        this.cliente_enderecos = response;
        this.selectedEndereco = this.cliente_enderecos.find((endereco) => {
          return (endereco.end_principal = true);
        });
        this.loading = false;
      });
    },

    getReferencia() {
      return fetchClienteReferencias(this.cliente_id)
        .then((response) => {
          this.referencia_original = response;
          if (this.referencia_original.length > 0) {
            this.referencia = this.referencia_original[0];
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    adicionarEnderecoCliente() {
      this.enderecoEditar = {};
      this.edit = false;
      this.dialogEnderecos = true;
    },
    editarEnderecoCliente(item) {
      this.enderecoEditar = { ...item };
      this.edit = true;
      this.dialogEnderecos = true;
    },

    updateClienteDadosVenda() {
      if (
        this.tipo_venda === "PISCINA" &&
        this.$refs.form.validate() &&
        this.$refs.formReferenciaCliente.validate()
      ) {
        this.loading = true;
        let cliente = {};
        let cliente_2 = {};
        cliente_2.doc_ci = this.cliente.doc_ci;
        cliente_2.nome = this.cliente.nome;
        cliente_2.doc_ruc = this.cliente.doc_ruc;
        cliente_2.celular = this.cliente.celular;
        cliente_2.telefone = this.cliente.telefone;
        cliente_2.local_trabalho = this.cliente.local_trabalho;
        cliente_2.credito = this.cliente.credito;
        for (let key in cliente_2) {
          if (
            cliente_2[key] !== null &&
            cliente_2[key] !== undefined &&
            cliente_2[key] !== ""
          ) {
            cliente[key] = cliente_2[key];
          }
        }

        let referencia = {};
        let referencia_2 = {};
        referencia_2.cliente_id = this.cliente_id;
        referencia_2.nome_conjugue = this.referencia.nome_conjugue;
        referencia_2.num_cedula_conjugue = this.referencia.num_cedula_conjugue;
        referencia_2.tel_conjugue = this.referencia.tel_conjugue;
        referencia_2.end_trabalho = this.referencia.end_trabalho;
        referencia_2.end_conjugue = this.referencia.end_conjugue;
        for (let key in referencia_2) {
          if (
            referencia_2[key] !== null &&
            referencia_2[key] !== undefined &&
            referencia_2[key] !== ""
          ) {
            referencia[key] = referencia_2[key];
          }
        }
        postClienteReferencias(referencia).catch((error) => {
          console.log(error.response);
        });

        putCliente(this.cliente_id, cliente)
          .then((response) => {
            this.closeDialog();
          })
          .catch((error) => {
            this.$toast.error("documento existente");
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.tipo_venda === "SIMPLES" && this.$refs.form.validate()) {
        this.loading = true;

        let cliente = {};

        let cliente_2 = {};
        cliente_2.doc_ci = this.cliente.doc_ci;
        cliente_2.nome = this.cliente.nome;
        cliente_2.doc_ruc = this.cliente.doc_ruc;
        cliente_2.celular = this.cliente.celular;
        cliente_2.telefone = this.cliente.telefone;
        cliente_2.local_trabalho = this.cliente.local_trabalho;
        cliente_2.credito = this.cliente.credito;

        for (let key in cliente_2) {
          if (
            cliente_2[key] !== null &&
            cliente_2[key] !== undefined &&
            cliente_2[key] !== ""
          ) {
            cliente[key] = cliente_2[key];
          }
        }

        putCliente(this.cliente_id, cliente)
          .then((response) => {
            this.closeDialog();
          })
          .catch((error) => {
            this.$toast.error("documento existente");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    closeDialog() {
      if (this.selectedEndereco) {
        putEnderecoFavorito(this.selectedEndereco.id, {
          cliente_id: this.cliente_id,
        });
        this.$emit("update:selectedEndereco", this.selectedEndereco.rua);
      }
      this.$emit("update:dialogHistoricoCliente", false);
    },
  },

  async mounted() {
    await this.fetchHistoricoVendas();
    await this.fetchCliente();
    await this.fetchClienteEnderecos();
    if (this.tipo_venda === "PISCINA") {
      await this.getReferencia();
    }
    
  },
};
</script>

<style></style>
