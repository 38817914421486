<template>
  <v-dialog
    v-model="DialogAddCheque"
    @click:outside="$emit('update:DialogAddCheque', false)"
    @keydown.esc="$emit('update:DialogAddCheque', false)"
    max-width="1000px"
    scrollable
  >
    <v-card flat :loading="loading">
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{
          editar
            ? $tc("global.editar") + " " + $tc("global.cheque")
            : $tc("global.add") + " " + $tc("global.cheque")
        }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="editar && cheque.status == 0"
          @click="allowEditar = !allowEditar"
          icon
          class="mr-2"
        >
          <v-icon>{{ allowEditar ? "mdi-check" : "mdi-pencil" }}</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('update:DialogAddCheque', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs v-model="tab">
        <v-tab>Cheque</v-tab>
        <v-tab v-if="editar">Midias</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-form
                @submit.prevent
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <v-row dense>
                  <v-col cols="12" md="3">
                    <v-combobox
                      v-model="selectedBanco"
                      :items="bancos"
                      :label="$tc('global.banco', 2)"
                      dense
                      outlined
                      :rules="formRules"
                      :loading="loadingBancos"
                      required
                      :disabled="!allowEditar"
                      item-text="descricao"
                      item-value="id"
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="cheque.conta"
                      :rules="formRules"
                      :label="$tc('global.conta')"
                      dense
                      :disabled="!allowEditar"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="cheque.numero"
                      :label="$tc('global.numero') + '-' + $tc('global.cheque')"
                      :rules="formRules"
                      :disabled="!allowEditar"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="selectedTipo"
                      :label="$tc('global.tipo')"
                      :items="tipos"
                      dense
                      :disabled="chequeNaoManual || !allowEditar"
                      :rules="formRules"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="cheque.emissor_nome"
                      :label="$tc('global.emitidoPor')"
                      :rules="formRules"
                      dense
                      :disabled="!allowEditar"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="cheque.emissor_ci"
                      :label="
                        $tc('global.documento') + ' ' + $tc('global.emissor')
                      "
                      :rules="formRules"
                      :disabled="!allowEditar"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-combobox
                      v-model="selectedMoeda"
                      :label="$tc('global.moeda', 2)"
                      :items="computedMoedas"
                      :rules="formRules"
                      :disabled="chequeNaoManual || !allowEditar"
                      item-text="sigla"
                      item-value="id"
                      dense
                      outlined
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="valor"
                      ref="valor"
                      :label="$tc('global.valor')"
                      :disabled="
                        !selectedMoeda || chequeNaoManual || !allowEditar
                          ? true
                          : false
                      "
                      :rules="formRules"
                      dense
                      outlined
                      v-currency="vCurrencyOptions"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <DataField
                      hide_details
                      :disabled="selectedTipo == 'A VISTA'"
                      :data_sync.sync="data_emissao"
                      :label="$tc('global.data-emissao')"
                      :obrigatorio="formRules ? true : false"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <DataField
                      hide_details
                      :disabled="!allowEditar"
                      :data_sync.sync="cheque.data_vencimento"
                      :label="$tc('global.data-vencimento')"
                      :obrigatorio="formRules ? true : false"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-col cols="12" v-if="!editar">
                <v-card flat class="mb-5">
                  <v-file-input
                    suffix="Max 100MB"
                    :messages="
                      $tc('global.formatosPermitidos') +
                      ' : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt.'
                    "
                    v-model="files"
                    :placeholder="$tc('global.anexos')"
                    accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
                    multiple
                    class="mb-2 mx-2"
                    prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        small
                        label
                        close
                        close-icon="mdi-delete"
                        color="primary"
                        @click:close="remove(text)"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-card>
              </v-col>

              <div v-if="editar && chequePai">
                <div class="my-4 text-subtitle-1">
                  {{ $tc("global.cheque") + " " + $tc("global.devolucao") }}
                </div>
                <v-card
                  flat
                  v-if="editar && chequePai"
                  class="d-flex justify-space-between"
                >
                  <v-text-field
                    v-model="chequePai.numero"
                    :label="$tc('global.numero') + '-' + $tc('global.cheque')"
                    hide-details
                    readonly
                    outlined
                    dense
                  ></v-text-field>

                  <v-text-field
                    class="mx-2"
                    v-model="chequePai.valor"
                    :label="$tc('global.cheque') + '-' + $tc('global.valor')"
                    hide-details
                    readonly
                    v-currency="vCurrencyOptions"
                    outlined
                    dense
                  ></v-text-field>

                  <v-textarea
                    v-model="chequePai.motivo_devolucao"
                    :label="
                      $tc('global.motivo') + ' ' + $tc('global.devolucao')
                    "
                    hide-details
                    readonly
                    outlined
                    rows="1"
                    dense
                  ></v-textarea>
                </v-card>
              </div>

              <div v-if="hasFilhos">
                <div class="py-4 text-subtitle-1">
                  {{
                    $tc("global.cheque", 2) + " " + $tc("global.substituto", 2)
                  }}
                </div>
                <v-data-table
                  :headers="headers"
                  :items="cheque.cheques_filhos"
                  :items-per-page="10"
                  hide-default-footer
                  item-key="id"
                  class="data-tables data-tables__row-click pt-4"
                >
                  <template v-slot:item.data_emissao="{ item }">
                    {{ item.data_emissao | dateFormat("dd/MM/yyyy") }}
                  </template>
                  <template v-slot:item.data_vencimento="{ item }">
                    {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
                  </template>
                  <template v-slot:item.valor="{ item }">
                    <span v-if="item.moeda_sigla === 'G$'">
                      {{ item.valor | guarani }}
                    </span>
                    <span v-else> {{ item.valor | currency }} </span>
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <InternFileManager
            :disabled="allowEditar"
            v-if="editar"
            origem="cheques"
            :origem_id="cheque_id"
          />
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="cheque.status !== 1"
          @click="editar ? editarCheque() : novoCheque()"
          :disabled="enviarChequeDisabled"
          :loading="loading"
          color="button_2"
          class="white--text"
          depressed
          small
        >
          {{ editar ? $tc("global.editar") : $tc("global.add") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { postCheque, fetchCheque, putCheque } from "@/api/cheques/cheques.js";
import { mapState } from "vuex";
import { fetchBancos } from "@/api/bancos/bancos.js";
import { fetchAllPaises } from "@/api/cidades/paises";
import { format, parseISO } from "date-fns";

export default {
  name: "DialogAddCheque",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
  },

  props: {
    DialogAddCheque: {
      type: Boolean,
      default: false,
    },
    editar: {
      type: Boolean,
      default: false,
    },
    cheque_id: {
      type: Number,
      default: null,
    },
    origem: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      cheque: {},
      tipos: ["A VISTA", "DIFERIDO"],
      valor: 0,
      bancos: [],
      files: [],
      validForm: true,
      loading: false,
      formRules: [inputRequired],
      loadingBancos: true,
      selectedMoeda: null,
      selectedBanco: null,
      cheque_original: {},
      selectedTipo: null,
      data_emissao: null,
      allowEditar: this.editar ? false : true,
      chequePai: null,
      tab: 0,
      hasFilhos: false,
    };
  },

  watch: {
    valor() {
      if (this.valor && this.cheque.valor != this.valor) {
        this.cheque.valor = this.$ci.parse(this.valor, this.vCurrencyOptions);
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.cheque.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedBanco() {
      if (this.selectedBanco) {
        this.cheque.banco_id = this.selectedBanco.id;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.cheque.tipo = this.selectedTipo;
        if (this.selectedTipo == "A VISTA" && !this.editar) {
          this.data_emissao = format(new Date(), "yyyy-MM-dd");
        }
      }
    },
    data_emissao() {
      if (this.data_emissao) {
        this.cheque.data_emissao = this.data_emissao;
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "center",
          value: "id",

          sortable: false,
        },
        {
          text: this.$tc("global.data-emissao"),
          align: "center",
          value: "data_emissao",
          // sortable: false,
        },
        {
          text: this.$tc("global.vencimento"),
          align: "center",
          value: "data_vencimento",
          // sortable: false,
        },
        {
          text: this.$tc("global.origem"),
          value: "origem",
          // sortable: false,
        },

        {
          text: this.$tc("global.banco"),
          value: "banco_descricao",
          sortable: false,
        },

        {
          text: this.$tc("global.numero"),
          value: "numero",
          sortable: false,
        },

        {
          text: this.$tc("global.tipo"),
          align: "right",
          value: "tipo",
          sortable: false,
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          align: "right",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
          sortable: false,
        },
      ];
    },
    computedMoedas() {
      let disabledItems = [1];
      return this.moedas.map((item) => {
        return {
          descricao: item.descricao,
          id_moeda: item.id_moeda,
          sigla: item.sigla,
          status: item.status,
          disabled: disabledItems.includes(item.id_moeda),
        };
      });
    },
    disableCheque() {
      let disable = false;
      if (this.cheque) {
        if (this.cheque.status === 1) {
          disable = true;
        } else if (this.cheque.status === 2) {
          disable = true;
        }
      }
      return disable;
    },
    chequeNaoManual() {
      let disable = false;

      if (this.cheque.origem != "MANUAL") {
        disable = true;
      }

      return disable;
    },

    vCurrencyOptions() {
      return {
        precision:
          this.selectedMoeda && this.selectedMoeda.sigla == "G$"
            ? 0
            : undefined,

        distractionFree: false,
      };
    },
    enviarChequeDisabled() {
      let result = false;

      if (!this.validForm || this.disableCheque) {
        result = true;
      }

      if (this.editar && !this.allowEditar) {
        result = true;
      }

      return result;
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      usuario: (state) => state.usuario,
    }),
  },

  methods: {
    novoCheque() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let block = false;

        if (this.files.length) {
          this.files.forEach((file) => {
            if (file.size >= 104857600) {
              this.$toast.error(
                "O tamanho maximo do arquivo permitido é 100MB"
              );
              block = true;
              return;
            } else if (
              file.type.substr(-5) != "/jpeg" &&
              file.type.substr(-4) != "/png" &&
              file.type.substr(-4) != "/txt" &&
              file.type.substr(-4) != "/xls" &&
              file.type.substr(-6) != ".sheet" &&
              file.type.substr(-4) != "/pdf" &&
              file.type.substr(-4) != "/doc" &&
              file.type.substr(-5) != ".text" &&
              file.type.substr(-9) != ".document"
            ) {
              this.$toast.error("Formato não permitido");
              block = true;
              return;
            }
          });
        }

        if (!block) {
          const cheque = new FormData();

          let cheque2 = {};

          cheque2.banco_id = this.cheque.banco_id;
          cheque2.conta = this.cheque.conta;
          cheque2.numero = this.cheque.numero;
          cheque2.tipo = this.cheque.tipo;
          cheque2.emissor_nome = this.cheque.emissor_nome;
          cheque2.emissor_ci = this.cheque.emissor_ci;
          cheque2.data_emissao = this.cheque.data_emissao;
          cheque2.data_vencimento = this.cheque.data_vencimento;
          cheque2.moeda_id = this.cheque.moeda_id;
          cheque2.valor = this.cheque.valor;
          cheque2.origem = this.cheque.origem;
          cheque2.origem_id = 0;
          cheque2.user_id_criacao = this.usuario.id;

          for (let key in cheque2) {
            if (
              cheque2[key] !== null &&
              cheque2[key] !== undefined &&
              cheque2[key] !== ""
            ) {
              cheque.append(key, cheque2[key]);
            }
          }

          if (this.files.length) {
            for (var i = 0; i < this.files.length; i++) {
              let files = this.files[i];
              cheque.append("files[" + i + "]", files);
            }
          }

          postCheque(cheque)
            .then((response) => {
              if (response.status === 201) {
                this.$emit("fetch-cheques");
                this.$emit("update:DialogAddCheque", false);
              }
            })
            .catch((error) => {
              this.$toast.error("Este número de cheque bancario ya existe");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    editarCheque() {
      if (this.$refs.form.validate()) {
        if (
          JSON.stringify(this.cheque) == JSON.stringify(this.cheque_original)
        ) {
          this.$toast.error("Por favor, faça uma mudança antes de atualizar!");
          this.loading = false;
        } else {
          this.loading = true;
          let cheque2 = {};
          cheque2.id = this.cheque.id;
          cheque2.banco_id = this.cheque.banco_id;
          cheque2.conta = this.cheque.conta;
          cheque2.numero = this.cheque.numero;
          cheque2.tipo = this.cheque.tipo;
          cheque2.emissor_nome = this.cheque.emissor_nome;
          cheque2.emissor_ci = this.cheque.emissor_ci;
          cheque2.data_vencimento = this.cheque.data_vencimento;
          if (this.cheque.origem === "MANUAL") {
            cheque2.data_emissao = this.cheque.data_emissao;
            cheque2.moeda_id = this.cheque.moeda_id;
            cheque2.valor = this.cheque.valor;
          }
          cheque2.user_id_criacao = this.usuario.id;

          putCheque(cheque2.id, cheque2)
            .then((response) => {
              if (response.status === 200) {
                this.$emit("fetch-cheques");
                this.$emit("update:DialogAddCheque", false);
              }
            })
            .catch((error) => {
              this.$toast.error("Este número de cheque bancario ya existe");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    getBancos() {
      this.loadingBancos = true;
      return fetchBancos()
        .then((response) => {
          this.bancos = response;
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingBancos = false;
        });
    },

    getCheque() {
      if (this.cheque_id) {
        this.loading = true;
        return fetchCheque(this.cheque_id)
          .then((response) => {
            this.cheque = { ...response };
            this.cheque_original = { ...response };
            this.selectedMoeda = this.moedas.find(
              (moeda) => moeda.id_moeda === this.cheque.moeda_id
            );
            this.selectedBanco = this.bancos.find(
              (banco) => banco.id === this.cheque.banco_id
            );
            this.$ci.setValue(this.$refs.valor, this.cheque.valor);
            if (this.cheque.cheques_filhos.length) {
              this.hasFilhos = true;
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  created() {
    if (this.origem && !this.editar) {
      this.cheque.origem = this.origem;
    }
  },

  async mounted() {
    await this.getBancos();

    if (this.editar) {
      await this.getCheque();
      this.selectedTipo = this.cheque.tipo;
      this.data_emissao = this.cheque.data_emissao;
      if (this.cheque.cheque_pai_id) {
        this.chequePai = await fetchCheque(this.cheque.cheque_pai_id);
      }
    }
  },
};
</script>

<style></style>
