import api from "../axios_service.js";

export async function fetchEmpresasUser(id, filtros) {
  const response = await api.get(
    `/empresas-users-admin/${id}${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchEmpresasUserNotEqual(id, filtros) {
  const response = await api.get(
    `/empresas-users-notequal/${id}${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function postUserEmpresa(userEmpresa) {
  return api.post("/empresas-users/add", userEmpresa);
}

export function deleteUserEmpresa(id) {
  return api.delete(`/empresas-users/${id}`);
}
