import api from "../axios_service.js";

const devolverVenda = async (body) => {
  return await api.post(`/devolver-venda`, body);
};
const devolverCompra = async (body) => {
  return api.post(`/devolver-compra`, body);
};

export { devolverCompra, devolverVenda };
