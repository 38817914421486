<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col>Pagina nao encontrada!</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PaginaNaoEncontrada",
};
</script>

<style></style>
