<template>
  <v-dialog
    v-model="dialogFormasPagamento"
    @click:outside="$emit('update:dialogFormasPagamento', false)"
    @keydown.esc="$emit('update:dialogFormasPagamento', false)"
    width="650px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.novo") + " " + $tc("global.formapagamento")
            : $tc("global.atualizar") + " " + $tc("global.formapagamento")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogFormasPagamento', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-container fluid class="py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="forma_pagamento.descricao"
                :label="$tc('global.descricao')"
                dense
                outlined
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pb-6">
              <v-card
                v-if="edit"
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Status
                  <span class="pl-1" v-if="forma_pagamento.status === 1">
                    {{ $tc("global.ativo") }}
                  </span>
                  <span class="pl-1" v-else>{{ $tc("global.inativo") }}</span>
                </v-subheader>
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  v-model="forma_pagamento.status"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogFormasPagamento', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createFormaPagamento() : updateFormaPagamento()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  postFormasPagamento,
  putFormasPagamento,
} from "@/api/formas-pagamento/formas_pagamento.js";

export default {
  name: "DialogFormasPagamento",

  props: {
    dialogFormasPagamento: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      forma_pagamento: {
        status: 1,
      },
      forma_pagamento_original: {},
      loading: true,
      validForm: true,
      hoverFile: false,
      formRules: [inputRequired],
    };
  },

  methods: {
    createFormaPagamento() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        postFormasPagamento(this.forma_pagamento)
          .then(() => {
            this.$emit("fetch-formas-pagamentos");
            this.$emit("update:dialogFormasPagamento", false);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error("Forma de pagamento já existe!");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateFormaPagamento() {
      if (
        JSON.stringify(this.forma_pagamento) ===
        JSON.stringify(this.forma_pagamento_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let forma_pagamento = {};

        forma_pagamento.descricao = this.forma_pagamento.descricao;
        forma_pagamento.status = this.forma_pagamento.status;

        putFormasPagamento(this.forma_pagamento.id, forma_pagamento)
          .then(() => {
            this.forma_pagamento_original = { ...this.forma_pagamento };
            this.$emit("fetch-formas-pagamentos");
            this.$emit("update:dialogFormasPagamento", false);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error("Forma de pagamento já existe!");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    if (this.edit) {
      this.forma_pagamento = { ...this.item };
      this.forma_pagamento_original = { ...this.item };
    }
  },
};
</script>

<style></style>
