import api from "../axios_service.js";

export async function fetchOrcamentoItems(orcamento_id) {
  const response = await api.get(
    `/orcamento-items?orcamento_id=${orcamento_id}`
  );
  return response.data.result;
}

// export function deleteVendaItem(id) {
//   return api.delete(`/vendas-items/${id}`);
// }
