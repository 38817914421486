<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
          {{ $tc("global.deposito", 2) }}
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogUserDeposito = !dialogUserDeposito"
            depressed
            fab
            x-small
            class="white--text"
            color="button_1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider v-if="usersDepositos && usersDepositos.length"></v-divider>
        <v-card-text
          v-if="usersDepositos && usersDepositos.length"
          class="py-4"
        >
          <v-chip
            v-for="(deposito, index) in usersDepositos"
            :key="index"
            class="ma-1"
            label
          >
            <v-icon
              size="20"
              :color="deposito.principal ? 'anchors' : ''"
              @click="depositoPrincipal(deposito.id)"
              left
              >mdi-star</v-icon
            >
            {{ deposito.deposito }}

            <v-icon
              v-if="!deposito.principal"
              right
              size="20"
              @click="delUserDeposito(deposito.id)"
              left
            >
              mdi-delete
            </v-icon>
          </v-chip>
        </v-card-text>

        <v-dialog v-model="dialogUserDeposito" width="100%" max-width="400">
          <v-card
            :loading="loadingUserDeposito"
            :disabled="loadingUserDeposito"
          >
            <v-card-title class="subtitle-1 lightgray">
              {{ $tc("global.add") + " " + $tc("global.deposito", 2) }}
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogUserDeposito = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-10">
              <v-select
                v-model="userDeposito.deposito_id"
                :items="depositoNotEqual"
                clearable
                item-text="descricao"
                item-value="id"
                filled
                dense
                hide-details
                :label="$tc('global.deposito', 2)"
              ></v-select>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!userDeposito.deposito_id"
                color="button_1"
                class="white--text"
                depressed
                small
                @click="criarUserDeposito"
              >
                {{ $tc("global.add") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import {
  fetchUsersDeposito,
  postUsersDepositos,
  deleteUsersDeposito,
  setDepositoPrincipal,
} from "@/api/usuarios/usuarios.js";
import { fetchDepositoNotEqual } from "@/api/produtos/produtos_depositos.js";

export default {
  name: "DepositoUsuario",

  props: {
    user_id: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      loadingUserDeposito: false,
      dialogUserDeposito: false,
      depositoNotEqual: [],
      usersDepositos: [],
      userDeposito: {
        deposito_id: null,
      },
    };
  },

  methods: {
    getTipoDepositoNotEqual() {
      fetchDepositoNotEqual(this.user_id)
        .then((response) => {
          this.depositoNotEqual = response;
        })
        .catch((error) => {});
    },
    getUsersDepositos() {
      fetchUsersDeposito(`?users_id=${this.user_id}`)
        .then((response) => {
          this.usersDepositos = response;
        })
        .catch((error) => {});
    },

    criarUserDeposito() {
      this.userDeposito.users_id = this.user_id;
      this.loadingUserDeposito = true;

      postUsersDepositos(this.userDeposito)
        .then((response) => {
          if (response.status === 201) {
            this.userDeposito = {};
            this.getUsersDepositos();
            this.getTipoDepositoNotEqual();
            this.dialogUserDeposito = false;
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingUserDeposito = false;
        });
    },
    delUserDeposito(id) {
      deleteUsersDeposito(id)
        .then((response) => {
          if (response.status === 200) {
            this.getUsersDepositos();
            this.getTipoDepositoNotEqual();
          }
        })
        .catch((error) => {});
    },

    depositoPrincipal(deposito_id) {
      let deposito_principal = {};

      deposito_principal.deposito_id = deposito_id;
      deposito_principal.user_id = this.user_id;
      setDepositoPrincipal(deposito_principal)
        .then((response) => {
          if (response.status === 200) {
            this.getUsersDepositos();
            this.$toast.success(this.$tc("global.depositoPrincipalAlterado"));
            this.getTipoDepositoNotEqual();
          }
        })
        .catch(() => {});
    },
  },

  mounted() {
    this.getTipoDepositoNotEqual();
    this.getUsersDepositos();
  },
};
</script>

<style></style>
