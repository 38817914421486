import api from "../axios_service.js";

export async function getFornecedoresTipos(filtros) {
    const response = await api.get(
        `/fornecedores-tipos${filtros ? filtros : ""}`
    );
    return response.data.result;
}

export async function getFornecedoresTiposAtivo(filtros) {
    const response = await api.get(
        `/fornecedores-tipos/ativo${filtros ? filtros : ""}`
    );
    return response.data.result;
}

// Busca um tipo de fornecedor
export async function getFornecedorTipos(id) {
    const response = await api.get(`/fornecedores-tipos/${id}`);
    return response.data.result;
}

// Atualiza um tipo existente
export function putFornecedoresTipos(id, body) {
    return api.put(`/fornecedores-tipos/${id}`, body);
}

// Cria um tipo novo
export function postFornecedorTipo(body) {
    return api.post("/fornecedores-tipos", body);
}
