<template>
  <v-container fluid>
    <v-card outlined class="mt-2 mx-4" :disabled="disabled">
      <v-card-title class="text-body-1 px-3 py-2 lightgray">
        <span class="pl-5 text-h6"> {{ this.$tc("global.midia", 2) }} </span>
        <v-spacer></v-spacer>

        <v-btn
          align="center"
          color="button_1"
          class="white--text"
          small
          @click="onButtonClick"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
          <input
            multiple
            type="file"
            accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
            class="d-none"
            ref="uploader"
            @change="createFiles"
          />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pa-0" :class="classe_tabela">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="files"
          :items-per-page="10"
          :search="search.length >= 3 ? search : ''"
          class="data-tables"
        >
          <template v-slot:item.nome="{ item }">
            {{ item.nome | excerpt(25) }}
          </template>
          <template v-slot:item.path="{ item }">
            <v-card flat max-width="50px" class="mx-auto pa-1">
              <v-img
                :src="item.url"
                max-height="30px"
                cover
                v-if="isImage(item.tipo)"
              >
              </v-img>
              <v-icon v-if="item.tipo === 'pdf'" size="30">
                mdi-file-pdf
              </v-icon>
              <v-icon
                v-if="item.tipo === 'xlsx' || item.tipo === 'xls'"
                size="30"
              >
                mdi-file-excel
              </v-icon>
              <v-icon
                v-if="
                  item.tipo === 'txt' ||
                  item.tipo === 'odt' ||
                  item.tipo === 'doc' ||
                  item.tipo === 'docx'
                "
                size="30"
              >
                mdi-file-word
              </v-icon>
              <v-icon
                v-if="item.tipo === '/mp4' || item.tipo === '/mp3'"
                size="30"
              >
                mdi-file-video-outline
              </v-icon>
            </v-card>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <template v-if="isImage(item.tipo)">
              <v-btn icon small @click.stop="openFull(item.url)">
                <v-icon>mdi-fullscreen</v-icon>
              </v-btn>
            </template>
            <template v-if="!isImage(item.tipo)">
              <v-btn icon small @click.stop="openFull(item.url)">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>

            <v-btn icon small @click.stop="delFile(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-card-text class="d-flex px-3 ma-0 text-subtitle-2">
          <div>
            {{ $tc("global.formatosPermitidos") }}: .jpg, .png, .txt, .xls,
            .xlsx, .pdf, .doc, .docx, .odt
          </div>
          <v-spacer></v-spacer>
          <div>Max 5MB</div>
        </v-card-text>
      </v-card-text>

      <v-dialog
        v-if="editFileDialog"
        v-model="editFileDialog"
        @click:outside="$emit('update:editFileDialog', false)"
        @keydown.esc="$emit('update:editFileDialog', false)"
        scrollable
        max-width="400px"
        width="100%"
      >
        <v-card>
          <v-card-title class="accent text-h5 font-weight-bold pa-3">
            Editar nome do arquivo
            <v-spacer></v-spacer>
            <v-btn icon @click="editFileDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-6">
            <v-text-field v-model="textName" label="Nome"></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4">
            <v-btn @click="editFileDialog = false">
              {{ this.$tc("global.voltar") }}
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn color="secondary" class="ml-3" @click="updateFile">{{
              this.$tc("global.salvar")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import {
  filterFiles,
  deleteMidia,
  uploadFiles,
  putFile,
} from "../../api/midias/midias.js";
export default {
  name: "InternFileManager",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Midias",
    },
    origem: {
      type: String,
    },
    origem_id: {
      type: Number,
    },
    classe_tabela: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      search: "",
      textName: "",
      files: [],
      file: null,
      editFileDialog: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          align: "center",
          width: "150px",
          value: "path",
          sortable: false,
        },
        {
          text: this.$tc("global.nome"),
          align: "start",
          value: "nome",
          divider: true,
        },
        {
          text: this.$tc("global.tipo"),
          align: "center",
          width: "150px",
          sortable: false,
          value: "tipo",
          divider: true,
        },

        {
          text: this.$tc("global.adicionado"),
          width: "150px",
          sortable: false,
          value: "created_at",
          divider: true,
        },
        {
          text: this.$tc("global.usuario"),
          value: "criador",
          width: "150px",
          align: "center",
          sortable: false,
          divider: true,
        },
        {
          value: "actions",
          sortable: false,
          align: "center",
          width: "100px",
          divider: true,
        },
      ];
    },
  },
  methods: {
    openFull(url) {
      window.open(url, "_blank");
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    updateFile() {
      if (this.textName.length) {
        this.loading = true;
        let file = {};
        file = { ...this.file };
        delete file.criador;
        file.nome = this.textName;

        putFile(file, file.id).then((response) => {
          if (response.status === 201) {
            this.getFiles();

            this.editFileDialog = false;
            this.loading = false;
          }
        });
      } else {
        this.$toast.error("Não permitido!!!");
      }
    },

    delFile(id) {
      deleteMidia(id)
        .then((response) => {
          if (response.status === 200) {
            this.getFiles();
          }
        })
        .catch(() => {});
    },
    createFiles(event) {
      // if (this.$refs.form.validate()) {
      let block = false;
      Array.from(event.target.files).forEach((file) => {
        if (file.size >= 5242888) {
          this.$toast.error("O tamanho maximo do arquivo permitido é 5Mb");
          block = true;
          return;
        } else if (
          file.type.substr(-5) != "/jpeg" &&
          file.type.substr(-4) != "/png" &&
          file.type.substr(-4) != "/txt" &&
          file.type.substr(-4) != "/xls" &&
          file.type.substr(-6) != ".sheet" &&
          file.type.substr(-4) != "/pdf" &&
          file.type.substr(-4) != "/doc" &&
          file.type.substr(-5) != ".text" &&
          file.type.substr(-9) != ".document"
        ) {
          this.$toast.error("Formato não permitido");
          block = true;
          return;
        }
      });

      if (block === false) {
        this.loading = true;
        this.file = event.target.files;
        const file = new FormData();
        file.append("origem", this.origem);
        file.append("origem_id", this.origem_id);

        if (this.file) {
          for (var i = 0; i < this.file.length; i++) {
            let files = this.file[i];
            file.append("files[" + i + "]", files);
          }
        }

        uploadFiles(file)
          .then((response) => {
            if (response.status === 201) {
              this.file = null;
              this.getFiles();
              this.loading = false;
            }
          })
          .finally(() => {
            this.loading = false;
            this.file = null;
          });
        // }
      } else {
        this.file = null;
        this.loading = false;
      }
    },
    getFiles() {
      this.loading = true;

      if (this.origem && this.origem_id) {
        return filterFiles(`?origem=${this.origem}&origem_id=${this.origem_id}`)
          .then((response) => {
            this.files = response;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    isImage(tipo) {
      if (
        tipo === "png" ||
        tipo === "jpg" ||
        tipo === "jpeg" ||
        tipo === "img"
      ) {
        return true;
      } else {
        return false;
      }
    },
    fileEdit(file) {
      this.file = file;
      this.textName = "";
      this.editFileDialog = true;
    },
  },
  async mounted() {
    await this.getFiles();
  },
};
</script>

<style lang="scss"></style>
