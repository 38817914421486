<template>
  <v-select
    v-model="plano_contas"
    :items="planos_contas"
    :loading="loading"
    clearable
    outlined
    dense
    hide-details
    :label="$tc('global.planocontas')"
    item-text="descricao"
    item-value="id"
    :disabled="disabled"
    @click:clear="clearPlanoContas"
    @change="updatePlanoContas"
  ></v-select>
</template>

<script>
import { fetchPlanosContas } from "@/api/plano-contas/plano_contas.js";
import { mapState } from "vuex";

export default {
  name: "PlanoContas",

  data() {
    return {
      plano_contas: null,
      disabled: false,
      planos_contas: [],
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  methods: {
    updatePlanoContas() {
      if (this.plano_contas) {
        this.$store.commit("FiltrosRelatorios/UPDATE_PLANO_CONTAS", this.plano_contas);
      }
    },

    clearPlanoContas() {
      this.$store.commit("FiltrosRelatorios/UPDATE_PLANO_CONTAS", null);
      this.plano_contas = null;
    },
  },

  async created() {
    try {
      this.loading = true;
      this.planos_contas = await fetchPlanosContas();
      this.filtros_componente.find((item) => {
        if (item.text == "PlanoContas") {
          this.planos_contas.find((plano_contas) => {
            if (plano_contas.id == item.default) {
              this.plano_contas = plano_contas;
            }
          });
          if (item.disabled) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
          this.updatePlanoContas();
        }
      });

      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
