<template>
  <v-dialog
    v-model="dialogSearchProdutos"
    @click:outside="$emit('update:dialogSearchProdutos', false)"
    @keydown.esc="$emit('update:dialogSearchProdutos', false)"
    width="1200px"
    scrollable
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        Buscar Produto
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogSearchProdutos', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-6">
        <v-text-field
          v-model="search"
          :loading="loadingSearch"
          dense
          outlined
          ref="search"
          style="min-width: 350px"
          class="mb-3"
          :error="error"
          clearable
          hide-details
          autofocus
          @click:clear="clearSearch"
          v-bind="$attrs"
          v-on="$listeners"
        >
        </v-text-field>

        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="produtos"
            :options.sync="options"
            :item-key="'id' + '-' + 'deposito'"
            :server-items-length="totalProdutos"
            class="data-tables"
            :item-class="row_classes"
            @click:row="selectProduto"
          >
            <template v-slot:item.preco="{ item }">
              <div
                v-if="item.moeda == 'G$'"
                class="d-flex align-center justify-space-between"
              >
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco | guarani }}</div>
              </div>
              <div v-else class="d-flex align-center justify-space-between">
                <div>{{ item.moeda }}</div>
                <div>{{ item.preco | currency }}</div>
              </div>
            </template>

            <template v-slot:item.ultimo_custo="{ item }">
              <div
                v-if="item.moeda == 'G$'"
                class="d-flex align-center justify-space-between"
              >
                <div>G$</div>
                <div>{{ item.ultimo_custo | guarani }}</div>
              </div>
              <div v-else class="d-flex align-center justify-space-between">
                <div>G$</div>
                <div>{{ item.ultimo_custo | currency }}</div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { buscaProdutosDialog } from "@/api/produtos/produtos.js";

export default {
  name: "DialogSearchProdutos",

  props: {
    dialogSearchProdutos: {
      type: Boolean,
      required: true,
    },
    lista_preco_id: {
      type: [Number, String],
    },
    moeda_id: {
      type: [Number, String],
    },
    deposito_id: {
      type: [Number, String],
    },
    typeComp: {
      type: String,
      default: "venda",
    },
    produto_sync: {
      type: Object,
    },
    tipo: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      produtos: [],
      totalProdutos: 0,
      options: {
        sortBy: ["disponivel"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15,
      },
      search: null,
      loadingSearch: false,
      error: false,
      produto: {
        id: null,
        descricao: null,
      },
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "id",
          value: "id",
          align: "center",
          width: "70px",
          divider: true,
        },
        {
          text: "referencia",
          value: "referencia",
          align: "center",
          width: "125px",
          divider: true,
        },
        {
          text: "descripcion",
          value: "descricao",
          divider: true,
        },
        {
          text: "disponivel",
          value: "disponivel",
          align: "center",
          width: "120px",
          divider: true,
        },
        {
          text: "Venta reservada",
          value: "reservado_venda",
          align: "center",
          width: "120px",
          divider: true,
        },
        {
          text: "Transferencia reservada",
          value: "reservado_transferencia",
          align: "center",
          width: "120px",
          divider: true,
        },
        {
          text: "deposito",
          value: "deposito",
          align: this.typeComp == "busca_deposito" ? " d-none" : "center",
          width: "120px",
          divider: true,
        },
        {
          text: "preco",
          value: "preco",
          divider: true,
          width: "120px",
          align: this.typeComp == "venda" ? "" : " d-none",
        },
        {
          text: "ultimo custo",
          value: "ultimo_custo",
          divider: true,
          width: "120px",
          align: this.typeComp == "compra" ? "" : " d-none",
        },
        {
          text: "marca",
          value: "marca",
          width: "120px",
          divider: true,
        },
        // {
        //   text: "categoria",
        //   value: "categoria",
        //   width: "140px",
        //   divider: true,
        // },
        // {
        //   text: "fornecedor",
        //   value: "fornecedor",
        //   width: "140px",
        // },
      ];
    },
  },

  watch: {
    options: {
      handler() {
        this.getProdutos();
      },
      deep: true,
    },

    search() {
      if (!this.search || this.loading) return;
      this.buscaProduto();
    },
  },

  methods: {
    buscaProduto() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.getProdutos();
      }, 500);
    },
    clearSearch() {
      this.search = null;
      this.produtos = [];
      (this.produto = {
        id: null,
        descricao: null,
      }),
        (this.error = false);
      this.getProdutos();
    },
    async getProdutos() {
      try {
        this.loadingSearch = true;
        this.produtos = [];
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let filtro = `?page=${page}`;
        filtro += `&sortBy=${sortBy}`;
        filtro += `&sortDesc=${sortDesc}`;
        filtro += `&itemsPerPage=${itemsPerPage}`;
        filtro += this.search ? `&search=${this.search}` : "";
        filtro += this.tipo ? `&tipo=${this.tipo}` : "";

        filtro += this.lista_preco_id
          ? `&lista_preco_id=${this.lista_preco_id}`
          : "";
        filtro += this.moeda_id ? `&moeda_id=${this.moeda_id}` : "";
        filtro += this.deposito_id ? `&deposito_id=${this.deposito_id}` : "";

        const response = await buscaProdutosDialog(filtro);

        response.data[0].disabled = true;
        this.produtos = response.data;
        this.totalProdutos = response.total;
        if (this.options.itemsPerPage != Number(response.per_page)) {
          this.options.itemsPerPage = Number(response.per_page);
        }

        this.loadingSearch = false;
      } catch (error) {
        this.loadingSearch = false;
      }
    },

    row_classes(item) {
      if (
        Number(item.disponivel) <= 0 &&
        item.controle_estoque &&
        (this.typeComp == "venda" || this.typeComp == "busca_deposito")
      ) {
        return ["tocs_gray_1 red--text", "row-disabled"];
      } else if (this.typeComp == "fornecedor_servico") {
        return ["green--text", "row-available"]; //can also return multiple classes e.g ["orange","disabled"]
      } else if (this.typeComp == "compra") {
        return ["green--text", "row-available"]; //can also return multiple classes e.g ["orange","disabled"]
      } else {
        if (Number(item.disponivel) == 0 && item.controle_estoque) {
          return ["red--text", "row-available"]; //can also return multiple classes e.g ["orange","disabled"]
        }
        if (item.produto_kit && this.tipo == "simples") {
          return ["red--text", "row-disabled"]; //can also return multiple classes e.g ["orange","disabled"]
        }
        if (item.deposito != this.deposito_id && item.controle_estoque) {
          return ["red--text", "row-disabled"]; //can also return multiple classes e.g ["orange","disabled"]
        }
        return ["green--text", "row-available"]; //can also return multiple classes e.g ["orange","disabled"]
      }
    },

    selectProduto(item) {
      if (
        (Number(item.disponivel) > 0 && item.deposito == this.deposito_id) ||
        (!item.controle_estoque && !item.produto_kit) ||
        (item.produto_kit && this.tipo !== "simples") ||
        this.typeComp == "compra" ||
        this.typeComp == "produtos" ||
        this.typeComp == "orcamento" ||
        this.typeComp == "fornecedor_servico"
      ) {
        this.produto.id = item.id;
        this.produto.descricao = item.marca
          ? item.marca + " | " + item.descricao
          : item.descricao;
        this.produto.disponivel = item.disponivel;
        this.produto.preco_minimo = item.preco_minimo;
        this.$emit("update:produto_sync", this.produto);
        this.$emit("update:dialogSearchProdutos", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .row-available {
  cursor: pointer;
}

::v-deep .row-disabled {
  opacity: 0.5;
}
</style>
