const routes = [
  {
    path: "/configuracoes/pdf-layouts",
    component: () => import("@/views/configuracoes/pdf-layouts/PdfLayouts.vue"),
    meta: {
      menu: "configuracoes",
      title: { text: "global.pdf_layout", plural: true },
      icon: "mdi-format-page-break",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.pdf_layout", plural: true },
      ],
      permission: { componente: "PdfLayouts" },

    },
  },
  {
    path: "/configuracoes/pdf-layouts/adicionar",
    component: () =>
      import("@/views/configuracoes/pdf-layouts/AddPdfLayout.vue"),
    meta: {
      menu: "configuracoes",
      title: { text: "global.add", plural: true },
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.pdf_layout", plural: true, to: { path: "/configuracoes/pdf-layouts" } },
        { text: "global.add" },
      ],
      voltar: { path: "/configuracoes/pdf-layouts" },
      permission: { componente: "PdfLayouts" },
    },
  },
  {
    path: "/configuracoes/pdf-layouts/:id",
    props: true,
    component: () => import("@/views/configuracoes/pdf-layouts/PdfLayout.vue"),
    meta: {
      menu: "configuracoes",
      title: { text: "global.pdf_layout", plural: true },
      icon: "mdi-format-page-break",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.pdf_layout", plural: true, to: { path: "/configuracoes/pdf-layouts" } },
        { text: "dynamic_crumb" },
      ],
      voltar: { path: "/configuracoes/pdf-layouts" },
      permission: { componente: "PdfLayouts" },
    },
  },
];

export default routes;
