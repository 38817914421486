import api from "../axios_service.js";

export async function getAudits(filtros) {
  const response = await api.get(`/audit${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function getAudit(id) {
  const response = await api.get(`/audit/${id}`);
  return response.data.result;
}
