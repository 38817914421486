<template>
  <v-dialog
    v-model="dialogVendasCaixa"
    @click:outside="fecharVendasCaixa"
    @keydown.esc="fecharVendasCaixa"
    width="1200px"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-tabs grow v-model="tab">
          <v-tab>
            {{ $tc("global.venda", 2) }} {{ $tc("global.pendentes", 2) }}</v-tab
          >
          <v-tab>
            {{ $tc("global.venda", 2) }} {{ $tc("global.finalizada", 2) }}
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-6 py-8">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ListVendas v-if="tab === 0" />
          </v-tab-item>

          <v-tab-item>
            <ListVendasFinalizadas v-if="tab === 1" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogVendasCaixa",

  props: {
    dialogVendasCaixa: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ListVendas: () => import("./ListVendas.vue"),
    ListVendasFinalizadas: () => import("./ListVendasFinalizadas.vue"),
  },

  data() {
    return {
      loading: false,
      tab: 0,
      dialogBaixaVenda: false,
    };
  },

  computed: {},

  methods: {
    fecharVendasCaixa() {
      this.$emit("update:dialogVendasCaixa", false);
      this.$emit("fetch-caixas-items");
    },
  },

  mounted() {},
};
</script>

<style></style>
