import api from "../axios_service.js";

const fetchFaturas = async (filtro) => {
  const response = await api.get(`/faturas${filtro}`);
  return response.data.faturas;
};

const fetchFatura = async (id) => {
  const response = await api.get(`/faturas/${id}`);
  return response.data.result;
};

const listaFaturas = async () => {
  const response = await api.get(`/faturas-list`);
  return response.data.result;
};

const postFatura = (body) => {
  return api.post("/faturas/add", body);
};

const dataImpressaoFatura = (id) => {
  return api.post(`/data-impressao-faturas/${id}`);
};

const putFatura = (id, body) => {
  return api.put(`/faturas/${id}`, body);
};

const postNotaCreditoManual = (body) => {
  return api.post("/nota-credito-manual/add", body);
};

const postFaturaManual = (body) => {
  return api.post("fatura-manual/add", body);
};

export {
  fetchFaturas,
  fetchFatura,
  postFatura,
  putFatura,
  listaFaturas,
  dataImpressaoFatura,
  postNotaCreditoManual,
  postFaturaManual,
};
