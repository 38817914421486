const routes = [
  {
    path: "/super-admin/usuarios",
    component: () => import("@/views/super_admin/usuarios/Usuarios.vue"),
    meta: {
      component: "Usuarios",
      menu: "super_admin",
      title: "Usuarios",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.usuario", plural: true },
      ],

    },
  },
  {
    path: "/super-admin/usuario/:usuarioId",
    component: () =>
      import("@/components/usuario/Usuario.vue"),
    props: true,
    meta: {
      menu: "super_admin",
      title: "Usuario",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.usuario",
          plural: true,
          to: { path: "/super-admin/usuarios" },
        },
        { text: "dynamic_crumb" },
      ],
      voltar: { path: "/super-admin/usuarios" },

    },
  },
  {
    path: "/super-admin/usuarios/adicionar",
    component: () => import("@/components/usuario/NovoUsuario.vue"),
    meta: {
      menu: "super_admin",
      title: "Adicionar Usuario",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.usuario",
          plural: true,
          to: { path: "/super-admin/usuarios" },
        },
        { text: "global.add" },
      ],
      voltar: { path: "/super-admin/usuarios" },

    },
  },
];

export default routes;
