import api from "../axios_service.js";

//----- ALL ----
export async function fetchAllPaises() {
  const response = await api.get("/pais");
  return response.data.result;
}
//------------------------

// ----- one -----
export async function fetchPais(id) {
  const response = await api.get(`/pais/${id}`);
  return response.data.result;
}
// ------------------

export async function updatePais(id , body) {
  const response = await api.put(`/pais/${id}` , body);
  return response.data.result;
}

export async function createPais(body) {
  const response = await api.post("/pais/add", body);
  return response.data.result;
}
