<template>
  <v-dialog
    v-model="dialogProfile"
    max-width="800px"
    @click:outside="$emit('update:dialogProfile', false)"
    origin="right right"
  >
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="py-8 tocs_gray_1 d-flex flex-column align-center justify-center"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                : ''
            "
          >
            <div class="d-flex justify-center align-center">
              <v-hover v-slot:default="{ hover }">
                <v-avatar
                  size="120"
                  style="box-shadow: 0px 0px 0px 3px #14525e"
                >
                  <v-img
                    :src="
                      avatar
                        ? avatar
                        : require('@/assets/avatar_placeholder.png')
                    "
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out darkblue v-card--reveal white--text"
                        style="height: 34px"
                      >
                        <v-btn
                          depressed
                          text
                          x-small
                          dark
                          class="mt-1"
                          @click="onButtonClick"
                        >
                          <input
                            accept="image/*"
                            type="file"
                            class="d-none"
                            ref="uploader"
                            @change="onFileChanged"
                          />
                          {{ $tc("global.alterar") }}
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-avatar>
              </v-hover>
            </div>

            <div class="lightgreen--text text-center font-weight-bold mt-6">
              {{ usuario.name }}
            </div>
          </v-col>
          <v-col cols="12" md="9" class="pa-0">
            <v-card-title class="subtitle-1 px-4 py-2">
              <v-spacer></v-spacer>
              <v-btn icon @click="$emit('update:dialogProfile', false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-form v-model="validForm" ref="form" lazy-validation>
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model.lazy="usuario.name"
                      :label="$tc('global.nomecompleto')"
                      outlined
                      dense
                      :rules="formRules"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model.lazy="usuario.celular"
                      :label="$tc('global.celular')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      name="senha"
                      :label="$tc('global.senha')"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                      dense
                      outlined
                      v-model="usuario.password"
                      class="input-group--focused"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      name="confirmarSenha"
                      :label="$tc('global.confirmsenha')"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                      dense
                      outlined
                      v-model="usuario.password_confirmation"
                      class="input-group--focused"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions class="pa-3">
              <v-spacer></v-spacer>
              <v-btn
                @click.prevent="updateUsuario(user_id)"
                depressed
                small
                :disabled="!validForm"
                color="button_2"
                class="white--text"
              >
                <v-icon left>mdi-check</v-icon>
                {{ $tc("global.salvar") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import {
  putUsuario,
  getUsuario,
  uploadImage,
  getImages,
} from "@/api/usuarios/usuarios.js";

import { mapState } from "vuex";

export default {
  name: "Profile",
  components: {},
  props: {
    dialogProfile: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    usuario: {},
    tab: null,
    show: false,
    validForm: true,
    formRules: [inputRequired],
    emailRules: [validaEmail],
    loading: false,
    notFound: false,
    original: {},
    apiUrl: null,
    avatar: null,
    avatarUpload: null,
  }),
  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },
  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.avatarUpload = file;
      this.usuario.avatar = !this.usuario.avatar ? "avatar" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
        // vue.editFornecedor();
      };
    },
    updateUsuario(id) {
      if (JSON.stringify(this.usuario) === JSON.stringify(this.original)) {
        this.$toast.error(this.$tc("global.fazermudanca"));
      } else {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const usuario = new FormData();

          if (this.avatarUpload) {
            usuario.append("avatar", this.avatarUpload, this.avatarUpload.name);
          }
          let usuario_2 = {};
          usuario_2.name = this.usuario.name;
          usuario_2.empresa_ativa = this.usuario.empresa_ativa;
          usuario_2.email = this.usuario.email;
          usuario_2.password = this.usuario.password;
          usuario_2.password_confirmation = this.usuario.password_confirmation;
          usuario_2.celular = this.usuario.celular;
          usuario_2.status = this.usuario.status;

          for (let key in usuario_2) {
            if (
              usuario_2[key] !== null &&
              usuario_2[key] !== undefined &&
              usuario_2[key] !== ""
            ) {
              usuario.append(key, usuario_2[key]);
            }
          }

          putUsuario(id, usuario)
            .then(() => {
              this.original = { ...this.usuario };
              // this.$emit("update:dialogProfile", false);
              this.$store.dispatch("Usuario/getUsuario");
              this.$emit("update:dialogProfile", false);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    fetchUser() {
      getUsuario(this.user_id)
        .then((response) => {
          this.usuario = response;
          this.original = { ...response };
          this.avatar = this.usuario.avatar;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // updateAvatar(image) {
    //   this.usuario.avatar = image;
    //   this.updateUsuario(this.user_id);
    //   this.dialogImages = false;
    // },
  },
  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    if (this.user_id) {
      this.fetchUser();
    } else {
      this.notFound = "nao encontrado";
    }
  },
  created() {},
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
