<template>
  <v-dialog
    v-model="addPrecoDialog"
    @click:outside="$emit('update:addPrecoDialog', false)"
    max-width="450"
  >
    <v-card tile>
      <v-card-title class="text-subtitle-1 lightgray">
        {{ $tc("global.add") }} {{ $tc("global.preco") }} {{ lista.descricao }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:addPrecoDialog', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-5 py-4">
        <v-row>
          <!-- Moeda -->
          <v-col cols="12" md="4">
            <v-select
              v-model="selectedMoeda"
              :items="moedas"
              clearable
              dense
              outlined
              hide-details
              item-text="sigla"
              item-value="id"
              :label="this.$tc('global.moeda')"
              return-object
            ></v-select>
          </v-col>

          <!-- Valor -->
          <v-col cols="12" md="8">
            <v-text-field
              v-model="valorListaPreco"
              dense
              :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
              outlined
              :label="this.$tc('global.preco')"
              :valor_sync.sync="preco.valor"
              :disabled="!selectedMoeda"
              height="40px"
              style="display: flex"
              class="remove-underline input-font-size input-right"
              v-currency="vCurrencyOptions"
              @blur="validaValor"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_1"
          class="white--text"
          depressed
          small
          @click="createPreco"
          :disabled="disableAddPreco"
        >
          {{ $tc("global.add") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postProdListaPrecos } from "@/api/produtos/prodlistasprecos.js";

import { mapState } from "vuex";

export default {
  name: "AddPreco",

  components: {},

  props: {
    addPrecoDialog: {
      type: Boolean,
      required: true,
    },
    lista: {
      type: Object,
      required: true,
    },
    produto: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      preco: {
        moeda_id: null,
        valor: null,
      },
      loading: false,
      selectedMoeda: null,
      valorListaPreco: 0,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),

    disableAddPreco() {
      let result = true;
      if (this.preco.valor && this.preco.moeda_id) {
        result = false;
      }
      return result;
    },
    vCurrencyOptions() {
      let precision = undefined;
      if (this.selectedMoeda) {
        if (this.selectedMoeda.sigla === "G$") {
          precision = 0;
        } else {
          precision = 2;
        }
      }
      return {
        precision: precision,
        distractionFree: false,
      };
    },
  },

  watch: {
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.preco.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
  },

  methods: {
    createPreco() {
      this.loading = true;

      this.preco.lista_preco_id = this.lista.id;
      this.preco.produto_id = this.produto.id;
      this.preco.status = 1;

      postProdListaPrecos(this.preco)
        .then((response) => {
          if (response.status == 201) {
            this.$toast.success("Preço adicionado com sucesso!");
            this.$emit("fetch-listas");
            this.$emit("update:addPrecoDialog", false);
            this.loading = false;
          }
        })
        .catch(() => {});
    },

    validaValor() {
      let moedaLista = this.$ci.parse(
        this.valorListaPreco,
        this.vCurrencyOptions
      );
      this.preco.valor = moedaLista;
    },
  },

  mounted() {
    this.selectedMoeda = this.moedas.find(
      (moeda) => moeda.id_moeda === this.moeda_empresa
    );
  },
};
</script>

<style></style>
