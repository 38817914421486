import api from "../axios_service.js";

export async function fetchListasPrecos(filtros) {
  const response = await api.get(`/listasprecos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchLPCompletas(filtros) {
  const response = await api.get(
    `/listasprecos-completa${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchListaPrecos(id) {
  const response = await api.get(`/listasprecos/${id}`);
  return response.data.result;
}

export async function putListaPrecos(id, body) {
  return api.put(`/listasprecos/${id}`, body);
}

export async function postListaPrecos(body) {
  return api.post("/listasprecos/add", body);
}

export function deleteListaPrecos(id) {
  return api.delete(`/listasprecos/${id}`);
}
