<template>
  <v-dialog
    v-model="dialogTotaisCaixa"
    @click:outside="$emit('update:dialogTotaisCaixa', false)"
    @keydown.esc="$emit('update:dialogTotaisCaixa', false)"
    max-width="750px"
  >
    <v-card tile>
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.saldo") }} {{ $tc("global.caixa") }} {{ caixa_id }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogTotaisCaixa', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6" v-if="totais_caixa_af">
        <div>
          <span class="d-flex text-subtitle-1 font-weight-bold">
            G$
            <v-spacer></v-spacer>
            {{ totais_caixa_af.saldo_guarani | guarani }}
          </span>
          <v-divider class="mb-2"></v-divider>
          <span class="d-flex text-subtitle-1 font-weight-bold">
            R$
            <v-spacer></v-spacer>
            {{ totais_caixa_af.saldo_real | currency }}
          </span>
          <v-divider class="mb-2"></v-divider>
          <span class="d-flex text-subtitle-1 font-weight-bold">
            U$ <v-spacer></v-spacer>
            {{ totais_caixa_af.saldo_dolar | currency }}
          </span>
          <v-divider class="mb-2"></v-divider>
          <span class="d-flex text-subtitle-1 font-weight-bold">
            {{ $tc("global.credito") }} G$
            <v-spacer></v-spacer>
            {{ totais_caixa_af.saldo_credito | guarani }}
          </span>
          <v-divider class="mb-2"></v-divider>
          <span class="d-flex text-subtitle-1 font-weight-bold">
            {{ $tc("global.debito") }} G$
            <v-spacer></v-spacer>
            {{ totais_caixa_af.saldo_debito | guarani }}
          </span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchCaixaAfTotais } from "@/api/caixas/caixas_af.js";
export default {
  name: "DialogTotaisCaixa",

  props: {
    dialogTotaisCaixa: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      totais_caixa_af: [],
    };
  },

  computed: {
    caixa_id() {
      return this.$route.params.caixa_id;
    },
  },

  methods: {
    getCaixaMovTotais() {
      this.loading = true;
      fetchCaixaAfTotais(this.caixa_id)
        .then((response) => {
          this.totais_caixa_af = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getCaixaMovTotais();
  },
};
</script>

<style></style>
