const routes = [
  {
    path: "/estoque/transferencia",
    component: () => import("@/views/dashboard/transferencia-estoque/TransferenciasEstoque.vue"),
    meta: {
      menu: "dashboard",

      title: { text: "global.transferenciaEstoque", plural: true },
      icon: "mdi-cash-plus",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.transferenciaEstoque" },
      ],

      permission: { componente: "TransferenciaEstoque", },

    },
  },
];

export default routes;
