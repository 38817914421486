<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.ordensServicos', 2)"
      icon="mdi-toolbox-outline"
      dark
    />
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>

        <v-select
          v-model="statusFilter"
          :items="status_lista"
          label="Status"
          multiple
          hide-details
          flat
          dense
          solo
          style="max-width: 450px"
          class="ml-0 ml-sm-3 mt-4 mt-sm-0"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center justify-start">
              <v-sheet
                width="10px"
                height="10px"
                :color="item.color"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ item.text }}
            </div>
          </template>

          <template v-slot:selection="{ item, index }">
            <div class="d-flex align-center justify-start">
              <v-sheet
                width="10px"
                height="10px"
                :color="item.color"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              <span class="text-caption">{{ item.text }}</span>
            </div>
            <v-divider
              v-if="index < statusFilter.length - 1"
              class="mx-2 mt-8 mt-sm-0"
              vertical
            ></v-divider>
          </template>
        </v-select>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <!-- <v-btn
          v-if="POrdensServicosAdicionar"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
          @click="addOrdemServico"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn> -->
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :search="search"
          :items="ordem_servicos"
          :items-per-page="10"
          sort-by="id"
          sort-desc="true"
          class="data-tables"
          :class="{ 'data-tables__row-click': POrdensServicosVisualizar }"
          @click:row="goToOrdemServico"
        >
          <template v-slot:item.previsao_inicio="{ item }">
            {{ item.previsao_inicio | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.previsao_fim="{ item }">
            {{ item.previsao_fim | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.desconto | guarani }}
            </span>
            <span v-else>
              {{ item.desconto | currency }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ (item.valor - item.desconto) | guarani }}
            </span>
            <span v-else>
              {{ (item.valor - item.desconto) | currency }}
            </span>
          </template>
          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center" v-if="item.status === 1">
              <v-sheet
                width="10px"
                height="10px"
                color="yellow lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.pendentes") }}
            </div>

            <div class="d-flex align-center" v-if="item.status === 2">
              <v-sheet
                width="10px"
                height="10px"
                color="lime darken-2"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.emPreparação") }}
            </div>
            <div class="d-flex align-center" v-if="item.status === 3">
              <v-sheet
                width="10px"
                height="10px"
                color="light-blue darken-3"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.aguardando") + " " + $tc("global.instalacao") }}
            </div>
            <div class="d-flex align-center" v-if="item.status === 4">
              <v-sheet
                width="10px"
                height="10px"
                color="orange lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ "Em " + $tc("global.instalacao") }}
            </div>
            <div class="d-flex align-center" v-if="item.status === 5">
              <v-sheet
                width="10px"
                height="10px"
                color="green lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.finalizado") }}
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status !== 0 || item.status !== 1"
                  icon
                  @click.stop="gerarpdf(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-basket</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("global.produto", 2) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status >= 3"
                  icon
                  @click.stop="gerarCheckList(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-check-underline-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Check list</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.status >= 3"
                  icon
                  @click.stop="gerarTermo(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-export</v-icon>
                </v-btn>
              </template>
              <span>{{ $tc("global.termo") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogOrdemServico
      v-if="dialogOrdemServico"
      :dialogOrdemServico.sync="dialogOrdemServico"
      :editar="editar"
      :ordem_servico_id="ordem_servico_id"
      @fetch-ordens-servicos="getOrdemServicos"
    />
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      :title="$tc('global.ordensServicos')"
    />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { fetchOrdensServico } from "@/api/ordem-servico/ordem_servicos.js";

const pdfGenerator = () =>
  import(/* webpackChunkName: "produtos-pdf" */ "./reports/ordem_servico.js");

const pdfChecklist = () =>
  import(/* webpackChunkName: "check-list-pdf" */ "./reports/check_list.js");

const pdfTermo = () =>
  import(/* webpackChunkName: "check-list-pdf" */ "./reports/termo_piscina.js");

export default {
  name: "OrdensServicos",

  components: {
    DialogOrdemServico: () => import("./components/DialogOrdemServico.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      search: "",
      loading: false,
      ordem_servicos: [],
      ordem_servico_id: null,
      editar: false,
      dialogOrdemServico: false,
      statusFilter: [1, 2, 3, 4, 5],
      pdfDialog: false,
      pdfData: "",
      loadingPdf: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    POrdensServicosVisualizar() {
      return this.getAccess("OrdensServicos", "visualizar");
    },
    POrdensServicosAdicionar() {
      return this.getAccess("OrdensServicos", "adicionar");
    },

    headers() {
      return [
        {
          text: "id",
          value: "id",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.previsaoInicio"),
          value: "previsao_inicio",
        },
        {
          text: this.$tc("global.previsaoFim"),
          value: "previsao_fim",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
        {
          text: "",
          value: "actions",
        },
      ];
    },

    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
    }),

    status_lista() {
      return [
        {
          text: this.$tc("global.pendentes"),
          value: 1,
          color: "yellow lighten-1",
        },
        {
          text: this.$tc("global.emPreparação"),
          value: 2,
          color: "lime darken-2",
        },
        {
          text:
            this.$tc("global.aguardando") + " " + this.$tc("global.instalacao"),
          value: 3,
          color: "light-blue darken-3",
        },
        {
          text: "Em " + this.$tc("global.instalacao"),
          value: 4,
          color: "orange lighten-1",
        },
        {
          text: this.$tc("global.finalizado"),
          value: 5,
          color: "green lighten-1",
        },
      ];
    },

    url() {
      let queryString = "";
      if (this.statusFilter.length) {
        queryString += `&status_filter=${this.statusFilter.join()}`;
      }
      return `?${queryString}`;
    },
  },

  watch: {
    url: {
      handler() {
        this.getOrdemServicos();
      },
      immediate: true,
    },
  },

  methods: {
    addOrdemServico() {
      this.editar = false;
      this.dialogOrdemServico = true;
    },
    goToOrdemServico(item) {
      this.ordem_servico_id = item.id;
      this.dialogOrdemServico = true;
    },

    getOrdemServicos() {
      this.loading = true;

      fetchOrdensServico(this.url)
        .then((response) => {
          this.ordem_servicos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async gerarCheckList(item) {
      try {
        this.loadingPdf = true;

        await pdfChecklist()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.empresa, item.id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loadingPdf = false;
          });
      } catch (error) {
        this.loadingPdf = false;
      }
    },

    async gerarTermo(item) {
      try {
        this.loadingPdf = true;

        await pdfTermo()
          .then((module) => {
            let generate = module.generatePdf;
            generate(item.id, item.origem_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loadingPdf = false;
          });
      } catch (error) {
        this.loadingPdf = false;
      }
    },
    async gerarpdf(item) {
      try {
        this.loadingPdf = true;

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.empresa, item.id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loadingPdf = false;
          });
      } catch (error) {
        this.loadingPdf = false;
      }
    },
  },

  mounted() {
    this.getOrdemServicos();
  },
};
</script>

<style></style>
