import api from "../axios_service.js";

//----- ALL ----
export async function fetchAllEstados() {
  const response = await api.get("/estados");
  return response.data.result;
}
//------------------------

// ----- one -----
export async function fetchEstado(id) {
  const response = await api.get(`/estados/${id}`);
  return response.data.result;
}
// ------------------

// ----- Estado Por Pais -----
export async function fetchEstadoPorPais(id) {
  const response = await api.get(`/estados-por-pais/${id}`);
  return response.data.result;
}
// ------------------

export async function updateEstado(id , body) {
  const response = await api.put(`/estados/${id}` , body);
  return response.data.result;
}

export async function createEstado(body) {
  const response = await api.post("/estados/add", body);
  return response.data.result;
}
