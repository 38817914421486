import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import dateLocaleEspanhol from "date-fns/locale/es";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { layoutEmpresa } from "@/views/dashboard/relatorios/config/layoutEmpresa.js";
import store from "@/store/modules/usuario.store.js";
import writtenNumber from "written-number";

export async function generatePagare(pagare) {
  const empresa = await fetchEmpresa(store.state.empresa.id);
  const bodyPDF = await layoutEmpresa();
  const cabecalho = JSON.stringify(bodyPDF.cabecalho);

  return new Promise((resolve, reject) => {
    let docDefinition = {};
    let fontItem = 9;
    let geral = [];
    let moeda_nome;

    if (pagare.moeda_sigla == "G$") {
      moeda_nome = "GUARANIES";
    }

    if (pagare.moeda_sigla == "R$") {
      moeda_nome = "REALES";
    }

    if (pagare.moeda_sigla == "U$") {
      moeda_nome = "DOLARES";
    }

    //  UNICO PAGARE
    //
    //
    //
    // ---------------------------------------------------------------------------------
    if (pagare.parcelas.length === 1) {
      let cabecalho2 = {
        margin: [0, -10, 0, 0],
        table: {
          widths: ["*", "*", "*"],
          body: [
            [
              {
                border: [false, false, false, false],
                fontSize: 11,
                text: "Nº: " + pagare.parcelas[0].numero_parcela,
                // fillColor: "#F5F5F5",
                margin: [10, 40, 10, 10],
              },
              {
                // fillColor: "#F5F5F5",
                border: [false, false, false, false],
                margin: [0, 10, 0, 0],
                stack: [
                  {
                    alignment: "center",
                    text: "PAGARÉ A LA ORDEN POR",
                    fontSize: 13,
                    bold: true,
                  },
                ],
              },
              {
                // fillColor: "#F5F5F5",
                border: [false, false, false, false],
                margin: [10, 40, 10, 10],
                stack: [
                  {
                    alignment: "right",
                    text:
                      pagare.moeda_sigla +
                      " " +
                      (pagare.moeda_sigla == "G$"
                        ? Vue.filter("guarani")(pagare.parcelas[0].valor)
                        : Vue.filter("currency")(pagare.parcelas[0].valor)),
                    fontSize: 11,
                  },
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
        },
      };

      let corpo = {
        margin: [0, 0, 0, 0],
        table: {
          widths: [180, 180, 180],
          body: [
            // TEXTO 1 INFO
            [
              {
                border: [false, false, false, false],
                fontSize: 11,
                margin: [5, 16, 8, 0],
                alignment: "justify",
                // fillColor: "#F5F5F5",
                text: [
                  "El  Dia " +
                    format(
                      parseISO(pagare.parcelas[0].vencimento),
                      "dd " + "MMMM " + "yyyy",
                      { locale: dateLocaleEspanhol }
                    ) +
                    "  pagaré(mos) ",
                  " a  la  orden  de  ",
                  { text: empresa.nome, bold: true },
                  " la  suma  de  ",
                  {
                    text:
                      writtenNumber(pagare.parcelas[0].valor, {
                        lang: "es",
                      }).toUpperCase() +
                      " " +
                      moeda_nome,
                    bold: true,
                  },
                  "  por  igual  valor  recibo  en  a   mi   (nuestra)  entera  satisfaccion, con vencimento en ",
                  {
                    text:
                      format(
                        parseISO(pagare.parcelas[0].vencimento),
                        "dd/MM/yyyy"
                      ) + ".",
                  },
                ],
                colSpan: 3,
              },
              {},
              {},
            ],

            // TEXTO 2  AVISO
            [
              {
                border: [false, false, false, false],
                fontSize: 9,
                margin: [5, 8, 8, 0],
                alignment: "justify",
                // fillColor: "#F5F5F5",
                text: "La falta de pago de este pagaré en la fecha de su vencimiento, me (nos) constituira en mora de pleno derecho y producirá el decaimiento de los plazos de todas mis (nuestras) demás obligaciones para con el acreedor tornándolas integramente exigibles sin necesidade de protesto ni requerimiento judicial o extrajudicial alguno en cuyo caso la totalidade de la deuda devengará un interés compensatorio de 2% mensual y un interés punitorio del 3% mensual desde el dia del vencimiento hasta el del efectivo pago. A todos los efectos legales, me(nos) somentemos a la jurisdiccion de los Tribunales Ordinarios en los Civil y Comercial del Alto Paraná y constituyo(imos) domicilio especial en.",
                colSpan: 3,
              },
              {},
              {},
            ],

            // DADOS CLIENTE
            [
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 40, 0, 0],
                // fillColor: "#F5F5F5",
                text: [
                  "Nombre :  ",
                  {
                    text: pagare.cliente_nome,
                    fontSize: 10,
                    bold: true,
                  },
                ],
              },
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 40, 0, 0],
                // fillColor: "#F5F5F5",
                text: ["Nombre :  "],
              },
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 40, 0, 0],
                // fillColor: "#F5F5F5",
                text: ["Nombre :  "],
              },
            ],

            [
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 0, 0, 0],
                // fillColor: "#F5F5F5",
                text: [
                  pagare.cliente_doc_ci
                    ? "C.i :  " + pagare.cliente_doc_ci
                    : pagare.cliente_doc_ruc
                    ? "R.u.c :  " + pagare.cliente_doc_ruc
                    : "",
                  {
                    text: pagare.cliente_endereco,
                    fontSize: 6,
                    bold: true,
                  },
                ],
              },
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 0, 0, 0],
                // fillColor: "#F5F5F5",
                text: ["C.i :          "],
              },
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 0, 0, 0],
                // fillColor: "#F5F5F5",
                text: ["C.i :          "],
              },
            ],

            [
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 0, 0, 30],
                // fillColor: "#F5F5F5",
                text: [
                  "Domicilio :          ",
                  {
                    text: pagare.cliente_endereco,
                    fontSize: 6,
                    bold: true,
                  },
                ],
              },
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 0, 0, 30],
                // fillColor: "#F5F5F5",
                text: ["Domicilio :          "],
              },
              {
                border: [false, false, false, false],
                alignment: "light",
                fontSize: fontItem,
                margin: [5, 0, 0, 30],
                // fillColor: "#F5F5F5",
                text: ["Domicilio :          "],
              },
            ],

            [
              {
                border: [false, false, false, false],
                margin: [20, -8, 0, 0],
                // fillColor: "#F5F5F5",
                fontSize: 8,
                alignment: "light",
                text: "_______________________________________",

                bold: true,
              },
              {
                border: [false, false, false, false],
                margin: [20, -8, 0, 0],
                // fillColor: "#F5F5F5",
                fontSize: 8,
                alignment: "light",
                text: "_______________________________________",

                bold: true,
              },
              {
                border: [false, false, false, false],
                margin: [20, -8, 0, 0],
                // fillColor: "#F5F5F5",
                fontSize: 8,
                alignment: "light",
                text: "_______________________________________",

                bold: true,
              },
            ],

            [
              {
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],

                fontSize: 8,
                alignment: "center",
                text: "Firma",
                bold: true,
              },
              {
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],

                fontSize: 8,
                alignment: "center",
                text: "Firma",
                bold: true,
              },
              {
                border: [false, false, false, false],
                margin: [0, 0, 0, 0],
                fontSize: 8,
                alignment: "center",
                text: "Firma",
                bold: true,
              },
            ],
            [
              {
                border: [false, false, false, false],
                margin: [-80, -4, 0, 0],

                fontSize: 8,
                alignment: "center",
                text: pagare.cliente_doc_ci
                  ? "C.i :   " + pagare.cliente_doc_ci
                  : pagare.cliente_doc_ruc
                  ? "R.u.c :  " + pagare.cliente_doc_ruc
                  : "",
                bold: true,
              },
              {
                border: [false, false, false, false],
                margin: [-80, -4, 0, 0],

                fontSize: 8,
                alignment: "center",
                text: "C.i:",
                bold: true,
              },
              {
                border: [false, false, false, false],
                margin: [-80, -4, 0, 0],
                fontSize: 8,
                alignment: "center",
                text: "C.i:",
                bold: true,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
        },
      };

      let tabela = [JSON.parse(cabecalho), cabecalho2, corpo];
      geral.push(tabela);
    } else {
      //  MULTIPLOS PAGARES
      //
      //
      //
      // ---------------------------------------------------------------------------------
      let variavel = [];
      for (let i = 0; i < pagare.parcelas.length; i++) {
        variavel[i] = cabecalho;
      }

      pagare.parcelas.forEach((item, ind) => {
        let cabecalho2 = {
          margin: [0, -10, 0, 0],
          table: {
            widths: ["*", "*", "*"],
            body: [
              [
                {
                  border: [false, false, false, false],
                  fontSize: 11,
                  text: "Nº: " + item.numero_parcela,
                  // fillColor: "#F5F5F5",
                  margin: [10, 40, 10, 10],
                },
                {
                  // fillColor: "#F5F5F5",
                  border: [false, false, false, false],
                  margin: [0, 10, 0, 0],
                  stack: [
                    {
                      alignment: "center",
                      text: "PAGARÉ A LA ORDEN POR",
                      fontSize: 13,
                      bold: true,
                    },
                  ],
                },
                {
                  // fillColor: "#F5F5F5",
                  border: [false, false, false, false],
                  margin: [10, 40, 10, 10],
                  stack: [
                    {
                      alignment: "right",
                      text:
                        pagare.moeda_sigla +
                        " " +
                        (pagare.moeda_sigla == "G$"
                          ? Vue.filter("guarani")(item.valor)
                          : Vue.filter("currency")(item.valor)),
                      fontSize: 11,
                    },
                  ],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
            },
          },
        };

        let corpo = {
          margin: [0, 0, 0, 0],
          table: {
            widths: [180, 180, 180],
            body: [
              // TEXTO 1 INFO
              [
                {
                  border: [false, false, false, false],
                  fontSize: 11,
                  margin: [5, 16, 8, 0],
                  alignment: "justify",
                  // fillColor: "#F5F5F5",
                  text: [
                    "El  Dia " +
                      format(
                        parseISO(item.vencimento),
                        "dd " + "MMMM " + "yyyy",
                        { locale: dateLocaleEspanhol }
                      ) +
                      "  pagaré(mos) ",
                    " a  la  orden  de  ",
                    { text: empresa.nome, bold: true },
                    " la  suma  de  ",
                    {
                      text:
                        writtenNumber(item.valor, {
                          lang: "es",
                        }).toUpperCase() +
                        " " +
                        moeda_nome,
                      bold: true,
                    },
                    "  por  igual  valor  recibo  en  a   mi   (nuestra)  entera  satisfaccion, con vencimento en ",

                    {
                      text:
                        format(parseISO(item.vencimento), "dd/MM/yyyy") + ".",
                    },
                  ],
                  colSpan: 3,
                },
                {},
                {},
              ],

              // TEXTO 2  AVISO
              [
                {
                  border: [false, false, false, false],
                  fontSize: 9,
                  margin: [5, 8, 8, 0],
                  alignment: "justify",
                  // fillColor: "#F5F5F5",
                  text: "La falta de pago de este pagaré en la fecha de su vencimiento, me (nos) constituira en mora de pleno derecho y producirá el decaimiento de los plazos de todas mis (nuestras) demás obligaciones para con el acreedor tornándolas integramente exigibles sin necesidade de protesto ni requerimiento judicial o extrajudicial alguno en cuyo caso la totalidade de la deuda devengará un interés compensatorio de 2% mensual y un interés punitorio del 3% mensual desde el dia del vencimiento hasta el del efectivo pago. A todos los efectos legales, me(nos) somentemos a la jurisdiccion de los Tribunales Ordinarios en los Civil y Comercial del Alto Paraná y constituyo(imos) domicilio especial en.",
                  colSpan: 3,
                },
                {},
                {},
              ],

              // DADOS CLIENTE
              [
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 40, 0, 0],
                  // fillColor: "#F5F5F5",
                  text: [
                    "Nombre :  ",
                    {
                      text: pagare.cliente_nome,
                      fontSize: 10,
                      bold: true,
                    },
                  ],
                },
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 40, 0, 0],
                  // fillColor: "#F5F5F5",
                  text: ["Nombre :  "],
                },
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 40, 0, 0],
                  // fillColor: "#F5F5F5",
                  text: ["Nombre :  "],
                },
              ],

              [
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 0, 0, 0],
                  // fillColor: "#F5F5F5",
                  text: [
                    pagare.cliente_doc_ci
                      ? "C.i :    " + pagare.cliente_doc_ci
                      : pagare.cliente_doc_ruc
                      ? "R.u.c :   " + pagare.cliente_doc_ruc
                      : "",
                    {
                      text: pagare.cliente_endereco,
                      fontSize: 6,
                      bold: true,
                    },
                  ],
                },
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 0, 0, 0],
                  // fillColor: "#F5F5F5",
                  text: ["C.i :          "],
                },
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 0, 0, 0],
                  // fillColor: "#F5F5F5",
                  text: ["C.i :          "],
                },
              ],

              [
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 0, 0, 30],
                  // fillColor: "#F5F5F5",
                  text: [
                    "Domicilio :          ",
                    {
                      text: pagare.cliente_endereco,
                      fontSize: 6,
                      bold: true,
                    },
                  ],
                },
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 0, 0, 30],
                  // fillColor: "#F5F5F5",
                  text: ["Domicilio :          "],
                },
                {
                  border: [false, false, false, false],
                  alignment: "light",
                  fontSize: fontItem,
                  margin: [5, 0, 0, 30],
                  // fillColor: "#F5F5F5",
                  text: ["Domicilio :          "],
                },
              ],

              [
                {
                  border: [false, false, false, false],
                  margin: [20, -8, 0, 0],
                  // fillColor: "#F5F5F5",
                  fontSize: 8,
                  alignment: "light",
                  text: "_______________________________________",

                  bold: true,
                },
                {
                  border: [false, false, false, false],
                  margin: [20, -8, 0, 0],
                  // fillColor: "#F5F5F5",
                  fontSize: 8,
                  alignment: "light",
                  text: "_______________________________________",

                  bold: true,
                },
                {
                  border: [false, false, false, false],
                  margin: [20, -8, 0, 0],
                  // fillColor: "#F5F5F5",
                  fontSize: 8,
                  alignment: "light",
                  text: "_______________________________________",

                  bold: true,
                },
              ],

              [
                {
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],

                  fontSize: 8,
                  alignment: "center",
                  text: "Firma",
                  bold: true,
                },
                {
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],

                  fontSize: 8,
                  alignment: "center",
                  text: "Firma",
                  bold: true,
                },
                {
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                  fontSize: 8,
                  alignment: "center",
                  text: "Firma",
                  bold: true,
                },
              ],
              [
                {
                  border: [false, false, false, false],
                  margin: [-80, -4, 0, 0],

                  fontSize: 8,
                  alignment: "center",
                  text: pagare.cliente_doc_ci
                    ? "C.i :    " + pagare.cliente_doc_ci
                    : pagare.cliente_doc_ruc
                    ? "R.u.c :   " + pagare.cliente_doc_ruc
                    : "",
                  bold: true,
                },
                {
                  border: [false, false, false, false],
                  margin: [-80, -4, 0, 0],

                  fontSize: 8,
                  alignment: "center",
                  text: "C.i:",
                  bold: true,
                },
                {
                  border: [false, false, false, false],
                  margin: [-80, -4, 0, 0],
                  fontSize: 8,
                  alignment: "center",
                  text: "C.i:",
                  bold: true,
                },
              ],

              // DADOS TESTEMUNHA1
              // [
              //   {
              //     border: [false, false, false, false],
              //     alignment: "light",
              //     fontSize: fontItem,
              //     margin: [5, 25, 0, 0],
              //     // fillColor: "#F5F5F5",
              //     text: "Nombre :",
              //     colSpan: 2,
              //   },
              //   {},
              //   {
              //     border: [false, false, false, false],
              //     margin: [0, 0, 0, 0],
              //     // fillColor: "#F5F5F5",
              //     text: "",
              //   },
              // ],
              // [
              //   {
              //     border: [false, false, false, false],
              //     alignment: "light",
              //     fontSize: fontItem,
              //     margin: [5, 0, 0, 5],
              //     // fillColor: "#F5F5F5",
              //     text: "Domicilio :",
              //     colSpan: 2,
              //   },
              //   {},
              //   {
              //     border: [false, false, false, false],
              //     margin: [0, -8, 0, 0],
              //     // fillColor: "#F5F5F5",
              //     fontSize: 8,
              //     alignment: "center",
              //     text: "-----------------------------------------------------------------------------------\nFirma    C.i No.   ",
              //     bold: true,
              //   },
              // ],

              // DADOS TESTEMUNHA 2
              // [
              //   {
              //     border: [false, false, false, false],
              //     alignment: "light",
              //     fontSize: fontItem,
              //     margin: [5, 25, 0, 0],
              //     // fillColor: "#F5F5F5",
              //     text: "Nombre :",
              //     colSpan: 2,
              //   },
              //   {},
              //   {
              //     border: [false, false, false, false],
              //     margin: [0, 0, 0, 0],
              //     // fillColor: "#F5F5F5",
              //     text: "",
              //   },
              // ],
              // [
              //   {
              //     border: [false, false, false, false],
              //     alignment: "light",
              //     fontSize: fontItem,
              //     margin: [5, 0, 0, 5],
              //     // fillColor: "#F5F5F5",
              //     text: "Domicilio :",
              //     colSpan: 2,
              //   },
              //   {},
              //   {
              //     border: [false, false, false, false],
              //     margin: [0, -8, 0, 0],
              //     // fillColor: "#F5F5F5",
              //     fontSize: 8,
              //     alignment: "center",
              //     text: "-----------------------------------------------------------------------------------\nFirma    C.i No.   ",
              //     bold: true,
              //   },
              // ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
            },
          },
        };

        let tabela = [JSON.parse(variavel[ind]), cabecalho2, corpo];
        geral.push(tabela);

        if (ind != pagare.parcelas.length - 1) {
          geral.push({
            text: "",
            pageBreak: "before",
          });
        }
      });
    }

    docDefinition = {
      info: {
        title: "PAGARÉ",
        author: "ADM",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
