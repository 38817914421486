<template>
  <v-dialog
    v-model="dialogCaixaAbertoVisualizacao"
    @click:outside="$emit('update:dialogCaixaAbertoVisualizacao', false)"
    @keydown.esc="$emit('update:dialogCaixaAbertoVisualizacao', false)"
    width="1500px"
  >
    <v-card :loading="loading">
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.movimentacao") + " " + $tc("global.caixa") }}
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogCaixaAbertoVisualizacao', false)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-data-table
          :headers="headers"
          :items="caixaAfMovimentacao"
          :items-per-page="15"
          multi-sort
          class="data-tables"
        >
          <template v-slot:item.circle="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo_af === 'E'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="success"> mdi-plus-circle </v-icon>
              </template>
              <span>
                {{ $tc("global.entrada") }}
              </span>
            </v-tooltip>
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo_af === 'S'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="error"> mdi-minus-circle </v-icon>
              </template>
              <span>
                {{ $tc("global.saida") }}
              </span>
            </v-tooltip>
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo_af === 'N'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="tocs_gray_2">
                  mdi-alpha-n-circle
                </v-icon>
              </template>
              <span> Essa entrada não acrescenta ao total do caixa </span>
            </v-tooltip>
          </template>
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.valor="{ item }">
            <span v-if="item.sigla === 'G$'">{{ item.valor | guarani }}</span>

            <span v-else>{{ item.valor | currency }}</span>
          </template>

          <!-- <template v-slot:item.recibo="{ item }">
            <div v-if="item.numero_recibo" class="d-flex align-center">
              <div class="pr-2">
                {{ item.numero_recibo }}
              </div>
              <v-btn
                v-if="item.historico !== 'TRANSFERÊNCIA'"
                icon
                @click.stop="openReciboPdf(item)"
              >
                <v-icon>mdi-alpha-r-box</v-icon>
              </v-btn>
              <v-btn v-else icon @click.stop="openReciboPdfTransferencia(item)">
                <v-icon>mdi-alpha-t-box</v-icon>
              </v-btn>
            </div>
            <v-btn
              v-else-if="item.tipo_af === 'S'"
              icon
              @click.stop="openRecibo(item)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchCaixasAfVisualizacaoMovimentacao } from "@/api/caixas/caixas_af.js";
export default {
  name: "DialogCaixaAbertoVisualizacao",
  props: {
    dialogCaixaAbertoVisualizacao: {
      type: Boolean,
      default: false,
    },
    caixa_pdv_id: {
      type: Number,
    },
  },

  data() {
    return {
      loading: false,
      caixaAfMovimentacao: [],
    };
  },

  computed: {
    headers() {
      let heads = [
        {
          text: "",
          value: "circle",
        },
        {
          text: "ID",
          width: "65px",
          value: "id",
        },
        {
          text: this.$tc("global.data"),
          value: "data",
        },
        {
          text: this.$tc("global.usuario"),
          width: "100px",
          value: "user_name",
        },
        {
          text: this.$tc("global.documento"),
          width: "125px",
          value: "documento",
        },
        {
          text: this.$tc("global.historico"),
          width: "125px",
          value: "historico",
        },
        {
          text: this.$tc("global.formapagamento"),
          width: "185px",
          value: "forma_pagamento_descricao",
        },
        {
          text: this.$tc("global.planocontas"),
          width: "155px",
          value: "plano_conta_descricao",
        },

        // {
        //   text: this.$tc("global.recibo"),
        //   sortable: false,
        //   align: "center",
        //   value: "recibo",
        // },

        {
          text: this.$tc("global.moeda"),
          sortable: false,
          value: "sigla",
        },
      ];

      let multiMoedas = [
        {
          text: this.$tc("global.valor"),
          width: "120px",
          value: "valor",
        },
      ];

      heads = [...heads, ...multiMoedas];

      return heads;
    },
  },

  watch: {},

  methods: {
    getCaixasAbertoVisualizacao() {
      this.loading = true;

      return fetchCaixasAfVisualizacaoMovimentacao(
        `?caixa=${this.caixa_pdv_id}`
      )
        .then((response) => {
          this.caixaAfMovimentacao = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getCaixasAbertoVisualizacao();
  },
};
</script>

<style lang="scss" scoped></style>
