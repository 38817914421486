<template>
  <v-select
    v-model="status_cliente"
    :items="campos"
    clearable
    @click:clear="clearStatusCliente"
    outlined
    dense
    hide-details
    item-text="text"
    item-value="value"
    label="Status Cliente"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StatusCliente",

  data() {
    return {
      status_cliente: null,
      campos: null,
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  watch: {
    status_cliente() {
      if (this.status_cliente) {
        this.$store.commit(
          "FiltrosRelatorios/UPDATE_STATUS_CLIENTE",
          this.status_cliente
        );
      }
    },
  },

  methods: {
    clearStatusCliente() {
      this.$store.commit("FiltrosRelatorios/UPDATE_STATUS_CLIENTE", null);
      this.status_cliente = null;
    },
  },

  async created() {
    this.filtros_componente.find((item) => {
      if (item.text == "StatusCliente") {
        return (this.campos = item.options);
      }
    });
  },
};
</script>

<style></style>
