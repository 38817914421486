import Vue from "vue";
import VueRouter from "vue-router";
import geral_routes from "./routes/geral";
import admin_routes from "./routes/configuracoes";
import super_admin_routes from "./routes/super_admin";
import { i18n } from "../plugins/i18n.js";
import store from "../store/index.js";

Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    component: () =>
      import(
        /* webpackChunkName: "not-found" */ "@/views/PaginaNaoEncontrada.vue"
      ),
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "layout" */ "@/layout/AppLayout.vue"),
    meta: {
      login: true,
      breadcrumb: [{ text: "global.home" }],
    },
    children: [...geral_routes, ...admin_routes, ...super_admin_routes],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title && to.meta.title.text) {
    document.title =
      `${i18n.tc(to.meta.title.text, to.meta.title.plural ? 2 : 1)} - TOCS` ||
      "TOCS";
  } else {
    document.title = `${to.meta.title} - TOCS` || "TOCS";
  }

  const token = Vue.ls.get("token");

  if (token && to.fullPath != "/login") {
    await store.dispatch("Usuario/getUsuario");
    await store.dispatch("Componentes/getComponentes");
    await store.dispatch("Moedas/getMoedas");
    await store.dispatch("ListasPreco/getListas");
  } else {
    if (to.fullPath != "/login") {
      next("/login");
    }
  }

  if (to.matched.some((record) => record.meta.login)) {
    if (token) {
      if (
        !to.meta.permission || (to.meta.permission && to.meta.permission.visualizar) ||
        store.state.Usuario.usuario.is_admin
      ) {
        next();
      } else {
        Vue.prototype.$toast.error("Acesso não autorizado!");
        next("/");
      }
    } else {
      if (to.fullPath != "/login") {
        next("/login");
      }
    }

  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (to.name != "Login") {
    Vue.ls.set("current_route", to.path);
  }
  Vue.ls.set("last_route", from.path);
});

export default router;
