<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    styling: {
      type: Object,
      default: null,
    },
  },

  data: () => ({}),

  mounted() {
    this.renderChart(this.chartdata, this.options, this.styling);
  },
};
</script>

<style></style>
