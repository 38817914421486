<template>
  <v-app
    :class="{ 'pattern-bg': light, 'pattern-bg-dark': dark }"
    v-if="!loading && empresa"
  >
    <v-navigation-drawer
      v-resize="onResize"
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      class="elevation-2"
      clipped
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      v-if="menu != 'none'"
    >
      <MenuDashBoard v-if="menu === 'dashboard'" />
      <MenuSuperAdmin v-if="menu === 'super_admin'" />
      <MenuConfiguracoes v-if="menu === 'configuracoes'" />
    </v-navigation-drawer>

    <v-app-bar
      clipped-left
      class="elevation-2"
      :class="{ tocs_blue_1: light, 'panel-gradient-bg-dark': dark }"
      app
      dark
    >
      <v-app-bar-nav-icon
        class="d-lg-none d-xl-none"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      {{ empresa ? empresa.nome : "nao carregou empresa" }}

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text @click="dialogProfile = true" v-bind="attrs" v-on="on">
            <v-avatar size="30" class="mr-md-2">
              <img
                :src="
                  avatar ? avatar : require('@/assets/avatar_placeholder.png')
                "
              />
            </v-avatar>
            <span
              style="
                letter-spacing: 0.0892857143em !important;
                text-transform: none !important;
              "
              class="text-subtitle-1 mr-1"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              {{ nome }}
            </span>
          </v-btn>
        </template>
        <span>Profile</span>
      </v-tooltip>

      <v-tooltip v-if="is_admin" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            :to="{ path: '/super-admin/usuarios' }"
          >
            <v-icon>mdi-shield-account</v-icon>
          </v-btn>
        </template>
        <span>Super Admin</span>
      </v-tooltip>

      <v-tooltip v-if="permissoesConfiguracoes" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            :to="{ path: '/configuracoes/empresa' }"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("global.configuracao", 2) }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            @click.prevent="deslogarUsuario"
          >
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc("global.sair") }}</span>
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <v-container fluid class="pa-0">
        <BreadCrumbs />
      </v-container>
      <transition mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <v-footer
      class="elevation-2"
      dark
      :class="{
        tocs_blue_1: light,
        'panel-gradient-bg-dark': dark,
      }"
      app
    >
      <!-- Softpar -->
      <div class="body-2" v-if="$vuetify.breakpoint.mdAndUp">
        SOFTPAR <span class="caption mb-2">&reg;</span> - {{ year }}
      </div>

      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mx-4"
        vertical
      ></v-divider>

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <strong>BETA</strong> - {{ app_version }}
      </div>

      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mx-4"
        vertical
      ></v-divider>

      <div v-if="$vuetify.breakpoint.mdAndUp && permissaoCambio">
        <Cambio v-if="permissaoCambio" />
      </div>

      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp && permissaoCambio"
        class="mx-4"
        vertical
      ></v-divider>

      <v-menu v-if="$vuetify.breakpoint.mdAndUp" offset-y top min-width="150px">
        <template v-slot:activator="{ on }">
          <v-btn class="pa-0" icon v-on="on">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>
        <LocaleSwitcher />
      </v-menu>
      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp"
        :class="$vuetify.breakpoint.mdAndUp ? 'mx-4' : 'mx-2'"
        vertical
      ></v-divider>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp && permissoesRelatorios"
        depressed
        text
        tile
        class="pa-0"
        @click="dialogRelatorios = true"
      >
        <v-icon :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : ''">
          mdi-home-analytics
        </v-icon>
        {{ $tc("global.relatorio", 2) }}
      </v-btn>

      <!-- RELATORIOS -->
      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp && permissoesRelatorios"
        class="mx-4"
        vertical
      ></v-divider>

      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp && permissoesRecibos"
        depressed
        text
        tile
        class="pa-0"
        @click="dialogRecibos = true"
      >
        <v-icon :class="$vuetify.breakpoint.mdAndUp ? 'mr-2' : ''">
          mdi-alpha-r-box
        </v-icon>
        {{ $tc("global.recibo", 2) }}
      </v-btn>

      <!-- ------------------------ -->

      <v-divider
        v-if="$vuetify.breakpoint.mdAndUp && permissoesRecibos"
        :class="$vuetify.breakpoint.mdAndUp ? 'mx-4' : 'mx-2'"
        vertical
      ></v-divider>

      <v-spacer></v-spacer>
      <!-- Light/Dark Mode -->
      <v-btn @click="setTemplate" icon>
        <v-icon>mdi-invert-colors</v-icon>
      </v-btn>
    </v-footer>

    <Recibos v-if="dialogRecibos" :dialogRecibos.sync="dialogRecibos" />
    <Profile v-if="dialogProfile" :dialogProfile.sync="dialogProfile" />
    <Relatorios
      v-if="dialogRelatorios"
      :dialogRelatorios.sync="dialogRelatorios"
    />
  </v-app>
  <v-app v-else>
    <v-main>
      <v-container fluid class="fill-height pa-0">
        <Carregando />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { version } from "../../package";
import { format } from "date-fns";

export default {
  name: "AppLayout",

  components: {
    Recibos: () => import("@/components/recibo/Recibos.vue"),
    LocaleSwitcher: () => import("./components/LocaleSwitcher.vue"),
    BreadCrumbs: () => import("./components/BreadCrumbs.vue"),
    Profile: () => import("@/views/dashboard/profile/Profile.vue"),
    MenuDashBoard: () => import("./menus/MenuDashBoard"),
    MenuSuperAdmin: () => import("./menus/MenuSuperAdmin"),
    MenuConfiguracoes: () => import("./menus/MenuConfiguracoes"),
    Cambio: () => import("./components/Cambio.vue"),
    Relatorios: () => import("@/views/dashboard/relatorios/Relatorios.vue"),
  },

  data() {
    return {
      drawer: null,
      mini: true,
      locale: "",
      dialogRelatorios: false,
      dialogRecibos: false,
      windowSize: {
        x: 0,
        y: 0,
      },
      apiUrl: null,
      dialogProfile: false,
      menu: "",
      loading: false,
      app_version: version,
      teste: this.apiUrl,
    };
  },

  computed: {
    year() {
      return format(new Date(), "yyyy");
    },
    nome() {
      const nome = this.$store.state.Usuario.usuario.name;
      let splitName = nome ? nome.split(/ /) : "";
      let firstName = splitName[0] ? splitName[0] : "";
      let lastName = splitName[1] ? splitName[1] : "";
      let name = firstName;
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    },
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
      menuDinamico: (state) => state.menuDinamico,
    }),
    ...mapState("Usuario", {
      avatar: (state) => state.usuario.avatar,
      is_admin: (state) => state.usuario.is_admin,
      empresa: (state) => state.empresa,
    }),
    ...mapState("Componentes", {
      componentes: (state) => state.componentes,
    }),
    permissaoCambio() {
      if (
        this.componentes.find(
          (componente) =>
            componente.componente === "Cambios" &&
            componente.visualizar === true
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    permissoesConfiguracoes() {
      if (
        this.componentes.find(
          (componente) =>
            componente.componente === "Configuracoes" &&
            componente.visualizar === true
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    permissoesRelatorios() {
      if (
        this.componentes.find(
          (componente) =>
            componente.componente === "Relatorios" &&
            componente.visualizar === true
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    permissoesRecibos() {
      if (
        this.componentes.find(
          (componente) =>
            componente.componente === "Recibos" &&
            componente.visualizar === true
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    $route(to, from) {
      if (this.$route.meta.menu) {
        this.menu = this.$route.meta.menu;
      }
    },
    menuDinamico() {
      if (this.menuDinamico) {
        this.menu = this.menuDinamico;
      }
    },
  },

  methods: {
    mudaMenu(menu) {
      this.$store.commit("UPDATE_MENUDINAMICO", menu);
    },

    deslogarUsuario() {
      this.loading = true;
      this.$store.dispatch("Usuario/deslogarUsuario").then(() => {
        this.$router.push("/login");
        this.loading = false;
      });
    },

    setTemplate() {
      if (this.light) {
        this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        this.$ls.set("template", "dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        this.$ls.set("template", "light");
        this.$vuetify.theme.dark = false;
      }
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      if (this.windowSize.x < 1264) {
        return (this.mini = false);
      }
    },
  },

  mounted() {
    if (this.$route.meta.menu) {
      this.menu = this.$route.meta.menu;
    } else {
      this.menu = "dashboard";
    }
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    this.onResize();
    this.locale = this.$i18n.locale;
  },
};
</script>

<style lang="scss" scoped>
.pattern-bg {
  background-color: #eeeeee !important;
  // background-image: url("../assets/pattern.svg") !important;
  // background-position: center !important;
  // background-repeat: no-repeat !important;
  // background-size: 100% !important;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
  // background-image: url("../assets/pattern_dark.svg") !important;
  // background-position: center !important;
  // background-repeat: no-repeat !important;
  // background-size: 100% !important;
}

.v-enter,
.v-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
