<template>
  <v-dialog
    v-model="dialogAddPermissao"
    @click:outside="$emit('update:dialogAddPermissao', false)"
    @keydown.esc="$emit('update:dialogAddPermissao', false)"
    scrollable
    max-width="1210px"
  >
    <v-card>
      <v-card-title class="text-subtitle-1 lightgray">
        <v-icon color="primary" left>mdi-account-lock</v-icon>
        {{$tc('global.add')}} {{$tc('global.permissao')}}
        <v-spacer></v-spacer>

        <v-icon color="black" @click="$emit('update:dialogAddPermissao', false)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pa-6">
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" md="4">
              <v-card
                outlined
                height="40px"
                class="d-flex justify-space-between align-center"
              >
                <v-card-text class="text-body-1 py-0">
                  {{ `Status: ${permissao.status ? $tc('global.ativo') : $tc('global.inativo')}` }}
                </v-card-text>
                <v-switch
                  class="ma-0"
                  hide-details
                  :color="permissao.status ? 'secondary' : 'success'"
                  v-model="permissao.status"
                ></v-switch>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model.lazy="permissao.componente"
                :label="$tc('global.componente')"
                :rules="[rules.required]"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="permissao.descricao"
                :label="$tc('global.descricao')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                class="ma-0"
                height="40px"
                :items="permissoes"
                clearable
                :label="$tc('global.permissao') + ' ' + $tc('global.pai')"
                dense
                editable
                hide-details
                item-text="descricao"
                item-value="id"
                v-model="selectedPermissaoPai"
                outlined
                return-object
              >
              </v-select>
            </v-col>
            <v-row class="px-3" align-content="stretch">
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> {{$tc('global.acessos')}} </v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.visualizar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="1"
                      :label="$tc('global.visualizar')"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> CRUD </v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.adicionar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="2"
                      :label="$tc('global.add')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.editar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="4"
                      :label="$tc('global.editar')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.deletar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="8"
                      :label="$tc('global.deletar')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.importar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="64"
                      :label="$tc('global.importar')"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> {{$tc('global.impressao')}} </v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.imprimir"
                      class="mt-0"
                      :false-value="0"
                      :true-value="16"
                      :label="$tc('global.imprimir')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.exportar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="32"
                      :label="$tc('global.exportar')"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> {{$tc('global.customizado')}} </v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.limitar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="128"
                      :label="$tc('global.limitar')"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
          <div class="d-flex pt-10">
            <v-spacer></v-spacer>
            <v-btn
              @click.prevent="novaPermissao"
              class="white--text"
              color="button_2"
              :disabled="!valid"
            >
              {{$tc('global.add')}}
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getPermissoes, postPermissao } from "@/api/permissoes/permissoes.js";
// import { mapGetters } from "vuex";

export default {
  name: "NovaPermissao",

  props: {
    dialogAddPermissao: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    permissao: {
      status: true,
    },
    bitWise: {
      visualizar: 0,
      adicionar: 0,
      editar: 0,
      deletar: 0,
      imprimir: 0,
      exportar: 0,
      importar: 0,
      limitar: 0,
    },
    permissoes: [],
    selectedPermissaoPai: null,
    show: false,
    valid: false,
    loading: true,
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => v.length >= 3 || "Minimo 4 caracteres",
    },
  }),

  watch: {
    selectedPermissaoPai(val) {
      if (val) {
        this.permissao.permissao_id = val.id;
      } else {
        this.permissao.permissao_id = null;
      }
    },
  },
  computed: {},
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    resetForm() {
      this.$refs.form.reset();
    },
    fetchPermissoes(filtro) {
      return getPermissoes(filtro ? filtro : "")
        .then((response) => {
          response.forEach((resp) => {
            this.permissoes.push(resp);
            resp.children.forEach((child) => {
              this.permissoes.push(child);
            });
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    novaPermissao() {
      this.permissao.bits =
        this.bitWise.visualizar +
        this.bitWise.adicionar +
        this.bitWise.editar +
        this.bitWise.deletar +
        this.bitWise.imprimir +
        this.bitWise.exportar +
        this.bitWise.importar +
        this.bitWise.limitar;

      postPermissao(this.permissao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Criado com sucesso!");
            this.resetForm();
            this.permissao.status = true;
            this.$emit("update:dialogAddPermissao", false);
            this.$emit("fetched-permissoes");
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            if (error.response.data.descricao) {
              this.$toast.error(error.response.data.descricao[0]);
            }
            if (error.response.data.status) {
              this.$toast.error(error.response.data.status[0]);
            }
          } else {
            this.$toast.error(error);
          }
        });
    },
  },
  async mounted() {
    await this.fetchPermissoes();
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
