const routes = [
  {
    path: "/super-admin/empresas",
    component: () => import("@/views/super_admin/empresas/Empresas.vue"),
    meta: {
      component: "Empresas",
      menu: "super_admin",
      title: "Empresas",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.empresa", plural: true },
      ],
    },
  },
  {
    path: "/super-admin/empresa/:empresa_id",
    component: () => import("@/views/super_admin/empresas/Empresa.vue"),
    props: true,
    meta: {
      menu: "super_admin",
      title: "Empresa",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.empresa",
          plural: true,
          to: { path: "/super-admin/empresas" },
        },
        { text: "dynamic_crumb" },
      ],
      voltar: { path: "/super-admin/empresas" },
    },
  },
  {
    path: "/super-admin/empresas/adicionar",
    component: () => import("@/views/super_admin/empresas/NovaEmpresa.vue"),
    meta: {
      menu: "super_admin",
      title: "Adicionar Empresa",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.empresa",
          plural: true,
          to: { path: "/super-admin/empresas" },
        },
        { text: "global.add" },
      ],
      voltar: { path: "/super-admin/empresas" },
    },
  },
];

export default routes;
