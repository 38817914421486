<template>
  <v-dialog
    v-model="dialogCategoria"
    @click:outside="$emit('update:dialogCategoria', false)"
    @keydown.esc="$emit('update:dialogCategoria', false)"
    width="700px"
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.categoria") }}
      </v-card-title>
      <v-divider></v-divider>
      <div
        class="px-4 pt-4 d-block d-sm-flex align-center justify-space-between"
      >
        <v-text-field
          style="max-width: 200px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-form
          @submit.prevent
          class="d-flex"
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <div class="d-flex mt-4 mt-sm-0">
            <v-text-field
              v-if="isAdding"
              v-model="categoria.descricao"
              :label="$tc('global.categoria')"
              dense
              hide-details
              class="pr-4"
              ref="categoriaDescricao"
              :rules="formRules"
              required
            ></v-text-field>
            <v-btn
              v-if="!isAdding"
              color="button_1"
              class="white--text"
              @click="isAdding = !isAdding"
            >
              <v-icon left> mdi-plus</v-icon>
              {{ $tc("global.add") }}
            </v-btn>
            <v-btn
              v-if="isAdding"
              color="button_1"
              class="white--text"
              @click="createCategoria"
              :disabled="!validForm"
            >
              <v-icon left> mdi-check</v-icon>
              {{ $tc("global.salvar") }}
            </v-btn>
          </div>
        </v-form>
      </div>
      <v-card-text class="py-5">
        <v-data-table
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="categorias"
          :items-per-page="10"
          disable-sort
          class="data-tables data-tables__row-click"
        >
          <template v-slot:item.descricao="{ item, index }">
            <v-text-field
              v-if="editItem === index && isEditing"
              v-model="categoria_editar.descricao"
              dense
              hide-details
              :rules="formRules"
              required
              class="mb-2"
            ></v-text-field>
            <div v-else>{{ item.descricao }}</div>
          </template>

          <template v-slot:item.status="{ item, index }">
            <div class="d-flex align-center justify-center">
              <v-switch
                v-if="isEditing && index === editItem"
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                v-model="categoria_editar.status"
              ></v-switch>
              <v-switch
                v-else
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                disabled
                v-model="item.status"
              ></v-switch>
            </div>
          </template>

          <template v-slot:item.editar="{ item, index }">
            <!-- Editar -->
            <v-btn
              v-if="!isEditing || editItem != index"
              small
              icon
              class=""
              @click="editRow(item, index)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <div class="d-flex align-center justify-center">
              <!-- Salvar -->
              <v-btn
                v-if="isEditing && editItem === index"
                small
                icon
                class=""
                @click="updateCategoria(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>

              <!-- Cancelar -->
              <v-btn
                v-if="isEditing && editItem === index"
                small
                icon
                class="ml-1"
                @click="closeEditing(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getAllProClass,
  postProClass,
  putProClass,
} from "@/api/produtos/produtos.js";
import { inputRequired } from "@/helpers/utils.js";
export default {
  name: "ProdutoClassificacoes",

  props: {
    dialogCategoria: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      categorias: [],
      categoria: {},
      loading: false,
      search: "",
      isAdding: false,
      isEditing: false,
      validForm: true,
      categoria_editar: {},
      formRules: [inputRequired],
      editItem: null,
      itemOriginal: null,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.categoria"),
          value: "descricao",
        },
        {
          text: "Status",
          sortable: false,
          width: "80px",
          align: "center",
          value: "status",
        },
        {
          text: this.$tc("global.editar"),
          sortable: false,
          width: "80px",
          align: "center",
          value: "editar",
        },
      ];
    },
  },
  methods: {
    editRow(item, index) {
      this.itemOriginal = { ...item };
      this.categoria_editar = { ...item };
      this.isEditing = true;
      this.isAdding = false;
      this.editItem = index;
    },

    getProClass() {
      getAllProClass()
        .then((response) => {
          this.categorias = response;
        })
        .catch(() => {});
    },
    createCategoria() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        postProClass(this.categoria)
          .then(() => {
            this.getProClass();
            this.isAdding = false;
            this.categoria.descricao = null;
          })
          .catch((error) => {
            this.$toast.error("Categoria já existente!");
            this.$refs.categoriaDescricao.reset();
            this.$refs.categoriaDescricao.focus();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateCategoria() {
      if (
        JSON.stringify(this.itemOriginal) ===
        JSON.stringify(this.categoria_editar)
      ) {
        this.$toast.error("Por favor faça uma modificação antes de salvar");
        return;
      }
      if (
        this.categoria_editar.descricao === null ||
        this.categoria_editar.descricao === "" ||
        this.categoria_editar.descricao === undefined
      ) {
        this.$toast.error("Por favor preencha o campo de categoria!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        putProClass(this.categoria_editar.id, this.categoria_editar)
          .then(() => {
            this.loading = false;
            this.isEditing = false;
            this.editItem = null;
            this.getProClass();
          })
          .catch((error) => {
            this.$toast.error("Categoria já existente!");
          });
      }
    },
    closeEditing(item) {
      this.itemOriginal = { ...item };
      this.isEditing = false;
      this.editItem = null;
    },
  },

  mounted() {
    this.getProClass();
  },
};
</script>

<style lang="scss" scoped></style>
