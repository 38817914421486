import store from "@/store/index.js";
import { format, parseISO } from "date-fns";

async function layoutEmpresa() {
  const { empresa } = store.state.Usuario;
  const { datafrom, datato } = store.state.FiltrosRelatorios.filtros;
  const filtros = store.state.FiltrosRelatorios.filtros;

  let bodyPDF = {};

  const cabecalho = {
    margin: [-13, -13, -13, 0],
    table: {
      widths: ["*"],
      body: [
        [
          {
            // fillColor: "#F5F5F5",
            border: [false, false, false, false],
            margin: [15, 0, 0, 0],
            columns: [
              {
                stack: [
                  {
                    image: empresa.logo64 ? empresa.logo64 : "",
                    width: 80,
                    height: 80,
                  },
                ],

                width: 90,
              },
              {
                stack: [
                  {
                    text: empresa.nome,
                    // color: "#009cde",
                    fontSize: 12,
                    bold: true,
                  },
                  {
                    text:
                      "RUC: " + (empresa.documento ? empresa.documento : ""),
                    // color: "#009cde",
                  },
                  {
                    text: "E-mail: " + (empresa.email ? empresa.email : ""),
                    // color: "#009cde",
                  },
                  {
                    text:
                      "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                    // color: "#009cde",
                  },
                  {
                    text:
                      "Dirección: " +
                      (empresa.endereco ? empresa.endereco : " "),
                    // color: "#009cde",
                  },
                ],
                fontSize: 10,
                alignment: "left",
                margin: [45, 5, 0, 0],
              },
            ],
          },
        ],
      ],
    },
  };

  const cabecalhoRecibo = {
    margin: [0, 0, 0, 0],
    table: {
      widths: ["*", "*"],
      body: [
        [
          {
            // fillColor: "#F5F5F5",
            margin: [10, 2, 2, 2],
            border: [true, true, false, false],
            stack: [
              {
                image: empresa.logo64 ? empresa.logo64 : "",
                width: 90,
                height: 80,
              },
            ],
          },
          {
            border: [false, true, true, false],

            stack: [
              {
                text: empresa.nome,
                // color: "#009cde",
                fontSize: 12,
                bold: true,
              },
              {
                text: "RUC: " + (empresa.documento ? empresa.documento : ""),
                // color: "#009cde",
              },
              {
                text: "E-mail: " + (empresa.email ? empresa.email : ""),
                // color: "#009cde",
              },
              {
                text: "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                // color: "#009cde",
              },
              {
                text:
                  "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                // color: "#009cde",
              },
            ],
            fontSize: 10,
            alignment: "left",
            margin: [-165, 10, 0, 0],
          },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i, node) {
        return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
      },
      vLineWidth: function (i, node) {
        return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
      },
    },
  };

  let periodo = null;
  // definicao de periodo de data do relatorio
  if (datafrom && datato) {
    let from = format(parseISO(datafrom), "dd/MM/yyyy");
    let to = format(parseISO(datato), "dd/MM/yyyy");

    if (from === to) {
      periodo = `${from}`;
    } else {
      periodo = `Entre ${from} y ${to}`;
    }
  } else {
    periodo = "Sin datos";
  }

  let queryString = "?";
  Object.keys(filtros).forEach((key) => {
    if (filtros[key]) {
      queryString += `${key}=${filtros[key]}&`;
    }
  });

  bodyPDF.cabecalho = cabecalho;
  bodyPDF.cabecalhoRecibo = cabecalhoRecibo;
  bodyPDF.periodo = periodo;
  bodyPDF.queryString = queryString;

  return bodyPDF;
}

export { layoutEmpresa };
