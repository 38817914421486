<template>
  <v-dialog
    v-model="dialogTipoCompras"
    @click:outside="$emit('update:dialogTipoCompras', false)"
    @keydown.esc="$emit('update:dialogTipoCompras', false)"
    width="700px"
  >
    <v-card dense>
      <v-card-title>
        <span class="text-h6">{{ $tc("global.escolhacompra") }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:dialogTipoCompras', false)" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <div
        no-gutters
        class="d-flex align-center justify-space-between px-8  py-4"
      >
        <v-btn
          @click="openCompraImportacao"
          color="secondary"
          class="pa-6   text-subtitle-1"
        >
          {{ $tc("global.importacao") }}
        </v-btn>

        <v-btn
          @click="openCompraLocal"
          color="secondary"
          class="pa-6   text-subtitle-1"
        >
          {{ $tc("global.localSemFatura") }}
        </v-btn>

        <v-btn
          @click="openCompraLocalFatura"
          color="secondary"
          class="pa-6   text-subtitle-1"
        >
          {{ $tc("global.localFatura") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogTipoCompras",

  props: {
    dialogTipoCompras: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  methods: {
    openCompraLocal() {
      this.$emit("update:dialogTipoCompras", false);
      this.$emit("compra-local");
    },
    openCompraLocalFatura() {
      this.$emit("update:dialogTipoCompras", false);
      this.$emit("compra-local-fatura");
    },
    openCompraImportacao() {
      this.$emit("update:dialogTipoCompras", false);
      this.$emit("compra-importacao");
    },
  },
};
</script>

<style></style>
