var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageToolbar',{attrs:{"title":_vm.$tc('global.orcamento', 2),"icon":"mdi-file-percent-outline","dark":""}}),(_vm.dialogOrcamento)?_c('DialogOrcamento',{attrs:{"dialogOrcamento":_vm.dialogOrcamento,"editar":_vm.editar,"item":_vm.orcamento},on:{"update:dialogOrcamento":function($event){_vm.dialogOrcamento=$event},"update:dialog-orcamento":function($event){_vm.dialogOrcamento=$event},"fetch-orcamentos":_vm.fetchOrcamentos}}):_vm._e(),_c('v-card',[_c('v-card-title',{staticClass:"tocs_gray_1"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),(_vm.POrcamentoAdicionar)?_c('v-btn',{staticClass:"white--text mt-4 mt-sm-0",attrs:{"color":"button_1"},on:{"click":_vm.addOrcamento}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" ")],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables",class:{ 'data-tables__row-click': _vm.POrcamentoVisualizar },attrs:{"loading":_vm.loading,"headers":_vm.headers,"search":_vm.search,"items":_vm.orcamentos,"items-per-page":10},on:{"click:row":_vm.goToOrcamento},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('span',[_vm._v(" Aberto ")]):_vm._e(),(item.status === 1)?_c('span',[_vm._v(" Finalizado ")]):_vm._e(),(item.status === -1)?_c('span',[_vm._v(" Cancelado ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.POrcamentoVisualizar,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.gerarpdf(item)}}},[_c('v-icon',[_vm._v("mdi-file-export")])],1)]}}])}),(_vm.pdfDialogSimples)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialogSimples,"pdfData":_vm.pdfDataSimples,"title":("Orçamento Nº.: " + (this.orcamento.id))},on:{"update:pdfDialog":function($event){_vm.pdfDialogSimples=$event},"update:pdf-dialog":function($event){_vm.pdfDialogSimples=$event}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }