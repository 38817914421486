import api from "../axios_service.js";

export async function fetchProdListasPrecos(filtros) {
  const response = await api.get(`/prodlistasprecos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchProdListaPrecos(id) {
  const response = await api.get(`/prodlistasprecos/${id}`);
  return response.data.result;
}

export async function putProdListaPrecos(id, prodlistasprecos) {
  let body = {};
  for (let key in prodlistasprecos) {
    body[key] = prodlistasprecos[key];
  }
  return api.put(`/prodlistasprecos/${id}`, body);
}

export async function postProdListaPrecos(prodlistasprecos) {
  let body = {};
  for (let key in prodlistasprecos) {
    body[key] = prodlistasprecos[key];
  }
  return api.post("/prodlistasprecos/add", body);
}

export function deleteProdListaPrecos(id) {
  return api.delete(`/prodlistasprecos/${id}`);
}
