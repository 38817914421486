<template>
  <v-dialog
    v-model="dialogTicket"
    @click:outside="fecharDialog"
    @keydown.esc="fecharDialog"
    width="450px"
  >
    <v-card>
      <v-card-title class="px-6 toolbar">
        <span class="white--text"> Deseja imprimir? </span>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            @click="pdfTicket"
            :loading="loading"
            color="tocs_gray_1"
            class="vertical-button mt-4"
            small
          >
            <v-icon class="pt-2" size="35" color="tocs_gray_2"
              >mdi-ticket-confirmation-outline</v-icon
            >
            <span class="pb-2 font-weight-bold"> Ticket </span>
          </v-btn>
        </div>
      </v-card-text>
      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        @close="loading = false"
        title="PDF"
      />
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" small class="white--text" color="button_2">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const importTicket = () =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_ticket.js");

export default {
  name: "DialogTicket",
  props: {
    dialogTicket: {
      type: Boolean,
      default: false,
    },
    venda_id: {
      type: [Number],
    },
  },
  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      loading: false,
      pdfDialog: false,
      pdfData: {},
    };
  },

  computed: {
    sizeButtomTransf() {
      return this.$vuetify.lang.current === "en" ? false : true;
    },
  },

  methods: {
    fecharDialog() {
      this.$emit("fechar-dialog");
    },
    async pdfTicket() {
      this.loading = true;

      try {
        importTicket()
          .then((module) => {
            let generate = module.default;
            generate(this.venda_id, false, false).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {},
};
</script>

<style>
.vertical-button,
.vertical-button span.v-btn__content {
  height: 80px !important;
  width: 90px !important;
}
.vertical-button span.v-btn__content {
  flex-direction: column;
  justify-content: space-around;
}
</style>
