<template>
  <v-dialog
    v-model="dialogCompensacaoBanco"
    @click:outside="$emit('update:dialogCompensacaoBanco', false)"
    @keydown.esc="$emit('update:dialogCompensacaoBanco', false)"
    width="85%"
    scrollable
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.compensacao") }} {{ $tc("global.banco", 2) }}

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogCompensacaoBanco', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-8" style="overflow: auto">
        <v-row dense>
          <v-col class="">
            <v-card outlined>
              <v-data-table
                :headers="headersBancos"
                :items="selectedBancos"
                hide-default-footer
                item-key="id"
                class="data-table"
              >
                <template v-slot:item.valor="{ item }">
                  <div v-if="item.moeda_sigla == 'G$'">
                    {{ item.valor | guarani }}
                  </div>
                  <div v-else>
                    {{ item.valor | currency }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
            <v-card class="mt-2" outlined>
              <v-data-table
                hide-default-footer
                :items="grupoBancos"
                :loading="loadingGrupoBancos"
                :headers="headers"
              >
                <template v-slot:item.total="{ item }">
                  <div v-if="item.moeda_sigla == 'G$'">
                    {{ item.total | guarani }}
                  </div>
                  <div v-else>
                    {{ item.total | currency }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="button_2"
          @click="compensar"
          class="white--text"
          depressed
          small
        >
          {{ $tc("global.compensar") }}
          <v-icon right>mdi-cash-sync</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postCompensarBancos } from "@/api/compensacao-bancos/compensacao_bancos.js";
export default {
  name: "DialogCompensacaoCartoes",

  props: {
    dialogCompensacaoBanco: {
      type: Boolean,
      default: false,
    },
    selectedBancos: {
      type: Array,
      default: () => [],
    },
  },

  components: {},

  data() {
    return {
      loading: false,
      selectedCaixa: null,
      referencia: "",
      caixas: [],
      loadingBancos: true,
      grupoBancos: [],
      loadingGrupoBancos: false,
    };
  },

  watch: {},

  computed: {
    headersBancos() {
      return [
        {
          text: this.$tc("global.caixa"),
          value: "caixa_descricao",
          sortable: false,
          // width: "70px",
        },
        {
          text: this.$tc("global.documento"),
          value: "documento",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          sortable: false,
          // width: "180px",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$tc("global.destino"),
          value: "caixa_descricao",
          sortable: false,
          // width: "100px",
        },

        {
          text: "documento",
          value: "documento",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
          sortable: false,
          right: true,
          // width: "100px",
        },
      ];
    },
  },

  methods: {
    inputReferencia() {
      this.loadingGrupoBancos = true;
      this.grupoBancos = [];

      let total_valor = this.selectedBancos.reduce(
        (accumulator, item) => accumulator + Number(item.valor),
        0
      );
      let obj = {
        documento: this.selectedBancos.reduce(
          (accumulator, item, index) =>
            `${accumulator}${item.documento}${
              index != this.selectedBancos.length - 1 ? " - " : ""
            }`,
          ""
        ),
        moeda_sigla: this.selectedBancos[0].moeda_sigla,
        moeda_id: this.selectedBancos[0].moeda_id,
        caixa_id: this.selectedBancos[0].caixa_id,
        caixa_descricao: this.selectedBancos[0].caixa_descricao,
        total: total_valor,
      };
      this.grupoBancos.push(obj);

      this.loadingGrupoBancos = false;
    },

    compensar() {
      this.loading = true;

      let data = {
        caixa_destino_id: this.selectedBancos[0].caixa_id,
        banco: this.grupoBancos[0],
        bancos: this.selectedBancos,
      };
      postCompensarBancos(data)
        .then((response) => {
          this.$emit("update:dialogCompensacaoBanco", false);
          this.$emit("fetch-bancos");
        })
        .catch((error) => {
          this.$toast.error(this.$tc("global.erro"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.inputReferencia();
  },
};
</script>

<style></style>
