import api from "../axios_service.js";

export async function fetchFormasPagamentos(filtros) {
  const response = await api.get(`/formas-pagamentos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchFormasPagamentosAtivo(filtros) {
  const response = await api.get(
    `/formas-pagamentos-ativo${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchFormasPagamento(id) {
  const response = await api.get(`/formas-pagamentos/${id}`);
  return response.data.result;
}

export function putFormasPagamento(id, formaspagamento) {
  return api.post(`/formas-pagamentos/${id}`, formaspagamento);
}

export function postFormasPagamento(formaspagamento) {
  return api.post("/formas-pagamentos/add", formaspagamento);
}
