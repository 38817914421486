<template>
  <v-dialog
    v-model="dialogReciboManual"
    @click:outside="fecharDialog()"
    @keydown.esc="fecharDialog()"
    scrollable
    max-width="700px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{ edit ? $tc("global.editar") : $tc("global.add") }}
        {{ $tc("global.recibo") }}
        <v-spacer></v-spacer>

        <v-btn icon @click="fecharDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card flat :loading="loading">
        <v-card-text>
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense>
              <!-- DATA RECIBO -->
              <v-col cols="12" md="6">
                <DataField
                  :data_sync.sync="recibo.data_recibo"
                  label="Data Recibo"
                  disabled
                  hide_details
                />
              </v-col>

              <!-- documento -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recibo.documento"
                  :label="$tc('global.documento')"
                  :rules="formRules"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- QUEM PAGOU -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recibo.quem_pagou"
                  :label="$tc('global.quemPagou')"
                  :rules="formRules"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- QUEM ASSINA -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recibo.quem_assina"
                  :label="$tc('global.responsavel')"
                  :placeholder="$tc('global.quemassina')"
                  :rules="formRules"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- MOEDA -->
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="selectedMoeda"
                  :label="$tc('global.moeda')"
                  :items="moedas"
                  :rules="formRules"
                  dense
                  outlined
                  required
                  item-text="sigla"
                  item-value="id"
                ></v-combobox>
              </v-col>

              <!-- VALOR -->
              <v-col cols="12" md="6">
                <v-text-field
                  ref="valorRecibo"
                  v-model="valorRecibo"
                  :label="$tc('global.valor')"
                  :rules="formRules"
                  :disabled="selectedMoeda ? false : true"
                  dense
                  outlined
                  v-currency="vCurrencyOptions"
                ></v-text-field>
              </v-col>

              <!-- DESCRICÃO -->
              <v-col cols="12">
                <v-textarea
                  v-model="recibo.concepto_descricao"
                  :rules="formRules"
                  label="Concepto"
                  dense
                  rows="2"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="button_2"
            class="white--text"
            depressed
            small
            :loading="loadingRecibo"
            :disabled="!validForm"
            @click="edit ? atualizarRecibo() : criarRecibo()"
          >
            {{ edit ? "Atualizar Recibo" : "Gerar Recibo" }}
            <v-icon right>
              {{ edit ? "mdi-content-save" : "mdi-plus" }}</v-icon
            ></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createRecibo,
  updateRecibo,
  fetchOneRecibo,
} from "@/api/recibos/recibos.js";
import { inputRequired } from "@/helpers/utils.js";
import { mapState } from "vuex";
export default {
  name: "DialogReciboManual",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    dialogReciboManual: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
    origem: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedMoeda: null,
      recibo: {},
      valorRecibo: null,
      loading: false,
      loadingRecibo: false,
      validForm: true,
      formRules: [inputRequired],
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    valorRecibo() {
      this.recibo.valor_recibo = this.$ci.parse(
        this.valorRecibo,
        this.vCurrencyOptions
      );
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.recibo.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
  },

  methods: {
    fecharDialog() {
      this.$emit("update:edit", false);
      this.$emit("update:dialogReciboManual", false);
    },

    criarRecibo() {
      if (this.$refs.form.validate()) {
        this.loadingRecibo = true;
        let recibo_2 = {};

        recibo_2.quem_assina = this.recibo.quem_assina;
        recibo_2.quem_pagou = this.recibo.quem_pagou;
        recibo_2.documento = this.recibo.documento;
        recibo_2.data_recibo = this.recibo.data_recibo;
        recibo_2.origem_id = 0;
        recibo_2.concepto_descricao = this.recibo.concepto_descricao;
        recibo_2.moeda_id = this.recibo.moeda_id;
        recibo_2.valor_recibo = this.recibo.valor_recibo;
        recibo_2.origem = this.origem;

        createRecibo(recibo_2)
          .then(() => {
            this.$emit("update:dialogReciboManual", false);
            this.$emit("fetchAll");
          })
          .catch((error) => {})
          .finally(() => {
            this.loadingRecibo = false;
          });
      }
    },

    atualizarRecibo() {
      if (this.$refs.form.validate()) {
        this.loadingRecibo = true;
        let recibo_2 = {};

        recibo_2.quem_assina = this.recibo.quem_assina;
        recibo_2.quem_pagou = this.recibo.quem_pagou;
        recibo_2.documento = this.recibo.documento;
        recibo_2.data_recibo = this.recibo.data_recibo;
        recibo_2.concepto_descricao = this.recibo.concepto_descricao;
        recibo_2.moeda_id = this.recibo.moeda_id;
        recibo_2.valor_recibo = this.recibo.valor_recibo;

        updateRecibo(this.recibo.id, recibo_2)
          .then(() => {
            this.$emit("update:dialogReciboManual", false);
            this.$emit("fetchAll");
          })
          .catch((error) => {})
          .finally(() => {
            this.loadingRecibo = false;
          });
      }
    },
  },
  async mounted() {
    this.loading = true;
    if (this.edit) {
      this.recibo = await fetchOneRecibo(this.item.id);
      this.selectedMoeda = this.moedas.find(
        (moeda) => moeda.id_moeda === this.recibo.moeda_id
      );
      this.$ci.setValue(this.$refs.valorRecibo, this.recibo.valor_recibo);
    } else {
      this.recibo.data_recibo = new Date();
    }
    this.loading = false;
  },
};
</script>

<style></style>
