<template>
  <v-dialog
    v-model="dialogVariavel"
    max-width="800px"
    width="100%"
    @click:outside="$emit('update:dialogVariavel', false)"
    @keydown.esc="$emit('update:dialogVariavel', false)"
    scrollable
  >
    <v-card :disabled="loading" :loading="loading">
      <v-card-title class="lightgray">
        {{ !add ? "Editar Campo" : "Adicionar Campo" }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogVariavel', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <span class="red--text" v-if="automatico">
          ** Essa Variavel não pode ser editada devido vinculo ao Plano de
          Contas **</span
        >
        <v-container fluid class="pb-0">
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense>
              <!-- Nome Campo -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="variavel.campo"
                  label="Nome Campo"
                  dense
                  outlined
                  :readonly="automatico"
                  :rules="formRules"
                  required
                ></v-text-field>
              </v-col>

              <!-- Grupos -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedGrupo"
                  :items="grupos"
                  :clearable="automatico ? false : true"
                  dense
                  outlined
                  :readonly="automatico"
                  label="Grupo"
                  :rules="formRules"
                  required
                ></v-select>
              </v-col>

              <!-- Categoria -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedCategoria"
                  :items="categorias"
                  :clearable="automatico ? false : true"
                  dense
                  outlined
                  :readonly="automatico"
                  label="Categoria"
                  :rules="formRules"
                  required
                ></v-select>
              </v-col>

              <!-- Tipo -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedTipo"
                  :items="tipos"
                  :clearable="
                    selectedCategoria === 'PLANO_DE_CONTAS' &&
                    selectedGrupo === 'PLANO_DE_CONTAS'
                      ? false
                      : true
                  "
                  dense
                  outlined
                  label="Tipo"
                  :readonly="
                    selectedCategoria === 'PLANO_DE_CONTAS' &&
                    selectedGrupo === 'PLANO_DE_CONTAS'
                      ? true
                      : false
                  "
                  :rules="formRules"
                  required
                ></v-select>
              </v-col>

              <!-- Valores -->
              <v-col cols="12">
                <!-- Valor tipo {{ text }} -->
                <v-text-field
                  v-if="
                    selectedTipo === 'TEXT' &&
                    variavel.categoria !== 'PLANO_DE_CONTAS'
                  "
                  v-model="variavel.valor"
                  label="Valor"
                  dense
                  outlined
                ></v-text-field>

                <v-text-field
                  v-if="
                    selectedTipo === 'NUMBER' &&
                    variavel.categoria !== 'PLANO_DE_CONTAS'
                  "
                  v-model="variavel.valor"
                  label="Valor"
                  dense
                  outlined
                ></v-text-field>

                <!-- Valor tipo {{ textarea }} -->
                <v-textarea
                  v-if="
                    selectedTipo === 'TEXTAREA' &&
                    variavel.categoria !== 'PLANO_DE_CONTAS'
                  "
                  v-model="variavel.valor"
                  label="Valor"
                  auto-grow
                  :rows="2"
                  dense
                  outlined
                ></v-textarea>

                <!-- Valor tipo {{ boolean }} -->
                <v-text-field
                  v-if="
                    selectedTipo === 'BOOLEAN' &&
                    variavel.categoria !== 'PLANO_DE_CONTAS'
                  "
                  dense
                  outlined
                  value="Valor:"
                >
                  <template v-slot:append>
                    <v-switch
                      v-model="variavel.valor"
                      false-value="0"
                      true-value="1"
                      class="mt-0 pt-0"
                      hide-details
                    >
                    </v-switch>
                  </template>
                </v-text-field>
                <v-col
                  cols="12"
                  md="12"
                  class="pa-0"
                  v-if="
                    variavel.categoria === 'PLANO_DE_CONTAS' &&
                    variavel.grupo === 'PLANO_DE_CONTAS'
                  "
                >
                  <DialogSelectPlanoContas
                    v-if="dialogSelectPlanoContas"
                    :dialogSelectPlanoContas.sync="dialogSelectPlanoContas"
                    @select-plano-contas="selectPlanoConta"
                    :automatico="automatico"
                    filtro_tipo
                  />
                  <v-card
                    v-if="!contaSelected"
                    outlined
                    width="100%"
                    class="d-flex align-center justify-space-between"
                    height="40px"
                    @click="dialogSelectPlanoContas = true"
                  >
                    <span
                      class="pl-4 text-subtitle-1 font-weight-light lightgrey--text"
                    >
                      {{ $tc("global.selecionePlanoPadraoVendas") }}
                    </span>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                  </v-card>

                  <div class="d-flex">
                    <v-combobox
                      v-if="contaSelected"
                      v-model="contaSelected"
                      class="ma-0"
                      height="40px"
                      :items="contas"
                      label="Contas"
                      :clearable="automatico ? false : true"
                      item-text="descricao"
                      item-value="id_conta"
                      @click:clear="variavel.valor = null"
                      dense
                      outlined
                      hide-details
                    ></v-combobox>

                    <v-btn
                      v-if="contaSelected && !automatico"
                      text
                      fab
                      small
                      @click="dialogSelectPlanoContas = true"
                    >
                      <v-icon size="18">mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="button_1"
          class="white--text"
          @click="add ? addVariavel() : updateVariavel()"
          :disabled="!validForm"
        >
          Salvar
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { update, create } from "@/api/empresas/empresa_variaveis.js";
import { inputRequired } from "@/helpers/utils.js";
import { fetchPlanosContas } from "@/api/plano-contas/plano_contas.js";

export default {
  name: "VariavelDialog",

  props: {
    dialogVariavel: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    add: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    DialogSelectPlanoContas: () =>
      import(
        "@/views/configuracoes/caixas/components/DialogSelectPlanoContas.vue"
      ),
  },

  data() {
    return {
      tipos: ["TEXT", "NUMBER", "BOOLEAN", "TEXTAREA"],
      grupos: ["MODULOS", "SEQUENCE", "CONFIGURACOES", "PLANO_DE_CONTAS"],
      categorias: ["MODULOS", "SEQUENCE", "CONFIGURACOES", "PLANO_DE_CONTAS"],
      variavel: {},
      variavel_original: {},
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      dialogSelectPlanoContas: false,
      contaSelected: null,
      contas: [],
      selectedGrupo: null,
      selectedCategoria: null,
      selectedTipo: null,
      automatico: null,
    };
  },

  computed: {
    empresa_id() {
      return this.$route.params.empresa_id;
    },
    validaPlanoContas() {
      let result = true;

      if (
        this.variavel.categoria === "PLANO_DE_CONTAS" &&
        this.variavel.grupo === "PLANO_DE_CONTAS" &&
        !this.contaSelected
      ) {
        result = false;
      }

      return result;
    },
  },

  watch: {
    contaSelected() {
      if (this.contaSelected) {
        this.variavel.valor = this.contaSelected.id;
      }
    },
    selectedGrupo() {
      if (this.selectedGrupo) {
        this.variavel.grupo = this.selectedGrupo;
        if (this.selectedGrupo !== "PLANO_DE_CONTAS") {
          if (this.selectedCategoria === "PLANO_DE_CONTAS") {
            this.selectedCategoria = null;
          }
          this.contaSelected = null;
        } else {
          this.selectedCategoria = "PLANO_DE_CONTAS";
          this.selectedTipo = "TEXT";
        }
      }
    },
    selectedCategoria() {
      if (this.selectedCategoria) {
        this.variavel.categoria = this.selectedCategoria;
        if (this.selectedCategoria !== "PLANO_DE_CONTAS") {
          if (this.selectedGrupo === "PLANO_DE_CONTAS") {
            this.selectedGrupo = null;
          }
          this.contaSelected = null;
        } else {
          this.selectedGrupo = "PLANO_DE_CONTAS";
          this.selectedTipo = "TEXT";
        }
      }
    },

    selectedTipo() {
      if (this.selectedTipo) {
        this.variavel.tipo = this.selectedTipo;
      }
    },
  },

  methods: {
    selectPlanoConta(plano_selecionado) {
      this.contaSelected = plano_selecionado;
      if (this.contaSelected) {
        this.variavel.valor = this.contaSelected.id;
      }
    },

    getPlanosContas() {
      this.loading = true;
      fetchPlanosContas().then((response) => {
        let planocontaSelecionado = response.find(
          (plano_conta) => plano_conta.id == this.variavel.valor
        );
        this.automatico = planocontaSelecionado.automatico;
        this.contaSelected = planocontaSelecionado;
        this.loading = false;
      });
    },

    async addVariavel() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.validaPlanoContas) {
          let body = {};
          body.empresa_id = this.empresa_id;

          for (let key in this.variavel) {
            if (
              this.variavel[key] !== undefined &&
              this.variavel[key] !== null &&
              this.variavel[key] !== ""
            ) {
              body[key] = this.variavel[key];
            }
          }

          await create(body);
          this.$emit("fetch-variaveis");
          this.loading = false;
          this.$emit("update:dialogVariavel", false);
        } else {
          this.$toast.error(
            this.$tc("global.selecionar") + " " + this.$tc("global.planocontas")
          );
          this.loading = false;
        }
      }
    },

    async updateVariavel() {
      if (
        JSON.stringify(this.variavel) === JSON.stringify(this.variavel_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let body = {};

        for (let key in this.variavel) {
          if (
            this.variavel[key] !== undefined &&
            this.variavel[key] !== null &&
            this.variavel[key] !== "" &&
            key != "id" &&
            key != "created_at" &&
            key != "update_at"
          ) {
            body[key] = this.variavel[key];
          }
        }

        await update(this.variavel.id, body);
        this.$emit("fetch-variaveis");
        this.loading = false;
        this.$emit("update:dialogVariavel", false);
      }
    },
  },

  mounted() {
    this.variavel = { ...this.item };
    this.variavel_original = { ...this.item };
    if (
      this.variavel &&
      this.variavel.grupo &&
      this.variavel.categoria &&
      this.variavel.tipo
    ) {
      this.selectedGrupo = this.variavel.grupo;
      this.selectedCategoria = this.variavel.categoria;
      this.selectedTipo = this.variavel.tipo;
      if (
        this.selectedGrupo === "PLANO_DE_CONTAS" &&
        this.selectedCategoria === "PLANO_DE_CONTAS"
      ) {
        if (this.variavel.valor) {
          this.getPlanosContas();
          this.automatico = true;
        }
      }
    }
  },
};
</script>

<style></style>
