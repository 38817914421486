<template>
  <v-dialog
    v-model="dialogTermoCheckList"
    @click:outside="fecharDialog"
    @keydown.esc="fecharDialog"
    width="550px"
  >
    <v-card>
      <v-card-title class="px-6 toolbar">
        <span class="white--text"> Deseja imprimir? </span>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            @click="gerarTermo"
            :loading="loading_termo"
            color="tocs_gray_1"
            class="mt-4 vertical-button"
            small
          >
            <div class="d-block">
              <div>
                <v-icon class="pt-2" size="25" color="tocs_gray_2">
                  mdi-file-export
                </v-icon>
              </div>
              <div>
                <span class="pb-2 font-weight-bold">
                  {{ $tc("global.termo") }}
                </span>
              </div>
            </div>
          </v-btn>
          <v-btn
            @click="gerarCheckList"
            :loading="loading_checklist"
            color="tocs_gray_1"
            class="mt-4 vertical-button"
            small
          >
            <div class="d-block">
              <div>
                <v-icon class="pt-2" size="25" color="tocs_gray_2">
                  mdi-check-underline-circle-outline
                </v-icon>
              </div>
              <div><span class="pb-2 font-weight-bold"> Check List </span></div>
            </div>
          </v-btn>
        </div>
      </v-card-text>
      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        @close="loading = false"
        title="PDF"
      />
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" small class="white--text" color="button_2">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dataImpressaoFatura } from "@/api/faturas/faturas.js";
import { mapState } from "vuex";

const pdfChecklist = () =>
  import(/* webpackChunkName: "check-list-pdf" */ "../reports/check_list.js");

const pdfTermo = () =>
  import(
    /* webpackChunkName: "check-list-pdf" */ "../reports/termo_piscina.js"
  );

export default {
  name: "DialogTermoCheckList",
  props: {
    dialogTermoCheckList: {
      type: Boolean,
      default: false,
    },
    ordem_servico_id: {
      type: [Number],
    },
    venda_id: {
      type: [Number],
    },
  },
  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      loading_termo: false,
      loading_checklist: false,
      pdfDialog: false,
      pdfData: {},
      dialogAddDocumento: false,
    };
  },

  computed: {
    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
    }),
  },

  methods: {
    fecharDialog() {
      this.$emit("update:dialogTermoCheckList", false);
      this.$emit("closeOs");
    },

    async gerarCheckList() {
      try {
        this.loading_checklist = true;

        await pdfChecklist()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.empresa, this.ordem_servico_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading_checklist = false;
          });
      } catch (error) {
        this.loading_checklist = false;
      }
    },

    async gerarTermo(item) {
      try {
        this.loadingTermo = true;

        await pdfTermo()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.ordem_servico_id, this.venda_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loadingTermo = false;
          });
      } catch (error) {
        this.loadingTermo = false;
      }
    },
  },

  mounted() {},
};
</script>

<style style="scss" scoped>
.vertical-button,
.vertical-button span.v-btn__content {
  height: 80px !important;
  width: 90px !important;
}
.vertical-button span.v-btn__content {
  flex-direction: column;
  justify-content: space-around;
}
</style>
