<template>
  <v-dialog
    v-model="dialogAddPais"
    max-width="290"
    @keydown.esc="closeDialog()"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{ !edit ? $tc('global.novo') + ' ' + $tc('global.pais') : $tc('global.atualizar') + ' ' + $tc('global.pais') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-text-field
            v-model="pais.nome"
            :label="$tc('global.pais')"
            dense
            outlined
            :rules="formRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="pais.sigla"
            label="Sigla"
            dense
            outlined
            :rules="formRules"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? criarPais() : atualizarPais()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { updatePais, createPais } from "@/api/cidades/paises.js";
export default {
  name: "DialogEstados",

  props: {
    dialogAddPais: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },

  data: () => {
    return {
      loading: false,
      pais: {},
      validForm: true,
      formRules: [inputRequired],
    };
  },

  methods: {
    closeDialog() {
      this.pais = {};
      this.$emit("update:dialogAddPais", false);
      this.$emit("update:edit", false);
    },

    criarPais() {
      if (this.$refs.form.validate()) {
        createPais(this.pais)
          .then(() => {
            this.pais = {};
            this.$emit("update:dialogAddPais", false);
            this.$emit("fetch-paises");
          })
          .catch(() => {});
      }
    },

    atualizarPais() {
      //ainda nao
    },
  },
  async mounted() {},
};
</script>

<style></style>
