const routes = [
  {
    path: "/caixas",
    component: () => import("@/views/dashboard/caixas/CaixasAcesso.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.caixa", plural: true },
      icon: "mdi-cash-multiple",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.caixa", plural: true },
      ],
      permission: { componente: "Caixas" },
    },
  },
  {
    path: "/caixas/:caixa_id/movimentacoes",
    props: true,
    component: () =>
      import("@/views/dashboard/caixas/movimentacoes/CaixaMov.vue"),
    meta: {
      menu: "dashboard",
      title: "Caixa Movimentações",
      breadcrumb: [{ text: "global.home", to: { path: "/" } }],
      voltar: { path: "/caixas" },
      permission: { componente: "Caixas" },
    },
  },
];

export default routes;
