<template>
  <v-dialog v-model="dialogMudarPrecosLotes" scrollable persistent>
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-icon left>mdi-priority-high</v-icon>
        {{ $tc("global.mudarPrecosLotes") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogMudarPrecosLotes', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <div class="pt-4 font-weight-bold text-subtitle-1">
              {{ $tc("global.selecioneProdutosAlteracaoValor") }}
            </div>
            <v-text-field
              :placeholder="$tc('global.buscarProdutosPorDescricao')"
              class="py-4"
              v-model="search"
              dense
              outlined
              ref="search"
              clearable
              hide-details
              autofocus
            >
            </v-text-field>
            <v-card outlined>
              <v-data-table
                v-model="produtoSelected"
                :loading="loading"
                :headers="headers"
                :items="produtos"
                :items-per-page="15"
                :search="search"
                show-select
                class="data-tables"
                fixed-header
                height="calc(100vh - 375px)"
              ></v-data-table>
            </v-card>
          </v-col>

          <v-col cols="12" md="1" class="d-flex align-center justify-center">
            <v-divider vertical></v-divider>
          </v-col>

          <v-col cols="12" md="5">
            <div class="pt-4 mb-4 font-weight-bold text-subtitle-1">
              {{ $tc("global.produto", 2) }}
              {{ $tc("global.selecionado", 2) }}
            </div>
            <v-card outlined>
              <v-data-table
                :headers="headers_selected"
                :items="produtoSelected"
                hide-default-footer
                :items-per-page="-1"
                class="data-tables"
                fixed-header
                height="calc(100vh - 258px)"
              >
                <template v-slot:item.actions="{ index }">
                  <v-btn icon small @click="deleteSelectedItem(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          @click="$emit('update:dialogMudarPrecosLotes', false)"
          class="white--text"
          color="button_2"
          depressed
        >
          <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="produtoSelecionadosNextPass"
          :disabled="produtoSelected.length == 0"
          class="white--text"
          color="button_1"
          depressed
        >
          <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogProdutosSelecionados
      v-if="dialogProdutosSelecionados"
      :dialogProdutosSelecionados.sync="dialogProdutosSelecionados"
      :produtoSelected="produtos_ids"
      @fetch-precos="getAllPrecosAlteracoes()"
    />
  </v-dialog>
</template>

<script>
import { getAllProdutos } from "@/api/produtos/produtos.js";
export default {
  name: "DialogMudarPrecosLotes",

  components: {
    DialogProdutosSelecionados: () =>
      import("./DialogProdutosSelecionados.vue"),
  },

  props: {
    dialogMudarPrecosLotes: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      produtos: [],
      produtoSelected: [],
      produtos_ids: [],
      search: null,
      dialogProdutosSelecionados: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "id",
          value: "id",
          align: "start",
          width: "70px",
          divider: true,
        },
        {
          text: "Ref.",
          value: "referencia",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
      ];
    },

    headers_selected() {
      return [
        {
          text: "id",
          value: "id",
          align: "start",
          width: "70px",
          divider: true,
        },
        {
          text: "Ref.",
          value: "referencia",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },

        {
          text: "",
          value: "actions",
          align: "center",
        },
      ];
    },
  },

  watch: {},

  methods: {
    getProdutos() {
      this.loading = true;
      return getAllProdutos()
        .then((response) => {
          this.produtos = response;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    produtoSelecionadosNextPass() {
      this.produtos_ids = [];
      this.produtos_ids = this.produtoSelected.map((produto) => produto.id);
      this.dialogProdutosSelecionados = true;
    },
    getAllPrecosAlteracoes() {
      this.$emit("update:dialogMudarPrecosLotes", false);
      this.$emit("fetch-all-precos");
    },

    deleteSelectedItem(index) {
      this.produtoSelected.splice(index, 1);
    },
  },

  async mounted() {
    await this.getProdutos();
  },
};
</script>

<style></style>
