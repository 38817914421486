<template>
  <v-dialog
    v-model="dialogCompensacaoCheque"
    @click:outside="$emit('update:dialogCompensacaoCheque', false)"
    @keydown.esc="$emit('update:dialogCompensacaoCheque', false)"
    :width="!selectedCaixa ? '75%' : '75%'"
    scrollable
  >
    <v-card flat>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.compensacao") }} {{ $tc("global.cheque") }}

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogCompensacaoCheque', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card flat class="pa-4">
        <v-card-text>
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense>
              <v-col cols="12" md="3">
                <DataField
                  hide_details
                  :data_sync.sync="dataDeposito"
                  :label="$tc('global.data') + $tc('global.deposito')"
                  :obrigatorio="true"
                  :loading="loadingBancos"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="comprovante"
                  :label="$tc('global.comprovante')"
                  :rules="formRules"
                  :loading="loadingBancos"
                  outlined
                  hide-details
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="documento"
                  ref="documento"
                  :label="$tc('global.documento')"
                  :rules="formRules"
                  placeholder="Número da Boleta"
                  dense
                  :loading="loadingBancos"
                  outlined
                  required
                  hide-details
                >
                  <!-- @blur="inputDocumento" -->
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-combobox
                  ref="combobox"
                  v-model="selectedCaixa"
                  :items="caixas"
                  :label="$tc('global.destino')"
                  :readonly="
                    caixas.length === 0 || documento.length === 0 ? true : false
                  "
                  dense
                  outlined
                  :rules="formRules"
                  :loading="loadingBancos"
                  required
                  hide-details
                  item-value="id"
                  item-text="descricao"
                >
                  <!-- <template v-slot:selection="data">
                    <span class="caption">
                      {{ data.item.descricao }} -
                      {{ data.item.banco_nome }}</span
                    >
                  </template> -->
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.descricao"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.banco_nome"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-combobox>
              </v-col>

              <v-col v-show="selectedCaixa" class="">
                <v-card outlined v-if="selectedCheques">
                  <v-data-table
                    :headers="headersCheques"
                    :items="selectedCheques"
                    hide-default-footer
                    item-key="id"
                    class="data-table"
                  >
                    <template v-slot:item.emissor_nome="{ item }">
                      {{ item.emissor_nome | excerpt(25) }}
                    </template>
                    <template v-slot:item.data_emissao="{ item }">
                      {{ item.data_emissao | dateFormat("dd/MM/yyyy") }}
                    </template>

                    <template v-slot:item.data_vencimento="{ item }">
                      {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
                    </template>

                    <template v-slot:item.valor="{ item }">
                      <span v-if="item.moeda_sigla === 'G$'">
                        {{ item.valor | guarani }}
                      </span>
                      <span v-else> {{ item.valor | currency }} </span>
                    </template>
                    <template v-slot:item.origem="{ item }">
                      {{ item.origem == "0" ? "MANUAL" : item.origem }}
                    </template>
                  </v-data-table>
                </v-card>
                <v-card class="mt-2" outlined>
                  <v-data-table
                    hide-default-footer
                    :items="gruopCheques"
                    :loading="loadingGrupoCheques"
                    :headers="headers"
                  >
                    <!-- valor -->
                    <template v-slot:item.valor_mesmo_banco="{ item }">
                      <div v-if="item.moeda_id == 1">
                        {{ item.moeda_sigla }}
                        {{ item.valor_mesmo_banco | guarani }}
                      </div>
                      <div v-else>
                        {{ item.moeda_sigla }}
                        {{ item.valor_mesmo_banco | currency }}
                      </div>
                    </template>

                    <!-- valor -->
                    <template v-slot:item.valor_diferente_banco="{ item }">
                      <div v-if="item.moeda_id == 1">
                        {{ item.moeda_sigla }}
                        {{ item.valor_diferente_banco | guarani }}
                      </div>
                      <div v-else>
                        {{ item.moeda_sigla }}
                        {{ item.valor_diferente_banco | currency }}
                      </div>
                    </template>
                    <template v-slot:body.append="{ headers }">
                      <tr class="lightgray font-weight-bold text-body-1">
                        <td :colspan="headers.length - 2"></td>
                        <td class="text-right">Total</td>
                        <td class="text-right">
                          {{ totalValorBancos | currency }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!validForm"
          :loading="loading"
          color="button_2"
          class="white--text"
          depressed
          @click="compensar"
          small
        >
          {{ $tc("global.compensar") }}
          <v-icon right>mdi-cash-sync</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchCaixasContaCorrente } from "@/api/caixas/caixas.js";
import { compensarCheques } from "@/api/cheques/cheques.js";
export default {
  name: "DialogCompensacaoCheques",
  props: {
    selectedCheques: {
      type: Array,
      required: true,
    },
    dialogCompensacaoCheque: {
      type: Boolean,
      required: true,
    },
  },
  components: { DataField: () => import("@/components/fields/DataField.vue") },
  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      cheques: [],
      loadingBancos: true,
      documento: "",
      caixas: [],
      gruopCheques: [],
      selectedCaixa: null,
      dataDeposito: null,
      comprovante: null,
      loadingGrupoCheques: false,
    };
  },
  watch: {
    selectedCaixa() {
      this.loadingGrupoCheques = true;
      this.gruopCheques = [];
      if (this.selectedCheques.length > 0) {
        let array_igual_banco = [];

        this.selectedCheques.find((item) => {
          if (item.banco_id == this.selectedCaixa.banco_id) {
            array_igual_banco.push(item);
          }
        });

        if (array_igual_banco.length > 0) {
          let total_valor = array_igual_banco.reduce(
            (accumulator, item) => accumulator + Number(item.valor),
            0
          );
          let obj = {
            descricao: array_igual_banco.reduce(
              (accumulator, item, index) =>
                `${accumulator}${item.numero}${
                  index != array_igual_banco.length - 1 ? " / " : ""
                }`,
              ""
            ),
            mesmo_banco: array_igual_banco[0].banco_descricao,
            moeda_id: array_igual_banco[0].moeda_id,
            moeda_sigla: array_igual_banco[0].moeda_sigla,
            banco_id: this.selectedCaixa.banco_id,
            banco_nome: this.selectedCaixa.banco_nome,
            caixa_descricao: this.selectedCaixa.descricao,
            caixa_destino_id: this.selectedCaixa.id,
            valor_mesmo_banco: total_valor,

            banco_selecionado_igual: true,
          };
          this.gruopCheques.push(obj);
        }

        let array_diferente_banco = [];

        this.selectedCheques.find((item) => {
          if (item.banco_id != this.selectedCaixa.banco_id) {
            array_diferente_banco.push(item);
          }
        });

        if (array_diferente_banco.length > 0) {
          let total_valor = array_diferente_banco.reduce(
            (accumulator, item) => accumulator + Number(item.valor),
            0
          );

          let banco_dirente_discricao = [];

          array_diferente_banco.map((item) => {
            if (banco_dirente_discricao.indexOf(item.banco_descricao) == -1) {
              banco_dirente_discricao.push(item.banco_descricao);
            }
          });

          let obj = {
            descricao: array_diferente_banco.reduce(
              (accumulator, item, index) =>
                `${accumulator}${item.numero}${
                  index != array_diferente_banco.length - 1 ? " - " : ""
                }`,
              ""
            ),
            diferente_banco: banco_dirente_discricao.reduce(
              (accumulator, item, index) =>
                `${accumulator}${item}${
                  index != banco_dirente_discricao.length - 1 ? " - " : ""
                }`,
              ""
            ),
            moeda_id: array_diferente_banco[0].moeda_id,
            moeda_sigla: array_diferente_banco[0].moeda_sigla,
            banco_id: this.selectedCaixa.banco_id,
            banco_nome: this.selectedCaixa.banco_nome,
            caixa_descricao: this.selectedCaixa.descricao,
            caixa_destino_id: this.selectedCaixa.id,
            valor_diferente_banco: total_valor,

            banco_selecionado_igual: false,
          };
          this.gruopCheques.push(obj);
        }
      } else {
        this.gruopCheques = [];
      }
      this.loadingGrupoCheques = false;
    },
  },
  computed: {
    totalValorBancos() {
      let valor = 0;
      this.gruopCheques.find((item) => {
        if (item.valor_diferente_banco) {
          valor += item.valor_diferente_banco;
        }
        if (item.valor_mesmo_banco) {
          valor += item.valor_mesmo_banco;
        }
      });
      return valor;
    },
    headersCheques() {
      return [
        {
          text: this.$tc("global.vencimento"),
          align: "center",
          value: "data_vencimento",
          sortable: false,
          divider: true,
        },
        {
          text: this.$tc("global.origem"),
          value: "origem",
          sortable: false,
          divider: true,
        },

        {
          text: this.$tc("global.nome"),
          value: this.$tc("global.emitidoPor"),
          sortable: false,
          divider: true,
        },

        {
          text: this.$tc("global.numero"),
          value: "numero",
          sortable: false,
          divider: true,
        },

        {
          text: this.$tc("global.tipo"),
          align: "left",
          value: "tipo",
          sortable: false,
          divider: true,
        },

        {
          text: "valor",
          value: "valor",
          align: "right",
          sortable: false,
        },
      ];
    },
    headers() {
      return [
        {
          text:
            this.$tc("global.cheque", 2) + " " + this.$tc("global.numero", 2),
          align: "left",

          sortable: false,
          value: "descricao",
          divider: true,
        },
        {
          text: this.$tc("global.mesmobanco"),
          align: "left",
          sortable: false,
          value: "mesmo_banco",
          divider: true,
        },
        {
          text: this.$tc("global.outrobanco"),
          align: "left",
          sortable: false,
          value: "diferente_banco",
          divider: true,
        },
        {
          text: this.$tc("global.mesmobanco"),
          align: "right",
          sortable: false,
          value: "valor_mesmo_banco",
          divider: true,
        },
        {
          text: this.$tc("global.outrobanco"),
          sortable: false,
          width: "116px",
          align: "right",
          value: "valor_diferente_banco",
        },
      ];
    },
  },
  methods: {
    getCaixasContaCorrent() {
      this.loadingBancos = true;
      return fetchCaixasContaCorrente()
        .then((response) => {
          response.find((item) => {
            if (item.moeda_id == this.selectedCheques[0].moeda_id) {
              this.caixas.push(item);
            }
          });
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingBancos = false;
          if (this.caixas.length == 0) {
            this.$toast.error(this.$tc("global.nenhumcaixaencontradomoeda"));
          }
          this.$nextTick(() => this.$refs.documento.focus());
        });
    },

    async compensar() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          caixa_destino_id: this.selectedCaixa.id,
          documento: this.documento,
          dataDeposito: this.dataDeposito,
          comprovante: this.comprovante,
          cheques: this.selectedCheques,
          grupo_cheques: this.gruopCheques,
        };
        await compensarCheques(data)
          .then((response) => {
            this.loading = false;
            this.$emit("update:dialogCompensacaoCheque", false);
            this.$emit("fetch-cheques");
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(error.message);
          });
      }
    },
  },
  async mounted() {
    await this.getCaixasContaCorrent();
  },
};
</script>

<style></style>
