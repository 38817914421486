import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { getContrato } from "@/api/contratos/contratos.js";
import { fetchOrdemServico } from "@/api/ordem-servico/ordem_servicos.js";
import store from "@/store/modules/usuario.store.js";

export async function generatePdf(ordem_servico_id, contrato_id) {
  const ordem_servico = await fetchOrdemServico(ordem_servico_id);
  const contrato = await getContrato(contrato_id);
  const empresa = await fetchEmpresa(store.state.empresa.id);

  const produtos = ordem_servico.produtos;
  let texto_produtos = "";
  produtos.find((produto) => {
    if (produto.kit) {
      texto_produtos +=
        produto.qtde +
        ` PISCINA ${contrato.produto_marca} ` +
        " con las siguientes medidas: ";
      texto_produtos += ordem_servico.medidas_piscina;
      texto_produtos += ", ";
      texto_produtos += produto.qtde + " " + produto.descricao + ": ";
    }
  });
  produtos.forEach((produto, index) => {
    if (!produto.kit) {
      texto_produtos += produto.qtde + " " + produto.descricao + ", ";
    }
  });
  texto_produtos =
    texto_produtos.slice(0, -2) + " TODOS EN PERFECTO ESTADO DE CONSERVACION.";

  return new Promise((resolve) => {
    let content = [
      {
        margin: [25, 25, 25, 25],
        stack: [
          {
            text: [
              {
                text: `DECLARACION DE RECIBIMIENTO DEL PRODUCTO, CERTIFICADO DE GARANTIA Y MANUAL.`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              `Declaro que, en esta fecha, la empresa POTABLE S.A. me ha entregado los productos abajo descriptos, con todos sus accesorios en perfecto estado de conservación y funcionamiento: `,
              `\n\n`,

              texto_produtos,

              `\n`,
              {
                text: `OBS:`,
                fontSize: 12,
                bold: true,
              },
              ` EL CLIENTE SE COMPROMETE EN REALIZAR LA CONFECCION DEL CONTRAPISO INMEDIATAMENTE DESPUES DEL TERMINO DEL NIVELAMIENTO EN EL AGUJERO DEL CASCO DE LA PISCINA, O LA PROTECCION DE ESTE PARA EVITAR QUE EN CASOS DE LLUVIAS FUERTES  O ALAGAMIENTOS VENGAN A DANIFICAR LA PISCINA, OCASIONANDO LA PERDIDA TOTAL DE LA GARANTIA DE FABRICA, TAMBIEN, SE RESPONSABILIZA POR LA CONTRATACION DE LA MANO DE OBRA CUALIFICADA PARA ESTE FIN, Y LA CORRESPONDIENTE ORIENTACION AL OBRERO PARA QUE RESPETE LA DETERMINACION TECNICA QUE CONTIENE EN EL CERTIFICADO DE GARANTIA, EN ESPECIAL LO QUE HACE RESPECTO AL `,

              {
                text: `NIVEL MINIMO DE 3%  DE DESNIVEL  DE LAS CALZADAS ALREDEDOR DE LA PISCINA Y CASA DE MAQUINAS`,
                fontSize: 12,
                bold: true,
              },
              ` PARA EVITAR QUE EL AGUA DE LAS LLUVIAS O DE LA LIMPIEZA DE LAS CALZADAS ENTRE DENTRO DE LAS MISMAS, PRINCIPALMENTE EN LA CASA DE MAQUINAS,  MOJANDO LOS MATERIALES ELECTRICOS Y ASI DEJANDO SIN VALOR SU GARANTIA, ESTANDO LA EMPRESA LIBRE DE CUALQUIER RESPONSABILIDAD POR EL SERVICIO PRESTADO POR TERCERAS PERSONAS, TAMBIEN SOBRE DAÑOS CAUSADOS A LOS EQUIPOS HIDRAULICOS, ELECTRICOS Y DISPOSITIVOS EN GENERAL POR NEGLIGENCIA DEL OBRERO EN EL MOMENTO DE LA REALIZACION DE LA OBRA, COMPACTACION DEL SUELO PARA LA CONSTRUCCION DE LAS CALZADAS U OTROS SERVICIOS ESTETICOS, DEL PAISAJISMO, ETC.`,
              `\n\n`,
              `Declaro también que me han entregado los documentos debajo descritos, referentes a los productos arriba mencionados:`,
              `\n\n`,
              `\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0- Certificado de garantía N.º  ${ordem_servico.numero_garantia} y manual de instalación y utilización de la piscina modelo PISCINA PAMPA con la relación de las situaciones que se excluyen de la garantía`,
              `\n`,
              `\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0- Motor y filtro de las instrucciones dadas, con la relación de las situaciones que se explican en el contrato`,
              `\n\n`,
              `En pruebe de conformidad firmo la presente, en Ciudad del Este, ${format(
                new Date(),
                "dd/MM/yyyy"
              )}.`,
            ],
            fontSize: 11,
            alignment: `justify`,
          },

          {
            margin: [0, 50, 0, 0],
            columns: [
              {
                text: [
                  `………………………………………………………\n`,
                  `${empresa.nome}\n`,
                  `RUC: ${empresa.documento}\n`,
                  `VENDEDOR`,
                ],
                alignment: `center`,
                fontSize: 11,
                bold: true,
              },
              // {
              //   text: [
              //     `………………………………………………………\n`,
              //     `${contrato.fiador_nome}\n`,
              //     `${contrato.fiador_tipo_doc} ${contrato.fiador_documento}\n`,
              //     `CO-DEUDOR`,
              //   ],
              //   alignment: `center`,
              //   fontSize: 11,
              //   bold: true,
              // },
              {
                text: [
                  `………………………………………………\n`,
                  `${contrato.cliente_nome}\n`,
                  `${contrato.cliente_tipo_doc}: ${contrato.cliente_documento}\n`,
                  `COMPRADOR`,
                ],
                alignment: `center`,
                fontSize: 11,
                bold: true,
              },
            ],
          },
        ],
      },
    ];

    let docDefinition = {
      pageSize: `A4`,
      pageMargins: [15, 15, 15, 15],
      pageOrientation: `portrait`, //`portrait`
      info: {
        title: `Document`,
      },
      defaultStyle: {
        font: `Roboto`,
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: `right`,
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: `Roboto-Regular.ttf`,
        bold: `Roboto-Medium.ttf`,
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
