<template>
  <v-select
    v-model="moeda"
    :items="moedas"
    item-text="sigla"
    item-value="id_moeda"
    clearable
    @click:clear="clearMoeda"
    outlined
    dense
    hide-details
    :label="$tc('global.moeda')"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Moedas",

  data() {
    return {
      moeda: null,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  watch: {
    moeda() {
      if (this.moeda) {
        this.$store.commit("FiltrosRelatorios/UPDATE_MOEDA", this.moeda);
      }
    },
  },

  methods: {
    clearMoeda() {
      this.$store.commit("FiltrosRelatorios/UPDATE_MOEDA", null);
      this.moeda = null;
    },
  },

  mounted() {
    if (this.$route.query.moeda) {
      let moeda = this.moedas.find(
        (item) => item.id_moeda == this.$route.query.moeda
      );
      this.moeda = moeda;
    }
  },
};
</script>

<style></style>
