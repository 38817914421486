<template>
  <v-card class="pa-6">
    <v-card-title class="">
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn @click="addVariavel" color="button_1" class="white--text">
        <v-icon left>mdi-plus</v-icon>
        {{ $tc("global.add") }}
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :search="search.length >= 3 ? search : ''"
        :items="variaveis"
        :items-per-page="10"
        class="data-tables data-tables__row-click"
        @click:row="goToVariavel"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat("dd/MM/yyyy") }}
        </template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-btn icon @click.stop="deleteVariavel(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template> -->
      </v-data-table>
    </v-card-text>

    <VariavelDialog
      v-if="dialogVariavel"
      :dialogVariavel.sync="dialogVariavel"
      :item="variavel"
      :add="add"
      @fetch-variaveis="getVariaveis"
    />
  </v-card>
</template>

<script>
import { index, remove } from "@/api/empresas/empresa_variaveis.js";

export default {
  name: "EmpresaVariaveis",

  components: {
    VariavelDialog: () => import("./VariavelDialog.vue"),
  },

  data() {
    return {
      loading: false,
      search: "",
      variaveis: [],
      variavel: {},
      dialogVariavel: false,
      add: false,
    };
  },

  computed: {
    empresa_id() {
      return this.$route.params.empresa_id;
    },

    headers() {
      return [
        {
          text: "campo",
          value: "campo",
        },
        {
          text: "tipo",
          value: "tipo",
        },
        {
          text: "grupo",
          value: "grupo",
        },
        {
          text: "categoria",
          value: "categoria",
        },
        {
          text: "data",
          value: "created_at",
        },
        // {
        //   text: "",
        //   value: "actions",
        // },
      ];
    },
  },

  methods: {
    goToVariavel(item) {
      this.add = false;
      this.variavel = { ...item };
      this.dialogVariavel = true;
    },
    addVariavel() {
      this.variavel = {};
      this.add = true;
      this.dialogVariavel = true;
    },

    async deleteVariavel(id) {
      this.loading = true;
      await remove(id);
      this.getVariaveis();
      this.loading = false;
    },

    async getVariaveis() {
      this.loading = true;
      this.variaveis = await index(`?empresa_id=${this.empresa_id}`);
      this.loading = false;
    },
  },

  mounted() {
    this.getVariaveis();
  },
};
</script>

<style></style>
