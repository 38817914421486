<template>
  <v-dialog
    v-model="dialogFechamentoCaixa"
    @click:outside="$emit('update:dialogFechamentoCaixa', false)"
    @keydown.esc="$emit('update:dialogFechamentoCaixa', false)"
    width="800px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $tc("global.fechamentoDeCaixa") }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogFechamentoCaixa', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-card outlined flat>
          <v-card-title class="text-subtitle-1 tocs_gray_1 d-flex align-center">
            <div style="min-width: 250px" class="d-flex justify-center">
              <span>
                {{ $tc("global.saldo") + " " + $tc("global.caixa") }}
              </span>
            </div>

            <div
              style="min-width: 400px"
              class="d-flex pl-15 pr-8 justify-space-between"
            >
              <span>
                {{
                  $tc("global.saldo") +
                  " " +
                  $tc("global.final") +
                  " " +
                  $tc("global.caixa")
                }}
              </span>
              <span>
                {{ $tc("global.diferenca") }}
              </span>
            </div>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-divider></v-divider>
            <div class="d-flex align-center justify-space-between">
              <v-divider vertical></v-divider>
              <div
                style="min-width: 100px"
                class="text-h6 font-weight-bold d-flex align-center justify-center"
              >
                G$
              </div>
              <v-divider vertical> </v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span
                  class="text-h6 font-weight-bold d-flex align-center justify-center"
                >
                  {{ caixa_af_valores.saldo_guarani | guarani }}
                </span>
              </div>
              <v-divider vertical></v-divider>
              <v-text-field
                v-model="valorFinalGuarani"
                :label="$tc('global.valor') + ' ' + 'G$'"
                ref="valorFinalGuarani"
                outlined
                hide-details
                style="max-width: 200px"
                dense
                class="input-right ma-4"
                v-currency="vCurrencyOptionsGuarani"
              ></v-text-field>

              <v-divider vertical></v-divider>

              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span class="text-h6">
                  {{ diferencaGuarani | guarani }}
                </span>
              </div>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between">
              <v-divider vertical></v-divider>
              <div
                style="min-width: 100px"
                class="text-h6 font-weight-bold d-flex align-center justify-center"
              >
                R$
              </div>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span
                  class="text-h6 font-weight-bold d-flex align-center justify-center"
                >
                  {{ caixa_af_valores.saldo_real | currency }}
                </span>
              </div>
              <v-divider vertical></v-divider>
              <v-text-field
                v-model="valorFinalReal"
                :label="$tc('global.valor') + ' ' + 'R$'"
                ref="valorFinalReal"
                outlined
                hide-details
                style="max-width: 200px"
                dense
                class="input-right ma-4"
                v-currency="vCurrencyOptions"
              ></v-text-field>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span class="text-h6">
                  {{ diferencaReal | currency }}
                </span>
              </div>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between">
              <v-divider vertical></v-divider>
              <div
                style="min-width: 100px"
                class="text-h6 font-weight-bold d-flex align-center justify-center"
              >
                U$
              </div>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span
                  class="text-h6 font-weight-bold d-flex align-center justify-center"
                >
                  {{ caixa_af_valores.saldo_dolar | currency }}
                </span>
              </div>
              <v-divider vertical></v-divider>
              <v-text-field
                v-model="valorFinalDolar"
                :label="$tc('global.valor') + ' ' + 'U$'"
                ref="valorFinalDolar"
                outlined
                hide-details
                style="max-width: 200px"
                dense
                class="input-right ma-4"
                v-currency="vCurrencyOptions"
              ></v-text-field>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span class="text-h6 d-flex align-center justify-center">
                  {{ diferencaDolar | currency }}
                </span>
              </div>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between">
              <v-divider vertical></v-divider>
              <div
                style="min-width: 100px"
                class="text-h6 font-weight-bold d-flex align-center justify-center"
              >
                {{ $tc("global.credito") }}
              </div>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span
                  class="text-h6 font-weight-bold d-flex align-center justify-center"
                >
                  {{ caixa_af_valores.saldo_credito | guarani }}
                </span>
              </div>

              <v-divider vertical></v-divider>

              <v-text-field
                v-model="valorFinalCredito"
                :label="$tc('global.valor') + ' ' + 'G$'"
                ref="valorFinalCredito"
                outlined
                hide-details
                style="max-width: 200px"
                dense
                class="input-right ma-4"
                v-currency="vCurrencyOptionsGuarani"
              ></v-text-field>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span class="text-h6 d-flex align-center justify-center">
                  {{ diferencaCredito | guarani }}
                </span>
              </div>
            </div>
            <v-divider></v-divider>

            <div class="d-flex align-center justify-space-between">
              <v-divider vertical></v-divider>
              <div
                style="min-width: 100px"
                class="text-h6 font-weight-bold d-flex align-center justify-center"
              >
                {{ $tc("global.debito") }}
              </div>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span
                  class="text-h6 font-weight-bold d-flex align-center justify-center"
                >
                  {{ caixa_af_valores.saldo_debito | guarani }}
                </span>
              </div>
              <v-divider vertical></v-divider>
              <v-text-field
                v-model="valorFinalDebito"
                :label="$tc('global.valor') + ' ' + 'G$'"
                ref="valorFinalDebito"
                outlined
                hide-details
                style="max-width: 200px"
                dense
                class="input-right ma-4"
                v-currency="vCurrencyOptionsGuarani"
              ></v-text-field>
              <v-divider vertical></v-divider>
              <div
                style="min-width: 200px"
                class="d-flex align-center justify-center"
              >
                <span class="text-h6">
                  {{ diferencaDebito | guarani }}
                </span>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-select
          v-model="selectedCaixa"
          :items="caixas"
          item-text="descricao"
          item-value="id"
          class="py-4"
          outlined
          dense
          hide-details
          :label="$tc('global.caixaDestino')"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          :disabled="!selectedCaixa ? true : false"
          @click="fecharCaixa"
          class="white--text"
          :loading="loading"
          small
        >
          <v-icon left>mdi-close</v-icon>
          {{ $tc("global.fechar") + " " + $tc("global.caixa") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="Fechamento de Caixa"
      @close="finalizarCaixa()"
    />
  </v-dialog>
</template>

<script>
import {
  fechamentoCaixaAf,
  fetchFechamentoCaixaValores,
} from "@/api/caixas/caixas_af.js";
import { mapState } from "vuex";
import { fetchCaixasGeral } from "@/api/caixas/caixas.js";

const generateFechamento = () =>
  import(/* webpackChunkName: "fechamentoPdf" */ "../reports/fechamentoPdf.js");

export default {
  name: "DialogFechamentoCaixa",

  props: {
    dialogFechamentoCaixa: {
      type: Boolean,
      default: false,
    },
    caixa_af_id: {
      type: Number,
    },
  },

  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      loading: false,
      valorFinalDolar: 0,
      valorFinalGuarani: 0,
      valorFinalReal: 0,
      valorFinalCredito: 0,
      valorFinalDebito: 0,
      caixa_af_valores: [],
      caixas: [],
      selectedCaixa: null,
      caixa_destino: null,
      pdfDialog: false,
    };
  },

  computed: {
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),

    vCurrencyOptionsGuarani() {
      return {
        precision: 0,
        distractionFree: false,
      };
    },
    vCurrencyOptions() {
      return {
        precision: undefined,
        distractionFree: false,
      };
    },

    diferencaGuarani() {
      let diferenca = 0;

      diferenca =
        this.$ci.parse(this.valorFinalGuarani, this.vCurrencyOptionsGuarani) -
        this.caixa_af_valores.saldo_guarani;
      return diferenca;
    },

    diferencaReal() {
      let diferenca = 0;

      diferenca =
        this.$ci.parse(this.valorFinalReal, this.vCurrencyOptions) -
        this.caixa_af_valores.saldo_real;
      return diferenca;
    },

    diferencaDolar() {
      let diferenca = 0;

      diferenca =
        this.$ci.parse(this.valorFinalDolar, this.vCurrencyOptions) -
        this.caixa_af_valores.saldo_dolar;
      return diferenca;
    },

    diferencaCredito() {
      let diferenca = 0;

      diferenca =
        this.$ci.parse(this.valorFinalCredito, this.vCurrencyOptionsGuarani) -
        this.caixa_af_valores.saldo_credito;
      return diferenca;
    },

    diferencaDebito() {
      let diferenca = 0;

      diferenca =
        this.$ci.parse(this.valorFinalDebito, this.vCurrencyOptionsGuarani) -
        this.caixa_af_valores.saldo_debito;
      return diferenca;
    },
  },

  watch: {
    selectedCaixa() {
      if (this.selectedCaixa) {
        this.caixa_destino = this.selectedCaixa;
      }
    },
  },

  methods: {
    async fecharCaixa() {
      this.loading = true;

      let valores = {};

      valores.valorFinalDolar = this.$ci.parse(
        this.valorFinalDolar,
        this.vCurrencyOptions
      );
      valores.valorFinalReal = this.$ci.parse(
        this.valorFinalReal,
        this.vCurrencyOptions
      );
      valores.valorFinalGuarani = this.$ci.parse(
        this.valorFinalGuarani,
        this.vCurrencyOptionsGuarani
      );
      valores.valorFinalCredito = this.$ci.parse(
        this.valorFinalCredito,
        this.vCurrencyOptionsGuarani
      );
      valores.valorFinalDebito = this.$ci.parse(
        this.valorFinalDebito,
        this.vCurrencyOptionsGuarani
      );
      valores.valorAcertoDolar = this.diferencaDolar;
      valores.valorAcertoReal = this.diferencaReal;
      valores.valorAcertoGuarani = this.diferencaGuarani;
      valores.valorAcertoCredito = this.diferencaCredito;
      valores.valorAcertoDebito = this.diferencaDebito;
      valores.caixa_destino = this.caixa_destino;

      await fechamentoCaixaAf(
        this.caixa_af_id ? this.caixa_af_id : this.caixa_af.id,
        valores
      )
        .then(async (response) => {
          if (response.status === 200) {
            await this.openFechamentoPdf();
            if (this.caixa_af_id) {
              this.$emit("fetch-caixas");
            }
            // this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getFechamentoCaixaValores() {
      this.loading = true;
      fetchFechamentoCaixaValores(
        this.caixa_af_id ? this.caixa_af_id : this.caixa_af.id
      )
        .then((response) => {
          this.caixa_af_valores = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getCaixasOrigem() {
      this.loading = true;
      fetchCaixasGeral()
        .then((response) => {
          this.caixas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    openFechamentoPdf() {
      return generateFechamento().then((module) => {
        let generate = module.generateFechamento;
        generate(
          this.caixa_af_id ? this.caixa_af_id : this.caixa_af.id,
          this.caixa_af_valores
        )
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },

    finalizarCaixa() {
      this.$emit("update:dialogFechamentoCaixa", false);
      this.$router.push({ path: `/caixas` });
    },
  },

  mounted() {
    this.getFechamentoCaixaValores();
    this.getCaixasOrigem();
  },
};
</script>

<style lang="scss" scoped>
.input-right ::v-deep input {
  text-align: right;
}
</style>
