<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.devolucao', 2)"
      icon="mdi-truck-delivery-outline"
      dark
    />

    <v-card>
      <v-card-title class="px-md-6 subtitle-1 tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-btn-toggle mandatory v-model="tipo_devolucao" class="ml-3">
          <v-btn value="vendas" class="text-caption" height="40px">
            {{ $tc("global.venda", 2) }}
          </v-btn>
          <v-btn value="compras" class="text-caption" disabled height="40px">
            {{ $tc("global.compra", 2) }}
          </v-btn>
        </v-btn-toggle>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-data-table
          :headers="headers"
          :items="vendas"
          :search="search"
          :items-per-page="15"
          class="data-tables"
          :loading="loading"
          @click:row="openDevolucao"
        >
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd-MM-yyyy") }}
          </template>

          <template v-slot:item.valor="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>
          <template v-slot:item.desconto="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.desconto | guarani }}
            </span>
            <span v-else>
              {{ item.desconto | currency }}
            </span>
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.total | guarani }}
            </span>
            <span v-else>
              {{ item.total | currency }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogDevolucao
      v-if="dialogDevolucao"
      :dialogDevolucao.sync="dialogDevolucao"
      :item_id="item_id"
      :tipo_devolucao="tipo_devolucao"
      @fetch-devolucoes="fetchDevolucoes"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getVendas } from "@/api/vendas/vendas.js";

export default {
  name: "Devolucoes",

  components: {
    DialogDevolucao: () => import("./components/DialogDevolucao.vue"),
  },

  data() {
    return {
      loading: false,
      search: "",
      tipo_devolucao: "vendas",
      vendas: [],
      item_id: null,
      dialogDevolucao: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PContaPagarAdicionar() {
      return this.getAccess("Devolucoes", "adicionar");
    },
    PContaPagarEditar() {
      return this.getAccess("Devolucoes", "editar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.numero"),
          width: "120px",
          value: "numero",
        },
        {
          text: this.$tc("global.data"),
          width: "120px",
          value: "data",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.valor"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "total",
        },

        {
          text: "",
          width: "100px",
          align: "center",
          value: "actions",
        },
      ];
    },
  },

  watch: {
    tipo_devolucao() {
      if (this.tipo_devolucao == "vendas") {
        this.fetchVendas();
      }
    },
  },

  methods: {
    openDevolucao(item) {
      this.dialogDevolucao = true;
      this.item_id = item.id;
    },

    fetchDevolucoes() {
      if (this.tipo_devolucao === "vendas") {
        this.fetchVendas();
      }
    },

    fetchVendas() {
      this.loading = true;
      getVendas("?status_filter=5")
        .then((response) => {
          this.vendas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.fetchDevolucoes();
  },
};
</script>

<style></style>
