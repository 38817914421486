import api from "../axios_service.js";

export async function fetchClientesObs(filtros) {
  const response = await api.get(`/cliente-obs${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca um cliente
export async function fetchClienteObs(id) {
  const response = await api.get(`/cliente-obs/${id}`);
  return response.data.result;
}

// Atualiza um cliente existente
export function putClienteObs(id, clienteObs) {
  return api.put(`/cliente-obs/${id}`, clienteObs);
}

// Cria um cliente novo
export function postClienteObs(clienteObs) {
  return api.post("/cliente-obs/add", clienteObs);
}
