<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
          {{ $tc("global.empresa", 2) }}
          <v-spacer></v-spacer>
          <v-btn
            depressed
            fab
            x-small
            class="white--text"
            color="button_1"
            @click="dialogUserEmpresa = !dialogUserEmpresa"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider v-if="userEmpresas.length"></v-divider>
        <v-card-text v-if="userEmpresas.length" class="py-4">
          <v-chip
            v-for="(empresa, index) in userEmpresas"
            :key="index"
            class="ma-1"
            label
            close
            :close-icon="
              empresa_ativa != empresa.empresa_id ? 'mdi-delete-outline' : ''
            "
            @click:close="delUserEmpresa(empresa.id)"
          >
            <v-icon left>mdi-domain</v-icon>
            {{ empresa.empresa }}
          </v-chip>
        </v-card-text>
        <p v-else class="d-flex justify-center pt-6">
          Nenhuma empresa cadastrada
        </p>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogUserEmpresa" width="100%" max-width="400">
      <v-card :loading="loadingUserEmpresa" :disabled="loadingUserEmpresa">
        <v-card-title class="subtitle-1 tocs_gray_1">
          {{ $tc("global.add") }} {{ $tc("global.empresa") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogUserEmpresa = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-6">
          <v-select
            v-model="userEmpresa.empresa_id"
            :items="empresasNotEqual"
            clearable
            item-text="nome"
            item-value="id"
            outlined
            dense
            :label="$tc('global.empresa')"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="button_2"
            depressed
            class="white--text"
            :disabled="userEmpresa.empresa_id ? false : true"
            small
            @click="criarUserEmpresa"
          >
            {{ $tc("global.add") }}
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  fetchEmpresasUser,
  deleteUserEmpresa,
  fetchEmpresasUserNotEqual,
  postUserEmpresa,
} from "@/api/empresas/empresas_users.js";

export default {
  props: {
    empresa_ativa: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    usuario_id() {
      return this.$route.params.usuarioId;
    },
  },

  data() {
    return {
      dialogUserEmpresa: false,
      loadingUserEmpresa: false,
      usuario: {},
      userEmpresas: [],
      userEmpresa: {},
      empresasNotEqual: [],
    };
  },
  methods: {
    getEmpresasNotEqual() {
      fetchEmpresasUserNotEqual(this.usuario_id)
        .then((response) => {
          this.empresasNotEqual = response;
        })
        .catch((error) => {});
    },
    getUserEmpresas() {
      fetchEmpresasUser(this.usuario_id)
        .then((response) => {
          this.userEmpresas = response;
        })
        .catch((error) => {});
    },
    criarUserEmpresa() {
      this.userEmpresa.user_id = this.usuario_id;
      this.loadingUserEmpresa = true;

      postUserEmpresa(this.userEmpresa)
        .then(() => {
          this.userEmpresa = {};
          this.getUserEmpresas();
          this.getEmpresasNotEqual();
          this.loadingUserEmpresa = false;
          this.dialogUserEmpresa = false;
        })
        .catch((error) => {
          this.loadingUserEmpresa = false;
          this.dialogUserEmpresa = false;
        });
    },
    delUserEmpresa(id) {
      if (this.empresa_ativa != id) {
        deleteUserEmpresa(id)
          .then(() => {
            this.getUserEmpresas();
            this.getEmpresasNotEqual();
          })
          .catch((error) => {});
      } else {
        this.$toast.warning(
          "Nao é possivel deletar a empresa ativa do usuario!"
        );
      }
    },
  },

  mounted() {
    this.getEmpresasNotEqual();
    this.getUserEmpresas();
  },
};
</script>

<style></style>
