var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-4 mb-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"tocs_gray_1 px-3 py-2"},[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('span',{staticClass:"pr-8"},[_vm._v("Total: G$ "+_vm._s(_vm._f("guarani")(_vm.totalValor)))])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[(!_vm.loading)?_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"search":_vm.search.length >= 3 ? _vm.search : '',"items":_vm.contas,"item-key":"id","sort-by":"id","show-select":true,"items-per-page":5},on:{"item-selected":_vm.contaSelected},scopedSlots:_vm._u([{key:"item.data_vencimento",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.data_vencimento)?_c('div',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_vencimento,"dd/MM/yyyy"))+" ")]):_vm._e()])]}},{key:"item.moeda",fn:function(ref){
var item = ref.item;
return [(item.moeda)?_c('div',[_vm._v(" "+_vm._s(item.moeda)+" ")]):_vm._e()]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$' && item.valor)?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):(item.valor)?_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")]):_vm._e()]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$' && item.desconto)?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):(item.desconto)?_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")]):_vm._e()]}},{key:"item.juros",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$' && item.juros)?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.juros))+" ")]):(item.juros)?_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.juros))+" ")]):_vm._e()]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$' && item.saldo)?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.saldo))+" ")]):(item.saldo)?_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")]):_vm._e()]}}],null,false,447823258),model:{value:(_vm.contasSelected),callback:function ($$v) {_vm.contasSelected=$$v},expression:"contasSelected"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }