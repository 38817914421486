<template>
  <v-select
    v-model="marca"
    :items="marcas"
    :loading="loading"
    clearable
    outlined
    dense
    hide-details
    :label="$tc('global.marca')"
    item-text="nome"
    item-value="id"
    :disabled="disabled"
    @click:clear="clearMarca"
    @change="updateMarca"
  ></v-select>
</template>

<script>
import { fetchMarcas } from "@/api/marcas/marcas.js";
import { mapState } from "vuex";

export default {
  name: "Marcas",

  data() {
    return {
      marca: null,
      disabled: false,
      marcas: [],
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  methods: {
    updateMarca() {
      if (this.marca) {
        this.$store.commit("FiltrosRelatorios/UPDATE_MARCA", this.marca);
      }
    },

    clearMarca() {
      this.$store.commit("FiltrosRelatorios/UPDATE_MARCA", null);
      this.marca = null;
    },
  },

  async created() {
    try {
      this.loading = true;
      this.marcas = await fetchMarcas();
      this.filtros_componente.find((item) => {
        if (item.text == "Marca") {
          this.marcas.find((marca) => {
            if (marca.id == item.default) {
              this.marca = marca;
            }
          });
          if (item.disabled) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
          this.updateMarca();
        }
      });

      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
