<template>
  <v-select
    v-model="forma_pagamento"
    :items="formas_pagamento"
    :loading="loading"
    clearable
    outlined
    dense
    hide-details
    :label="$tc('global.formapagamento')"
    item-text="descricao"
    item-value="id"
    :disabled="disabled"
    @click:clear="clearFormaPagamento"
    @change="updateFormaPagamento"
  ></v-select>
</template>

<script>
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";
import { mapState } from "vuex";

export default {
  name: "FormaPagamento",

  data() {
    return {
      forma_pagamento: null,
      disabled: false,
      formas_pagamento: [],
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  methods: {
    updateFormaPagamento() {
      if (this.forma_pagamento) {
        this.$store.commit("FiltrosRelatorios/UPDATE_FORMA_PAGAMENTO", this.forma_pagamento);
      }
    },

    clearFormaPagamento() {
      this.$store.commit("FiltrosRelatorios/UPDATE_FORMA_PAGAMENTO", null);
      this.forma_pagamento = null;
    },
  },

  async created() {
    try {
      this.loading = true;
      this.formas_pagamento = await fetchFormasPagamentos();
      this.filtros_componente.find((item) => {
        if (item.text == "FormaPagamento") {
          this.formas_pagamento.find((forma_pagamento) => {
            if (forma_pagamento.id == item.default) {
              this.forma_pagamento = forma_pagamento;
            }
          });
          if (item.disabled) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
          this.updateFormaPagamento();
        }
      });

      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
