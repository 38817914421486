<template>
  <v-card flat>
    <v-card-title class="pa-0 d-flex">
      <v-combobox
        v-model="selectedFornecedor"
        :label="$tc('global.fornecedor', 2)"
        :items="fornecedores"
        item-text="nome"
        item-value="id"
        dense
        class="input-low-height"
        outlined
        solo
        flat
        hide-details
      >
        <!-- <template v-slot:append-item>
          <v-btn @click="dialogAddPais = true" text block class="primary--text">
            <v-icon left>mdi-plus</v-icon>
            {{ $tc("global.add") + " Pais" }}
          </v-btn>
        </template> -->
      </v-combobox>
    </v-card-title>
  </v-card>
</template>

<script>
import { fetchFornecedoresServicos } from "@/api/fornecedores/fornecedores.js";
export default {
  name: "ServicosOrdemServico",

  data() {
    return {
      loading: false,
      fornecedores: [],
      selectedFornecedor: null,
      servicos: [],
      selectedServico: {},
    };
  },

  watch: {
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.$emit("update:selectedFornecedor", this.selectedFornecedor);
        this.$emit("atualizarFornecedor", this.selectedFornecedor);
      }
    },
  },

  methods: {
    getFornecedores() {
      this.loading = true;
      fetchFornecedoresServicos()
        .then((response) => {
          this.fornecedores = response;
          this.loading = false;
        })
        .catch(() => {});
    },
  },

  mounted() {
    this.getFornecedores();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-low-height {
  display: flex;
  align-items: center;
  justify-content: center;

  & .v-input__control {
    justify-content: center;
  }

  & .v-input__slot {
    height: 28px;
    min-height: 28px !important;
  }
}
</style>
