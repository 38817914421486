<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.usuario', 2)"
      icon="mdi-account-circle"
      dark
    />

    <v-card>
      <v-card-title class="pa-3 subtitle-1 tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <DialogUsuariosInativos
          v-if="dialogUsuariosInativos"
          :dialogUsuariosInativos.sync="dialogUsuariosInativos"
          :items="usuarios_inativos"
          @fetch-usuarios-inativos="fetchUsuariosInativos"
          @fetch-usuarios="fetchUsuariosEmpresaAtivo"
        />
        <v-btn
          color="tocs_gray_2"
          class="white--text mr-2"
          @click="fetchUsuariosInativos"
        >
          <v-icon left>mdi-account-off-outline</v-icon>
          {{ $tc("global.inativo", 2) }}
        </v-btn>
        <v-btn
          :to="{ path: '/configuracoes/usuarios/adicionar' }"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
        <!-- <DialogUsuarios
          v-if="dialogUsuarios"
          :dialogUsuarios.sync="dialogUsuarios"
          :edit="edit"
          :adding="adding"
          :item="usuario"
          @fetch-usuarios="fetchUsuariosEmpresaAtivo"
        /> -->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="usuarios"
          :items-per-page="10"
          item-key="id"
          :search="search.length >= 3 ? search : ''"
          class="data-tables data-tables__row-click"
          @click:row="goToUsuario"
        >
          <template v-slot:item.desativar="{ item }">
            <v-btn
              v-if="item.id != user_id"
              @click.stop="updateStatusDesativar(item)"
              color="error"
              small
              text
              class="butto_1--text my-1"
            >
              {{ $tc("global.desativar") }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  getLimiteUsuarios,
  getUsuariosEmpresaAtivo,
  getUsuariosEmpresaInativo,
  putUsuarioDesativar,
} from "@/api/usuarios/usuarios.js";
import { mapState } from "vuex";

export default {
  name: "Usuarios",
  data: () => ({
    search: "",
    loading: true,
    usuarios: [],
    usuarios_inativos: [],
    // dialogUsuarios: false,
    dialogUsuariosInativos: false,
    usuario_index: {},
    usuario: {},
  }),

  components: {
    // DialogUsuarios: () => import("./components/DialogUsuarios.vue"),
    DialogUsuariosInativos: () =>
      import("./components/DialogUsuariosInativos.vue"),
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    headers() {
      let headers = [
        {
          text: this.$tc("global.nome"),
          value: "name",
        },
        {
          text: "username",
          value: "email",
        },
        {
          text: this.$tc("global.celular"),
          value: "celular",
        },
        {
          text: this.$tc("global.desativar"),
          value: "desativar",
          align: "center",
        },
      ];
      return headers;
    },
  },

  methods: {
    addUsuario() {
      getLimiteUsuarios().then((response) => {
        if (response) {
          // this.dialogUsuarios = true;
          this.adding = true;
          this.edit = false;
        } else {
          this.$toast.error(
            "Limite de usuários atingido, desative um usuário para adicionar outro!"
          );
        }
      });
    },
    goToUsuario(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.name);
      this.$router.push({ path: `/configuracoes/usuario/${item.id}` });
    },
    // goToUsuario(item) {
    //   this.usuario = { ...item };
    //   this.dialogUsuarios = true;
    //   this.adding = false;
    //   this.edit = true;
    // },
    fetchUsuariosEmpresaAtivo() {
      this.loading = true;
      getUsuariosEmpresaAtivo()
        .then((response) => {
          this.usuarios = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    fetchUsuariosInativos() {
      getUsuariosEmpresaInativo()
        .then((response) => {
          this.usuarios_inativos = response;
          this.dialogUsuariosInativos = true;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    updateStatusDesativar(item) {
      this.usuario_index = { ...item };
      putUsuarioDesativar(this.usuario_index.id)
        .then(() => {
          this.fetchUsuariosEmpresaAtivo();
        })
        .catch(() => {});
    },
  },

  mounted() {
    this.fetchUsuariosEmpresaAtivo();
  },
};
</script>

<style></style>
