<template>
  <v-select
    v-model="opcao"
    :items="opcoes"
    item-value="value"
    item-text="text"
    clearable
    outlined
    dense
    hide-details
    :label="$tc('global.groupby')"
    @change="updateGroup"
    @clear="clearGroup"
    @click:clear="clearGroup"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GroupCompras",

  data() {
    return {
      opcao: null,
      disabled: false,
      opcoes: [
        {
          text: "Fornecedor",
          value: "fornecedor",
        },
      ],
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  methods: {
    updateGroup() {
      if (this.opcao) {
        this.$store.commit(
          "FiltrosRelatorios/UPDATE_GROUP_COMPRAS",
          this.opcao
        );
      }
    },

    clearGroup() {
      this.$store.commit("FiltrosRelatorios/UPDATE_GROUP_COMPRAS", null);
      this.opcao = null;
    },
  },

  mounted() {
    this.filtros_componente.find((item) => {
      if (item.text == "GroupCompras") {
        this.opcoes.find((opcao) => {
          if (opcao == item.default) {
            this.opcao = opcao;
          }
        });
      }
    });
  },
};
</script>

<style></style>
