const routes = [
  {
    path: "/contabilidade/timbrado",
    component: () => import("@/views/dashboard/timbrado/Timbrados.vue"),
    meta: {
      menu: "dashboard",
      title: "Timbrados",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "timbrado", disableTrans: true },
      ],
      permission: { componente: "Timbrados" },
    },
  },
];

export default routes;
