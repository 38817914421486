<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.editar') + ' ' + $tc('global.fornecedor')"
      icon="mdi-truck-outline"
      dark
      voltar
    />

    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 justify-space-between align-center">
        Dados Gerais
        <v-spacer></v-spacer>
        <v-btn
          @click="editFornecedor"
          v-if="PFornecedorEditar"
          :disabled="!validForm"
          color="button_1"
          class="white--text"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $tc("global.salvar") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="2">
              <div class="d-flex justify-center align-center">
                <v-card
                  outlined
                  class="fill-height d-flex align-center justify-center"
                  style="position: relative"
                >
                  <v-card flat>
                    <v-img v-if="logo" :src="logo" contain></v-img>
                    <v-img
                      v-else
                      :src="require('@/assets/pagina-default.png')"
                      contain
                    ></v-img>
                    <v-btn
                      fab
                      dark
                      x-small
                      absolute
                      bottom
                      right
                      :disabled="!PFornecedorEditar"
                      @click="onButtonClick"
                    >
                      <input
                        accept="image/*"
                        type="file"
                        class="d-none"
                        ref="uploader"
                        @change="onFileChanged"
                      />
                      <v-icon>mdi-image</v-icon>
                    </v-btn>
                  </v-card>
                </v-card>
              </div>
              <v-btn
                v-if="fornecedor.cliente_id"
                dark
                x-small
                bottom
                right
                @click="diretect"
                class="ml-2 mt-8"
              >
                <span class="dense">Fornecedor e Cliente</span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="10">
              <v-row dense>
                <!-- Nome -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="fornecedor.nome"
                    :label="$tc('global.nome') + ' ' + $tc('global.fornecedor')"
                    dense
                    :readonly="!PFornecedorEditar"
                    outlined
                    :rules="formRules"
                    required
                  ></v-text-field>
                </v-col>

                <!-- Documento -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="fornecedor.documento"
                    :label="$tc('global.documento')"
                    dense
                    :readonly="!PFornecedorEditar"
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Categoria -->
                <v-col cols="12" md="3">
                  <v-combobox
                    v-model="selectedCategoria"
                    :label="$tc('global.categoria')"
                    :items="categorias"
                    dense
                    outlined
                    :rules="formRules"
                    :readonly="!PFornecedorEditar"
                    required
                    item-text="descricao"
                    item-value="id"
                    :item-disabled="checkCategoriaDisabled"
                  ></v-combobox>
                </v-col>

                <!-- Tipo -->
                <v-col cols="12" md="2">
                  <v-combobox
                    v-model="selectedTipo"
                    :label="$tc('global.tipo')"
                    :items="tipos"
                    dense
                    outlined
                    :rules="formRules"
                    :readonly="!PFornecedorEditar"
                    required
                    item-text="descricao"
                    item-value="id"
                    :item-disabled="checkTipoDisabled"
                  ></v-combobox>
                </v-col>

                <!-- Localizacao -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="fornecedor.localizacao"
                    :label="$tc('global.localizacao')"
                    dense
                    :readonly="!PFornecedorEditar"
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="selectedCidade"
                    :items="cidades"
                    :loading="buscandoCidades"
                    :search-input.sync="searchCidade"
                    @keyup="getCidades"
                    dense
                    :readonly="!PFornecedorEditar"
                    outlined
                    hide-details
                    item-text="nome"
                    item-value="id"
                    :placeholder="$tc('global.cidade')"
                    append-icon=""
                    full-width
                    return-object
                    autocomplete="off"
                    class="remove-underline input-font-size"
                  ></v-autocomplete>
                </v-col>

                <!-- Moeda -->
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="selectedMoeda"
                    :label="$tc('global.moeda')"
                    :items="moedas"
                    dense
                    :rules="formRules"
                    :readonly="!PFornecedorEditar"
                    outlined
                    item-text="sigla"
                    item-value="id"
                  ></v-combobox>
                </v-col>

                <!--Status -->
                <v-col cols="12" md="4">
                  <v-card
                    outlined
                    height="40px"
                    class="pa-3 d-flex justify-space-between align-center"
                  >
                    <v-subheader
                      style="height: auto !important"
                      class="ma-0 pa-0 body-1"
                    >
                      Status
                      <span class="pl-1" v-if="fornecedor.status === 1">
                        {{ $tc("global.ativo") }}
                      </span>
                      <span class="pl-1" v-else>{{
                        $tc("global.inativo")
                      }}</span>
                    </v-subheader>
                    <v-switch
                      :false-value="0"
                      :true-value="1"
                      :readonly="!PFornecedorEditar"
                      v-model="fornecedor.status"
                    ></v-switch>
                  </v-card>
                </v-col>

                <!-- Endereco -->
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="fornecedor.endereco"
                    :label="$tc('global.endereco')"
                    dense
                    :readonly="!PFornecedorEditar"
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="fornecedor.telefone"
                    :readonly="!PFornecedorEditar"
                    :label="$tc('global.telefone')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Celular -->
                <v-col cols="12" md="4">
                  <v-text-field
                    :readonly="!PFornecedorEditar"
                    v-model="fornecedor.celular"
                    :label="$tc('global.celular')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="4">
                  <v-text-field
                    :readonly="!PFornecedorEditar"
                    v-model="fornecedor.email"
                    :label="$tc('global.email')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="fornecedor.tipo_id == 2">
          <v-divider class="pb-7"></v-divider>
          <ListaFornecedorServicos
            v-if="!loading"
            :fornecedor_id="fornecedor.id"
            :moeda_fornecedor="selectedMoeda"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import {
  putFornecedor,
  getFornecedor,
} from "@/api/fornecedores/fornecedores.js";
import { getFornecedoresCategorias } from "@/api/fornecedores/categorias_fornecedores.js";
import { getFornecedoresTipos } from "@/api/fornecedores/tipos_fornecedores.js";
import { mapGetters } from "vuex";
import { fetchMoedas } from "@/api/moedas/moedas.js";
import { searchCidades } from "@/api/cidades/cidades.js";

export default {
  name: "EditFornecedor",

  components: {
    ListaFornecedorServicos: () =>
      import("./components/ListaFornecedorServicos.vue"),
  },

  data() {
    return {
      fornecedor: {},
      fornecedorOriginal: null,
      fornecedorServicos: [],
      loading: true,
      validForm: true,
      formRules: [inputRequired],
      emailRules: [validaEmail],
      listas: [],
      categorias: [],
      tipos: [],
      moedas: [],
      cidades: [],
      logo: null,
      logoUpload: null,
      hoverFile: false,
      selectedCategoria: null,
      selectedTipo: null,
      selectedMoeda: null,
      buscandoCidades: false,
      searchCidade: "",
      selectedCidade: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PFornecedorEditar() {
      return this.getAccess("Fornecedor", "editar");
    },
    fornecedor_id() {
      return this.$route.params.fornecedorId;
    },

    headers() {
      return [
        {
          text: this.$tc("global.produto"),
          value: "produto_id",
          divider: true,
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    selectedCategoria() {
      if (this.selectedCategoria) {
        this.fornecedor.categoria_id = this.selectedCategoria.id;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.fornecedor.tipo_id = this.selectedTipo.id;
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.fornecedor.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedCidade() {
      if (this.selectedCidade) {
        this.fornecedor.cidade_id = this.selectedCidade.id;
      }
    },
  },

  methods: {
    checkCategoriaDisabled(item) {
      return item.status === 0;
    },
    checkTipoDisabled(item) {
      return item.status === 0;
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.logoUpload = file;
      this.fornecedor.logo = !this.fornecedor.logo ? "logo" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
        // vue.editFornecedor();
      };
    },

    diretect() {
      this.$router.push({
        path: `/clientes/${this.fornecedor.cliente_id}`,
      });
    },

    editFornecedor() {
      if (
        JSON.stringify(this.fornecedor) ===
        JSON.stringify(this.fornecedorOriginal)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const fornecedor = new FormData();

        if (this.logoUpload) {
          fornecedor.append("logo", this.logoUpload, this.logoUpload.name);
          fornecedor.append("logo64", this.logo);
        }
        let fornecedor_2 = {};
        fornecedor_2.nome = this.fornecedor.nome;
        fornecedor_2.categoria_id = this.fornecedor.categoria_id;
        fornecedor_2.tipo_id = this.fornecedor.tipo_id;
        fornecedor_2.documento = this.fornecedor.documento;
        fornecedor_2.endereco = this.fornecedor.endereco;
        fornecedor_2.localizacao = this.fornecedor.localizacao;
        fornecedor_2.cidade_id = this.fornecedor.cidade_id;
        fornecedor_2.email = this.fornecedor.email;
        fornecedor_2.telefone = this.fornecedor.telefone;
        fornecedor_2.celular = this.fornecedor.celular;
        fornecedor_2.moeda_id = this.fornecedor.moeda_id;
        fornecedor_2.status = this.fornecedor.status;

        for (let key in fornecedor_2) {
          if (
            fornecedor_2[key] !== null &&
            fornecedor_2[key] !== undefined &&
            fornecedor_2[key] !== ""
          ) {
            fornecedor.append(key, fornecedor_2[key]);
          }
        }

        putFornecedor(this.fornecedor.id, fornecedor)
          .then(() => {
            this.fornecedorOriginal = { ...this.fornecedor };
            this.loading = false;
            this.$router.push({ path: "/fornecedores" });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    fetchMoeda() {
      return fetchMoedas().then((response) => {
        this.moedas = response;

        this.selectedMoeda = this.moedas.find(
          (moeda) => moeda.id_moeda === this.fornecedor.moeda_id
        );
      });
    },

    searchCidades(filtro) {
      return searchCidades(filtro).then((response) => {
        this.cidades = response;
      });
    },

    async getCidades() {
      if (this.searchCidade) {
        if (this.searchCidade.length > 2 && event.key != "Backspace") {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
    },

    getCategorias() {
      return getFornecedoresCategorias().then((response) => {
        this.categorias = response;

        this.selectedCategoria = this.categorias.find(
          (categoria) => categoria.id === this.fornecedor.categoria_id
        );
      });
    },

    getTipos() {
      return getFornecedoresTipos().then((response) => {
        this.tipos = response;

        this.selectedTipo = this.tipos.find(
          (tipo) => tipo.id === this.fornecedor.tipo_id
        );
      });
    },

    async getFornecedor() {
      await getFornecedor(this.fornecedor_id).then((response) => {
        this.fornecedor = response;
        this.fornecedorOriginal = { ...response };
        this.logo = this.fornecedor.logo;
      });
      if (this.fornecedor.cidade_id) {
        await this.searchCidades(`?search=id=${this.fornecedor.cidade_id}`);
        this.selectedCidade = this.cidades[0];
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.getFornecedor();
    await this.getCategorias();
    await this.getTipos();
    await this.fetchMoeda();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
</style>
