<template>
  <v-dialog
    v-model="pdfDialog"
    @click:outside="fecharPdf"
    @keydown.esc="fecharPdf"
    scrollable
    fullscreen
  >
    <v-card tile height="100vh">
      <v-card-title class="px-4 py-2 secondary white--text">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="fecharPdf">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="pa-0"
        style="height: calc(100vh - 68px); overflow: hidden"
      >
        <iframe
          :src="pdfData"
          frameborder="0"
          width="100%"
          height="100%"
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PdfDialogComp",

  props: {
    pdfDialog: {
      type: Boolean,
      required: true,
    },
    pdfData: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    fecharPdf() {
      this.$emit("update:pdfDialog", false);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss"></style>
