<template>
  <div
    style="width: 100%; height: 80%;"
    class="py-12 d-flex align-center justify-center"
  >
    <v-progress-circular
      :width="5"
      indeterminate
      :color="themeDark ? 'white' : 'primary'"
      size="80"
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      themeDark: (state) => state.template.dark,
    }),
  },
};
</script>

<style></style>
