var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageToolbar',{attrs:{"title":"Giros","icon":"mdi-sim","dark":""}}),_c('v-card',[_c('v-card-title',{staticClass:"tocs_gray_1"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn-toggle',{staticClass:"ml-3",attrs:{"mandatory":""},model:{value:(_vm.tipo_giro),callback:function ($$v) {_vm.tipo_giro=$$v},expression:"tipo_giro"}},[_c('v-btn',{staticClass:"text-caption",attrs:{"value":"pendente","height":"40px"}},[_vm._v(" "+_vm._s(_vm.$tc("global.pendentes", 2))+" ")]),_c('v-btn',{staticClass:"text-caption",attrs:{"value":"identificado","height":"40px"}},[_vm._v(" "+_vm._s(_vm.$tc("global.identificado", 2))+" ")]),_c('v-btn',{staticClass:"text-caption",attrs:{"value":"baixado","height":"40px"}},[_vm._v(" "+_vm._s(_vm.$tc("global.baixado", 2))+" ")])],1),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-btn',{staticClass:"white--text mt-4 mt-sm-0",attrs:{"color":"button_1"},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" ")],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-table data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.giros,"search":_vm.search,"items-per-page":10},on:{"click:row":_vm.goToGiro},scopedSlots:_vm._u([{key:"item.cliente_nome",fn:function(ref){
var item = ref.item;
return [(item.cliente_nome)?_c('span',[_vm._v(_vm._s(item.cliente_nome))]):_c('span',{staticClass:"h4"},[_vm._v(" "+_vm._s(_vm.$tc("global.nao"))+" "+_vm._s(_vm.$tc("global.vinculado"))+" ")])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" G$ "+_vm._s(_vm._f("guarani")(item.valor))+" ")]}},{key:"item.taxa",fn:function(ref){
var item = ref.item;
return [_vm._v(" G$ "+_vm._s(_vm._f("guarani")(item.taxa))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.goToEditGiro(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}])})],1)],1),(_vm.dialogAddEditGiros)?_c('DialogAddEditGiros',{attrs:{"dialogAddEditGiros":_vm.dialogAddEditGiros,"editar":_vm.editar,"prepararGiro":_vm.prepararGiro,"item":_vm.giro,"origem":"MANUAL"},on:{"update:dialogAddEditGiros":function($event){_vm.dialogAddEditGiros=$event},"update:dialog-add-edit-giros":function($event){_vm.dialogAddEditGiros=$event},"update:editar":function($event){_vm.editar=$event},"update:prepararGiro":function($event){_vm.prepararGiro=$event},"update:preparar-giro":function($event){_vm.prepararGiro=$event},"fetch-giros":_vm.getGiros}}):_vm._e(),(_vm.dialogBaixarGiros)?_c('DialogBaixarGiros',{attrs:{"dialogBaixarGiros":_vm.dialogBaixarGiros,"item":_vm.giro},on:{"update:dialogBaixarGiros":function($event){_vm.dialogBaixarGiros=$event},"update:dialog-baixar-giros":function($event){_vm.dialogBaixarGiros=$event},"fetch-giros":_vm.getGiros}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }