import api from "../axios_service.js";

export async function fetchVendasItems(venda_id) {
  const response = await api.get(`/vendas-items?venda_id=${venda_id}`);
  return response.data.result;
}

export function addVendaItem(body) {
  return api.post("/vendas-items/add", body);
}

export function putVendaItem(id, body) {
  return api.put(`/vendas-items/${id}`, body);
}

export function deleteVendaItem(id) {
  return api.delete(`/vendas-items/${id}`);
}
