import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { listaDeVendas } from "@/api/relatorios/relatorio_vendas.js";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import { format, parseISO } from "date-fns";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();
  const filtro_get_extrato = bodyPDF.queryString;
  let vendas = await listaDeVendas(filtro_get_extrato);
  let vendas_info = vendas.data;
  const filtros_info = vendas.filtros;
  let text_filtros = "";

  for (const [key, value] of Object.entries(filtros_info)) {
    if (value){ 
      text_filtros += key + ": " + value + "   ";
    }
  }
  const cabecalho = bodyPDF.cabecalho;
  const periodo = bodyPDF.periodo;

  return new Promise((resolve, reject) => {
    let cabecalho_data = {
      margin: [0, 5, 0, 2],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: " VENDAS - " + periodo,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let cabecalho_filtros = {
      margin: [0, 0, 0, -3],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: text_filtros,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let table_vendas = {
      margin: [0, 0, 0, 5],
      table: {
        widths: [20, 45, "*", "*", 40, "*", "*", "*", "*", 40],
        body: [
          [
            {
              text: "COD",
              border: [true, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "CLIENTE",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "DATA",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "VENDEDOR",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "DEPOSITO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "MOEDA",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "VALOR",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "CUSTO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "LUCRO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "MARGEM",
              border: [false, true, true, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 1;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    const row_font_size = 8;
    vendas_info.forEach((vendas, index) => {
      let table_row = [
        {
          text: vendas.venda_id,
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            true,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },

        {
          text: Vue.filter("excerpt")(vendas.cliente, 15),
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },

        {
          text: format(parseISO(vendas.data), "dd/MM/yyyy"),
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
        {
          text: Vue.filter("excerpt")(vendas.vendedor, 15),
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
        {
          text:vendas.deposito_id + " - " + Vue.filter("excerpt")(vendas.deposito, 15),            
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
        {
          text: vendas.sigla,
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
        {
          text: (
              vendas.sigla == "G$"
                ? Vue.filter("guarani")(vendas.valor)
                : Vue.filter("currency")(vendas.valor)
            ),
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
        {
          text:(
              vendas.sigla == "G$"
                ? Vue.filter("guarani")(vendas.custo)
                : Vue.filter("currency")(vendas.custo)
            ),
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
        {
          text:(
              vendas.sigla == "G$"
                ? Vue.filter("guarani")(vendas.lucro)
                : Vue.filter("currency")(vendas.lucro)
            ),
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            false,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
        {
          text:(
              vendas.sigla == "G$"
                ? Vue.filter("guarani")(vendas.margem)
                : Vue.filter("currency")(vendas.margem)
            ),
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [
            false,
            true,
            true,
            index != vendas_info.length - 1 ? false : true,
          ],
        },
      ];

      table_vendas.table.body.push(table_row);
    });

    let content = [cabecalho, cabecalho_data, table_vendas];
    if (!Object.values(filtros_info).every(value => value === null)) {
      content.splice(1, 0, cabecalho_filtros);
    }

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: "right",
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
