<template>
  <v-dialog
    v-model="dialogTipoFaturas"
    @click:outside="$emit('update:dialogTipoFaturas', false)"
    @keydown.esc="$emit('update:dialogTipoFaturas', false)"
    width="800px"
  >
    <v-card dense>
      <v-card-title>
        <span class="text-h6">{{ $tc("global.escolhaTipoFatura") }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:dialogTipoFaturas', false)" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <div
        no-gutters
        class="d-flex align-center justify-space-between px-8 py-4"
      >
        <v-btn
          @click="openAddFatura"
          color="secondary"
          class="pa-6 text-subtitle-1"
        >
          {{ $tc("global.fatura") }}
        </v-btn>

        <!-- <v-btn
          @click="openAddFaturaManual"
          color="secondary"
          class="pa-6 text-subtitle-1"
        >
          {{ $tc("global.manual") }}
        </v-btn> -->

        <v-btn
          @click="openNotaCreditoManual"
          color="secondary"
          class="pa-6 text-subtitle-1"
        >
          {{ $tc("global.notaCreditoManual") }}
        </v-btn>

        <v-btn
          @click="openNotaCredito"
          color="secondary"
          disabled
          class="pa-6 text-subtitle-1"
        >
          {{ $tc("global.notaCredito") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogTipoFaturas",

  props: {
    dialogTipoFaturas: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  methods: {
    openNotaCreditoManual() {
      this.$emit("update:dialogTipoFaturas", false);
      this.$emit("tipo-nota-credito-manual");
    },
    openNotaCredito() {
      this.$emit("update:dialogTipoFaturas", false);
      this.$emit("tipo-nota-credito");
    },
    openAddFatura() {
      this.$emit("update:dialogTipoFaturas", false);
      this.$emit("tipo-fatura");
    },
    openAddFaturaManual() {
      this.$emit("update:dialogTipoFaturas", false);
      this.$emit("tipo-fatura-manual");
    },
  },
};
</script>

<style></style>
