<template>
  <v-dialog
    v-model="dialogAddBandeira"
    @click:outside="$emit('update:dialogAddBandeira', false)"
    @keydown.esc="$emit('update:dialogAddBandeira', false)"
    width="500px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{ !edit ? "Nova Bandeira" : "Atualizar Bandeira" }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddBandeira', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider> </v-divider>
      <v-card-text class="py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                v-model="bandeira.bandeira"
                :label="$tc('global.nome')"
                dense
                outlined
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedTipoBandeira"
                :label="$tc('global.tipo')"
                :items="tipos_bandeiras"
                dense
                outlined
                :rules="formRules"
                required
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="valorComissao"
                v-model="valorComissao"
                :label="$tc('global.comissao')"
                dense
                outlined
                :rules="formRules"
                v-currency="vCurrencyOptions"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="valorComissaoIva"
                v-model="valorComissaoIva"
                :label="$tc('global.comissao') + ' IVA'"
                dense
                outlined
                :rules="formRules"
                v-currency="vCurrencyOptions"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="valorRetencaoRenta"
                v-model="valorRetencaoRenta"
                :label="$tc('global.retencao') + ' Renta'"
                dense
                outlined
                :rules="formRules"
                v-currency="vCurrencyOptions"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-card
                v-if="edit"
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Status
                </v-subheader>
                <v-switch
                  :false-value="false"
                  :true-value="true"
                  v-model="bandeira.status"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createBandeira() : updateBandeira()"
        >
          {{ !edit ? $tc("global.add") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  putCartaoBandeira,
  postCartaoBandeira,
} from "@/api/cartoes-bandeiras/cartoes_bandeiras.js";

export default {
  name: "DialogAddBandeira",

  props: {
    dialogAddBandeira: {
      type: Boolean,
    },
    edit: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      loading: true,
      validForm: true,
      formRules: [inputRequired],
      bandeira: {
        status: true,
        comissao: null,
        comissao_iva: null,
        ret_renta: null,
      },
      bandeira_original: {},
      valorComissao: 0,
      valorComissaoIva: 0,
      valorRetencaoRenta: 0,
      tipos_bandeiras: ["CREDITO", "DEBITO"],
      selectedTipoBandeira: null,
    };
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision: undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    selectedTipoBandeira() {
      this.bandeira.tipo = this.selectedTipoBandeira;
    },
    valorComissao() {
      this.bandeira.comissao = this.$ci.parse(
        this.valorComissao,
        this.vCurrencyOptions
      );
    },
    valorComissaoIva() {
      this.bandeira.comissao_iva = this.$ci.parse(
        this.valorComissaoIva,
        this.vCurrencyOptions
      );
    },
    valorRetencaoRenta() {
      this.bandeira.ret_renta = this.$ci.parse(
        this.valorRetencaoRenta,
        this.vCurrencyOptions
      );
    },
  },

  methods: {
    updateBandeira() {
      if (
        JSON.stringify(this.bandeira) === JSON.stringify(this.bandeira_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        let bandeira = {};

        bandeira.bandeira = this.bandeira.bandeira;
        bandeira.comissao = this.bandeira.comissao;
        bandeira.comissao_iva = this.bandeira.comissao_iva;
        bandeira.ret_renta = this.bandeira.ret_renta;
        bandeira.tipo = this.bandeira.tipo;
        bandeira.status = this.bandeira.status;

        putCartaoBandeira(this.bandeira.id, bandeira)
          .then(() => {
            this.$emit("fetch-bandeiras");
            this.$emit("update:dialogAddBandeira", false);
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },

    createBandeira() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let bandeira = {};

        bandeira.bandeira = this.bandeira.bandeira;
        bandeira.comissao = this.bandeira.comissao;
        bandeira.comissao_iva = this.bandeira.comissao_iva;
        bandeira.tipo = this.bandeira.tipo;
        bandeira.ret_renta = this.bandeira.ret_renta;

        postCartaoBandeira(bandeira)
          .then(() => {
            this.$emit("fetch-bandeiras");
            this.$emit("update:dialogAddBandeira", false);
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    if (this.edit) {
      this.bandeira = { ...this.item };
      this.bandeira_original = { ...this.item };
      this.$ci.setValue(this.$refs.valorComissao, this.bandeira.comissao);
      this.$ci.setValue(
        this.$refs.valorComissaoIva,
        this.bandeira.comissao_iva
      );
      this.$ci.setValue(this.$refs.valorRetencaoRenta, this.bandeira.ret_renta);
      this.selectedTipoBandeira = this.bandeira.tipo;
    }
  },
};
</script>

<style></style>
