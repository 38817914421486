import api from "../axios_service.js";

const fetchPdfLayouts = async (filtros) => {
  const response = await api.get(`/pdf-layouts${filtros ? filtros : ""}`);
  return response.data.result;
};

const fetchPdfLayoutPorTipo = async (tipo) => {
  const response = await api.get(`/pdf-layouts-por-tipo?tipo=${tipo}`);
  return response.data.result;
};

const fetchPdfLayout = async (id) => {
  const response = await api.get(`/pdf-layouts/${id}`);
  return response.data.result;
};

const fetchPdfLayoutImage = async (id) => {
  const response = await api.get(`/pdf-layouts/${id}/image`);
  return response.data.result;
};

const putPdfLayout = async (id, body) => {
  return api.put(`/pdf-layouts/${id}`, body);
};

const postPdfLayout = async (body) => {
  return api.post("/pdf-layouts/add", body);
};

export { fetchPdfLayouts, fetchPdfLayoutPorTipo, fetchPdfLayout, putPdfLayout, postPdfLayout, fetchPdfLayoutImage };
