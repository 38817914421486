<template>
  <v-dialog
    v-model="dialogNovaMoeda"
    @click:outside="$emit('update:dialogNovaMoeda', false)"
    max-width="500"
  >
    <v-card tile v-if="!loading">
      <v-card-title class="text-subtitle-1 lightgray">
        Adicionar Moeda
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogNovaMoeda', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6 px-5" v-if="!loading">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!-- Descrição -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="moeda.descricao"
                label="Descrição"
                outlined
                :rules="formRules"
                required
                dense
              ></v-text-field>
            </v-col>
            <!-- Sigla -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="moeda.sigla"
                label="Sigla"
                outlined
                :rules="formRules"
                required
                dense
              ></v-text-field>
            </v-col>
            <!-- Separador Milhar -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="moeda.separador_milhar"
                label="Separador Milhar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- Separador Decimal -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="moeda.separador_decimal"
                label="Separador Decimal"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- Contador Decimal -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="moeda.contador_decimal"
                label="Contador Decimal"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <!-- Status -->
            <v-col cols="12" md="6">
              <v-card
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Status
                </v-subheader>
                <v-switch
                  :false-value="-1"
                  :true-value="1"
                  v-model="moeda.status"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          depressed
          small
          @click="createMoeda"
        >
          {{ $tc("global.criar") }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postMoedaAdmin } from "@/api/moedas/moedas.js";
import { inputRequired } from "@/helpers/utils.js";

export default {
  name: "NovaMoeda",

  props: {
    dialogNovaMoeda: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      formRules: [inputRequired],
      validForm: true,
      moeda: {
        status: 1,
      },
    };
  },

  methods: {
    createMoeda() {
      if (this.$refs.form.validate()) {
        
        this.loading = true;
        postMoedaAdmin(this.moeda)
          .then(() => {
            this.loading = false;
            
            this.$store.dispatch("Moedas/getMoedas");
            this.$emit("update:dialogNovaMoeda", false);
          })
          .catch((error) => {
            this.$toast.error("Erro ao criar a moeda!");
            console.log(error);
            
            this.loading = false;
            this.$emit("update:dialogNovaMoeda", false);
          });
      }
    },
  },
};
</script>

<style></style>
