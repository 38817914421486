<template>
  <v-dialog v-model="dialogEstado" max-width="290" @keydown.esc="closeDialog()">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{ !edit ? $tc('global.novo') + ' ' + $tc('global.estado') : $tc('global.atualizar') + ' ' + $tc('global.estado') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-text-field
            v-model="estado.nome"
            :label="$tc('global.estado')"
            dense
            outlined
            :rules="formRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="estado.sigla"
            label="Sigla"
            dense
            outlined
            :rules="formRules"
            required
          ></v-text-field>
          <v-combobox
            v-if="pais.length > 0"
            :label="$tc('global.pais',2)"
            :items="pais"
            item-text="nome"
            item-value="id"
            dense
            disabled
            outlined
            solo
            flat
            hide-details
            v-model="selectedPais"
          >
          </v-combobox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? criarEstado() : atualizarEstado()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { updateEstado, createEstado } from "@/api/cidades/estados.js";
export default {
  name: "DialogEstados",

  props: {
    dialogEstado: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    pais: {
      type: Array,Object,
    },
    pais_id: {
      type: String, Number,
    }
  },

  data: () => {
    return {
      loading: false,
      estado: {},
      validForm: true,
      formRules: [inputRequired],
      selectedPais: null,
    };
  },

  methods: {
    closeDialog() {
      this.estado = {};
      this.$emit("update:dialogEstado", false);
      this.$emit("update:edit", false);
    },

    criarEstado() {
      if (this.$refs.form.validate()) {
        let estado2 = {};

        estado2.nome = this.estado.nome;
        estado2.sigla = this.estado.sigla;
        estado2.pais_id = this.pais_id;

        createEstado(estado2)
          .then(() => {
            this.estado = {};
            this.$emit("update:dialogEstado", false);
            this.$emit("fetch-estados");
          })
          .catch(() => {});
      }
    },

    atualizarEstado() {
      if (
        JSON.stringify(this.estado) === JSON.stringify(this.estado_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        let estado2 = {};

        estado2.nome = this.estado.nome;
        estado2.sigla = this.estado.sigla;
        estado2.pais_id = this.pais_id;

        updateEstado(this.estado.id, estado2)
          .then(() => {
            this.estado_original = { ...this.estado };
            this.$emit("fetch-estados");
            this.$emit("update:dialogEstado", false);
          })
          .catch(() => {});
      }
    },
  },
  async mounted() {

    this.selectedPais = this.pais;
    if (this.edit) {
      this.estado = { ...this.item };
      this.estado_original = { ...this.item };
      this.selectedPais = this.pais[0];
    } else {
      this.selectedPais = this.pais[0];
    }
  },
};
</script>

<style></style>
