<template>
  <v-container fluid>
    <template v-if="!loading">
      <PageToolbar
        voltar
        dark
        icon="mdi-account-circle"
        :title="usuario.name"
      />

      <v-card class="mt-8">
        <v-tabs v-model="tab">
          <v-tab class="caption font-weight-bold justify-start">
            {{ $tc("global.informacoes") }}
          </v-tab>
          <v-tab
            v-if="is_admin && $route.meta.menu != 'configuracoes'"
            class="caption font-weight-bold justify-start"
          >
            {{ $tc("global.empresa", 2) }}
          </v-tab>
          <v-tab class="caption font-weight-bold justify-start">
            {{ $tc("global.caixa", 2) }}
          </v-tab>
          <v-tab class="caption font-weight-bold justify-start">
            {{ $tc("global.timbrado", 2) }}
          </v-tab>
          <v-tab class="caption font-weight-bold justify-start">
            {{ $tc("global.permissao", 2) }}
          </v-tab>
          <v-tab class="caption font-weight-bold justify-start">
            {{ $tc("global.deposito", 2) }}
          </v-tab>
        </v-tabs>
        <v-container fluid class="py-0">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-title class="subtitle-1">
                <v-spacer></v-spacer>
                <v-btn
                  @click="
                    is_admin && $route.meta.menu == 'super_admin'
                      ? updateUsuarioAdmin()
                      : updateUsuario()
                  "
                  :disabled="!validForm"
                  color="button_1"
                  class="white--text"
                >
                  <v-icon left>mdi-check</v-icon>
                  {{ $tc("global.salvar") }}
                </v-btn>
              </v-card-title>
              <v-form
                @submit.prevent
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <v-row class="pb-10">
                  <v-col cols="12" md="2" class="">
                    <v-card
                      outlined
                      class="fill-height d-flex align-center justify-center"
                    >
                      <v-img v-if="avatar" :src="avatar" contain></v-img>
                      <v-img
                        v-else
                        :src="require('@/assets/pagina-default.png')"
                        contain
                      ></v-img>
                      <v-btn
                        fab
                        dark
                        x-small
                        absolute
                        bottom
                        right
                        @click="onButtonClick"
                      >
                        <input
                          accept="image/*"
                          type="file"
                          class="d-none"
                          ref="uploader"
                          @change="onFileChanged"
                        />
                        <v-icon>mdi-image</v-icon>
                      </v-btn>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="10">
                    <!-- Dados Gerais -->

                    <v-container fluid>
                      <v-row dense>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="usuario.email"
                            :rules="formRules"
                            required
                            label="Username"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </v-col>
                        <!-- Status -->
                        <v-col cols="12" md="4">
                          <v-card
                            v-if="
                              is_admin && $route.meta.menu != 'configuracoes'
                            "
                            outlined
                            height="40px"
                            class="pa-3 d-flex justify-space-between align-center"
                          >
                            <v-card-text class="body-2 py-0">
                              Status:
                            </v-card-text>
                            <v-switch
                              :false-value="0"
                              :true-value="1"
                              v-model="usuario.status"
                            ></v-switch>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model.lazy="usuario.name"
                            :label="$tc('global.nomecompleto')"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model.lazy="usuario.celular"
                            :label="$tc('global.celular')"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            name="senha"
                            :label="$tc('global.senha')"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            dense
                            outlined
                            v-model="usuario.password"
                            class="input-group--focused"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            name="confirmarSenha"
                            :label="$tc('global.confirmsenha')"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            dense
                            outlined
                            v-model="usuario.password_confirmation"
                            class="input-group--focused"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row> </v-form
            ></v-tab-item>
            <v-tab-item v-if="is_admin && $route.meta.menu != 'configuracoes'">
              <EmpresaUsuario :empresa_ativa="usuario.empresa_ativa" />
            </v-tab-item>
            <v-tab-item>
              <CaixaUsuario :user_id.sync="usuario_id" />
            </v-tab-item>
            <v-tab-item>
              <TimbradoUsuario :user_id.sync="usuario_id" />
            </v-tab-item>
            <v-tab-item>
              <PermissaoUsuario
                v-if="usuario_id"
                :usuarioId="usuario_id"
                :key="tab"
              />
            </v-tab-item>
            <v-tab-item>
              <DepositoUsuario :user_id.sync="usuario_id" />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { inputRequired } from "@/helpers/utils.js";
import {
  putUsuario,
  getUsuario,
  putUsuarioAtivo,
} from "@/api/usuarios/usuarios.js";

export default {
  name: "Usuario",
  props: ["usuarioId"],

  components: {
    TimbradoUsuario: () => import("./components/TimbradoUsuario.vue"),
    CaixaUsuario: () => import("./components/CaixaUsuario.vue"),
    EmpresaUsuario: () => import("./components/EmpresaUsuario.vue"),
    PermissaoUsuario: () => import("./components/PermissaoUsuario.vue"),
    DepositoUsuario: () => import("./components/DepositoUsuario.vue"),
  },

  data() {
    return {
      usuario: {},
      usuario_original: {},
      formRules: [inputRequired],
      show: false,
      tab: 0,
      validForm: true,
      loading: false,
      avatarUpload: null,
      notFound: false,
      original: {},
      selectedFile: null,
      apiUrl: null,
      avatar: "",
    };
  },

  computed: {
    usuario_id() {
      return this.usuarioId;
    },

    ...mapState("Usuario", {
      is_admin: (state) => state.usuario.is_admin,
    }),
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.avatarUpload = file;
      this.usuario.avatar = !this.usuario.avatar ? "" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },
    updateUsuarioAdmin() {
      if (
        JSON.stringify(this.usuario) === JSON.stringify(this.usuario_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const usuario = new FormData();

        if (this.avatarUpload) {
          usuario.append("avatar", this.avatarUpload, this.avatarUpload.name);
        }
        if (this.usuario.password) {
          usuario.append("password", this.usuario.password);
          usuario.append(
            "password_confirmation",
            this.usuario.password_confirmation
          );
        }
        usuario.append("name", this.usuario.name);
        usuario.append("empresa_ativa", this.usuario.empresa_ativa);
        usuario.append("email", this.usuario.email);
        usuario.append("celular", this.usuario.celular);
        usuario.append("status", this.usuario.status);

        putUsuario(this.usuario.id, usuario)
          .then(() => {
            this.usuario_original = { ...this.usuario };
            this.loading = false;
            this.$router.push({ path: "/super-admin/usuarios" });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateUsuario() {
      if (
        JSON.stringify(this.usuario) === JSON.stringify(this.usuario_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const usuario = new FormData();

        if (this.avatarUpload) {
          usuario.append("avatar", this.avatarUpload, this.avatarUpload.name);
        }
        if (this.usuario.password) {
          usuario.append("password", this.usuario.password);
          usuario.append(
            "password_confirmation",
            this.usuario.password_confirmation
          );
        }
        usuario.append("name", this.usuario.name);
        usuario.append("empresa_ativa", this.usuario.empresa_ativa);
        usuario.append("email", this.usuario.email);
        usuario.append("celular", this.usuario.celular);
        usuario.append("status", this.usuario.status);

        putUsuarioAtivo(this.usuario.id, usuario)
          .then(() => {
            this.usuario_original = { ...this.usuario };
            this.loading = false;
            this.$router.push({ path: "/configuracoes/usuarios" });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    fetchUser() {
      this.loading = true;

      getUsuario(this.usuario_id)
        .then((response) => {
          this.usuario = response;
          this.usuario_original = { ...response };
          this.avatar = this.usuario.avatar;
          this.loading = false;

          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.usuario.name
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.usuario.name);
          }
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
  },

  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    if (this.usuario_id) {
      this.fetchUser();
    } else {
      this.notFound = this.$tc("global.not-found");
    }
  },
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
