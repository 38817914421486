var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageToolbar',{attrs:{"title":_vm.$tc('global.antecipo'),"icon":"mdi-cash-plus","dark":""}}),_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',{staticClass:"tocs_gray_1"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn-toggle',{staticClass:"ml-3",attrs:{"mandatory":""},model:{value:(_vm.tipo_antecipo),callback:function ($$v) {_vm.tipo_antecipo=$$v},expression:"tipo_antecipo"}},[_c('v-btn',{staticClass:"text-caption",attrs:{"value":"cliente","height":"40px"}},[_vm._v(" "+_vm._s(_vm.$tc("global.cliente"))+" ")]),_c('v-btn',{staticClass:"text-caption",attrs:{"value":"fornecedor","height":"40px"}},[_vm._v(" "+_vm._s(_vm.$tc("global.fornecedor"))+" ")])],1),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),(_vm.AntecipoAdicionar)?_c('v-btn',{staticClass:"white--text mt-4 mt-sm-0",attrs:{"color":"button_1"},on:{"click":_vm.addAntecipo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" ")],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables",class:{ 'data-tables__row-click': _vm.AnteciposVisualizar },attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.antecipos,"items-per-page":15,"disable-sort":""},on:{"click:row":_vm.goToAntecipo},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created_at,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.valor_utilizado",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor_utilizado))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor_utilizado))+" ")])]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [(item.moeda == 'G$')?_c('div',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.saldo))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.recibo_id)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openReciboAntecipoPdf(item.recibo_id)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1):_vm._e()]}}])})],1)],1),(_vm.dialogAddEditAntecipo)?_c('DialogAddEditAntecipo',{attrs:{"dialogAddEditAntecipo":_vm.dialogAddEditAntecipo,"edit":_vm.edit,"item":_vm.antecipo},on:{"update:dialogAddEditAntecipo":function($event){_vm.dialogAddEditAntecipo=$event},"update:dialog-add-edit-antecipo":function($event){_vm.dialogAddEditAntecipo=$event},"fetch-antecipos":_vm.getAtencipos}}):_vm._e(),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"RECIBO"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }