<template>
  <v-dialog
    v-model="dialogDepositos"
    @click:outside="$emit('update:dialogDepositos', false)"
    @keydown.esc="$emit('update:dialogDepositos', false)"
    width="600px"
  >
    <v-card>
      <v-card-title class="px-md-6 subtitle-1 tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="this.$tc('global.buscar')"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>

        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          color="button_1"
          v-if="PDepositoAdicionar"
          class="white--text"
          @click="adicionarDeposito()"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
        <!-- <Marcas />

        <DialogCategorias /> -->
        <!-- <Depositos /> -->
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="depositos"
          :search="search.length >= 3 ? search : ''"
          :items-per-page="-1"
          :sort-by="['id']"
          class="data-tables"
          :class="{ 'data-tables__row-click': PDepositoVisualizar }"
          @click:row="openDeposito"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="PDepositoDeletar"
              icon
              @click.stop="deleteDeposito(item.id)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogAddEditDeposito
      v-if="dialogAddEditDeposito"
      :dialogAddEditDeposito.sync="dialogAddEditDeposito"
      :item="deposito"
      :edit="edit"
      @fetch-depositos="getDepositos"
    />
  </v-dialog>
  <!-- <NaoAutorizado v-else /> -->
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  fetchDepositos,
  deleteDeposito,
} from "@/api/produtos/produtos_depositos.js";
import { mapGetters } from "vuex";
export default {
  name: "DialogDepositos",
  props: { dialogDepositos: { type: Boolean, default: false } },
  data() {
    return {
      search: "",
      formRules: [inputRequired],
      loading: false,
      depositos: [],
      deposito: {},
      dialogAddEditDeposito: false,
      edit: false,
      cidades: null,
      loadingCidades: false,
    };
  },
  components: {
    DialogAddEditDeposito: () =>
      import("./components/DialogAddEditDeposito.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PDepositoVisualizar() {
      return this.getAccess("Depositos", "visualizar");
    },
    PDepositoAdicionar() {
      return this.getAccess("Depositos", "adicionar");
    },
    PDepositoDeletar() {
      return this.getAccess("Depositos", "deletar");
    },
    disableAdd() {
      if (this.deposito.descricao && this.deposito.cidade_id) {
        return false;
      } else {
        return true;
      }
    },
    headers() {
      return [
        {
          text: this.$tc("global.id"),
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.cidade"),
          value: "cidade",
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
        {
          text: "",
          width: "70px",
          align: "right",
          value: "actions",
        },
      ];
    },
  },

  methods: {
    getDepositos() {
      fetchDepositos()
        .then((response) => {
          this.depositos = response;
        })
        .catch((error) => {})
        .finally(() => {});
    },

    adicionarDeposito() {
      this.deposito = {};
      this.edit = false;
      this.dialogAddEditDeposito = true;
    },
    openDeposito(item) {
      if (this.PDepositoVisualizar) {
        this.deposito = { ...item };
        this.edit = true;
        this.dialogAddEditDeposito = true;
      }
    },
    deleteDeposito(id) {
      deleteDeposito(id)
        .then(() => {
          this.getDepositos();
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$toast.error(this.$tc("global.depositoComProdutosSaldo"));
          } else {
            this.$toast.error("Erro ao deletar Deposito");
          }
        });
    },
  },
  created() {
    this.getDepositos();
    // this.getCidades();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr {
  &.v-data-table__expanded__row {
    background: #eeeeee;
    margin-top: 10px;
    td {
      border-top: 0px solid #dddddd;

      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }

  &.v-data-table__expanded__content {
    margin-bottom: 10px;
    box-shadow: none;
    td {
      border-bottom: none;
    }
  }
}

::v-deep .sub-data-tables {
  border-bottom: 0px solid #dddddd;
  thead {
    background: #eeeeee;
    th {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
  tbody {
    td {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
}
</style>
