const routes = [
  {
    path: "/relatorios",
    // component: () => import("@/views/dashboard/relatorios/Relatorios.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.relatorio", plural: true },
      icon: "mdi-home-analytics",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.relatorio", plural: true },
      ],
      permission: { componente: "Relatorios", },
    },
  },
];

export default routes;
