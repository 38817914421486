<template>
  <v-container fluid>
    <PageToolbar
      :title="`${$tc('global.atualizar')}  ${$tc('global.pdf_layout')}`"
      icon="mdi-format-page-break"
      dark
    />
    <v-card flat :loading="loading" :disabled="loading">
      <v-tabs class="" v-model="tab">
        <v-tab class="caption font-weight-bold">
          {{ $tc("global.dados-gerais") }}
        </v-tab>
        <v-tab class="caption font-weight-bold">
          {{ $tc("global.pdf_layout") }} Items
        </v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-tabs-items v-if="!loading" v-model="tab">
        <v-tab-item>
          <PdfLayoutDados v-if="tab === 0" :pdf-layout="layout" />
        </v-tab-item>
        <v-tab-item>
          <PdfLayoutItems v-if="tab === 1" :pdf-layout="layout" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { fetchPdfLayout } from "@/api/pdf-layouts/pdf_layouts.js";

export default {
  name: "PdfLayout",

  components: {
    PdfLayoutDados: () => import("./PdfLayoutDados.vue"),
    PdfLayoutItems: () => import("./PdfLayoutItems.vue"),
  },

  data() {
    return {
      tab: 1,
      layout: null,
      loading: true,
    };
  },

  computed: {
    pdf_layout_id() {
      return this.$route.params.id;
    },
  },

  methods: {
    getPdfLayout() {
      fetchPdfLayout(this.pdf_layout_id)
        .then((response) => {
          this.layout = response;

          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.$tc("global.pdf_layout")
          ) {
            this.$store.commit(
              "UPDATE_DYNAMICCRUMB",
              this.$tc("global.pdf_layout")
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getPdfLayout();
  },
};
</script>

<style></style>
