import api from "../axios_service.js";

export async function filterFiles(filtros) {
  const response = await api.get(`/midias${filtros}`);
  return response.data.result;
}

export async function uploadFiles(fd) {
  const response = await api.post("/midias/uploadFiles", fd);
  return response;
}

export function putFile(midia, id) {
  let body = {};
  for (let key in midia) {
    body[key] = midia[key];
  }
  return api.put(`/midias/${id}`, body);
}

export function deleteMidia(id) {
  return api.delete(`/midias/${id}`);
}
