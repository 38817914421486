<template>
  <v-dialog
    v-model="dialogGerarRecibo"
    @click:outside="$emit('update:dialogGerarRecibo', false)"
    @keydown.esc="$emit('update:dialogGerarRecibo', false)"
    scrollable
    max-width="700px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        GERAR RECIBO
        <v-spacer></v-spacer>

        <v-btn icon @click="$emit('update:dialogGerarRecibo', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card flat style="overflow: auto">
        <v-card-text class="py-3">
          <v-form>
            <v-row dense>
              <!-- DATA RECIBO -->
              <v-col class="pt-5" cols="12" md="5">
                <DataField
                  hide_details
                  disabled
                  :data_sync.sync="recibo.data_recibo"
                  label="Data Recibo"
                />
              </v-col>

              <!-- MOEDA -->
              <v-col class="pt-5" cols="12" md="3">
                <v-combobox
                  v-model="selectedMoeda"
                  :label="$tc('global.moeda')"
                  :items="moedas"
                  disabled
                  dense
                  outlined
                  required
                  item-text="sigla"
                  item-value="id"
                ></v-combobox>
              </v-col>

              <!-- VALOR -->
              <v-col class="pt-5" cols="12" md="4">
                <v-text-field
                  ref="valorRecibo"
                  v-model="valorRecibo"
                  :label="$tc('global.valor')"
                  dense
                  disabled
                  outlined
                  v-currency="vCurrencyOptions"
                ></v-text-field>
              </v-col>

              <!-- QUEM ASSINA -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recibo.quem_assina"
                  label="Quem Assina"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <!-- documento -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="recibo.documento"
                  :label="$tc('global.documento')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <!-- DESCRICÃO -->
              <v-col cols="12">
                <v-textarea
                  v-model="recibo.concepto_descricao"
                  label="Concepto"
                  dense
                  rows="2"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="button_2"
              class="white--text"
              depressed
              small
              @click="criarRecibo()"
            >
              Gerar Recibo <v-icon>mdi-plus</v-icon></v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { createRecibo } from "@/api/recibos/recibos.js";
import { mapState } from "vuex";
export default {
  name: "DialogGerarRecibo",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    dialogGerarRecibo: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
    origem: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedMoeda: null,
      recibo: {},
      caixa_mov_item: {},
      valorRecibo: null,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    vCurrencyOptions() {
      return {
        precision: this.moedas.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    valorRecibo() {
      this.recibo.valor_recibo = this.$ci.parse(
        this.valorRecibo,
        this.vCurrencyOptions
      );
    },
    selectedMoeda() {
      if (this.selectedMoeda && !this.recibo.moeda_id) {
        this.recibo.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
  },

  methods: {
    criarRecibo() {
      const recibo_2 = {};

      recibo_2.quem_assina = this.recibo.quem_assina;
      recibo_2.data_recibo = this.recibo.data_recibo;
      recibo_2.origem_id = this.caixa_mov_item.id;
      recibo_2.concepto_descricao = this.recibo.concepto_descricao;
      recibo_2.moeda_id = this.recibo.moeda_id;
      recibo_2.valor_recibo = this.recibo.valor_recibo;
      recibo_2.origem = this.origem;

      createRecibo(recibo_2)
        .then(() => {
          this.$emit("update:dialogGerarRecibo", false);
          this.$emit("fetch-caixa-mov");
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.caixa_mov_item = { ...this.item };
    this.recibo.data_recibo = this.caixa_mov_item.data;
    this.$ci.setValue(
      this.$refs.valorRecibo,
      Number(this.caixa_mov_item.valor)
    );
    this.recibo.moeda_id = this.caixa_mov_item.moeda_id;

    if (this.recibo.moeda_id) {
      this.selectedMoeda = this.moedas.find(
        (moeda) => moeda.id_moeda === this.recibo.moeda_id
      );
      this.recibo.moeda_id = this.selectedMoeda.id_moeda;
    }
  },
};
</script>

<style></style>
