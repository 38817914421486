function formatJson(filterVal, jsonData) {
  return jsonData.map(v =>
    filterVal.map(j => {
      return v[j];
    })
  );
}

export function excelDownload(tHeader, filterVal, list, filename) {
  return new Promise((resolve, reject) => {
    import("@/helpers/Export2Excel").then(excel => {
      const data = formatJson(filterVal, list);
      excel.export_json_to_excel({
        header: tHeader,
        data,
        filename: filename,
        autoWidth: true,
        bookType: "xlsx"
      });
      resolve();
    });
  });
}
