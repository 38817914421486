import api from "../axios_service.js";

export async function all(filtros) {
  const response = await api.get(`/recibos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchRecibo(id) {
  const response = await api.get(`/recibos/${id}`);
  return response.data.result;
}

export async function fetchOneRecibo(id) {
  const response = await api.get(`/recibo/${id}`);
  return response.data.result;
}

export function updateRecibo(id, recibo) {
  return api.put(`/recibos/${id}`, recibo);
}

export function createRecibo(recibo) {
  return api.post("/recibos/add", recibo);
}
