<template>
  <v-dialog v-model="dialogAddDocumento" persistent width="450px">
    <v-card>
      <v-card-title class="px-6 toolbar">
        <span class="white--text">
          {{ $tc("global.add") }} {{ $tc("global.documento") }}</span
        >
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:dialogAddDocumento', false)" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="6" class="pt-7">
            <v-text-field
              v-model="cliente.doc_ruc"
              label="RUC"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pt-7">
            <v-text-field
              v-model="cliente.doc_ci"
              label="C.I"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="addDocumento" small class="white--text" color="button_2">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { putCliente } from "@/api/clientes/clientes.js";
export default {
  name: "DialogAddDocumento",

  props: {
    dialogAddDocumento: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: Number,
    },
  },

  data() {
    return {
      cliente: {
        doc_ci: null,
        doc_ruc: null,
      },
    };
  },

  methods: {
    addDocumento() {

      putCliente(this.cliente_id, this.cliente)
        .then(() => {
          this.$emit("update:dialogAddDocumento", false);
          this.$emit("open-pagare");
        })
        .catch((err) => {
          this.$toast.error("Algo de Errado");
        });
    },
  },
};
</script>

<style></style>
