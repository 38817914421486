<template>
  <v-combobox
    class="ma-0"
    height="40px"
    :items="cidades"
    :label="$tc('global.cidade')"
    item-text="nome"
    item-value="id_cidade"
    dense
    outlined
    hide-details
    v-model="selectedCidades"
    :loading="loadingCidades"
    clearable
    @click:clear="clearCidades"
  ></v-combobox>
</template>

<script>
// import { fetchCidades } from "@/services/api/cidades.api.js";
import { mapState } from "vuex";

export default {
  name: "Cidades",

  data() {
    return {
      cidades: [],
      selectedCidades: null,
      cidade: null,
      loadingCidades: true,
    };
  },

  watch: {
    selectedCidades() {
      if (this.selectedCidades) {
        this.cidade = this.selectedCidades.id_cidade;
      }
    },
    cidade() {
      if (this.cidade) {
        this.$store.commit("FiltrosRelatorios/UPDATE_CIDADE", this.cidade);
      }
    },
  },

  methods: {
    clearCidades() {
      this.selectedCidades = null;
      this.cidade = null;
      this.$store.commit("FiltrosRelatorios/UPDATE_CIDADE", null);
    },
    // getCidades() {
    //   fetchCidades()
    //     .then((response) => {
    //       this.cidades = response;
    //       if (this.$route.query.cidade) {
    //         let cidade = this.cidades.find(
    //           (item) => item.id_cidade == this.$route.query.cidade
    //         );
    //         this.selectedCidades = cidade.id_cidade;
    //       }
    //     })
    //     .catch((error) => console.log(error))
    //     .finally(() => {
    //       this.loadingCidades = false;
    //     });
    // },
  },

  mounted() {
    // this.getCidades();
  },
};
</script>

<style></style>
