<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <PageToolbar
          :title="$tc('global.addproduto')"
          icon="mdi-basket"
          voltar
          dark
        />
      </v-col>

      <v-col cols="12">
        <v-card class="pa-sm-6">
          <v-card-title class="subtitle-1">
            {{ $tc("global.dados-gerais") }}
            <v-spacer></v-spacer>
            <v-btn
              @click="criarProduto"
              :disabled="!validForm"
              color="button_1"
              class="white--text"
            >
              <v-icon left>mdi-check</v-icon>
              {{ $tc("global.add") }}
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-tabs v-model="tab">
            <v-tab>{{ $tc("global.cadastro") }}</v-tab>
            <v-tab>{{ $tc("global.avancado") }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-text class="pt-7">
                <v-form
                  @submit.prevent
                  ref="form"
                  v-model="validForm"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-card
                        flat
                        class="fill-height d-flex align-center justify-center"
                      >
                        <v-card flat v-if="img">
                          <v-img :src="img" contain></v-img>
                        </v-card>
                        <div v-else class="fill-height" style="width: 100%">
                          <vue-dropzone
                            ref="myVueDropzone"
                            id="dropzone"
                            :class="{ hoverFile: hoverFile }"
                            :use-custom-dropzone-options="true"
                            :options="dropzoneOptions"
                            @vdropzone-file-added="fileAdded"
                            @vdropzone-drag-enter="hoverFile = true"
                            @vdropzone-drag-over="hoverFile = true"
                            @vdropzone-drag-leave="hoverFile = false"
                            :useCustomSlot="true"
                            :include-styling="false"
                          >
                            <div class="text-center">
                              <v-icon size="80" color="info">
                                mdi-image-plus
                              </v-icon>
                              <h3 class="text-subtitle-1">
                                {{ $tc("global.arrastesolte") }}
                              </h3>
                              <div class="text-caption">
                                {{ $tc("global.cliqueselecionar") }}
                              </div>
                            </div>
                          </vue-dropzone>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-row dense>
                        <!-- Descrição -->
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="produto.descricao"
                            :label="this.$tc('global.descricao')"
                            outlined
                            dense
                            :rules="formRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <!-- Classificações -->
                        <v-col cols="12" md="3">
                          <v-select
                            v-model="produto.produto_categoria_id"
                            :items="categorias"
                            :loading="loadingClass"
                            clearable
                            item-text="descricao"
                            item-value="id"
                            dense
                            outlined
                            :label="this.$tc('global.categoria')"
                          ></v-select>
                        </v-col>
                        <!-- Referencia -->
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="produto.referencia"
                            :label="this.$tc('global.referencia')"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <!-- Kit -->
                        <v-col cols="12" md="3">
                          <v-card
                            outlined
                            height="40px"
                            class="pa-3 d-flex justify-space-between align-center"
                          >
                            <v-subheader
                              style="height: auto !important"
                              class="ma-0 pa-0 body-1"
                            >
                              {{ $tc("global.produtoKit") }}
                            </v-subheader>
                            <div class="d-flex align-center">
                              {{
                                produtoKit
                                  ? $tc("global.sim")
                                  : $tc("global.nao")
                              }}
                              <v-switch
                                class="pl-2"
                                :false-value="false"
                                :true-value="true"
                                v-model="produtoKit"
                              ></v-switch>
                            </div>
                          </v-card>
                        </v-col>
                        <!-- Controle de estoque -->
                        <v-col cols="12" md="4">
                          <v-card
                            outlined
                            height="40px"
                            class="pa-3 d-flex justify-space-between align-center"
                          >
                            <v-subheader
                              style="height: auto !important"
                              class="ma-0 pa-0 body-1"
                            >
                              {{ $tc("global.controleEstoque") }}
                            </v-subheader>
                            <div class="d-flex align-center">
                              {{
                                produto.controle_estoque
                                  ? $tc("global.sim")
                                  : $tc("global.nao")
                              }}
                              <v-switch
                                class="pl-2"
                                :false-value="0"
                                :true-value="1"
                                :disabled="disableControleEstoque"
                                v-model="produto.controle_estoque"
                              ></v-switch>
                            </div>
                          </v-card>
                        </v-col>
                        <!-- Url -->
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="produto.url"
                            label="Url"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <!-- Moeda Custo -->
                        <v-col cols="12" md="2">
                          <v-select
                            v-model="selectedMoeda"
                            :items="moedas"
                            clearable
                            dense
                            outlined
                            required
                            :rules="formRules"
                            item-text="sigla"
                            item-value="id"
                            :disabled="moeda_empresa ? true : false"
                            :label="
                              this.$tc('global.moeda') +
                              ' ' +
                              this.$tc('global.custo')
                            "
                            return-object
                          ></v-select>
                        </v-col>

                        <!-- Fornecedores -->
                        <v-col cols="12" md="3">
                          <v-combobox
                            v-model="selectedFornecedor"
                            :label="$tc('global.fornecedor')"
                            :items="fornecedores"
                            item-text="nome"
                            item-value="id"
                            dense
                            outlined
                            clearable
                            hide-details
                            class="mb-2"
                            required
                            return-object
                            :rules="formRules"
                          ></v-combobox>
                        </v-col>
                        <!-- Estoque Min -->
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="produto.estoque_min"
                            :label="this.$tc('global.produto') + ' Min'"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <!-- Estoque Max -->
                        <v-col cols="12" md="2 ">
                          <v-text-field
                            v-model="produto.estoque_max"
                            :label="this.$tc('global.produto') + ' max'"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <!-- Qtde por Caixa -->
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-model="produto.qtde_caixa"
                            :label="this.$tc('global.qtdcaixa')"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <!-- Unidade de Medida -->
                        <v-col cols="12" md="3">
                          <v-select
                            v-model="selectedUnidadeMedida"
                            :items="unidades"
                            clearable
                            dense
                            outlined
                            :label="this.$tc('global.unidademed')"
                          ></v-select>
                        </v-col>

                        <!-- tipo imposto -->
                        <v-col cols="12" md="3">
                          <v-select
                            v-model="produto.tipo_imposto"
                            :items="tipo_imposto"
                            clearable
                            dense
                            outlined
                            item-text="descricao"
                            item-value="value"
                            :label="
                              $tc('global.tipo') + ' ' + $tc('global.imposto')
                            "
                          ></v-select>
                        </v-col>
                        <!-- Descrição detalhada -->
                        <v-col cols="12">
                          <v-textarea
                            v-model="produto.descricao_detalhada"
                            :label="this.$tc('global.descricaodetalhada')"
                            :rows="3"
                            outlined
                            dense
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
                <ProdutosKit
                  v-if="produtoKit"
                  :produtoSelected.sync="produtoSelected"
                />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <!-- PRODUTO -->
                <v-col cols="12">
                  <span class="text-h6 font-weight-bold"> Produto </span>
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12" md="12">
                  <v-row dense>
                    <!-- PESO -->
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="produto.peso_produto"
                        dense
                        outlined
                        :label="this.$tc('global.peso') + ' KG'"
                      ></v-text-field>
                    </v-col>
                    <!-- Volume M³ -->
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="produto.volume_produto"
                        :label="this.$tc('global.volume') + ' M³'"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <!-- Dimensao -->
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="produto.dimensoes_produto"
                        :label="this.$tc('global.dimensao') + ' Cm'"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <!-- vencimento -->
                    <v-col cols="12" md="6">
                      <DataField
                        :data_sync.sync="produto.vencimentos"
                        :label="this.$tc('global.vencimento')"
                      />
                    </v-col>
                    <!-- Codigo NCM -->
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model="produto.cdg_ncm"
                        dense
                        outlined
                        :label="this.$tc('global.codigo') + ' NCM'"
                      ></v-text-field>
                    </v-col>

                    <!-- Codigo de barras -->
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="produto.cdg_barras"
                        dense
                        outlined
                        :label="this.$tc('global.codigoBarras')"
                      ></v-text-field>
                    </v-col>

                    <!-- Tipo -->
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="produto.tipo_cdg_barras"
                        :items="tipos"
                        clearable
                        :label="this.$tc('global.tipo')"
                        outlined
                        dense
                      ></v-select>
                    </v-col>

                    <!-- Descrição detalhada -->
                    <v-col cols="12">
                      <v-textarea
                        v-model="produto.descricao_detalhada"
                        :label="this.$tc('global.descricaodetalhada')"
                        :rows="3"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-card outlined class="pa-3">
                    <v-row>
                      <v-col cols="12">
                        <v-subheader class="text-h6 font-weight-bold">
                          {{ $tc("global.caixa", 2) }}
                        </v-subheader>
                        <v-divider></v-divider>
                      </v-col>

                      <v-col cols="12" md="12">
                        <v-row dense>
                          <!-- Quantidade de produto por caixa -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.qtde_caixa"
                              dense
                              outlined
                              :label="this.$tc('global.produtoPorCaixa')"
                            ></v-text-field>
                          </v-col>
                          <!-- PESO -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.peso_caixa"
                              clearable
                              dense
                              outlined
                              :label="this.$tc('global.peso') + ' KG'"
                            ></v-text-field>
                          </v-col>
                          <!-- Volume M³ -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.volume_caixa"
                              :label="this.$tc('global.volume') + ' M³'"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <!-- Dimensao -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.dimensoes_caixa"
                              :label="this.$tc('global.dimensao') + ' Cm'"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <!-- LOTE -->
                          <v-col cols="12" md="8">
                            <v-text-field
                              v-model="produto.lote"
                              :label="this.$tc('global.lote')"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <!-- PALLET --->
                <v-col cols="12" md="6">
                  <v-card outlined class="pa-3">
                    <v-row>
                      <v-col cols="12">
                        <v-subheader class="text-h6 font-weight-bold">
                          Pallets
                        </v-subheader>
                        <v-divider></v-divider>
                      </v-col>

                      <v-col cols="12" md="12">
                        <v-row dense>
                          <!-- Quantidade de caixa no Pallet -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.qtde_caixa_pallet"
                              :label="this.$tc('global.caixasPorPallet')"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <!-- PESO -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.peso_pallet"
                              clearable
                              dense
                              outlined
                              :label="this.$tc('global.peso') + ' KG'"
                            ></v-text-field>
                          </v-col>
                          <!-- Volume M³ -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.volume_pallet"
                              :label="this.$tc('global.volume') + ' M³'"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <!-- Dimensao -->
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="produto.dimensoes_pallet"
                              :label="this.$tc('global.dimensao') + ' Cm'"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- <NaoAutorizado v-else /> -->
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { postProdutos, getAllProClassAtivo } from "@/api/produtos/produtos.js";
import { inputRequired } from "@/helpers/utils.js";
import { getFornecedores } from "@/api/fornecedores/fornecedores.js";

import { mapState } from "vuex";

export default {
  name: "NovoProduto",

  components: {
    vueDropzone: vue2Dropzone,
    DataField: () => import("@/components/fields/DataField.vue"),
    ProdutosKit: () => import("./components/ProdutosKit.vue"),
  },

  props: {},

  data() {
    return {
      produto: {
        controle_estoque: 1,
      },
      produtoKit: false,
      produtoKitSemPreco: false,
      categorias: [],
      fornecedores: [],
      loadingClass: true,
      selectedMoeda: null,
      selectedFornecedor: null,
      validForm: true,
      img: null,
      imgUpload: null,
      hoverFile: false,
      formRules: [inputRequired],
      unidades: [
        {
          text: this.$tc("global.metros"),
          value: "MTS",
        },
        {
          text: this.$tc("global.unidade", 2),
          value: "UND",
        },
        {
          text: "KG",
          value: "KGS",
        },
      ],
      produtoPreco: null,
      tab: 0,
      tipos: [
        {
          text: "EAN-13",
          value: "EAN-13",
        },
        {
          text: "EAN-8",
          value: "EAN-8",
        },
        {
          text: "EAN-128",
          value: "EAN-128",
        },
        {
          text: "DUN-14 / ITF-14",
          value: "DUN-14",
        },
        {
          text: "ITF",
          value: "ITF",
        },
        {
          text: "UPC",
          value: "UPC-A",
        },
        {
          text: "CODE 11",
          value: "CODE 11",
        },
        {
          text: "CODE 39",
          value: "CODE 39",
        },
        {
          text: "CODE 93",
          value: "CODE 93",
        },
        {
          text: "CODE 128",
          value: "CODE 128",
        },
        {
          text: "CODABAR",
          value: "CODABAR",
        },
      ],
      tipo_imposto: [
        {
          descricao: "ISENTO",
          value: "ISENTO",
        },
        {
          descricao: "GRAVADO 10 %",
          value: "GRAVADO_10",
        },
        {
          descricao: "GRAVADO 5 %",
          value: "GRAVADO_5",
        },
        {
          descricao: "DECRETO",
          value: "DECRETO",
        },
      ],
      produtoSelected: [],
      selectedUnidadeMedida: null,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
      moeda: (state) => state.moedas[0],
    }),
    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),

    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },

    vCurrencyOptions() {
      return {
        precision: this.moeda.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    vCurrencyOptions2() {
      return {
        precision: 0,
        distractionFree: false,
      };
    },

    disableControleEstoque() {
      let result = false;

      if (this.produtoKit) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.produto.custo_moeda_id = this.selectedMoeda.id;
      }
    },
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.produto.fornecedor_id = this.selectedFornecedor.id;
      } else {
        this.produto.fornecedor_id = null;
      }
    },

    produtoPreco() {
      this.produto.preco = this.$ci.parse(
        this.produtoPreco,
        this.vCurrencyOptions2
      );
    },

    produtoKit() {
      if (this.produtoKit) {
        this.produto.controle_estoque = 0;
      }
    },

    selectedUnidadeMedida() {
      if (this.selectedUnidadeMedida) {
        this.produto.und = this.selectedUnidadeMedida;
      }
    },
  },

  methods: {
    fileAdded(file) {
      this.imgUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.img = event.target.result;
      };
    },

    fetchFornecedores() {
      getFornecedores()
        .then((response) => {
          this.fornecedores = response;
        })
        .catch((error) => console.log(error));
    },

    getProClass() {
      getAllProClassAtivo()
        .then((response) => {
          this.categorias = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loadingClass = false;
        });
    },

    criarProduto() {
      if (this.$refs.form.validate()) {
        const produto = new FormData();

        if (this.imgUpload) {
          produto.append("img", this.imgUpload, this.imgUpload.name);
          // produto.append("img64", this.img);
        }

        let produto_2 = {};
        produto_2.empresa_id = this.produto.empresa_id;
        produto_2.produto_categoria_id = this.produto.produto_categoria_id;
        produto_2.descricao = this.produto.descricao;
        produto_2.descricao_detalhada = this.produto.descricao_detalhada;
        produto_2.referencia = this.produto.referencia;
        produto_2.fornecedor_id = this.produto.fornecedor_id;
        produto_2.custo_moeda_id = this.produto.custo_moeda_id;
        produto_2.preco = this.produto.preco;
        produto_2.und = this.produto.und;
        produto_2.qtde_caixa = this.produto.qtde_caixa;
        produto_2.estoque_min = this.produto.estoque_min;
        produto_2.estoque_max = this.produto.estoque_max;
        produto_2.url = this.produto.url;
        produto_2.peso_produto = this.produto.peso_produto;
        produto_2.peso_caixa = this.produto.peso_caixa;
        produto_2.peso_pallet = this.produto.peso_pallet;
        produto_2.volume_produto = this.produto.volume_produto;
        produto_2.volume_caixa = this.produto.volume_caixa;
        produto_2.volume_pallet = this.produto.volume_pallet;
        produto_2.qtde_caixa = this.produto.qtde_caixa;
        produto_2.qtde_caixa_pallet = this.produto.qtde_caixa_pallet;
        produto_2.dimensoes_pallet = this.produto.dimensoes_pallet;
        produto_2.dimensoes_produto = this.produto.dimensoes_produto;
        produto_2.dimensoes_caixa = this.produto.dimensoes_caixa;
        produto_2.cdg_barras = this.produto.cdg_barras;
        produto_2.tipo_cdg_barras = this.produto.tipo_cdg_barras;
        produto_2.cdg_ncm = this.produto.cdg_ncm;
        produto_2.lote = this.produto.lote;
        produto_2.vencimentos = this.produto.vencimentos;
        produto_2.descricao_detalhada = this.produto.descricao_detalhada;
        produto_2.tipo_imposto = this.produto.tipo_imposto;
        produto_2.controle_estoque = this.produto.controle_estoque;
        produto_2.produto_kit = this.produtoKit;

        for (let key in produto_2) {
          if (
            produto_2[key] !== null &&
            produto_2[key] !== undefined &&
            produto_2[key] !== ""
          ) {
            produto.append(key, produto_2[key]);
          }
        }

        if (this.produtoKit) {
          let produtos_kits = [];
          this.produtoSelected.forEach((produto) => {
            let produto_kit = {};
            produto_kit.id = produto.id;
            produto_kit.qtde = produto.qtde;
            produto_kit.preco = this.$ci.parse(
              produto.preco,
              this.vCurrencyOptions2
            );
            produtos_kits.push(produto_kit);
          });

          this.produtoKitSemPreco = this.produtoSelected.some(
            (produto) =>
              this.$ci.parse(produto.preco, this.vCurrencyOptions) <= 0
          );

          produto.append(
            "produtos_kit_selecionados",
            JSON.stringify(produtos_kits)
          );
        }
        if (!this.produtoKit || !this.produtoKitSemPreco) {
          postProdutos(produto)
            .then(() => {
              this.$router.push({ path: "/estoque/produtos" });
            })
            .catch((error) => {
              this.$toast.error("Referência já existente!");
            });
        } else {
          this.$toast.error(this.$tc("global.produtoKitValorInvalido"));
        }
      }
    },
  },

  mounted() {
    this.produto.tipo_imposto = "GRAVADO_10";
    this.getProClass();
    this.fetchFornecedores();
    if (this.moeda_empresa) {
      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda === this.moeda_empresa;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.input-center ::v-deep input {
  text-align: center;
}

.input-right ::v-deep input {
  text-align: right;
}

.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
