<template>
  <v-dialog
    v-model="dialogBaixarContasAddFormaPago"
    persistent
    @keydown.esc="$emit('update:dialogBaixarContasAddFormaPago', false)"
    width="1000px"
  >
    <v-card outlined>
      <v-card-title class="text-subtitle-1 px-3 py-2 lightgray">
        {{ $tc("global.add") }} {{ $tc("global.formapagamento") }}
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="$emit('update:dialogBaixarContasAddFormaPago', false)"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-7">
        <v-row>
          <!-- Forma de Pagamento -->
          <v-col cols="12">
            <v-combobox
              class="ma-0"
              :disabled="valorRestante == 0 ? true : false"
              height="40px"
              :items="formaPagamentos"
              item-text="descricao"
              item-value="id"
              :loading="loadingFormaPagamentos"
              :label="$tc('global.formapagamento')"
              placeholder="Selecione uma forma de pagamento"
              clearable
              dense
              outlined
              hide-details
              v-model="formaPagamentoSelected"
            ></v-combobox>
          </v-col>

          <v-col
            v-if="
              formaPagamentoSelected &&
              (formaPagamentoSelected.id === 6 ||
                formaPagamentoSelected.id === 7)
            "
          >
            <v-combobox
              class="ma-0"
              height="40px"
              :items="bandeiras"
              item-text="bandeira"
              item-value="id"
              :loading="loading"
              :label="$tc('global.bandeira')"
              clearable
              dense
              outlined
              hide-details
              v-model="selectedBandeira"
            ></v-combobox>
            <v-text-field
              v-model="codigoAutorizacao"
              :label="$tc('global.codigoAutorizacao')"
              class="pt-4"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>

          <BaixaCheque
            v-if="formaPagamentoSelected && formaPagamentoSelected.id === 4"
            :valor_restante.sync="valorRestante"
            :moeda_venda="moeda_conta"
            :allowCheque.sync="allowCheque"
            :cheque.sync="chequeAdd"
          />

          <template v-if="formaPagamentoSelected">
            <template
              v-if="
                formaPagamentoSelected.id === 1 ||
                formaPagamentoSelected.id === 2 ||
                formaPagamentoSelected.id === 6 ||
                formaPagamentoSelected.id === 7 ||
                formaPagamentoSelected.id === 8
              "
            >
              <v-col cols="12" md="12">
                <v-text-field
                  v-if="formaPagamentoSelected.id === 2"
                  v-model="formaPagamentoItem.documento"
                  :label="$tc('global.documento')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <!-- Caixa -->
              <v-col
                cols="12"
                md="12"
                v-if="
                  formaPagamentoSelected.id === 1 ||
                  formaPagamentoSelected.id === 2 ||
                  formaPagamentoSelected.id === 6 ||
                  formaPagamentoSelected.id === 7 ||
                  formaPagamentoSelected.id === 8
                "
              >
                <v-select
                  v-model="caixaSelected"
                  :items="caixas"
                  :loading="loadingTipoCaixas"
                  item-text="descricao"
                  item-value="id_tipo_caixa"
                  :label="$tc('global.caixa')"
                  dense
                  outlined
                  hide-details
                  return-object
                ></v-select>
              </v-col>

              <!-- Valor -->
              <v-col
                v-if="formaPagamentoSelected.id !== 8"
                cols="12"
                md="12"
                class="d-flex"
              >
                <div class="d-flex">
                  <v-btn
                    icon
                    small
                    @click="enableSelectMoeda"
                    class="mr-2 mt-2"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-select
                    v-model="selectedMoeda"
                    :items="moedas"
                    item-text="sigla"
                    item-value="id"
                    :label="$tc('global.moeda', 2)"
                    dense
                    class="mr-2"
                    :disabled="blockSelectedMoeda"
                    clearable
                    outlined
                    hide-details
                    return-object
                  ></v-select>
                </div>

                <v-text-field
                  v-model="formaPagamentoValor"
                  :label="$tc('global.valor')"
                  ref="formaPagamentoValor"
                  outlined
                  dense
                  class="input-right"
                  v-currency="vCurrencyOptions"
                  :disabled="disableValor"
                  @blur="validatePagamentoValor"
                ></v-text-field>

                <v-text-field
                  v-model="formaPagamentoCambio"
                  :label="$tc('global.cambio')"
                  ref="formaPagamentoCambio"
                  outlined
                  dense
                  class="input-right ml-2"
                  @blur="atualizarConsolidado"
                  :disabled="disableValor"
                  v-currency="
                    moeda_conta == 'G$' ? vCurrencyOptions2 : vCurrencyOptions
                  "
                ></v-text-field>

                <div>
                  <v-text-field
                    v-model="formaPagamentoConsolidado"
                    :label="$tc('global.consolidado')"
                    ref="formaPagamentoConsolidado"
                    outlined
                    dense
                    class="input-right ml-2"
                    :disabled="disableValor"
                    @blur="atualizarCambio"
                    v-currency="vCurrencyOptions2"
                    :error="formaPagamentoValorInputError"
                    :error-messages="formaPagamentoValorInputErrorMessage"
                  ></v-text-field>
                </div>
              </v-col>
            </template>
          </template>
          <BaixaAntecipo
            v-if="
              formaPagamentoSelected &&
              formaPagamentoSelected.id === 8 &&
              caixaSelected
            "
            :valor_restante.sync="valorRestante"
            :moeda_venda="moeda_conta"
            :antecipo.sync="antecipo"
            :cliente_id="cliente_id"
            :antecipos_adicionados="antecipos_adicionados"
            @push-antecipo="pushAntecipo"
          />
        </v-row>
        <div
          class="d-flex"
          v-if="selectedMoeda && formaPagamentoSelected.id !== 4"
        >
          <v-spacer></v-spacer>
          {{ $tc("global.restante") + " " + selectedMoeda.sigla }}
          <span v-if="selectedMoeda.sigla === 'G$'">
            {{ totalRestanteConvertido | guarani }}
          </span>
          <span v-else>
            {{ totalRestanteConvertido | currency }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="formaPagamentoSelected && formaPagamentoSelected.id !== 8"
          depressed
          small
          color="button_2"
          class="white--text"
          @click="pushPagamento"
          :disabled="disableAddPagamento"
        >
          {{ $t("global.add") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchCaixasOrigem, fetchCaixas } from "@/api/caixas/caixas.js";
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";
import { mapState } from "vuex";
import { fetchCartoesBandeiras } from "@/api/cartoes-bandeiras/cartoes_bandeiras.js";

export default {
  name: "BaixarContasAddFormaPago",

  components: {
    BaixaCheque: () => import("./BaixaCheque.vue"),
    BaixaAntecipo: () =>
      import("@/views/dashboard/caixas-pdv/components/BaixaAntecipo.vue"),
  },

  props: {
    dialogBaixarContasAddFormaPago: {
      type: Boolean,
      default: false,
    },

    valorRestante: {
      type: Number,
    },
    baixaFatura: {
      type: Boolean,
      default: false,
    },
    moeda_conta: {
      type: String,
    },
    tipo_baixa: {
      type: String,
    },
    cliente_id: {
      type: Number,
    },
  },

  data() {
    return {
      loadingFormaPagamentos: false,
      formaPagamentos: [],
      formaPagamentoSelected: null,
      formaPagamentoItem: {
        forma_pagamento: null,
        destino: null,
        destino_id: null,
        moeda: null,
        valor: null,
        cheque: null,
        retencao: null,
        fatura: null,
        consolidado: null,
        midias: null,
      },
      formaPagamentoValor: null,
      formaPagamentoMidias: null,
      loadingTipoCaixas: false,
      caixas: [],
      caixaSelected: null,
      bancos: [],
      bancoSelected: null,
      formaPagamentoValorInputError: false,
      formaPagamentoValorInputErrorMessage: null,
      selectedMoeda: null,
      formaPagamentoCambio: null,
      pagamentoRestante: 0,
      formaPagamentoConsolidado: null,
      cambio_consolidado: 1,
      bandeiras: [],
      selectedBandeira: null,
      codigoAutorizacao: null,
      allowCheque: false,
      chequeAdd: {},
      antecipo: null,
      antecipos_adicionados: [],
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    ...mapState("Cambio", {
      cambio: (state) => state.cambio,
    }),

    disableAddPagamento() {
      let result = false;

      if (
        this.formaPagamentoSelected?.id === 1 ||
        this.formaPagamentoSelected?.id === 2 ||
        this.formaPagamentoSelected?.id === 3 ||
        this.formaPagamentoSelected?.id === 6 ||
        this.formaPagamentoSelected?.id === 7 ||
        !this.formaPagamentoSelected?.id
      ) {
        let forma_pagamentos = [
          "forma_pagamento",
          "destino",
          "destino_id",
          "moeda",
          "valor",
        ];
        forma_pagamentos.forEach((k) => {
          if (
            !this.formaPagamentoItem[k] ||
            this.allowBaixaCartao ||
            !this.selectedMoeda
          ) {
            result = true;
          }
        });
      }
      if (this.formaPagamentoSelected?.id === 4 && !this.allowCheque) {
        result = true;
      }

      if (this.formaPagamentoValorInputError) {
        result = true;
      }

      return result;
    },

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    vCurrencyOptions2() {
      return {
        precision: this?.moeda_conta === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    disableValor() {
      let result = false;

      if (!this.selectedMoeda) {
        result = true;
      }
      return result;
    },

    allowBaixaCartao() {
      let result = false;
      if (
        this.formaPagamentoSelected?.id === 6 ||
        this.formaPagamentoSelected?.id === 7
      ) {
        if (!this.selectedBandeira || !this.codigoAutorizacao) {
          result = true;
        }
      }
      return result;
    },

    totalRestanteConvertido() {
      let cambio = this.$ci.parse(
        this.formaPagamentoCambio,
        this.vCurrencyOptions
      );
      let result = this.cambioResolve(
        this.moeda_conta,
        this.valorRestante,
        this.selectedMoeda.sigla,
        cambio
      );

      return result;
    },
  },

  watch: {
    codigoAutorizacao() {
      if (this.codigoAutorizacao) {
        this.formaPagamentoItem.codigo_autorizacao = this.codigoAutorizacao;
      }
    },

    selectedBandeira() {
      if (this.selectedBandeira) {
        this.formaPagamentoItem.bandeira_id = this.selectedBandeira.id;
      }
    },

    formaPagamentoSelected(newValue, oldValue) {
      if (this.formaPagamentoSelected) {
        this.blockSelectedMoeda = false;
        this.formaPagamentoItem.forma_pagamento =
          this.formaPagamentoSelected.descricao;
        this.formaPagamentoItem.forma_de_pagamento_id =
          this.formaPagamentoSelected.id;
        this.selectedMoeda = null;
        this.cambio_consolidado = 0;
        this.getTipoCaixas("caixa");
        if (
          this.formaPagamentoSelected.id === 6 ||
          this.formaPagamentoSelected.id === 7
        ) {
          this.getBandeiras();
          this.moedas.map((moeda) => {
            if (moeda.sigla === "R$" || moeda.sigla === "U$") {
              moeda.disabled = true;
            } else {
              moeda.disabled = false;
            }
          });
        } else {
          this.formaPagamentoItem.bandeira_id = null;
          this.formaPagamentoItem.codigo_autorizacao = null;
          this.selectedBandeira = null;
          this.codigoAutorizacao = null;
          this.moedas.map((moeda) => {
            moeda.disabled = false;
          });
        }
      } else {
        this.resetPagamento();
      }
    },

    caixaSelected() {
      if (this.caixaSelected) {
        this.formaPagamentoItem.destino = this.caixaSelected.descricao;
        this.formaPagamentoItem.destino_id = this.caixaSelected.id;
        if (this.formaPagamentoSelected.id === 2) {
          this.selectedMoeda = this.moedas.find((moeda) => {
            return moeda.sigla === this.caixaSelected.sigla;
          });
        }
      }
    },

    formaPagamentoValor() {
      if (this.formaPagamentoValor) {
        this.formaPagamentoItem.valor = this.$ci.parse(
          this.formaPagamentoValor,
          this.vCurrencyOptions
        );

        this.atualizarConsolidado();
      }
    },

    async selectedMoeda() {
      if (this.selectedMoeda) {
        this.blockSelectedMoeda = true;
        if (this.formaPagamentoSelected.id !== 8) {
          this.formaPagamentoItem.moeda = this.selectedMoeda.sigla;
          this.$ci.setValue(this.$refs.formaPagamentoValor, 0);
          await this.setCambio();
        }
      }
    },
    formaPagamentoConsolidado() {
      if (this.formaPagamentoConsolidado) {
        this.validatePagamentoValor();
      }
    },
  },

  methods: {
    cambioResolve(moeda_origem, valor_origem, moeda_destino, valor_destino) {
      let result = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        result = valor_origem / valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        result = valor_destino * valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      if (
        (moeda_origem === "U$" && moeda_destino === "U$") ||
        (moeda_origem === "R$" && moeda_destino === "R$") ||
        (moeda_origem === "G$" && moeda_destino === "G$")
      ) {
        result = valor_origem * valor_destino;
      }

      return result ? result + Number.EPSILON : result;
    },

    consolidadoToCambio(
      moeda_origem,
      valor_origem,
      moeda_destino,
      valor_destino
    ) {
      let result = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        result = valor_origem / valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        result = valor_destino / valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        result = valor_destino / valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        result = valor_destino / valor_origem;
      }

      if (
        (moeda_origem === "U$" && moeda_destino === "U$") ||
        (moeda_origem === "R$" && moeda_destino === "R$") ||
        (moeda_origem === "G$" && moeda_destino === "G$")
      ) {
        result = valor_origem / valor_destino;
      }

      return result ? result + Number.EPSILON : result;
    },

    changeConsolidado() {
      if (Number(this.formaPagamentoConsolidado) > 0) {
        this.formaPagamentoItem.consolidado = this.$ci.parse(
          this.formaPagamentoConsolidado,
          this.vCurrencyOptions2
        );

        let cambio = this.consolidadoToCambio(
          this.selectedMoeda.sigla,
          this.formaPagamentoItem.valor,
          this.moeda_conta,
          this.$ci.parse(this.formaPagamentoConsolidado, this.vCurrencyOptions)
        );
        this.$ci.setValue(this.$refs.formaPagamentoCambio, cambio);
      }
    },

    setCambio() {
      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_conta === "G$") ||
        (this.selectedMoeda.sigla === "R$" && this.moeda_conta === "R$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_conta === "U$")
      ) {
        this.cambio_consolidado = 1;
      }

      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_conta === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_conta === "G$")
      ) {
        this.cambio_consolidado = this.cambio.guarani_venda;
      }

      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_conta === "R$") ||
        (this.selectedMoeda.sigla === "R$" && this.moeda_conta === "G$")
      ) {
        this.cambio_consolidado = this.cambio.guarani_real;
      }

      if (
        (this.selectedMoeda.sigla === "R$" && this.moeda_conta === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_conta === "R$")
      ) {
        this.cambio_consolidado = this.cambio.real_venda;
      }
      if (this.formaPagamentoSelected.id !== 8) {
        this.$ci.setValue(
          this.$refs.formaPagamentoCambio,
          this.cambio_consolidado
        );
      }
    },

    atualizarCambio() {
      let consolidado = this.$ci.parse(
        this.formaPagamentoConsolidado,
        this.vCurrencyOptions
      );
      if (consolidado > 0) {
        this.formaPagamentoItem.consolidado = consolidado;
        let changeCambio = this.formaPagamentoItem.valor / consolidado;

        this.$ci.setValue(this.$refs.formaPagamentoCambio, changeCambio);
      }
      this.validatePagamentoValor();
    },

    atualizarConsolidado() {
      let cambio = this.$ci.parse(
        this.formaPagamentoCambio,
        this.vCurrencyOptions
      );

      if (cambio > 0) {
        let changeConsolidado = this.cambioResolve(
          this.selectedMoeda.sigla,
          this.formaPagamentoItem.valor,
          this.moeda_conta,
          cambio
        );

        this.$ci.setValue(
          this.$refs.formaPagamentoConsolidado,
          changeConsolidado
        );

        this.formaPagamentoItem.consolidado = changeConsolidado;

        this.validatePagamentoValor();
      }
    },

    getFormasDePagamento() {
      this.loadingFormaPagamentos = true;
      fetchFormasPagamentos()
        .then((response) => {
          this.formaPagamentos = response.map((item) => {
            if (
              item.id == 1 ||
              (item.id == 2 && this.tipo_baixa == "recebimento") ||
              (item.id == 4 && this.tipo_baixa == "recebimento") ||
              item.id == 6 ||
              item.id == 7 ||
              (item.id == 8 && this.tipo_baixa == "recebimento")
            ) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }

            return item;
          });
        })
        .finally(() => (this.loadingFormaPagamentos = false));
    },

    resetPagamento() {
      var self = this;

      Object.keys(this.formaPagamentoItem).forEach(function (key, index) {
        if (key != "moeda") {
          self.formaPagamentoItem[key] = null;
        }
      });

      this.formaPagamentoSelected = null;
      this.formaPagamentoValor = null;
      this.formaPagamentoCambio = null;
      this.selectedBandeira = null;
      this.codigoAutorizacao = null;
      this.formaPagamentoMidias = null;
      this.caixaSelected = null;
      this.bancoSelected = null;
      this.selectedMoeda = null;
    },

    getTipoCaixas(tipo) {
      this.loadingTipoCadialogBaixarContasAddFormaPagoixas = true;
      if (this.formaPagamentoSelected.id === 2) {
        this.loading = true;
        fetchCaixas("?tipo_caixa=CONTA CORRENTE")
          .then((response) => {
            this.caixas = response;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        fetchCaixasOrigem()
          .then((response) => {
            if (tipo === "caixa") {
              this.caixas = response;

              this.caixaSelected = this.caixas.find((item) => item.id === 1);
            } else {
              this.caixas = response;
            }
            this.loadingTipoCaixas = false;
          })
          .catch(() => {
            this.loadingTipoCaixas = false;
          });
      }
    },

    getBandeiras() {
      this.loading = true;
      this.bandeiras = [];
      fetchCartoesBandeiras(
        `?tipo=${this.formaPagamentoSelected.id === 6 ? "CREDITO" : "DEBITO"}`
      )
        .then((response) => {
          this.bandeiras = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    validatePagamentoValor() {
      if (!this.formaPagamentoItem.valor) return;

      if (
        this.formaPagamentoItem.consolidado > this.valorRestante + 0.01 &&
        this.tipo_baixa == "pagamento"
      ) {
        this.formaPagamentoValorInputError = true;
        this.formaPagamentoValorInputErrorMessage = "Valor maior que restante";
      } else {
        this.formaPagamentoValorInputError = false;
        this.formaPagamentoValorInputErrorMessage = "";
      }
    },

    pushCheque() {
      this.formaPagamentoItem.valor = this.chequeAdd.valor;
      this.formaPagamentoItem.consolidado = this.chequeAdd.consolidado;
      this.formaPagamentoItem.moeda = this.chequeAdd.moeda_sigla;
      this.formaPagamentoItem.cambio = this.chequeAdd.cambio;
      this.formaPagamentoItem.cheque = this.chequeAdd;

      this.chequeAdd = {};
      this.allowCheque = false;
    },

    pushPagamento() {
      if (this.formaPagamentoSelected.id === 4) {
        this.pushCheque();
      } else {
        this.formaPagamentoItem.cambio = this.$ci.parse(
          this.formaPagamentoCambio,
          this.moeda_conta == "G$"
            ? this.vCurrencyOptions2
            : this.vCurrencyOptions
        );
      }
      let forma_pagamento = { ...this.formaPagamentoItem };
      this.$emit("push-pagamento", forma_pagamento);
      this.$emit("update:dialogBaixarContasAddFormaPago", false);
      this.resetPagamento();
    },

    enableSelectMoeda() {
      this.blockSelectedMoeda = false;
      this.selectedMoeda = null;
      if (this.formaPagamentoSelected.id === 2) {
        this.caixaSelected = null;
        this.formaPagamentoItem.destino = null;
        this.formaPagamentoItem.destino_id = null;
      }
    },

    pushAntecipo() {
      if (this.antecipo) {
        this.selectedMoeda = this.moedas.find((moeda) => {
          return moeda.sigla === this.antecipo.moeda;
        });
        this.setCambio();

        let consolidado_antecipo = this.cambioResolve(
          this.selectedMoeda.sigla,
          this.antecipo.saldo,
          this.moeda_conta,
          Number(this.cambio_consolidado)
        );
        if (Number(consolidado_antecipo) > this.valorRestante) {
          let valor_antecipo_utilizaco = this.cambioResolve(
            this.moeda_conta,
            this.valorRestante,
            this.selectedMoeda.sigla,
            Number(this.cambio_consolidado)
          );
          this.formaPagamentoItem.valor = valor_antecipo_utilizaco;
          this.formaPagamentoItem.consolidado = this.valorRestante;
        } else {
          this.formaPagamentoItem.valor = this.antecipo.saldo;
          this.formaPagamentoItem.consolidado = consolidado_antecipo;
        }
        this.formaPagamentoItem.moeda = this.selectedMoeda.sigla;
        this.formaPagamentoItem.cambio = this.cambio_consolidado;
        this.formaPagamentoItem.antecipo_id = this.antecipo.id;
        this.antecipos_adicionados.push(this.antecipo.id);
        let forma_pagamento = { ...this.formaPagamentoItem };
        this.$emit("push-pagamento", forma_pagamento);
        this.resetPagamento();
        this.formaPagamentoSelected = null;
        this.antecipo = null;
      }
    },
  },

  mounted() {
    this.getFormasDePagamento();
  },
};
</script>

<style></style>
