<template>
  <v-card outlined>
    <v-data-table
      :headers="headers"
      :items="vendas_final"
      :sort-desc="true"
      :loading="loading"
      :items-per-page="-1"
      hide-default-footer
      class="data-tables"
    >
      <template v-slot:item.total="{ item }">
        {{ item.moeda_sigla }}
        <span v-if="item.moeda_sigla === 'G$'">
          {{ item.total | guarani }}
        </span>
        <span v-else>
          {{ item.total | currency }}
        </span>
      </template>

      <template v-slot:item.pagare="{ item }">
        <v-btn
          v-if="item.condicao_pagamento === 2"
          icon
          @click.stop="openPagarePdf(item)"
        >
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.fatura="{ item }">
        <v-btn
          v-if="item.fatura_id"
          icon
          class="mr-2"
          @click.stop="
            generateFatura(item.fatura_id, true, false, false, 'fatura')
          "
        >
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="!item.fatura_id"
          icon
          :loading="loading"
          class="mr-2"
          @click.stop="novaFatura(item)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.ticket="{ item }">
        <v-btn
          icon
          class="mr-2"
          @click.stop="generateTicket(item.id, false, false)"
        >
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="PDF"
    />
    <DialogAddDocumento
      v-if="dialogAddDocumento"
      :dialogAddDocumento.sync="dialogAddDocumento"
      :cliente_id="venda_final.cliente_id"
      @open-pagare="openPagarePdf"
    />
  </v-card>
</template>

<script>
const generatePagare = () =>
  import(/* webpackChunkName: "pagaréPdf" */ "../reports/pagarePdf.js");

const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );
const importTicket = () =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_ticket.js");
import { mapState } from "vuex";

import {
  getFinalVendasCaixa,
  infoPagareVendaFinalizada,
} from "@/api/vendas/vendas.js";
import { postFatura, dataImpressaoFatura } from "@/api/faturas/faturas.js";

export default {
  name: "ListVendasFinalizadas",

  props: {
    item: {
      type: Array,
    },
  },

  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogAddDocumento: () => import("./DialogAddDocumento.vue"),
  },

  data() {
    return {
      vendas_final: [],
      venda_final: {},
      loading: false,
      pdfDialog: false,
      pdfData: null,
      dialogAddDocumento: false,
    };
  },

  computed: {
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
    headers() {
      return [
        {
          text: this.$tc("global.cliente", 2),

          width: "230px",
          value: "cliente_nome",
          divider: true,
        },

        {
          text: this.$tc("global.numero"),

          width: "10px",
          align: "center",
          value: "numero",
          divider: true,
        },

        {
          text: this.$tc("global.valor"),

          width: "150px",
          align: "right",
          sortable: false,
          value: "total",
          divider: true,
        },

        {
          text: "Pagaré",

          width: "10px",
          sortable: false,
          value: "pagare",
          align: "center",
          divider: true,
        },

        {
          text: this.$tc("global.fatura"),

          width: "10px",
          sortable: false,
          align: "center",
          value: "fatura",
          divider: true,
        },
        {
          text: "ticket",
          width: "10px",
          sortable: false,
          align: "center",
          value: "ticket",
        },
      ];
    },
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
  },

  methods: {
    async fetchVendas() {
      await getFinalVendasCaixa(this.caixa_af.id)
        .then((response) => {
          this.loading = false;
          this.vendas_final = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async openPagarePdf(item) {
      await infoPagareVendaFinalizada(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.venda_final = response.data.result;
          }
        })
        .catch(() => {});

      if (this.venda_final.cliente_doc_ci || this.venda_final.cliente_doc_ruc) {
        generatePagare().then((module) => {
          let generate = module.generatePagare;
          generate(this.venda_final)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
      } else {
        this.$toast.error("Cliente não possui documento de identificação");
        this.dialogAddDocumento = true;
      }
    },

    async generateTicket(venda_id, download, print) {
      this.loading = true;

      try {
        importTicket()
          .then((module) => {
            let generate = module.default;
            generate(venda_id, download, print).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }

      try {
        importFatura()
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    novaFatura(item) {
      this.loading = true;
      postFatura({
        venda_id: item.id,
        timbrado_id: this.caixa_af.timbrado_id,
      })
        .then((response) => {
          if (response.status === 201) {
            this.fetchVendas();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchVendas();
  },
};
</script>

<style></style>
