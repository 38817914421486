<template>
  <v-dialog
    v-model="dialogPlanoContas"
    @click:outside="$emit('update:dialogPlanoContas', false)"
    @keydown.esc="$emit('update:dialogPlanoContas', false)"
    width="850px"
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <DialogAddPlanoContas
          v-if="dialogAddPlanoContas"
          :dialogAddPlanoContas.sync="dialogAddPlanoContas"
          :edit="edit"
          :item="planocontas"
          @fetch-planocontas="getPlanosContas"
        />
        <v-btn
          v-if="PPlanoContaAdicionar"
          @click="addPlanoContas"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-treeview
          v-if="!loading"
          :items="planoscontas"
          :search="search.length >= 3 ? search : ''"
          :open.sync="open"
          open-on-click
          item-text="descricao"
          item-key="id"
          item-children="children"
          hoverable
          transition
          dense
          color="lightGray"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon>
              {{ open ? "mdi-file-document" : "mdi-file-document-outline" }}
            </v-icon>
          </template>
          <!-- <template v-slot:label="{ item }">
            <div class="text-body-2">{{ item.descricao }}</div>
          </template> -->
          <template v-slot:append="{ item }">
            <v-btn
              v-if="PPlanoContaVisualizar | automatico"
              @click.stop="goToPlanoContas(item)"
              small
              icon
            >
              <v-icon> mdi-pencil-outline </v-icon>
            </v-btn>
          </template>
        </v-treeview>
        <Carregando v-else />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchPlanosContasTree } from "@/api/plano-contas/plano_contas.js";
import { mapGetters } from "vuex";

export default {
  name: "DialogPlanoContas",

  components: {
    DialogAddPlanoContas: () => import("./DialogAddPlanoContas.vue"),
  },

  props: {
    dialogPlanoContas: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      planocontas: {},
      dialogAddPlanoContas: false,
      loading: false,
      planoscontas: null,
      planocontas_original: {},
      search: "",
      open: [1, 2],
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PPlanoContaVisualizar() {
      return this.getAccess("PlanoContas", "visualizar");
    },

    PPlanoContaAdicionar() {
      return this.getAccess("PlanoContas", "adicionar");
    },

    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: "id_pai",
          value: "id_pai",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
      ];
    },
  },

  methods: {
    addPlanoContas() {
      this.dialogAddPlanoContas = true;
      this.edit = false;
    },

    goToPlanoContas(item) {
      if (this.PPlanoContaVisualizar) {
        this.planocontas = { ...item };
        this.dialogAddPlanoContas = true;
        this.edit = true;
      }
    },

    getPlanosContas() {
      this.loading = true;

      fetchPlanosContasTree()
        .then((response) => {
          this.planoscontas = response;
          this.planocontas_original = { ...response };
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.getPlanosContas();
  },
};
</script>

<style></style>
