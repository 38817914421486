import api from "../axios_service";

export async function fetchListaCliente(filtros) {
  const response = await api.get(
    `/relatorios/clientes-lista${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchExtratoCliente(filtros) {
  const response = await api.get(
    `/relatorios/clientes-extrato${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchContasCliente(filtros) {
  const response = await api.get(
    `/relatorios/contas-de-clientes${filtros ? filtros : ""}`
  );
  return response.data.result;
}


