const routes = [
    {
      path: "/configuracoes",
      meta: {
        menu: "configuracoes",
        title: "Configurações",
        permission: { componente: "Configuracoes" },
      },
    },
  ];
  
  export default routes;