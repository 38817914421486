

const routes = [
  {
    path: "/clientes",
    component: () => import("@/views/dashboard/clientes/Clientes.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.cliente", plural: true },
      icon: "mdi-clipboard-account-outline",
      breadcrumb: [
        { text: "global.home", to: { path: "/home" } },
        { text: "global.cliente", plural: true },
      ],
      permission: {
        componente: "Cliente"
      },
    },

  },

  {
    path: "/clientes/adicionar",
    component: () => import("@/views/dashboard/clientes/NovoCliente.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Cliente",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.cliente", plural: true, to: { path: "/clientes" } },
        { text: "global.add" },
      ],
      voltar: { path: "/clientes" },
      permission: {
        componente: "Cliente"
      },
    },
    beforeEnter: (to, from, next) => {
      if (to.meta.permission && to.meta.permission.adicionar) {
        next();
      } else {
        next("/");
      }
    }
  },

  {
    path: "/clientes/:id",
    props: true,
    component: () => import("@/views/dashboard/clientes/Cliente.vue"),
    meta: {
      menu: "dashboard",
      title: "Cliente",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.cliente", plural: true, to: { path: "/clientes" } },
        { text: "dynamic_crumb" },
      ],
      permission: {
        componente: "Cliente"
      },
      voltar: { path: "/clientes" },
    },
  },
];

export default routes;
