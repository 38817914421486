import { putPermissao } from "@/api/permissoes/permissoes.js";

export default {
  methods: {
    updatePermissao(id) {
      this.permissao.bits = this.somaBits;
      this.checkUserPermissions(id, this.permissao.bits);
      let isEqual = this.checkUserPermissions(id, this.permissao.bits);

      if (JSON.stringify(this.permissao) == JSON.stringify(this.original)) {
        this.$toast.error("Por favor, faça uma mudanca antes de atualizar");
      } else {
        if (
          isEqual.visualizar === false &&
          isEqual.adicionar === false &&
          isEqual.editar === false &&
          isEqual.deletar === false &&
          isEqual.imprimir === false &&
          isEqual.importar === false &&
          isEqual.exportar === false
        ) {
          putPermissao(id, this.permissao)
            .then((response) => {
              if (response.status === 201) {
                this.$toast.success("Permissao atualizado com sucesso!");
                this.original = { ...this.permissao };
              }
            })
            .catch((error) => {})
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$toast.error(
            `Remova 
            ${isEqual.visualizar ? "VISUALIZAR, " : ""}
            ${isEqual.adicionar ? "ADICIONAR, " : ""}
            ${isEqual.editar ? "EDITAR, " : ""}
            ${isEqual.deletar ? "DELETAR, " : ""}
            ${isEqual.imprimir ? "IMPRIMIR, " : ""}
            ${isEqual.importar ? "IMPORTAR, " : ""}
            ${isEqual.exportar ? "EXPORTAR, " : ""}
            de todas as permissoes de todos os usuarios antes!`
          );
        }
      }
    },
  },
};
