<template>
  <v-card flat :loading="loading" :disabled="loading">
    <v-list dense class="menu_configuracoes pt-0">
      <v-list-item-group color="secondary">
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-keyboard-return</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Voltar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
        <div v-for="(menu, index) in menus" :key="index">
          <v-list-item
            :to="menu.no_action ? '' : { path: menu.path }"
            v-if="menu.type === 'single' && menu.permission"
            :disabled="menu.disabled"
            @click="menu.click_menu ? mudaMenu(menu.click_menu) : ''"
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="menu.type === 'group' && menu.permission"
            :group="menu.path"
            no-action
            :prepend-icon="menu.icon"
            :disabled="menu.disabled"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </template>
            <div v-for="(child, index) in menu.children" :key="index">
              <v-list-item
                v-if="child.type === 'single' && child.permission"
                :to="{ path: child.path }"
                :disabled="child.disabled"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MenuConfiguracoes",

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    menus() {
      return [
        {
          type: "single",
          title: this.$tc("global.empresa", 1),
          path: "/configuracoes/empresa",
          icon: "mdi-domain",
          disabled: false,
          hide: false,
          permission: this.getAccess("Empresas", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.usuario", 2),
          path: "/configuracoes/usuarios",
          icon: "mdi-account-circle",
          disabled: false,
          permission: this.getAccess("Usuarios", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.cidade", 2),
          path: "/configuracoes/cidades",
          icon: "mdi-map-marker",
          disabled: false,
          permission: this.getAccess("Cidades", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.pdf_layout", 2),
          path: "/configuracoes/pdf-layouts",
          icon: "mdi-format-page-break",
          disabled: false,
          hide: false,
          permission: this.getAccess("PdfLayouts", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.caixa", 2),
          path: "/configuracoes/caixas",
          icon: "mdi-cash-multiple",
          disabled: false,
          hide: false,
          permission: this.getAccess("Caixas", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.bandeira", 2),
          path: "/configuracoes/bandeiras",
          icon: "mdi-cards",
          disabled: false,
          hide: false,
          permission: this.getAccess("Bandeiras", "visualizar"),
        },
      ];
    },
  },

  methods: {
    mudaMenu(menu) {
      this.$store.commit("UPDATE_MENUDINAMICO", menu);
    },
  },

  async mounted() {},
};
</script>

<style lang="scss" scoped>
.menu_configuracoes ::v-deep .v-list-item {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.menu_configuracoes ::v-deep .v-list-item .v-list-item__title {
  font-size: 0.9rem !important;
  font-weight: 500;
  line-height: 1rem;
}
</style>
