<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.cidade', 2)"
      icon="mdi-map-marker-outline"
      dark
    />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          @click="dialogPaises = true"
          color="button_2"
          class="white--text mr-6"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.estado", 2) }}
        </v-btn>

        <v-btn
          @click="addCidade"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="cidades"
          :search="search.length >= 3 ? search : ''"
          :items-per-page="15"
          class="data-tables data-tables__row-click"
          @click:row="goToCidade"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogCidade
      v-if="dialogCidade"
      :dialogCidade.sync="dialogCidade"
      :edit="edit"
      :item="cidade"
      @fetch-cidades="getCidades"
    />
    <DialogPaises v-if="dialogPaises" :dialogPaises.sync="dialogPaises" />
  </v-container>
  <!-- <NaoAutorizado v-else /> -->
</template>

<script>
import { fetchCidades } from "@/api/cidades/cidades.js";

export default {
  name: "Cidades",

  components: {
    DialogPaises: () => import("../cidades/components/DialogPaises.vue"),
    DialogCidade: () => import("./components/DialogCidade.vue"),
  },
  data: () => ({
    cidade: {},
    dialogCidade: false,
    dialogPaises: false,
    loading: false,
    search: "",
    cidades: null,
    cidade_original: {},
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.nome"),
          value: "nome",
        },
        {
          text: this.$tc("global.estado"),
          value: "estado",
        },
        {
          text: this.$tc("global.pais"),
          value: "pais",
        },
      ];
    },
  },
  methods: {
    // openPaises() {
    //   this.dialogPaises = true;
    // }

    addCidade() {
      this.dialogCidade = true;
      this.edit = false;
    },
    goToCidade(item) {
      this.cidade = { ...item };
      this.dialogCidade = true;
      this.edit = true;
    },

    getCidades() {
      this.loading = true;
      this.cidades = null;

      fetchCidades()
        .then((response) => {
          this.cidades = response;
          this.cidade_original = { ...response };
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    this.getCidades();
  },
};
</script>

<style></style>
