const routes = [
  {
    path: "/configuracoes/bandeiras",
    component: () => import("@/views/configuracoes/bandeiras/Bandeiras.vue"),
    meta: {
      menu: "configuracoes",
      title: { text: "global.bandeira", plural: true },
      icon: "mdi-cash-multiple",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.bandeira", plural: true },
      ],
      permission: { componente: "Bandeiras" },
    },
  },
];

export default routes;
