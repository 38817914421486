import api from "../axios_service.js";

// Produtos
export async function fetchDespesas(filtro) {
  const response = await api.get(`/compras-despesas${filtro ? filtro : ""}`);
  return response.data.result;
}
export async function fetchTotalDespesas(id) {
  const response = await api.get(`/compras-despesas-total/${id}`);
  return response.data.result;
}

export async function postDespesa(body) {
  return api.post(`/compras-despesas/add`, body);
}

export async function putDespesa(id, body) {
  return api.put(`/compras-despesas/${id}`, body);
}

export async function deleteDespesa(id) {
  const response = await api.delete(`/compras-despesas/${id}`);
}
