import api from "../axios_service.js";

const fetchFaturasGastos = async (filtros) => {
  const response = await api.get(
    `/faturas-gastos${filtros ? filtros : ""}`
  );
  return response.data.result;
};

const fetchFaturaGasto = async (id, filtros) => {
  const response = await api.get(
    `/faturas-gastos/${id}${filtros ? `?${filtros}` : ""}`
  );
  return response.data.result;
};

export { fetchFaturaGasto , fetchFaturasGastos };
