<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4">
        <BoxAniversariantes />
      </v-col>
      <v-col cols="12" sm="8">
        <BoxVendasReceber v-if="DashboardContasReceberVisualizar" />
      </v-col>
      <v-col cols="12" sm="4">
        <BoxVendasStatus v-if="DashboardVendasVisualizar" />
      </v-col>
      <v-col cols="12" sm="8">
        <BoxContasPagar v-if="DashboardContasPagarVisualizar" />
        <BoxChequesAbertos
          v-if="DashboardChequesVisualizar"
          :class="{ 'mt-5': DashboardContasPagarVisualizar }"
        />
      </v-col>
    </v-row>
    <v-row> </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",

  components: {
    BoxAniversariantes: () => import("./components/BoxAniversariantes.vue"),
    BoxVendasReceber: () => import("./components/BoxVendasReceber.vue"),
    BoxContasPagar: () => import("./components/BoxContasPagar.vue"),
    BoxVendasStatus: () => import("./components/BoxVendasStatus.vue"),
    BoxChequesAbertos: () => import("./components/BoxChequesAbertos.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    DashboardChequesVisualizar() {
      return this.getAccess("DashboardCheques", "visualizar");
    },
    DashboardContasPagarVisualizar() {
      return this.getAccess("DashboardContasPagar", "visualizar");
    },
    DashboardContasReceberVisualizar() {
      return this.getAccess("DashboardContasReceber", "visualizar");
    },
    DashboardVendasVisualizar() {
      return this.getAccess("DashboardVendas", "visualizar");
    },
  },
};
</script>

<style></style>
