<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.produto', 2)" icon="mdi-basket" dark />

    <v-card>
      <v-card-title class="px-md-6 subtitle-1 tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-menu offset-y :close-on-click="closeOnClick">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="tocs_gray_2" class="" dark v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus</v-icon>
              {{ $tc("global.opcao", 2) }}
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <!--- Lista Preços -->
          <v-list class="pa-0" dense>
            <v-divider></v-divider>
            <v-list-item @click="openListaPreco">
              <v-list-item-icon>
                <v-icon left>mdi-clipboard-list-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $tc("global.listadepreco", 2) }}</v-list-item-title
              >
            </v-list-item>
            <v-divider v-if="PListasPrecosVisualizar"></v-divider>

            <!--- Categoria -->
            <v-list-item
              @click="dialogCategoria = true"
              v-if="PCategoriaVisualizar"
            >
              <v-list-item-icon>
                <v-icon left>mdi-format-list-bulleted-type</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $tc("global.categoria", 2) }}
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="PCategoriaVisualizar"></v-divider>

            <!--- Deposito -->
            <v-list-item
              @click="dialogDepositos = true"
              v-if="PDepositosVisualizar"
            >
              <v-list-item-icon>
                <v-icon left>mdi-barn</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $tc("global.deposito", 2) }}
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="PDepositosVisualizar"></v-divider>

            <!--- Marcas -->
            <v-list-item @click="dialogMarcas = true" v-if="PMarcaVisualizar">
              <v-list-item-icon>
                <v-icon left>mdi-caps-lock</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $tc("global.marca", 2) }}
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="PMarcaVisualizar"></v-divider>

            <!--- preços lotes -->
            <v-list-item
              @click="dialogHistoricoPrecosAlteracoes = true"
              v-if="AlteracoesPrecosVisualizar"
            >
              <v-list-item-icon>
                <v-icon left>mdi-priority-high</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $tc("global.mudarPrecosLotes") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <DialogListasPrecos
          v-if="dialogListasPrecos && PListasPrecosVisualizar"
          :dialogListasPrecos.sync="dialogListasPrecos"
        />

        <DialogDepositos
          v-if="dialogDepositos && PDepositosVisualizar"
          :dialogDepositos.sync="dialogDepositos"
        />

        <DialogMarcas v-if="dialogMarcas" :dialogMarcas.sync="dialogMarcas" />
        <DialogProduto
          v-if="dialogProduto"
          :dialogProduto.sync="dialogProduto"
          :produto_id="produto_id"
          @fetch-produtos="getProdutos"
        />

        <DialogCategorias
          v-if="dialogCategoria && PCategoriaVisualizar"
          :dialogCategoria.sync="dialogCategoria"
        />

        <HistoricoPrecosAlteracoes
          v-if="dialogHistoricoPrecosAlteracoes && AlteracoesPrecosVisualizar"
          :dialogHistoricoPrecosAlteracoes.sync="
            dialogHistoricoPrecosAlteracoes
          "
        />

        <v-btn
          v-if="PProdutoAdicionar"
          :to="{ path: '/estoque/produtos/adicionar' }"
          color="button_1"
          class="white--text ml-2 mt-4 mt-sm-0 ml-sm-4"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $tc("global.produto") }}
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="produtos"
          :search="search.length >= 3 ? search : ''"
          :loading="loading"
          :items-per-page="15"
          :single-expand="true"
          show-expand
          class="data-tables"
          :class="{ 'data-tables__row-click': PProdutoVisualizar }"
          @click:row="goToProduto"
        >
          <template
            v-slot:item.data-table-expand="{ item, isExpanded, expand }"
          >
            <v-btn
              icon
              small
              @click.stop="expand(isExpanded ? false : true)"
              v-if="item.produto_kit"
            >
              <v-icon>
                {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="12" class="px-0">
              <v-data-table
                :headers="headersKits"
                :items="item.produtos_kit"
                :loading="loading"
                :items-per-page="-1"
                hide-default-footer
                class="data-tables mb-8 mt-2 mx-6"
                :class="{ 'data-tables__row-click': PProdutoVisualizar }"
              >
              </v-data-table>
            </td>
          </template>

          <template v-slot:item.descricao="{ item }">
            <v-tooltip top v-if="item.img" class="py-1" max-width="200">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.descricao }}
                  <v-btn
                    v-if="item.url"
                    :href="item.url"
                    target="_blank"
                    icon
                    x-small
                    class="ml-1"
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-card max-height="200px" max-width="200px">
                <v-img :src="item.img"> </v-img>
              </v-card>
            </v-tooltip>
            <div v-else>
              {{ item.descricao }}
              <v-btn
                v-if="item.url"
                :href="item.url"
                target="_blank"
                icon
                x-small
                class="ml-1"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.qtde="{ item }">
            <span v-if="item.qtde !== null">
              {{ item.qtde }} {{ item.und ? item.und : "" }}
            </span>
            <span v-else>0 {{ item.und ? item.und : "" }}</span>
            <v-icon
              color="error"
              small
              v-if="saldoAbaixo(item.qtde, item.estoque_min)"
            >
              mdi-arrow-down
            </v-icon>
            <v-icon
              color="buttons"
              small
              v-if="saldoAcima(item.qtde, item.estoque_max)"
            >
              mdi-arrow-up
            </v-icon>
          </template>
          <template v-slot:item.produto_kit="{ item }">
            <span v-if="item.produto_kit"> Kit </span>
            <span v-else>{{ $tc("global.produto") }}</span>
          </template>

          <template v-slot:item.preco="{ item }">
            <div>G$ {{ item.preco | guarani }}</div>
          </template>
          <template v-slot:item.cif="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.cif | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.cif | currency }}</div>
          </template>
          <template v-slot:item.fob="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.fob | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.fob | currency }}</div>
          </template>
          <template v-slot:item.ucif="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.ucif | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.ucif | currency }}</div>
          </template>
          <template v-slot:item.ufob="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.ufob | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.ufob | currency }}</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-btn
                v-if="item.ativo"
                color="tocs_red"
                x-small
                icon
                @click.stop="openCancelarProduto(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-if="item.ativo"
                color="tocs_blue"
                x-small
                icon
                @click.stop="openQuantityDialog(item)"
              >
                <v-icon>mdi-tag-multiple-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogCancelarProduto
      v-if="dialogCancelarProduto"
      :dialogCancelarProduto.sync="dialogCancelarProduto"
      :produto_id="produto_cancelar_id"
      @fetch-produtos="getProdutos"
    />
    <DialogQntdEtiqueta
      @confirmed="printMultiplePriceLabels"
      @cancelled="closeQuantityDialog"
      ref="quantityDialog"
    />
  </v-container>
  <!-- <NaoAutorizado v-else /> -->
</template>

<script>
import { getAllProdutos } from "@/api/produtos/produtos.js";
import { excelDownload } from "@/helpers/excel.js";
import { format, parseISO } from "date-fns";
import { mapGetters } from "vuex";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export default {
  name: "Produtos",

  components: {
    DialogCategorias: () => import("./produto/components/DialogCategorias.vue"),
    DialogDepositos: () => import("./depositos/DialogDepositos.vue"),
    DialogMarcas: () => import("./marcas/DialogMarcas.vue"),
    DialogProduto: () => import("./produto/DialogProduto.vue"),
    DialogListasPrecos: () => import("./listas-precos/DialogListasPrecos.vue"),
    HistoricoPrecosAlteracoes: () =>
      import("./alteracoes-precos/HistoricoPrecosAlteracoes.vue"),
    DialogCancelarProduto: () =>
      import("./components/DialogCancelarProduto.vue"),
    DialogQntdEtiqueta: () => import("./components/DialogQntdEtiqueta.vue"),
  },

  data() {
    return {
      display: false,
      dialogMarcas: false,
      dialogCategoria: false,
      dialogListasPrecos: false,
      dialogDepositos: false,
      dialogHistoricoPrecosAlteracoes: false,
      produtos: [],
      expanded: [],
      search: "",
      apiUrl: null,
      loading: false,
      closeOnClick: true,
      dialogProduto: false,
      dialogCancelarProduto: false,
      produto_cancelar_id: null,
      selectedItem: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PCategoriaVisualizar() {
      return this.getAccess("ProdutosCategoria", "visualizar");
    },
    PMarcaVisualizar() {
      return this.getAccess("ProdutosMarcas", "visualizar");
    },
    PDepositosVisualizar() {
      return this.getAccess("Depositos", "visualizar");
    },
    PListasPrecosVisualizar() {
      return this.getAccess("ListasPrecos", "visualizar");
    },
    AlteracoesPrecosVisualizar() {
      return this.getAccess("AlteracoesPrecos", "visualizar");
    },
    ProdutosColunasVisualizar() {
      return this.getAccess("ProdutosColunas", "visualizar");
    },
    PProdutoAdicionar() {
      return this.getAccess("Produtos", "adicionar");
    },
    PProdutoVisualizar() {
      return this.getAccess("Produtos", "visualizar");
    },
    PProdutoDeletar() {
      return this.getAccess("Produtos", "deletar");
    },

    headers() {
      let headers = [
        {
          text: this.$tc("global.id"),
          width: "80px",
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.categoria"),
          width: "115px",
          value: "categoria",
        },
        {
          text: this.$tc("global.tipo"),
          width: "115px",
          value: "produto_kit",
        },
        {
          text: this.$tc("global.saldo"),
          width: "100px",
          value: "qtde",
        },
      ];
      if (this.ProdutosColunasVisualizar) {
        headers.push(
          {
            text: this.$tc("global.custoMedio") + " CIF",
            width: "120px",
            value: "cif",
          },
          {
            text: this.$tc("global.custoMedio") + " FOB",
            width: "120px",
            value: "fob",
          },
          {
            text: this.$tc("global.ultimo") + " CIF",
            width: "120px",
            value: "ucif",
          },
          {
            text: this.$tc("global.ultimo") + " FOB",
            width: "120px",
            value: "ufob",
          }
        );
      }
      if (this.PProdutoDeletar) {
        headers.push({
          value: "actions",
          sortable: true,
          align: "center",
          width: "10px",
          // divider: true,
        });
      }

      return headers;
    },
    headersKits() {
      let headers = [
        {
          text: this.$tc("global.id"),
          width: "80px",
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.categoria"),
          value: "categoria",
        },
        {
          text: this.$tc("global.saldo"),
          width: "100px",
          value: "qtde",
        },
      ];

      return headers;
    },

    depositosHeader() {
      return [
        {
          text: this.$tc("global.deposito"),
          value: "deposito",
        },
        {
          text: this.$tc("global.saldo"),
          align: "right",
          value: "qtde",
        },
      ];
    },
    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      return `?${queryString}`;
    },

    permissaoOpcoes() {
      let result = false;
      if (
        this.PCategoriaVisualizar ||
        this.PMarcaVisualizar ||
        this.PDepositosVisualizar ||
        this.PListasPrecosVisualizar ||
        this.AlteracoesPrecosVisualizar
      ) {
        result = true;
      }
      return result;
    },
  },

  watch: {
    url() {
      this.getProdutos();
    },
  },

  methods: {
    openCancelarProduto(item) {
      if (item.ativo) {
        this.produto_cancelar_id = item.id;
        this.dialogCancelarProduto = true;
      }
    },
    openListaPreco() {
      this.dialogListasPrecos = true;
    },
    saldoAbaixo(qtde, min) {
      let result = false;
      if (!this.$route.query.cidade && !this.$route.query.deposito && min) {
        if (qtde <= min) {
          return true;
        }
      }
      return result;
    },

    saldoAcima(qtde, max) {
      let result = false;
      if (!this.$route.query.cidade && !this.$route.query.deposito && max) {
        if (qtde >= max) {
          return true;
        }
      }
      return result;
    },

    goToProduto(item) {
      if (this.PProdutoVisualizar) {
        this.produto_id = item.id;
        this.dialogProduto = true;
      }
    },

    getProdutos() {
      this.loading = true;

      getAllProdutos(this.url)
        .then((response) => {
          this.produtos = response;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    gerarExcel() {
      const tHeader = ["ID", "CLASSIFICAÇÃO", "DESCRIÇÃO", "SALDO", "PREÇO"];
      const filterVal = ["id", "classificacao", "descricao", "saldo", "precio"];
      const list = this.produtos;
      list.forEach((item) => {
        item.precio = this.$options.filters.guarani(item.precio);
      });
      const date = new Date();
      const now = format(date, "dd-MM-yyyy");
      const hrs = format(date, "HH");
      const mm = format(date, "mm");
      const ss = format(date, "ss");
      let filtros = "";
      for (let key in this.$route.query) {
        filtros += `-${key}`;
      }
      const filename = `TOCS-Produtos${filtros}_${now}_${hrs}hrs_${mm}m_${ss}s`;

      this.loading = true;
      excelDownload(tHeader, filterVal, list, filename).then(() => {
        this.$toast.success("Excel gerado com sucesso!");

        this.loading = false;
      });
    },

    async gerarExcelReposicao() {
      try {
        let reposicao = [];

        const response = await getAllProdutos();

        response.forEach((item) => {
          if (
            item.estoque_min &&
            Number(item.qtde) <= Number(item.estoque_min)
          ) {
            item.sugestao = Number(item.estoque_max) - Number(item.qtde);

            reposicao.push(item);
          }
        });

        const tHeader = [
          "ID",
          "CLASSIFICAÇÃO",
          "DESCRIÇÃO",

          "SALDO ATUAL",
          "SUGESTAO",
          "FORNECEDOR",
          "LINK",
        ];
        const filterVal = [
          "id",
          "classificacao",
          "descricao",

          "saldo",
          "sugestao",
          "fornecedor",
          "url",
        ];
        const list = reposicao;
        const date = new Date();
        const now = format(date, "dd-MM-yyyy");
        const hrs = format(date, "HH");
        const mm = format(date, "mm");
        const ss = format(date, "ss");
        const filename = `TOCS-Produtos-Reposicao_${now}_${hrs}hrs_${mm}m_${ss}s`;

        this.loading = true;
        excelDownload(tHeader, filterVal, list, filename).then(() => {
          this.$toast.success("Excel gerado com sucesso!");

          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
      }
    },

    openQuantityDialog(item) {
      this.selectedItem = item;
      this.$refs.quantityDialog.open();
    },
    closeQuantityDialog() {
      this.selectedItem = null;
    },

    async printMultiplePriceLabels(quantidade) {
      const produto = this.selectedItem;
      try {
        const browserPrint = new ZebraBrowserPrintWrapper();

        const defaultPrinter = await browserPrint.getDefaultPrinter();
        browserPrint.setPrinter(defaultPrinter);

        const printerStatus = await browserPrint.checkPrinterStatus();

        if (printerStatus.isReadyToPrint) {
          for (let i = 0; i < quantidade; i++) {
            // ZPL script para imprimir etiqueta de preço sem código de barras
            const zpl = `^XA
                          ^FO30,40^GB750,210,1^FS 
                          ^FO70,190
                          ^B3N,N,30,Y,N
                          ^FD>:${produto.id};${produto.descricao};${
              produto.preco
            }^FS
                          ^FO80,70^FH^FD${produto.descricao.zplHexEncode()}^FS
                          ^FO80,120^FDID: ${produto.id}^FS
                          ^FO80,160^FDPreço: ${produto.preco}^FS
                          ^XZ`;

            await browserPrint.print(zpl);
          }

          console.log(
            `Etiquetas de preço (${quantidade}) impressas com sucesso!`
          );
        } else {
          console.log("Erro(s) na impressora:", printerStatus.errors);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
  },

  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    this.getProdutos();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr {
  &.v-data-table__expanded__row {
    background: #eeeeee;
    margin-top: 10px;
    td {
      border-top: 0px solid #dddddd;

      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }

  &.v-data-table__expanded__content {
    margin-bottom: 10px;
    box-shadow: none;
    td {
      border-bottom: none;
    }
  }
}

::v-deep .sub-data-tables {
  border-bottom: 0px solid #dddddd;
  thead {
    background: #eeeeee;
    th {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
  tbody {
    td {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
}
</style>
