<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.venda', 2)" icon="mdi-tag-outline" dark />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 350px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        ></v-text-field>

        <v-select
          v-model="statusFilter"
          :items="status_lista"
          label="Status"
          multiple
          hide-details
          flat
          dense
          solo
          style="max-width: 450px"
          class="ml-0 ml-sm-3 mt-4 mt-sm-0"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center justify-start">
              <v-sheet
                width="10px"
                height="10px"
                :color="item.color"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ item.text }}
            </div>
          </template>

          <template v-slot:selection="{ item, index }">
            <div class="d-flex align-center justify-start">
              <v-sheet
                width="10px"
                height="10px"
                :color="item.color"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              <span class="text-caption">{{ item.text }}</span>
            </div>
            <v-divider
              v-if="index < statusFilter.length - 1"
              class="mx-2 mt-8 mt-sm-0"
              vertical
            ></v-divider>
          </template>
        </v-select>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-dialog v-model="dialogMinhasVendas" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="button_2"
              class="white--text mt-4 mt-sm-0 mr-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-printer</v-icon>
              {{ $tc("global.minhasVendas") }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="tocs_gray_1 pa-4">
              <v-icon left>mdi-printer</v-icon>
              {{ $tc("global.minhasVendas") }}
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pa-4">
              <v-row>
                <v-col cols="12" md="6">
                  <DataField
                    hide_details
                    :data_sync.sync="dataFrom"
                    :label="$tc('global.datainicio')"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <DataField
                    hide_details
                    :data_sync.sync="dataTo"
                    :label="$tc('global.datafim')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="error" text @click="dialogMinhasVendas = false">
                {{ $tc("global.cancelar") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                small
                color="button_2"
                retain-focus-on-click
                :loading="loadingMinhasVendasPdf"
                @click="gerarMinhasVendasPdf()"
                class="white--text"
              >
                <v-icon left>mdi-printer</v-icon>
                {{ $tc("global.gerar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          color="tocs_green"
          retain-focus-on-click
          @click="openVendaPiscina"
          class="mr-2 white--text"
        >
          <v-icon left>mdi-pool</v-icon>
          {{ $tc("global.vendaPiscina") }}
        </v-btn>

        <v-btn
          v-if="PVendaAdicionar"
          @click="addNovaVenda"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>

      <NovaVenda
        v-if="dialogNovaVenda"
        :dialogNovaVenda.sync="dialogNovaVenda"
        @fetch-vendas="fetchVendas"
        :editar="editar"
        :item_venda_id="venda ? venda.id : null"
      />

      <NovaVendaPiscina
        v-if="dialogNovaVendaPiscina"
        :dialogNovaVendaPiscina.sync="dialogNovaVendaPiscina"
        @fetch-vendas="fetchVendas"
        :editar="editar"
        :item_venda_id="venda ? venda.id : null"
      />

      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :search="search"
          :items="vendas"
          :items-per-page="-1"
          :sort-desc="true"
          class="data-tables"
          :class="{ 'data-tables__row-click': PVendaVisualizar }"
          @click:row="goToVenda"
        >
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.valor="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>

          <template v-slot:item.desconto="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.desconto | guarani }}
            </span>
            <span v-else>
              {{ item.desconto | currency }}
            </span>
          </template>

          <template v-slot:item.total="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.total | guarani }}
            </span>
            <span v-else>
              {{ item.total | currency }}
            </span>
          </template>

          <template v-slot:item.status="{ item }">
            <div
              v-if="item.status === -1"
              class="d-flex align-center justify-start"
            >
              <v-sheet
                width="10px"
                height="10px"
                color="red lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.cancelada") }}
            </div>

            <div
              v-if="item.status === 0"
              class="d-flex align-center justify-start"
            >
              <v-sheet
                width="10px"
                height="10px"
                color="grey lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.emAndamento") }}
            </div>

            <div
              v-if="item.status === 1"
              class="d-flex align-center justify-start"
            >
              <v-sheet
                width="10px"
                height="10px"
                color="yellow lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.pendentes") }}
              <v-tooltip v-if="item.motivo_devolucao" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="18" right>
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ item.motivo_devolucao }}</span>
              </v-tooltip>
            </div>

            <div
              v-if="item.status === 2"
              class="d-flex align-center justify-start"
            >
              <v-sheet
                width="10px"
                height="10px"
                color="lime darken-2"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.enviadoCaixa") }}
            </div>
            <div
              v-if="item.status === 3"
              class="d-flex align-center justify-start"
            >
              <v-sheet
                width="10px"
                height="10px"
                color="light-blue darken-3"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.baixando") }}
            </div>

            <div
              v-if="item.status === 5"
              class="d-flex align-center justify-start"
            >
              <v-sheet
                width="10px"
                height="10px"
                color="green lighten-1"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              {{ $tc("global.finalizado") }}
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-btn v-if="PVendaVisualizar" icon @click.stop="gerarpdf(item)">
                <v-icon>mdi-file-export</v-icon>
              </v-btn>

              <v-btn
                v-if="PVendaDeletar && item.status == 1"
                color="tocs_red"
                icon
                @click.stop="openCancelarVenda(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-btn
                v-if="item.possui_contrato"
                icon
                @click.stop="gerarContratoPdf(item)"
              >
                <v-icon>mdi-file-sign</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <Carregando v-else />
        <DialogCancelarVenda
          v-if="dialogCancelarVenda"
          :dialogCancelarVenda.sync="dialogCancelarVenda"
          :venda_id="venda_cancelar_id"
          @fetch-vendas="fetchVendas"
        />
        <DialogTiposVendas
          v-if="dialogTiposVendas"
          :dialogTiposVendas.sync="dialogTiposVendas"
          @venda-comum="addNovaVenda"
          @venda-piscina="openVendaPiscina"
        />
      </v-card-text>

      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        :title="
          pdfMinhasVendas
            ? `VENDAS DO MÊS`
            : pdfContrato
            ? 'CONTRATO Nº.:'
            : `VENDA Nº.: ${this.venda.numero}`
        "
      />
    </v-card>
  </v-container>
</template>

<script>
import { getVendas, vendaPdf } from "@/api/vendas/vendas.js";
import { mapState, mapGetters } from "vuex";
import { format, startOfMonth } from "date-fns";
import { fetchUserHasDepositos } from "@/api/usuarios/usuarios.js";

const pdfGenerator = () =>
  import(/* webpackChunkName: "venda-pdf" */ "./reports/venda.js");
const pdfMinhasVendasGenerator = () =>
  import(/* webpackChunkName: "venda-pdf" */ "./reports/minhas_vendas.js");
const pdfContratoGenerator = () =>
  import(
    /* webpackChunkName: "venda-pdf" */ "../contratos/reports/contrato_pdf.js"
  );

export default {
  name: "Vendas",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    NovaVenda: () => import("./components/NovaVenda.vue"),
    NovaVendaPiscina: () => import("./components/NovaVendaPiscina.vue"),
    DialogCancelarVenda: () => import("./components/DialogCancelarVenda.vue"),
    DialogTiposVendas: () => import("./components/DialogTiposVendas.vue"),
  },

  data() {
    return {
      loading: false,
      loadingPdf: false,
      vendas: [],
      venda: null,
      search: "",
      display: false,
      dialogNovaVenda: false,
      dialogNovaVendaPiscina: false,
      statusFilter: [1, 2],
      pdfDialog: false,
      pdfData: "",
      userHasDeposito: false,
      dialogCancelarVenda: false,
      dialogMinhasVendas: false,
      dataFrom: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      dataTo: format(new Date(), "yyyy-MM-dd"),
      loadingMinhasVendasPdf: false,
      pdfMinhasVendas: false,
      venda_cancelar_id: null,
      dialogTiposVendas: false,
      pdfContrato: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PVendaVisualizar() {
      return this.getAccess("Vendas", "visualizar");
    },
    PVendaAdicionar() {
      return this.getAccess("Vendas", "adicionar");
    },
    PVendaDeletar() {
      return this.getAccess("Vendas", "deletar");
    },

    headers() {
      return [
        {
          text: this.$tc("global.data"),
          width: "120px",
          value: "data",
        },
        {
          text: this.$tc("global.numero"),
          width: "120px",
          value: "numero",
        },
        {
          text: this.$tc("global.tipo") + " " + this.$tc("global.venda"),
          width: "120px",
          value: "tipo_venda",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.valor"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "total",
        },
        {
          text: "Status",
          width: "160px",
          divider: true,
          value: "status",
        },
        {
          text: "",
          width: "100px",
          align: "center",
          value: "actions",
        },
      ];
    },

    status_lista() {
      return [
        {
          text: this.$tc("global.cancelada"),
          value: -1,
          color: "red lighten-1",
        },
        {
          text: this.$tc("global.emAndamento"),
          value: 0,
          color: "grey lighten-1",
        },
        {
          text: this.$tc("global.pendentes"),
          value: 1,
          color: "yellow lighten-1",
        },
        {
          text: this.$tc("global.enviadoCaixa"),
          value: 2,
          color: "lime darken-2",
        },
        {
          text: this.$tc("global.baixando"),
          value: 3,
          color: "light-blue darken-3",
        },
        // {
        //   text: this.$tc("global.devolucao"),
        //   value: 4,
        //   color: "deep-purple darken-2",
        // },
        {
          text: this.$tc("global.finalizado"),
          value: 5,
          color: "green lighten-1",
        },
      ];
    },

    ...mapState("Moedas", {
      moeda: (state) => state.moedas[0],
    }),

    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
      usuario: (state) => state.usuario,
    }),

    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }

      if (this.statusFilter.length) {
        queryString += `&status_filter=${this.statusFilter.join()}`;
      }
      return `?${queryString}`;
    },
  },

  watch: {
    url: {
      handler() {
        this.fetchVendas();
      },
      immediate: true,
    },
  },

  methods: {
    openCancelarVenda(item) {
      if (item.status == 1) {
        this.venda_cancelar_id = item.id;
        this.dialogCancelarVenda = true;
      }
    },

    getUserHasDeposito() {
      this.loading = true;
      return fetchUserHasDepositos(this.usuario.id)
        .then((reponse) => {
          this.userHasDeposito = reponse;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async openVendaPiscina() {
      await this.getUserHasDeposito();
      if (this.userHasDeposito) {
        this.venda = null;
        this.dialogNovaVendaPiscina = true;
        this.editar = false;
      } else {
        this.$toast.error(this.$tc("global.usuarioSemDeposito"));
      }
    },

    async addNovaVenda() {
      await this.getUserHasDeposito();
      if (this.userHasDeposito) {
        this.venda = null;
        this.dialogNovaVenda = true;
        this.editar = false;
      } else {
        this.$toast.error(this.$tc("global.usuarioSemDeposito"));
      }
    },

    goToVenda(item) {
      if (this.PVendaVisualizar) {
        this.venda = { ...item };
        if (this.venda.tipo_venda == "SIMPLES") {
          this.dialogNovaVenda = true;
        }
        if (this.venda.tipo_venda == "PISCINA") {
          this.dialogNovaVendaPiscina = true;
        }
        this.editar = true;
        // console.log(this.venda);
      }
    },

    fetchVendas() {
      this.loading = true;
      getVendas(this.url)
        .then((response) => {
          this.vendas = response;
          this.loading = false;
        })
        .catch(() => {});
    },

    async gerarpdf(item) {
      try {
        this.pdfMinhasVendas = false;
        this.pdfContrato = false;
        this.loadingPdf = true;

        this.venda = await vendaPdf(item.id);

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.empresa, this.venda).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loadingPdf = false;
          });
      } catch (error) {
        this.loadingPdf = false;
      }
    },

    async gerarContratoPdf(item) {
      try {
        this.pdfMinhasVendas = false;
        this.pdfContrato = true;
        this.loadingPdf = true;

        await pdfContratoGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(item.id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loadingPdf = false;
          });
      } catch (error) {
        this.loadingPdf = false;
      }
    },

    async gerarMinhasVendasPdf() {
      try {
        this.loadingMinhasVendasPdf = true;
        this.pdfMinhasVendas = true;

        await pdfMinhasVendasGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.dataFrom, this.dataTo, this.usuario.id).then(
              (response) => {
                if (response.error) {
                  this.$toast.error(response.message);
                } else {
                  this.pdfData = response.pdfData;
                  this.pdfDialog = true;
                }
              }
            );
          })
          .finally(() => {
            this.loadingMinhasVendasPdf = false;
          });
      } catch (error) {
        this.loadingMinhasVendasPdf = false;
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped></style>
