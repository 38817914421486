import ComponentsApi from "@/api/permissoes/validate_components.js";
import router from "../../router/index.js";
import Vue from "vue";

const initialState = () => ({
  componentes: null,
});

const state = initialState();

// getters
const getters = {
  // vueFatura: (state, getters) => {
  //   return getters.getAccess("vueFatura", "visualizar");
  // },

  getAccess: (state, getters, rootState) => (componente, type) => {

    let result = false;
    let check = 0;
    const admin = rootState.Usuario.usuario.is_admin;

    if (admin) {
      result = true;
      return result;
    }

    let comp = state.componentes.find(
      (comp) => comp.componente === componente
    );

    if (comp && comp.status) {
      result = comp[type];
    }

    return result;
  },
};

// actions
const actions = {
  async getComponentes(context) {
    const id = context.rootState.Usuario.usuario.id;

    const routes = router.getRoutes();

    if (id && (!context.state.componentes || !context.state.componentes.length)) {
      try {
        const response = await ComponentsApi.getComponentes();
        context.commit("UPDATE_COMPONENTES", response);

        response.forEach(comp => {
          if (comp.status) {
            routes.forEach(route => {
              if (route.meta && route.meta.permission) {
                if (route.meta.permission.componente === comp.componente) {
                  route.meta.permission = { ...comp };
                }
              }
            });
          }
        });

      } catch (error) {
        context.dispatch("Usuario/deslogarUsuario", null, { root: true }).then(() => {
          if (router.currentRoute.path != '/login') {
            router.push("/login");
          }
          Vue.$toast.error("Não foi possível carregar os componentes, entre em contato com o suporte!");
        });
        throw error;
      }
    }
  },

  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  UPDATE_COMPONENTES(state, payload) {
    state.componentes = payload;
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
