<template>
  <v-container fluid>
    <div class="d-flex mb-6">
      {{ produto.id }} - {{ produto.descricao }} - Ref.
      {{ produto.referencia }}
      <v-spacer></v-spacer>
      <v-btn @click="updateProduto" color="button_1" class="white--text">
        <v-icon left>mdi-check</v-icon>
        {{ $tc("global.salvar") }}
      </v-btn>
    </div>
    <v-row>
      <!-- PRODUTO -->
      <v-col cols="12">
        <span class="text-h6 font-weight-bold"> Produto </span>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="12">
        <v-row dense>
          <!-- PESO -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.peso_produto"
              dense
              outlined
              label="Peso KG"
            ></v-text-field>
          </v-col>
          <!-- Volume M³ -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.volume_produto"
              label="Volume M³"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Dimensao -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.dimensoes_produto"
              label="Dimensão Cm"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- vencimento -->
          <v-col cols="12" md="6">
            <DataField
              :data_sync.sync="produto.vencimentos"
              label="Vencimento"
            />
          </v-col>
          <!-- Codigo NCM -->
          <v-col cols="12" md="2">
            <v-text-field
              v-model="produto.cdg_ncm"
              dense
              outlined
              label="Codigo NCM "
            ></v-text-field>
          </v-col>

          <!-- Codigo de barras -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="produto.cdg_barras"
              dense
              outlined
              label="Codigo de Barras "
            ></v-text-field>
          </v-col>

          <!-- Tipo -->
          <v-col cols="12" md="4">
            <v-select
              v-model="produto.tipo_cdg_barras"
              :items="tipos"
              clearable
              label="Tipo"
              outlined
              dense
            ></v-select>
          </v-col>

          <!-- Codigo de Fabrica -->
          <v-col cols="12" md="12">
            <v-text-field
              v-model="produto.codigo_fabrica"
              :label="this.$tc('global.codigoFabrica')"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <!-- Descrição detalhada -->
          <v-col cols="12">
            <v-textarea
              v-model="produto.descricao_detalhada"
              label="Descriçao"
              :rows="3"
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- CAIXAS --->

    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined class="pa-3">
          <v-row>
            <v-col cols="12">
              <v-subheader class="text-h6 font-weight-bold">
                Caixas
              </v-subheader>
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" md="12">
              <v-row dense>
                <!-- Quantidade de produto por caixa -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.qtde_caixa"
                    dense
                    outlined
                    label="Produto por caixa"
                  ></v-text-field>
                </v-col>
                <!-- PESO -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.peso_caixa"
                    clearable
                    dense
                    outlined
                    label="Peso KG"
                  ></v-text-field>
                </v-col>
                <!-- Volume M³ -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.volume_caixa"
                    label="Volume M³"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Dimensao -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.dimensoes_caixa"
                    label="Dimensão Cm"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- LOTE -->
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="produto.lote"
                    label="Lote"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- PALLET --->
      <v-col cols="12" md="6">
        <v-card outlined class="pa-3">
          <v-row>
            <v-col cols="12">
              <v-subheader class="text-h6 font-weight-bold">
                Pallets
              </v-subheader>
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12" md="12">
              <v-row dense>
                <!-- Quantidade de caixa no Pallet -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.qtde_caixa_pallet"
                    label="Caixas por Pallet"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- PESO -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.peso_pallet"
                    clearable
                    dense
                    outlined
                    label="Peso KG"
                  ></v-text-field>
                </v-col>
                <!-- Volume M³ -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.volume_pallet"
                    label="Volume M³"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Dimensao -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.dimensoes_pallet"
                    label="Dimensão Cm"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { putProdutos } from "@/api/produtos/produtos.js";
export default {
  name: "ProdutoAvancado",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    getProduto: {
      type: Function,
    },
  },

  data() {
    return {
      produto: {},
      tipos: [
        {
          text: "EAN-13",
          value: "EAN-13",
        },
        {
          text: "EAN-8",
          value: "EAN-8",
        },
        {
          text: "EAN-128",
          value: "EAN-128",
        },
        {
          text: "DUN-14 / ITF-14",
          value: "DUN-14",
        },
        {
          text: "ITF",
          value: "ITF",
        },
        {
          text: "UPC",
          value: "UPC-A",
        },
        {
          text: "CODE 11",
          value: "CODE 11",
        },
        {
          text: "CODE 39",
          value: "CODE 39",
        },
        {
          text: "CODE 93",
          value: "CODE 93",
        },
        {
          text: "CODE 128",
          value: "CODE 128",
        },
        {
          text: "CODABAR",
          value: "CODABAR",
        },
      ],
    };
  },

  watch: {},

  methods: {
    updateProduto() {
      let produto_2 = {};
      produto_2.peso_produto = this.produto.peso_produto;
      produto_2.peso_caixa = this.produto.peso_caixa;
      produto_2.peso_pallet = this.produto.peso_pallet;
      produto_2.volume_produto = this.produto.volume_produto;
      produto_2.volume_caixa = this.produto.volume_caixa;
      produto_2.volume_pallet = this.produto.volume_pallet;
      produto_2.qtde_caixa = this.produto.qtde_caixa;
      produto_2.qtde_caixa_pallet = this.produto.qtde_caixa_pallet;
      produto_2.dimensoes_pallet = this.produto.dimensoes_pallet;
      produto_2.dimensoes_produto = this.produto.dimensoes_produto;
      produto_2.dimensoes_caixa = this.produto.dimensoes_caixa;
      produto_2.cdg_barras = this.produto.cdg_barras;
      produto_2.tipo_cdg_barras = this.produto.tipo_cdg_barras;
      produto_2.cdg_ncm = this.produto.cdg_ncm;
      produto_2.lote = this.produto.lote;
      produto_2.vencimentos = this.produto.vencimentos;
      produto_2.descricao_detalhada = this.produto.descricao_detalhada;
      produto_2.codigo_fabrica = this.produto.codigo_fabrica;

      putProdutos(this.produto.id, produto_2)
        .then(() => {
          this.getProduto();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  async mounted() {
    this.loading = true;
    this.produto = { ...this.item };
  },
};
</script>

<style lang="scss" scoped>
.input-center ::v-deep input {
  text-align: center;
}

.input-right ::v-deep input {
  text-align: right;
}
</style>
