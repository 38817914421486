<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
          {{ $tc("global.caixa", 2) }}
          <v-spacer></v-spacer>

          <v-btn
            @click="dialogUserCaixa = !dialogUserCaixa"
            depressed
            fab
            x-small
            class="white--text"
            color="button_1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider v-if="userCaixas && userCaixas.length"></v-divider>
        <v-card-text v-if="userCaixas && userCaixas.length" class="py-4">
          <v-chip
            v-for="(caixa, index) in userCaixas"
            :key="index"
            class="ma-1"
            label
            close
            close-icon="mdi-delete"
            @click:close="delUserCaixa(caixa.id)"
          >
            <v-icon left>mdi-store</v-icon>
            {{ caixa.caixa }}
          </v-chip>
        </v-card-text>
        <p v-else class="d-flex justify-center pt-6">
          {{ $tc("global.nenhumcaixacadastrado") }}
        </p>
        <v-dialog v-model="dialogUserCaixa" width="100%" max-width="400">
          <v-card :loading="loadingUserCaixa" :disabled="loadingUserCaixa">
            <v-card-title class="subtitle-1 lightgray">
              {{ $tc("global.add") }} {{ $tc("global.caixa") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogUserCaixa = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-10">
              <v-select
                v-model="userCaixa.caixa_id"
                :items="caixasNotEqual"
                clearable
                item-text="descricao"
                item-value="id"
                filled
                dense
                hide-details
                :label="$tc('global.caixa', 2)"
              ></v-select>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="userCaixa.caixa_id ? false : true"
                color="button_1"
                class="white--text"
                depressed
                small
                @click="criarUserCaixa"
              >
                {{ $tc("global.add") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import {
  fetchUsersCaixas,
  postUsersCaixas,
  deleteUserCaixa,
} from "@/api/usuarios/usuarios.js";
import { fetchCaixaNotEquall } from "@/api/caixas/caixas.js";

export default {
  name: "CaixaUsuario",

  props: {
    user_id: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      loadingUserCaixa: false,
      dialogUserCaixa: false,
      caixasNotEqual: [],
      userCaixas: [],
      userCaixa: {
        caixa_id: null,
      },
    };
  },

  methods: {
    getTipoCaixasNotEqual() {
      fetchCaixaNotEquall(this.user_id)
        .then((response) => {
          this.caixasNotEqual = response;
        })
        .catch((error) => {});
    },
    getUsersCaixas() {
      fetchUsersCaixas(`?user_id=${this.user_id}`)
        .then((response) => {
          this.userCaixas = response;
        })
        .catch((error) => {});
    },
    criarUserCaixa() {
      this.userCaixa.user_id = this.user_id;
      this.loadingUserCaixa = true;

      postUsersCaixas(this.userCaixa)
        .then(() => {
          this.userCaixa = {};
          this.getUsersCaixas();
          this.getTipoCaixasNotEqual();
          this.dialogUserCaixa = false;
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingUserCaixa = false;
        });
    },
    delUserCaixa(id) {
      deleteUserCaixa(id)
        .then(() => {
          this.getUsersCaixas();
          this.getTipoCaixasNotEqual();
        })
        .catch((error) => {});
    },
  },

  mounted() {
    this.getTipoCaixasNotEqual();
    this.getUsersCaixas();
  },
};
</script>

<style></style>
