import Vue from "vue";
import Vuetify from "vuetify";
import pt from "vuetify/es5/locale/pt";
import en from "vuetify/es5/locale/en";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

const locale = window.localStorage.globalsat__locale
  ? JSON.parse(window.localStorage.globalsat__locale)
  : null;
let currentLocale = "";
if (locale) {
  currentLocale = locale.value;
} else if (navigator.language) {
  currentLocale = navigator.language.substring(0, 2);
}

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#243884",
        secondary: "#3472B8",
        lightgreen: "#14525E",
        lightblue: "#50B3E7",
        lightgray: "#eeeeee",
        darkblue: "#1F3D73",
        toolbar: "#3473B8",
        whitetocs: "#01579B",
        tabs: "#f5f5f5",
        tabs2: "#CFD8DC",
        tabs3: "#CFD8DC",
        anchors: "#e9950d",
        button_1: "#1da328",
        button_2: "#ff7200",
        button_3: "#004D40",
        accent: "#1fb48a",
        error: "#FF5252",
        info: "#FFC107",
        success: "#4CAF50",
        usermessage: "#DCF8C6",
        warning: "#FFC107",
        tocs_gray_1: "#EBEEF9",
        tocs_gray_2: "#424242",
        tocs_gray_3: "#888888",
        tocs_blue_1: "#232D58",
        tocs_blue_2: "#223981",
        tocs_blue_3: "#3473B8",
        tocs_blue_4: "#50B2E6",
        tocs_blue_5: "#4D5983",
        tocs_orange: "#e9950d",
        tocs_red: "#BD1E33",
        tocs_blue: "#3473B8",
        tocs_green: "#009688",
        label: "#838383",
      },
      dark: {
        primary: "#314170",
        secondary: "#01579B",
        lightgreen: "#14525E",
        lightblue: "#50B3E7",
        lightgray: "#111",
        darkblue: "#1F3D73",
        toolbar: "#111",
        whitetocs: "#FFFFFF",
        tabs: "#f5f5f5",
        tabs2: "#CFD8DC",
        tabs3: "#CFD8DC",
        anchors: "#e9950d",
        accent: "#1fb48a",
        error: "#FF5252",
        info: "#FFC107",
        success: "#4CAF50",
        usermessage: "#344f1f",
        warning: "#FFC107",
        button_1: "#1da328",
        button_2: "#6432fd",
        button_3: "#26A69A",
        tocs_gray_1: "#111",
        tocs_gray_2: "#424242",
        tocs_gray_3: "#888888",
        tocs_blue_1: "#232D58",
        tocs_blue_2: "#314170",
        tocs_blue_3: "#335880",
        tocs_blue_4: "#50B2E6",
        tocs_blue_5: "#4D5983",
        tocs_orange: "#e9950d",
        tocs_red: "#F44336",
        tocs_blue: "#3473B8",
        tocs_green: "#009688",
        label: "#b6b6b6",
      },
    },
  },
  lang: {
    locales: { pt, en, es },
    current: currentLocale,
  },
  icons: {
    iconfont: "mdi",
  },
});
