<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="headline">Quantidade de Etiquetas</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="quantity"
          type="number"
          label="Quantidade"
          required
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="confirm">Imprimir</v-btn>
        <v-btn color="error" @click="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
export default {
  name: "DialogQntdEtiqueta",
  data() {
    return {
      dialog: false,
      quantity: 1,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirmed", this.quantity);
      this.dialog = false;
    },
    cancel() {
      this.$emit("cancelled");
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
  },
};
</script>
  