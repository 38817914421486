import api from "../axios_service.js";

export async function fetchCaixasAf(filtros) {
  const response = await api.get(`/caixas-af${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchCaixasAfItems(filtros) {
  const response = await api.get(`/caixas-af-items${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchFechamentoCaixaValores(id) {
  const response = await api.get(`/fechamento-caixas-valores/${id}`);
  return response.data.result;
}

export async function fetchCaixaAf(id) {
  const response = await api.get(`/caixas-af/${id}`);
  return response.data.result;
}

export async function fetchCaixaAfUser(id) {
  const response = await api.get(`/caixas-af-user/${id}`);
  return response.data;
}

export async function usuarioCaixaAberto() {
  const response = await api.get(`/caixas-af-usario-caixa-aberto`);
  return response.data.result;
}

export function postCaixaAfItem(caixa_af_item) {
  return api.post("/caixas-af-item/add", caixa_af_item);
}

export function postAbrirCaixa(abrir_caixa) {
  return api.post("/abrir-caixa", abrir_caixa);
}

export function fechamentoCaixaAf(id, fechar_caixa) {
  return api.post(`/fechamento-caixa-af/${id}`, fechar_caixa);
}

export async function getFechamentoCaixaAf(id) {
  const response = await api.get(`/pdf-fechamento-caixa/${id}`);
  return response.data;
}

export async function fetchCaixaAfTotais(id) {
  const response = await api.get(`/caixas-af-totais/${id}`);
  return response.data.result;
}

export function postBaixaContasCaixaAf(body) {
  return api.post("/baixar-contas-caixa-af", body);
}

export function postBaixaContasFornecedoresCaixaAf(body) {
  return api.post("/baixar-contas-pagamento-fornecedores-caixa-af", body);
}

export async function fetchCaixasAfVisualizacaoMovimentacao(filtros) {
  const response = await api.get(`/caixas-af-visualizacao-movimentacao${filtros ? filtros : ""}`);
  return response.data.result;
}
