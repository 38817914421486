const routes = [
  {
    path: "/financeiro/compensacao-bancos",
    component: () =>
      import("@/views/dashboard/compensacao-bancos/CompensacaoBancos.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.compensacaoBanco" },
      icon: "mdi-bank-check",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.compensacaoBanco" },
      ],
      permission: { componente: "CompensacaoBanco" },
    },
  },
];

export default routes;
