import api from "../axios_service.js";

export async function fetchMoedas(filtros) {
  const response = await api.get(`/moedas${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchMoedasConfig(filtros) {
  const response = await api.get(`/moedas-config${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchMoedasNotEqual() {
  const response = await api.get("/moedas-not-equal");
  return response.data.result;
}

export async function postMoeda(body) {
  const response = await api.post("/moedas/add", body);
}

export async function deleteMoeda(id) {
  const response = await api.delete(`/moedas/${id}`);
}

export async function fetchMoedasAdmin(filtros) {
  const response = await api.get(`/moedas-admin${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function putMoeda(id, moeda) {
  let body = {};
  for (let key in moeda) {
    body[key] = moeda[key];
  }
  return api.put(`/moedas/${id}`, body);
}

export async function postMoedaAdmin(moeda) {
  let body = {};
  for (let key in moeda) {
    body[key] = moeda[key];
  }
  return api.post("/moedas-admin/add", body);
}
