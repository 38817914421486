<template>
  <v-dialog
    v-model="dialogEnderecos"
    @click:outside="$emit('update:dialogEnderecos', false)"
    @keydown.esc="$emit('update:dialogEnderecos', false)"
    scrollable
    width="750"
  >
    <v-card>
      <v-card-title class="text-subtitle-1 lightgray">
        {{ edit ? "Endereço" : "Novo Endereço" }}
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:dialogEnderecos', false)" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4">
        <v-form @submit.prevent lazy-validation>
          <v-row>
            <!-- Rua -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="endereco.rua"
                label="Rua"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <!-- Numero -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="endereco.numero"
                label="Número"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <!-- Cidades -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="endereco.cidade_nome"
                label="Cidade"
                dense
                outlined
                hide-details
              >
              </v-text-field>
            </v-col>
            <!-- Estado -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="endereco.estado"
                label="Estado"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <!-- pais -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="endereco.pais"
                label="País"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <!-- Bairro -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="endereco.bairro"
                label="Bairro"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <!-- Status -->
            <v-col cols="12" md="6">
              <v-card
                v-if="edit"
                outlined
                flat
                class="d-flex pa-2 align-center justify-space-between"
              >
                Status:
                <v-switch
                  class="ma-0 pa-0"
                  hide-details
                  :false-value="0"
                  :true-value="1"
                  v-model="endereco.status"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text"
          color="button_1"
          @click="edit ? updateEndereco() : novoEndereco()"
          depressed
          small
        >
          Salvar
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  fetchEnderecosClientes,
  putEnderecosClientes,
  postEnderecosClientes,
  deleteEnderecos,
} from "@/api/clientes/clientes_enderecos.js";

export default {
  name: "DialogEnderecos",

  props: {
    dialogEnderecos: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    cliente_id: {
      type: [Number, String],
    },
  },

  data() {
    return {
      loading: false,
      endereco: {
        status: 1,
        numero: null,
      },
      endereco_original: {},
    };
  },

  methods: {
    novoEndereco() {
      this.loading = true;

      let endereco_2 = {};

      endereco_2.rua = this.endereco.rua;
      endereco_2.numero = this.endereco.numero;
      endereco_2.cidade_nome = this.endereco.cidade_nome;
      endereco_2.estado = this.endereco.estado;
      endereco_2.bairro = this.endereco.bairro;
      endereco_2.pais = this.endereco.pais;
      endereco_2.cliente_id = Number(this.cliente_id);

      postEnderecosClientes(endereco_2)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("global.addsucesso"));
            this.$emit("update:dialogEnderecos", false);
            this.$emit("fetch-enderecos");
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    updateEndereco() {
      this.loading = true;

      let endereco_2 = {};

      endereco_2.rua = this.endereco.rua;
      endereco_2.numero = this.endereco.numero;
      endereco_2.cidade_nome = this.endereco.cidade_nome;
      endereco_2.estado = this.endereco.estado;
      endereco_2.bairro = this.endereco.bairro;
      endereco_2.pais = this.endereco.pais;
      endereco_2.status = this.endereco.status;
      endereco_2.cliente_id = Number(this.cliente_id);

      putEnderecosClientes(this.endereco.id, endereco_2)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success(this.$tc("global.atualizadosucesso"));
            this.$emit("update:dialogEnderecos", false);
            this.$emit("fetch-enderecos");
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  async mounted() {
    if (this.edit) {
      this.endereco = { ...this.item };
      this.endereco_original = { ...this.item };
    }
  },
};
</script>

<style></style>
