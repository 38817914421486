const routes = [
  {
    path: "/orcamentos",
    component: () => import("@/views/dashboard/orcamentos/Orcamentos.vue"),
    meta: {
      menu: "dashboard",

      title: { text: "global.orcamento", plural: true },
      icon: "mdi-file-percent-outline",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.orcamento", plural: true },
      ],

      permission: { componente: "Orcamentos", },

    },
  },
];

export default routes;
