const routes = [
  {
    path: "/financeiro/contas-pagar",
    component: () => import("@/views/dashboard/contas-a-pagar/ContasPagar.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.conta", plural: true },
      icon: "mdi-cash-minus",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.conta", plural: true, },
      ],
      permission: { componente: "ContasPagar", }
    },

  },
];

export default routes;
