import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import { fetchListaProdutosComValor } from "@/api/relatorios/relatorio_produtos.js";

export async function generatePdf() {

  let result = {
    error: false,
    message: null,
    pdfData: null,
  };
  const bodyPDF = await layoutEmpresa();
  const cabecalho = bodyPDF.cabecalho;
  const filtro_get_extrato = bodyPDF.queryString;
  const produtos_infos = await fetchListaProdutosComValor(filtro_get_extrato);

  return new Promise((resolve) => {
    let content = [];
    content.push(cabecalho);

    //-------------------------------
    let cabecalho_lista_produtos = {
      margin: [0, 5, 0, 5],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: "LISTA DE PRODUTOS COM VALOR ",
              fillColor: "#F5F5F5",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };
    content.push(cabecalho_lista_produtos);
    //----------------------------------

    let table_produtos_color = "#B0BEC5";
    let table_lista_color = "#CFD8DC";
    let table_produtos_font = 8;
    let table_produtos_font_row = 7;


    produtos_infos.forEach((produto, index) => {

      let table_produtos = {
        unbreakable: true,
        margin: [0, 0, 0, 0],
        table: {
          widths: ["auto", "*", "*", "*"],
          body: [
            [
              {
                text: "CÓD",
                bold: true,
                // color: "#FFFFFF",
                fillColor: table_produtos_color,
                alignment: "center",
                fontSize: table_produtos_font,
              },
              {
                text: "DESCRIÇÃO",
                bold: true,
                fillColor: table_produtos_color,
                // color: "#FFFFFF",
                alignment: "center",
                fontSize: table_produtos_font,
              },
              {
                text: "CATEGORIA",
                fillColor: table_produtos_color,
                bold: true,
                // color: "#FFFFFF",
                alignment: "center",
                fontSize: table_produtos_font,
              },
              {
                text: "FORNECEDOR",
                bold: true,
                fillColor: table_produtos_color,
                // color: "#FFFFFF",
                alignment: "center",
                fontSize: table_produtos_font,
              },
            ],
            [
              {
                text: produto.referencia,
                alignment: "center",
                fontSize: table_produtos_font_row,
              },
              {
                text: produto.descricao,
                alignment: "start",
                fontSize: table_produtos_font_row,
              },
              {
                text: produto.categoria,
                alignment: "center",
                fontSize: table_produtos_font_row,
              },
              {
                text: produto.fornecedor_nome,
                alignment: "center",
                fontSize: table_produtos_font_row,
              },
            ]
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          hLineColor: function (i) {
            return i === 1 ? "#666666" : "#666666";
          },
          vLineColor: function (i) {
            return i === 1 ? "#666666" : "#666666";
          },
        },
      };
      content.push(table_produtos);

      if (produto.listas.length > 0) {
        let table_lista = {
          unbreakable: true,
          margin: [24, 1, 0, 20],
          table: {
            widths: ["*", "*", "*", "*"],
            body: [
              [
                {
                  text: "TIPO",
                  bold: true,
                  // color: "#FFFFFF",
                  fillColor: table_lista_color,
                  alignment: "center",
                  fontSize: table_produtos_font - 1,
                },
                {
                  text: "R$",
                  bold: true,
                  fillColor: table_lista_color,
                  // color: "#FFFFFF",
                  alignment: "center",
                  fontSize: table_produtos_font - 1,
                },
                {
                  text: "U$",
                  fillColor: table_lista_color,
                  bold: true,
                  // color: "#FFFFFF",
                  alignment: "center",
                  fontSize: table_produtos_font - 1,
                },
                {
                  text: "G$",
                  bold: true,
                  fillColor: table_lista_color,
                  // color: "#FFFFFF",
                  alignment: "center",
                  fontSize: table_produtos_font - 1,
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
            },
            hLineColor: function (i) {
              return i === 1 ? "#666666" : "#666666";
            },
            vLineColor: function (i) {
              return i === 1 ? "#666666" : "#666666";
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex % 2 === 0) ? "#F5F5F5" : null;
            }
          },
        };

        produto.listas.forEach((lista) => {
          let row_lista = [
            {
              text: lista.descricao,
              alignment: "center",
              fontSize: table_produtos_font_row - 1,
            },
            {
              text: Vue.filter("currency")(lista.reais),
              alignment: "center",
              fontSize: table_produtos_font_row - 1,
            },
            {
              text: Vue.filter("currency")(lista.dolar),
              alignment: "center",
              fontSize: table_produtos_font_row - 1,
            },
            {
              text: Vue.filter("guarani")(lista.guarani),
              alignment: "center",
              fontSize: table_produtos_font_row - 1,
            },

          ];

          table_lista.table.body.push(row_lista);
        });
        content.push(table_lista);
      } else {
        content.push(" ");
      }
    });

    content;

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: "right",
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
