<template>
  <v-dialog
    v-model="dialogCancelarProduto"
    @click:outside="$emit('update:dialogCancelarProduto', false)"
    @keydown.esc="$emit('update:dialogCancelarProduto', false)"
    width="500"
  >
    <v-card v-if="!loading">
      <v-card-title>
        <v-icon left>mdi-alert</v-icon>
        {{ $tc(`global.${mensagem}`) }}
      </v-card-title>

      <v-card-actions>
        <v-btn
          color="error"
          text
          @click="$emit('update:dialogCancelarProduto', false)"
        >
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          @click="desativarProduto()"
          small
          color="warning"
          class="white--text"
        >
          <v-icon left>mdi-minus-circle-off-outline</v-icon>
          {{ $tc("global.desativar") }}
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="deletarProduto()"
          v-if="!status"
          small
          color="red"
          class="white--text"
        >
          <v-icon left>mdi-delete</v-icon>
          {{ $tc("global.deletar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <Carregando v-else />
  </v-dialog>
</template>

<script>
import {
  getCheckProdutoMovimento,
  postDesativarProduto,
  deleteProduto,
} from "@/api/produtos/produtos.js";
export default {
  name: "DialogCancelarProduto",

  props: {
    dialogCancelarProduto: {
      type: Boolean,
      default: false,
    },
    produto_id: {
      type: Number,
    },
  },

  data() {
    return {
      loading: false,
      mensagem: "produtoSemMovimento",
      status: false,
    };
  },

  methods: {
    deletarProduto() {
      this.loading = true;
      deleteProduto(this.produto_id)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("fetch-produtos");
            this.$emit("update:dialogCancelarProduto", false);
            this.loading = false;
          }
        })
        .catch(() => {
          this.$toast("global.errordel");
          this.loading = false;
        });
    },

    desativarProduto() {
      this.loading = true;
      postDesativarProduto(this.produto_id)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("fetch-produtos");
            this.$emit("update:dialogCancelarProduto", false);
            this.loading = false;
          }
        })
        .catch(() => {
          this.$toast("global.errordel");
          this.loading = false;
        });
    },

    vereficacaoProdutoCancelar() {
      this.loading = true;
      return getCheckProdutoMovimento(this.produto_id)
        .then((response) => {
          this.mensagem = response.mensagem;
          this.status = response.status;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.vereficacaoProdutoCancelar();
  },
};
</script>

<style></style>
