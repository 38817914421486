<template>
  <v-autocomplete
    v-model="fornecedor"
    :items="fornecedores"
    @keyup="buscaFornecedores"
    clearable
    :search-input.sync="search"
    @click:clear="clearFornecedor"
    dense
    outlined
    hide-details
    item-text="nome"
    item-value="id"
    :label="$tc('global.fornecedor')"
    :placeholder="$tc('global.buscarFornecedor')"
    prepend-inner-icon="mdi-magnify"
  >
  </v-autocomplete>
</template>

<script>
import { searchFornecedores } from "@/api/fornecedores/fornecedores.js";
export default {
  name: "Fornecedor",

  data() {
    return {
      fornecedor: null,
      fornecedores: [],
      search: "",
    };
  },
  computed: {
    empresa() {
      return this.$store.state.empresa;
    },
  },

  watch: {
    fornecedor() {
      if (this.fornecedor) {
        this.$store.commit(
          "FiltrosRelatorios/UPDATE_FORNECEDOR",
          this.fornecedor
        );
      }
    },
  },

  methods: {
    clearFornecedor() {
      this.$store.commit("FiltrosRelatorios/UPDATE_FORNECEDOR", null);
      this.fornecedor = null;
    },
    searchFornecedores(filtro) {
      return searchFornecedores(filtro)
        .then((response) => {
          this.fornecedores = response;
        })
        .catch(() => {});
    },

    async buscaFornecedores(event) {
      if (this.search) {
        if (this.search.length > 2 && event.key != "Backspace") {
          await this.searchFornecedores(`?search=${this.search}`);
        }
      }
    },
  },
  async mounted() {
    if (this.$route.query.fornecedor) {
      let filtro = `?search=id=${this.$route.query.fornecedor}`;
      await this.searchFornecedores(filtro)
        .then((response) => {
          this.fornecedores = response;
          this.fornecedor = this.response[0].id;
        })
        .catch(() => {});
    }
  },
};
</script>

<style></style>
