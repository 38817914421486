const routes = [
  {
    path: "/super-admin/permissoes",
    component: () => import("@/views/super_admin/permissoes/Permissoes.vue"),
    meta: {
      menu: "super_admin",
      title: "Permissões",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.permissao", plural: true },
      ],
    },
  },
  {
    path: "/super-admin/permissao/:permissaoId",
    component: () => import("@/views/super_admin/permissoes/Permissao.vue"),
    meta: {
      menu: "super_admin",
      title: "Permissão",
    },
  },
  {
    path: "/super-admin/permissoes/add",
    component: () => import("@/views/super_admin/permissoes/NovaPermissao.vue"),
    meta: {
      menu: "super_admin",
      title: "Novo",
    },
  },
];

export default routes;
