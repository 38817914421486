import api from "../axios_service.js";

export async function fetchDepositosList(filtros) {
  const response = await api.get(
    `/produtosdepositos-list${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchDepositos(filtros) {
  const response = await api.get(`/produtosdepositos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function Api_postDeposito(deposito) {
  let body = {};
  for (let key in deposito) {
    body[key] = deposito[key];
  }
  return api.post("produtosdepositos/add", body);
}

export async function Api_putDeposito(id, deposito) {
  let body = {};
  for (let key in deposito) {
    if (key != "cidade") {
      body[key] = deposito[key];
    }
  }
  return api.put(`/produtosdepositos/${id}`, body);
}

export function deleteDeposito(id) {
  return api.delete(`/produtosdepositos/${id}`);
}

export async function postEstoqueMinimo(estoque) {
  const response = api.post(`/controle-estoque`, estoque);
  return response;
}

export async function fetchDepositoNotEqual(id) {
  const response = await api.get(`/produtosdepositos/${id}/notequal`);
  return response.data.result;
}
