<template>
  <v-autocomplete
    v-if="!loading"
    v-model="selectedCliente"
    :label="$tc('global.cliente')"
    :items="clientes"
    :loading="buscandoClientes"
    :search-input.sync="searchCliente"
    dense
    outlined
    hide-details
    item-text="nome"
    item-value="id"
    :placeholder="$tc('global.buscarCliente')"
    append-icon=""
    full-width
    return-object
    no-filter
    :readonly="readonly"
    :clearable="!readonly"
    prepend-inner-icon="mdi-magnify"
    ><template v-slot:selection="data">
      <div class="pa-0">
        <div class="">{{ data.item.nome }}</div>
        <div class="text-caption">{{ data.item.doc_ruc }}</div>
      </div>
    </template>
    <!-- :disabled="venda_items.length >= 1 || selectedProduto != null"
                :autofocus="!selectedCliente ? true : false" -->
  </v-autocomplete>
</template>

<script>
import { searchClientes } from "@/api/clientes/clientes.js";
export default {
  name: "SearchClientes",

  props: {
    cliente_sync: {
      type: [Object, String],
    },
    cliente_id: {
      type: [Number, String],
      default: null,
    },
    cliente_nome: {
      type: [String],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      selectedCliente: null,
      clientes: [],
      buscandoClientes: false,
      searchCliente: "",
      debounce: null,
    };
  },

  watch: {
    selectedCliente() {
      if (this.selectedCliente) {
        this.$emit("update:cliente_sync", this.selectedCliente);
      } else {
        this.$emit("update:cliente_sync", null);
      }
    },
    searchCliente() {
      if (this.buscandoClientes) return;
      this.buscaClientes();
    },
  },

  methods: {
    async searchClientes(filtro) {
      this.buscandoClientes = true;

      await searchClientes(filtro).then((response) => {
        this.clientes = response;
        if (this.cliente_id) {
          this.selectedCliente = this.clientes.find((item) => {
            return item.id == this.cliente_id;
          });
        }
      });
      this.buscandoClientes = false;
    },

    async buscaClientes() {
      const filtro = `?search=${this.searchCliente}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.searchClientes(this.searchCliente ? filtro : "");
      }, 500);
    },
  },

  async mounted() {
    if (this.cliente_nome) {
      await this.searchClientes(`?search=${this.cliente_nome}`);
    }
    this.loading = false;
  },
};
</script>

<style></style>
