<template>
  <v-container fluid>
    <v-card outlined class="mt-2 mx-4">
      <v-card-title class="text-body-1 px-3 py-2 lightgray">
        <span class="pl-5 text-h6"> Contatos </span>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text mt-6 mt-sm-0"
          color="button_1"
          small
          @click="openModalAdd"
        >
          <v-icon left>mdi-plus</v-icon>
          Contato
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="contatos"
          :items-per-page="-1"
          hide-default-footer
          class="data-tables data-tables__row-click"
          @click:row="openContato"
        >
          <template v-slot:item.data_nascimento="{ item }">
            <div v-if="item.data_nascimento">
              {{ item.data_nascimento | dateFormat("dd/MM/yyyy") }}
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              icon
              color="secondary"
              @click.stop="deleteContato(item.id)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <Carregando v-else />
      </v-card-text>

      <v-dialog v-model="dialog" scrollable width="400">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-card>
            <v-card-title class="text-subtitle-1 lightgray">
              {{ add ? "Adicionar Contato" : "Contatos" }}
              <v-spacer></v-spacer>
              <v-btn @click="dialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-4">
              <v-row dense>
                <!-- Nome Completo -->
                <v-col cols="12">
                  <v-text-field
                    v-model="contato.nome"
                    label="Nome Completo"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- Documento -->
                <v-col cols="12">
                  <v-text-field
                    class="pa-0"
                    v-model="contato.documento"
                    label="Documento"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- Email -->
                <v-col cols="12">
                  <v-text-field
                    class="pa-0"
                    v-model="contato.email"
                    label="Email"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Data de Nascimento -->
                <v-col cols="12">
                  <v-menu
                    v-model="nascimentoDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="DataNascimentoFormatted"
                        label="Data Nascimento"
                        append-icon="mdi-calendar"
                        dense
                        clearable
                        readonly
                        outlined
                        hide-details
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      v-model="contato.data_nascimento"
                      @input="nascimentoDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Telefone 1 -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="contato.tel1"
                    label="Telefone 1"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- Telefone 2 -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="contato.tel2"
                    label="Telefone 2"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- Grau Parentesco -->
                <v-col cols="12">
                  <v-text-field
                    v-model="contato.grau_parentesco"
                    label="Grau Parentesco"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="button_1"
                class="white--text"
                @click="add ? novoContato() : updateContato()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import {
  fetchContatosClientes,
  putContatosClientes,
  postContatosClientes,
  deleteContatosClientes,
} from "@/api/clientes/clientes_contatos.js";
import { format, parseISO } from "date-fns";

export default {
  name: "DadosContato",
  data() {
    return {
      loading: false,
      contatos: [],
      nascimentoDatePicker: false,
      contato: {},
      original: {},
      add: false,
      dialog: false,
      formRules: [inputRequired],
      validForm: true,
      emailRules: [validaEmail],
    };
  },
  computed: {
    DataNascimentoFormatted() {
      return this.contato.data_nascimento
        ? format(parseISO(this.contato.data_nascimento), "dd/MM/yyyy")
        : "";
    },
    cliente_id() {
      return this.$route.params.id;
    },
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
          divider: true,
        },
        {
          text: "Telefone 1",
          value: "tel1",
          divider: true,
        },
        {
          text: "Telefone 2",
          value: "tel2",
          divider: true,
        },
        {
          text: "Grau de Parentesco",
          value: "grau_parentesco",
          divider: true,
        },
        {
          text: "Email",
          value: "email",
          divider: true,
        },
        {
          text: "Aniversário",
          value: "data_nascimento",
        },
        {
          text: "",
          align: "right",
          value: "actions",
          width: "20px",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    openModalAdd() {
      this.add = true;
      this.dialog = true;
    },
    getContatos() {
      this.loading = true;
      fetchContatosClientes(this.cliente_id)
        .then((response) => {
          this.contatos = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    openContato(item) {
      this.add = false;
      this.contato = item;
      this.dialog = true;
    },

    updateContato() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        putContatosClientes(this.contato.id, this.contato)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Contato atualizado com sucesso!");
              this.loading = false;
              this.dialog = false;

              this.contato = [];
            }
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
          });
      }
    },
    novoContato() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.contato.cliente_id = this.cliente_id;
        postContatosClientes(this.contato)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Contato criado com sucesso!");

              this.getContatos(this.cliente_id);
              this.loading = false;
              this.contato = {};
              this.dialog = false;
            }
          })
          .catch((error) => {
            this.loading = false;

            console.error(error);
            if (error.response.status === 422) {
              if (error.response.data.nome) {
                this.$toast.error(error.response.data.nome[0]);
              }
            }
          });
      }
    },
    deleteContato(id) {
      this.loading = true;
      deleteContatosClientes(id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Contato deletado com sucesso!");
            this.getContatos(this.cliente_id);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;

          console.log(error);
        });
    },
  },
  created() {
    this.getContatos();
  },
};
</script>

<style></style>
