<template>
  <v-container fluid>
    <PageToolbar voltar dark icon="mdi-domain" :title="empresa.nome" />

    <v-card class="px-0 pt-0 pb-8" :loading="loading" :disabled="loading">
      <v-tabs v-model="tab">
        <v-tab class="caption font-weight-bold"> Informações </v-tab>
        <v-tab class="caption font-weight-bold"> Váriaveis </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <!-- Informações -->
        <v-tab-item>
          <v-card flat class="mt-4 pb-10">
            <v-card-title class="subtitle-1">
              <v-spacer></v-spacer>
              <v-btn
                @click="updateEmpresa"
                :disabled="!validForm"
                color="button_1"
                class="white--text"
              >
                <v-icon left>mdi-check</v-icon>
                {{ $tc("global.salvar") }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" md="2">
                    <v-card
                      outlined
                      class="fill-height d-flex align-center justify-center"
                      style="position: relative"
                    >
                      <v-card flat>
                        <v-img v-if="logo" :src="logo" contain></v-img>
                        <v-img
                          v-else
                          :src="require('@/assets/pagina-default.png')"
                          contain
                        ></v-img>
                        <v-btn
                          fab
                          dark
                          x-small
                          absolute
                          bottom
                          right
                          @click="onButtonClick"
                        >
                          <input
                            accept="image/*"
                            type="file"
                            class="d-none"
                            ref="uploader"
                            @change="onFileChanged"
                          />
                          <v-icon>mdi-image</v-icon>
                        </v-btn>
                      </v-card>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="10">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.lazy="empresa.nome"
                          label="Nome"
                          outlined
                          dense
                          :rules="formRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.lazy="empresa.localizacao"
                          label="Localizacao"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- telefone -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model.lazy="empresa.telefone"
                          :label="$tc('global.telefone')"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Moeda -->
                      <v-col cols="12" md="4">
                        <v-combobox
                          v-model="selectedMoeda"
                          :label="$tc('global.moeda')"
                          :items="moedas"
                          item-text="sigla"
                          item-value="id_moeda"
                          dense
                          outlined
                          :disabled="hasCompra"
                          hide-details
                          class="mb-2"
                        ></v-combobox>
                      </v-col>

                      <!-- Lista Preço -->
                      <v-col cols="12" md="4">
                        <v-combobox
                          v-model="selectedLista"
                          :items="listas"
                          clearable
                          item-text="descricao"
                          item-value="id"
                          dense
                          outlined
                          required
                          :rules="formRules"
                          :label="$tc('global.listadepreco')"
                          :loading="loadingListas"
                        ></v-combobox>
                      </v-col>

                      <!-- CDG autorizacao -->

                      <v-col cols="12" md="4">
                        <v-text-field
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'"
                          @click:append="show = !show"
                          v-model="empresa.cdg_autorizacao"
                          label="Codigo Autorizacao"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Status -->
                      <v-col cols="12" md="4">
                        <v-text-field dense outlined value="Status:">
                          <template v-slot:append>
                            <v-switch
                              v-model="empresa.status"
                              :false-value="0"
                              :true-value="1"
                              class="mt-0 pt-0"
                              hide-details
                            >
                            </v-switch>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <EmpresaVariaveis />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchEmpresaAdmin, putEmpresaAdmin } from "@/api/empresas/empresas.js";
import { fetchHasCompra } from "@/api/compras/compras.js";
import { mapState } from "vuex";
import { fetchListasPrecos } from "@/api/listas-precos/listasprecos.js";

export default {
  name: "SA_Empresa",

  props: {},

  components: {
    EmpresaVariaveis: () => import("./components/EmpresaVariaveis.vue"),
  },

  data() {
    return {
      show: false,
      empresa: {},
      validForm: true,
      formRules: [inputRequired],
      loading: false,
      empresa_original: {},
      logo: null,
      logoUpload: null,
      tab: 0,
      selectedMoeda: null,
      hasCompra: false,
      compra: [],
      loadingListas: false,
      selectedLista: null,
      listas: [],
    };
  },

  computed: {
    empresa_id() {
      return this.$route.params.empresa_id;
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  watch: {
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.empresa.moeda_empresa = this.selectedMoeda.id_moeda;
      }
    },
    selectedLista() {
      if (this.selectedLista) {
        this.empresa.lista_preco_id = this.selectedLista.id;
      }
    },
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.logoUpload = file;
      this.empresa.logo = !this.empresa.logo ? "logo" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },

    getListas() {
      this.loadingListas = true;
      fetchListasPrecos()
        .then((response) => {
          this.listas = response;
          this.loadingListas = false;
        })
        .catch((error) => {
          this.loadingListas = false;
        });
    },

    updateEmpresa() {
      if (
        JSON.stringify(this.empresa) === JSON.stringify(this.empresa_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const fd = new FormData();

        if (this.logoUpload) {
          fd.append("logo", this.logoUpload, this.logoUpload.name);
          fd.append("logo64", this.logo);
        }

        let empresa = {};
        empresa.nome = this.empresa.nome;
        empresa.localizacao = this.empresa.localizacao;
        empresa.status = this.empresa.status;
        empresa.moeda_empresa = this.empresa.moeda_empresa;
        empresa.lista_preco_id = this.empresa.lista_preco_id;
        empresa.telefone = this.empresa.telefone;
        empresa.cdg_autorizacao = this.empresa.cdg_autorizacao;

        for (let key in empresa) {
          if (
            empresa[key] !== null &&
            empresa[key] !== undefined &&
            empresa[key] !== ""
          ) {
            fd.append(key, empresa[key]);
          }
        }

        putEmpresaAdmin(this.empresa_id, fd)
          .then(() => {
            this.empresa_original = { ...this.empresa };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    getEmpresa() {
      this.loading = true;

      return fetchEmpresaAdmin(this.empresa_id).then((response) => {
        this.empresa = response;
        this.empresa_original = { ...response };
        this.logo = this.empresa.logo;

        if (
          !this.$store.state.dynamic_crumb ||
          this.$store.state.dynamic_crumb != this.empresa.nome
        ) {
          this.$store.commit("UPDATE_DYNAMICCRUMB", this.empresa.nome);
        }
      });
    },

    getHasCompra() {
      this.loading = true;
      fetchHasCompra()
        .then((response) => {
          this.compra.push(response);

          if (this.compra[0] !== null) {
            this.hasCompra = true;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.getListas();
    this.loading = true;
    await this.getEmpresa();
    this.getHasCompra();
    if (this.empresa.moeda_empresa) {
      this.selectedMoeda = this.moedas.find((moeda) => {
        return moeda.id_moeda === this.empresa.moeda_empresa;
      });
    }
    if (this.empresa.lista_preco_id) {
      this.selectedLista = this.listas.find((lista) => {
        return lista.id === this.empresa.lista_preco_id;
      });
    }
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
