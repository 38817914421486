const routes = [
  {
    path: "/fornecedores",
    component: () => import("@/views/dashboard/fornecedores/Fornecedores.vue"),
    meta: {
      menu: "dashboard",

      icon: "mdi-truck-outline",
      title: { text: "global.fornecedor", plural: true },
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.fornecedor", plural: true },
      ],
      permission: { componente: "Fornecedor" },
    },
  },
  {
    path: "/fornecedores/adicionar",
    component: () =>
      import("@/views/dashboard/fornecedores/NovoFornecedor.vue"),
    meta: {
      menu: "dashboard",
      title: "Novo Fornecedor",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.fornecedor",
          plural: true,
          to: { path: "/fornecedores" },
        },
        { text: "global.add", plural: true },
      ],
      voltar: { path: "/fornecedores" },
      permission: { componente: "Fornecedor" },
    },
  },
  {
    path: "/fornecedores/editar/:fornecedorId",
    component: () =>
      import("@/views/dashboard/fornecedores/EditFornecedor.vue"),
    meta: {
      menu: "dashboard",
      title: "Editar Fornecedor",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.fornecedor",
          plural: true,
          to: { path: "/fornecedores" },
        },
        { text: "global.editar", plural: true },
      ],
      permission: { componente: "Fornecedor" },
    },
  },
];

export default routes;
