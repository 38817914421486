<template>
  <v-dialog
    v-model="dialogHistoricoPrecosAlteracoes"
    @click:outside="$emit('update:dialogHistoricoPrecosAlteracoes', false)"
    @keydown.esc="$emit('update:dialogHistoricoPrecosAlteracoes', false)"
    scrollable
  >
    <v-card flat>
      <v-card-title class="tocs_gray_1">
        <v-icon left>mdi-clipboard-text-clock-outline</v-icon>
        {{ $tc("global.historicoPrecosAlterados") }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="$emit('update:dialogHistoricoPrecosAlteracoes', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-2">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            :placeholder="$tc('global.buscarProdutosPorDescricao')"
            clearable
            solo
            flat
            outlined
            dense
            autofocus
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogMudarPrecosLotes = true"
            color="button_1"
            class="white--text"
            depressed
          >
            {{ $tc("global.add") }}
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card outlined>
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="historicoPrecosAlteracoesProdutos"
              :items-per-page="15"
              :search="search"
              fixed-header
              height="calc(100vh - 345px)"
              class="data-tables"
            >
              <template v-slot:item.actions="{ item }">
                <template>
                  <v-tooltip bottom color="darkblue">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-3"
                        depressed
                        color="tocs_green"
                        v-on="on"
                        @click="openViewAlteracaoPrecos(item)"
                        icon
                        x-small
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-caption">{{
                      $tc("global.verPrecoAlterado").toUpperCase()
                    }}</span>
                  </v-tooltip>

                  <v-tooltip bottom color="darkblue">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        depressed
                        @click="openRefazerPrecosAlteracoes(item)"
                        color="tocs_blue"
                        v-on="on"
                        icon
                        x-small
                      >
                        <v-icon>mdi-arrow-u-right-bottom-bold</v-icon>
                      </v-btn>
                    </template>
                    <span class="text-caption">{{
                      $tc("global.refazerAlteracao").toUpperCase()
                    }}</span>
                  </v-tooltip>
                </template>
              </template>

              <template v-slot:item.created_at="{ item }">
                {{ item.created_at | dateFormat("dd/MM/yyyy") }}
              </template>

              <template v-slot:item.usuario_nome="{ item }">
                <v-tooltip bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      {{ item.usuario_nome | excerpt(30) }}
                    </div>
                  </template>
                  <span>{{ item.usuario_nome }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card-text>
    </v-card>
    <DialogMudarPrecosLotes
      v-if="dialogMudarPrecosLotes"
      :dialogMudarPrecosLotes.sync="dialogMudarPrecosLotes"
      @fetch-all-precos="getProdutosPrecosAlteracoes()"
    />
    <DialogProdutosSelecionados
      v-if="dialogProdutosSelecionados"
      :dialogProdutosSelecionados.sync="dialogProdutosSelecionados"
      :produtoSelected="produtos_ids"
      :listaPrecoId="lista_preco_id"
      :moedaId="moeda_id"
      @fetch-precos="getProdutosPrecosAlteracoes()"
    />
    <DialogVisualizarAlteracoesPrecos
      v-if="dialogVisualizarAlteracoesPrecos"
      :dialogVisualizarAlteracoesPrecos.sync="dialogVisualizarAlteracoesPrecos"
      :alteracaoId="alteracao_id"
      :selectedMoeda="moeda_sigla"
    />
  </v-dialog>
</template>

<script>
import { fetchProdutosPrecosAlteracoes } from "@/api/produtos/produtos_alteracoes_precos.js";
export default {
  name: "HistoricoPrecosAlteracoes",

  components: {
    DialogMudarPrecosLotes: () =>
      import("./components/DialogMudarPrecosLotes.vue"),
    DialogProdutosSelecionados: () =>
      import("./components/DialogProdutosSelecionados.vue"),
    DialogVisualizarAlteracoesPrecos: () =>
      import("./components/DialogVisualizarAlteracoesPrecos.vue"),
  },

  props: {
    dialogHistoricoPrecosAlteracoes: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      search: "",
      produtos_ids: [],
      moeda_id: null,
      lista_preco_id: null,
      historicoPrecosAlteracoesProdutos: [],
      dialogMudarPrecosLotes: false,
      dialogProdutosSelecionados: false,
      dialogVisualizarAlteracoesPrecos: false,
      alteracao_id: null,
      moeda_sigla: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "id",
          value: "id",
          align: "start",
          width: "70px",
          divider: true,
        },
        {
          text: this.$tc("global.usuario"),
          value: "usuario_nome",
          align: "start",
          width: "120px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.lista") + " " + this.$tc("global.preco"),
          value: "lista_preco",
          align: "start",
          width: "80px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.moeda"),
          value: "sigla",
          align: "center",
          width: "40px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
          align: "center",
          width: "80px",
          divider: true,
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          align: "center",
          width: "70px",
          sortable: false,
          //   divider: true,
        },
      ];
    },
  },

  watch: {},

  methods: {
    getProdutosPrecosAlteracoes() {
      this.loading = true;
      return fetchProdutosPrecosAlteracoes()
        .then((response) => {
          this.historicoPrecosAlteracoesProdutos = response;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openRefazerPrecosAlteracoes(item) {
      if (item) {
        this.produtos_ids = item.produtos.map((produto) => produto.produto_id);
        this.moeda_id = item.moeda_id;
        this.lista_preco_id = item.lista_preco_id;
        this.dialogProdutosSelecionados = true;
      } else {
        this.produtos_ids = [];
        this.moeda_id = null;
        this.lista_preco_id = null;
      }
    },

    openViewAlteracaoPrecos(item) {
      this.alteracao_id = item.id;
      this.moeda_sigla = item.sigla;
      this.dialogVisualizarAlteracoesPrecos = true;
    },
  },

  async mounted() {
    await this.getProdutosPrecosAlteracoes();
  },
};
</script>

<style></style>
