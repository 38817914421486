import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import dateLocaleEspanhol from "date-fns/locale/es";
import writtenNumber from "written-number";
import { layoutEmpresa } from "@/views/dashboard/relatorios/config/layoutEmpresa.js";
import store from "@/store/modules/usuario.store.js";
import { getUsuario } from "@/api/usuarios/usuarios.js";
import { i18n } from "../../../../plugins/i18n";

export async function generateTransferencia(recibo) {
  const usuario = await getUsuario(recibo[0].usuario_id);
  const bodyPDF = await layoutEmpresa();
  const cabecalho = JSON.stringify(bodyPDF.cabecalhoRecibo);
  const cabecalhoRecibo = JSON.parse(cabecalho);
  const cabecalhoRecibo2 = JSON.parse(cabecalho);
  return new Promise((resolve, reject) => {
    let docDefinition = {};
    let recibo_info = recibo[0];
    let de;
    let para;
    let documento;
    let descricao;
    // descricao = recibo_info.concepto_descricao;
    de = usuario.name;
    para = recibo_info.quem_assina;
    documento = recibo_info.documento ? recibo_info.documento : " ";
    recibo_info.moeda_sigla =
      recibo_info.moeda_id === 1
        ? "R$"
        : recibo_info.moeda_id === 2
        ? "G$"
        : "U$";
    recibo_info.usuario_nome = store.state.usuario.name;
    let fontItem = 12;
    let moeda_nome;
    if (recibo_info.moeda_sigla == "G$") {
      moeda_nome = "GUARANIES";
    }

    if (recibo_info.moeda_sigla == "R$") {
      moeda_nome = "REALES";
    }

    if (recibo_info.moeda_sigla == "U$") {
      moeda_nome = "DOLARES";
    }
    let cabecalho = [cabecalhoRecibo];
    let cabecalho2 = [cabecalhoRecibo2];
    let corpo = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["auto", 200, "*"],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "center",
              fontSize: 17,
              margin: [0, 10, 0, 0],
              text: "TRANSFERENCIA",
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, true, false],
              alignment: "center",
              fontSize: 15,
              margin: [0, 10, 0, 0],
              // fillColor: "#F5F5F5",
              text:
                "Monto: " +
                recibo_info.moeda_sigla +
                " " +
                (recibo_info.moeda_sigla == "G$"
                  ? Vue.filter("guarani")(recibo_info.valor_recibo)
                  : Vue.filter("currency")(recibo_info.valor_recibo)),
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              fontSize: fontItem,
              border: [true, false, true, false],
              margin: [10, 10, 10, 0],
              // fillColor: "#F5F5F5",
              text: i18n.tc("global.caixaOrigem") + ": " + recibo_info.origem,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              fontSize: fontItem,
              border: [true, false, true, false],
              margin: [10, 0, 10, 0],
              // fillColor: "#F5F5F5",
              text: i18n.tc("global.caixaDestino") + ": " + recibo_info.destino,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              border: [true, false, true, false],
              margin: [40, 25, 40, 40],
              // fillColor: "#F5F5F5",
              text:
                "_____________________________________\n" +
                para +
                "\n" +
                documento +
                "\n\n" +
                format(parseISO(recibo_info.data_recibo), "dd MMMM yyyy", {
                  locale: dateLocaleEspanhol,
                }),
              colSpan: 3,
              bold: true,
            },
            {},
            {},
          ],
          [
            {
              colSpan: 3,
              columns: [
                {
                  fontSize: 8,
                  // fillColor: "#F5F5F5",
                  margin: [0, 0, 0, 0],
                  border: [true, true, false, true],
                  text: "ORIGEM: " + recibo_info.origem,
                  alignment: "left",
                  bold: true,
                },
                {
                  fontSize: 8,
                  alignment: "center",
                  // fillColor: "#F5F5F5",
                  margin: [0, 0, 0, 0],
                  border: [false, true, false, true],
                  text: "PRUEBA CAIXA",
                  bold: true,
                },
                {
                  // fillColor: "#F5F5F5",
                  fontSize: 8,
                  alignment: "right",
                  margin: [0, 0, 0, 0],
                  border: [false, true, true, true],
                  text: "USUARIO: " + recibo_info.usuario_nome,
                  bold: true,
                },
              ],
            },
            {},
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let pula = {
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-40, -40, 0, -40],
              border: [false, false, false, false],
              text: "\n\n\n---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n\n\n\n",
            },
          ],
        ],
      },
    };

    let corpo2 = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["auto", 200, "*"],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "center",
              fontSize: 17,
              margin: [0, 10, 0, 0],
              text: "TRANSFERENCIA",
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, true, false],
              alignment: "center",
              fontSize: 15,
              margin: [0, 10, 0, 0],
              // fillColor: "#F5F5F5",
              text:
                "Monto: " +
                recibo_info.moeda_sigla +
                " " +
                (recibo_info.moeda_sigla == "G$"
                  ? Vue.filter("guarani")(recibo_info.valor_recibo)
                  : Vue.filter("currency")(recibo_info.valor_recibo)),
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              fontSize: fontItem,
              border: [true, false, true, false],
              margin: [10, 10, 10, 0],
              // fillColor: "#F5F5F5",
              text: i18n.tc("global.caixaOrigem") + ": " + recibo_info.origem,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              fontSize: fontItem,
              border: [true, false, true, false],
              margin: [10, 0, 10, 0],
              // fillColor: "#F5F5F5",
              text: i18n.tc("global.caixaDestino") + ": " + recibo_info.destino,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              alignment: "center",
              border: [true, false, true, false],
              margin: [40, 25, 40, 40],
              // fillColor: "#F5F5F5",
              text:
                "_____________________________________\n" +
                para +
                "\n" +
                documento +
                "\n\n" +
                format(parseISO(recibo_info.data_recibo), "dd MMMM yyyy", {
                  locale: dateLocaleEspanhol,
                }),
              colSpan: 3,
              bold: true,
            },
            {},
            {},
          ],
          [
            {
              colSpan: 3,
              columns: [
                {
                  fontSize: 8,
                  alignment: "left",
                  // fillColor: "#F5F5F5",
                  text: "ORIGEM: " + recibo_info.origem,
                  bold: true,
                },
                {
                  alignment: "center",
                  fontSize: 8,
                  // fillColor: "#F5F5F5",
                  text: "PRUEBA EMPRESA",
                  bold: true,
                },
                {
                  alignment: "right",
                  fontSize: 8,
                  // fillColor: "#F5F5F5",
                  text: "USUARIO: " + recibo_info.usuario_nome,
                  bold: true,
                },
              ],
            },
            {},
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let geral = [cabecalho, corpo, pula, cabecalho2, corpo2];
    docDefinition = {
      info: {
        author: "ADM",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
