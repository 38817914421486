<template>
  <v-dialog
    v-model="dialogMoedaEmpresa"
    @click:outside="$emit('update:dialogMoedaEmpresa', false)"
    @keydown.esc="$emit('update:dialogMoedaEmpresa', false)"
    width="30%"
    max-width="400"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        Adicionar Empresa
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogMoedaEmpresa', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-select
          v-model="moeda_id"
          :items="moedasNotEqual"
          clearable
          item-text="sigla"
          item-value="id"
          outlined
          dense
          label="Moeda"
        ></v-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          depressed
          class="white--text"
          small
          @click="createMoeda"
        >
          {{ $tc("global.add") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchMoedasNotEqual, postMoeda } from "@/api/moedas/moedas.js";
export default {
  name: "DialogMoedaEmpresa",

  props: {
    dialogMoedaEmpresa: {
      type: Boolean,
      required: true,
    },
    moedasNotEqual: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      moeda_id: null,
    };
  },

  methods: {
    createMoeda() {
      this.loading = true;

      let moeda = {};

      moeda.moeda_id = this.moeda_id;

      postMoeda(moeda)
        .then(() => {
          this.$emit("fetch-moedas");
          this.$emit("fetch-moedas-not-equal");
          this.$emit("update:dialogMoedaEmpresa", false);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {},
};
</script>

<style></style>
