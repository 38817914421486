import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { i18n } from "@/plugins/i18n";
import { fetchOneRecibo } from "@/api/recibos/recibos.js";

import store from "@/store/modules/usuario.store.js";
import writtenNumber from "written-number";

export async function generateReciboFiscal(recibo_id) {
  const recibo = await fetchOneRecibo(recibo_id);
  const empresa = await fetchEmpresa(store.state.empresa.id);
  return new Promise((resolve, reject) => {
    let docDefinition = {};

    let recibo_info = recibo;

    let moeda_nome;

    if (recibo_info.moeda_sigla == "G$") {
      moeda_nome = "GUARANIES";
    }

    if (recibo_info.moeda_sigla == "R$") {
      moeda_nome = "REALES";
    }

    if (recibo_info.moeda_sigla == "U$") {
      moeda_nome = "DOLARES";
    }

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              margin: [12, 5, 2, 2],
              border: [true, true, false, true],
              stack: [
                {
                  image: empresa.logo64 ? empresa.logo64 : "",
                  width: 60,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, true],

              stack: [
                {
                  text: empresa.nome,
                  fontSize: 12,
                  bold: true,
                },
                {},
                {
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  text:
                    "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  text:
                    "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                },
              ],
              fontSize: 9,
              alignment: "left",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, true],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "RUC: " + (empresa.documento ? empresa.documento : ""),

                  alignment: "center",
                  fontSize: 10,
                },
                {
                  text: recibo_info.recibo_fiscal
                    ? "RECIBO DE DINERO"
                    : "RECIBO",

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text:
                    "FECHA: " +
                    format(parseISO(recibo_info.data_recibo), "dd/MM/yyyy"),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text: "Nº.: " + recibo_info.numero_recibo,
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let detalleDelReciboItens = [];

    if (recibo_info.historico_recebimento) {
      let fontSizeHistoricoRecebimento = 7;
      if (recibo_info.historico_recebimento.itens.length > 35) {
        fontSizeHistoricoRecebimento = 5;
      }
      recibo_info.historico_recebimento.itens.forEach((item) => {
        detalleDelReciboItens.push([
          {
            text: format(parseISO(item.created_at), "dd/MM/yyyy"),
            fontSize: fontSizeHistoricoRecebimento,
            alignment: "center",
          },
          {
            text: item.conta_receber_id,
            fontSize: fontSizeHistoricoRecebimento,
            alignment: "center",
          },
          {
            text: item.fatura_id ? item.numero_fatura : "",
            fontSize: fontSizeHistoricoRecebimento,
            alignment: "center",
          },
          {
            text:
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor_pago)
                : Vue.filter("currency")(item.valor_pago),
            fontSize: fontSizeHistoricoRecebimento,
            alignment: "right",
          },
          {
            text: item.conta_receber_numero_parcela,
            fontSize: fontSizeHistoricoRecebimento,
            alignment: "center",
          },
        ]);
      });
    }

    // ---------------------------------------------------------------
    let arrayFormaPago = [];

    if (recibo_info.historico_recebimento) {
      recibo_info.historico_recebimento.formas_pagamento.forEach((item) => {
        arrayFormaPago.push([
          {
            text: i18n
              .tc(`global.${item.forma_pagamento_descricao.toLowerCase()}`)
              .toUpperCase(),
            fontSize: 6,
            alignment: "right",
            border: [false, false, false, false],
          },
          {
            text:
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor)
                : Vue.filter("currency")(item.valor),
            fontSize: 6,
            alignment: "right",
            border: [false, false, false, false],
          },
        ]);
      });
    }

    let dadosCliente = {
      margin: [0, 5, 0, 0],
      table: {
        widths: ["auto", "auto", "auto", "auto"],
        body: [
          [
            {
              text: i18n.tc(`global.nome`).toUpperCase() + " : ",
              fontSize: 7,
              alignment: "start",
              border: [false, false, false, false],
            },
            {
              text: recibo_info.quem_pagou,
              fontSize: 7,
              alignment: "start",
              border: [false, false, false, false],
            },
            {
              text: "RUC: ",
              fontSize: 7,
              alignment: "start",
              border: [false, false, false, false],
            },
            {
              text: recibo_info.documento,
              fontSize: 7,
              alignment: "start",
              border: [false, false, false, false],
            },
          ],
        ],
      },
    };

    let corpoColuna = {
      alignment: "justify",
      columns: [
        {
          margin: [0, 5, 0, 30],
          table: {
            widths: ["*", "*", "*", "auto", "*"],
            body: [
              [
                {
                  text: "Fecha",
                  fontSize: 7,
                  alignment: "center",
                },
                {
                  text: "Nº. Conta",
                  fontSize: 7,
                  alignment: "center",
                },
                {
                  text: "Nº. Factura",
                  fontSize: 7,
                  alignment: "center",
                },
                {
                  text: "Importe",
                  fontSize: 7,
                  alignment: "center",
                },
                {
                  text: "Cuota",
                  fontSize: 7,
                  alignment: "center",
                },
              ],
              ...detalleDelReciboItens,
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
            },
          },
        },
        {},
      ],
    };

    let footerCorpo = {
      margin: [0, 50, 0, 0],
      columns: [
        {
          width: "40%",
          table: {
            widths: ["*", "*"],
            body: [
              [
                {
                  text: "Total:",
                  alignment: "start",
                  border: [true, true, false, false],
                  fontSize: 7,
                },
                {
                  text:
                    recibo_info.moeda_sigla == "G$"
                      ? Vue.filter("guarani")(recibo_info.valor_recibo)
                      : Vue.filter("currency")(recibo_info.valor_recibo),
                  alignment: "right",
                  border: [false, true, true, false],
                  fontSize: 7,
                },
              ],
              [
                {
                  text: "Importe Retenido:",
                  alignment: "start",
                  fontSize: 7,
                  border: [true, false, false, false],
                },
                {
                  text:
                    recibo_info.moeda_sigla == "G$"
                      ? Vue.filter("guarani")(recibo_info.valor_retenido)
                      : Vue.filter("currency")(recibo_info.valor_retenido),
                  alignment: "right",
                  fontSize: 7,
                  border: [false, false, true, false],
                },
              ],
              [
                {
                  text: "Total sin Ret.:",
                  alignment: "start",
                  fontSize: 7,
                  border: [true, false, false, true],
                },
                {
                  text:
                    recibo_info.moeda_sigla == "G$"
                      ? Vue.filter("guarani")(recibo_info.valor_recibo)
                      : Vue.filter("currency")(recibo_info.valor_recibo),
                  alignment: "right",
                  fontSize: 7,
                  border: [false, false, true, true],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
            },
          },
        },
        {
          width: "*",
          margin: [10, 22, 0, 0],
          stack: [
            {
              columns: [
                {
                  width: "*",
                  text: "Total:",
                  alignment: "start",
                  fontSize: 7,
                },
                {
                  width: "*",

                  margin: [
                    0,
                    arrayFormaPago.length > 1
                      ? -arrayFormaPago.length * 9.5
                      : -1,
                    0,
                    0,
                  ],
                  table: {
                    widths: ["auto", "auto"],
                    body: arrayFormaPago,
                  },
                  layout: "noBorders",
                },
              ],
            },
            {
              text:
                moeda_nome +
                " " +
                writtenNumber(recibo_info.valor_recibo, {
                  lang: "es",
                }).toUpperCase(),
              alignment: "start",
              fontSize: 6,
            },
          ],
        },
      ],
    };

    let footer = {
      margin: [5, 10, 0, 0],
      columns: [
        {
          alignment: "justify",
          fontSize: 6,
          stack: [
            "En caso de Recepcionarse Cheque, éste Recibo no tiene Fuerza Cancelatoria. Conforme a los Establecido en el Articulo 1/10 " +
            "del Código Civil Paraguayo, en consecuencia em casa que el Cheque no sea pagado por el Banco Girado, por cualquier causa, " +
            "el Emisor del Cheque está Sujeto al Pago de su Obligación Girando un nuevo Cheque contra un Banco de Plaza o Cancelando " +
            "la misma con Dinero en Efectivo.",
          ],
        },
        {
          margin: [0, 12, 0, 0],
          alignment: "center",
          fontSize: 7,
          bold: true,
          text:
            "__________________________________________________\n" +
            recibo_info.quem_assina,
        },
      ],
    };

    let pula = {
      margin: [0, 0, 0, 30],
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-5, -12, 0, 0],
              border: [false, false, false, false],
              text: "________________________________________________________________________________________________________",
            },
          ],
          [
            {
              margin: [5, -6, 0, 0],
              border: [false, false, false, false],
              fontSize: 6,
              text: "",
            },
          ],
        ],
      },
    };

    let break_page = {
      text: "",
      pageBreak: "before",
    };

    let geral = [
      cabecalho,
      dadosCliente,
      corpoColuna,
      footerCorpo,
      footer,
      pula,
      detalleDelReciboItens.length > 10 ? break_page : "",

    ];

    let content = [];
    let content_funcionario_via = JSON.parse(JSON.stringify(geral));
    content.push(JSON.parse(JSON.stringify(geral)));
    content.push(JSON.parse(JSON.stringify(content_funcionario_via)));


    docDefinition = {
      info: {
        title: "Recibo",
        author: "ADM",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
