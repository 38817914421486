<template>
  <v-container fluid>
    <PageToolbar
      :title="`${$tc('global.add')}  ${$tc('global.pdf_layout', 2)}`"
      icon="mdi-format-page-break"
      dark
    />

    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1">
        <v-spacer></v-spacer>
        <v-btn
          @click="createPdfLayout"
          class="white--text"
          color="button_1"
          :disabled="disableAdd"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-8">
        <v-row>
          <!-- HEADER: Dados Gerais -->
          <v-col cols="12" class="">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.dados-gerais") }}
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Tipo -->
          <v-col cols="12" md="6">
            <v-select
              v-model="layout.tipo"
              :items="layoutTipos"
              clearable
              outlined
              dense
              hide-details
              label="Tipo"
            ></v-select>
          </v-col>

          <!-- Items por pagina -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="layout.items_por_pagina"
              :label="$tc('global.itenspagina')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- HEADER: Configuracao da Pagina -->
          <v-col cols="12" class="mt-8">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.configuracaopagina") }}
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Margens -->
          <v-col
            cols="12"
            md="6"
            class="d-flex align-center justify-flex-start"
          >
            <v-text-field
              v-model="layout.obj_folha.margin_left"
              label="Margin Left"
              append-icon="mdi-chevron-left"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="layout.obj_folha.margin_top"
              label="Margin Top"
              append-icon="mdi-chevron-up"
              outlined
              dense
              hide-details
              class="ml-2 mr-1"
            ></v-text-field>
            <v-text-field
              v-model="layout.obj_folha.margin_right"
              label="Margin Right"
              append-icon="mdi-chevron-right"
              outlined
              dense
              hide-details
              class="ml-1 mr-2"
            ></v-text-field>
            <v-text-field
              v-model="layout.obj_folha.margin_bottom"
              label="Margin Bottom"
              append-icon="mdi-chevron-down"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- pageSize -->
          <v-col cols="12" md="3">
            <v-select
              v-model="layout.obj_folha.pageSize"
              :items="pageSizes"
              outlined
              clearable
              dense
              hide-details
              :label="$tc('global.tamanhopagina')"
            ></v-select>
          </v-col>

          <!-- Orientacao -->
          <v-col cols="12" md="3">
            <v-select
              v-model="layout.obj_folha.pageOrientation"
              :items="pageOrientations"
              outlined
              clearable
              dense
              hide-details
              :label="$tc('global.orientacaopagina')"
            />
          </v-col>

          <!-- HEADER: Configuracao de Imagem -->
          <v-col cols="12" class="mt-8">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.configuracaofundo") }}
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Input add Image -->
          <v-col cols="12">
            <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg"
              :placeholder="$tc('global.selecioneimagem')"
              append-icon="mdi-image-plus"
              @change="setImage"
              outlined
              dense
              hide-details
            ></v-file-input>
          </v-col>

          <!-- Imagem -->
          <v-col cols="12">
            <v-card
              outlined
              class="pa-4 d-flex align-center justify-center"
              min-height="121px"
            >
              <v-img
                v-if="layout.image"
                :src="layout.image"
                contain
                max-width="250"
              ></v-img>
              <v-card-text class="text-center" v-else>
                {{ $tc("global.selecioneimagem") }}
              </v-card-text>
            </v-card>
          </v-col>

          <!-- HEADER: Posição imagem 1 -->
          <v-col cols="12" class="mt-8">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.posicao") }} {{ $tc("global.imagem") }} 1
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Largura -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem1.width"
              :label="$tc('global.largura')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Altura -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem1.height"
              :label="$tc('global.altura')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posicao X -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem1.absolutePosition.x"
              :label="` ${$tc('global.posicao')} X`"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posicao Y -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem1.absolutePosition.y"
              :label="` ${$tc('global.posicao')} Y`"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Status -->
          <v-col md="4">
            <v-btn
              block
              small
              height="40"
              :depressed="!layout.obj_imagem1.status"
              :color="layout.obj_imagem1.status ? 'secondary' : ''"
              @click="layout.obj_imagem1.status = !layout.obj_imagem1.status"
            >
              {{ layout.obj_imagem1.status ? "ON" : "OFF" }}
            </v-btn>
          </v-col>

          <!-- HEADER: Posição imagem 2 -->
          <v-col cols="12" class="mt-8">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.posicao") }} {{ $tc("global.imagem") }} 2
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Largura -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem2.width"
              :label="$tc('global.largura')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Altura -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem2.height"
              :label="$tc('global.altura')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posicao X -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem2.absolutePosition.x"
              :label="` ${$tc('global.posicao')} X`"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posicao Y -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem2.absolutePosition.y"
              :label="` ${$tc('global.posicao')} Y`"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Status -->
          <v-col md="4">
            <v-btn
              block
              small
              height="40"
              :depressed="!layout.obj_imagem2.status"
              :color="layout.obj_imagem2.status ? 'secondary' : ''"
              @click="layout.obj_imagem2.status = !layout.obj_imagem2.status"
            >
              {{ layout.obj_imagem2.status ? "ON" : "OFF" }}
            </v-btn>
          </v-col>

          <!-- HEADER: Posição imagem 3 -->
          <v-col cols="12" class="mt-8">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.posicao") }} {{ $tc("global.imagem") }} 2
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Largura -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem3.width"
              :label="$tc('global.largura')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Altura -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem3.height"
              :label="$tc('global.altura')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posicao X -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem3.absolutePosition.x"
              :label="` ${$tc('global.posicao')} X`"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posicao Y -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model="layout.obj_imagem3.absolutePosition.y"
              :label="` ${$tc('global.posicao')} Y`"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Status -->
          <v-col md="4">
            <v-btn
              block
              small
              height="40"
              :depressed="!layout.obj_imagem3.status"
              :color="layout.obj_imagem3.status ? 'secondary' : ''"
              @click="layout.obj_imagem3.status = !layout.obj_imagem3.status"
            >
              {{ layout.obj_imagem3.status ? "ON" : "OFF" }}
            </v-btn>
          </v-col>

          <!-- HEADER: Marca D`agua -->
          <v-col cols="12" class="mt-8">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.marcadagua") }}
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Texto -->
          <v-col cols="12" md="9">
            <v-text-field
              v-model="layout.obj_marca_dagua.text"
              :label="$tc('global.label')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-btn
              block
              small
              height="40"
              :depressed="!layout.obj_marca_dagua.status"
              :color="layout.obj_marca_dagua.status ? 'secondary' : ''"
              @click="
                layout.obj_marca_dagua.status = !layout.obj_marca_dagua.status
              "
            >
              {{ layout.obj_marca_dagua.status ? "ON" : "OFF" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { postPdfLayout } from "@/api/pdf-layouts/pdf_layouts.js";

export default {
  name: "AddPdfLayout",

  data() {
    return {
      loading: false,
      validForm: true,
      layout: {
        tipo: "fatura",
        items_por_pagina: 10,
        image: "",
        obj_folha: {
          margin_left: 0,
          margin_top: 0,
          margin_right: 0,
          margin_bottom: 0,
          pageSize: "A4",
          pageOrientation: "portrait",
        },
        obj_imagem1: {
          absolutePosition: {
            x: null,
            y: null,
          },
          width: null,
          height: null,
          status: true,
        },
        obj_imagem2: {
          absolutePosition: {
            x: null,
            y: null,
          },
          width: null,
          height: null,
          status: null,
        },
        obj_imagem3: {
          absolutePosition: {
            x: null,
            y: null,
          },
          width: null,
          height: null,
          status: null,
        },
        obj_marca_dagua: {
          text: "",
          status: false,
        },
      },
    };
  },

  computed: {
    pageSizes() {
      return [
        "4A0",
        "2A0",
        "A0",
        "A1",
        "A2",
        "A3",
        "A4",
        "A5",
        "A6",
        "A7",
        "A8",
        "A9",
        "A10",
        "B0",
        "B1",
        "B2",
        "B3",
        "B4",
        "B5",
        "B6",
        "B7",
        "B8",
        "B9",
        "B10",
        "C0",
        "C1",
        "C2",
        "C3",
        "C4",
        "C5",
        "C6",
        "C7",
        "C8",
        "C9",
        "C10",
        "RA0",
        "RA1",
        "RA2",
        "RA3",
        "RA4",
        "SRA0",
        "SRA1",
        "SRA2",
        "SRA3",
        "SRA4",
        "EXECUTIVE",
        "FOLIO",
        "LEGAL",
        "LETTER",
        "TABLOID",
      ];
    },
    layoutTipos() {
      return [
        {
          text: "Fatura",
          value: "fatura",
        },
        {
          text: "Fatura Unica",
          value: "fatura_unica",
        },
        {
          text: "Nota de Credito",
          value: "notaDeCredito",
        },
        {
          text: "Recibo de Dinero",
          value: "recibo",
        },
      ];
    },
    pageOrientations() {
      return [
        {
          text: "Retrato",
          value: "portrait",
        },
        {
          text: "Paisagem",
          value: "landscape",
        },
      ];
    },
    rules() {
      return [
        (value) =>
          !value || value.size < 2000000 || "Imagem deve ser menor que 2 MB!",
      ];
    },

    disableAdd() {
      let result = false;

      if (
        !this.layout.tipo ||
        !this.layout.items_por_pagina ||
        !this.layout.image ||
        !this.layout.obj_folha.pageSize ||
        !this.layout.obj_folha.pageOrientation ||
        !this.layout.obj_imagem1.absolutePosition.x ||
        !this.layout.obj_imagem1.absolutePosition.y ||
        !this.layout.obj_imagem1.width ||
        !this.layout.obj_imagem1.height ||
        !this.layout.obj_imagem1.status
      ) {
        result = true;
      }

      return this.loading || result;
    },
  },

  methods: {
    setImage(event) {
      const file = event;
      if (!file) return;

      if (!file.type.includes("image/")) {
        this.$toast.error(this.$tc("global.selecioneimagem"));
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.layout.image = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.$toast.error(
          "FileReader API não suportada, atualize seu navegador!"
        );
      }
    },

    createPdfLayout() {
      this.loading = true;
      postPdfLayout(this.layout)
        .then((response) => {
          if (response.status === 201) {
            this.$router.push({ path: "/configuracoes/pdf-layouts" });
          }
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$toast.error(this.$tc("global.jaexiste"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
