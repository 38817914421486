<template>
  <v-dialog
    v-model="dialogAddEditGiros"
    @click:outside="close"
    @keydown.esc="close"
    :max-width="
      !selectedCliente || (prepararGiro && editar) ? '800px' : '1200px'
    "
    scrollable
  >
    <v-card flat>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{
          editar
            ? $tc("global.editar") + " Giros"
            : !editar && prepararGiro
            ? $tc("global.preparar") + " Giros"
            : $tc("global.add") + " Giros"
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card flat style="overflow: auto" :loading="loading">
        <v-card-text>
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense>
              <v-col cols="12" md="5">
                <SearchClientes
                  v-if="!loading"
                  :cliente_sync.sync="selectedCliente"
                  :cliente_nome="giro.cliente_nome"
                  :cliente_id="giro.cliente_id"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="giro.telefone"
                  :rules="formRules"
                  :label="$tc('global.telefone')"
                  dense
                  :readonly="readonly"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-combobox
                  v-model="selectedTipo"
                  :label="$tc('global.tipo')"
                  :items="tipos"
                  :rules="formRules"
                  item-text="nome"
                  item-value="nome"
                  dense
                  :readonly="readonly"
                  outlined
                ></v-combobox>
              </v-col>

              <v-col cols="12" :md="existeJuros ? '3' : '6'">
                <v-text-field
                  v-model="giro.historico"
                  :label="$tc('global.comprovante')"
                  :rules="formRules"
                  dense
                  :readonly="readonly"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col v-if="existeJuros" cols="12" md="3">
                <v-select
                  v-model="selectedTimbrado"
                  :items="timbrados"
                  :label="$tc('global.timbrado')"
                  item-text="timbrado"
                  item-value="id"
                  dense
                  outlined
                >
                  <template v-slot:selection="{ item }">
                    <span>{{
                      `${item.descricao ? item.descricao : ""} ${
                        item.timbrado
                      } `
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{
                      `${item.descricao ? item.descricao : ""} ${
                        item.timbrado
                      } `
                    }}</span>
                  </template></v-select
                >
              </v-col>

              <v-col cols="12" md="3">
                <v-combobox
                  v-model="selectedMoeda"
                  :label="$tc('global.moeda', 2)"
                  :items="moedas"
                  :rules="formRules"
                  item-text="sigla"
                  item-value="id"
                  dense
                  readonly
                  outlined
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="valor"
                  ref="valor"
                  :label="$tc('global.valor')"
                  :rules="formRules"
                  :readonly="
                    !editValor && !editar && prepararGiro ? true : false
                  "
                  dense
                  outlined
                  v-currency="vCurrencyOptions"
                >
                  <template v-if="!editar && prepararGiro" v-slot:append>
                    <v-btn
                      @click="editValor = true"
                      v-if="!editValor"
                      icon
                      x-small
                    >
                      <v-icon> mdi-pencil-outline </v-icon>
                    </v-btn>

                    <v-btn @click="salvaValor()" v-if="editValor" icon x-small>
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <SelectContasGiros
            v-if="selectedCliente && !editar"
            :selectedCliente.sync="selectedCliente"
            :totalValor.sync="totalValor"
            :setContas.sync="setContas"
          />
        </v-card-text>
      </v-card>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="actionOk"
          :loading="loading"
          color="button_2"
          class="white--text"
          depressed
          :disabled="disableButton"
          small
        >
          {{
            editar
              ? $tc("global.editar")
              : !editar && prepararGiro
              ? $tc("global.preparar")
              : $tc("global.add")
          }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";
import { postGiro, putGiro, prepararGiro } from "@/api/giros/giros.js";
import { mapState } from "vuex";

export default {
  name: "DialogAddEditGiros",

  components: {
    SearchClientes: () => import("@/components/fields/SearchClientes.vue"),
    SelectContasGiros: () => import("./SelectContasGiros.vue"),
  },

  props: {
    dialogAddEditGiros: {
      type: Boolean,
      default: false,
    },
    prepararGiro: {
      type: Boolean,
      default: false,
    },
    editar: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    origem: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      editValor: false,
      giro: {},
      valor: null,
      validForm: true,
      loading: true,
      readonly: null,
      timbrado_id: null,
      cliente: false,
      formRules: [inputRequired],
      selectedMoeda: null,
      selectedCliente: null,
      selectedTipo: null,
      selectedTimbrado: null,
      giro_original: {},
      totalValor: null,
      setContas: [],
      tipos: [
        { nome: "GIROS TIGO" },
        { nome: "BILHETERIA PERSONAL" },
        { nome: "GIROS CLARO" },
        { nome: "VISIÓN GIROS" },
      ],
    };
  },

  watch: {
    setContas() {
      this.totalValor = this.setContas.reduce(
        (accumulator, item) => accumulator + Number(item.saldo),
        0
      );
    },

    valor() {
      if (this.valor && this.giro.valor != this.valor) {
        this.giro.valor = this.$ci.parse(this.valor, this.vCurrencyOptions);
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.giro.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedCliente() {
      if (this.selectedCliente) {
        this.giro.cliente_id = this.selectedCliente.id;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.giro.tipo = this.selectedTipo.nome;
      }
    },
    selectedTimbrado() {
      if (this.selectedTimbrado) {
        this.timbrado_id = this.selectedTimbrado;
      }
    },
  },

  computed: {
    disableButton() {
      let disabled = true;
      if (this.validForm) {
        disabled = false;
      }
      if (this.prepararGiro && !this.editar) {
        if (this.selectedCliente && this.totalValor == this.giro.valor) {
          disabled = false;
        } else {
          disabled = true;
        }
      }

      if (this.setContas.length > 0) {
        if (this.totalValor == this.giro.valor) {
          disabled = false;
        } else {
          disabled = true;
        }
      }

      return disabled;
    },

    vCurrencyOptions() {
      return {
        precision:
          this.selectedMoeda && this.selectedMoeda.sigla == "G$"
            ? 0
            : undefined,

        distractionFree: false,
      };
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    existeJuros() {
      let result = false;
      if (this.setContas.length > 0) {
        this.setContas.forEach((conta) => {
          if (conta.juros > 0) {
            result = true;
          }
        });
      }

      return result;
    },
  },

  methods: {
    actionOk() {
      if (this.editar) {
        // console.log("editar");
        this.editarGiro();
      } else if (
        !this.editar &&
        this.prepararGiro | (this.setContas.length >= 1)
      ) {
        // console.log("prepararGiro");
        this.preparandoGiro();
      } else {
        // console.log("novo");
        this.novoGiro();
      }
    },

    salvaValor() {
      this.giro.valor = this.$ci.parse(this.valor, this.vCurrencyOptions);
      this.editValor = false;
    },
    close() {
      this.$emit("update:dialogAddEditGiros", false);
      this.$emit("update:editar", false);
      this.$emit("update:prepararGiro", false);
      this.editValor = false;
    },
    novoGiro() {
      if (this.$refs.form.validate()) {
        this.loading = false;
        let giro2 = {};

        giro2.cliente_id = this.giro.cliente_id;
        giro2.telefone = this.giro.telefone;
        giro2.moeda_id = this.giro.moeda_id;
        giro2.valor = this.giro.valor;
        giro2.tipo = this.giro.tipo;
        giro2.historico = this.giro.historico;
        giro2.origem = this.origem;
        giro2.origem_id = 0;

        postGiro(giro2)
          .then((response) => {
            if (response.status === 201) {
              this.$emit("fetch-giros");
              this.$emit("update:dialogAddEditGiros", false);
              this.$emit("update:editar", false);
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = true;
          });
      }
    },
    editarGiro() {
      if (this.$refs.form.validate()) {
        if (JSON.stringify(this.giro) == JSON.stringify(this.giro_original)) {
          this.$toast.error("Por favor, faça uma mudança antes de atualizar!");
          this.loading = true;
        } else {
          this.loading = false;
          let giro2 = {};
          giro2.id = this.giro.id;
          giro2.cliente_id = this.giro.cliente_id;
          giro2.telefone = this.giro.telefone;
          giro2.moeda_id = this.giro.moeda_id;
          giro2.valor = this.giro.valor;
          giro2.tipo = this.giro.tipo;
          giro2.historico = this.giro.historico;

          putGiro(giro2.id, giro2)
            .then((response) => {
              if (response.status === 200) {
                this.$emit("fetch-giros");
                this.$emit("update:dialogAddEditGiros", false);
                this.$emit("update:editar", false);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loading = true;
            });
        }
      }
    },
    preparandoGiro() {
      let giro2 = {};
      giro2.id = this.giro.id;
      giro2.cliente_id = this.giro.cliente_id;
      giro2.telefone = this.giro.telefone;
      giro2.moeda_id = this.giro.moeda_id;
      giro2.valor = this.giro.valor;
      giro2.tipo = this.giro.tipo;
      giro2.origem = this.origem;
      giro2.historico = this.giro.historico;
      if (this.existeJuros) {
        if (!this.selectedTimbrado) {
          this.$toast.error(this.$tc("global.SelecioneTimbrado"));
          return;
        } else {
          giro2.timbrado_id = this.timbrado_id;
        }
      }

      let contas = [];

      this.setContas.forEach((conta) => {
        contas.push(conta.id);
      });

      giro2.contas = contas;

      prepararGiro(giro2)
        .then((response) => {
          this.$emit("fetch-giros");
          this.$emit("update:prepararGiro", false);
          this.$emit("update:dialogAddEditGiros", false);
        })
        .catch(() => {});
    },

    getTimbrados() {
      this.loadingTimbrado = true;

      return fetchUserTimbrados()
        .then((response) => {
          this.timbrados = response;
          this.loadingTimbrado = false;
        })
        .catch((error) => {
          this.loadingTimbrado = false;
        });
    },
  },

  async mounted() {
    this.getTimbrados();
    this.selectedMoeda = this.moedas.find((moeda) => moeda.sigla == "G$");
    if (this.editar || this.prepararGiro) {
      this.giro = { ...this.item };
      this.giro_original = { ...this.item };

      this.selectedTipo = this.tipos.find(
        (tipo) => tipo.nome == this.giro.tipo
      );
      this.$ci.setValue(this.$refs.valor, this.giro.valor);
      this.loading = false;
    } else {
      this.loading = false;
    }

    if (this.prepararGiro) {
      this.readonly = true;
    } else {
      this.readonly = false;
    }
  },
};
</script>

<style></style>
