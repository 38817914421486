<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
          {{ $tc("global.timbrado") }}
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogUserTimbrado = !dialogUserTimbrado"
            depressed
            fab
            x-small
            class="white--text"
            color="button_1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider v-if="usersTimbrados && usersTimbrados.length"></v-divider>
        <v-card-text
          v-if="usersTimbrados && usersTimbrados.length"
          class="py-4"
        >
          <v-chip
            v-for="(timbrado, index) in usersTimbrados"
            :key="index"
            class="ma-1"
            label
            close
            close-icon="mdi-delete"
            @click:close="delUserTimbrado(timbrado.id)"
          >
            <v-icon left>mdi-book-information-variant</v-icon>
            {{ timbrado.timbrado + " - " + timbrado.descricao }}
          </v-chip>
        </v-card-text>
        <p v-else class="d-flex justify-center pt-6">
          {{ $tc("global.nenhumtimbradocadastrado") }}
        </p>
        <v-dialog v-model="dialogUserTimbrado" width="100%" max-width="400">
          <v-card
            :loading="loadingUserTimbrado"
            :disabled="loadingUserTimbrado"
          >
            <v-card-title class="subtitle-1 lightgray">
              {{ $tc("global.add") }} {{ $tc("global.timbrado") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogUserTimbrado = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-10">
              <v-select
                v-model="userTimbrado.timbrado_id"
                :items="timbradoNotEqual"
                clearable
                item-text="timbrado"
                item-value="id"
                filled
                dense
                hide-details
                :label="$tc('global.timbrado')"
              >
                <template v-slot:selection="{ item }">
                  <span>{{
                    `${item.descricao ? item.descricao : ""} - ${
                      item.timbrado
                    } `
                  }}</span>
                </template>
                <template v-slot:item="{ item }">
                  <span>{{
                    `${item.descricao ? item.descricao : ""} - ${
                      item.timbrado
                    } `
                  }}</span>
                </template>
              </v-select>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!userTimbrado.timbrado_id"
                color="button_1"
                class="white--text"
                depressed
                small
                @click="criarUserTimbrado"
              >
                {{ $tc("global.add") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import {
  fetchUsersTimbrado,
  postUsersTimbrados,
  deleteUsersTimbrado,
} from "@/api/usuarios/usuarios.js";
import { fetchTimbradoNotEqual } from "@/api/timbrado/timbrado.js";

export default {
  name: "TimbradoUsuario",

  props: {
    user_id: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      loadingUserTimbrado: false,
      dialogUserTimbrado: false,
      timbradoNotEqual: [],
      usersTimbrados: [],
      userTimbrado: {
        timbrado_id: null,
      },
    };
  },

  methods: {
    getTipoTimbradoNotEqual() {
      fetchTimbradoNotEqual(this.user_id)
        .then((response) => {
          this.timbradoNotEqual = response;
        })
        .catch((error) => {});
    },
    getUsersTimbrados() {
      fetchUsersTimbrado(`?users_id=${this.user_id}`)
        .then((response) => {
          this.usersTimbrados = response;
        })
        .catch((error) => {});
    },

    criarUserTimbrado() {
      this.userTimbrado.users_id = this.user_id;
      this.loadingUserTimbrado = true;

      postUsersTimbrados(this.userTimbrado)
        .then((response) => {
          if (response.status === 201) {
            this.userTimbrado = {};
            this.getUsersTimbrados();
            this.getTipoTimbradoNotEqual();
            this.dialogUserTimbrado = false;
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingUserTimbrado = false;
        });
    },
    delUserTimbrado(id) {
      deleteUsersTimbrado(id)
        .then((response) => {
          if (response.status === 200) {
            this.getUsersTimbrados();
            this.getTipoTimbradoNotEqual();
          }
        })
        .catch((error) => {});
    },
  },

  mounted() {
    this.getTipoTimbradoNotEqual();
    this.getUsersTimbrados();
  },
};
</script>

<style></style>
