import { fetchCaixaAfUser } from "@/api/caixas/caixas_af.js";
import Vue from "vue";

import { i18n } from "@/plugins/i18n";
const routes = [
  {
    path: "/caixas-pdv/:caixa_id",
    props: true,
    component: () => import("@/views/dashboard/caixas-pdv/CaixaAf.vue"),
    meta: {
      menu: "dashboard",
      title: "Caixa PDV",
      breadcrumb: [{ text: "global.home", to: { path: "/" } }],
      voltar: { path: "/caixas" },
      permission: { componente: "CaixasAf" },
    },
    beforeEnter: async (to, from, next) => {
      let allow_user = await fetchCaixaAfUser(to.params.caixa_id);

      if (allow_user.result === 1) {
        next();
      } else {
        next("/caixas");
        Vue.prototype.$toast.error(i18n.tc("global.acessonegado"));
      }
    },
  },
];

export default routes;
