<template>
  <v-dialog
    v-model="dialogAddEditTransferencia"
    @click:outside="$emit('update:dialogAddEditTransferencia', false)"
    @keydown.esc="$emit('update:dialogAddEditTransferencia', false)"
    width="1000px"
    scrollable
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.nova") + " " + $tc("global.transferenciaEstoque")
            : $tc("global.atualizar") + " " + $tc("global.transferenciaEstoque")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddEditTransferencia', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-6 py-8">
        <v-row>
          <!-- Deposito Origem -->
          <v-col cols="12" md="6">
            <v-combobox
              v-model="selectedDepositoOrigem"
              :label="`${$tc('global.deposito')} ${$tc('global.origem')}`"
              :items="depositosOrigem"
              item-text="descricao"
              item-value="id"
              dense
              :readonly="edit && selectedDepositoOrigem ? true : false"
              hide-details
              outlined
              :clearable="edit && selectedDepositoOrigem ? false : true"
            ></v-combobox>
          </v-col>

          <!-- Deposito Destino -->
          <v-col cols="12" md="6">
            <v-combobox
              v-model="selectedDepositoDestino"
              :label="`${$tc('global.deposito')} ${$tc('global.destino')}`"
              :items="depositosDestino"
              item-text="descricao"
              item-value="id"
              dense
              :readonly="edit && selectedDepositoDestino ? true : false"
              hide-details
              outlined
              :clearable="edit && selectedDepositoDestino ? false : true"
            ></v-combobox>
          </v-col>

          <!-- Observação -->
          <v-col cols="12">
            <v-textarea
              v-model="obs"
              :label="$tc('global.observacao')"
              :rows="2"
              :readonly="transferencia && transferencia.status === 2"
              outlined
              hide-details
            ></v-textarea>
          </v-col>

          <!-- Busca Produto -->
          <v-col v-if="!edit" cols="12">
            <SearchProdutos
              :produto_sync.sync="selectedProduto"
              ref="buscaProduto"
              typeComp="busca_deposito"
              :deposito_id="
                selectedDepositoOrigem ? selectedDepositoOrigem.id : null
              "
              :clear_search.sync="clear_search"
              :disableAll="!selectedDepositoOrigem ? true : false"
            />
          </v-col>

          <!-- Produtos Transferir -->
          <v-col cols="12">
            <v-card outlined>
              <v-card-title
                class="text-body-2 justify-center px-3 py-2 lightgray"
              >
                {{ $tc("global.transferenciaEstoque") }}
              </v-card-title>

              <v-divider></v-divider>

              <v-card-text class="pa-0">
                <v-data-table
                  :headers="headers"
                  :items="transferencia_items"
                  :items-per-page="-1"
                  class="data-tables"
                  hide-default-footer
                  disable-sort
                >
                  <template v-slot:item.qtde="{ item }">
                    <div class="d-flex align-center">
                      <v-text-field
                        v-if="transferencia && transferencia.status !== 2"
                        v-model.number="item.qtde"
                        append-outer-icon="mdi-plus"
                        @click:append-outer="
                          item.qtde + 1 <= item.disponivel ? item.qtde++ : ''
                        "
                        prepend-icon="mdi-minus"
                        @click:prepend="item.qtde > 1 ? item.qtde-- : ''"
                        solo
                        flat
                        outlined
                        dense
                        class="input-center"
                        hide-details
                        height="25px"
                        :error="item.qtde > item.disponivel ? true : false"
                      ></v-text-field>
                      <span v-else>{{ item.qtde }}</span>
                    </div>
                  </template>

                  <template v-slot:item.actions="{ index }">
                    <v-btn
                      v-if="transferencia && transferencia.status !== 2"
                      icon
                      @click="removeProdutoTransferir(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="edit && enableCampoJustificativa">
            <!-- justificativa -->
            <v-textarea
              v-model="justificativa"
              :label="$tc('global.justificativa')"
              :rows="1"
              :readonly="transferencia && transferencia.status === 2"
              outlined
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider v-if="transferencia.status !== 2"></v-divider>

      <v-card-actions
        v-if="!loading && transferencia.status !== 2"
        class="px-6 py-4"
      >
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            edit &&
            transferencia &&
            transferencia.status === 1 &&
            enableCampoJustificativa
          "
          color="button_1"
          class="white--text"
          depressed
          :loading="loading"
          small
          :disabled="disabledAddEdit"
          @click="atualizarTransferencia(false)"
        >
          {{ $tc("global.salvar") }}
          <v-icon right>{{ "mdi-content-save" }}</v-icon>
        </v-btn>

        <v-btn
          v-if="transferencia && transferencia.status !== 2"
          color="button_2"
          class="white--text"
          depressed
          :loading="loading"
          small
          :disabled="disabledAddEdit"
          @click="edit ? atualizarTransferencia(true) : criarTransferencia()"
        >
          {{
            !edit
              ? $tc("global.nova") + " " + $tc("global.transferenciaEstoque")
              : $tc("global.atualizar") +
                " " +
                $tc("global.transferenciaEstoque")
          }}
          <v-icon right>{{ !edit ? "mdi-plus" : "mdi-content-save" }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      @close="closePdfDialog"
      title="PDF"
    />
  </v-dialog>
</template>

<script>
import { fetchDepositosList } from "@/api/produtos/produtos_depositos.js";
import {
  postTransferenciaEstoque,
  fetchTransferenciaEstoque,
  confirmarTransferenciaEstoque,
  salvarTransferenciaEstoque,
} from "@/api/transferenciaEstoque";
const generateTransferenciaEstoque = () =>
  import(
    /* webpackChunkName: "transferenciaEstoquePdf" */ "@/components/recibo/reports/reciboTransferenciaEstoquePdf.js"
  );

export default {
  name: "DialogAddEditTransferencia",

  components: {
    SearchProdutos: () => import("@/components/fields/SearchProdutos.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  props: {
    dialogAddEditTransferencia: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item_id: {
      type: Number,
    },
  },

  data() {
    return {
      loading: true,
      depositos: null,
      selectedDepositoOrigem: null,
      selectedDepositoDestino: null,
      selectedProduto: null,
      transferencia_items: [],
      transferencia: {},
      obs: null,
      clear_search: false,
      pdfDialog: false,
      pdfData: {},
      justificativa: null,
      transferencia_items_qtde_original: "",
    };
  },

  computed: {
    depositosOrigem() {
      if (this.selectedDepositoDestino) {
        return this.depositos.filter(
          (deposito) => deposito.id != this.selectedDepositoDestino.id
        );
      } else {
        return this.depositos;
      }
    },

    depositosDestino() {
      if (this.selectedDepositoOrigem) {
        return this.depositos.filter(
          (deposito) => deposito.id != this.selectedDepositoOrigem.id
        );
      } else {
        return this.depositos;
      }
    },

    disabledAddEdit() {
      let result = false;

      if (
        !this.selectedDepositoOrigem ||
        !this.selectedDepositoDestino ||
        !this.transferencia_items.length
      ) {
        result = true;
      }
      if (this.transferencia && this.transferencia.status === 2) {
        result = true;
      }

      this.transferencia_items.forEach((item) => {
        if (item.qtde > item.disponivel) {
          result = true;
        }
      });

      if (this.edit && this.enableCampoJustificativa && !this.justificativa) {
        result = true;
      }

      return result;
    },

    enableCampoJustificativa() {
      let result = false;
      if (this.transferencia.justificativa) {
        result = true;
      }
      if (this.transferencia_items) {
        let trans_itens = this.transferencia_items.reduce(
          (accumulator, item, index) =>
            `${accumulator}${item.qtde}${
              index != this.transferencia_items.length - 1 ? " , " : ""
            }`,
          ""
        );
        if (trans_itens != this.transferencia_items_qtde_original) {
          result = true;
        }
      }

      return result;
    },

    headers() {
      return [
        {
          text: "id",
          value: "id",
          width: "70px",
          divider: true,
        },
        {
          text: this.$tc("global.produto"),
          value: "descricao",
          divider: true,
        },
        {
          text: this.$tc("global.disponivel"),
          value: "disponivel",
          width: "120px",
          divider: true,
        },
        {
          text: "qtde",
          value: "qtde",
          width: "180px",
          divider: true,
        },
        {
          text: "",
          align: "center",
          value: "actions",
          width: "80px",
        },
      ];
    },
  },

  watch: {
    selectedProduto() {
      if (!this.selectedProduto) return;
      let item_existente = false;
      this.transferencia_items.some((item) => {
        if (item.id == this.selectedProduto.id) item_existente = true;
        return;
      });
      if (!item_existente) {
        this.transferencia_items.push({
          id: this.selectedProduto.id,
          descricao: this.selectedProduto.descricao,
          disponivel: this.selectedProduto.disponivel,
          qtde: 1,
        });
        this.clear_search = !this.clear_search;
      } else {
        this.$toast.warning("Produto ja adicionado!");
        this.clear_search = !this.clear_search;
      }
    },
  },

  methods: {
    async getDepositos() {
      const response = await fetchDepositosList();
      this.depositos = response;
    },

    removeProdutoTransferir(index) {
      this.transferencia_items.splice(index, 1);
    },

    async criarTransferencia() {
      try {
        this.loading = true;

        const transferencia = {
          produtos_depositos_id_origem: this.selectedDepositoOrigem.id,
          produtos_depositos_id_destino: this.selectedDepositoDestino.id,
          obs: this.obs,
          items: this.transferencia_items,
        };

        const response = await postTransferenciaEstoque(transferencia);

        if (response.status == 201) {
          this.$emit("update:dialogAddEditTransferencia", false);
          this.$emit("fetch-transferencias");
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },

    async atualizarTransferencia(bolean) {
      if (bolean) {
        this.loading = true;
        const transferencia = {
          justificativa: this.justificativa,
          obs: this.obs,
          items: this.transferencia_items,
        };

        const response = await confirmarTransferenciaEstoque(
          this.item_id,
          transferencia
        );

        if (response.status == 200) {
          await generateTransferenciaEstoque().then((module) => {
            let generate = module.generateReciboTransferenciaEstoque;
            generate(this.item_id)
              .then((response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              })
              .finally(() => {
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;
              });
          });
        }
      } else {
        this.loading = true;
        const transferencia = {
          justificativa: this.justificativa,
          obs: this.obs,
          items: this.transferencia_items,
        };

        const response = await salvarTransferenciaEstoque(
          this.item_id,
          transferencia
        );
        if (response.status == 200) {
          this.$emit("update:dialogAddEditTransferencia", false);
          this.$emit("fetch-transferencias");
        }
      }
    },

    async getTransferencia() {
      const response = await fetchTransferenciaEstoque(this.item_id);
      this.transferencia = { ...response };

      this.selectedDepositoOrigem = this.depositos.find((deposito) => {
        return deposito.id === response.produtos_depositos_id_origem;
      });
      this.selectedDepositoDestino = this.depositos.find((deposito) => {
        return deposito.id === response.produtos_depositos_id_destino;
      });
      this.obs = response.obs;
      this.justificativa = response.justificativa;
      this.transferencia_items = response.items;
      this.transferencia_items_qtde_original = response.items.reduce(
        (accumulator, item, index) =>
          `${accumulator}${item.qtde}${
            index != response.items.length - 1 ? " , " : ""
          }`,
        ""
      );
    },

    closePdfDialog() {
      this.$emit("update:dialogAddEditTransferencia", false);
      this.$emit("fetch-transferencias");
    },
  },

  async mounted() {
    this.loading = true;
    await this.getDepositos();
    if (this.edit) {
      await this.getTransferencia();
    }

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.input-center ::v-deep {
  .v-input__control {
    justify-content: center;
  }

  .v-input__slot {
    min-height: 20px !important;
  }

  input {
    text-align: center;
  }
}
</style>
