import { format, parseISO } from "date-fns";

export function dateFormat(date, formato) {
  if (date) {
    return format(parseISO(date), formato);
  } else {
    return "";
  }
}
export function peso(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat("es-AR", {
    
      currency: "ARS",
    }).format(valor);
  } else {
    return "";
  }
}

export function number(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat({
      maximumFractionDigits: 2,
    }).format(valor);
  } else {
    return "";
  }
}

export function excerpt(text, number) {
  if (!text) return;
  if (text.length > number) {
    return text.substr(0, number) + "...";
  } else {
    return text;
  }
}

