<template>
  <v-container fluid>
    <div class="d-flex mb-6">
      {{ produto.id }} - {{ produto.descricao }} - Ref.
      {{ produto.referencia }}
      <v-spacer></v-spacer>
      <v-btn @click="updateProduto" color="button_1" class="white--text">
        <v-icon left>mdi-check</v-icon>
        {{ $tc("global.salvar") }}
      </v-btn>
    </div>
    <v-row class="mb-6" dense>
      <v-col cols="12" md="2">
        <v-card outlined class="fill-height d-flex align-center justify-center">
          <v-img v-if="img" :src="img" contain></v-img>
          <v-img
            v-else
            :src="require('@/assets/pagina-default.png')"
            contain
          ></v-img>
          <v-btn fab dark x-small absolute bottom right @click="onButtonClick">
            <input
              accept="image/*"
              type="file"
              class="d-none"
              ref="uploader"
              @change="onFileChanged"
            />
            <v-icon>mdi-image</v-icon>
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-row dense>
          <!-- Descrição -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="produto.descricao"
              :label="this.$tc('global.descricao')"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Classificações -->
          <v-col cols="12" md="4">
            <v-select
              v-model="produto.produto_categoria_id"
              :items="categorias"
              :loading="loadingClass"
              clearable
              item-text="descricao"
              item-value="id"
              dense
              outlined
              :label="this.$tc('global.categoria')"
            ></v-select>
          </v-col>
          <!-- Referencia -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="produto.referencia"
              :label="this.$tc('global.referencia')"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Kit -->
          <v-col cols="12" md="3">
            <v-card
              outlined
              height="40px"
              class="pa-3 d-flex justify-space-between align-center"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-1"
              >
                {{ $tc("global.produtoKit") }}
              </v-subheader>
              <div class="d-flex align-center">
                {{
                  produto.produto_kit ? $tc("global.sim") : $tc("global.nao")
                }}
                <v-switch
                  class="pl-2"
                  :false-value="false"
                  :true-value="true"
                  disabled
                  v-model="produto.produto_kit"
                ></v-switch>
              </div>
            </v-card>
          </v-col>
          <!-- Url -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="produto.url"
              label="Url"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- Moeda Custo -->
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedMoeda"
              :items="moedas"
              clearable
              dense
              outlined
              item-text="sigla"
              item-value="id"
              :label="this.$tc('global.moeda') + ' ' + this.$tc('global.custo')"
              :disabled="moeda_empresa ? true : false"
              return-object
            ></v-select>
          </v-col>
          <!-- Fornecedor -->
          <v-col cols="12" md="3">
            <v-select
              v-model="produto.fornecedor_id"
              :items="fornecedores"
              :loading="loadingClass"
              item-text="nome"
              item-value="id"
              dense
              outlined
              :label="$tc('global.fornecedor')"
            ></v-select>
          </v-col>
          <!-- tipo imposto -->
          <v-col cols="12" md="4">
            <v-select
              v-model="produto.tipo_imposto"
              :items="tipo_imposto"
              clearable
              dense
              item-text="descricao"
              item-value="value"
              outlined
              :label="$tc('global.tipo') + ' ' + $tc('global.imposto')"
            ></v-select>
          </v-col>
          <!-- Descrição detalhada -->
          <v-col cols="12">
            <v-textarea
              v-model="produto.descricao_detalhada"
              :label="this.$tc('global.descricaodetalhada')"
              :rows="3"
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="!editar_kit">
      <div class="d-flex my-2">
        <v-spacer></v-spacer>
        <v-btn small @click="editar_kit = true" class="primary">
          <v-icon left>mdi-pencil</v-icon>
          {{ $tc("global.editar") }} Kit</v-btn
        >
      </div>
      <div>
        <v-data-table
          :headers="headers"
          :items="produtos_kit"
          :loading="loading"
          :items-per-page="15"
          class="data-tables"
          :class="{ 'data-tables__row-click': PProdutoVisualizar }"
        >
          <template v-slot:item.descricao="{ item }">
            <v-tooltip top v-if="item.img" class="py-1" max-width="200">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.descricao }}
                  <v-btn
                    v-if="item.url"
                    :href="item.url"
                    target="_blank"
                    icon
                    x-small
                    class="ml-1"
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-card max-height="200px" max-width="200px">
                <v-img :src="item.img"> </v-img>
              </v-card>
            </v-tooltip>
            <div v-else>
              {{ item.descricao }}
              <v-btn
                v-if="item.url"
                :href="item.url"
                target="_blank"
                icon
                x-small
                class="ml-1"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.produto_kit="{ item }">
            <span v-if="item.produto_kit"> Kit </span>
            <span v-else>{{ $tc("global.produto") }}</span>
          </template>
          <template v-slot:item.preco="{ item }">
            <div>G$ {{ item.preco | guarani }}</div>
          </template>
          <template v-slot:item.cif="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.cif | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.cif | currency }}</div>
          </template>
          <template v-slot:item.fob="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.fob | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.fob | currency }}</div>
          </template>
          <template v-slot:item.ucif="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.ucif | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.ucif | currency }}</div>
          </template>
          <template v-slot:item.ufob="{ item }">
            <div v-if="item.custo_moeda === 'G$'">
              {{ item.custo_moeda }} {{ item.ufob | guarani }}
            </div>
            <div v-else>{{ item.custo_moeda }} {{ item.ufob | currency }}</div>
          </template>
        </v-data-table>
      </div>
    </div>
    <div v-else>
      <ProdutosKit v-if="editar_kit" :produtoSelected.sync="produtoSelected" />
    </div>
  </v-container>
</template>

<script>
import {
  getAllProClassAtivo,
  putProdutosKit,
  getAllProdutosKit,
} from "@/api/produtos/produtos.js";

import { mapGetters, mapState } from "vuex";
// import { fetchMoedas } from "@/api/geral/moedas.js";
import { inputRequired } from "@/helpers/utils/";
import { getFornecedores } from "@/api/fornecedores/fornecedores.js";

export default {
  name: "ProdutoCadastro",

  props: {
    item: {
      type: Object,
      required: true,
    },
    getProduto: {
      type: Function,
    },
  },

  components: {
    ProdutosKit: () => import("../components/ProdutosKit.vue"),
  },

  data() {
    return {
      loading: false,
      categorias: [],
      fornecedores: [],
      selectedFornecedor: null,
      produtoKitSemPreco: false,
      formRules: [inputRequired],
      loadingClass: false,
      selectedMoeda: null,
      img: null,
      imgUpload: null,
      unidades: [
        {
          text: this.$tc("global.metros"),
          value: "MTS",
        },
        {
          text: this.$tc("global.unidade", 2),
          value: "UND",
        },
        {
          text: "KG",
          value: "KGS",
        },
      ],

      produto: {},
      tipo_imposto: [
        {
          descricao: "ISENTO",
          value: "ISENTO",
        },
        {
          descricao: "GRAVADO 10 %",
          value: "GRAVADO_10",
        },
        {
          descricao: "GRAVADO 5 %",
          value: "GRAVADO_5",
        },
        {
          descricao: "DECRETO",
          value: "DECRETO",
        },
      ],
      loadingKit: false,
      produtos_kit: [],
      editar_kit: false,
      produtoSelected: [],
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PProdutoVisualizar() {
      return this.getAccess("Produtos", "visualizar");
    },

    ProdutosColunasVisualizar() {
      return this.getAccess("ProdutosColunas", "visualizar");
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    vCurrencyOptions2() {
      return {
        precision: 0,
        distractionFree: false,
      };
    },
    headers() {
      let headers = [
        {
          text: this.$tc("global.id"),
          width: "80px",
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.categoria"),
          width: "115px",
          value: "categoria",
        },
        {
          text: this.$tc("global.qtd") + " " + "Kit",
          width: "100px",
          value: "qtde",
        },
        {
          text: this.$tc("global.preco"),
          width: "100px",
          value: "preco",
        },
      ];
      if (this.ProdutosColunasVisualizar) {
        headers.push(
          {
            text: this.$tc("global.custoMedio") + " CIF",
            width: "150px",
            value: "cif",
          },
          {
            text: this.$tc("global.custoMedio") + " FOB",
            width: "160px",
            value: "fob",
          },
          {
            text: this.$tc("global.ultimo") + " CIF",
            width: "150px",
            value: "ucif",
          },
          {
            text: this.$tc("global.ultimo") + " FOB",
            width: "150px",
            value: "ufob",
          }
        );
      }
      return headers;
    },
  },

  watch: {},

  selectedFornecedor() {
    if (this.selectedFornecedor) {
      this.produto.fornecedor_id = this.selectedFornecedor.id;
    }
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.imgUpload = file;
      this.produto.img = !this.produto.img ? "img" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.img = event.target.result;
        // vue.editFornecedor();
      };
    },

    fetchFornecedores() {
      this.loadingClass = true;
      return getFornecedores()
        .then((response) => {
          this.fornecedores = response;
          this.loadingClass = false;
          this.selectedFornecedor = this.fornecedores.find(
            (fornecedor) => fornecedor.id === this.produto.fornecedor_id
          );
        })
        .catch((error) => console.log(error));
    },

    getProClass() {
      this.loadingClass = true;
      return getAllProClassAtivo()
        .then((response) => {
          this.categorias = response;
          this.loadingClass = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingClass = false;
        });
    },

    fetchProdutosKit() {
      this.loadingKit = true;
      return getAllProdutosKit(this.produto.id)
        .then((response) => {
          this.produtos_kit = response;
          this.produtoSelected = response;
          this.loadingKit = false;
        })
        .catch(() => {
          this.loadingKit = false;
        });
    },

    updateProduto() {
      const produto = new FormData();

      if (this.imgUpload) {
        produto.append("img", this.imgUpload, this.imgUpload.name);
        // produto.append("img64", this.img);
      }

      let produto_2 = {};
      produto_2.empresa_id = this.produto.empresa_id;
      produto_2.produto_categoria_id = this.produto.produto_categoria_id;
      produto_2.descricao = this.produto.descricao;
      produto_2.descricao_detalhada = this.produto.descricao_detalhada;
      produto_2.referencia = this.produto.referencia;
      produto_2.fornecedor_id = this.produto.fornecedor_id;
      produto_2.custo_moeda_id = this.produto.custo_moeda_id;

      produto_2.und = this.produto.und;
      produto_2.qtde_caixa = this.produto.qtde_caixa;
      produto_2.estoque_min = this.produto.estoque_min;
      produto_2.estoque_max = this.produto.estoque_max;
      produto_2.url = this.produto.url;
      produto_2.controle_estoque = this.produto.controle_estoque;
      produto_2.tipo_imposto = this.produto.tipo_imposto;

      for (let key in produto_2) {
        if (
          produto_2[key] !== null &&
          produto_2[key] !== undefined &&
          produto_2[key] !== ""
        ) {
          produto.append(key, produto_2[key]);
        }
      }

      let produtos_kits = [];
      this.produtoSelected.forEach((produto) => {
        let produto_kit = {};
        produto_kit.id = produto.id;
        produto_kit.qtde = produto.qtde;
        produto_kit.preco = this.$ci.parse(
          produto.preco,
          this.vCurrencyOptions2
        );
        produtos_kits.push(produto_kit);
      });

      this.produtoKitSemPreco = this.produtoSelected.some(
        (produto) => this.$ci.parse(produto.preco, this.vCurrencyOptions) <= 0
      );

      produto.append(
        "produtos_kit_selecionados",
        JSON.stringify(produtos_kits)
      );

      if (!this.produtoKitSemPreco) {
        putProdutosKit(this.produto.id, produto)
          .then(() => {
            this.getProduto();
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error("Referência já existente!");
            }
          });
      } else {
        this.$toast.error(this.$tc("global.produtoKitValorInvalido"));
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.produto = { ...this.item };

    this.getProClass();
    this.img = this.produto.img;
    await this.fetchFornecedores();
    if (this.produto.iva_porcentagem == 10) {
      this.produto.tipo_imposto = this.tipo_imposto.find((imposto) => {
        return imposto.value == "GRAVADO_10";
      }).value;
    } else if (this.produto.iva_porcentagem == 5) {
      this.produto.tipo_imposto = this.tipo_imposto.find((imposto) => {
        return imposto.value == "GRAVADO_5";
      }).value;
    } else {
      this.produto.tipo_imposto = this.tipo_imposto.find((imposto) => {
        return imposto.value == "ISENTO";
      }).value;
    }
    this.selectedMoeda = this.moedas.find(
      (moeda) => moeda.id_moeda === this.moeda_empresa
    );
    if (this.selectedMoeda) {
      this.produto.custo_moeda_id = this.selectedMoeda.id_moeda;
    }
    this.fetchProdutosKit();

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.input-center ::v-deep input {
  text-align: center;
}

.input-right ::v-deep input {
  text-align: right;
}
</style>
