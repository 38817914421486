import { fetchCaixaAf } from "@/api/caixas/caixas_af.js";
import Vue from "vue";

const initialState = () => ({
  caixa_af: null,
});

const state = initialState();

const getters = {};

const actions = {
  async getCaixaAf({ dispatch, commit }, caixa_id) {
    const response = await fetchCaixaAf(caixa_id);

    commit("UPDATE_CAIXA_AF", response);
  },

  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  UPDATE_CAIXA_AF(state, payload) {
    state.caixa_af = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
