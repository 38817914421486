import api from "../axios_service.js";

export async function fetchGiros(filtros) {
  const response = await api.get(`/giros${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchGiro(id) {
  const response = await api.get(`/giros/${id}`);
  return response.data.result;
}

export function putGiro(id, giro) {
  return api.put(`/giros/${id}`, giro);
}

export function prepararGiro( giro) {
  return api.put("/preparar-giros/preparar", giro);
}
export function baixarGiro(id, giro) {
  return api.put(`/baixar-giros/${id}`, giro);
}

export function postGiro(body) {
  return api.post("/giros/add", body);
}




