const routes = [
  {
    path: "/configuracoes/empresa",
    component: () => import("@/views/configuracoes/empresa/Empresa.vue"),
    props: true,
    meta: {
      menu: "configuracoes",
      title: "Empresa",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "dynamic_crumb" },
      ],
      permission: { componente: "Empresas" },
    },
  },
];

export default routes;
