var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4",attrs:{"flat":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-package-variant-plus")]),_vm._v(" "+_vm._s(_vm.$tc("global.produtoKit"))+" "),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"pt-4 font-weight-bold text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$tc("global.selecioneProdutosKits"))+" ")]),_c('v-text-field',{ref:"search",staticClass:"py-4",attrs:{"placeholder":_vm.$tc('global.buscarProdutosPorDescricao'),"dense":"","outlined":"","clearable":"","hide-details":"","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-tables",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.produtos,"items-per-page":15,"search":_vm.search,"show-select":"","fixed-header":"","height":"calc(100vh - 375px)"},model:{value:(_vm.produtoSelected),callback:function ($$v) {_vm.produtoSelected=$$v},expression:"produtoSelected"}})],1)],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"pt-4 mb-4 font-weight-bold text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$tc("global.produto", 2))+" Kit "+_vm._s(_vm.$tc("global.selecionado", 2))+" ")]),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers_kit,"items":_vm.produtoSelected,"hide-default-footer":"","items-per-page":-1,"fixed-header":"","disable-sort":"","height":"calc(100vh - 258px)"},scopedSlots:_vm._u([{key:"item.qtde",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{key:_vm.i,staticClass:"pa-2",attrs:{"placeholder":_vm.$tc('global.qtd'),"outlined":"","dense":"","hide-details":"","append-outer-icon":"mdi-plus","prepend-icon":"mdi-minus"},on:{"click:append-outer":function($event){return _vm.plusItemKit(item, index)},"click:prepend":function($event){return _vm.minusItemKit(item, index)}},model:{value:(item.qtde),callback:function ($$v) {_vm.$set(item, "qtde", _vm._n($$v))},expression:"item.qtde"}})]}},{key:"item.preco",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.vCurrencyOptions),expression:"vCurrencyOptions"}],key:_vm.i,staticClass:"pa-2",attrs:{"placeholder":_vm.$tc('global.preco'),"outlined":"","dense":"","error":item.preco <= 0,"hide-details":""},model:{value:(item.preco),callback:function ($$v) {_vm.$set(item, "preco", $$v)},expression:"item.preco"}})]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteKitItem(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }