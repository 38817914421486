<template>
  <v-dialog
    v-model="dialogAddEditListaPrecos"
    @click:outside="$emit('update:dialogAddEditListaPrecos', false)"
    @keydown.esc="$emit('update:dialogAddEditListaPrecos', false)"
    max-width="400"
  >
    <v-card tile v-if="!loading">
      <v-card-title class="text-subtitle-1 lightgray">
        {{
          !edit
            ? $tc("global.add") + " " + $tc("global.lista")
            : $tc("global.atualizar") + " " + $tc("global.lista")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddEditListaPrecos', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6" v-if="!loading">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <!-- Descrição -->
          <v-text-field
            v-model="lista.descricao"
            :label="$tc('global.descricao')"	
            outlined
            :readonly="edit ? !PListaPrecoEditar : false"
            :rules="formRules"
            required
            dense
          ></v-text-field>
          <!-- Status -->
          <v-card
            v-if="edit"
            outlined
            height="40px"
            class="pa-3 d-flex justify-space-between align-center mt-4"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 body-1"
            >
              Status
            </v-subheader>
            <v-switch
              class="ma-0 pa-0"
              hide-details
              :false-value="0"
              :readonly="edit ? !PListaPrecoEditar : false"
              :true-value="1"
              v-model="lista.status"
            ></v-switch>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="edit ? !PListaPrecoEditar : false || !validForm"
          depressed
          small
          @click="!edit ? createLista() : updateListaPrecos()"
        >
          {{ !edit ? $tc("global.add") : $tc("global.atualizar") }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  postListaPrecos,
  putListaPrecos,
} from "@/api/listas-precos/listasprecos.js";
import { mapGetters } from "vuex";
export default {
  name: "DialogLista",

  props: {
    dialogAddEditListaPrecos: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      lista: {
        status: 1,
      },
      validForm: true,
      formRules: [inputRequired],
      lista_original: {},
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PListaPrecoEditar() {
      return this.getAccess("ListasPreco", "editar");
    },
  },

  methods: {
    createLista() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        postListaPrecos(this.lista)
          .then(() => {
            this.loading = false;

            this.$emit("fetch-listas");
            this.$emit("update:dialogAddEditListaPrecos", false);
          })
          .catch((error) => {
            this.$toast.error("Erro ao criar a lista!");

            this.loading = false;
            this.$emit("update:dialogAddEditListaPrecos", false);
          });
      }
    },

    updateListaPrecos() {
      if (JSON.stringify(this.lista) === JSON.stringify(this.lista_original)) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        putListaPrecos(this.lista.id, this.lista)
          .then(() => {
            this.loading = false;
            this.lista_original = { ...this.lista };

            this.$emit("fetch-listas");
            this.$emit("update:dialogAddEditListaPrecos", false);
          })
          .catch((error) => {
            this.$toast.error("Erro ao atualizar lista!");
            console.log(error);

            this.loading = false;
            this.$emit("update:dialogAddEditListaPrecos", false);
          });
      }
    },
  },

  mounted() {
    this.loading = true;
    if (this.edit) {
      this.lista = { ...this.item };
      this.lista_original = { ...this.item };
    }
    this.loading = false;
  },
};
</script>

<style></style>
