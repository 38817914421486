import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "./plugins/i18n";
import * as filters from "./filters"; // global filters
import mixins from "./mixins"; // global mixins
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLodash from "vue-lodash";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import Clipboard from "v-clipboard";
import VueCurrencyFilter from "vue-currency-filter";
import * as VueGoogleMaps from "vue2-google-maps";
import VueMask from "v-mask";
import VueCurrencyInput from "vue-currency-input";
import Storage from "vue-ls";

const toast_options = {
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.SUCCESS]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-check",
      },
    },
    [TYPE.WARNING]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert-circle-outline",
      },
    },
    [TYPE.INFO]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert-circle-outline",
      },
    },
    [TYPE.ERROR]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert-outline",
      },
    },
  },
};

const lodash_options = { lodash: { cloneDeep, isEqual, isEmpty, orderBy } };

const currency_filter_options = [
  {
    // default name 'currency'
    symbol: "",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolSpacing: false,
  },
  {
    // default name 'currency_2'
    name: "guarani",
    symbol: "",
    thousandsSeparator: ".",
    fractionCount: 0,
    fractionSeparator: ",",
    symbolSpacing: false,
  },
];

const currency_input_options = {
  /* see config reference */
  globalOptions: {
    autoDecimalMode: false,
    currency: null,
    distractionFree: false,
  },
};

const maps_options = {
  load: {
    key: "AIzaSyC2qFoRmof0EBEpBWx2JS-BnU_8hkwaF2c",
  },
};

const storage_options = {
  namespace: "tocsAdmPy__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local", // storage name session, local, memory
};

Vue.use(Storage, storage_options);
Vue.use(Toast, toast_options);
Vue.use(VueLodash, lodash_options);
Vue.use(Clipboard);
Vue.use(VueCurrencyFilter, currency_filter_options);
Vue.use(VueGoogleMaps, maps_options);
Vue.use(VueCurrencyInput, currency_input_options);

Vue.use(VueMask);

Vue.config.productionTip = false;

Vue.component("PageToolbar", () =>
  import("@/components/global/PageToolbar.vue")
);
Vue.component("NaoAutorizado", () =>
  import("@/components/global/NaoAutorizado.vue")
);
Vue.component("Carregando", () => import("@/components/global/Carregando.vue"));
Vue.component("PdfDialogComp", () =>
  import("@/components/global/PdfDialogComp.vue")
);

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// register global utility mixins
Vue.mixin(mixins);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
