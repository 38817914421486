import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { layoutEmpresa } from "@/views/dashboard/relatorios/config/layoutEmpresa.js";
import { fetchOneRecibo } from "@/api/recibos/recibos.js";

import store from "@/store/modules/usuario.store.js";

export async function generateRecibo(recibo_id) {
  const recibo = await fetchOneRecibo(recibo_id);
  const empresa = await fetchEmpresa(store.state.empresa.id);
  const bodyPDF = await layoutEmpresa();
  const cabecalho = JSON.stringify(bodyPDF.cabecalhoRecibo);
  const cabecalhoRecibo2 = JSON.parse(cabecalho);
  return new Promise((resolve, reject) => {
    let docDefinition = {};

    let recibo_info = recibo;

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              // fillColor: "#F5F5F5",
              margin: [12, 5, 2, 2],
              border: [true, true, false, false],
              stack: [
                {
                  image: empresa.logo64 ? empresa.logo64 : "",
                  width: 60,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],

              stack: [
                {
                  text: empresa.nome,
                  fontSize: 12,
                  bold: true,
                },
                {},
                {
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  text:
                    "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  text:
                    "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                },
              ],
              fontSize: 9,
              alignment: "left",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "RUC: " + (empresa.documento ? empresa.documento : ""),

                  alignment: "center",
                  fontSize: 10,
                },
                {
                  text: recibo_info.recibo_fiscal
                    ? "RECIBO DE DINERO"
                    : "RECIBO",

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text:
                    "FECHA: " +
                    format(parseISO(recibo_info.data_recibo), "dd/MM/yyyy"),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text: "Nº.: " + recibo_info.numero_recibo,

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let cabecalhoDuplicado = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              // fillColor: "#F5F5F5",
              margin: [12, 5, 2, 2],
              border: [true, true, false, false],
              stack: [
                {
                  image: empresa.logo64 ? empresa.logo64 : "",
                  width: 60,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],

              stack: [
                {
                  text: empresa.nome,
                  fontSize: 12,
                  bold: true,
                },
                {},
                {
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  text:
                    "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  text:
                    "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                },
              ],
              fontSize: 9,
              alignment: "left",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "RUC: " + (empresa.documento ? empresa.documento : ""),

                  alignment: "center",
                  fontSize: 10,
                },
                {
                  text: recibo_info.recibo_fiscal
                    ? "RECIBO DE DINERO"
                    : "RECIBO",

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text:
                    "FECHA: " +
                    format(parseISO(recibo_info.data_recibo), "dd/MM/yyyy"),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text: "Nº.: " + recibo_info.numero_recibo,

                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    // ---------------------------------------------------------------
    let detalleDelFactura = {
      colSpan: 3,
      border: [true, false, true, false],
      margin: [40, 0, 0, 0],
      table: {
        widths: [50, 50, 50, 50],
        body: [
          [
            {
              text: "FECHA",
              fontSize: 8,
            },
            {
              text: "Nº. CONTA",
              fontSize: 8,
            },
            {
              text: "IMPORTE",
              fontSize: 8,
            },
            {
              text: "CUOTA",
              fontSize: 8,
              // border: [true, false, true, false],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    if (recibo_info.historico_pagamento) {
      recibo_info.historico_pagamento.itens.forEach((item, index) => {
        detalleDelFactura.table.body.push([
          {
            text: format(parseISO(item.created_at), "dd/MM/yyyy"),
            fontSize: 8,
          },
          {
            text: item.conta_a_pagar_id,
            fontSize: 8,
          },
          {
            text:
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor_pago)
                : Vue.filter("currency")(item.valor_pago),
            fontSize: 8,
          },
          {
            text: item.conta_receber_numero_parcela,
            fontSize: 8,
          },
        ]);
      });
    } else {
      detalleDelFactura = {
        colSpan: 3,
        text: "",
        border: [true, false, true, false],
      };
    }
    // ---------------------------------------------------------------
    let detalleDelFactura2 = {
      colSpan: 3,
      border: [true, false, true, false],
      margin: [40, 0, 0, 0],
      table: {
        widths: [50, 50, 50, 50],
        body: [
          [
            {
              text: "FECHA",
              fontSize: 8,
            },
            {
              text: "Nº. CONTA",
              fontSize: 8,
            },
            {
              text: "IMPORTE",
              fontSize: 8,
            },
            {
              text: "CUOTA",
              fontSize: 8,
              // border: [true, false, true, false],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    if (recibo_info.historico_pagamento) {
      recibo_info.historico_pagamento.itens.forEach((item, index) => {
        detalleDelFactura2.table.body.push([
          {
            text: format(parseISO(item.created_at), "dd/MM/yyyy"),
            fontSize: 8,
          },
          {
            text: item.conta_a_pagar_id,
            fontSize: 8,
          },
          {
            text:
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor_pago)
                : Vue.filter("currency")(item.valor_pago),
            fontSize: 8,
          },
          {
            text: item.conta_receber_numero_parcela,
            fontSize: 8,
          },
        ]);
      });
    } else {
      detalleDelFactura2 = {
        colSpan: 3,
        text: "",
        border: [true, false, true, false],
      };
    }
    // ---------------------------------------------------------------
    let detalleDelRecibo = {
      colSpan: 3,
      border: [true, false, true, false],
      margin: [40, 0, 0, 0],
      table: {
        widths: [100, 100, 100],
        body: [
          [
            {
              text: "FORMA DE PAGO",
              fontSize: 8,
            },
            {
              text: "MONEDA",
              fontSize: 8,
            },
            {
              text: "IMPORTE",
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    if (recibo_info.historico_pagamento) {
      recibo_info.historico_pagamento.formas_pagamento.forEach((item) => {
        detalleDelRecibo.table.body.push([
          {
            text: item.forma_pagamento_descricao,
            fontSize: 8,
          },
          {
            text: item.moeda,
            fontSize: 8,
          },
          {
            text:
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor)
                : Vue.filter("currency")(item.valor),
            fontSize: 8,
          },
        ]);
      });
    } else {
      detalleDelRecibo.table.body.push([
        {
          text: "EFECTIVO",
          fontSize: 8,
        },
        {
          text: recibo_info.moeda_sigla,
          fontSize: 8,
        },
        {
          text:
            recibo_info.moeda_sigla == "G$"
              ? Vue.filter("guarani")(recibo_info.valor_recibo)
              : Vue.filter("currency")(recibo_info.valor_recibo),
          fontSize: 8,
        },
      ]);
    }
    // ---------------------------------------------------------------
    let detalleDelRecibo2 = {
      colSpan: 3,
      border: [true, false, true, false],
      margin: [40, 0, 0, 0],
      table: {
        widths: [100, 100, 100],
        body: [
          [
            {
              text: "FORMA DE PAGO",
              fontSize: 8,
            },
            {
              text: "MONEDA",
              fontSize: 8,
            },
            {
              text: "IMPORTE",
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    if (recibo_info.historico_pagamento) {
      recibo_info.historico_pagamento.formas_pagamento.forEach((item) => {
        detalleDelRecibo2.table.body.push([
          {
            text: item.forma_pagamento_descricao,
            fontSize: 8,
          },
          {
            text: item.moeda,
            fontSize: 8,
          },
          {
            text:
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor)
                : Vue.filter("currency")(item.valor),
            fontSize: 8,
          },
        ]);
      });
    } else {
      detalleDelRecibo2.table.body.push([
        {
          text: "EFECTIVO",
          fontSize: 8,
        },
        {
          text: recibo_info.moeda_sigla,
          fontSize: 8,
        },
        {
          text:
            recibo_info.moeda_sigla == "G$"
              ? Vue.filter("guarani")(recibo_info.valor_recibo)
              : Vue.filter("currency")(recibo_info.valor_recibo),
          fontSize: 8,
        },
      ]);
    }
    // ---------------------------------------------------------------
    let corpo = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "start",
              fontSize: 8,
              margin: [20, 20, 0, 10],
              text: "NOME: " + recibo_info.quem_pagou,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, false, false],
              text: "CONCEPTO: " + recibo_info.concepto_descricao,
              fontSize: 8,
              alignment: "start",
              margin: [20, 10, 0, 10],
              colSpan: 2,
            },
            {},
            {
              border: [false, false, true, false],
              text: recibo_info.historico_pagamento
                ? recibo_info.historico_pagamento.plano_conta_descricao +
                  " " +
                  recibo_info.historico_pagamento.descricao_pagamento.toUpperCase()
                : "",
              fontSize: 8,
              alignment: "right",
              margin: [0, 10, 20, 0],
            },
          ],
          [detalleDelFactura, {}, {}],
          [
            {
              border: [true, false, true, false],
              text: "DETALLES DEL PAGO",
              fontSize: 8,
              alignment: "start",
              margin: [20, 10, 0, 10],
              colSpan: 3,
            },
            {},
            {},
          ],
          [detalleDelRecibo, {}, {}],
          [
            {
              text:
                "____________________________ \n Autorizado por: " +
                recibo_info.quem_pagou,
              fontSize: 8,
              alignment: "center",
              margin: [0, 20, 0, 20],
              border: [true, false, false, false],
            },

            {
              text: "",
              margin: [0, 20, 0, 20],
              border: [false, false, false, false],
            },
            {
              text:
                "____________________________ \n Firma: " +
                recibo_info.quem_assina,
              fontSize: 8,
              alignment: "center",
              margin: [0, 20, 0, 20],
              border: [false, false, true, false],
            },
          ],
          [
            {
              text:
                "Este documento fue registrado por: " +
                recibo_info.usuario_nome +
                " el dia " +
                format(parseISO(recibo_info.data_recibo), "dd/MM/yyyy"),
              border: [true, true, false, true],
              margin: [0, 0, 0, 0],
              colSpan: 2,
              fontSize: 8,
            },
            {},
            {
              text: "ORIGINAL",
              alignment: "right",
              fontSize: 8,
              margin: [0, 0, 0, 0],
              border: [false, true, true, true],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    // ---------------------------------------------------------------
    let pula = {
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-40, -40, 0, -40],
              border: [false, false, false, false],
              text: "\n\n\n---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n\n\n\n",
            },
          ],
        ],
      },
    };
    // ---------------------------------------------------------------
    let corpo2 = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "start",
              fontSize: 8,
              margin: [20, 20, 0, 10],
              text: "NOME: " + recibo_info.quem_pagou,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, false, false],
              text: "CONCEPTO: " + recibo_info.concepto_descricao,
              fontSize: 8,
              alignment: "start",
              margin: [20, 10, 0, 10],
              colSpan: 2,
            },
            {},
            {
              border: [false, false, true, false],
              text: recibo_info.historico_pagamento
                ? recibo_info.historico_pagamento.plano_conta_descricao +
                  " " +
                  recibo_info.historico_pagamento.descricao_pagamento.toUpperCase()
                : "",
              fontSize: 8,
              alignment: "right",
              margin: [0, 10, 20, 0],
            },
          ],
          [detalleDelFactura2, {}, {}],
          [
            {
              border: [true, false, true, false],
              text: "DETALLES DEL PAGO",
              fontSize: 8,
              alignment: "start",
              margin: [20, 10, 0, 10],
              colSpan: 3,
            },
            {},
            {},
          ],
          [detalleDelRecibo2, {}, {}],
          [
            {
              text:
                "____________________________ \n Autorizado por: " +
                recibo_info.quem_pagou,
              fontSize: 8,
              alignment: "center",
              margin: [0, 20, 0, 20],
              border: [true, false, false, false],
            },

            {
              text: "",
              margin: [0, 20, 0, 20],
              border: [false, false, false, false],
            },
            {
              text:
                "____________________________ \n Firma: " +
                recibo_info.quem_assina,
              fontSize: 8,
              alignment: "center",
              margin: [0, 20, 0, 20],
              border: [false, false, true, false],
            },
          ],
          [
            {
              text:
                "Este documento fue registrado por: " +
                recibo_info.usuario_nome +
                " el dia " +
                format(parseISO(recibo_info.data_recibo), "dd/MM/yyyy"),
              border: [true, true, false, true],
              margin: [0, 0, 0, 0],
              colSpan: 2,
              fontSize: 8,
            },
            {},
            {
              text: "DUPLICADO",
              alignment: "right",
              fontSize: 8,
              margin: [0, 0, 0, 0],
              border: [false, true, true, true],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    // ---------------------------------------------------------------
    let geral = [cabecalho, corpo, pula, cabecalhoDuplicado, corpo2];

    docDefinition = {
      info: {
        title: "Recibo",
        author: "ADM",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [...geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
