const routes = [
  {
    path: "/super-admin/audits",
    component: () => import("@/views/super_admin/audits/Audits.vue"),
    meta: {
      component: "Auditoria",
      menu: "super_admin",
      title: "Audits",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.auditoria", to: { path: "/super-admin/audits" } },
      ],
      voltar: { path: "/super-admin/usuarios" },
    },
  },
];

export default routes;
