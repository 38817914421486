<template>
  <v-card class="mb-4" flat width="100%" :loading="loading">
    <v-card-text>
      <v-data-table
        :headers="headers"
        v-model="antecipoSelected"
        :items="antecipos"
        :items-per-page="15"
        item-key="id"
        :show-select="true"
        hide-default-footer
        disable-sort
        single-select
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat("dd/MM/yyyy") }}
        </template>
        <template v-slot:item.valor="{ item }">
          <div v-if="item.moeda == 'G$'">
            {{ item.valor | guarani }}
          </div>
          <div v-else>
            {{ item.valor | currency }}
          </div>
        </template>
        <template v-slot:item.valor_utilizado="{ item }">
          <div v-if="item.moeda == 'G$'">
            {{ item.valor_utilizado | guarani }}
          </div>
          <div v-else>
            {{ item.valor_utilizado | currency }}
          </div>
        </template>
        <template v-slot:item.saldo="{ item }">
          <div v-if="item.moeda == 'G$'">
            {{ item.saldo | guarani }}
          </div>
          <div v-else>
            {{ item.saldo | currency }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        small
        color="button_2"
        class="white--text"
        @click="pushPagamento"
        :disabled="disableAddPagamento"
      >
        {{ $t("global.add") }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { fetchAntecipos } from "@/api/antecipos/index.js";

export default {
  name: "BaixaAntecipo",

  props: {
    valor_restante: {
      type: Number,
      default: 0,
    },
    moeda_venda: {
      type: String,
      default: null,
    },
    antecipos_adicionados: {
      type: Array,
    },
    cliente_id: {
      type: Number,
    },
  },

  data() {
    return {
      antecipo: null,
      loading: false,
      antecipos: [],
      antecipoSelected: [],
    };
  },

  watch: {
    antecipoSelected() {
      if (this.antecipoSelected) {
        this.antecipo = this.antecipoSelected[0];
      }
    },
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Cambio", {
      cambio: (state) => state.cambio,
    }),

    headers() {
      return [
        {
          text: "id",
          value: "id",
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente",
        },
        {
          text: this.$tc("global.formapagamento"),
          value: "forma_pagamento",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda",
          align: "right",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
        },
        {
          text: this.$tc("global.valorutilizado"),
          value: "valor_utilizado",
          align: "right",
        },
        {
          text: this.$tc("global.saldo"),
          value: "saldo",
          align: "right",
        },
      ];
    },
    disableAddPagamento() {
      let result = false;
      if (!this.antecipo) {
        result = true;
      }
      return result;
    },
  },

  methods: {
    getAntecipos() {
      this.loading = true;
      fetchAntecipos(`?cliente=${this.cliente_id}`)
        .then((response) => {
          this.antecipos = response;
          this.antecipos.map((item) => {
            if (item.saldo == 0) {
              item.isSelectable = false;
            }

            this.antecipos_adicionados.forEach((item_adicionado) => {
              if (item.id == item_adicionado) {
                item.isSelectable = false;
              }
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    pushPagamento() {
      this.$emit("update:antecipo", this.antecipo);
      this.$emit("push-antecipo");
    },
  },

  mounted() {
    this.getAntecipos();
  },
};
</script>

<style></style>
