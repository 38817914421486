<template>
  <v-card>
    <v-card-title class="pa-3 subtitle-1 tocs_gray_1">
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        solo
        flat
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        @click="dialogAddPermissao = true"
        class="white--text mt-4 mt-sm-0"
        color="button_1"
      >
        <v-icon left>mdi-plus</v-icon> {{ $tc("global.add") }}
      </v-btn>
    </v-card-title>

    <v-row class="">
      <v-col cols="12">
        <v-treeview
          v-if="!loading"
          :items="permissoes"
          :search="search.length >= 3 ? search : ''"
          :open.sync="open"
          item-text="descricao"
          item-key="id"
          item-children="children"
          hoverable
          activatable
          transition
          dense
          return-object
          class="data__row-click px-3 pt-3"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon>
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <div @click="goToPermissao(item)" class="text-body-2">
              {{ item.descricao }}
            </div>
          </template>
          <template v-slot:append="{ item }">
            <div class="d-flex justify-end align-center">
              <div class="d-none d-md-block text-caption">
                {{ item.componente }}
              </div>

              <!-- <v-btn
                class="ml-8"
                @click="goToPermissao(item)"
                small
                depressed
                icon
            
              >
                <v-icon color="darkblue" size="23">
                  mdi-chevron-right
                </v-icon>
              </v-btn> -->
            </div>
          </template>
        </v-treeview>
        <Carregando v-else />
      </v-col>
    </v-row>
    <Permissao
      v-if="dialogPermissao"
      :dialogPermissao.sync="dialogPermissao"
      :permissao_id="permissao_id"
      @fetched-permissoes="fetchPermissoes"
    />
    <NovaPermissao
      v-if="dialogAddPermissao"
      :dialogAddPermissao.sync="dialogAddPermissao"
      @fetched-permissoes="fetchPermissoes"
    />
  </v-card>
</template>

<script>
import { getPermissoes } from "@/api/permissoes/permissoes.js";
// import { mapGetters } from "vuex";

export default {
  name: "ListaPermissoes",

  components: {
    Permissao: () => import("../Permissao.vue"),
    NovaPermissao: () => import("../NovaPermissao.vue"),
  },

  data() {
    return {
      dialogAddPermissao: false,
      search: "",
      loading: false,
      permissoes: [],
      dialogPermissao: false,
      open: [],
      permissao_id: null,
    };
  },

  watch: {},

  methods: {
    goToPermissao(item) {
      this.dialogPermissao = true;
      this.permissao_id = item.id;
    },

    fetchPermissoes(filtro) {
      this.loading = true;
      return getPermissoes(filtro ? filtro : "")
        .then((response) => {
          this.permissoes = response;
          this.permissoes.forEach((permissao) => {
            this.open.push(permissao.id);
            permissao.children.forEach((child) => {
              this.open.push(child.id);
            });
          });
          this.loading = false;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.fetchPermissoes();
  },
};
</script>

<style scoped lang="scss"></style>
