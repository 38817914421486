import api from "../axios_service.js";
export async function getUsuariosPermissoes() {
  const response = await api.get("/usuariospermissoes");
  return response.data.userpermissao;
}

export async function getUsuarioPermissoes(id) {
  const response = await api.get(`/usuariospermissoes/${id}`);
  return response.data.userpermissao;
}

export function putPermissaoUsuario(id, userPermissao) {
  return api.put(`/usuariospermissoes/${id}`, {
    bits: userPermissao.bits,
    valor: userPermissao.valor,
    operador: userPermissao.operador,
  });
}

export function postPermissaoUsuario(userPermissao, userId, permissaoId) {
  return api.post("/usuariospermissoes/add", {
    permissao_id: permissaoId,
    user_id: userId,
    bits: userPermissao.bits,
  });
}
