<template>
  <v-dialog
    v-model="dialogFaturarCompras"
    @click:outside="$emit('update:dialogFaturarCompras', false)"
    @keydown.esc="$emit('update:dialogFaturarCompras', false)"
    width="600px"
  >
    <v-card-title class="tocs_gray_1">
      <span class="primary--text font-weight-bold subtitle-1">
        <v-icon class="mx-1"> mdi-credit-card-fast-outline </v-icon>
        {{ $tc("global.faturar") }}
      </span>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('update:dialogFaturarCompras', false)"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-stepper tile v-model="stepper">
      <v-stepper-header>
        <v-stepper-step step="1"> Dados Compra </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2"> Despesas </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="pa-0" step="1">
          <v-card tile>
            <v-card-text class="pa-6">
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-card outlined class="pa-3 ma-2 subtitle-1 d-flex">
                    <span class="font-weight-bold">{{
                      $tc("global.documento") + ": "
                    }}</span>
                    <v-sheet color="tabs2" class="ml-1 px-2">
                      {{ compra.ncontrol ? compra.ncontrol : compra.invoice }}
                    </v-sheet>
                    <v-spacer></v-spacer>

                    <span class="font-weight-bold">
                      {{ $tc("global.valor") + ": " }}
                    </span>
                    <v-sheet color="tabs2" class="ml-1 px-2">
                      {{ compra.moeda_sigla }}
                      <span v-if="compra.moeda_sigla === 'G$'">
                        {{ compra.total | guarani }}
                      </span>
                      <span v-else>
                        {{ compra.total | currency }}
                      </span>
                    </v-sheet>
                  </v-card>
                </v-col>

                <v-col v-if="compra.condpago === 2" cols="12">
                  <v-card outlined class="pa-3 ma-2 subtitle-1">
                    <div class="d-flex">
                      {{ $tc("global.formapagamento") + ": " }}
                      <v-spacer></v-spacer>
                      <div class="d-flex align-center mb-2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"
                              ><v-menu
                                ref="menu"
                                v-model="menu_picker"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                outlined
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    style="max-width: 150px"
                                    :value="dateFormated"
                                    class="text-body-2 mx-2"
                                    label="Primeira Parcela"
                                    append-icon="mdi-calendar"
                                    @click:append="menu_picker = true"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="data_vencimento"
                                  type="date"
                                  no-title
                                  scrollable
                                >
                                </v-date-picker>
                              </v-menu>
                            </span>
                          </template>
                          <span>
                            As outras parcelas são geradas com 30 dias de
                            diferença
                          </span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      {{ $tc("global.aPrazo") }}
                      x{{ compra.numero_parcelas }}
                      <v-spacer></v-spacer>

                      <v-btn
                        @click="openGerarParcelas"
                        small
                        class="white--text"
                        color="button_2"
                        :disabled="notDataVencimento || parcelas.length > 0"
                      >
                        <v-icon left>
                          mdi-numeric-1-box-multiple-outline
                        </v-icon>
                        Gerar Parcelas
                      </v-btn>
                    </div>
                  </v-card>
                  <TabelaParcelasCompras
                    v-if="dialogParcelarCompras"
                    :dialogParcelarCompras.sync="dialogParcelarCompras"
                    :compra="compra"
                    :data_vencimento="data_vencimento"
                    :parcelas_compra.sync="parcelas"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <v-card outlined class="pa-3 ma-2 subtitle-1">
                    <v-combobox
                      v-model="selectedCaixa"
                      :items="caixas"
                      :label="$tc('global.caixa')"
                      item-text="descricao"
                      item-value="id"
                      hide-details
                      dense
                      flat
                      outlined
                      class="input-center"
                      required
                    >
                    </v-combobox>

                    <v-combobox
                      v-model="selectedFormaPagamento"
                      :label="$tc('global.formapagamento')"
                      :items="formas_pagamentos"
                      :loading="loading"
                      dense
                      class="mt-4"
                      item-text="descricao"
                      item-value="id"
                      outlined
                    ></v-combobox>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="button_1"
                class="white--text"
                small
                @click="stepper = 2"
                :disabled="proximoPasso"
              >
                Próximo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card
            v-for="(despesa, i) in despesas"
            :key="i"
            class="mb-12"
            outlined
          >
            <div class="pa-6">
              <div class="d-flex">
                <span class="font-weight-bold">{{
                  $tc("global.documento") + ": "
                }}</span>
                <v-sheet color="tabs2" class="ml-1 px-2">
                  {{ despesa.ncontrole }}
                </v-sheet>

                <v-spacer></v-spacer>
                <span>
                  {{
                    despesa.condpago === 1
                      ? $tc("global.aVista")
                      : $tc("global.aPrazo") + " "
                  }}
                </span>

                <span class="d-flex ml-4">
                  <span class="font-weight-bold"
                    >{{ $tc("global.valor") }}:</span
                  >
                  {{ despesa.moeda_valor_origem_sigla }}
                  <v-sheet color="tabs2" class="ml-1 px-2">
                    <span v-if="despesa.moeda_valor_origem_sigla === 'G$'">
                      {{ despesa.valor_origem | guarani }}
                    </span>
                    <span v-else>
                      {{ despesa.valor_origem | currency }}
                    </span>
                  </v-sheet>
                </span>
              </div>
              <v-row class="mt-4" v-if="despesa.condpago === 1">
                <v-col cols="12" md="6">
                  <v-select
                    v-model="despesa.forma_pagamento_id"
                    :label="$tc('global.formapagamento')"
                    :items="formas_pagamentos"
                    dense
                    item-text="descricao"
                    item-value="id"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="despesa.caixa_id"
                    :items="allowCaixaDespesa(despesa)"
                    :label="$tc('global.caixa')"
                    item-text="descricao"
                    item-value="id"
                    hide-details
                    dense
                    flat
                    outlined
                    :required="despesa.condpago === 1 ? true : false"
                    class="input-center"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <div class="d-flex">
            <v-btn small class="primary white--text" @click="stepper = 1">
              <v-icon left small>mdi-arrow-left</v-icon>
              {{ $tc("global.voltar") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="verificaFaturar"
              color="button_1"
              class="white--text"
              small
              @click="faturaCompra"
            >
              <v-icon left class="mx-1">mdi-credit-card-fast-outline</v-icon>
              {{ $tc("global.faturar") + " " + $tc("global.compra") }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import { fetchCaixasFaturamento, fetchCaixas } from "@/api/caixas/caixas.js";
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";
import { faturarCompra } from "@/api/compras/compras.js";
import { fetchDespesas } from "@/api/compras/compras_despesas.js";

export default {
  name: "DialogFaturarCompras",

  props: {
    dialogFaturarCompras: {
      type: Boolean,
      default: false,
    },
    compra: {
      type: Object,
    },
    compra_items: {
      type: Array,
    },
  },

  data() {
    return {
      loading: false,
      dialogParcelarCompras: false,
      data_vencimento: format(new Date(), "yyyy-MM-dd"),
      menu_picker: false,
      parcelas: [],
      caixas: [],
      caixas_despesas: [],
      selectedCaixa: null,
      selectedFormaPagamento: null,
      formas_pagamentos: [],
      despesas: [],
      stepper: 1,
      selectedCaixaDespesa: null,
    };
  },

  components: {
    TabelaParcelasCompras: () => import("./TabelaParcelarCompras.vue"),
  },

  computed: {
    dateFormated() {
      let result = "";

      result = format(parseISO(this.data_vencimento), "dd/MM/yyyy");

      return result;
    },

    notDataVencimento() {
      let result = false;

      if (
        this.data_vencimento === null ||
        this.data_vencimento === undefined ||
        this.data_vencimento === ""
      ) {
        result = true;
      }
      return result;
    },

    verificaFaturar() {
      let result = false;

      if (this.compra) {
        if (this.compra.condpago === 2) {
          if (
            this.parcelas.length === 0 ||
            !this.selectedCaixa ||
            !this.selectedFormaPagamento
          ) {
            result = true;
          }
        }
        if (this.compra.condpago === 1) {
          if (!this.selectedCaixa || !this.selectedFormaPagamento) {
            result = true;
          }
        }
        if (this.despesas.length > 0) {
          this.despesas.forEach((despesa) => {
            if (despesa.condpago === 1) {
              if (!despesa.caixa_id || !despesa.forma_pagamento_id) {
                result = true;
              }
            }
          });
        }
      }
      return result;
    },

    proximoPasso() {
      let result = false;

      if (
        (this.compra.condpago === 2 && this.parcelas.length <= 0) ||
        !this.selectedCaixa ||
        !this.selectedFormaPagamento
      ) {
        result = true;
      }
      return result;
    },
  },

  methods: {
    openGerarParcelas() {
      this.dialogParcelarCompras = true;
    },

    getCaixasFaturamento() {
      this.loading = true;

      fetchCaixasFaturamento(`?moeda_id=${this.compra.moeda_id}`)
        .then((response) => {
          this.caixas = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    getCaixasDespesas() {
      this.loading = true;

      fetchCaixas()
        .then((response) => {
          this.caixas_despesas = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    allowCaixaDespesa(item) {
      let caixa_liberado = [];

      this.caixas_despesas.forEach((caixa) => {
        if (caixa.tipo_caixa === "GERAL") {
          caixa_liberado.push(caixa);
        }
        if (caixa.moeda_id === item.moeda_valor_origem_id) {
          caixa_liberado.push(caixa);
        }
      });

      return caixa_liberado;
    },

    faturaCompra() {
      this.loading = true;
      let compra_faturar = {};

      compra_faturar.compra = this.compra;
      compra_faturar.compra_items = this.compra_items;
      compra_faturar.despesas = this.despesas;
      if (this.selectedCaixa.tipo_caixa !== "PDV") {
        compra_faturar.caixa_id = this.selectedCaixa.id;
      } else {
        compra_faturar.caixa_id = this.selectedCaixa.caixa_id;
      }
      compra_faturar.forma_pagamento_id = this.selectedFormaPagamento.id;

      if (this.compra.condpago === 2) {
        compra_faturar.parcelas = this.parcelas;
      }

      faturarCompra(compra_faturar)
        .then(() => {
          this.$toast.success(this.$tc("global.faturaGerada"));
          this.$emit("update:dialogFaturarCompras", false);
          this.$emit("fatura-compra");
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    getFormasPagamentos() {
      this.loading = true;
      fetchFormasPagamentos()
        .then((response) => {
          this.formas_pagamentos = response;
          if (this.compra.condpago === 2) {
            this.selectedFormaPagamento = this.formas_pagamentos.find(
              (forma_pago) => {
                return forma_pago.descricao === "A PRAZO";
              }
            );
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getDespesas() {
      this.loading = true;
      fetchDespesas(`?compra_id=${this.compra.id}`)
        .then((response) => {
          this.despesas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getDespesas();
    this.getCaixasDespesas();
    this.getFormasPagamentos();
    if (this.compra) {
      this.getCaixasFaturamento();
    }
  },
};
</script>

<style lang="scss" scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
