import api from "../axios_service";

export async function fetchListaFornecedor(filtros) {
  const response = await api.get(
    `/relatorios/fornecedores-lista${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchExtratoFornecedor(filtros) {
  const response = await api.get(
    `/relatorios/fornecedores-extrato${filtros ? filtros : ""}`
  );
  return response.data.result;
}
