<template>
  <v-container fluid>
    <PageToolbar
      voltar
      dark
      icon="mdi-domain"
      :title="$tc('global.add') + ' ' + $tc('global.empresa')"
    />

    <v-card class="form-add">
      <v-card-title>
        <h3 class="text-h5 mb-3">{{ $tc("global.empresa") }}</h3>
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="addEmpresa"
          :disabled="!validForm"
          color="button_1"
          class="white--text"
        >
          <v-icon>mdi-check</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="2">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="logo">
                  <v-img :src="logo" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">
                        {{ $tc("global.arrastesolte") }}
                      </h3>
                      <div class="text-caption">
                        {{ $tc("global.cliqueselecionar") }}
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="10">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.lazy="empresa.nome"
                    :label="$tc('global.nome')"
                    outlined
                    dense
                    :rules="formRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.lazy="empresa.localizacao"
                    :label="$tc('global.localizacao')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Moeda -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model.number="empresa.moeda_id"
                    :items="moedas"
                    clearable
                    item-text="sigla"
                    item-value="id"
                    dense
                    outlined
                    :label="$tc('global.moeda')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { postEmpresa } from "@/api/empresas/empresas.js";
import { fetchMoedasAdmin } from "@/api/moedas/moedas.js";
import { inputRequired } from "@/helpers/utils/";

export default {
  name: "SA_NovaEmpresa",

  data() {
    return {
      loading: false,
      validForm: true,
      moedas: [],
      formRules: [inputRequired],
      logo: null,
      logoUpload: null,
      hoverFile: false,
      empresa: {
        nome: null,
        localizacao: null,
        status: 1,
      },
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  methods: {
    fileAdded(file) {
      this.logoUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },

    getMoedas() {
      return fetchMoedasAdmin(`?status=1`)
        .then((response) => {
          this.moedas = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addEmpresa() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const fd = new FormData();

        if (this.logoUpload) {
          fd.append("logo", this.logoUpload, this.logoUpload.name);
          fd.append("logo64", this.logo);
        }

        let empresa = {};
        empresa.nome = this.empresa.nome;
        empresa.moeda_id = this.empresa.moeda_id;
        empresa.localizacao = this.empresa.localizacao;

        for (let key in empresa) {
          if (
            empresa[key] !== null &&
            empresa[key] !== undefined &&
            empresa[key] !== ""
          ) {
            fd.append(key, empresa[key]);
          }
        }

        postEmpresa(fd)
          .then(() => {
            this.$router.push({ path: "/super-admin/empresas" });
          })
          .catch((error) => {
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getMoedas();
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
