const routes = [
  {
    path: "/vendas",
    component: () => import("@/views/dashboard/vendas/Vendas.vue"),
    meta: {
      menu: "dashboard",
      icon: "mdi-tag-outline",
      title: { text: "global.venda", plural: true },
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.venda", plural: true },
      ],
      permission: { componente: "Vendas" },
    },
  },
];

export default routes;
