import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import writtenNumber from "written-number";
import Vue from "vue";
import { format, parseISO } from "date-fns";
import store from "@/store/index.js";
import { es } from 'date-fns/locale';

import { fetchFatura } from "@/api/faturas/faturas.js";

export default async function generateFatura(
  fatura_id,
  print,
  download,
  unica,
  tipo
) {
  const cancelado_img = () =>
    import(/* webpackChunkName: "cancelado_img" */ "./cancelado_img.js");
  const logo_extenso = () =>
    import(/* webpackChunkName: "logo_extenso" */ "./logo_potable_extenso.js");

  let logo_extenso_source = null;

  logo_extenso().then((module) => {
    logo_extenso_source = module.default;
  });
  const empresa = store.state.Usuario.empresa;
  const fatura = await fetchFatura(fatura_id);
  return new Promise((resolve, reject) => {
    let content = [];

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              margin: [0, 0, 0, 0],
              border: [true, true, false, false],
              stack: [
                {
                  image: logo_extenso_source,
                  fit: [120, 120]
                },
              ],
            },
            {
              border: [false, true, true, false],
              alignment: "center",
              margin: [0, 0, 0, 0],
              fontSize: 8,
              stack: [
                {
                  text: empresa.nome,
                  bold: true,
                  fontSize: 12,
                  // margin: [0, 0, 40, 2],
                },
                {
                  text: "de: Monica Gabriela Oleinik De Hahn",
                  fontSize: 10,
                  // margin: [0, 0, 10, 2],
                },
                {
                  text: "Comercio al por menor de artículos de ferretería",
                  bold: true,
                },
                {
                  text: "Comercio al por menor de otros alimentos n.c.p.",
                  bold: true,
                },
                {
                  text: "Comercio al por menor de productos farmacéuticos de uso veterinario",
                  bold: true,
                },
              ],
            },
            {
              border: [false, true, true, false],
              margin: [0, 0, 0, 0],
              stack: [
                {
                  margin: [0, 0, 0, 0],
                  text: "TIMBRADO Nº " + fatura.timbrado,
                  alignment: "center",
                  bold: true,
                  fontSize: 12,
                },
                {
                  text: "Fecha inicio vigencia " + format(parseISO(fatura.inicio_timbrado), "dd",) + ' de ' + format(parseISO(fatura.inicio_timbrado), "MMMM", { locale: es }) + ' de ' + format(parseISO(fatura.inicio_timbrado), "yyyy"),
                  fontSize: 8,
                  alignment: "center",
                },
                {
                  text: "Fecha fin de vigencia " + format(parseISO(fatura.fim_timbrado
                  ), "dd") + ' de ' + format(parseISO(fatura.fim_timbrado
                  ), "MMMM", { locale: es }) + ' de ' + format(parseISO(fatura.fim_timbrado
                  ), "yyyy"),
                  fontSize: 8,
                  alignment: "center",
                },

                {
                  text: "RUC: " + empresa.documento,
                  margin: [0, 0, 0, 5],
                  bold: true,
                  fontSize: 12,
                  alignment: "center",
                },
                {
                  text: "F A C T U R A",
                  margin: [0, 0, 0, 5],
                  bold: true,
                  fontSize: 13,
                  alignment: "center",
                },
                {
                  text: 'Nº ' + fatura.ponto + '-' + fatura.numero.toString().padStart(7, "0"),
                  bold: true,
                  fontSize: 12,
                  alignment: "center",
                },

              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let texto_extra = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-187, -23, 0, 0],
              border: [false, false, false, false],
              stack: [
                {
                  fontSize: 8,
                  text: "Cel.: " + empresa.telefone + ' - ' + empresa.endereco,
                  alignment: "center",
                },
                {
                  fontSize: 8,
                  text: empresa.cidade + ' - ' + empresa.estado + ' - ' + empresa.pais,
                  alignment: "center",
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let infos_cliente = {
      margin: [0, -5, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              border: [true, true, false, true],
              margin: [0, 5, 0, 0],
              fontSize: 8,
              text: "Francisco C. Álvarez     " + format(parseISO(fatura.data_emissao), "dd") + '     de     ' + format(parseISO(fatura.data_emissao), "MMMM", { locale: es }) + '     de     ' + format(parseISO(fatura.data_emissao), "yyyy"),
              colSpan: 2,
            },
            {},
            {
              border: [false, true, true, true],
              margin: [0, 5, 0, 0],
              text: fatura.condpag == 1 ? "CONDICIÓN DE VENTA:CONTADO [ X ] CRÉDITO [  ]" : "CONDICIÓN DE VENTA:CONTADO [  ] CRÉDITO [ X ]",
              fontSize: 8,
              colSpan: 2,
              alignment: "right"
            },
            {},
          ],
          [
            {
              border: [true, true, false, true],
              margin: [0, 5, 0, 0],
              fontSize: 8,
              text: "SEÑOR(ES): " + fatura.cliente_nome,
              colSpan: 3,
            },
            {},
            {},
            {
              border: [false, true, true, true],
              margin: [0, 5, 0, 0],
              text: fatura.cliente_documento ? "C.I o RUC: " + fatura.cliente_documento : "C.I o RUC: ",
              fontSize: 8,
              alignment: "start"
            },
          ],
          [
            {
              border: [true, true, false, false],
              margin: [0, 5, 0, 0],
              fontSize: 8,
              text: fatura.cliente_endereco ? "DIRECCION: " + fatura.cliente_endereco : "DIRECCION: ",
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              text: fatura.cliente_telefone ? "Tel.: " + fatura.cliente_telefone : "Tel.:",
              fontSize: 8,
              margin: [0, 5, 0, 0],
              alignment: "start"
            },
            {
              border: [false, false, true, false],
              margin: [0, 5, 0, 0],
              text: '',
              fontSize: 8,
              alignment: "start"
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let corpo = {
      table: {
        widths: [40, 240, 80, "*", 20, "*"],

        body: [
          [
            {
              margin: [0, 7, 0, 0],
              fontSize: 8,
              text: "CANTIDAD",
              bold: true,
              alignment: "center",

              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "D E S C R I P C I Ó N",
              bold: true,
              alignment: "center",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "PRECIO UNITARIO",
              bold: true,
              alignment: "center",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              text: "VALOR DE VENTA",
              bold: true,
              fillColor: "#EEEEEE",
              alignment: "center",
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {},
            {},
            {},
            {
              text: "EXENTAS",
              bold: true,
              alignment: "center",
              fontSize: 7,
            },
            {
              text: "5%",
              bold: true,
              alignment: "center",
              fontSize: 7,
            },
            {
              text: "10%",
              bold: true,
              alignment: "center",
              fontSize: 7,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let fatura_items = {
      table: {
        widths: [40, 240, 80, "*", 20, "*"],
        border: [true, false, true, false],
        body: [],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let filtro_moeda = "";
    if (fatura.moeda == "G$") {
      filtro_moeda = "guarani";
    } else {
      filtro_moeda = "currency";
    }

    fatura.items.forEach((fatura_item) => {
      let item = [
        {
          text: fatura_item.qntde,
          border: [true, false, true, false],
          alignment: "center",
          fontSize: 7,
        },
        {
          text: fatura_item.descricao,
          border: [true, false, true, false],
          alignment: "left",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_isento),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_5),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_10),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
      ];

      fatura_items.table.body.push(item);
    });

    let total_pagar_extenso = writtenNumber(Number(fatura.valor_total), {
      lang: "es",
    });

    if (
      Number(fatura.valor_total) > 1000000 &&
      Number(fatura.valor_total) < 2000000
    ) {
      total_pagar_extenso = total_pagar_extenso.substring(3);
      total_pagar_extenso = "UN" + total_pagar_extenso;
    }

    let footer = {
      table: {
        widths: [90, 190, 80, "*", 20, "*"],
        border: [true, true, true, true],
        body: [
          [
            {
              text: "DESCUENTOS CONDEDIDOS:",
              fontSize: 8,
              bold: true,
              colSpan: 4,
            },
            {},
            {},
            {},
            {
              text: Vue.filter(filtro_moeda)(fatura.desconto),
              bold: true,
              fontSize: 8,
              alignment: "center",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              margin: [0, 10, 0, -4],
              text: "",
              fontSize: 8,
              colSpan: 2
            },
            {},
            {
              text: "SUB-TOTALES",
              fontSize: 8,
              alignment: "right",
              bold: true,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_exento),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado5),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado10),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
          ],
          [
            {
              text: "TOTAL A PAGAR: " + fatura.moeda,
              fontSize: 8,
              bold: true,
              border: [true, false, false, true]
            },
            {
              text: total_pagar_extenso.toLocaleUpperCase(),
              fontSize: 8,
              colSpan: 3,
              border: [false, false, false, true]
            },
            {},
            {
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total),
              fontSize: 8,
              alignment: "right",
              fillColor: "#EEEEEE",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [true, false, true, true],
              text: "LIQUIDACIÓN DEL IVA:",
              fontSize: 7,
              bold: true,
            },
            {
              border: [false, false, true, true],
              text:
                "(5%) " +
                Vue.filter(filtro_moeda)(fatura.iva_5) +
                " (10%) " +
                Vue.filter(filtro_moeda)(fatura.iva_10),
              fontSize: 8,
              colSpan: 2,
            },
            {},
            {
              text: "TOTAL IVA",
              fontSize: 7,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.total_iva),
              fontSize: 8,
              alignment: "right",
              fillColor: "#EEEEEE",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [true, false, false, true],
              text: "Firma:",
              margin: [0, 2, 0, 0],
              fontSize: 8,
              colSpan: 2,
            },
            {

            },
            {
              border: [false, false, false, true],
              text: "Aclaración de la Firma:",
              margin: [-78, 2, 0, 0],
              fontSize: 8,
              colSpan: 2,
            },
            {

            },
            {
              border: [false, false, true, true],
              text: "C.I. Nº",
              margin: [-19, 2, 0, 0],
              fontSize: 8,
              alignment: "start",
              colSpan: 2,
            },
            {

            },
          ],
          [
            {
              border: [false, false, false, false],
              margin: [0, 0, 0, 20],
              fontSize: 4,
              text: "GRAFICA INTEGRACION DE Joslaine Patricia Erthal Fumegali - RUC: 4272044-3 - HAB. Nº 1713 - Calle 24 De Mayo Entre Cnel. Cubas Barboza y \n Luis Alberto Del Parana - frente a la Municipalidad - Katuete - Canindeyu - Paraguay - Tel.: (0471) 234 434 Cel.: (0983) 666 134 - Octubre 2023",
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              fontSize: 4,
              text: "Cant. Impresa 100 H. \n Del 0002951 hasta 0003050",
              alignment: "right",
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              fontSize: 4,
              text: "ORIGINAL",
              alignment: "right",
              colSpan: 2,
            },
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let cabecalho_2 = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              margin: [0, 0, 0, 0],
              border: [true, true, false, false],
              stack: [
                {
                  image: logo_extenso_source,
                  fit: [120, 120]
                },
              ],
            },
            {
              border: [false, true, true, false],
              alignment: "center",
              margin: [0, 0, 0, 0],
              fontSize: 8,
              stack: [
                {
                  text: empresa.nome,
                  bold: true,
                  fontSize: 12,
                  // margin: [0, 0, 40, 2],
                },
                {
                  text: "de: Monica Gabriela Oleinik De Hahn",
                  fontSize: 10,
                  // margin: [0, 0, 10, 2],
                },
                {
                  text: "Comercio al por menor de artículos de ferretería",
                  bold: true,
                },
                {
                  text: "Comercio al por menor de otros alimentos n.c.p.",
                  bold: true,
                },
                {
                  text: "Comercio al por menor de productos farmacéuticos de uso veterinario",
                  bold: true,
                },
              ],
            },
            {
              border: [false, true, true, false],
              margin: [0, 0, 0, 0],
              stack: [
                {
                  margin: [0, 0, 0, 0],
                  text: "TIMBRADO Nº " + fatura.timbrado,
                  alignment: "center",
                  bold: true,
                  fontSize: 12,
                },
                {
                  text: "Fecha inicio vigencia " + format(parseISO(fatura.inicio_timbrado), "dd",) + ' de ' + format(parseISO(fatura.inicio_timbrado), "MMMM", { locale: es }) + ' de ' + format(parseISO(fatura.inicio_timbrado), "yyyy"),
                  fontSize: 8,
                  alignment: "center",
                },
                {
                  text: "Fecha fin de vigencia " + format(parseISO(fatura.fim_timbrado
                  ), "dd") + ' de ' + format(parseISO(fatura.fim_timbrado
                  ), "MMMM", { locale: es }) + ' de ' + format(parseISO(fatura.fim_timbrado
                  ), "yyyy"),
                  fontSize: 8,
                  alignment: "center",
                },

                {
                  text: "RUC: " + empresa.documento,
                  margin: [0, 0, 0, 5],
                  bold: true,
                  fontSize: 12,
                  alignment: "center",
                },
                {
                  text: "F A C T U R A",
                  margin: [0, 0, 0, 5],
                  bold: true,
                  fontSize: 13,
                  alignment: "center",
                },
                {
                  text: 'Nº ' + fatura.ponto + '-' + fatura.numero.toString().padStart(7, "0"),
                  bold: true,
                  fontSize: 12,
                  alignment: "center",
                },

              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let texto_extra_2 = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-187, -23, 0, 0],
              border: [false, false, false, false],
              stack: [
                {
                  fontSize: 8,
                  text: "Cel.: " + empresa.telefone + ' - ' + empresa.endereco,
                  alignment: "center",
                },
                {
                  fontSize: 8,
                  text: empresa.cidade + ' - ' + empresa.estado + ' - ' + empresa.pais,
                  alignment: "center",
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let infos_cliente_2 = {
      margin: [0, -5, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              border: [true, true, false, true],
              margin: [0, 5, 0, 0],
              fontSize: 8,
              text: "Francisco C. Álvarez     " + format(parseISO(fatura.data_emissao), "dd") + '     de     ' + format(parseISO(fatura.data_emissao), "MMMM", { locale: es }) + '     de     ' + format(parseISO(fatura.data_emissao), "yyyy"),
              colSpan: 2,
            },
            {},
            {
              border: [false, true, true, true],
              margin: [0, 5, 0, 0],
              text: fatura.condpag == 1 ? "CONDICIÓN DE VENTA:CONTADO [ X ] CRÉDITO [  ]" : "CONDICIÓN DE VENTA:CONTADO [  ] CRÉDITO [ X ]",
              fontSize: 8,
              colSpan: 2,
              alignment: "right"
            },
            {},
          ],
          [
            {
              border: [true, true, false, true],
              margin: [0, 5, 0, 0],
              fontSize: 8,
              text: "SEÑOR(ES): " + fatura.cliente_nome,
              colSpan: 3,
            },
            {},
            {},
            {
              border: [false, true, true, true],
              margin: [0, 5, 0, 0],
              text: fatura.cliente_documento ? "C.I o RUC: " + fatura.cliente_documento : "C.I o RUC: ",
              fontSize: 8,
              alignment: "start"
            },
          ],
          [
            {
              border: [true, true, false, false],
              margin: [0, 5, 0, 0],
              fontSize: 8,
              text: fatura.cliente_endereco ? "DIRECCION: " + fatura.cliente_endereco : "DIRECCION: ",
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              text: fatura.cliente_telefone ? "Tel.: " + fatura.cliente_telefone : "Tel.:",
              fontSize: 8,
              margin: [0, 5, 0, 0],
              alignment: "start"
            },
            {
              border: [false, false, true, false],
              margin: [0, 5, 0, 0],
              text: '',
              fontSize: 8,
              alignment: "start"
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let corpo_2 = {
      table: {
        widths: [40, 240, 80, "*", 20, "*"],

        body: [
          [
            {
              margin: [0, 7, 0, 0],
              fontSize: 8,
              text: "CANTIDAD",
              bold: true,
              alignment: "center",

              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "D E S C R I P C I Ó N",
              bold: true,
              alignment: "center",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "PRECIO UNITARIO",
              bold: true,
              alignment: "center",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              text: "VALOR DE VENTA",
              bold: true,
              fillColor: "#EEEEEE",
              alignment: "center",
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {},
            {},
            {},
            {
              text: "EXENTAS",
              bold: true,
              alignment: "center",
              fontSize: 7,
            },
            {
              text: "5%",
              bold: true,
              alignment: "center",
              fontSize: 7,
            },
            {
              text: "10%",
              bold: true,
              alignment: "center",
              fontSize: 7,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let fatura_items_2 = {
      table: {
        widths: [40, 240, 80, "*", 20, "*"],
        border: [true, false, true, false],
        body: [],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    fatura.items.forEach((fatura_item) => {
      let item = [
        {
          text: fatura_item.qntde,
          border: [true, false, true, false],
          alignment: "center",
          fontSize: 7,
        },
        {
          text: fatura_item.descricao,
          border: [true, false, true, false],
          alignment: "left",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_isento),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_5),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_10),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
      ];

      fatura_items_2.table.body.push(item);
    });

    let footer_2 = {
      table: {
        widths: [90, 190, 80, "*", 20, "*"],
        border: [true, true, true, true],
        body: [
          [
            {
              text: "DESCUENTOS CONDEDIDOS:",
              fontSize: 8,
              bold: true,
              colSpan: 4,
            },
            {},
            {},
            {},
            {
              text: Vue.filter(filtro_moeda)(fatura.desconto),
              bold: true,
              fontSize: 8,
              alignment: "center",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              margin: [0, 10, 0, -4],
              text: "",
              fontSize: 8,
              colSpan: 2
            },
            {},
            {
              text: "SUB-TOTALES",
              fontSize: 8,
              alignment: "right",
              bold: true,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_exento),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado5),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado10),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
          ],
          [
            {
              text: "TOTAL A PAGAR: " + fatura.moeda,
              fontSize: 8,
              bold: true,
              border: [true, false, false, true]
            },
            {
              text: total_pagar_extenso.toLocaleUpperCase(),
              fontSize: 8,
              colSpan: 3,
              border: [false, false, false, true]
            },
            {},
            {
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total),
              fontSize: 8,
              alignment: "right",
              fillColor: "#EEEEEE",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [true, false, true, true],
              text: "LIQUIDACIÓN DEL IVA:",
              fontSize: 7,
              bold: true,
            },
            {
              border: [false, false, true, true],
              text:
                "(5%) " +
                Vue.filter(filtro_moeda)(fatura.iva_5) +
                " (10%) " +
                Vue.filter(filtro_moeda)(fatura.iva_10),
              fontSize: 8,
              colSpan: 2,
            },
            {},
            {
              text: "TOTAL IVA",
              fontSize: 7,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.total_iva),
              fontSize: 8,
              alignment: "right",
              fillColor: "#EEEEEE",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [true, false, false, true],
              text: "Firma:",
              margin: [0, 2, 0, 0],
              fontSize: 8,
              colSpan: 2,
            },
            {

            },
            {
              border: [false, false, false, true],
              text: "Aclaración de la Firma:",
              margin: [-78, 2, 0, 0],
              fontSize: 8,
              colSpan: 2,
            },
            {

            },
            {
              border: [false, false, true, true],
              text: "C.I. Nº",
              margin: [-19, 2, 0, 0],
              fontSize: 8,
              alignment: "start",
              colSpan: 2,
            },
            {

            },
          ],
          [
            {
              border: [false, false, false, false],
              margin: [0, 0, 0, 20],
              fontSize: 4,
              text: "GRAFICA INTEGRACION DE Joslaine Patricia Erthal Fumegali - RUC: 4272044-3 - HAB. Nº 1713 - Calle 24 De Mayo Entre Cnel. Cubas Barboza y \n Luis Alberto Del Parana - frente a la Municipalidad - Katuete - Canindeyu - Paraguay - Tel.: (0471) 234 434 Cel.: (0983) 666 134 - Octubre 2023",
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              fontSize: 4,
              text: "Cant. Impresa 100 H. \n Del 0002951 hasta 0003050",
              alignment: "right",
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              fontSize: 4,
              text: "DUPLICADO",
              alignment: "right",
              colSpan: 2,
            },
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let break_page = {
      text: "",
      pageBreak: "before",
    };

    content.push(
      cabecalho,
      texto_extra,
      infos_cliente,
      corpo,
      fatura_items,
      footer,
      fatura.items.length > 10 ? break_page : "",
      cabecalho_2,
      texto_extra_2,
      infos_cliente_2,
      corpo_2,
      fatura_items_2,
      footer_2
    );

    if (fatura.status == -1) {
      let cancelado_img_source = {};
      cancelado_img_source.absolutePosition = { x: 60, y: 50 };
      cancelado_img_source.opacity = 0.3;
      cancelado_img_source.width = 700;

      cancelado_img().then((module) => {
        cancelado_img_source.image = module.default;
        content.push(cancelado_img_source);
      });
    }

    let docDefinition = {
      info: {
        title: `FATURA_${fatura.ponto}-${fatura.numero
          .toString()
          .padStart(7, "0")}`,
        author: "ADM",
        subject: "subject of document",
        keywords: "keywords for document",
      },
      // pageSize: layout.obj_folha.pageSize,
      pageSize: "A4",
      pageMargins: [10, 10, 10, 10],
      pageOrientation: "portrait",
      content: content,
    };

    let backgroundImages = [];

    // adiciona as imagems ao docDefinition
    docDefinition.background = backgroundImages;
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (print) {
      pdfDocGenerator.print();
    } else if (download) {
      pdfDocGenerator.download(
        `FATURA_${fatura.ponto}-${fatura.numero
          .toString()
          .padStart(7, "0")}.pdf`
      );
    } else {
      // pdfDocGenerator.open();
      pdfDocGenerator.getDataUrl((data) => {
        resolve(data);
      });
    }
  });
}
