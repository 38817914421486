var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageToolbar',{attrs:{"title":_vm.$tc('global.caixa', 2),"icon":"mdi-cash-register","dark":""}}),_c('v-card',{attrs:{"vi":""}},[_c('v-card-title',{staticClass:"tocs_gray_1"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.dialogAddBandeira && (_vm.PCaixaAdicionar || _vm.PCaixaEditar))?_c('DialogAddBandeira',{attrs:{"dialogAddBandeira":_vm.dialogAddBandeira,"edit":_vm.edit,"item":_vm.bandeira},on:{"update:dialogAddBandeira":function($event){_vm.dialogAddBandeira=$event},"update:dialog-add-bandeira":function($event){_vm.dialogAddBandeira=$event},"fetch-bandeiras":_vm.getBandeiras}}):_vm._e(),(_vm.PCaixaAdicionar)?_c('v-btn',{staticClass:"white--text mt-4 mt-sm-0",attrs:{"color":"button_1"},on:{"click":_vm.addBandeira}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" ")],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"search":_vm.search.length >= 3 ? _vm.search : '',"items":_vm.bandeiras,"items-per-page":10,"sort-by":['created_at'],"sort-desc":true},on:{"click:row":_vm.goToBandeira},scopedSlots:_vm._u([{key:"item.comissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.comissao))+" ")]}},{key:"item.comissao_iva",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.comissao_iva))+" ")]}},{key:"item.ret_renta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.ret_renta))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.status)?_c('span',{attrs:{"size":"28"}},[_vm._v(_vm._s(_vm.$tc("global.inativo")))]):_vm._e(),(item.status)?_c('span',[_vm._v(_vm._s(_vm.$tc("global.ativo"))+" ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }