<template>
  <v-dialog
    v-model="dialogListasPrecos"
    @click:outside="$emit('update:dialogListasPrecos', false)"
    @keydown.esc="$emit('update:dialogListasPrecos', false)"
    width="600px"
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          v-if="PListaPrecoAdicionar"
          @click="AddLista"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search"
          :loading="loading"
          :items="listas"
          :items-per-page="15"
          class="data-tables"
          :class="{ 'data-tables__row-click': PListaPrecoVisualizar }"
          @click:row="goToLista"
        >
          <template v-slot:item.status="{ item }">
            <span size="28" v-if="item.status === 0">{{
              $tc("global.inativo")
            }}</span>
            <span v-if="item.status === 1"> {{ $tc("global.ativo") }} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogAddEditListaPrecos
      v-if="dialogAddEditListaPrecos"
      :dialogAddEditListaPrecos.sync="dialogAddEditListaPrecos"
      :edit="edit"
      :item="lista"
      @fetch-listas="getListas"
    />
  </v-dialog>
</template>

<script>
import { fetchListasPrecos } from "@/api/listas-precos/listasprecos.js";
import { mapGetters } from "vuex";
export default {
  name: "DialogListasPrecos",

  components: {
    DialogAddEditListaPrecos: () =>
      import("./components/DialogAddEditListaPrecos.vue"),
  },

  props: {
    dialogListasPrecos: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      compListaPrecosKey: 0,
      loading: false,
      listas: [],
      search: "",
      dialogAddEditListaPrecos: false,
      lista: {},
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PListaPrecoVisualizar() {
      return this.getAccess("ListasPreco", "visualizar");
    },
    PListaPrecoAdicionar() {
      return this.getAccess("ListasPreco", "adicionar");
    },
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: "status",
          value: "status",
        },
      ];
    },
  },

  methods: {
    AddLista() {
      this.dialogAddEditListaPrecos = true;
      this.edit = false;
    },
    goToLista(item) {
      if (this.PListaPrecoVisualizar) {
        this.lista = { ...item };
        this.dialogAddEditListaPrecos = true;
        this.edit = true;
      }
    },
    getListas() {
      this.loading = true;
      fetchListasPrecos(this.url)
        .then((response) => {
          this.listas = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getListas();
  },
};
</script>

<style></style>
