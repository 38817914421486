<template>
  <v-dialog
    v-model="dialogCidade"
    max-width="290"
    @click:outside="$emit('update:dialogCidade', false)"
    @keydown.esc="$emit('update:dialogCidade', false)"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.nova") + " " + $tc("global.cidade")
            : $tc("global.atualizar") + " " + $tc("global.cidade")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogCidade', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <ComboboxPais
            class="pb-6"
            :pais_id.sync="pais_id"
            :estados.sync="estados"
            @get-estados="getEstados()"
            @clear-estado="clearEstado()"
            :edit.sync="edit"
            :item="item"
          />

          <v-combobox
            label="Estados"
            :items="estados"
            item-text="nome"
            item-value="id"
            dense
            outlined
            solo
            :disabled="pais_id ? false : true"
            flat
            hide-details
            v-model="selectedEstados"
            class="pb-6"
          >
            <template v-slot:append-item>
              <v-btn
                @click="dialogEstado = true"
                text
                block
                class="primary--text"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $tc("global.add") + " " + $tc("global.estado") }}
              </v-btn>
            </template>
          </v-combobox>

          <DialogEstados
            v-if="dialogEstado"
            :dialogEstado.sync="dialogEstado"
            :pais_id.sync="pais_id"
             @fetch-estados="getEstados(pais_id)"
            
          />

          <v-text-field
            v-model="cidade.nome"
            :label="$tc('global.cidade')"
            dense
            outlined
            :disabled="selectedEstados ? false : true"
            :rules="formRules"
            required
          ></v-text-field>
          <!-- <v-text-field
            v-model="cidade.departamento"
            :label="$tc('global.estado')"
            dense
            outlined
            :rules="formRules"
            required
          ></v-text-field> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogCidade', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createCidade() : updateCidade()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchEstadoPorPais } from "@/api/cidades/estados.js";

import { postCidade, putCidade } from "@/api/cidades/cidades.js";
export default {
  name: "DialogCidades",

  components: {
    DialogEstados: () => import("./DialogEstados.vue"),
    ComboboxPais: () => import("@/components/fields/ComboboxPais.vue"),
  },

  props: {
    dialogCidade: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    descCidade: {
      type: String,
      default: "",
    },
  },

  data: () => {
    return {
      loading: false,
      dialogEstado: false,
      cidade: {},
      validForm: true,
      formRules: [inputRequired],
      cidade_original: {},
      searchCidade: "",
      setarCidade: true,
      selectedEstados: null,
      pais_id: null,
      estados: [],
      pais:[],
    };
  },

  watch: {},

  methods: {
    clearEstado() {
      this.estados = [];
      this.selectedEstados = null;
    },

    updateCidade() {
      let cidade2 = {};
      cidade2.nome = this.cidade.nome;
      cidade2.pais_id = this.pais_id;
      cidade2.estado_id = this.selectedEstados.id;
      if (JSON.stringify(cidade2) === JSON.stringify(this.cidade_original)) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.atualizando = true;

        putCidade(this.cidade.id, cidade2)
          .then(() => {
            this.cidade_original = { ...this.cidade };
            this.$emit("fetch-cidades");
            this.$emit("update:dialogCidade", false);
          })
          .catch(() => {})
          .finally(() => {
            this.atualizando = false;
          });
      }
    },

    getEstados() {
      fetchEstadoPorPais(this.pais_id)
        .then((response) => {
          this.estados = response;
          this.loading = false;
          if (this.edit) {
            this.selectedEstados = this.estados.find((estados) => {
              return estados.id === this.item.estado_id;
            });
          }
        })
        .catch(() => {});
    },

    createCidade() {
      if (this.$refs.form.validate()) {
        // this.loading = true;
        let cidade2 = {};
        cidade2.nome = this.cidade.nome;
        cidade2.pais_id = this.pais_id;
        cidade2.estado_id = this.selectedEstados.id;

        if (this.descCidade) {
          this.cidade.nome = this.descCidade;
        }

        this.searchCidade = cidade2.nome.toUpperCase();

        postCidade(cidade2)
          .then(() => {
            this.cidade = {};
            this.$emit("update:searchCidade", this.searchCidade);
            this.$emit("update:setarCidade", this.setarCidade);
            this.$emit("setaCidade");
            this.$emit("fetch-cidades");
            this.$emit("update:dialogCidade", false);
          })
          .catch(() => {})
          .finally(() => {
            // this.loading = false;
          });
      }
    },
  },
  async mounted() {
    this.loading = true;
    if (this.descCidade) {
      this.cidade.nome = this.descCidade;
    }

    if (this.edit) {
      this.cidade = { ...this.item };
      this.cidade_original = { ...this.item };
    }
    this.loading = false;
  },
};
</script>

<style></style>
