<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.transferenciaEstoque')"
      icon="mdi-swap-horizontal"
      dark
    />
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>

        <v-btn-toggle mandatory v-model="tipo_transferencia" class="ml-3">
          <v-btn value="pendente" class="text-caption" height="40px">
            {{ $tc("global.pendentes") }}
          </v-btn>
          <v-btn value="finalizado" class="text-caption" height="40px">
            {{ $tc("global.finalizado") }}
          </v-btn>
        </v-btn-toggle>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          v-if="TransferenciasAdicionar"
          @click="addTransferencia"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="transferencias"
          :items-per-page="-1"
          class="data-tables"
          :class="{ 'data-tables__row-click': TransferenciasVisualizar }"
          hide-default-footer
          disable-sort
          @click:row="goToTransferencia"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-center">
              <v-btn
                @click.stop="openReciboTranferencia(item.id)"
                icon
                class="mr-2"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-tooltip v-if="item.justificativa" bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-icon>mdi-information-outline</v-icon>
                  </div>
                </template>
                <div>{{ item.justificativa | excerpt(30) }}</div>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogAddEditTransferencia
      v-if="dialogAddEditTransferencia"
      :dialogAddEditTransferencia.sync="dialogAddEditTransferencia"
      :edit="edit"
      :item_id="transferencia_id"
      @fetch-transferencias="getTransferencias"
    />
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="PDF"
    />
  </v-container>
</template>

<script>
import { fetchTransferenciasEstoque } from "@/api/transferenciaEstoque";
import { mapGetters } from "vuex";
const generateTransferenciaEstoque = () =>
  import(
    /* webpackChunkName: "transferenciaEstoquePdf" */ "@/components/recibo/reports/reciboTransferenciaEstoquePdf.js"
  );

export default {
  name: "TransferenciasEstoque",

  components: {
    DialogAddEditTransferencia: () =>
      import("./components/DialogAddEditTransferencia.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      search: "",
      loading: false,
      transferencias: [],
      edit: false,
      dialogAddEditTransferencia: false,
      tipo_transferencia: "pendente",
      transferencia_id: null,
      pdfDialog: false,
      pdfData: {},
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    TransferenciasVisualizar() {
      return this.getAccess("TransferenciaEstoque", "visualizar");
    },
    TransferenciasAdicionar() {
      return this.getAccess("TransferenciaEstoque", "adicionar");
    },

    headers() {
      return [
        {
          text: "id",
          value: "id",
        },
        {
          text: this.$tc("global.data"),
          value: "created_at",
        },
        {
          text: this.$tc("global.deposito") + " " + this.$tc("global.origem"),
          value: "deposito_origem",
        },
        {
          text: this.$tc("global.deposito") + " " + this.$tc("global.destino"),
          value: "deposito_destino",
        },
        {
          text: this.$tc("global.usuarioCriou"),
          value: "usuario_criou",
        },
        {
          text: this.$tc("global.usuarioAutorizou"),
          value: "usuario_baixou",
        },
        {
          text: "",
          value: "actions",
          width: "55px",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    tipo_transferencia() {
      this.getTransferencias();
    },
  },

  methods: {
    goToTransferencia(item) {
      if (this.TransferenciasVisualizar) {
        this.transferencia_id = item.id;
        this.dialogAddEditTransferencia = true;
        this.edit = true;
      }
    },

    addTransferencia() {
      this.edit = false;
      this.dialogAddEditTransferencia = true;
    },

    async getTransferencias() {
      this.loading = true;
      this.transferencias = await fetchTransferenciasEstoque(
        `?tipo_transferencia=${this.tipo_transferencia}`
      );
      this.loading = false;
    },

    async openReciboTranferencia(item_id) {
      this.loading = true;
      await generateTransferenciaEstoque().then((module) => {
        let generate = module.generateReciboTransferenciaEstoque;
        generate(item_id)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },
  },

  mounted() {
    this.getTransferencias();
  },
};
</script>

<style></style>
