<template>
  <v-card flat class="mb-6">
    <v-toolbar dark dense :tile="tile" :color="getColor">
      <template v-if="voltar">
        <v-btn icon tile @click="backHistory" class="mr-2">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <v-toolbar-title
        :class="$vuetify.breakpoint.mdAndDown ? 'text-wrap' : ''"
        class="text-uppercase text-md-h6 pl-0 text-body-2 tocs_blue-5--text"
      >
        <v-icon style="margin-bottom: 2px;" class="mr-2">
          {{ icon }}
        </v-icon>
        {{ title }}
      </v-toolbar-title>
      <template v-if="add">
        <v-spacer></v-spacer>
        <slot name="add"></slot>
      </template>
    </v-toolbar>
    <!-- <v-divider></v-divider> -->
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageToolbar",
  props: {
    icon: String,
    title: String,
    color: String,
    voltar: Boolean,
    tile: Boolean,
    add: Boolean,
    irParaUrl: String,
  },
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    getColor() {
      if (this.color) {
        return this.color;
      } else {
        if (this.$vuetify.theme.dark) {
          return "tocs_blue_3";
        } else {
          return "tocs_blue_3";
        }
      }
    },
    getIrPara() {
      return this.irParaUrl;
    },
  },
  methods: {
    backHistory() {
      let last_route = this.$ls.get("last_route");
      if (this.$route.meta.voltar) {
        this.$router.push(this.$route.meta.voltar);
      } else {
        if (last_route != "/") {
          this.$router.push({
            path: last_route,
          });
        }
      }
    },
    irParaClick() {
      this.$router.push({ path: this.getIrPara });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrap {
  max-width: 250px !important;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
}
</style>
