<template>
  <v-dialog
    v-model="dialogImportPdfLayoutItems"
    @click:outside="$emit('update:dialogImportPdfLayoutItems', false)"
    @keydown.esc="$emit('update:dialogImportPdfLayoutItems', false)"
    width="500px"
    scrollable
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        Importar Itens
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogImportPdfLayoutItems', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-5 py-7">
        <v-row>
          <!-- Posição -->
          <v-col cols="12">
            <v-select
              v-model="posicao_de"
              :items="positions"
              outlined
              clearable
              dense
              hide-details
              label="Importar items de:"
            />
          </v-col>

          <!-- Aletar DATA -->
          <v-col>
            <v-alert
              border="right"
              outlined
              colored-border
              type="warning"
              elevation="0"
            >
              Irá importar os itens de uma posição para outra. Utilizando o
              campo de coluna como base para verificação. Se o campo da coluna
              nao existir no destino, ele ira importar.
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="px-5 py-4">
        <v-btn
          color="primary"
          outlined
          @click="$emit('update:dialogImportPdfLayoutItems', false)"
        >
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="importarPdfLayoutItems"
          :disabled="disableImport"
        >
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { importPdfLayoutItems } from "@/api/pdf-layout-items/pdf_layout_items.js";

export default {
  name: "ImportPdfLayoutItems",

  props: {
    dialogImportPdfLayoutItems: {
      type: Boolean,
      required: true,
    },
    pdfLayout: {
      type: Object,
      required: true,
    },
    pdf_layout_id: {
      type: [Number, String],
      required: true,
    },
    posicao_para: {
      type: [Number, String],
      required: true,
    },
    grupo: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      posicao_de: null,
    };
  },

  computed: {
    positions() {
      let positions = [];

      if (
        this.pdfLayout.obj_imagem1 &&
        this.pdfLayout.obj_imagem1.status &&
        this.posicao_para != 1
      ) {
        positions.push({
          text: "Imagem 1",
          value: 1,
        });
      }

      if (
        this.pdfLayout.obj_imagem2 &&
        this.pdfLayout.obj_imagem2.status &&
        this.posicao_para != 2
      ) {
        positions.push({
          text: "Imagem 2",
          value: 2,
        });
      }

      if (
        this.pdfLayout.obj_imagem3 &&
        this.pdfLayout.obj_imagem3.status &&
        this.posicao_para != 3
      ) {
        positions.push({
          text: "Imagem 3",
          value: 3,
        });
      }

      return positions;
    },

    grupos() {
      return [
        {
          text: "Cabeçalho",
          value: "cabecalho",
        },
        {
          text: "Items",
          value: "items",
        },
        {
          text: "Rodapé",
          value: "rodape",
        },
      ];
    },

    disableImport() {
      let result = false;

      if (!this.posicao_de) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    importarPdfLayoutItems() {
      this.loading = true;
      importPdfLayoutItems(
        this.pdfLayout.id,
        this.posicao_de,
        this.posicao_para,
        this.grupo
      )
        .then((response) => {
          if (response.qtd_importados > 0) {
            this.$toast.success(
              `${response.qtd_importados} items importados com sucesso!`
            );
            this.$emit("fetch-layout-items");
          } else {
            this.$toast.warning(`Nenhum item foi importado.`);
          }
          this.$emit("update:dialogImportPdfLayoutItems", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {},
};
</script>

<style>
</style>