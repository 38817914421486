import api from "../axios_service.js";

export async function fetchTimbrados(filtros) {
  const response = await api.get(`/timbrado${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchTimbrado(id) {
  const response = await api.get(`/timbrado/${id}`);
  return response.data.result;
}

export function putTimbrado(id, marca) {
  return api.post(`/timbrado/${id}`, marca);
}

export function postTimbrado(marca) {
  return api.post("/timbrado/add", marca);
}

export async function fetchTimbradoNotEqual(id) {
  const response = await api.get(`/timbrado/${id}/notequal`);
  return response.data.result;
}
