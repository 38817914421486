import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { i18n } from "@/plugins/i18n.js";
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { fetchListaCliente } from "@/api/relatorios/relatorio_clientes.js";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();
  // const filtro_get_extrato = bodyPDF.queryString;
  const cabecalho = bodyPDF.cabecalho;
  const response_cliente = await fetchListaCliente();
  let clientes = response_cliente;

  return new Promise((resolve, reject) => {

    let table = {
      margin: [0, 5, 0, 5],
      table: {
        widths: [
          "auto",
          "*",
          "*",
          "*",
          // 120,
          // 120
        ],
        body: [
          [
            {
              text: i18n.tc("global.nome").toUpperCase(),
              bold: true,
              alignment: "center",
              fillColor: "#EBEEF9",
              // margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "E-MAIL",
              bold: true,
              alignment: "center",
              fillColor: "#EBEEF9",
              // margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: i18n.tc("global.endereco").toUpperCase(),
              bold: true,
              alignment: "center",
              fillColor: "#EBEEF9",
              // margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: i18n.tc("global.telefone").toUpperCase(),
              bold: true,
              alignment: "center",
              fillColor: "#EBEEF9",
              // margin: [0, 4, 0, 2],
              fontSize: 8,
            },

          ],
        ],
      },

      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
        hLineColor: function (i) {
          return i === 1 ? "#666666" : "#666666";
        },
        vLineColor: function (i) {
          return i === 1 ? "#666666" : "#666666";
        },
        fillColor: function (rowIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    const row_font_size = 7;

    clientes.forEach((cliente) => {
      let table_row = [
        {
          text: cliente.nome,
          fontSize: row_font_size,
        },
        {
          text: cliente.email,
          fontSize: row_font_size,
        },
        {
          text: Vue.filter("excerpt")(cliente.endereco, 20),
          fontSize: row_font_size,
        },
        {
          text: cliente.telefone,
          fontSize: row_font_size,
        },

      ];

      table.table.body.push(table_row);
    });

    let geral = [cabecalho, table];

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content: [...geral],
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: "right",
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
