import api from "../axios_service.js";

export async function fetchPlanosContas(filtros) {
  const response = await api.get(`/plano-de-contas${filtros ? filtros : ""}`);
  return response.data.result;
}
export async function fetchPlanosContasTree(filtros) {
  const response = await api.get(
    `/plano-de-contas-tree${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchPlanoContas(id) {
  const response = await api.get(`/plano-de-contas/${id}`);
  return response.data.result;
}

export function putPlanoContas(id, marca) {
  return api.post(`/plano-de-contas/${id}`, marca);
}

export function postPlanoContas(marca) {
  return api.post("/plano-de-contas/add", marca);
}
