<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.deposito') + ' ' + $tc('global.cartao', 2)"
      icon="mdi-credit-card-check-outline"
      dark
    />
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-btn-toggle mandatory v-model="tipo_cartao" class="ml-3">
          <v-btn value="abertos" class="text-caption" height="40px">
            {{ $tc("global.aberto") }}
          </v-btn>
          <v-btn value="compensados" class="text-caption" height="40px">
            {{ $tc("global.compensacao", 2) }}
          </v-btn>
        </v-btn-toggle>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          color="button_1"
          :disabled="selectedCartoes.length == 0 ? true : false"
          @click="dialogCompensacaoCartao = true"
          class="white--text mt-4 mt-sm-0 mx-2"
        >
          <v-icon left>mdi-cash-sync</v-icon>
          {{ $tc("global.compensar") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          v-model="selectedCartoes"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="cartoes"
          :loading="loading"
          :items-per-page="15"
          class="data-tables"
          disable-sort
          :show-select="tipo_cartao == 'abertos' ? true : false"
        >
          <template v-slot:item.created_at="{ item }">
            <div>
              {{ item.created_at | dateFormat("dd/MM/yyyy") }}
            </div>
          </template>

          <template v-slot:item.valor="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.valor | guarani }}
            </div>
            <div v-else>
              {{ item.valor | currency }}
            </div>
          </template>

          <template v-slot:item.comissao="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.comissao | guarani }}
            </div>
            <div v-else>
              {{ item.comissao | currency }}
            </div>
          </template>

          <template v-slot:item.comissao_iva="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.comissao_iva | guarani }}
            </div>
            <div v-else>
              {{ item.comissao_iva | currency }}
            </div>
          </template>

          <template v-slot:item.ret_renta="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.ret_renta | guarani }}
            </div>
            <div v-else>
              {{ item.ret_renta | currency }}
            </div>
          </template>

          <template v-slot:item.ret_iva="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.ret_iva | guarani }}
            </div>
            <div v-else>
              {{ item.ret_iva | currency }}
            </div>
          </template>

          <template v-slot:item.total="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.total | guarani }}
            </div>
            <div v-else>
              {{ item.total | currency }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogCompensacaoCartoes
      v-if="dialogCompensacaoCartao"
      :dialogCompensacaoCartao.sync="dialogCompensacaoCartao"
      :selectedCartoes="selectedCartoes"
      @fetched-cartoes="getCartoes"
    />
  </v-container>
</template>

<script>
import { fetchCartoes } from "@/api/compensacao-cartoes/compensacao_cartoes.js";
import { mapGetters } from "vuex";

export default {
  name: "CompensacaoCartoes",

  components: {
    DialogCompensacaoCartoes: () =>
      import("./components/DialogCompensacaoCartoes.vue"),
  },

  data() {
    return {
      search: "",
      loading: false,
      cartoes: [],
      cartao: {},
      selectedCartoes: [],
      dialogCompensacaoCartao: false,
      tipo_cartao: "abertos",
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    headers() {
      return [
        {
          text: this.$tc("global.data"),
          value: "created_at",
          sortable: false,
          // width: "70px",
        },
        {
          text: this.$tc("global.cartao"),
          value: "cartao_bandeira",
          sortable: false,
          // width: "70px",
        },
        {
          text: this.$tc("global.codigoAutorizacao"),
          value: "codigo_autorizacao",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          sortable: false,
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.cartao"),
          value: "valor",
          sortable: false,
          // width: "180px",
        },
        {
          text: this.$tc("global.comissao"),
          value: "comissao",
          align: "right",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.comissao") + " IVA",
          value: "comissao_iva",
          align: "right",
          sortable: false,
          // width: "100px",
        },
        {
          text: "Ret. Renta",
          value: "ret_renta",
          align: "right",
          sortable: false,
          // width: "150px",
        },
        {
          text: "Ret. Iva",
          value: "ret_iva",
          align: "right",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
          align: "right",
          sortable: false,
          // width: "100px",
        },
        // {
        //   text: "Status",
        //   value: "status",
        //   align: "right",
        //   sortable: false,
        //   // width: "100px",
        // },
      ];
    },
  },

  watch: {
    tipo_cartao() {
      this.getCartoes();
    },
  },

  methods: {
    async getCartoes() {
      this.loading = true;
      this.cartoes = [];
      return fetchCartoes(`?tipo_cartao=${this.tipo_cartao}`)
        .then((response) => {
          this.cartoes = response;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getCartoes();
  },
};
</script>

<style></style>
