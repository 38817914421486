<template>
  <div>
    <v-btn
      depressed
      small
      color="tocs_red"
      class="white--text"
      v-bind="$attrs"
      v-on="$listeners"
      :loading="loading"
      @click="gerarPdf"
    >
      Gerar Pdf
    </v-btn>

    <PdfDialogComp
      v-if="pdfDialog && item"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      :title="`${$tc('global.relatorio')} - ${item.title}`"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ContasReceber_Pdf",

  props: {},

  data() {
    return {
      loading: false,
      pdfDialog: false,
      pdfData: null,
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      item: (state) => state.selectedItem,
      group_contas_receber: (state) => state.filtros.group_contas_receber,
    }),
  },

  methods: {
    async gerarPdf() {
      try {
        this.loading = true;

        let module; 

        if (this.group_contas_receber) {
          module = await import("./contasreceberagrupado.js");
        }else{
          module = await import("./contasreceber.js");
        }

        if (!module) {
          this.$toast.error("Não foi possível carregar o módulo!");
        }

        const response = await module.generatePdf();

        if (response.error) {
          this.$toast.error(response.message);
        } else {
          this.pdfData = response.pdfData;
          this.pdfDialog = true;
        }

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
