import api from "../axios_service";

export default {
  // Valida o acesso do usuario ao component.
  async validate(component, userId) {
    const response = await api.post("/usuarios/permissao", {
      id_usuario: userId,
      componente: component
    });
    return response.data.permissao[0];
  },
  async getComponentes() {
    const response = await api.get("/usuarios/componentes");
    return response.data.componentes;
  }
};