<template>
  <v-card flat>
    <v-combobox
      label="Pais"
      clearable
      @click:clear="clear"
      :items="paises"
      item-text="nome"
      item-value="id"
      dense
      outlined
      solo
      flat
      hide-details
      v-model="selectedPais"
    >
      <template v-slot:append-item>
        <v-btn @click="dialogAddPais = true" text block class="primary--text">
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") + " Pais" }}
        </v-btn>
      </template>
    </v-combobox>
    <DialogAddPais
      v-if="dialogAddPais"
      :dialogAddPais.sync="dialogAddPais"
      @fetch-paises="getPaises"
    />
  </v-card>
</template>

<script>
import { fetchAllPaises } from "@/api/cidades/paises.js";
export default {
  name: "ComboboxEstado",

  components: {
    DialogAddPais: () =>
      import("@/views/configuracoes/cidades/components/DialogAddPais.vue"),
  },

  props: {
    pais_id: {
      type: Number,
    },
    item: {
      type: Object,
    },
    edit: {
      type: Boolean,
    },
    pais: {
      type: Array,
    }
  },

  data() {
    return {
      paises: [],
      selectedPais: null,
      dialogAddPais: false,
    };
  },

  watch: {
    selectedPais() {
      if (!this.selectedPais) return;
      this.pais_id = this.selectedPais.id;
      this.pais = this.selectedPais;
      this.$emit("update:pais_id", this.pais_id);
      // this.$emit("update:pais", this.selectedPais);
      this.$emit("get-estados");
    },
  },

  methods: {
    clear() {
      this.pais_id = null;
      this.$emit("update:pais_id", this.pais_id);
      this.$emit("clear-estado");
    },

    getPais() {
      fetchAllPaises()
        .then((response) => {
          this.paises = response;
          if (this.edit) {
            this.selectedPais = this.paises.find((pais) => {
              return pais.id === this.item.pais_id;
            });
          }
        })
        .catch((error) => {});
    },
  },

  async mounted() {
    this.getPais();
  },
};
</script>

<style></style>
