<template>
  <v-dialog
    v-model="dialogUsuariosInativos"
    @click:outside="$emit('update:dialogUsuariosInativos', false)"
    @keydown.esc="$emit('update:dialogUsuariosInativos', false)"
    width="850px"
  >
    <v-card class>
      <v-card-title class="pa-4 subtitle-1 tocs_gray_1">
        <h3 class="subtitle-1">
          {{ $tc("global.usuario", 2) }} {{ $tc("global.inativo", 2) }}
        </h3>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        item-key="id"
        :search="search.length >= 3 ? search : ''"
        class="data-tables data-tables__row-click pa-2"
      >
        <template v-slot:item.ativar="{ item }">
          <v-btn
            @click="updateStatusAtivar(item)"
            color="button_1"
            small
            text
            class="butto_1--text my-1"
          >
            {{ $tc("global.ativar") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { putUsuarioAtivar } from "@/api/usuarios/usuarios.js";

export default {
  props: {
    dialogUsuariosInativos: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      search: "",
      usuarios: [],
      loading: true,
      usuario: {},
      usuario_index: {},
    };
  },

  computed: {
    headers() {
      let headers = [
        {
          text: this.$tc("global.nome"),
          value: "name",
        },
        {
          text: "username",
          value: "email",
        },

        {
          text: this.$tc("global.ativar"),
          value: "ativar",
          align: "center",
        },
      ];
      return headers;
    },
  },

  methods: {
    updateStatusAtivar(item) {
      this.usuario_index = { ...item };
      putUsuarioAtivar(this.usuario_index.id)
        .then((response) => {
          if (response) {
            this.$emit("fetch-usuarios-inativos");
            this.$emit("fetch-usuarios");
          } else {
            this.$toast.error(
              "Limite de usuários ativos atingido, desative um usuário antes!"
            );
            this.$emit("update:dialogUsuariosInativos", false);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
