<template>
  <v-card height="100%">
    <v-card-title class="tocs_gray_1">
      <v-icon class="mr-2">mdi-chart-areaspline</v-icon>
      {{ $tc("global.vendaStatus") }}
      <v-spacer></v-spacer>
      <h3 class="text-subtitle-1">
        {{ $tc("global.total") }}
        <span class="font-weight-medium">{{ totalVendas }}</span>
      </h3>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text
      v-if="graficoData.length"
      class="pa-2 d-flex align-center justify-center"
    >
      <Doughnut :chartdata="graficoVendas" :styling="styling" />
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      {{ $tc("global.nenhumaVenda") }}
    </v-card-text>
  </v-card>
</template>

<script>
import { getVendasStatus } from "@/api/graficos/vendas.js";
export default {
  name: "BoxGrafico",

  components: {
    Doughnut: () => import("../graficos/Doughnut"),
  },

  data() {
    return {
      vendas: [],
      loadingVendas: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      styling: {
        width: "500px",
        borderAlign: "center",
      },
      graficoData: [],
      graficoLabel: [],
      graficoColor: [],
    };
  },

  computed: {
    graficoVendas() {
      return {
        labels: this.graficoLabel,
        datasets: [
          {
            data: this.graficoData,
            backgroundColor: this.graficoColor,
          },
        ],
      };
    },
    totalVendas() {
      let result = 0;
      if (this.graficoData.length) {
        result = this.graficoData.reduce(
          (accumulator, dado) => accumulator + dado,
          0
        );
      }
      return result;
    },
  },

  methods: {
    fetchVendasStatus() {
      this.loadingVendas = true;
      getVendasStatus()
        .then((response) => {
          response.forEach((item) => {
            this.graficoData.push(item.qtde);
            if (item.status == -1) {
              this.graficoLabel.push(this.$tc("global.cancelar"));
              this.graficoColor.push("#EF5350");
            }
            if (item.status == 0) {
              this.graficoLabel.push(this.$tc("global.emAndamento"));
              this.graficoColor.push("#bdbdbd");
            }
            if (item.status == 1) {
              this.graficoLabel.push(this.$tc("global.pendentes"));
              this.graficoColor.push("#ffee58");
            }
            if (item.status == 2) {
              this.graficoLabel.push(this.$tc("global.enviadoCaixa"));
              this.graficoColor.push("#AFB42B");
            }
            if (item.status == 3) {
              this.graficoLabel.push(this.$tc("global.baixando"));
              this.graficoColor.push("#0277BD");
            }
            if (item.status == 4) {
              this.graficoLabel.push(this.$tc("global.devolucao"));
              this.graficoColor.push("#512DA8");
            }
            if (item.status == 5) {
              this.graficoLabel.push(this.$tc("global.finalizado"));
              this.graficoColor.push("#66BB6A");
            }
          });
          this.loadingVendas = false;
        })
        .catch((error) => {
          this.loadingVendas = false;
        });
    },
  },

  mounted() {
    this.fetchVendasStatus();
  },
};
</script>

<style></style>
