<template>
  <v-container fluid>
    <v-card flat :loading="loading" :disabled="loading">
      <v-card-text class="pb-8">
        <div
          v-for="(layout_item, index) in pdf_layout_items"
          :key="index"
          :class="{ 'mt-8': index != 0 }"
        >
          <v-subheader
            style="height: auto !important"
            class="ma-0 pa-0 pb-1 text-h5"
          >
            {{ layout_item.nome }}
            <v-spacer> </v-spacer>
            <v-btn
              icon
              :class="`session${layout_item.expand ? '--active' : ''}`"
              @click="layout_item.expand = !layout_item.expand"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-subheader>
          <v-divider></v-divider>
          <v-slide-y-transition>
            <div v-show="layout_item.expand">
              <!-- Cabeçalho -->
              <v-card outlined class="mt-5">
                <v-card-title
                  class="text-body-1 px-3 py-2"
                  :class="`${layout_item.cor}`"
                >
                  {{ $tc("global.cabecalho") }}
                  <v-spacer> </v-spacer>
                  <v-btn
                    class="white--text"
                    color="button_2"
                    small
                    @click="
                      importarPdfLayoutItems(layout_item.posicao, 'cabecalho')
                    "
                  >
                    <v-icon left>mdi-import</v-icon>
                    {{ $tc("global.importar") }}
                  </v-btn>

                  <v-divider vertical class="mx-4"></v-divider>

                  <v-btn
                    class="white--text"
                    color="button_1"
                    small
                    @click="AddPdfLayoutItem(layout_item.posicao, 'cabecalho')"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    {{ $tc("global.add") }}
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="headers"
                    :items="layout_item.cabecalho"
                    :items-per-page="-1"
                    class="data-tables data-tables__row-click"
                    hide-default-footer
                    disable-sort
                    @click:row="goToPdfLayoutItem"
                  >
                    <template v-slot:item.obj_label="{ item }">
                      {{ item.obj_label.text ? item.obj_label.text : "--" }}
                    </template>
                    <template v-slot:item.obj_data="{ item }">
                      {{ item.obj_data.text ? item.obj_data.text : "--" }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      {{
                        item.status
                          ? $tc("global.ativo")
                          : $tc("global.inativo")
                      }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                      {{ item.updated_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>

              <!-- Items -->
              <v-card outlined class="mt-5">
                <v-card-title
                  class="text-body-1 px-3 py-2"
                  :class="`${layout_item.cor}`"
                >
                  {{ $tc("global.item", 2) }}
                  <v-spacer> </v-spacer>

                  <v-btn
                    class="white--text"
                    color="button_2"
                    small
                    @click="
                      importarPdfLayoutItems(layout_item.posicao, 'items')
                    "
                  >
                    <v-icon left>mdi-import</v-icon>
                    {{ $tc("global.importar") }}
                  </v-btn>

                  <v-divider vertical class="mx-4"></v-divider>

                  <v-btn
                    class="white--text"
                    color="button_1"
                    small
                    @click="AddPdfLayoutItem(layout_item.posicao, 'items')"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    {{ $tc("global.add") }}
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="headers"
                    :items="layout_item.items"
                    :items-per-page="-1"
                    class="data-tables data-tables__row-click"
                    hide-default-footer
                    disable-sort
                    @click:row="goToPdfLayoutItem"
                  >
                    <template v-slot:item.obj_label="{ item }">
                      {{ item.obj_label.text ? item.obj_label.text : "--" }}
                    </template>
                    <template v-slot:item.obj_data="{ item }">
                      {{ item.obj_data.text ? item.obj_data.text : "--" }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      {{
                        item.status
                          ? $tc("global.ativo")
                          : $tc("global.inativo")
                      }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                      {{ item.updated_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>

              <!-- Rodape -->
              <v-card outlined class="mt-5">
                <v-card-title
                  class="text-body-1 px-3 py-2"
                  :class="`${layout_item.cor}`"
                >
                  {{ $tc("global.rodape") }}
                  <v-spacer> </v-spacer>

                  <v-btn
                    class="white--text"
                    color="button_2"
                    small
                    @click="
                      importarPdfLayoutItems(layout_item.posicao, 'rodape')
                    "
                  >
                    <v-icon left>mdi-import</v-icon>
                    {{ $tc("global.importar") }}
                  </v-btn>

                  <v-divider vertical class="mx-4"></v-divider>

                  <v-btn
                    class="white--text"
                    color="button_1"
                    small
                    @click="AddPdfLayoutItem(layout_item.posicao, 'rodape')"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    {{ $tc("global.add") }}
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="headers"
                    :items="layout_item.rodape"
                    :items-per-page="-1"
                    class="data-tables data-tables__row-click"
                    hide-default-footer
                    disable-sort
                    @click:row="goToPdfLayoutItem"
                  >
                    <template v-slot:item.obj_label="{ item }">
                      {{ item.obj_label.text ? item.obj_label.text : "--" }}
                    </template>
                    <template v-slot:item.obj_data="{ item }">
                      {{ item.obj_data.text ? item.obj_data.text : "--" }}
                    </template>
                    <template v-slot:item.status="{ item }">
                      {{
                        item.status
                          ? $tc("global.ativo")
                          : $tc("global.inativo")
                      }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                      {{ item.updated_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </div>
          </v-slide-y-transition>
        </div>
      </v-card-text>
    </v-card>

    <AddPdfLayoutItem
      v-if="dialogAddPdfLayoutItem"
      :dialogAddPdfLayoutItem.sync="dialogAddPdfLayoutItem"
      @fetch-layout-items="fetchLayoutItems"
      :edit="edit_pdf_layout_item"
      :item="pdf_layout_item"
      :pdf-layout="pdfLayout"
      :posicao="pdf_layout_item_posicao"
      :grupo="pdf_layout_item_grupo"
    />

    <ImportPdfLayoutItems
      v-if="dialogImportPdfLayoutItems"
      :dialogImportPdfLayoutItems.sync="dialogImportPdfLayoutItems"
      @fetch-layout-items="fetchLayoutItems"
      :pdf-layout="pdfLayout"
      :pdf_layout_id="pdfLayout.id"
      :posicao_para="pdf_layout_items_import_para"
      :grupo="pdf_layout_items_import_grupo"
    />
  </v-container>
</template>

<script>
import { fetchPdfLayoutItems } from "@/api/pdf-layout-items/pdf_layout_items.js";

export default {
  name: "PdfLayoutItems",

  components: {
    AddPdfLayoutItem: () => import("./AddPdfLayoutItem.vue"),
    ImportPdfLayoutItems: () => import("./ImportPdfLayoutItems.vue"),
  },

  props: {
    pdfLayout: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      dialogAddPdfLayoutItem: false,
      edit_pdf_layout_item: false,
      pdf_layout_item: null,
      pdf_layout_items: [],
      pdf_layout_item_posicao: null,
      pdf_layout_item_grupo: null,
      dialogImportPdfLayoutItems: false,
      pdf_layout_items_import_para: null,
      pdf_layout_items_import_grupo: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
          align: "center",
          width: "80px",
          divider: true,
        },
        {
          text: this.$tc("global.nome"),
          value: "nome",
          divider: true,
        },
        {
          text: this.$tc("global.label"),
          value: "obj_label",
          width: "170px",
          divider: true,
        },
        {
          text: this.$tc("global.coluna"),
          value: "obj_data",
          width: "170px",
          divider: true,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          width: "100px",
          divider: true,
        },
        {
          text: this.$tc("global.data-criacao"),
          value: "created_at",
          width: "150px",
          divider: true,
        },
        {
          text: this.$tc("global.data-atualizacao"),
          value: "updated_at",
          width: "150px",
        },
      ];
    },
  },

  methods: {
    fetchLayoutItems() {
      this.loading = true;
      fetchPdfLayoutItems(this.pdfLayout.id)
        .then((response) => {
          let pdf_layout_items = [];
          if (this.pdfLayout.obj_imagem1 && this.pdfLayout.obj_imagem1.status) {
            let item = {
              nome: "Imagem 1",
              posicao: 1,
              expand: true,
              cor: "tabs",
              cabecalho: response.filter(
                (item) => item.grupo === "cabecalho" && item.posicao == 1
              ),
              items: response.filter(
                (item) => item.grupo === "items" && item.posicao == 1
              ),
              rodape: response.filter(
                (item) => item.grupo === "rodape" && item.posicao == 1
              ),
            };

            pdf_layout_items.push(item);
          }

          if (this.pdfLayout.obj_imagem2 && this.pdfLayout.obj_imagem2.status) {
            let item = {
              nome: "Imagem 2",
              posicao: 2,
              expand: true,
              cor: "tocs_gray_1",
              cabecalho: response.filter(
                (item) => item.grupo === "cabecalho" && item.posicao == 2
              ),
              items: response.filter(
                (item) => item.grupo === "items" && item.posicao == 2
              ),
              rodape: response.filter(
                (item) => item.grupo === "rodape" && item.posicao == 2
              ),
            };

            pdf_layout_items.push(item);
          }

          if (this.pdfLayout.obj_imagem3 && this.pdfLayout.obj_imagem3.status) {
            let item = {
              nome: "Imagem 3",
              posicao: 3,
              expand: true,
              cor: "tabs2",
              cabecalho: response.filter(
                (item) => item.grupo === "cabecalho" && item.posicao == 3
              ),
              items: response.filter(
                (item) => item.grupo === "items" && item.posicao == 3
              ),
              rodape: response.filter(
                (item) => item.grupo === "rodape" && item.posicao == 3
              ),
            };

            pdf_layout_items.push(item);
          }

          this.pdf_layout_items = pdf_layout_items;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToPdfLayoutItem(item) {
      this.pdf_layout_item_posicao = null;
      this.pdf_layout_item_grupo = null;
      this.pdf_layout_item = item;
      this.edit_pdf_layout_item = true;
      this.dialogAddPdfLayoutItem = true;
    },

    AddPdfLayoutItem(posicao, grupo) {
      this.pdf_layout_item_posicao = posicao;
      this.pdf_layout_item_grupo = grupo;
      this.pdf_layout_item = null;
      this.edit_pdf_layout_item = false;
      this.dialogAddPdfLayoutItem = true;
    },

    importarPdfLayoutItems(posicao_para, grupo) {
      this.pdf_layout_items_import_para = posicao_para;
      this.pdf_layout_items_import_grupo = grupo;
      this.dialogImportPdfLayoutItems = true;
    },
  },

  mounted() {
    this.fetchLayoutItems();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .session--active .v-icon {
  transform: rotate(-180deg);
}
</style>
