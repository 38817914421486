<template>
  <v-dialog
    v-model="dialogDevolucaoCheque"
    @click:outside="$emit('update:dialogDevolucaoCheque', false)"
    @keydown.esc="$emit('update:dialogDevolucaoCheque', false)"
    width="1350px"
    scrollable
  >
    <v-card flat :loading="loading">
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.devolucao") }} {{ $tc("global.cheque") }}

        <v-spacer></v-spacer>
        <div class="text-center font-weight-bold">
          <v-chip
            v-if="moeda_cheque_devolvido === 'G$'"
            color="tocs_blue"
            class="mr-2"
            outlined
            label
            small
          >
            {{ $tc("global.total") }} : {{ moeda_cheque_devolvido }}
            {{ totalValor | guarani }}
          </v-chip>
          <v-chip v-else label small class="mr-2" color="tocs_blue" outlined>
            {{ $tc("global.total") }} : {{ moeda_cheque_devolvido }}
            {{ totalValor | currency }}
          </v-chip>

          <v-chip
            v-if="moeda_cheque_devolvido === 'G$'"
            color="tocs_blue"
            class="mr-2"
            outlined
            label
            small
          >
            {{ $tc("global.restante") }} : {{ moeda_cheque_devolvido }}
            {{ totalRestante | guarani }}
          </v-chip>
          <v-chip v-else label small class="mr-2" color="tocs_blue" outlined>
            {{ $tc("global.restante") }} : {{ moeda_cheque_devolvido }}
            {{ totalRestante | currency }}
          </v-chip>

          <v-chip
            v-if="moeda_cheque_devolvido === 'G$'"
            color="tocs_blue"
            class="mr-2"
            outlined
            small
            label
          >
            {{ $tc("global.recebido") }} : {{ moeda_cheque_devolvido }}
            {{ totalPagamentos | guarani }}
          </v-chip>
          <v-chip v-else label small class="mr-2" color="tocs_blue" outlined>
            {{ $tc("global.recebido") }} : {{ moeda_cheque_devolvido }}
            {{ totalPagamentos | currency }}
          </v-chip>
        </div>
        <v-btn icon @click="$emit('update:dialogDevolucaoCheque', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- MOTIVO DEVOLUCAO -->
      <v-container fluid>
        <v-row>
          <v-col cols="12" :md="motivoDevolucao === 'Otro' ? '6' : '12'">
            <v-select
              v-model="motivoDevolucao"
              :items="motivosDevolucao"
              :label="$tc('global.motivo') + ' ' + $tc('global.devolucao')"
              :error="errorMotivoDevolucao"
              class="mx-3"
              outlined
              dense
              hide-details
            >
            </v-select>
          </v-col>
          <v-col v-if="motivoDevolucao === 'Otro'" cols="12" md="6">
            <v-textarea
              v-model="motivoDevolucaoOtro"
              :error="errorMotivoDevolucao"
              :label="$tc('global.motivo') + ' ' + $tc('global.devolucao')"
              :rules="formRulesMin"
              dense
              hide-details
              counter="6"
              outlined
              rows="1"
              class="mx-3"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <v-card-text>
        <v-card flat class="px-4 pt-4" outlined>
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense>
              <v-col cols="12" md="3">
                <v-combobox
                  v-model="selectedBanco"
                  :items="bancos"
                  :label="$tc('global.banco', 2)"
                  dense
                  outlined
                  :rules="formRules"
                  :loading="loadingBancos"
                  required
                  hide-details
                  item-text="descricao"
                  item-value="id"
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cheque.conta"
                  :rules="formRules"
                  :label="$tc('global.conta')"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cheque.numero"
                  :label="$tc('global.numero') + '-' + $tc('global.cheque')"
                  :rules="formRules"
                  dense
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="selectedTipo"
                  :label="$tc('global.tipo')"
                  :items="tipos"
                  dense
                  hide-details
                  :rules="formRules"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="cheque.emissor_nome"
                  :label="$tc('global.emitidoPor')"
                  :rules="formRules"
                  dense
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hide-details
                  v-model="cheque.emissor_ci"
                  :label="$tc('global.documento') + ' ' + $tc('global.emissor')"
                  :rules="formRules"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-combobox
                  hide-details
                  v-model="selectedMoeda"
                  :label="$tc('global.moeda', 2)"
                  :items="moedas"
                  :rules="formRules"
                  item-text="sigla"
                  item-value="id"
                  dense
                  outlined
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  ref="valor"
                  v-model="valor"
                  :label="$tc('global.valor')"
                  :disabled="!selectedMoeda ? true : false"
                  :rules="formRules"
                  v-currency="vCurrencyOptions"
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <DataField
                  hide_details
                  :disabled="selectedTipo == 'A VISTA'"
                  :data_sync.sync="data_emissao"
                  :label="$tc('global.data-emissao')"
                  :obrigatorio="formRules ? true : false"
                  :key="data_emissao"
                />
              </v-col>
              <v-col cols="12" md="6">
                <DataField
                  hide_details
                  :data_sync.sync="data_vencimento"
                  :label="$tc('global.data-vencimento')"
                  :obrigatorio="formRules ? true : false"
                  :key="data_vencimento"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              color="button_1"
              class="white--text"
              depressed
              small
              :loading="loadingAdicionarCheque"
              @click="adicionarCheque"
              :disabled="!validForm"
            >
              {{ $tc("global.add") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card outlined class="mt-4">
          <v-data-table
            :headers="headers"
            :items="cheques"
            :loading="loading"
            class="data-tables"
            hide-default-footer
          >
            <template v-slot:item.data_emissao="{ item }">
              {{ item.data_emissao | dateFormat("dd/MM/yyyy") }}
            </template>

            <template v-slot:item.data_vencimento="{ item }">
              {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
            </template>

            <template v-slot:item.valor="{ item }">
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.valor | guarani }}
              </span>
              <span v-else> {{ item.valor | currency }} </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn icon small @click.stop="removerCheque(item)" class="mr-4">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogDevolucaoCheque', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          :disabled="disabledButtonDevolucao"
          :loading="loadingFinalizarDevolucao"
          @click="finalizarDevolucaoCheque"
          color="button_2"
          class="white--text"
          depressed
          small
        >
          {{ $tc("global.devolver") }}
        </v-btn>
      </v-card-actions>
      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        title="CHEQUE"
        @close="closeDevolucaoCheques()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import {
  fetchCheque,
  chequeIsValid,
  devolucaoCheque,
} from "@/api/cheques/cheques.js";
import { inputRequired, minCaracter } from "@/helpers/utils.js";
import { fetchBancos } from "@/api/bancos/bancos.js";
import { mapState } from "vuex";
import { format } from "date-fns";

const importComprovanteCheque = () =>
  import(
    /* webpackChunkName: "comprovanteCheque" */ "@/components/recibo/reports/reciboDevolucaoChequePdf.js"
  );

export default {
  name: "DialogDevolucaoCheque",

  props: {
    dialogDevolucaoCheque: {
      type: Boolean,
      default: false,
    },
    selectedCheque: {
      type: [String, Number],
      default: null,
    },
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      loading: false,
      cheques: [],
      cheque: {
        valor: 0,
      },
      chequeDevolvido: {},
      validForm: true,
      formRules: [inputRequired],
      formRulesMin: [minCaracter],
      selectedTipo: null,
      errorMotivoDevolucao: false,
      data_emissao: null,
      data_vencimento: null,
      selectedMoeda: null,
      selectedBanco: null,
      loadingBancos: true,
      valor: 0,
      tipos: ["A VISTA", "DIFERIDO"],
      bancos: [],
      loadingFinalizarDevolucao: false,
      loadingAdicionarCheque: false,
      motivoDevolucao: "",
      motivoDevolucaoOtro: "",
      motivosDevolucao: [
        "Insuficiencia de Fondos",
        "Cuenta Cancelada",
        "Firma Deficiente",
        "Orden de no Pago",
        "Presentación Extemporánea",
        "No Válido como Cheque Bancario",
        "Endoso Deficiente",
        "Texto Deficiente",
        "Fecha Deficiente",
        "Inhabilitado para operar en Cta. Cte.",
        "Firmante Inhabilitado para operar en Cta. Cte.",
        "No Compensable Acudir al Banco Emisor",
        "Otro",
      ],
      cambio_consolidado: 1,
      moeda_cheque_devolvido: "",
      pdfDialog: false,
      pdfData: "",
    };
  },

  watch: {
    motivoDevolucao() {
      if (this.motivoDevolucao != "Otro") {
        this.motivoDevolucaoOtro = "";
      }
    },
    valor() {
      if (this.valor && this.cheque.valor != this.valor) {
        this.cheque.valor = this.$ci.parse(this.valor, this.vCurrencyOptions);
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.cheque.moeda_id = this.selectedMoeda.id_moeda;
        this.cheque.moeda_sigla = this.selectedMoeda.sigla;
      }
    },
    selectedBanco() {
      if (this.selectedBanco) {
        this.cheque.banco_id = this.selectedBanco.id;
        this.cheque.banco_descricao = this.selectedBanco.descricao;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.cheque.tipo = this.selectedTipo;
        if (this.selectedTipo == "A VISTA" && !this.editar) {
          this.data_emissao = format(new Date(), "yyyy-MM-dd");
        }
      }
    },
    data_emissao() {
      if (this.data_emissao) {
        this.cheque.data_emissao = this.data_emissao;
      }
    },
    data_vencimento() {
      if (this.data_vencimento) {
        this.cheque.data_vencimento = this.data_vencimento;
      }
    },
  },

  computed: {
    totalValor() {
      let total = this.chequeDevolvido.valor;
      return total;
    },

    totalPagamentos() {
      let total = this.cheques.reduce((accumulator, item) => {
        return accumulator + item.consolidado;
      }, 0);
      return total;
    },

    totalRestante() {
      let total = this.totalValor - this.totalPagamentos;
      if (total > 0) {
        return total;
      } else {
        return 0;
      }
    },

    headers() {
      return [
        {
          text: this.$tc("global.data-emissao"),
          class: "tocs_gray_1",
          align: "start",
          value: "data_emissao",
          width: "140px",
          sortable: false,
        },
        {
          text: this.$tc("global.vencimento"),
          class: "tocs_gray_1",
          align: "start",
          value: "data_vencimento",
          width: "100px",
          sortable: false,
        },
        {
          text: this.$tc("global.origem"),
          class: "tocs_gray_1",
          value: "origem",
          sortable: false,
        },
        {
          text: this.$tc("global.nome"),
          class: "tocs_gray_1",
          value: "emissor_nome",
          sortable: false,
        },
        {
          text: this.$tc("global.banco"),
          class: "tocs_gray_1",
          value: "banco_descricao",
          sortable: false,
        },
        {
          text: this.$tc("global.numero"),
          class: "tocs_gray_1",
          value: "numero",
          sortable: false,
        },
        {
          text: this.$tc("global.tipo"),
          class: "tocs_gray_1",
          align: "right",
          value: "tipo",
          sortable: false,
        },
        {
          text: this.$tc("global.moeda"),
          class: "tocs_gray_1",
          value: "moeda_sigla",
          align: "right",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          class: "tocs_gray_1",
          value: "valor",
          align: "right",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
          align: "center",
          width: "100px",
          class: "tocs_gray_1",
          divider: true,
        },
      ];
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    ...mapState("Cambio", {
      cambio: (state) => state.cambio,
    }),
    vCurrencyOptions() {
      return {
        precision:
          this.selectedMoeda && this.selectedMoeda.sigla == "G$"
            ? 0
            : undefined,

        distractionFree: false,
      };
    },
    disabledButtonDevolucao() {
      let result = false;
      if (this.cheques.length == 0) {
        result = true;
      }
      if (
        this.motivoDevolucao.length == 0 ||
        (this.motivoDevolucao == "Otro" && !this.motivoDevolucaoOtro)
      ) {
        result = true;
      }
      if (
        this.motivoDevolucao == "Otro" &&
        this.motivoDevolucaoOtro.length < 6
      ) {
        result = true;
      }
      if (this.totalRestante != 0) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    cambioResolve(moeda_origem, valor_origem, moeda_destino, valor_destino) {
      let result = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        result = valor_origem / valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        result = valor_destino * valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      if (
        (moeda_origem === "U$" && moeda_destino === "U$") ||
        (moeda_origem === "R$" && moeda_destino === "R$") ||
        (moeda_origem === "G$" && moeda_destino === "G$")
      ) {
        result = valor_origem * valor_destino;
      }

      return result ? result + Number.EPSILON : result;
    },
    setCambio() {
      if (
        (this.selectedMoeda.sigla === "G$" &&
          this.moeda_cheque_devolvido === "G$") ||
        (this.selectedMoeda.sigla === "R$" &&
          this.moeda_cheque_devolvido === "R$") ||
        (this.selectedMoeda.sigla === "U$" &&
          this.moeda_cheque_devolvido === "U$")
      ) {
        this.cambio_consolidado = 1;
      }

      if (
        (this.selectedMoeda.sigla === "G$" &&
          this.moeda_cheque_devolvido === "U$") ||
        (this.selectedMoeda.sigla === "U$" &&
          this.moeda_cheque_devolvido === "G$")
      ) {
        this.cambio_consolidado = this.cambio.guarani_venda;
      }

      if (
        (this.selectedMoeda.sigla === "G$" &&
          this.moeda_cheque_devolvido === "R$") ||
        (this.selectedMoeda.sigla === "R$" &&
          this.moeda_cheque_devolvido === "G$")
      ) {
        this.cambio_consolidado = this.cambio.guarani_real;
      }

      if (
        (this.selectedMoeda.sigla === "R$" &&
          this.moeda_cheque_devolvido === "U$") ||
        (this.selectedMoeda.sigla === "U$" &&
          this.moeda_cheque_devolvido === "R$")
      ) {
        this.cambio_consolidado = this.cambio.real_venda;
      }
    },
    removerCheque(cheque) {
      this.cheques.splice(this.cheques.indexOf(cheque), 1);
    },
    adicionarCheque() {
      this.loadingAdicionarCheque = true;
      if (this.$refs.form.validate()) {
        chequeIsValid(this.cheque)
          .then((response) => {
            if (response.status === 200) {
              this.setCambio();
              let consolidado_cheque = this.cambioResolve(
                this.selectedMoeda.sigla,
                this.cheque.valor,
                this.moeda_cheque_devolvido,
                Number(this.cambio_consolidado)
              );

              this.cheque.consolidado = consolidado_cheque;
              this.cheque.origem = "DEVOLUCAO";
              this.cheque.origem_id = this.selectedCheque;
              this.cheques.push(this.cheque);
              this.cheque = {};
              (this.data_emissao = null),
                (this.data_vencimento = null),
                (this.selectedMoeda = null),
                (this.selectedBanco = null),
                (this.selectedTipo = null),
                (this.valor = 0);
              this.$nextTick(() => {
                this.$refs.form.reset();
                this.loadingAdicionarCheque = false;
              });
            }
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error(this.$tc("global.chequeNumeroExistente"));
              this.loadingAdicionarCheque = false;
            }
          });
      } else {
        this.loadingAdicionarCheque = false;
      }
    },
    finalizarDevolucaoCheque() {
      this.loadingFinalizarDevolucao = true;
      if (this.motivoDevolucao.length > 0) {
        let dados_devolucao = {
          cheque_pai_id: this.selectedCheque,
          motivo_devolucao: this.motivoDevolucao,
          cheques: this.cheques,
        };
        if (this.motivoDevolucao == "Otro") {
          dados_devolucao.motivo_devolucao = this.motivoDevolucaoOtro;
        }
        devolucaoCheque(dados_devolucao)
          .then((response) => {
            if (response.status === 200) {
              this.loadingFinalizarDevolucao = false;
              this.generateComprovante(dados_devolucao.cheque_pai_id);
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              this.loadingFinalizarDevolucao = false;
            }
          });
      } else {
        this.errorMotivoDevolucao = true;
        this.loadingFinalizarDevolucao = false;
      }
    },

    async generateComprovante(cheque_id) {
      this.loading = true;

      try {
        importComprovanteCheque()
          .then((module) => {
            let generate = module.generateReciboTransferenciaEstoque;
            generate(cheque_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    closeDevolucaoCheques() {
      this.$emit("update:dialogDevolucaoCheque", false);
      this.$emit("fetch-cheques");
    },
    getBancos() {
      this.loadingBancos = true;
      return fetchBancos()
        .then((response) => {
          this.bancos = response;
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingBancos = false;
        });
    },
    getCheque() {
      this.loading = true;
      return fetchCheque(this.selectedCheque)
        .then((response) => {
          this.chequeDevolvido = response;
          this.selectedMoeda = this.moedas.find(
            (moeda) => moeda.id_moeda === this.chequeDevolvido.moeda_id
          );
          let valor_cheque_devolvido = this.chequeDevolvido.valor;
          this.$ci.setValue(this.$refs.valor, valor_cheque_devolvido);
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getCheque();
    await this.getBancos();
    let moeda_sigla = this.moedas?.find(
      (item) => item.id_moeda == this.chequeDevolvido.moeda_id
    );
    this.moeda_cheque_devolvido = moeda_sigla.sigla;
    this.moedas.forEach((moeda) => {
      if (moeda.sigla === "R$") {
        moeda.disabled = true;
      } else {
        moeda.disabled = false;
      }
    });
  },
};
</script>

<style></style>
