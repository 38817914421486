import api from "../axios_service.js";

export async function getFornecedores(filtros) {
  const response = await api.get(`/fornecedores${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchFornecedoresList(filtros) {
  const response = await api.get(`/fornecedores-list${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function searchFornecedores(filtros) {
  const response = await api.post(
    `/search-fornecedores${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getListFornecedores(filtros) {
  const response = await api.post(`/listafornecedores${filtros}`);
  return response.data.result;
}

// Busca um fornecedores
export async function getFornecedor(id) {
  const response = await api.get(`/fornecedores/${id}`);
  return response.data.result;
}

// Atualiza um fornecedor existente
export function putFornecedor(id, fornecedor) {
  return api.post(`/fornecedores/${id}`, fornecedor);
}

// Cria um fornecedor novo
export function postFornecedor(fornecedor) {
  return api.post("/fornecedores/add", fornecedor);
}

// Get Images
export async function getImages(id) {
  const response = await api.get(`/fornecedores/${id}/imgs`);
  return response.data.logo;
}

export async function fetchFornecedoresServicos(filtros) {
  const response = await api.get(
    `/fornecedores-servicos${filtros ? filtros : ""}`
  );
  return response.data.result;
}
