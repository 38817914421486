<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.fatura', 2)" icon="mdi-receipt" dark />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          @click="dialogTipoFaturas = true"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="faturas"
          :options.sync="options"
          :server-items-length="totalFaturas"
          :items-per-page="10"
          item-key="id"
          class="data-table data-tables__row-click"
          @click:row="goToFatura"
        >
          <!-- data -->
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>

          <!-- data_emissao -->
          <template v-slot:item.data_emissao="{ item }">
            {{ item.data_emissao | dateFormat("dd/MM/yyyy") }}
          </template>
          <!-- data_emissao -->
          <template v-slot:item.data_impressao="{ item }">
            {{ item.data_impressao | dateFormat("dd/MM/yyyy") }}
          </template>

          <!-- cliente_nome -->
          <template v-slot:item.cliente_nome="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.cliente_nome | excerpt(30) }}
                </div>
              </template>
              <span>{{ item.cliente_nome }}</span>
            </v-tooltip>
          </template>

          <!-- status -->
          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center justify-start">
              <v-sheet
                width="10px"
                height="10px"
                :class="{
                  tocs_blue: item.status === 1,
                  tocs_red: item.status === -1,
                  success: item.status === 3,
                }"
                style="border-radius: 3px"
                class="mr-2"
              >
              </v-sheet>
              <span v-if="item.status == -1">
                {{ $t("global.cancelada") }}
              </span>
              <span v-if="item.status == 1">
                {{ $t("global.aberta") }}
              </span>
              <span v-if="item.status == 3">
                {{ $t("global.recebida") }}
              </span>
            </div>
          </template>

          <!-- condpag -->
          <template v-slot:item.condpag="{ item }">
            <div v-if="item.condpag == '1'">{{ $tc("global.aVista") }}</div>
            <div v-if="item.condpag == '2'">{{ $tc("global.aPrazo") }}</div>
          </template>

          <!-- valor_total -->
          <template v-slot:item.valor_total="{ item }">
            <div v-if="item.moeda == 'G$'">
              {{ item.valor_total | guarani }}
            </div>
            <div v-else>
              {{ item.valor_total | currency }}
            </div>
          </template>

          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <div
              v-if="!item.timbrado_manual || item.tipo_fatura == 'NOTA_CREDITO'"
              class="d-flex align-center justify-left"
            >
              <v-btn
                v-if="!item.timbrado_auto_impressor"
                icon
                class="mr-2"
                @click.stop="
                  generateFatura(item.id, false, false, false, item.tipo_fatura)
                "
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  item.timbrado_auto_impressor && item.tipo_fatura == 'FATURAS'
                "
                icon
                class="mr-2"
                @click.stop="
                  generateFaturaAutoImpressor(
                    item.id,
                    false,
                    false,
                    false,
                    item.tipo_fatura
                  )
                "
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>

              <v-btn
                v-if="!item.timbrado_auto_impressor"
                icon
                class="mr-2"
                @click.stop="
                  generateFatura(item.id, true, false, false, item.tipo_fatura)
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  item.timbrado_auto_impressor && item.tipo_fatura == 'FATURAS'
                "
                icon
                class="mr-2"
                @click.stop="
                  generateFaturaAutoImpressor(
                    item.id,
                    true,
                    false,
                    false,
                    item.tipo_fatura
                  )
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>

              <v-btn
                icon
                @click.stop="
                  generateFatura(item.id, false, true, false, 'fatura')
                "
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
            <div v-else>TIMBRADO MANUAL</div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogAddFatura
      v-if="DialogAddFatura"
      :DialogAddFatura.sync="DialogAddFatura"
      @fetch-faturas="getFaturas"
    />
    <DialogAddFaturaManual
      v-if="dialogAddFaturaManual"
      :dialogAddFaturaManual.sync="dialogAddFaturaManual"
      @fetch-faturas="getFaturas"
    />
    <DialogAddNotaCreditoManual
      v-if="dialogAddNotaCreditoManual"
      :dialogAddNotaCreditoManual.sync="dialogAddNotaCreditoManual"
      @fetch-faturas="getFaturas"
    />

    <DialogTiposFaturas
      v-if="dialogTipoFaturas"
      :dialogTipoFaturas.sync="dialogTipoFaturas"
      @tipo-fatura="DialogAddFatura = true"
      @tipo-fatura-manual="dialogAddFaturaManual = true"
      @tipo-nota-credito-manual="dialogAddNotaCreditoManual = true"
    />

    <DialogFatura
      v-if="DialogFatura"
      :DialogFatura.sync="DialogFatura"
      :fatura_id="fatura_id"
      @fetch-faturas="getFaturas"
    />

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="FATURA"
    />
  </v-container>
</template>

<script>
import { fetchFaturas, dataImpressaoFatura } from "@/api/faturas/faturas.js";
import { mapState } from "vuex";
import { index } from "@/api/empresas/empresa_variaveis.js";

const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );

const importFaturaAutoImpressor = (route) =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/" + route + ".js");

export default {
  name: "Faturas",

  components: {
    DialogAddFatura: () => import("./components/DialogAddFatura.vue"),
    DialogAddFaturaManual: () =>
      import("./components/DialogAddFaturaManual.vue"),
    DialogAddNotaCreditoManual: () =>
      import("./components/DialogAddNotaCreditoManual.vue"),
    DialogTiposFaturas: () => import("./components/DialogTiposFaturas.vue"),
    DialogFatura: () => import("./components/DialogFatura.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      search: "",
      loading: true,
      faturas: [],
      fatura_id: null,
      totalFaturas: 0,
      options: {
        sortBy: ["data"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15,
      },
      DialogAddFatura: false,
      DialogFatura: false,
      pdfDialog: false,
      pdfData: "",
      dialogTipoFaturas: false,
      dialogAddNotaCreditoManual: false,
      dialogAddFaturaManual: false,
      route_fatura_auto_impressor: "",
    };
  },

  computed: {
    ...mapState("Usuario", {
      empresa: (state) => state.empresa,
    }),

    headers() {
      return [
        {
          text: "ID",
          align: "center",
          value: "id",
          width: "80px",
          sortable: false,
        },
        {
          text: this.$tc("global.numero"),
          align: "center",
          value: "numero",
          width: "113px",
        },
        {
          text: this.$t("global.data"),
          value: "data",
          width: "90px",
        },
        {
          text: this.$tc("global.data-emissao", 2),
          value: "data_emissao",
          width: "140px",
          sortable: true,
        },
        {
          text: this.$tc("global.data") + " " + this.$tc("global.impressao"),
          value: "data_impressao",
          width: "150px",
        },

        {
          text: this.$tc("global.nome"),
          value: "cliente_nome",
          sortable: false,
        },
        {
          text: "Status",
          width: "105px",
          value: "status",
          sortable: false,
        },
        {
          text: "Tipo",
          width: "105px",
          value: "tipo_fatura",
          sortable: false,
        },

        {
          text: this.$tc("global.pagamento"),
          value: "condpag",
          width: "100px",
          sortable: false,
        },

        {
          text: this.$tc("global.moeda"),
          align: "right",
          value: "moeda",
          width: "90px",
          sortable: false,
        },
        {
          text: this.$tc("global.total"),
          value: "valor_total",
          align: "right",
          width: "120px",
          divider: true,
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          width: "170px",
          sortable: false,
        },
      ];
    },

    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      if (this.search && this.search.length > 3) {
        queryString += `&search=${this.search}`;
      }
      return `${queryString}`;
    },
  },

  watch: {
    url() {
      this.getFaturas();
    },

    search() {
      if (this.search && this.search.length > 3) {
        this.getFaturas();
      }
    },

    options: {
      handler() {
        this.getFaturas();
      },
      deep: true,
    },
  },

  methods: {
    async getFaturas() {
      try {
        this.loading = true;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const response = await fetchFaturas(
          `?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}` +
            this.url
        );
        this.faturas = response.data;
        this.totalFaturas = response.total;
        if (this.options.itemsPerPage != Number(response.per_page)) {
          this.options.itemsPerPage = Number(response.per_page);
        }

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },

    goToFatura(item) {
      this.fatura_id = item.id;
      this.DialogFatura = true;
    },

    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }

      try {
        importFatura()
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async generateFaturaAutoImpressor(fatura_id, print, download, unica, tipo) {
      console.log(fatura_id, print, download, unica, tipo, "enviando");
      this.loading = true;

      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }

      try {
        importFaturaAutoImpressor(this.route_fatura_auto_impressor)
          .then((module) => {
            let generate = module.default;
            generate(fatura_id, print, download, unica, tipo).then(
              (response) => {
                this.pdfData = response;
                this.pdfDialog = true;
              }
            );
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    let variaveis = await index(`?empresa_id=${this.empresa.id}`);
    this.route_fatura_auto_impressor = variaveis.find(
      (variavel) => variavel.campo === "LAYOUT_AUTO_IMPRESSOR_FATURA"
    ).valor;
    console.log(this.route_fatura_auto_impressor);
  },
};
</script>

<style lang="scss" scoped></style>
