<template>
  <v-combobox
    class="ma-0"
    height="40px"
    :items="caixas_pdv"
    :label="$tc('global.caixa', 2) + ' PDV'"
    item-text="descricao"
    item-value="id"
    dense
    outlined
    hide-details
    v-model="selectedCaixasPdv"
    :loading="loadingCaixasPdv"
    clearable
    @click:clear="clearCaixasPdv"
  ></v-combobox>
</template>

<script>
import { fetchCaixas } from "@/api/caixas/caixas.js";
export default {
  name: "CaixasPdv",

  data() {
    return {
      caixas_pdv: [],
      selectedCaixasPdv: null,
      caixa_pdv: null,
      loadingCaixasPdv: true,
    };
  },

  watch: {
    selectedCaixasPdv() {
      if (this.selectedCaixasPdv) {
        this.caixa_pdv = this.selectedCaixasPdv.id;
      }
    },
    caixa_pdv() {
      if (this.caixa_pdv) {
        this.$store.commit(
          "FiltrosRelatorios/UPDATE_CAIXA_PDV",
          this.caixa_pdv
        );
      }
    },
  },

  methods: {
    clearCaixasPdv() {
      this.selectedCaixasPdv = null;
      this.caixa_pdv = null;
      this.$store.commit("FiltrosRelatorios/UPDATE_CAIXA_PDV", null);
    },
    getCaixas() {
      fetchCaixas("?tipo_caixa=PDV")
        .then((response) => {
          this.caixas_pdv = response;
          if (this.$route.query.caixa_pdv) {
            let caixa_pdv = this.caixas_pdv.find(
              (item) => item.id == this.$route.query.caixa_pdv
            );
            this.selectedCaixasPdv = caixa_pdv;
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingCaixasPdv = false;
        });
    },
  },

  mounted() {
    this.getCaixas();
  },
};
</script>

<style></style>
