import api from "../axios_service.js";

// Produtos
export async function fetchAllComprasTiposDespesas(filtro) {
  const response = await api.get(
    `/compras-tipos-despesas${filtro ? filtro : ""}`
  );
  return response.data.result;
}

export async function fetchComprasTiposDespesas(id) {
  const response = await api.get(`/compras-tipos-despesas/${id}`);
  return response.data.result;
}

export function putComprasTiposDespesas(id, marca) {
  return api.put(`/compras-tipos-despesas/${id}`, marca);
}

export function postComprasTiposDespesas(marca) {
  return api.post("/compras-tipos-despesas/add", marca);
}
