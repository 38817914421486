<template>
  <v-select
    v-model="status"
    :items="campos"
    clearable
    @click:clear="clearStatus"
    outlined
    dense
    hide-details
    label="Status"
  ></v-select>
</template>

<script>
export default {
  name: "Status",
  props: {
    campos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      status: null,
    };
  },
  watch: {
    status() {
      if (this.status) {
        this.$store.commit("Filtros/UPDATE_STATUS", this.status);
      }
    },
  },
  methods: {
    clearStatus() {
      this.$store.commit("Filtros/UPDATE_STATUS", null);
      this.status = null;
    },
  },
  mounted() {
    if (this.$route.query.status) {
      let status = this.campos.find(
        (item) => item.value == this.$route.query.status
      );
      this.status = status.value;
    }
  },
};
</script>

<style></style>
