<template>
  <div id="app">
    <transition mode="out-in">
      <router-view v-if="!loading" />
      <v-app v-else>
        <v-main>
          <v-container fluid class="fill-height pa-0">
            <Carregando />
          </v-container>
        </v-main>
      </v-app>
    </transition>
  </div>
</template>

<script>
import { loadLanguageAsync } from "@/plugins/i18n.js";
import { mapState } from "vuex";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      auto_redirect: (state) => state.auto_redirect,
      usuario: (state) => state.usuario,
      empresa: (state) => state.empresa,
    }),
    ...mapState("Componentes", {
      componentes: (state) => state.componentes,
    }),
  },

  async mounted() {
    try {
      const locale = this.$ls.get(
        "locale",
        window.navigator.language.substr(0, 2)
      );

      await loadLanguageAsync(locale);
      this.$store.commit("UPDATE_LOCALE", locale);
      this.$vuetify.lang.current = locale;

      const token = this.$ls.get("token");

      if (token) {
        if (this.empresa) {
          await this.$store.dispatch("Moedas/getMoedas");
          await this.$store.dispatch("ListasPreco/getListas");
        }
      } else {
        if (this.$route.path != "/login") {
          this.$router.push({ path: "/login" });
        }
      }

      const template = this.$ls.get("template", "light");
      if (template) {
        if (template === "light") {
          this.$vuetify.theme.dark = false;
          this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        } else {
          this.$vuetify.theme.dark = true;
          this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        }
      } else {
        this.$ls.set("template", "light");
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.theme--dark.v-sheet {
  color: #eeeeee !important;
}

.theme--dark.v-icon {
  color: #eeeeee !important;
}

.panel-gradient-bg {
  background: rgb(35, 45, 88);
  background: linear-gradient(
    180deg,
    rgba(35, 45, 88, 1) 0%,
    rgba(34, 57, 129, 1) 100%
  );
}

.panel-gradient-bg-reverse {
  background: rgb(35, 45, 88);
  background: linear-gradient(
    0deg,
    rgba(35, 45, 88, 1) 0%,
    rgba(34, 57, 129, 1) 100%
  );
}

.panel-gradient-bg-dark {
  background: rgb(15, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(15, 15, 15, 1) 0%,
    rgba(33, 33, 33, 1) 50%,
    rgba(15, 15, 15, 1) 100%
  );
}

.pattern-bg {
  background-color: #eeeeee !important;
  // background-image: url("./assets/pattern.svg") !important;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
  // background-image: url("./assets/pattern_dark.svg") !important;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.data-tables__row-click {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
.data-tables__row-click-error {
  tbody {
    tr {
      cursor: no-drop;
    }
  }
}

.v-enter,
.v-leave-to {
  transform: translate3d(-30px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.8s;
}

.v-input input {
  height: 32px !important;
}

.v-overflow-btn .v-input__slot {
  border-top: none !important;
  border-width: 1px 0 !important;
  border-color: rgba(0, 0, 0, 0.42) !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(0, 0, 0, 0.12) !important;
}

.v-btn--outlined {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.v-data-table-header-mobile {
  display: none;
}

.v-data-table {
  th {
    font-size: 0.7rem !important;
    height: 32px !important;
    text-transform: uppercase;
  }
  td {
    font-size: 0.68rem !important;
    height: 32px !important;
  }
  .v-data-table__mobile-table-row {
    display: flex !important;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 15px !important;
    overflow: hidden;
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
    border-left: thin solid rgba(0, 0, 0, 0.12) !important;
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
    padding: 0;
    border-radius: 4px;
  }

  .v-data-table__mobile-row {
    flex: 1 auto;
    height: auto !important;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 0px !important;
    padding: 8px !important;
    border: thin solid rgba(0, 0, 0, 0.12) !important;
    margin: -1px -1px 0 -1px;

    .v-data-table__mobile-row__header {
      font-size: 0.7rem;
    }

    .v-data-table__mobile-row__cell {
      font-size: 0.68rem;
      width: 100%;
      text-align: left;
    }

    &:last-child {
      // border-bottom: none !important;
      margin-bottom: 0px;
    }
  }
}

.v-text-field input,
.v-textarea textarea {
  font-size: 14px;
}

.container.bg_white {
  background-color: #ffffff !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-left: solid thin rgba(0, 0, 0, 0.12);
  border-right: solid thin rgba(0, 0, 0, 0.12);
}

.textarea-mobile {
  .v-input__slot {
    padding: 12px !important;
  }
  textarea {
    font-size: 12px !important;
    line-height: 1.5 !important;
    margin-top: 0 !important;
  }
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 30px !important;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 2px !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 8px !important;
}
::-webkit-scrollbar {
  display: flex;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #484545;
}

::-webkit-scrollbar-thumb {
  background: #02058e;
  border: solid 1px #000000;
  // border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(1, 3, 122);
}

html .light ::-webkit-scrollbar {
  width: 6px;
}

html .light ::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

html .light ::-webkit-scrollbar-thumb {
  background: var(--v-primary-base) !important;
  border: solid 1px #e6e6e6;
  border-radius: 7px;
}

html .light ::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>
