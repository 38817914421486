<template>
  <v-dialog v-model="dialogPrinter" persistent width="550px">
    <v-card>
      <v-card-title class="px-6 toolbar">
        <span class="white--text"> Deseja imprimir? </span>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            @click="pdfFatura"
            :loading="loading_fatura"
            color="tocs_gray_1"
            class="mt-4 vertical-button"
            :disabled="fatura_id ? false : true"
            small
          >
            <div class="d-block">
              <div>
                <v-icon class="pt-2" size="25" color="tocs_gray_2"
                  >mdi-receipt</v-icon
                >
              </div>
              <div>
                <span class="pb-2 font-weight-bold">
                  {{ $tc("global.fatura") }}
                </span>
              </div>
            </div>
          </v-btn>
          <v-btn
            @click="pdfTicket"
            :loading="loading_ticket"
            color="tocs_gray_1"
            class="mt-4 vertical-button"
            small
          >
            <div class="d-block">
              <div>
                <v-icon class="pt-2" size="25" color="tocs_gray_2"
                  >mdi-ticket-confirmation-outline</v-icon
                >
              </div>
              <div><span class="pb-2 font-weight-bold"> Ticket </span></div>
            </div>
          </v-btn>

          <v-btn
            @click="openPagare"
            :loading="loading_pagare"
            :disabled="venda_condicao_pagamento == 2 ? false : true"
            color="tocs_gray_1"
            class="mt-4 vertical-button"
            small
          >
            <div class="d-block">
              <div>
                <v-icon class="pt-2" size="25" color="tocs_gray_2"
                  >mdi-file-document-outline</v-icon
                >
              </div>
              <div><span class="pb-2 font-weight-bold"> Pagaré </span></div>
            </div>
          </v-btn>
          <v-btn
            v-if="possui_contrato"
            @click="openContrato"
            :loading="loading_contrato"
            color="tocs_gray_1"
            class="mt-4 vertical-button"
            small
          >
            <div class="d-block">
              <div>
                <v-icon class="pt-2" size="25" color="tocs_gray_2"
                  >mdi-file-sign</v-icon
                >
              </div>
              <div>
                <span class="pb-2 font-weight-bold">
                  {{ $tc("global.contrato") }}
                </span>
              </div>
            </div>
          </v-btn>
        </div>
      </v-card-text>
      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        @close="loading = false"
        title="PDF"
      />
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fecharDialog" small class="white--text" color="button_2">
          OK
        </v-btn>
      </v-card-actions>
      <DialogAddDocumento
        :dialogAddDocumento.sync="dialogAddDocumento"
        :cliente_id="pagare.cliente_id"
        @open-pagare="openPagare"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { infoPagareVendaFinalizada } from "@/api/vendas/vendas.js";
import { dataImpressaoFatura, fetchFatura } from "@/api/faturas/faturas.js";
import { index } from "@/api/empresas/empresa_variaveis.js";
const generatePagare = () =>
  import(/* webpackChunkName: "pagaréPdf" */ "../reports/pagarePdf.js");

const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );

const importFaturaAutoImpressor = (route) =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/" + route + ".js");

const importTicket = () =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_ticket.js");

const pdfContratoGenerator = () =>
  import(
    /* webpackChunkName: "venda-pdf" */ "@/views/dashboard/contratos/reports/contrato_pdf.js"
  );

export default {
  name: "DialogPrinter",
  props: {
    dialogPrinter: {
      type: Boolean,
      default: false,
    },
    fatura_id: {
      type: [Number],
    },
    venda_id: {
      type: [Number],
    },
    venda_condicao_pagamento: {
      type: [Number, String],
    },
    possui_contrato: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogAddDocumento: () => import("./DialogAddDocumento.vue"),
  },

  data() {
    return {
      pagare: {},
      loading_fatura: false,
      loading_contrato: false,
      loading_pagare: false,
      loading_ticket: false,
      pdfDialog: false,
      pdfData: {},
      dialogAddDocumento: false,
    };
  },

  methods: {
    fecharDialog() {
      this.$emit("fechar-dialog");
    },

    async pdfFatura() {
      this.loading_fatura = true;

      let fatura = await fetchFatura(this.fatura_id);
      let variaveis = await index(`?empresa_id=${fatura.empresa_id}`);
      let route_fatura_auto_impressor = variaveis.find(
        (variavel) => variavel.campo === "LAYOUT_AUTO_IMPRESSOR_FATURA"
      ).valor;

      if (!fatura.timbrado_auto_impressor) {
        try {
          importFatura()
            .then((module) => {
              let generate = module.default;
              generate(this.fatura_id, true, false, false, "FATURA").then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;

                  dataImpressaoFatura(this.fatura_id)
                    .then(() => {})
                    .catch(() => {});
                }
              );
            })
            .finally(() => {
              this.loading_fatura = false;
            });
        } catch (error) {
          console.log(error);
          this.loading_fatura = false;
        }
      } else {
        try {
          importFaturaAutoImpressor(route_fatura_auto_impressor)
            .then((module) => {
              let generate = module.default;
              generate(this.fatura_id, true, false, false, "FATURA").then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;

                  dataImpressaoFatura(this.fatura_id)
                    .then(() => {})
                    .catch(() => {});
                }
              );
            })
            .finally(() => {
              this.loading_fatura = false;
            });

          this.loading_fatura = false;
        } catch (error) {
          console.log(error);
        }
      }
    },

    gerarPagare() {
      infoPagareVendaFinalizada(this.venda_id)
        .then((response) => {
          if (response.status === 200) {
            this.pagare = response.data.result;
          }
        })
        .catch(() => {});
    },

    async pdfTicket() {
      this.loading_ticket = true;

      try {
        importTicket()
          .then((module) => {
            let generate = module.default;
            generate(this.venda_id, false, false).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading_ticket = false;
          });

        this.loading_ticket = false;
      } catch (error) {
        console.log(error);
      }
    },

    async openContrato() {
      try {
        this.pdfContrato = true;
        this.loading_contrato = true;

        await pdfContratoGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.venda_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading_contrato = false;
          });
      } catch (error) {
        this.loading_contrato = false;
      }
    },

    openPagare() {
      this.loading_pagare = true;
      this.gerarPagare();
      if (!this.pagare.cliente_doc_ci || !this.pagare.cliente_doc_ruc) {
        this.$toast.error("Cliente não possui documento de identificação");
        this.dialogAddDocumento = true;
      } else {
        this.pdfPagare();
      }
      this.loading_pagare = false;
    },

    pdfPagare() {
      this.loading = true;
      generatePagare().then((module) => {
        let generate = module.generatePagare;
        generate(this.pagare)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {})
          .catch(() => {
            this.loading = false;
          });
      });
    },
  },

  mounted() {
    if (this.venda_condicao_pagamento == 2) {
      this.gerarPagare();
    }
  },
};
</script>

<style style="scss" scoped>
.vertical-button,
.vertical-button span.v-btn__content {
  height: 80px !important;
  width: 90px !important;
}
.vertical-button span.v-btn__content {
  flex-direction: column;
  justify-content: space-around;
}
</style>
