<template>
  <v-dialog
    v-model="dialogPermissao"
    @click:outside="$emit('update:dialogPermissao', false)"
    @keydown.esc="$emit('update:dialogPermissao', false)"
    max-width="1210px"
  >
    <v-card :loading="loading">
      <v-card-title class="text-subtitle-1 lightgray">
        <v-icon color="primary" left>mdi-account-lock</v-icon>

        {{ $tc("global.editar") }} {{ $tc("global.permissao") }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          left
          @click="$emit('update:dialogPermissao', false)"
          class="mr-1"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-6">
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" md="4">
              <v-card
                outlined
                height="40px"
                class="d-flex justify-space-between align-center"
              >
                <v-card-text class="text-body-1 py-0">
                  {{
                    `Status: ${
                      permissao.status
                        ? $tc("global.ativo")
                        : $tc("global.inativo")
                    }`
                  }}
                </v-card-text>
                <v-switch
                  class="ma-0"
                  hide-details
                  :color="permissao.status ? 'secondary' : 'success'"
                  v-model="permissao.status"
                ></v-switch>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model.lazy="permissao.componente"
                label="componente"
                :rules="[rules.required]"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="permissao.descricao"
                label="descrição"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                outlined
                class="ma-0"
                height="40px"
                :items="permissoes"
                :loading="loading"
                label="Pai Permissão"
                dense
                editable
                hide-details
                item-text="descricao"
                item-value="id"
                return-object
                v-model="permissao.permissao_id"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="permissao.descricao_detalhada"
                label="descrição"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-row class="px-3" align-content="stretch">
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> ACESSOS </v-subheader>
                  <v-divider class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.visualizar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="1"
                      label="Visualizar"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> CRUD </v-subheader>
                  <v-divider class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.adicionar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="2"
                      label="Adicionar"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.editar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="4"
                      label="Editar"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.deletar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="8"
                      label="Deletar"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.importar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="64"
                      label="Importar"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> IMPRESSÃO </v-subheader>
                  <v-divider class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.imprimir"
                      class="mt-0"
                      :false-value="0"
                      :true-value="16"
                      label="Imprimir"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.exportar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="32"
                      label="Exportar"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader> CUSTOMIZADO </v-subheader>
                  <v-divider class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.limitar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="128"
                      label="Limitar"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          class="white--text"
          color="button_2"
          :disabled="!valid"
          :loading="loading"
          @click="editPermissao"
        >
          Editar
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getPermissao, getPermissoes } from "@/api/permissoes/permissoes.js";
import updatePermissoes from "@/mixins/configuracoes/permissoes/updatePermissoes.js";
import updateBitMask from "@/mixins/configuracoes/permissoes/updateBitMask.js";
import checkUserPermissions from "@/mixins/configuracoes/permissoes/checkUserPermissions.js";
import checkPermissions from "@/mixins/configuracoes/permissoes/checkPermissions.js";

export default {
  name: "Permissao",

  props: {
    dialogPermissao: {
      type: Boolean,
      required: true,
    },
    permissao_id: {
      type: [Number, String],
      required: true,
    },
  },

  mixins: [
    updatePermissoes,
    updateBitMask,
    checkUserPermissions,
    checkPermissions,
  ],

  data: () => ({
    permissao: {
      bits: 0,
    },
    permissoes: [],
    show: false,
    valid: false,
    loading: true,
    notFound: false,
    original: {},
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => v.length >= 3 || "Minimo 4 caracteres",
    },
  }),

  computed: {
    id() {
      return this.permissao_id;
    },

    somaBits() {
      let soma =
        this.bitWise.visualizar +
        this.bitWise.adicionar +
        this.bitWise.editar +
        this.bitWise.deletar +
        this.bitWise.imprimir +
        this.bitWise.exportar +
        this.bitWise.importar +
        this.bitWise.limitar;

      return soma;
    },
  },

  watch: {},

  methods: {
    editPermissao() {
      this.updatePermissao(this.id);
      this.$emit("update:dialogPermissao", false);
      this.$emit("fetched-permissoes");
    },
    backHistory() {
      this.$router.go(-1);
    },
    fetchPermissoes(filtro) {
      return getPermissoes(filtro ? filtro : "")
        .then((response) => {
          response.forEach((resp) => {
            this.permissoes.push(resp);
            resp.children.forEach((child) => {
              this.permissoes.push(child);
            });
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await getPermissao(this.id)
      .then((response) => {
        this.permissao = response;
        this.original = { ...response };
        this.updateBitMasks(this.permissao.bits);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.notFound = "El permissao no ha sido encontrada";
        }
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
    await this.fetchPermissoes();
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
