<template>
  <v-dialog
    v-model="dialogAuth"
    width="280px"
    @click:outside="$emit('update:dialogAuth', false)"
    @keydown.esc="$emit('update:dialogAuth', false)"
  >
    <v-card flat>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        Confirmar Valor
      </v-card-title>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="auth"
                  label="Codigo De Autorizacao"
                  type="password"
                  outlined
                  dense
                  hide-details
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="autenticar"
          color="button_2"
          class="white--text"
          depressed
          small
        >
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { authDesconto } from "@/api/empresas/empresas";
export default {
  name: "DialogAuth",

  props: {
    dialogAuth: {
      type: Boolean,
      default: false,
    },
    authTabela: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      auth: null,
      validForm: true,
    };
  },

  methods: {
    autenticar() {
      let body = {
        cdg_autorizacao: this.auth,
      };
      authDesconto(body)
        .then((response) => {
          if (response.data.success) {
            if (this.authTabela) {
              this.$emit("update:dialogAuth", false);
              this.$emit("edit-produto");
            } else {
              this.$emit("update:dialogAuth", false);
              this.$emit("add-produto");
            }

            this.$emit("update:dialogAuth", false);
          } else {
            this.$toast.error("Senha incorreta");
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style></style>
