<template>
  <div class="py-5">
    <v-card outlined>
      <v-card-title
        :class="status_contas === true ? ' usermessage ' : 'lightgray'"
      >
        <span class="subtitle-1 pr-2 font-weight-bold">
          {{ cliente_nome }} - {{ $tc("global.contasselecionadas") }}
        </span>
        -
        <span
          v-if="status_contas === false"
          class="secondary--text font-weight-bold text-body-2 py-1 pl-2"
        >
          {{ $tc("global.pendentes") }}
        </span>

        <span v-if="status_contas === true" class="py-1 pl-2">
          <v-icon color="success"> mdi-check </v-icon>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          v-if="recibo_antecipo"
          small
          depressed
          color="primary"
          class="mx-2"
          :loading="loadingReciboAntecipo"
          @click="openReciboAntecipoPdf(recibo_antecipo)"
        >
          <v-icon left> mdi-alpha-r-box </v-icon>
          {{ $tc("global.recibo") }} {{ $tc("global.antecipo") }}
        </v-btn>

        <v-btn
          v-if="recibo"
          small
          depressed
          color="primary"
          class="mr-2"
          :loading="loading"
          @click="openReciboPdf(recibo)"
        >
          <v-icon left> mdi-alpha-r-box </v-icon>
          {{ $tc("global.recibo") }}
        </v-btn>

        <v-btn
          icon
          v-if="!disableEditor"
          @click="editarConta"
          :disabled="disableEditor"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          :class="`session${expand ? '--active' : ''}`"
          @click="expand = !expand"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card-title>
      <v-slide-y-transition>
        <v-card-text v-show="expand" class="py-8 px-7">
          <v-row>
            <!-- Resumo -->
            <v-col cols="12">
              <v-card outlined class="d-flex align-center justify-center">
                <v-card flat tile width="100%">
                  <v-card-title
                    class="text-subtitle-1 lightgray px-3 py-1 justify-center"
                  >
                    {{ $tc("global.devido") }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="py-1">
                    <div class="text-center text-h6">
                      <span v-if="moeda === 'G$'">
                        {{ moeda }} {{ totalValor | guarani }}
                      </span>
                      <span v-else>
                        {{ moeda }} {{ totalValor | currency }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
                <v-divider vertical></v-divider>

                <v-card flat tile width="100%">
                  <v-card-title
                    class="text-subtitle-1 lightgray px-3 py-1 justify-center"
                  >
                    {{ $tc("global.restante") }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="py-1">
                    <div class="text-center text-h6">
                      <span v-if="moeda === 'G$'">
                        {{ moeda }} {{ totalRestante | guarani }}
                      </span>
                      <span v-else>
                        {{ moeda }} {{ totalRestante | currency }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
                <v-divider vertical></v-divider>
                <v-card flat tile width="100%">
                  <v-card-title
                    class="text-subtitle-1 lightgray px-3 py-1 justify-center"
                  >
                    {{ $tc("global.recebido") }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="py-1">
                    <div class="text-center text-h6">
                      <span v-if="moeda === 'G$'">
                        {{ moeda }} {{ totalPagamentos | guarani }}
                      </span>
                      <span v-else>
                        {{ moeda }} {{ totalPagamentos | currency }}
                      </span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>

            <v-col cols="12" class="text-center">
              <div v-if="existe_contas_abertas" class="error--text text-h6">
                {{ $tc("global.avisoValorExcedenteComContasAbertas") }}
              </div>
              <v-data-table
                v-if="!loading"
                :headers="headers"
                :search="search.length >= 3 ? search : ''"
                :items="contas"
                sort-by="data_vencimento"
                :item-class="row_classes"
                :items-per-page="10"
                class="data-tables"
              >
                <template v-slot:item.valor="{ item }">
                  <span v-if="item.moeda_sigla === 'G$'">
                    G$ {{ item.saldo | guarani }}
                  </span>
                  <span v-else>
                    {{ item.moeda_sigla }} {{ item.saldo | currency }}
                  </span>
                </template>
                <template v-slot:item.desconto="{ item }">
                  <span v-if="item.moeda_sigla === 'G$'">
                    G$ {{ item.desconto | guarani }}
                  </span>
                  <span v-else>
                    {{ item.moeda_sigla }}$ {{ item.desconto | currency }}
                  </span>
                </template>
                <template v-slot:item.valor_pago="{ item }">
                  <span v-if="item.moeda_sigla === 'G$'"
                    >G$
                    {{ item.valor_pago | guarani }}
                  </span>
                  <span v-else>
                    {{ item.moeda_sigla }}
                    {{ item.valor_pago | currency }}
                  </span>
                </template>

                <template v-if="edit" v-slot:item.actions="{ index }">
                  <v-btn icon @click="removeContaCliente(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>

            <!-- Formas de Pagamento -->
            <v-col cols="12">
              <v-card outlined>
                <v-card-title class="text-subtitle-1 px-3 py-2 lightgray">
                  {{ $tc("global.formapagamento", 2) }}
                  <v-spacer></v-spacer>
                  <BaixarContasAddFormaPago
                    v-if="dialogBaixarContasAddFormaPago"
                    :dialogBaixarContasAddFormaPago.sync="
                      dialogBaixarContasAddFormaPago
                    "
                    @push-pagamento="pushPagamentoItens"
                    :valor-restante="totalRestante"
                    :moeda_conta="contas[0].moeda_sigla"
                    :tipo_baixa="tipo_baixa"
                    :cliente_id="cliente_id"
                  />
                  <v-btn
                    v-if="edit"
                    color="primary"
                    small
                    :disabled="recibo ? true : false"
                    @click="dialogBaixarContasAddFormaPago = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ $tc("global.add") }}
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0 text-center">
                  <v-data-table
                    :headers="headerFormaPagamentoItems"
                    :items="formaPagamentoItems"
                    :items-per-page="5"
                    disable-sort
                    class="data-tables data-tables__row-click"
                    :hide-default-footer="
                      formaPagamentoItems.length > 5 ? false : true
                    "
                  >
                    <template v-slot:item.valor="{ item }">
                      {{ item.moeda }}
                      <span v-if="item.moeda === 'G$'">
                        {{ item.valor | guarani }}
                      </span>
                      <span v-else>
                        {{ item.valor | currency }}
                      </span>
                    </template>

                    <template v-slot:item.consolidado="{ item, index }">
                      <div
                        v-if="!editConsolidado || indexEditConsolidado != index"
                      >
                        {{ moeda }}
                        <span v-if="moeda === 'G$'">
                          {{ item.consolidado | guarani }}
                        </span>
                        <span v-else>
                          {{ item.consolidado | currency }}
                        </span>
                        <v-btn
                          v-if="edit"
                          @click="editarConsolidado(item.consolidado, index)"
                          icon
                          x-small
                        >
                          <v-icon v-if="edit"> mdi-pencil-outline </v-icon>
                        </v-btn>
                      </div>

                      <v-text-field
                        v-show="
                          editConsolidado && indexEditConsolidado === index
                        "
                        v-model="consolidadoEdicao"
                        label="Consolidado"
                        ref="consolidadoEdicao"
                        outlined
                        dense
                        hide-details
                        style="width: 250px"
                        class="input-right my-1 ml-4"
                        v-currency="vCurrencyOptions"
                      >
                        <template v-slot:append>
                          <v-btn
                            v-show="
                              editConsolidado && indexEditConsolidado === index
                            "
                            @click="salvaConsolidado(index)"
                            icon
                            x-small
                          >
                            <v-icon> mdi-check </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </template>

                    <template v-if="edit" v-slot:item.actions="{ index }">
                      <v-btn icon @click="removeFormaPagamento(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-slide-y-transition>
      <v-divider></v-divider>
      <v-slide-y-transition>
        <v-card-actions v-show="expand">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            small
            color="button_2"
            class="white--text"
            :loading="baixandoContas"
            :disabled="disableSalvarContas"
            @click="salvarContas"
          >
            {{ $t("global.salvar") }}
            <v-icon right>mdi-content-save-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-slide-y-transition>
    </v-card>
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="RECIBO"
    />
  </div>
</template>

<script>
import {
  postCheckContasReceberPagas,
  postCheckContasAPagarPagas,
} from "@/api/baixa-contas/baixa_contas.js";
import { checkContasClientes } from "@/api/contas-receber/contas_receber.js";
const generateRecibo = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboPdf.js"
  );
const generateReciboAntecipo = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboAntecipoPdf.js"
  );
const generateReciboFiscal = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboFiscalPdf.js"
  );

import { mapState } from "vuex";

export default {
  name: "BaixarContas",

  props: {
    contas: {
      type: Array,
      required: true,
    },
    cliente_id: {
      type: Number,
    },
    fornecedor_id: {
      type: Number,
    },
    fatura_id: {
      type: Number,
    },
    cliente_nome: {
      type: String,
    },
    cliente_empresa: {
      type: Number,
    },
    recibo: {
      type: Object,
      default: null,
    },
    recibo_antecipo: {
      type: Object,
      default: null,
    },
    tipo_baixa: {
      type: String,
    },
  },

  components: {
    BaixarContasAddFormaPago: () => import("./BaixarContasAddFormaPago.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      pdfDialog: false,
      baixandoContas: false,
      loadingTipoContas: false,
      tipoContas: [],
      tipoContaSelected: null,
      formaPagamentoItems: [],
      dialogBaixarContasAddFormaPago: false,
      search: "",
      expand: true,
      loading: false,
      loadingReciboAntecipo: false,
      status_contas: false,
      edit: true,
      editConsolidado: false,
      consolidadoEdicao: null,
      indexEditConsolidado: null,
      pdfData: null,
      existe_contas_abertas: false,
    };
  },

  computed: {
    disableEditor() {
      let result = false;
      if (this.recibo) {
        result = true;
      } else if (this.edit) {
        result = false;
      }
      return result;
    },
    ...mapState("Usuario", {
      empresa: (state) => state.empresa.id,
    }),

    vCurrencyOptions() {
      return {
        precision: undefined,
        distractionFree: false,
      };
    },

    moeda() {
      return this.contas[0].moeda_sigla;
    },

    totalValor() {
      let total = this.contas.reduce((accumulator, item) => {
        let saldo = Number(item.saldo);

        return accumulator + saldo;
      }, 0);

      return total;
    },

    totalPagamentos() {
      let total = this.formaPagamentoItems.reduce((accumulator, item) => {
        return accumulator + item.consolidado;
      }, 0);

      return total;
    },

    totalRestante() {
      let total = this.totalValor - this.totalPagamentos;
      if (total > 0) {
        return total;
      } else {
        return 0;
      }
    },

    headerFormaPagamentoItems() {
      return [
        {
          text: this.$tc("global.formapagamento"),
          value: "forma_pagamento",
        },
        {
          text: this.$tc("global.destino"),
          value: "destino",
        },
        {
          text: this.$tc("global.valor"),
          align: "right",
          value: "valor",
        },
        {
          text: this.$tc("global.consolidado"),
          align: "right",
          width: "250px",
          value: "consolidado",
        },
        {
          text: "",
          align: "center",
          value: "actions",
        },
      ];
    },

    disableSalvarContas() {
      let result = false;

      if (Math.round(this.totalPagamentos) <= 0) {
        result = true;
      }

      if (
        Math.round(this.totalPagamentos) > Math.round(this.totalValor) &&
        this.existe_contas_abertas
      ) {
        result = true;
      }

      if (this.edit === false) {
        result = true;
      }

      if (this.existe_contas_abertas) {
        result = true;
      }

      return result;
    },

    headers() {
      return [
        {
          text: "ID",
          width: "70px",
          value: "id",
        },
        {
          text: this.$tc("global.vencimento"),
          width: "80px",
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.moeda"),
          width: "80px",
          value: `${this.moeda ? "moeda_sigla" : "moeda_nome"}`,
        },
        {
          text: this.$tc("global.valor"),
          width: "100px",
          align: "right",
          value: "valor",
        },
        {
          text: this.$tc("global.desconto"),
          width: "100px",
          align: "right",
          value: "desconto",
        },
        {
          text: this.$tc("global.desconto") + " " + this.$tc("global.pago"),
          width: "100px",
          align: "right",
          value: "valor_pago",
        },
        {
          text: "",
          width: "50px",
          value: "actions",
        },
      ];
    },
  },

  watch: {
    totalPagamentos() {
      if (this.totalPagamentos > this.totalValor) {
        this.verifyContasCliente(this.contas);
      }
    },
    totalRestante() {
      if (this.totalPagamentos > this.totalValor) {
        this.verifyContasCliente(this.contas);
      }
      if (this.totalPagamentos < this.totalValor) {
        this.existe_contas_abertas = false;
      }
    },
    contas() {
      if (this.contas.length) {
        this.formaPagamentoItems = [];
        let pagamento = {};
        pagamento.cambio = 1;
        pagamento.consolidado = this.totalValor;
        pagamento.destino = "CAIXA ADM";
        pagamento.destino_id = 2;
        pagamento.forma_de_pagamento_id = 1;
        pagamento.forma_pagamento = "DINHEIRO";
        pagamento.moeda = "G$";
        pagamento.valor = this.totalValor;
        this.formaPagamentoItems.push(pagamento);
      }
    },
  },

  methods: {
    async openReciboPdf(recibo) {
      this.loading = true;
      if (recibo.tipo == "RECEBIMENTO") {
        await generateReciboFiscal().then((module) => {
          let generate = module.generateReciboFiscal;
          generate(recibo.id)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        });
      } else {
        await generateRecibo().then((module) => {
          let generate = module.generateRecibo;
          generate(recibo.id)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        });
      }
    },

    async openReciboAntecipoPdf(recibo) {
      this.loadingReciboAntecipo = true;

      await generateReciboAntecipo().then((module) => {
        let generate = module.generateRecibo;
        generate(recibo.id)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loadingReciboAntecipo = false;
          })
          .catch((error) => {
            this.loadingReciboAntecipo = false;
          });
      });
    },

    editarConsolidado(item, index) {
      this.indexEditConsolidado = index;

      this.$ci.setValue(
        this.$refs.consolidadoEdicao,
        Number(this.formaPagamentoItems[this.indexEditConsolidado].consolidado)
      );

      this.editConsolidado = true;
    },

    salvaConsolidado(index) {
      this.formaPagamentoItems[index].consolidado = this.$ci.parse(
        this.consolidadoEdicao,
        this.vCurrencyOptions
      );
      this.indexEditConsolidado = null;
      this.consolidadoEdicao = null;
      this.editConsolidado = false;
    },

    editarConta() {
      this.edit = true;
      this.status_contas = false;
      this.expand = true;
      if (this.fornecedor_id) {
        this.$emit("editarConta", this.fornecedor_id);
      } else if (this.cliente_id) {
        this.$emit("editarConta", this.cliente_id);
      } else if (this.fatura_id) {
        this.$emit("editarConta", this.fatura_id);
      }
    },

    pushPagamentoItens(item) {
      this.formaPagamentoItems.push(item);
    },

    removeFormaPagamento(index) {
      this.formaPagamentoItems.splice(index, 1);
    },

    removeContaCliente(index) {
      this.contas.splice(index, 1);
      if (!this.contas.length) {
        this.$emit("deleteContaCliente");
      }
    },

    async salvarContas() {
      this.baixandoContas = true;
      if (this.fornecedor_id) {
        await postCheckContasAPagarPagas({ contas: this.contas })
          .then((response) => {
            if (response.data.result.status == true) {
              let body = {};
              body.contas = this.contas;
              body.forma_pagamentos = this.formaPagamentoItems;
              body.totalPagamentos = this.totalPagamentos;

              body.empresa_id = this.empresa;
              body.desconto = this.contas.reduce(
                (accumulator, item) => accumulator + Number(item.desconto),
                0
              );

              body.fornecedor_id = this.fornecedor_id;

              this.status_contas = true;

              this.$emit("updateContasBaixar", body);
              this.expand = false;
              this.edit = false;
              this.baixandoContas = false;
            } else {
              this.formaPagamentoItems = [];
              this.status_contas = false;

              this.contas.map((item) => {
                if (response.data.result.items.includes(item.id)) {
                  item.pago = true;
                }
              });
              this.$toast.error(
                `As contas ${response.data.result.items} já foram pagas`
              );
              this.baixandoContas = false;
            }
          })
          .catch(() => {
            this.baixandoContas = false;
          });
      } else if (this.cliente_id) {
        await postCheckContasReceberPagas({ contas: this.contas })
          .then((response) => {
            if (response.data.result.status == true) {
              let body = {};
              body.contas = this.contas;
              body.forma_pagamentos = this.formaPagamentoItems;
              body.totalPagamentos = this.totalPagamentos;

              body.empresa_id = this.empresa;
              body.desconto = this.contas.reduce(
                (accumulator, item) => accumulator + Number(item.desconto),
                0
              );

              body.cliente_id = this.cliente_id;

              this.status_contas = true;

              this.$emit("updateContasBaixar", body);
              this.expand = false;
              this.edit = false;
              this.baixandoContas = false;
            } else {
              this.formaPagamentoItems = [];
              this.status_contas = false;

              this.contas.map((item) => {
                if (response.data.result.items.includes(item.id)) {
                  item.pago = true;
                }
              });
              this.$toast.error(
                `As contas ${response.data.result.items} já foram pagas`
              );
              this.baixandoContas = false;
            }
          })
          .catch(() => {
            this.baixandoContas = false;
          });
      }
    },

    row_classes(item) {
      if (item.pago) {
        return ["table-row-error", "font-weight-bold"]; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    verifyContasCliente(contas_array) {
      let contas_ids = [];
      let contas = {};
      contas_array.forEach((conta) => {
        contas_ids.push(conta.id);
      });
      contas.contas_ids = contas_ids;
      checkContasClientes(this.cliente_id, contas)
        .then((response) => {
          this.existe_contas_abertas = response.data.exite_contas_abertas;
        })
        .catch(() => {});
    },
  },

  mounted() {
    if (this.contas.length) {
      this.contas.map((item) => {
        item.pago = false;
      });
      this.formaPagamentoItems = [];
      let pagamento = {};
      pagamento.cambio = 1;
      pagamento.consolidado = this.totalValor;
      pagamento.destino = "CAIXA ADM";
      pagamento.destino_id = 2;
      pagamento.forma_de_pagamento_id = 1;
      pagamento.forma_pagamento = "DINHEIRO";
      pagamento.moeda = "G$";
      pagamento.valor = this.totalValor;
      this.formaPagamentoItems.push(pagamento);
    }

    this.descricao = this.cliente_nome;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .session--active .v-icon {
  transform: rotate(-180deg);
}
::v-deep .table-row-error {
  background-color: #fbe2e2 !important;
  border-color: #fbe2e2 !important;
}
</style>
