import api from "../axios_service.js";

export async function fetchCaixas(filtros) {
  const response = await api.get(`/caixas${filtros ? filtros : ""}`);
  return response.data.result;
}
export async function fetchCaixasAcesso(filtros) {
  const response = await api.get(`/caixas-acesso${filtros ? filtros : ""}`);
  return response.data.result;
}
export async function fetchCaixasFaturamento(filtros) {
  const response = await api.get(
    `/caixas-faturamento${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchCaixa(id) {
  const response = await api.get(`/caixas/${id}`);
  return response.data.result;
}

export function putCaixa(id, marca) {
  return api.post(`/caixas/${id}`, marca);
}

export function postCaixa(marca) {
  return api.post("/caixas/add", marca);
}

export async function fetchCaixaNotEquall(id) {
  const response = await api.get(`/caixas/${id}/notequal`);
  return response.data.result;
}

export async function fetchCaixasOrigem(filtros) {
  const response = await api.get(`/caixas-origem${filtros ? filtros : ""}`);
  return response.data.result;
}
export async function fetchCaixasContaCorrente(filtros) {
  const response = await api.get(`/caixas-conta-corrente${filtros ? filtros : ""}`);
  return response.data.result;
}
export async function fetchCaixasGeral(filtros) {
  const response = await api.get(`/caixas-geral${filtros ? filtros : ""}`);
  return response.data.result;
}

export function postTransferenciaCaixaPdv(body) {
  return api.post("/caixa-transferencia-pdv", body);
}

export function postTransferenciaCaixa(body) {
  return api.post("/caixa-transferencia-move", body);
}

export function putCancelarRegistroVenda(id, motivo) {
  return api.post(`/caixas-af-cancelar-registro-venda/${id}`, motivo);
}
