<template>
  <v-autocomplete
    v-model="usuario"
    :items="usuarios"
    @keyup="buscaUsuarios"
    :search-input.sync="search"
    clearable
    @click:clear="clearUsuario"
    dense
    outlined
    hide-details
    hide-no-data
    hide-selected
    item-text="name"
    item-value="id"
    :label="$tc('global.usuario')"
    prepend-inner-icon="mdi-magnify"
  ></v-autocomplete>
</template>

<script>
import { searchUsers } from "@/api/usuarios/usuarios.js";

export default {
  name: "Usuario",

  data() {
    return {
      usuario: null,
      usuarios: [],
      search: "",
    };
  },

  computed: {
    empresa() {
      return this.$store.state.empresa;
    },
  },

  watch: {
    usuario() {
      if (this.usuario) {
        this.$store.commit("FiltrosRelatorios/UPDATE_USUARIO", this.usuario);
      }
    },
  },

  methods: {
    clearUsuario() {
      this.$store.commit("FiltrosRelatorios/UPDATE_USUARIO", null);
      this.usuario = null;
    },
    getUsuarios(filtros) {
      this.usuarios = null;
      return searchUsers(filtros)
        .then((response) => {
          this.usuarios = response;
        })
        .catch((error) => console.log(error));
    },
    buscaUsuarios(event) {
      if (this.search) {
        if (
          this.search.length > 2 &&
          event.key != "Backspace"
          // this.empresa != 3
        ) {
          this.getUsuarios(`?search=${this.search}`);
        }
      }
    },
  },

  mounted() {
    if (this.$route.query.usuario) {
      let filtro = `?search=id=${this.$route.query.usuario}`;
      searchUsers(filtro)
        .then((response) => {
          this.usuarios = response;
          this.usuario = response[0].id;
        })
        .catch(() => {});
    }
  },
};
</script>

<style></style>
