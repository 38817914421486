<template>
  <v-card :loading="loading" :disabled="loading" outlined>
    <v-autocomplete
      v-if="!loading"
      v-model="selectedCidade"
      :items="cidades"
      :loading="buscandoCidades"
      :search-input.sync="searchCidade"
      @keyup="buscaCidades"
      dense
      outlined
      hide-details
      item-text="nome"
      item-value="id"
      :label="$tc('global.cidade')"
      append-icon=""
      full-width
      return-object
      ref="searchCidade"
    >
      <template v-slot:no-data>
        <v-btn small @click="openAddCidade" text block class="primary--text">
          <v-icon>mdi-plus</v-icon>
          {{ $tc("global.add") + " " + $tc("global.cidade") }}
        </v-btn>
      </template>
    </v-autocomplete>
    <DialogCidade  
    :dialogCidade.sync="dialogCidade"
    :searchCidade.sync="searchCidade"
    :setarCidade.sync="setarCidade"
    @setaCidade="setaCidade"
     />
  </v-card>
</template>

<script>
import { searchCidades } from "@/api/cidades/cidades.js";
export default {
  name: "SearchCidades",

  components: {
    DialogCidade: () =>
      import("@/views/configuracoes/cidades/components/DialogCidade.vue"),
  },

  props: {
    cidade_sync: {
      type: [Object, String],
    },
    cidade_id: {
      type: [Number, String],
      default: null,
    },
    cidade_nome: {
      type: String,
      default: null,
    },

  },

  data() {
    return {
      loading: true,
      selectedCidade: null,
      cidades: [],
      buscandoCidades: false,
      searchCidade: "",
      dialogCidade: false,
      setarCidade: false,
    };
  },

  watch: {
    selectedCidade() {
      if (this.selectedCidade) {
        this.$emit("update:cidade_sync", this.selectedCidade);
      } else {
        this.$emit("update:cidade_sync", null);
      }
    },
    cidade_nome() {
      if (this.cidade_nome) {
        this.loading = true;
        this.searchCidades(`?search=${this.cidade_nome}`);
        this.loading = false;
      }
    },
  },

  methods: {
    async searchCidades(filtro) {
      await searchCidades(filtro).then((response) => {
        this.cidades = response;
          if (this.setarCidade) {
            this.selectedCidade = this.cidades.find((item) => {
              return item.nome == this.searchCidade;
            });                             
            this.setarCidade = false;
          }
        if (this.cidade_id) {
          this.selectedCidade = this.cidades.find((item) => {
            return item.id == this.cidade_id;
          });
          this.setarCidade = false;
        }
      });
    },
    async buscaCidades(event) {
      if (this.searchCidade && event && !this.setarCidade) {
        if (this.searchCidade.length > 2 && event.key != "Backspace") {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
      if (this.searchCidade && this.setarCidade) {
        if (this.searchCidade.length > 2) {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
    },

    setaCidade() {
      if (this.searchCidade && this.setarCidade) { 
        this.buscaCidades();
      }
    },

    openAddCidade() {
      this.dialogCidade = true;
    },
  },

  async mounted() {
    this.loading = true;
    if (this.cidade_nome) {
      await this.searchCidades(`?search=${this.cidade_nome}`);
    }
    this.loading = false;
  },
};
</script>

<style></style>
