<template>
  <v-dialog
    v-model="dialogProduto"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div class="pa-6">
        <SearchProdutos
          typeComp="produtos"
          :produto_sync.sync="selectedProduto"
          @clear-add-item="clearSearchProduto"
        />
      </div>
      <template v-if="!loading">
        <v-card>
          <v-card flat>
            <v-tabs v-model="tab">
              <v-tab class="caption font-weight-bold justify-center">
                GERAL
              </v-tab>
              <v-tab
                v-if="produto.ativo"
                class="caption font-weight-bold justify-center"
              >
                AVANÇADO
              </v-tab>
              <v-tab
                v-if="produto.ativo"
                class="caption font-weight-bold justify-center"
              >
                {{ $tc("global.inventario") }}
              </v-tab>
              <v-tab
                v-if="produto.ativo"
                class="caption font-weight-bold justify-center"
              >
                {{ $tc("global.preco") }}
              </v-tab>
            </v-tabs>
          </v-card>
          <v-container fluid class="py-4">
            <v-tabs-items v-model="tab">
              <!-- Cadastro -->
              <v-tab-item>
                <Cadastro
                  :item="produto"
                  :getProduto="getProduto"
                  v-if="tab === 0 && !produto.produto_kit"
                />
                <CadastroKit
                  v-if="tab === 0 && produto.produto_kit"
                  :item="produto"
                  :getProduto="getProduto"
                />
              </v-tab-item>
              <!-- Avancado -->
              <v-tab-item>
                <Avancado
                  :item="produto"
                  :getProduto="getProduto"
                  v-if="tab === 1"
                />
              </v-tab-item>
              <!-- Inventario -->
              <v-tab-item class="py-6 px-4">
                <Inventario v-if="tab === 2" :produto="produto" />
              </v-tab-item>
              <!-- Inventario -->
              <v-tab-item class="py-4 px-0">
                <ProdutoListasPrecos v-if="tab === 3" :produto="produto" />
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { getOneProduto, putProdutos } from "@/api/produtos/produtos.js";
import { mapState } from "vuex";

export default {
  name: "Produto",
  components: {
    Cadastro: () => import("./Cadastro"),
    CadastroKit: () => import("./CadastroKit"),
    Inventario: () => import("./Inventario"),
    ProdutoListasPrecos: () => import("./ProdutoListasPrecos"),
    Avancado: () => import("./Avancado.vue"),
    SearchProdutos: () => import("@/components/fields/SearchProdutos.vue"),
  },
  props: {
    dialogProduto: {
      type: Boolean,
      default: false,
    },
    produto_id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      produto: {},
      loading: false,
      tab: 0,
      apiUrl: null,
      dialogImages: false,
      selectedProduto: null,
    };
  },

  computed: {
    ...mapState({
      imagemSelecionada: (state) => state.imagemSelecionada,
    }),
    ...mapState({
      dynamic_crumb: (state) => state.dynamic_crumb,
    }),
  },

  watch: {
    selectedProduto() {
      if (this.selectedProduto) {
        this.produto_id = this.selectedProduto.id;
        this.getProduto();
      }
    },
  },

  methods: {
    getProduto() {
      this.loading = true;
      return getOneProduto(this.produto_id)
        .then((response) => {
          this.produto = response;
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.produto.descricao
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.produto.descricao);
          }
          this.loading = false;
          this.clearSearchProduto();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    updateProduto() {
      const produto = new FormData();

      if (this.imgUpload) {
        produto.append("img", this.imgUpload, this.imgUpload.name);
        // produto.append("img64", this.img);
      }

      let produto_2 = {};
      produto_2.empresa_id = this.produto.empresa_id;
      produto_2.produto_categoria_id = this.produto.produto_categoria_id;
      produto_2.descricao = this.produto.descricao;
      produto_2.descricao_detalhada = this.produto.descricao_detalhada;
      produto_2.referencia = this.produto.referencia;
      produto_2.fornecedor = this.produto.fornecedor;
      produto_2.costo = this.produto.costo;
      produto_2.costo_moeda_id = this.produto.costo_moeda_id;
      produto_2.precio = this.produto.precio;
      produto_2.und = this.produto.und;
      produto_2.qtde_caixa = this.produto.qtde_caixa;
      produto_2.estoque_min = this.produto.estoque_min;
      produto_2.estoque_max = this.produto.estoque_max;
      produto_2.url = this.produto.url;

      for (let key in produto_2) {
        if (
          produto_2[key] !== null &&
          produto_2[key] !== undefined &&
          produto_2[key] !== ""
        ) {
          produto.append(key, produto_2[key]);
        }
      }
      putProdutos(this.produto_id, this.produto)
        .then(() => {})
        .catch((error) => {
          console.log(error.response);
        });
    },

    updateAvatar(image) {
      this.produto.img = image;
      this.updateProduto();
      this.dialogImages = false;
    },

    clearSearchProduto() {
      this.selectedProduto = null;
    },

    closeDialog() {
      this.$emit("update:dialogProduto", false);
      this.$emit("fetch-produtos");
    },
  },

  async mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    await this.getProduto();
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btn-toggle-mobile {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  & > .v-btn.v-btn:not(:first-child) {
    border-left-width: thin;
  }
  & > .v-btn.v-btn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > .v-btn.v-btn:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
