<template>
  <v-card width="100%" flat class="mb-6 mx-1">
    <div class="d-flex">
      <v-row dense class="px-2 pt-2">
        <!-- NUMERO FATURA -->
        <v-col v-if="compras_despesas" cols="12" md="12">
          <v-text-field
            v-model="fatura.fatura_numero"
            :label="$tc('global.numero') + ' ' + $tc('global.fatura')"
            outlined
            hide-details
            dense
            required
          ></v-text-field>
        </v-col>
        <!-- TIMBRADO -->
        <v-col cols="12" md="12">
          <v-text-field
            v-model="fatura.fatura_timbrado"
            :label="$tc('global.timbrado')"
            outlined
            v-mask="'########'"
            hide-details
            dense
            required
          ></v-text-field>
        </v-col>
        <!-- VENCIMENTO TIMBRADO -->
        <v-col cols="12" md="12">
          <DataField
            hide_details
            :data_sync.sync="fatura.fatura_vencimento_timbrado"
            :label="$tc('global.vencimento') + ' ' + $tc('global.timbrado')"
          />
        </v-col>
        <!-- RUC -->
        <v-col cols="12" md="12">
          <v-text-field
            v-model="fatura.fatura_ruc"
            label="RUC"
            outlined
            hide-details
            dense
            required
          ></v-text-field>
        </v-col>
        <!-- Fornecedores Fatura -->
        <v-col cols="12" md="12">
          <v-combobox
            v-model="selectedFornecedorFatura"
            :label="
              compras_despesas
                ? $tc('global.pagarpara')
                : $tc('global.fornecedor')
            "
            :items="fornecedores"
            item-text="nome"
            item-value="id"
            dense
            outlined
            clearable
            hide-details
            class="mb-2"
            required
            :rules="formRules"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row dense class="px-2">
        <!-- MOEDA -->
        <v-col cols="12" md="12">
          <v-combobox
            v-model="selectedMoeda"
            :label="$tc('global.moeda', 2)"
            :items="moedas"
            dense
            outlined
            required
            :rules="formRules"
            item-text="sigla"
            item-value="id"
          ></v-combobox>
        </v-col>
        <!-- Isento -->
        <v-col cols="12" md="12">
          <v-text-field
            ref="valorIsento"
            v-model="valorIsento"
            :label="$tc('global.isento')"
            dense
            required
            :rules="formRules"
            :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
            outlined
            height="40px"
            :disabled="!selectedMoeda"
            style="display: flex"
            class="remove-underline input-font-size input-right"
            v-currency="vCurrencyOptions"
          ></v-text-field>
        </v-col>
        <!-- IVA10 -->
        <v-col cols="12" md="12">
          <v-text-field
            ref="valorIva10"
            v-model="valorIva10"
            label="IVA 10%"
            dense
            required
            :rules="formRules"
            :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
            outlined
            height="40px"
            :disabled="!selectedMoeda"
            style="display: flex"
            class="remove-underline input-font-size input-right"
            @keydown.enter="setValorImpostos"
            @blur="setValorImpostos"
            v-currency="vCurrencyOptions"
          >
            <template v-if="valorIva10Calculado > 0" v-slot:append>
              <v-chip small v-if="selectedMoeda.sigla === 'G$'">
                {{ selectedMoeda.sigla }}
                {{ valorIva10Calculado | guarani }}
              </v-chip>
              <v-chip small v-else>
                {{ selectedMoeda.sigla }}
                {{ valorIva10Calculado | currency }}
              </v-chip>
            </template>
          </v-text-field>
        </v-col>
        <!-- IVA5 -->
        <v-col cols="12" md="12">
          <v-text-field
            ref="valorIva5"
            v-model="valorIva5"
            label="IVA 5%"
            dense
            required
            :rules="formRules"
            :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
            outlined
            height="40px"
            :disabled="!selectedMoeda"
            style="display: flex"
            class="remove-underline input-font-size input-right"
            @keydown.enter="setValorImpostos"
            @blur="setValorImpostos"
            v-currency="vCurrencyOptions"
          >
            <template v-if="valorIva5Calculado > 0" v-slot:append>
              <v-chip small v-if="selectedMoeda.sigla === 'G$'">
                {{ selectedMoeda.sigla }}
                {{ valorIva5Calculado | guarani }}
              </v-chip>
              <v-chip small v-else>
                {{ selectedMoeda.sigla }}
                {{ valorIva5Calculado | currency }}
              </v-chip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <!-- total -->
      <v-col class="d-flex px-4 text-h6" cols="12">
        {{
          $tc("global.imposto") +
          `: ${selectedMoeda ? selectedMoeda.sigla : ""}`
        }}
        <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">{{
          valorTotalImposto | guarani
        }}</span>
        <span v-else>{{ valorTotalImposto | currency }}</span>
        <v-spacer></v-spacer>
        {{
          $tc("global.total") + `: ${selectedMoeda ? selectedMoeda.sigla : ""}`
        }}
        <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">{{
          valorTotal | guarani
        }}</span>
        <span v-else>{{ valorTotal | currency }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { inputRequired } from "@/helpers/utils/";
import { mapState } from "vuex";
import { fetchFornecedoresList } from "@/api/fornecedores/fornecedores.js";
export default {
  name: "FaturaGastos",

  props: {
    fatura_edit: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    compras_despesas: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      loading: false,
      fatura: {},
      valorIva10: 0,
      valorIva10Imposto: 0,
      valorIva10Calculado: 0,
      valorIva5: 0,
      valorIva5Imposto: 0,
      valorIva5Calculado: 0,
      valorIsento: 0,
      selectedMoeda: null,
      fornecedores: [],
      selectedFornecedorFatura: null,
      validForm: true,
      formRules: [inputRequired],
      valorTotalImposto: 0,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    valorTotal() {
      let total = 0;

      const valor_isento = this.$ci.parse(
        this.valorIsento,
        this.vCurrencyOptions
      );

      total =
        this.valorIva10Calculado +
        this.valorIva5Calculado +
        valor_isento +
        this.valorTotalImposto;
      this.$emit("set-valor-fatura", total);
      return total;
    },
  },

  watch: {
    valorTotal() {
      if (this.valorTotal > 0) {
        this.fatura.isento = this.$ci.parse(
          this.valorIsento,
          this.vCurrencyOptions
        );
        this.fatura.valor = this.valorTotal;
      }
    },
    selectedFornecedorFatura() {
      if (this.selectedFornecedorFatura) {
        this.fatura.fatura_fornecedor_id = this.selectedFornecedorFatura.id;
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.fatura.moeda_id = this.selectedMoeda.id_moeda;
        this.$emit("update:selectedMoeda", this.selectedMoeda);
      }
    },
    fatura() {
      this.$emit("update:fatura", this.fatura);
    },
  },

  methods: {
    setValorImpostos() {
      const valor_iva_10 = this.$ci.parse(
        this.valorIva10,
        this.vCurrencyOptions
      );
      const valor_iva_5 = this.$ci.parse(
        this.valorIva5, 
        this.vCurrencyOptions
      );

      if (valor_iva_10 > 0) {
        this.valorIva10Imposto = valor_iva_10 / 11;
        this.valorIva10Calculado = valor_iva_10 - this.valorIva10Imposto;
        this.fatura.iva_10 = this.valorIva10Imposto;
        this.fatura.total_iva_10 = valor_iva_10;
      }
      if (valor_iva_5 > 0) {
        this.valorIva5Imposto = valor_iva_5 / 21;
        this.valorIva5Calculado = valor_iva_5 - this.valorIva5Imposto;
        this.fatura.iva_5 = this.valorIva5Imposto;
        this.fatura.total_iva_5 = valor_iva_5;
      }
      this.valorTotalImposto = this.valorIva10Imposto + this.valorIva5Imposto;
      this.fatura.total_imposto = this.valorTotalImposto;
    },

    async getFornecedores() {
      this.loading = true;
      await fetchFornecedoresList()
        .then((response) => {
          this.fornecedores = response;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getFornecedores();
    this.moedas.map((moeda) => {
      if (moeda.sigla === "R$") {
        moeda.disabled = true;
      }
    });
    if (this.edit) {
      if (this.compras_despesas) {
        this.fatura.fatura_numero = this.fatura_edit.numero_fatura;
      }
      this.fatura.fatura_timbrado = this.fatura_edit.timbrado;
      this.fatura.fatura_vencimento_timbrado =
        this.fatura_edit.timbrado_vencimento;
      this.fatura.fatura_ruc = this.fatura_edit.ruc;
      this.selectedFornecedorFatura = this.fornecedores.find((fornecedor) => {
        return fornecedor.id === this.fatura_edit.fornecedor_id;
      });
      this.selectedMoeda = this.moedas.find((moeda) => {
        return moeda.id_moeda === this.fatura_edit.moeda_id;
      });
      this.$ci.setValue(this.$refs.valorIsento, this.fatura_edit.isento);
      this.$ci.setValue(this.$refs.valorIva10, this.fatura_edit.total_iva_10);
      this.$ci.setValue(this.$refs.valorIva5, this.fatura_edit.total_iva_5);
      this.setValorImpostos();
      this.$emit("update:fatura", this.fatura);
    }
  },
};
</script>

<style></style>
