<template>
  <v-dialog
    v-model="dialogAddEditDeposito"
    @click:outside="$emit('update:dialogAddEditDeposito', false)"
    @keydown.esc="$emit('update:dialogAddEditDeposito', false)"
    max-width="450"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          edit
            ? deposito.descricao
            : $tc("global.add") + " " + $tc("global.deposito")
        }}

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddEditDeposito', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <!-- Descrição -->
          <v-text-field
            v-model="deposito.descricao"
            :label="$tc('global.descricao')"
            :readonly="edit ? !PDepositoEditar : false"
            class="mt-4"
            outlined
            :rules="formRules"
            required
            dense
          ></v-text-field>

          <!-- Cidade -->

          <SearchCidades
            :cidade_sync.sync="selectedCidade"
            :cidade_id="cidade_id"
            :cidade_nome="cidade_nome"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          depressed
          class="white--text"
          small
          :disabled="(edit ? !PDepositoEditar : false) || !validForm"
          @click="edit ? putDeposito() : postDeposito()"
        >
          {{ edit ? $tc("global.salvar") : $tc("global.criar") }}
          <v-icon right>{{ edit ? "mdi-plus" : "mdi-check" }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchCidades } from "@/api/cidades/cidades.js";
import {
  Api_postDeposito,
  Api_putDeposito,
} from "@/api/produtos/produtos_depositos.js";
import { mapGetters } from "vuex";

export default {
  name: "dialogAddEditDeposito",

  components: {
    SearchCidades: () => import("@/components/fields/SearchCidades.vue"),
  },

  props: {
    dialogAddEditDeposito: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // cidades: [],
      validForm: false,
      formRules: [inputRequired],
      loading: false,
      deposito: {},
      deposito_original: {},
      selectedCidade: null,
      loadingCidades: true,
      cidade_id: null,
      cidade_nome: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PDepositoEditar() {
      return this.getAccess("Depositos", "Editar");
    },
  },

  watch: {
    selectedCidade() {
      if (this.selectedCidade) {
        this.deposito.cidade_id = this.selectedCidade.id;
      }
    },
  },

  methods: {
    postDeposito() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        Api_postDeposito(this.deposito)
          .then(() => {
            this.deposito = {};
            this.$emit("fetch-depositos");
            this.$emit("update:dialogAddEditDeposito", false);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    putDeposito() {
      if (
        JSON.stringify(this.deposito) === JSON.stringify(this.deposito_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        Api_putDeposito(this.deposito.id, this.deposito)
          .then(() => {
            this.$emit("update:dialogAddEditDeposito", false);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    // await this.getCidades();
    if (this.edit) {
      this.deposito = { ...this.item };
      this.deposito_original = { ...this.item };
      this.cidade_id = this.deposito.cidade_id;
      this.cidade_nome = this.deposito.cidade;
    }
  },
};
</script>

<style></style>
