<template>
  <v-dialog
    v-model="dialogSelectPlanoContas"
    @click:outside="$emit('update:dialogSelectPlanoContas', false)"
    @keydown.esc="$emit('update:dialogSelectPlanoContas', false)"
    width="650px"
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        <h3 class="text-subtitle1 font-weight-medium">
          {{ $tc("global.planocontas") }}
        </h3>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-treeview
          v-if="!loading"
          :items="planoscontas"
          :open.sync="open"
          item-text="descricao"
          item-key="id"
          item-disabled="disabled"
          item-children="children"
          transition
          activatable
          @update:active="getPlanoContasId"
          :active="active"
          return-object
          dense
          color="lightGray"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon
              v-if="filtro_tipo"
              :color="item.tipo === 'A' ? 'tocs_gray_3' : ''"
            >
              {{
                item.tipo === "A"
                  ? "mdi-file-cancel-outline"
                  : "mdi-file-document-outline"
              }}
            </v-icon>
            <v-icon v-else>
              {{ open ? "mdi-file-document" : "mdi-file-document-outline" }}
            </v-icon>
          </template>

          <template v-slot:label="{ item }">
            <span v-if="filtro_tipo">
              <span v-if="item.tipo === 'A'" class="tocs_gray_3--text">{{
                item.descricao
              }}</span>
              <span v-else>{{ item.descricao }}</span>
            </span>
            <span v-else>
              <span>{{ item.descricao }}</span>
            </span>
          </template>
        </v-treeview>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="button_2"
          class="white--text"
          :disabled="!planocontasselecionado"
          @click="sendPlanoContasId"
        >
          <v-icon left>mdi-check</v-icon>
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchPlanosContasTree } from "@/api/plano-contas/plano_contas.js";

export default {
  name: "DialogSelectPlanoContas",

  props: {
    dialogSelectPlanoContas: {
      type: Boolean,
      required: true,
    },
    filtro_tipo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      planocontasselecionado: {},
      loading: false,
      planoscontas: null,
      open: [1, 2],
      active: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: "id_pai",
          value: "id_pai",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
      ];
    },
  },

  methods: {
    getPlanosContas() {
      this.loading = true;

      fetchPlanosContasTree()
        .then((response) => {
          this.planoscontas = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    getPlanoContasId(value) {
      if (this.filtro_tipo) {
        if (value[0] && value[0].tipo === "A") {
          this.active = [];
        } else {
          this.planocontasselecionado = value[0];
        }
      } else {
        this.planocontasselecionado = value[0];
      }
    },
    sendPlanoContasId() {
      this.$emit("select-plano-contas", this.planocontasselecionado);
      this.$emit("update:dialogSelectPlanoContas", false);
    },
  },

  created() {
    this.getPlanosContas();
  },
};
</script>

<style></style>
