<template>
  <v-combobox
    class="ma-0"
    height="40px"
    :items="caixas"
    :label="$tc('global.caixa', 2)"
    item-text="descricao"
    item-value="id"
    dense
    outlined
    hide-details
    v-model="selectedCaixas"
    :loading="loadingCaixas"
    clearable
    @click:clear="clearCaixas"
  ></v-combobox>
</template>

<script>
import { fetchCaixas } from "@/api/caixas/caixas.js";
export default {
  name: "Caixas",

  data() {
    return {
      caixas: [],
      selectedCaixas: null,
      caixa: null,
      loadingCaixas: true,
    };
  },

  watch: {
    selectedCaixas() {
      if (this.selectedCaixas) {
        this.caixa = this.selectedCaixas.id;
      }
    },
    caixa() {
      if (this.caixa) {
        this.$store.commit("FiltrosRelatorios/UPDATE_CAIXA", this.caixa);
      }
    },
  },

  methods: {
    clearCaixas() {
      this.selectedCaixas = null;
      this.caixa = null;
      this.$store.commit("FiltrosRelatorios/UPDATE_CAIXA", null);
    },
    getCaixas() {
      fetchCaixas()
        .then((response) => {
          this.caixas = response;
          if (this.$route.query.caixa) {
            let caixa = this.caixas.find(
              (item) => item.id == this.$route.query.caixa
            );
            this.selectedCaixas = caixa;
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loadingCaixas = false;
        });
    },
  },

  mounted() {
    this.getCaixas();
  },
};
</script>

<style></style>
