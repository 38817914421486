<template>
  <v-card :loading="loading">
    <v-card-title class="tocs_gray_1">
      <v-icon class="mr-2">mdi-card-bulleted-outline</v-icon>
      {{ $tc("global.cheque", 2) }}
      <v-spacer></v-spacer>
      {{ $tc("global.totalreceber") }}: &nbsp;
      <span class="px-2"> G$ {{ totalGeralGuarani | guarani }} </span>
      <span class="px-2"> U$ {{ totalGeralDolar | currency }} </span>
      <span class="px-2"> R$ {{ totalGeralReal | currency }} </span>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="cheques"
        :items-per-page="5"
        :sort-by="['data_vencimento']"
        :hide-default-footer="cheques.length > 5 ? false : true"
        :item-class="row_classes"
      >
        <template v-slot:item.data_vencimento="{ item }">
          {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
        </template>

        <template v-slot:item.data_emissao="{ item }">
          {{ item.data_emissao | dateFormat("dd/MM/yyyy") }}
        </template>

        <template v-slot:item.valor="{ item }">
          <div v-if="item.moeda_sigla === 'G$'">
            {{ item.moeda_sigla }} {{ item.valor | guarani }}
          </div>
          <div v-else>{{ item.moeda_sigla }} {{ item.valor | currency }}</div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getBoxChequesAbertos } from "@/api/cheques/cheques.js";
import { format } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "BoxChequesAbertos",

  data() {
    return {
      loading: false,
      cheques: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.data-emissao"),
          align: "start",
          value: "data_emissao",
          // sortable: false,
        },
        {
          text: this.$tc("global.vencimento"),
          align: "start",
          value: "data_vencimento",
          // sortable: false,
        },
        {
          text: this.$tc("global.nome"),
          value: "emissor_nome",
          sortable: false,
        },
        {
          text: this.$tc("global.banco"),
          value: "banco_descricao",
          sortable: false,
        },
        {
          text: this.$tc("global.numero"),
          value: "numero",
          sortable: false,
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          align: "right",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
          sortable: false,
          width: "100",
        },
      ];
    },

    totalGeralGuarani() {
      let result = 0;
      if (this.cheques.length) {
        let total = 0;
        const reducer = (accumulator, item) => {
          if (item.moeda_sigla == "G$") {
            total = accumulator + Number(item.valor);
          }
          return total;
        };
        result = this.cheques.reduce(reducer, 0);
      }
      return result;
    },

    totalGeralDolar() {
      let result = 0;
      if (this.cheques.length) {
        let total = 0;
        const reducer = (accumulator, item) => {
          if (item.moeda_sigla == "U$") {
            total = accumulator + Number(item.valor);
          }
          return total;
        };
        result = this.cheques.reduce(reducer, 0);
      }
      return result;
    },

    totalGeralReal() {
      let result = 0;
      if (this.cheques.length) {
        let total = 0;
        const reducer = (accumulator, item) => {
          if (item.moeda_sigla == "R$") {
            total = accumulator + Number(item.valor);
          }
          return total;
        };
        result = this.cheques.reduce(reducer, 0);
      }
      return result;
    },
  },

  methods: {
    row_classes(item) {
      let dataNow = "";
      dataNow = format(new Date(), "yyyy-MM-dd");
      if (item.data_vencimento < dataNow) {
        return "tocs_red--text";
      }
    },
  },

  async mounted() {
    this.loading = true;
    this.cheques = await getBoxChequesAbertos();
    this.loading = false;
  },
};
</script>

<style></style>
