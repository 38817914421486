const routes = [
  {
    path: "/configuracoes/usuarios",
    component: () => import("@/views/configuracoes/usuarios/Usuarios.vue"),
    meta: {
      menu: "configuracoes",
      title: "Usuarios",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.usuario", plural: true },
      ],
      permission: { componente: "Usuarios" },
    },
  },
  {
    path: "/configuracoes/usuario/:usuarioId",
    component: () =>
      import("@/components/usuario/Usuario.vue"),
    props: true,
    meta: {
      menu: "configuracoes",
      title: "Usuario",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.usuario",
          plural: true,
          to: { path: "/configuracoes/usuarios" },
        },
        { text: "dynamic_crumb" },
      ],
      voltar: { path: "/configuracoes/usuarios" },
      permission: { componente: "Usuarios" },
    },
  },
  {
    path: "/configuracoes/usuarios/adicionar",
    component: () => import("@/components/usuario/NovoUsuario.vue"),
    meta: {
      menu: "configuracoes",
      title: "Adicionar Usuario",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        {
          text: "global.usuario",
          plural: true,
          to: { path: "/configuracoes/usuarios" },
        },
        { text: "global.add" },
      ],
      voltar: { path: "/configuracoes/usuarios" },
      permission: { componente: "Usuarios" },

    },
  },
];

export default routes;
