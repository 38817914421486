<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.caixa', 2)"
      icon="mdi-cash-register"
      dark
    />

    <v-card vi>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <DialogAddBandeira
          v-if="dialogAddBandeira && (PCaixaAdicionar || PCaixaEditar)"
          :dialogAddBandeira.sync="dialogAddBandeira"
          :edit="edit"
          @fetch-bandeiras="getBandeiras"
          :item="bandeira"
        />
        <v-btn
          v-if="PCaixaAdicionar"
          color="button_1"
          @click="addBandeira"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="bandeiras"
          :items-per-page="10"
          :sort-by="['created_at']"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToBandeira"
        >
          <template v-slot:item.comissao="{ item }">
            {{ item.comissao | currency }}
          </template>
          <template v-slot:item.comissao_iva="{ item }">
            {{ item.comissao_iva | currency }}
          </template>
          <template v-slot:item.ret_renta="{ item }">
            {{ item.ret_renta | currency }}
          </template>
          <template v-slot:item.status="{ item }">
            <span size="28" v-if="!item.status">{{
              $tc("global.inativo")
            }}</span>
            <span v-if="item.status">{{ $tc("global.ativo") }} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchCartoesBandeiras } from "@/api/cartoes-bandeiras/cartoes_bandeiras.js";
import { mapGetters } from "vuex";
export default {
  name: "Bandeiras",

  components: {
    DialogAddBandeira: () => import("./components/DialogAddBandeira.vue"),
  },

  data() {
    return {
      bandeira: {},
      loading: false,
      bandeiras: [],
      dialogAddBandeira: false,
      bandeiras_original: {},
      search: "",
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PCaixaVisualizar() {
      return this.getAccess("Bandeiras", "visualizar");
    },
    PCaixaEditar() {
      return this.getAccess("Bandeiras", "editar");
    },
    PCaixaAdicionar() {
      return this.getAccess("Bandeiras", "adicionar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.bandeira"),
          value: "bandeira",
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipo",
        },
        {
          text: this.$tc("global.comissao"),
          value: "comissao",
        },
        {
          text: this.$tc("global.comissao") + " IVA",
          value: "comissao_iva",
        },
        {
          text: this.$tc("global.retencao") + " Renta",
          value: "ret_renta",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
          value: "status",
        },
      ];
    },
  },

  methods: {
    addBandeira() {
      this.dialogAddBandeira = true;
      // this.adding = true;
      this.edit = false;
    },
    goToBandeira(item) {
      this.bandeira = { ...item };
      this.dialogAddBandeira = true;
      // this.adding = false;
      this.edit = true;
    },

    getBandeiras() {
      this.loading = true;
      this.bandeiras = [];
      fetchCartoesBandeiras()
        .then((response) => {
          this.bandeiras = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.getBandeiras();
  },
};
</script>

<style></style>
