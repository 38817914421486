const routes = [
  {
    path: "/financeiro/adiantamentos",
    component: () => import("@/views/dashboard/antecipos/Antecipos.vue"),
    meta: {
      menu: "dashboard",

      title: { text: "global.antecipo", plural: true },
      icon: "mdi-cash-plus",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.antecipo" },
      ],

      permission: { componente: "Antecipos", },

    },
  },
];

export default routes;
