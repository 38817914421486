var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.vendas,"sort-desc":true,"items-per-page":-1,"hide-default-footer":""},on:{"click:row":_vm.iniciarBaixaVenda},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd/MM/yyyy"))+" ")]}},{key:"item.condicao_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.condicao_pagamento == 1 ? _vm.$tc("global.aVista") : _vm.$tc("global.aPrazo"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])]}},{key:"item.cliente_nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("excerpt")(item.cliente_nome,20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cliente_nome))])])]}},{key:"item.vendedor_nome",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("excerpt")(item.vendedor_nome,20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.vendedor_nome))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"button_2","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDialogDevolucao(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-u-left-top")])],1)]}}])}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogDevolucao),callback:function ($$v) {_vm.dialogDevolucao=$$v},expression:"dialogDevolucao"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$tc("global.desejaDevolver"))+" ")],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$tc('global.motivo') + ' ' + _vm.$tc('global.devolucao'),"outlined":""},model:{value:(_vm.motivo_devolucao),callback:function ($$v) {_vm.motivo_devolucao=$$v},expression:"motivo_devolucao"}}),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$tc("global.mincaracteres", 10))+" ")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialogDevolucao = false}}},[_vm._v(" "+_vm._s(_vm.$tc("global.cancelar"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"button_2","disabled":_vm.semMotivo},on:{"click":function($event){return _vm.retornarVenda(_vm.devolucao_id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" "+_vm._s(_vm.$tc("global.devolver"))+" ")],1)],1)],1)],1),(_vm.dialogBaixaVenda)?_c('DialogBaixaVenda',{attrs:{"dialogBaixaVenda":_vm.dialogBaixaVenda,"venda":_vm.venda,"venda_parcelas":_vm.venda_parcelas},on:{"update:dialogBaixaVenda":function($event){_vm.dialogBaixaVenda=$event},"update:dialog-baixa-venda":function($event){_vm.dialogBaixaVenda=$event},"fetch-vendas-caixa":_vm.fetchVendas}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }