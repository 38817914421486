const routes = [
    {
        path: "/contabilidade/faturas",
          component: () => import("@/views/dashboard/faturas/Faturas.vue"),
        meta: {
            menu: "dashboard",
            title: { text: "global.fatura", plural: true },
            icon: "mdi-barn",
            breadcrumb: [
                { text: "global.home", to: { path: "/" } },
                { text: "global.fatura", plural: true },
            ],
            permission: { componente: "Faturas", }
        },
    },
];

export default routes;