import api from "../axios_service.js";

const fetchUsersTimbrados = async () => {
  const response = await api.get("/users-timbrados");
  return response.data.result;
};

const fetchUserTimbrados = async (filtros) => {
  const response = await api.get(`/user-timbrados${filtros ? filtros : ""}`);
  return response.data.result;
};

const fetchUserTimbrado = async (id) => {
  const response = await api.get(`/users-timbrados/${id}`);
  return response.data.result;
};

const putUserTimbrado = async (id, body) => {
  return api.put(`/users-timbrados/${id}`, body);
};

const postUserTimbrado = async (body) => {
  return api.post("/users-timbrados/add", body);
};

const deleteUserTimbrado = async (id) => {
  return api.delete(`/users-timbrados/${id}`);
};

export {
  fetchUsersTimbrados,
  fetchUserTimbrados,
  fetchUserTimbrado,
  putUserTimbrado,
  postUserTimbrado,
  deleteUserTimbrado,
};
