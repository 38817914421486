import api from "../axios_service.js";

export async function fetchCompBancos(filtros) {
  const response = await api.get(`/compensacao-bancos${filtros ? filtros : ""}`);
  return response.data.result;
}

export function postCompensarBancos(body) {
  return api.post("/compensar-bancos", body);
}
