<template>
  <v-select
    v-model="deposito"
    :items="depositos"
    :loading="loading"
    clearable
    outlined
    dense
    hide-details
    :label="$tc('global.deposito')"
    item-text="descricao"
    item-value="id"
    :disabled="disabled"
    @click:clear="clearDeposito"
    @change="updateDeposito"
  ></v-select>
</template>

<script>
import { fetchDepositosList } from "@/api/produtos/produtos_depositos.js";
import { mapState } from "vuex";

export default {
  name: "Depositos",

  data() {
    return {
      deposito: null,
      disabled: false,
      depositos: [],
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    updateDeposito() {
      if (this.deposito) {
        this.$store.commit("FiltrosRelatorios/UPDATE_DEPOSITO_VINCULADO", this.deposito);
      }
    },

    clearDeposito() {
      this.$store.commit("FiltrosRelatorios/UPDATE_DEPOSITO_VINCULADO", null);
      this.deposito = null;
    },
  },

  async created() {
    try {
      this.loading = true;
      // current user
      this.depositos = await fetchDepositosList("/" + this.user_id);
      this.filtros_componente.find((item) => {
        if (item.text == "DepositoVinculado") {
          this.depositos.find((deposito) => {
            if (deposito.id == item.default) {
              this.deposito = deposito;
            }
          });
          if (item.disabled) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
          this.updateDeposito();
        }
      });

      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
