const routes = [
  {
    path: "/ordens-servicos",
    component: () =>
      import("@/views/dashboard/ordens-servicos/OrdensServicos.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.ordensServicos", plural: true },
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.ordensServicos", plural: true },
      ],
      permission: { componente: "OrdensServicos" },
    },
  },
];

export default routes;
