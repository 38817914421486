import api from "../axios_service.js";

// Busca os servicos do fornecedor
export async function getFornecedorServicos(fornedecor_id) {
    const response = await api.get(`/fornecedores-servicos-all/${fornedecor_id}`);
    return response.data.result;
}

// Busca o servico do fornecedor
export async function getFornecedorServico(id) {
    const response = await api.get(`/fornecedores-servicos/${id}`);
    return response.data.result;
}

// Atualiza um servico existente
export function putFornecedorServico(id, body) {
    return api.put(`/fornecedores-servicos/${id}`, body);
}

// Cria um novo servico
export function postFornecedorServico(body) {
    return api.post("/fornecedores-servicos", body);
}
