import api from "../axios_service.js";

export async function getUsuario(id) {
  const response = await api.get(`/usuarios/${id}`);
  return response.data.result;
}

export async function putUsuario(id, usuario) {
  return api.post(`/usuarios/${id}`, usuario);
}

export async function setEmpresaAtiva(id, body) {
  const response = await api.put(`/empresa-ativa/${id}`, body);
  return response.data.result;
}

// User Depositos
export async function fetchUserDepositos(filtros) {
  const response = await api.get(`/usersdepositos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function postUserDeposito(userDeposito) {
  let body = {};
  for (let key in userDeposito) {
    body[key] = userDeposito[key];
  }
  return api.post("/usersdepositos/add", body);
}

export async function fetchDepositosNotEqual(id) {
  const response = await api.get(`/produtosdepositos/${id}/notequal`);
  return response.data.result;
}
//------------------------------------------------//

//USERS CAIXAS

export async function fetchUsersCaixas(filtros) {
  const response = await api.get(`/userscaixas${filtros}`);
  return response.data.result;
}

export async function postUsersCaixas(body) {
  return api.post("/userscaixas/add", body);
}

export function deleteUserCaixa(id) {
  return api.delete(`/userscaixas/${id}`);
}

//------------------------------------------------//

//USERS TIMBRADO

export async function fetchUsersTimbrado(filtros) {
  const response = await api.get(`/users-timbrados${filtros}`);
  return response.data.result;
}

export async function postUsersTimbrados(body) {
  return api.post("/users-timbrados/add", body);
}

export function deleteUsersTimbrado(id) {
  return api.delete(`/users-timbrados/${id}`);
}

//-------------------------///

// Get Images
export async function getImages(id) {
  const response = await api.get(`/usuarios-imgs/${id}`);
  return response.data.user_imgs;
}

export function deleteUserDeposito(id) {
  return api.delete(`/usersdepositos/${id}`);
}

export async function getEmpresaUsuarios() {
  const response = await api.get("/empresa-users");
  return response.data.result;
}

export async function getUsuariosEmpresaAtivo() {
  const response = await api.get("/usuarios-empresa-ativo");
  return response.data.result;
}

export async function getUsuariosEmpresaInativo() {
  const response = await api.get("/usuarios-empresa-inativo");
  return response.data.result;
}
export async function getLimiteUsuarios() {
  const response = await api.get("/usuarios/limite");
  return response.data.result;
}

export async function postUsuario(usuario) {
  return api.post("/usuarios/add", usuario);
}

export async function putUsuarioAtivo(id, usuario) {
  return api.post(`/usuarios-config/${id}`, usuario);
}

export async function putUsuarioAtivar(id, usuario) {
  const response = await api.put(`/usuarios/ativar/${id}`, usuario);
  return response.data.result;
}

export async function putUsuarioDesativar(id, usuario) {
  return api.put(`/usuarios/desativar/${id}`, usuario);
}

export async function getAllUsuarios() {
  const response = await api.get("/usuarios-all");
  return response.data.result;
}

export async function getUsuarios() {
  const response = await api.get("/usuarios");
  return response.data.result;
}

export async function postUsuarioAdmin(usuario) {
  return api.post("/usuarios-admin/add", usuario);
}

export async function searchUsers(filtros) {
  const response = await api.post(
    `/lista-users-completos${filtros ? filtros : ""}`
  );
  return response.data.result;
}

//------------------------------------------------//

//USERS DEPOSITO

export async function fetchUsersDeposito(filtros) {
  const response = await api.get(`/users-depositos${filtros}`);
  return response.data.result;
}

export async function postUsersDepositos(body) {
  return api.post("/users-depositos/add", body);
}

export function deleteUsersDeposito(id) {
  return api.delete(`/users-depositos/${id}`);
}

export function setDepositoPrincipal(body) {
  return api.post(`/users-depositos/principal`, body);
}
export async function fetchUserHasDepositos(id) {
  const response = await api.get(`/user-has-deposito/${id}`);
  return response.data.result;
}

//-------------------------///
