import api from "../axios_service.js";

const fetchPdfLayoutItems = async (pdf_layout_id, filtros) => {
  const response = await api.get(`/pdf-layout-items?pdf_layout_id=${pdf_layout_id}${filtros ? filtros : ""}`);
  return response.data.result;
};

const fetchPdfLayoutItem = async (id) => {
  const response = await api.get(`/pdf-layout-items/${id}`);
  return response.data.result;
};

const putPdfLayoutItem = async (id, body) => {
  return api.put(`/pdf-layout-items/${id}`, body);
};

const postPdfLayoutItem = async (body) => {
  return api.post("/pdf-layout-items/add", body);
};

const importPdfLayoutItems = async (pdf_layout_id, posicao_de, posicao_para, grupo) => {
  const response = await api.post(`/pdf-layout-items/importar`, {
    pdf_layout_id,
    posicao_de,
    posicao_para,
    grupo
  });
  return response.data;
};

export { fetchPdfLayoutItems, fetchPdfLayoutItem, putPdfLayoutItem, postPdfLayoutItem, importPdfLayoutItems };
