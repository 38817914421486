var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":""},on:{"click:outside":function($event){return _vm.$emit('update:dialogVisualizarAlteracoesPrecos', false)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('update:dialogVisualizarAlteracoesPrecos', false)}},model:{value:(_vm.dialogVisualizarAlteracoesPrecos),callback:function ($$v) {_vm.dialogVisualizarAlteracoesPrecos=$$v},expression:"dialogVisualizarAlteracoesPrecos"}},[_c('v-card',[_c('v-card-title',{staticClass:"tocs_gray_1"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-swap-horizontal")]),_vm._v(" "+_vm._s(_vm.$tc("global.produtosPrecosAlterados"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('update:dialogVisualizarAlteracoesPrecos', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-8 pb-8"},[_c('v-text-field',{ref:"search",staticClass:"py-4",attrs:{"dense":"","outlined":"","placeholder":_vm.$tc('global.buscarProdutosPorDescricao'),"clearable":"","hide-details":"","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-tables",attrs:{"loading":_vm.loading,"headers":_vm.headers,"search":_vm.search,"items":_vm.alteracoes_precos_items,"items-per-page":-1,"hide-default-footer":"","fixed-header":"","height":"calc(100vh - 345px)"},scopedSlots:_vm._u([{key:"item.preco_anterior",fn:function(ref){
var item = ref.item;
return [(_vm.selectedMoeda && _vm.selectedMoeda === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.preco_anterior))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.preco_anterior))+" ")])]}},{key:"item.preco_editado",fn:function(ref){
var item = ref.item;
return [(_vm.selectedMoeda && _vm.selectedMoeda === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.preco_editado))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.preco_editado))+" ")])]}},{key:"item.preco_novo",fn:function(ref){
var item = ref.item;
return [(_vm.selectedMoeda && _vm.selectedMoeda === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.preco_novo))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.preco_novo))+" ")])]}},{key:"item.preco_final",fn:function(ref){
var item = ref.item;
return [(_vm.selectedMoeda && _vm.selectedMoeda === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.preco_final))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.preco_final))+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }