<template>
  <v-dialog
    v-model="dialogAddFornecedor"
    @click:outside="$emit('update:dialogAddFornecedor', false)"
    @keydown.esc="$emit('update:dialogAddFornecedor', false)"
    width="1250px"
  >
    <v-card class="pa-sm-6">
      <v-card-title class="subtitle-1 justify-space-between align-center">
        DADOS GERAIS
        <v-btn
          @click="createFornecedor"
          :disabled="!validForm"
          class="white--text"
          color="button_1"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="2">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="logo">
                  <v-img :src="logo" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">Arrastar e soltar</h3>
                      <div class="text-caption">Clique para selecionar</div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="10">
              <v-row dense>
                <!-- Nome -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="fornecedor.nome"
                    :label="$tc('global.nome') + ' ' + $tc('global.fornecedor')"
                    dense
                    outlined
                    :rules="formRules"
                    required
                  ></v-text-field>
                </v-col>

                <!-- Documento -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="fornecedor.documento"
                    :label="$tc('global.documento')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Categoria -->
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="selectedCategoria"
                    :label="$tc('global.categoria')"
                    :items="categorias"
                    dense
                    outlined
                    :rules="formRules"
                    required
                    item-text="descricao"
                    item-value="id"
                  ></v-combobox>
                </v-col>

                <!-- Localizacao -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="fornecedor.localizacao"
                    :label="$tc('global.localizacao')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="selectedCidade"
                    :items="cidades"
                    :loading="buscandoCidades"
                    :search-input.sync="searchCidade"
                    @keyup="getCidades"
                    dense
                    outlined
                    hide-details
                    item-text="nome"
                    item-value="id"
                    :placeholder="$tc('global.cidade')"
                    append-icon=""
                    full-width
                    return-object
                    autocomplete="off"
                    class="remove-underline input-font-size"
                  ></v-autocomplete>
                </v-col>

                <!-- Moeda -->
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="selectedMoeda"
                    :label="$tc('global.moeda')"
                    :items="moedas"
                    dense
                    outlined
                    item-text="sigla"
                    item-value="id"
                  ></v-combobox>
                </v-col>

                <!-- Endereco -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="fornecedor.endereco"
                    :label="$tc('global.endereco')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="fornecedor.email"
                    :label="$tc('global.email')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="fornecedor.telefone"
                    :label="$tc('global.telefone')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Celular -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="fornecedor.celular"
                    :label="$tc('global.celular')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { postFornecedor } from "@/api/fornecedores/fornecedores.js";
import { inputRequired, validaEmail } from "@/helpers/utils/";
import { getFornecedoresCategoriasAtivo } from "@/api/fornecedores/categorias_fornecedores.js";
import { mapState } from "vuex";
import { searchCidades } from "@/api/cidades/cidades";
export default {
  name: "DialogAddFornecedor",

  props: {
    dialogAddFornecedor: {
      type: Boolean,
      default: false,
    },
    descFornecedor: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      fornecedor: {
        status: 1,
      },
      validForm: true,
      formRules: [inputRequired],
      emailRules: [validaEmail],
      loading: false,
      listas: [],
      categorias: [],
      cidades: [],
      logo: null,
      logoUpload: null,
      hoverFile: false,
      selectedMoeda: null,
      selectedCategoria: null,
      buscandoCidades: false,
      searchCidade: "",
      selectedCidade: null,
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  // watch: {
  //   selectedCategoria() {
  //     if (this.selectedCategoria) {
  //       this.fornecedor.categoria_id = this.selectedCategoria.id;
  //     }
  //   },
  //   selectedMoeda() {
  //     if (this.selectedMoeda) {
  //       this.fornecedor.moeda_id = this.selectedMoeda.id;
  //     }
  //   },
  //   selectedCidade() {
  //     if (this.selectedCidade) {
  //       this.fornecedor.cidade_id = this.selectedCidade.id;
  //     }
  //   },
  // },

  methods: {
    fileAdded(file) {
      this.logoUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },

    searchCidades(filtro) {
      return searchCidades(filtro).then((response) => {
        this.cidades = response;
      });
    },

    async getCidades() {
      if (this.searchCidade) {
        if (this.searchCidade.length > 2 && event.key != "Backspace") {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
    },

    getCategorias() {
      getFornecedoresCategoriasAtivo().then((response) => {
        this.categorias = response;
      });
    },
    createFornecedor() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const fornecedor = new FormData();

        if (this.logoUpload) {
          fornecedor.append("logo", this.logoUpload, this.logoUpload.name);
          fornecedor.append("logo64", this.logo);
        }
        let fornecedor_2 = {};
        fornecedor_2.nome = this.fornecedor.nome;
        fornecedor_2.categoria_id = this.fornecedor.categoria_id;
        fornecedor_2.documento = this.fornecedor.documento;
        fornecedor_2.endereco = this.fornecedor.endereco;
        fornecedor_2.localizacao = this.fornecedor.localizacao;
        fornecedor_2.cidade_id = this.fornecedor.cidade_id;
        fornecedor_2.email = this.fornecedor.email;
        fornecedor_2.telefone = this.fornecedor.telefone;
        fornecedor_2.celular = this.fornecedor.celular;
        fornecedor_2.moeda_id = this.fornecedor.moeda_id;

        for (let key in fornecedor_2) {
          if (
            fornecedor_2[key] !== null &&
            fornecedor_2[key] !== undefined &&
            fornecedor_2[key] !== ""
          ) {
            fornecedor.append(key, fornecedor_2[key]);
          }
        }

        postFornecedor(fornecedor)
          .then(() => {
            this.$emit("update:dialogAddFornecedor", false);
            this.$emit("buscaFornecedores");
            this.$emit("update:fornecedorCriado", this.fornecedor);

            this.fornecedor = {};
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
  },

  mounted() {
    this.getCategorias();
    if (this.descFornecedor) {
      this.fornecedor.nome = this.descFornecedor;
    }
  },
};
</script>

<style></style>
