var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.vendas_final,"sort-desc":true,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])]}},{key:"item.pagare",fn:function(ref){
var item = ref.item;
return [(item.condicao_pagamento === 2)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openPagarePdf(item)}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1):_vm._e()]}},{key:"item.fatura",fn:function(ref){
var item = ref.item;
return [(item.fatura_id)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.generateFatura(item.fatura_id, true, false, false, 'fatura')}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1):_vm._e(),(!item.fatura_id)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.novaFatura(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}},{key:"item.ticket",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.generateTicket(item.id, false, false)}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1)]}}])}),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"PDF"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e(),(_vm.dialogAddDocumento)?_c('DialogAddDocumento',{attrs:{"dialogAddDocumento":_vm.dialogAddDocumento,"cliente_id":_vm.venda_final.cliente_id},on:{"update:dialogAddDocumento":function($event){_vm.dialogAddDocumento=$event},"update:dialog-add-documento":function($event){_vm.dialogAddDocumento=$event},"open-pagare":_vm.openPagarePdf}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }