<template>
  <v-dialog
    v-model="dialogTransferencia"
    @click:outside="$emit('update:dialogTransferencia', false)"
    @keydown.esc="$emit('update:dialogTransferencia', false)"
    width="550px"
  >
    <v-card>
      <v-card-title class="subtitle-1 lightgray">
        {{ $tc("global.transferencia") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogTransferencia', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <!-- Deposito Origem -->
        <v-select
          v-model="selectedDepositoOrigem"
          :items="depositos_origem"
          class="mt-4"
          clearable
          item-text="descricao"
          item-value="id"
          dense
          outlined
          hide-details
          :label="this.$tc('global.depositoOrigem')"
        ></v-select>

        <!-- Qtde -->
        <v-text-field
          v-model="movimentacao.qtde"
          :label="this.$tc('global.qtd')"
          class="mt-4"
          outlined
          hide-details
          dense
        ></v-text-field>

        <!-- Depositos -->

        <v-select
          v-model="selectedDepositoDestino"
          :items="depositos_destino"
          class="mt-4"
          item-text="descricao"
          item-value="id"
          dense
          :disabled="desativaDeposito"
          outlined
          hide-details
          :label="this.$tc('global.depositodestino')"
        ></v-select>

        <v-textarea
          outlined
          hide-details
          class="mt-4"
          label="Obs"
          :rows="2"
          auto-grow
          v-model="movimentacao.obs"
        ></v-textarea>

        <span v-if="saldo_produto_origem" class="d-flex pt-4">
          {{ $tc("global.saldo") + " " + $tc("global.depositoOrigem") }}
          <v-spacer></v-spacer>{{ saldo_produto_origem.saldo }}
        </span>
        <span v-if="saldo_produto_destino" class="d-flex pt-2">
          {{ $tc("global.saldo") + " " + $tc("global.depositodestino") }}
          <v-spacer></v-spacer>{{ saldo_produto_destino.saldo }}
        </span>
        <span
          v-if="selectedDepositoDestino && saldo_produto_destino === null"
          class="d-flex pt-2"
        >
          {{ $tc("global.depositoSemMovimentacao") }}
        </span>
      </v-card-text>

      <v-card-actions class="d-flex pb-3 pr-6">
        <v-spacer></v-spacer>
        <v-btn
          @click="transfereMercadoria()"
          color="button_1"
          class="white--text"
          :disabled="disableTransferencia"
          small
        >
          <v-icon left> mdi-swap-horizontal </v-icon>
          {{ $tc("global.transferencia") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchDepositos } from "@/api/produtos/produtos_depositos.js";
import { fetchSaldoDeposito } from "@/api/produtos/produtos_saldos.js";
import { postTransferencia } from "@/api/produtos/produtos.js";

export default {
  name: "DialogTransferencia",

  props: {
    dialogTransferencia: {
      type: Boolean,
      default: false,
    },
    produto: {
      type: Object,
    },
  },

  data() {
    return {
      loadingDepositoOrigem: false,
      depositos_destino: [],
      depositos_origem: [],
      movimentacao: {},
      selectedDepositoDestino: null,
      selectedDepositoOrigem: null,
      saldo_produto_origem: null,
      saldo_produto_destino: null,
      set_saldo_origem: false,
    };
  },

  computed: {
    desativaDeposito() {
      let result = false;
      if (!this.selectedDepositoOrigem) {
        result = true;
      }
      return result;
    },

    disableTransferencia() {
      let result = true;

      if (
        !isNaN(this.movimentacao.qtde) &&
        this.movimentacao.deposito_id_origem &&
        this.movimentacao.deposito_id_destino &&
        this.movimentacao.obs
      ) {
        result = false;
        if (Number(this.movimentacao.qtde) < 0) {
          result = true;
        }
      }
      return result;
    },
  },

  watch: {
    selectedDepositoOrigem() {
      if (this.selectedDepositoOrigem) {
        this.depositos_destino = [];
        this.movimentacao.deposito_id_origem = this.selectedDepositoOrigem;
        this.set_saldo_origem = true;
        this.saldoEstoque();
        this.depositos_origem.forEach((deposito) => {
          if (deposito.id !== this.selectedDepositoOrigem) {
            this.depositos_destino.push(deposito);
          }
        });
      }
    },
    selectedDepositoDestino() {
      if (this.selectedDepositoDestino) {
        this.movimentacao.deposito_id_destino = this.selectedDepositoDestino;
        this.set_saldo_origem = false;
        this.saldoEstoque();
      }
    },
  },

  methods: {
    getDepositos() {
      this.loadingDepositoOrigem = true;

      return fetchDepositos()
        .then((response) => {
          this.depositos_origem = response;
          this.loadingDepositoOrigem = false;
        })
        .catch(() => {
          this.loadingDepositoOrigem = false;
        });
    },

    saldoEstoque() {
      this.loading = true;
      fetchSaldoDeposito(
        `?produto_id=${this.produto.id}&deposito_id=${
          this.set_saldo_origem
            ? this.selectedDepositoOrigem
            : this.selectedDepositoDestino
        }`
      )
        .then((response) => {
          if (this.set_saldo_origem) {
            this.saldo_produto_origem = response;
            this.loading = false;
          } else {
            this.saldo_produto_destino = response;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    transfereMercadoria() {
      this.loading = true;
      let movimentacao = {};
      movimentacao.produto_id = this.produto.id;
      movimentacao.deposito_id_origem = this.selectedDepositoOrigem;
      movimentacao.deposito_id_destino = this.selectedDepositoDestino;
      movimentacao.qtde = this.movimentacao.qtde;
      movimentacao.obs = this.movimentacao.obs;

      postTransferencia(movimentacao)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            this.$emit("update:dialogTransferencia", false);
            this.$emit("fetch-inventario");
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getDepositos();
  },
};
</script>

<style></style>
