import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { listaDeContasReceber } from "@/api/relatorios/relatorio_contas_receber";
import { format, parseISO } from "date-fns";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();
  const filtros = bodyPDF.queryString;
  let items = await listaDeContasReceber(filtros);
  let items_info = items.contas;
  const filtros_info = items.filtros;
  let text_filtros = "";

  for (const [key, value] of Object.entries(filtros_info)) {
    if (value) {
      text_filtros += key + ": " + value + "   ";
    }
  }
  const cabecalho = bodyPDF.cabecalho;
  const periodo = bodyPDF.periodo;

  return new Promise((resolve, reject) => {
    let cabecalho_data = {
      margin: [0, 5, 0, 2],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: " CONTAS A RECEBER - " + periodo,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let cabecalho_filtros = {
      margin: [0, 0, 0, -3],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: text_filtros,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let table_contas = {
      margin: [0, 0, 0, 0],
      table: {
        dontBreakRows: true,
        widths: [165, "*", "*", 35, "auto", "auto", "auto"],
        body: [
          [
            {
              text: "CLIENTE",
              border: [true, true, false, true],
              bold: true,
              fillColor: "#E0E0E0",
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "DT VENCIMENTO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              fillColor: "#E0E0E0",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },

            {
              text: "DT PAGAMENTO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              fillColor: "#E0E0E0",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "MOEDA",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              fillColor: "#E0E0E0",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "VALOR",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              fillColor: "#E0E0E0",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "DESCONTO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              fillColor: "#E0E0E0",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "VALOR PAGO",
              border: [false, true, true, true],
              bold: true,
              alignment: "center",
              fillColor: "#E0E0E0",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },

          ],
        ],
      },
      layout: {
        // hLineWidth: function (i, node) {
        //   return i <= 1 || i === node.table.body.length ? 1 : 1;
        // },
        // hLineColor: function (i, node) {
        //   return i === 0 || i === node.table.body.length
        //     ? "#666666"
        //     : "#666666";
        // },
        // vLineColor: function (i, node) {
        //   return i === 0 || i === node.table.widths.length
        //     ? "#666666"
        //     : "#666666";
        // },
        fillColor: function (rowIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let totalValorG = 0;
    let totalDescontoG = 0;
    let totalValorPagoG = 0;

    // let totalValorR = 0;
    // let totalDescontoR = 0;
    // let totalValorPagoR = 0;

    // let totalValorD = 0;
    // let totalDescontoD = 0;
    // let totalValorPagoD = 0;



    const row_font_size = 8;
    items_info.forEach((item, index) => {
      // if item doest not have a key value named filtros
      if (!item.filtros) {

        if (item.moeda == 'G$') {
          totalValorG += +item.valor;
          totalDescontoG += +item.desconto;
          totalValorPagoG += +item.valor_pago;
        }
        // else if (item.moeda == 'R$') {
        //   totalValorR += +item.valor;
        //   totalDescontoR += +item.desconto;
        //   totalValorPagoR += +item.valor_pago;
        // } else {
        //   totalValorD += +item.valor;
        //   totalDescontoD += +item.desconto;
        //   totalValorPagoD += +item.valor_pago;
        // }


        let table_row = [
          {
            text: item.cliente,
            fontSize: row_font_size,
            alignment: "left",
            margin: [0, 4, 0, 5],
            border: [
              true,
              false,
              true,
              index != items_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.data_vencimento ? format(parseISO(item.data_vencimento), "dd/MM/yyyy") : "",
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              false,
              true,
              index != items_info.length - 1 ? false : true,
            ],
          },

          {
            text: item.data_pagamento ? format(parseISO(item.data_pagamento), "dd/MM/yyyy") : "",
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              false,
              true,
              index != items_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.moeda,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              false,
              true,
              index != items_info.length - 1 ? false : true,
            ],
          },
          {
            text: (
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor)
                : Vue.filter("currency")(item.valor)
            ),
            fontSize: row_font_size,
            alignment: "right",
            margin: [0, 4, 0, 5],
            border: [
              false,
              false,
              true,
              index != items_info.length - 1 ? false : true,
            ],
          },
          {
            text: (
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.desconto)
                : Vue.filter("currency")(item.desconto)
            ),
            fontSize: row_font_size,
            alignment: "right",
            margin: [0, 4, 0, 5],
            border: [
              false,
              false,
              true,
              index != items_info.length - 1 ? false : true,
            ],
          },
          {
            text: (
              item.moeda == "G$"
                ? Vue.filter("guarani")(item.valor_pago)
                : Vue.filter("currency")(item.valor_pago)
            ),
            fontSize: row_font_size,
            alignment: "right",
            margin: [0, 4, 0, 5],
            border: [
              false,
              false,
              true,
              index != items_info.length - 1 ? false : true,
            ],
          },

        ];

        table_contas.table.body.push(table_row);
      }
    });

    table_contas.table.body.push([
      { text: "Total G$: ", colSpan: 4, border: [true, false, true, true], bold: true, alignment: "right", fontSize: row_font_size + 1 },
      {},
      {},
      {},
      { text: Vue.filter("guarani")(totalValorG), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", },
      { text: Vue.filter("guarani")(totalDescontoG), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", },
      { text: Vue.filter("guarani")(totalValorPagoG), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", }
    ]);

    // table_contas.table.body.push([
    //   { text: "Total R$: ", colSpan: 4, border: [true, false, true, true], bold: true, alignment: "right", fontSize: row_font_size + 1 },
    //   {},
    //   {},
    //   {},
    //   { text: Vue.filter("currency")(totalValorR), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", },
    //   { text: Vue.filter("currency")(totalDescontoR), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", },
    //   { text: Vue.filter("currency")(totalValorPagoR), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", }
    // ]);

    // table_contas.table.body.push([
    //   { text: "Total U$: ", colSpan: 4, border: [true, false, true, true], bold: true, alignment: "right", fontSize: row_font_size + 1 },
    //   {},
    //   {},
    //   {},
    //   { text: Vue.filter("currency")(totalValorD), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", },
    //   { text: Vue.filter("currency")(totalDescontoD), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", },
    //   { text: Vue.filter("currency")(totalValorPagoD), border: [false, false, true, true], bold: true, fontSize: row_font_size + 1, alignment: "right", }
    // ]);

    let content = [cabecalho, cabecalho_data, table_contas];
    if (!Object.values(filtros_info).every(value => value === null)) {
      content.splice(1, 0, cabecalho_filtros);
    }

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
