import api from "../axios_service.js";

export async function fetchProdutosPrecosAlteracoes(filtros) {
    const response = await api.get(`/produtos-precos-alteracoes${filtros ? filtros : ""}`);
    return response.data.result;
}
export async function fetchProdutosAlteracoesPrecosItems(id) {
    const response = await api.get(`/produtos-precos-alteracoes-items/${id}`);
    return response.data.result;
}

export async function fetchProdutosSelecionados(produtos) {
    return api.post(`/produtos-selecionados`, produtos);
}

export async function postProdutosPrecosAlteracoes(produtos) {
    return api.post(`/produtos-precos-alteracoes`, produtos);
}

