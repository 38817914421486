<template>
  <v-dialog
    v-model="dialogClienteObs"
    @click:outside="$emit('update:dialogClienteObs', false)"
    @keydown.esc="$emit('update:dialogClienteObs', false)"
    width="650px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.nova") + " " + $tc("global.anotacao")
            : $tc("global.atualizar") + " " + $tc("global.anotacao")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogClienteObs', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="cliente_observacao.obs"
                :label="$tc('global.observacao')"
                dense
                outlined
                height="500px"
                scrollable
                :rules="formRules"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogClienteObs', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createClienteObs() : updateClienteObs()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { postClienteObs, putClienteObs } from "@/api/clientes/clientes_obs.js";
import { getCliente } from "@/api/clientes/clientes.js";
export default {
  name: "DialogClienteObs",

  props: {
    dialogClienteObs: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      cliente_observacao: {},
      cliente_observacao_original: {},
      loading: true,
      validForm: true,
      formRules: [inputRequired],
    };
  },

  computed: {
    cliente_id() {
      return this.$route.params.id;
    },
  },

  methods: {
    createClienteObs() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let body = {};
        body.obs = this.cliente_observacao.obs;
        body.cliente_id = this.cliente_id;

        postClienteObs(body)
          .then(() => {
            this.$emit("fetch-cliente-obs");
            this.$emit("update:dialogClienteObs", false);
          })
          .catch((error) => {
            // console.log(error.response);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateClienteObs() {
      if (
        JSON.stringify(this.cliente_observacao) ===
        JSON.stringify(this.cliente_observacao_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let body = {};
        body.obs = this.cliente_observacao.obs;
        body.cliente_id = this.cliente_id;

        putClienteObs(this.cliente_observacao.id, body)
          .then(() => {
            this.cliente_observacao_original = { ...this.cliente_observacao };
            this.$emit("fetch-cliente-obs");
            this.$emit("update:dialogClienteObs", false);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    if (this.edit) {
      this.cliente_observacao = { ...this.item };
      this.cliente_observacao_original = { ...this.item };
    }
  },
};
</script>

<style lang="scss" scoped></style>
