<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.cheque', 2)"
      icon="mdi-card-bulleted-outline"
      dark
    />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          append-icon="mdi-magnify"
          v-model="search"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-btn-toggle mandatory v-model="tipo_cheque" class="ml-3">
          <v-btn value="abertos" class="text-caption" height="40px">
            {{ $tc("global.aberto", 2) }}
          </v-btn>
          <v-btn value="compensados" class="text-caption" height="40px">
            {{ $tc("global.compensacao", 2) }}
          </v-btn>
          <v-btn value="devolvidos" class="text-caption" height="40px">
            {{ $tc("global.devolucao", 2) }}
          </v-btn>
        </v-btn-toggle>

        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-btn
          color="button_3"
          @click="dialogCompensacaoCheque = true"
          :disabled="selectedCheques.length == 0"
          class="white--text mt-4 mt-sm-0 mx-2"
        >
          <v-icon left>mdi-cash-sync</v-icon>
          {{ $tc("global.compensar") }}
        </v-btn>
        <v-btn
          @click="goToAddCheque"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-model="selectedCheques"
          :headers="headers"
          :search="search"
          :items="cheques"
          :items-per-page="10"
          :show-select="tipo_cheque == 'abertos' ? true : false"
          item-key="id"
          :loading="loadingSelectedCheques || loading"
          class="data-tables data-tables__row-click"
          @click:row="goToCheque"
          @toggle-select-all="chequesSelected"
          @item-selected="chequeSelected"
        >
          <template v-slot:item.data_emissao="{ item }">
            {{ item.data_emissao | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.data_vencimento="{ item }">
            {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.valor="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else> {{ item.valor | currency }} </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip v-if="item.status == 0" bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click.stop="openDevolucaoCheque(item)">
                  <v-icon color="tocs_red">mdi-minus-box-outline</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $tc("global.devolucao") }}
              </div>
            </v-tooltip>
            <v-tooltip v-if="item.status == 2" bottom color="dakblue">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click.stop="generateComprovante(item.id)"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $tc("global.comprovante") }}
              </div>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogAddCheque
      v-if="DialogAddCheque"
      :DialogAddCheque.sync="DialogAddCheque"
      :editar="editar"
      :cheque_id="cheque_id"
      @fetch-cheques="getCheques"
      origem="MANUAL"
    />
    <DialogCompensacaoCheques
      v-if="dialogCompensacaoCheque"
      :dialogCompensacaoCheque.sync="dialogCompensacaoCheque"
      :selectedCheques="selectedCheques"
      @fetch-cheques="getCheques"
    />
    <DialogDevolucaoCheque
      v-if="dialogDevolucaoCheque"
      :dialogDevolucaoCheque.sync="dialogDevolucaoCheque"
      :selectedCheque="cheque_id"
      @fetch-cheques="getCheques"
    />

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="CHEQUE"
    />
  </v-container>
</template>

<script>
import { fetchCheques } from "@/api/cheques/cheques.js";
import { format } from "date-fns";

const importComprovanteCheque = () =>
  import(
    /* webpackChunkName: "comprovanteCheque" */ "@/components/recibo/reports/reciboDevolucaoChequePdf.js"
  );

export default {
  name: "Cheques",

  components: {
    DialogAddCheque: () => import("./components/DialogAddCheque.vue"),
    DialogCompensacaoCheques: () =>
      import("./components/DialogCompensacaoCheques.vue"),
    DialogDevolucaoCheque: () =>
      import("./components/DialogDevolucaoCheque.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
  },

  data() {
    return {
      loading: false,
      cheques: [],
      selectedCheques: [],
      search: "",
      editar: false,
      DialogAddCheque: false,
      dialogCompensacaoCheque: false,
      loadingSelectedCheques: false,
      tipo_cheque: "aberto",
      cheque_id: null,
      dialogDevolucaoCheque: false,
      pdfDialog: false,
      pdfData: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "center",
          value: "id",

          sortable: false,
        },
        {
          text: this.$tc("global.data-emissao"),
          align: "center",
          value: "data_emissao",
          // sortable: false,
        },
        {
          text: this.$tc("global.vencimento"),
          align: "center",
          value: "data_vencimento",
          // sortable: false,
        },
        {
          text: this.$tc("global.origem"),
          value: "origem",
          // sortable: false,
        },

        {
          text: this.$tc("global.nome"),
          value: "emissor_nome",
          sortable: false,
        },
        {
          text: this.$tc("global.banco"),
          value: "banco_descricao",
          sortable: false,
        },

        {
          text: this.$tc("global.numero"),
          value: "numero",
          sortable: false,
        },

        {
          text: this.$tc("global.tipo"),
          align: "right",
          value: "tipo",
          sortable: false,
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          align: "right",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "right",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    tipo_cheque() {
      this.getCheques();
    },
  },

  methods: {
    openDevolucaoCheque(cheque) {
      this.cheque_id = cheque.id;
      this.dialogDevolucaoCheque = true;
    },

    chequeSelected({ item, value }) {
      if (value) {
        let result_moeda = false;

        this.selectedCheques.some((cheque, index) => {
          if (cheque.moeda_id != item.moeda_id) {
            result_moeda = true;
          }
        });
        this.loadingSelectedCheques = false;
        if (result_moeda) {
          this.$nextTick(() => {
            const index = this.selectedCheques.findIndex(
              (cheque) => cheque.id === item.id
            );
            if (index !== -1) this.selectedCheques.splice(index, 1);
            this.$toast.error(this.$tc("global.ChequesDevemSerDoMesmoMoeda"));
          });
        }
      } else {
        this.$nextTick(() => {
          const index = this.selectedCheques.findIndex(
            (cheque) => cheque.id === item.id
          );
          if (index !== -1) this.selectedCheques.splice(index, 1);
        });
      }
    },

    chequesSelected({ items, value }) {
      if (value) {
        let result_moeda = false;

        this.loadingSelectedCheques = true;

        items.some((cheque, index) => {
          if (cheque.isSelectable && cheque.moeda_id != items[0].moeda_id) {
            result_moeda = true;
            return true;
          }
        });

        if (result_moeda) {
          this.$nextTick(() => {
            this.selectedCheques = [];
            this.$toast.error(this.$tc("global.ChequesDevemSerDoMesmoMoeda"));
          });
        }

        this.loadingSelectedCheques = false;
      } else {
        this.selectedCheques = [];
      }
    },

    getCheques() {
      this.loading = true;
      this.cheques = [];
      this.selectedCheques = [];
      return fetchCheques(`?tipo_cheque=${this.tipo_cheque}`)
        .then((response) => {
          this.cheques = response;
          this.cheques.map((cheque) => {
            if (
              cheque.tipo == "DIFERIDO" &&
              cheque.data_vencimento <= format(new Date(), "yyyy-MM-dd")
            ) {
              cheque.isSelectable = false;
            }
          });
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToCheque(cheque) {
      this.cheque_id = cheque.id;
      this.editar = true;
      this.DialogAddCheque = true;
    },

    goToAddCheque() {
      this.cheque_id = null;
      this.editar = false;
      this.DialogAddCheque = true;
    },

    async generateComprovante(cheque_id) {
      this.loading = true;

      try {
        importComprovanteCheque()
          .then((module) => {
            let generate = module.generateReciboTransferenciaEstoque;
            generate(cheque_id).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.getCheques();
  },
};
</script>

<style lang="scss" scoped></style>
