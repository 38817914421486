<template>
  <v-breadcrumbs
    v-if="breadcrumbList && $vuetify.breakpoint.mdAndUp"
    :items="breadcrumbList"
    class="px-0 pt-3 pb-0"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        active-class=""
        :to="item.to"
        :disabled="item.disabled"
      >
        <template v-if="item.text === 'dynamic_crumb'">
          <div class="font-weight-bold whitetocs--text">
            {{ crumb ? crumb.toString().toUpperCase() : "" }}
          </div>
        </template>
        <template v-else-if="item.text === 'global.home'">
          <div class="font-weight-bold whitetocs--text">
            <v-icon color="whitetocs" class="ml-2">mdi-home-outline</v-icon>
            {{
              $tc(item.text)
                .toString()
                .toUpperCase()
            }}
          </div>
        </template>
        <template v-else>
          <div class="font-weight-bold whitetocs--text">
            <template v-if="item.plural">
              {{
                $tc(item.text, 2)
                  .toString()
                  .toUpperCase()
              }}
            </template>
            <template v-if="item.disableTrans">
              {{ item.text.toUpperCase() }}
            </template>
            <template v-else-if="!item.plural && !item.disableTrans">
              {{
                $tc(item.text)
                  .toString()
                  .toUpperCase()
              }}
            </template>
          </div>
        </template>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BreadCrumbs",
  data: () => ({
    breadcrumbList: [],
  }),
  watch: {
    $route() {
      this.updateList();
    },
  },
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
      crumb: (state) => state.dynamic_crumb,
    }),
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  mounted() {
    this.updateList();
  },
};
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  li {
    opacity: 0.6;
    &:last-child {
      opacity: 1;
    }
    ::v-deep .v-breadcrumbs__item div {
      max-width: 300px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr;
    }
  }
}
</style>
