<template>
  <v-dialog
    v-model="dialogContaPagarViewr"
    @click:outside="$emit('update:dialogContaPagarViewr', false)"
    @keydown.esc="$emit('update:dialogContaPagarViewr', false)"
    width="1200px"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title class="pa-4 font-weight-bold tocs_gray_1">
        {{ $tc("global.conta") }} -
        {{ conta.id }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogContaPagarViewr', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pa-6">
        <div class="d-flex justify-space-between py-4">
          <!--fornecedor -->
          <span class="text-subtitle-1">
            Fornecedor:
            <span class="font-weight-bold">
              {{ conta.fornecedor_nome }}
            </span>
          </span>

          <!--data -->
          <span class="text-subtitle-1">
            Data:
            <span class="font-weight-bold">
              {{ conta.data_lancamento | dateFormat("dd/MM/yyyy") }}
            </span>
          </span>

          <!--documento -->
          <span class="text-subtitle-1">
            Documento:
            <span class="font-weight-bold">
              {{ conta.documento }}
            </span>
          </span>

          <!--vencimento -->
          <span class="text-subtitle-1">
            Vencimento:
            <span class="font-weight-bold">
              {{ conta.data_vencimento | dateFormat("dd/MM/yyyy") }}
            </span>
          </span>
        </div>
        <!--data pagamento -->
        <div class="d-flex justify-space-between py-6">
          <span class="text-subtitle-1">
            Data Pagamento:
            <span class="font-weight-bold">
              {{ conta.data_pagamento | dateFormat("dd/MM/yyyy") }}
            </span>
          </span>

          <!--valor -->
          <span class="text-subtitle-1">
            Valor:
            {{ conta.moeda_sigla }}
            <span class="font-weight-bold" v-if="conta.moeda_sigla === 'G$'">
              {{ conta.valor | guarani }}
            </span>
            <span class="font-weight-bold" v-else>
              {{ conta.valor | currency }}
            </span>
          </span>

          <!--desconto -->
          <span class="text-subtitle-1">
            Desconto:
            {{ conta.moeda_sigla }}
            <span class="font-weight-bold" v-if="conta.moeda_sigla === 'G$'">
              {{ conta.desconto | guarani }}
            </span>
            <span class="font-weight-bold" v-else>
              {{ conta.desconto | currency }}
            </span>
          </span>
          <!--valor -->
          <span class="text-subtitle-1">
            Valor Pago:
            {{ conta.moeda_sigla }}
            <span class="font-weight-bold" v-if="conta.moeda_sigla === 'G$'">
              {{ conta.valor_pago | guarani }}
            </span>
            <span class="font-weight-bold" v-else>
              {{ conta.valor_pago | currency }}
            </span>
          </span>
          <!--saldo -->
          <span class="text-subtitle-1">
            Saldo:
            {{ conta.moeda_sigla }}
            <span class="font-weight-bold" v-if="conta.moeda_sigla === 'G$'">
              {{ conta.saldo | guarani }}
            </span>
            <span class="font-weight-bold" v-else>
              {{ conta.saldo | currency }}
            </span>
          </span>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <!-- <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn
          small
          class="white--text"
          color="button_2"
          @click="editarConta()"
        >
          {{ $tc("global.editar") }}
          <v-icon right>mdi-pencil </v-icon>
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchContaPagar } from "@/api/contas-pagar/contas_pagar.js";
export default {
  name: "DialogContaPagarViewr",

  props: {
    dialogContaPagarViewr: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    conta_id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      conta: {},
    };
  },

  methods: {
    getContaPagar() {
      this.loading = true;
      fetchContaPagar(this.conta_id)
        .then((response) => {
          this.conta = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getContaPagar();
  },
};
</script>
