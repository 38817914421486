import { fetchListasPrecos } from "@/api/listas-precos/listasprecos.js";

const initialState = () => ({
  listas_precos: null,
});

const state = initialState();

const getters = {};

const actions = {
  getListas({ commit }, filtros) {
    if (!state.listas_precos) {
      return fetchListasPrecos(filtros)
        .then((response) => {
          commit("UPDATE_LISTAS", response);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }
  },
  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_LISTAS(state, payload) {
    state.listas_precos = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
