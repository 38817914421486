import api from "../axios_service.js";

export async function fetchCartoesBandeiras(filtros) {
  const response = await api.get(`/cartoes-bandeiras${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchCartaoBandeira(id) {
  const response = await api.get(`/cartoes-bandeiras/${id}`);
  return response.data.result;

}

export function putCartaoBandeira(id, body) {
  return api.put(`/cartoes-bandeiras/${id}`, body);
}

export function postCartaoBandeira(body) {
  return api.post("/cartoes-bandeiras/add", body);
}

