<template>
  <v-dialog
    v-model="DialogAddFatura"
    @click:outside="$emit('update:DialogAddFatura', false)"
    @keydown.esc="$emit('update:DialogAddFatura', false)"
    max-width="1000px"
    scrollable
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        <span class="headline">
          {{ $tc("global.add") }} {{ $tc("global.fatura") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('update:DialogAddFatura', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5">
        <v-select
          v-model="timbrado_id"
          :items="timbrados"
          item-text="timbrado"
          item-value="timbrado_id"
          clearable
          outlined
          dense
          hide-details
          :label="$tc('global.timbrado')"
        >
          <template v-slot:selection="{ item }">
            <span>
              {{
                `${item.descricao ? item.descricao : ""} - ${item.timbrado} `
              }}
            </span>
          </template>
          <template v-slot:item="{ item }">
            <span>
              {{
                `${item.descricao ? item.descricao : ""} - ${item.timbrado} `
              }}
            </span>
          </template>
        </v-select>

        <v-card outlined class="mt-6">
          <v-card-title class="text-body-1 px-3 py-2 tocs_gray_1">
            {{ $tc("global.selecionar") }} {{ $tc("global.venda") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table
              v-model="vendasSelected"
              :headers="headers"
              :items="vendas"
              :items-per-page="10"
              item-key="id"
              show-select
              single-select
              class="data-tables data-tables__row-click"
            >
              <template v-slot:item.data="{ item }">
                {{ item.data | dateFormat("dd/MM/yyyy") }}
              </template>
              <template v-slot:item.valor="{ item }">
                {{ item.moeda_sigla }}
                <span v-if="item.moeda_sigla === 'G$'">
                  {{ item.valor | guarani }}
                </span>
                <span v-else>
                  {{ item.valor | currency }}
                </span>
              </template>
              <template v-slot:item.desconto="{ item }">
                {{ item.moeda_sigla }}
                <span v-if="item.moeda_sigla === 'G$'">
                  {{ item.desconto | guarani }}
                </span>
                <span v-else>
                  {{ item.desconto | currency }}
                </span>
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.moeda_sigla }}
                <span v-if="item.moeda_sigla === 'G$'">
                  {{ item.total | guarani }}
                </span>
                <span v-else>
                  {{ item.total | currency }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="novaFatura"
          class="white--text"
          color="button_2"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-12'"
          :disabled="disableAdd"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getVendas } from "@/api/vendas/vendas.js";
import { postFatura } from "@/api/faturas/faturas.js";
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";

export default {
  name: "DialogAddFatura",

  props: {
    DialogAddFatura: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  data() {
    return {
      loading: false,
      vendas: [],
      vendasSelected: [],
      timbrados: [],
      timbrado_id: null,
    };
  },

  watch: {},

  computed: {
    headers() {
      return [
        {
          text: "id",
          width: "80px",
          value: "id",
        },
        {
          text: this.$tc("global.data"),
          width: "120px",
          value: "data",
        },
        {
          text: this.$tc("global.numero"),
          width: "120px",
          value: "numero",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
        },
        {
          text: this.$tc("global.valor"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "valor",
        },
        {
          text: this.$tc("global.total"),
          width: "150px",
          align: "right",
          sortable: false,
          value: "total",
        },
      ];
    },

    disableAdd() {
      let result = false;

      if (this.vendasSelected.length === 0 || !this.timbrado_id) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    async fetchVendas() {
      const response = await getVendas(`?status=5&sem_fatura=true`);
      this.vendas = response;
    },
    async getUserTimbrados() {
      const response = await fetchUserTimbrados();
      this.timbrados = response;
    },

    novaFatura() {
      this.loading = true;
      postFatura({
        venda_id: this.vendasSelected[0].id,
        timbrado_id: this.timbrado_id,
      })
        .then((response) => {
          if (response.status === 201) {
            this.fetchVendas();
            this.$emit("fetch-faturas");
            this.$emit("update:DialogAddFatura", false);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.fetchVendas();
      await this.getUserTimbrados();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
