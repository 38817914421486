import api from "../axios_service.js";

//pega todos os Contatos
export async function all() {
  const response = await api.get("/cliente-contatos");
  return response.data.result;
}

// pega um contato
export async function fetchContatosClientes(id) {
  const response = await api.get(`/cliente-contatos?cliente_id=${id}`);
  return response.data.result;
}

// Atualiza um contato existente
export function putContatosClientes(id, contato) {
  return api.put(`/cliente-contatos/${id}`, contato);
}

// Cria um contato novo
export function postContatosClientes(contato) {
  return api.post("/cliente-contatos/add", contato);
}

//Deleta um contato
export function deleteContatosClientes(id) {
  return api.delete(`/cliente-contatos/${id}`);
}
