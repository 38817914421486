import api from "../axios_service.js";

export async function fetchCartoes(filtros) {
  const response = await api.get(`/compensacao-cartoes-all${filtros ? filtros : ""}`);
  return response.data.result;
}


export function postCompensarCartoes(body) {
  return api.post("/compensar-cartoes", body);
}