<template>
  <v-select
    v-model="empresa"
    :items="empresas"
    :loading="loading"
    clearable
    outlined
    dense
    hide-details
    label="Empresa"
    item-text="nome"
    item-value="id_empresa"
    :disabled="disabled"
    @click:clear="clearEmpresa"
    @change="updateEmpresa"
  ></v-select>
</template>

<script>
import { fetchEmpresas } from "@/api/empresas/empresas.js";
import { mapState } from "vuex";

export default {
  name: "Empresas",

  data() {
    return {
      empresa: null,
      disabled: false,
      empresas: [],
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  methods: {
    updateEmpresa() {
      if (this.empresa) {
        this.$store.commit("FiltrosRelatorios/UPDATE_EMPRESA", this.empresa.id);
      }
    },

    clearEmpresa() {
      this.$store.commit("FiltrosRelatorios/UPDATE_EMPRESA", null);
      this.empresa = null;
    },
  },

  async created() {
    try {
      this.loading = true;
      this.empresas = await fetchEmpresas();
      this.filtros_componente.find((item) => {
        if (item.text == "Empresa") {
          this.empresas.find((empresa) => {
            if (empresa.id == item.default) {
              this.empresa = empresa;
            }
          });
          if (item.disabled) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
          this.updateEmpresa();
        }
      });

      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
