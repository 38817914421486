var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageToolbar',{attrs:{"title":_vm.$tc('global.ordensServicos', 2),"icon":"mdi-toolbox-outline","dark":""}}),_c('v-card',[_c('v-card-title',{staticClass:"tocs_gray_1"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"ml-0 ml-sm-3 mt-4 mt-sm-0",staticStyle:{"max-width":"450px"},attrs:{"items":_vm.status_lista,"label":"Status","multiple":"","hide-details":"","flat":"","dense":"","solo":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":item.color}}),_vm._v(" "+_vm._s(item.text)+" ")],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":item.color}}),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.text))])],1),(index < _vm.statusFilter.length - 1)?_c('v-divider',{staticClass:"mx-2 mt-8 mt-sm-0",attrs:{"vertical":""}}):_vm._e()]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}),_c('v-spacer',{staticClass:"d-none d-sm-flex"})],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables",class:{ 'data-tables__row-click': _vm.POrdensServicosVisualizar },attrs:{"loading":_vm.loading,"headers":_vm.headers,"search":_vm.search,"items":_vm.ordem_servicos,"items-per-page":10,"sort-by":"id","sort-desc":"true"},on:{"click:row":_vm.goToOrdemServico},scopedSlots:_vm._u([{key:"item.previsao_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.previsao_inicio,"dd/MM/yyyy"))+" ")]}},{key:"item.previsao_fim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.previsao_fim,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")((item.valor - item.desconto)))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")((item.valor - item.desconto)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 1)?_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"yellow lighten-1"}}),_vm._v(" "+_vm._s(_vm.$tc("global.pendentes"))+" ")],1):_vm._e(),(item.status === 2)?_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"lime darken-2"}}),_vm._v(" "+_vm._s(_vm.$tc("global.emPreparação"))+" ")],1):_vm._e(),(item.status === 3)?_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"light-blue darken-3"}}),_vm._v(" "+_vm._s(_vm.$tc("global.aguardando") + " " + _vm.$tc("global.instalacao"))+" ")],1):_vm._e(),(item.status === 4)?_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"orange lighten-1"}}),_vm._v(" "+_vm._s("Em " + _vm.$tc("global.instalacao"))+" ")],1):_vm._e(),(item.status === 5)?_c('div',{staticClass:"d-flex align-center"},[_c('v-sheet',{staticClass:"mr-2",staticStyle:{"border-radius":"3px"},attrs:{"width":"10px","height":"10px","color":"green lighten-1"}}),_vm._v(" "+_vm._s(_vm.$tc("global.finalizado"))+" ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status !== 0 || item.status !== 1)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.gerarpdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-basket")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc("global.produto", 2)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status >= 3)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.gerarCheckList(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-underline-circle-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Check list")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status >= 3)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.gerarTermo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-export")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc("global.termo")))])])]}}])})],1)],1),(_vm.dialogOrdemServico)?_c('DialogOrdemServico',{attrs:{"dialogOrdemServico":_vm.dialogOrdemServico,"editar":_vm.editar,"ordem_servico_id":_vm.ordem_servico_id},on:{"update:dialogOrdemServico":function($event){_vm.dialogOrdemServico=$event},"update:dialog-ordem-servico":function($event){_vm.dialogOrdemServico=$event},"fetch-ordens-servicos":_vm.getOrdemServicos}}):_vm._e(),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":_vm.$tc('global.ordensServicos')},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }