const routes = [
  {
    path: "/super-admin/moedas",
    component: () => import("@/views/super_admin/moedas/Moedas.vue"),
    meta: {
      component: "Moedas",
      menu: "super_admin",
      title: "Moedas",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.moeda", plural: true },
      ],
    },
  },
];

export default routes;
