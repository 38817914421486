import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { fetchExtratoCaixa } from "@/api/relatorios/relatorio_caixas.js";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import { format, parseISO } from "date-fns";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();
  const filtros = bodyPDF.queryString;

  let extrato = await fetchExtratoCaixa(filtros);
  let extrato_info = extrato.data;
  const filtros_info = extrato.filtros;
  let text_filtros = "";

  for (const [key, value] of Object.entries(filtros_info)) {
    if (value){ 
      text_filtros += key + ": " + value + "   ";
    }
  }
  const cabecalho = bodyPDF.cabecalho;
  const periodo = bodyPDF.periodo;

  return new Promise((resolve, reject) => {
    let cabecalho_filtros = {
      margin: [0, 0, 0, -3],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: text_filtros,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let cabecalho_data = {
      margin: [0, 5, 0, 2],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: " EXTRATO DE CAIXA - " + periodo,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let table_extrato = {
      margin: [0, 0, 0, 5],
      table: {
        widths: ["auto", "auto", "auto", "*", "auto", "auto", "auto", "auto", "*"],
        body: [
          [
            {
              text: "CAIXA",
              border: [true, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "DATA",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "DOCUMENTO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "HISTORICO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
                text: "MOEDA",
                border: [false, true, false, true],
                bold: true,
                alignment: "center",
                margin: [0, 4, 0, 2],
                fontSize: 8,
              },
            {
              text: "VALOR",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "FORMA",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "TIPO",
              border: [false, true, false, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "USUARIO",
              border: [false, true, true, true],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 1;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    const row_font_size = 8;
    extrato_info.forEach((item, index) => {
        let table_row = [
          {
            text: item.caixa + " - " + item.caixa_descricao,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              true,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.data ? format(parseISO(item.data), "dd/MM/yyyy") : "",
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.documento,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.historico,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.moeda,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text:(
                item.moeda == "G$"
                  ? Vue.filter("guarani")(item.valor)
                  : Vue.filter("currency")(item.valor)
              ),        
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.forma_pagamento,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.tipo_af,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              false,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
          {
            text: item.usuario,
            fontSize: row_font_size,
            alignment: "center",
            margin: [0, 4, 0, 5],
            border: [
              false,
              true,
              true,
              index != extrato_info.length - 1 ? false : true,
            ],
          },
        ];

        table_extrato.table.body.push(table_row);
      
    });

    let content = [cabecalho, cabecalho_data, table_extrato];
    if (!Object.values(filtros_info).every(value => value === null)) {
      content.splice(1, 0, cabecalho_filtros);
    }

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
