import api from "../axios_service.js";

//pega todas contas a pagar
export async function getContasPagar(filtros) {
  const response = await api.get(`/contas-pagar${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function getBoxContasPagar(filtros) {
  const response = await api.get(`/box-contas-pagar${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca uma conta a pagar
export async function fetchContaPagar(id) {
  const response = await api.get(`/contas-pagar/${id}`);
  return response.data.result;
}

export async function putContaPagar(id, contapagar) {
  let body = {};
  for (let key in contapagar) {
    body[key] = contapagar[key];
  }
  return api.put(`/contas-pagar/${id}`, body);
}

export function postContaPagar(contapagar) {
  return api.post("/contas-pagar/add", contapagar);
}
