<template>
  <v-dialog
    v-model="dialogOrcamento"
    @click:outside="$emit('update:dialogOrcamento', false)"
    @keydown.esc="$emit('update:dialogOrcamento', false)"
    max-width="1400px"
  >
    <v-card :loading="loading" :disabled="loading" height="550px" tile>
      <v-card-title
        class="pt-3 pb-4 px-5 text-subtitle-1 secondary white--text"
      >
        <v-row class="d-flex aling-center">
          <v-col class="d-flex align-center">
            <v-autocomplete
              v-model="selectedCliente"
              :items="clientes"
              :loading="buscandoClientes"
              :search-input.sync="searchCliente"
              dense
              no-filter
              dark
              hide-details
              item-text="nome"
              item-value="id"
              :placeholder="$tc('global.buscarCliente')"
              append-icon=""
              full-width
              return-object
              :disabled="orcamento.status !== 0"
              :autofocus="!selectedCliente ? true : false"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              v-model="selectedLista"
              :items="listas_precos"
              :label="$tc('global.listadepreco')"
              item-text="descricao"
              item-value="id"
              dark
              :disabled="orcamento_items.length >= 1 || selectedProduto != null"
              class="px-4 mt-0"
              hide-details
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              v-model="selectedMoeda"
              :items="moedas"
              :label="$tc('global.moeda')"
              item-text="descricao"
              item-value="id"
              :disabled="orcamento_items.length >= 1 || selectedProduto != null"
              dark
              class="px-4 mt-0"
              hide-details
            >
            </v-combobox>
          </v-col>
          <v-col cols="12" md="3">
            <v-combobox
              v-model="selectedDeposito"
              :label="$tc('global.deposito', 2)"
              :items="depositos"
              item-text="descricao"
              item-value="id"
              dark
              class="px-4 mt-0"
              :disabled="orcamento_items.length >= 1 || selectedProduto != null"
              hide-details
              required
            ></v-combobox>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text style="flex-grow: 1" class="pa-0 d-flex">
        <div style="flex-grow: 1">
          <v-data-table
            :headers="orcamento_items_headers"
            :items="orcamento_items"
            :items-per-page="-1"
            disable-sort
            fixed-header
            class="data-tables"
            height="
               calc(550px - 186px)
            "
            hide-default-footer
          >
            <template v-slot:item.img="{ item }">
              <div class="my-1">
                <v-avatar v-if="item.img">
                  <v-img max-width="65px" :src="item.img"></v-img>
                </v-avatar>
                <v-avatar v-else>
                  <v-img
                    max-width="65px"
                    src="@/assets/product-placeholder.jpg"
                  ></v-img>
                </v-avatar>
              </div>
            </template>

            <template v-slot:item.qtde="{ item, index }">
              <template
                v-if="
                  isOrcamentoItemEditing && index === indexOrcamentoItemEditing
                "
              >
                <div class="d-flex align-center justify-center">
                  <v-text-field
                    v-model.number="orcamentoItemEditing.qtde"
                    placeholder="Qtde"
                    outlined
                    dense
                    class="input-low-height input-center"
                    hide-details
                    append-outer-icon="mdi-plus"
                    @click:append-outer="orcamentoItemEditing.qtde++"
                    prepend-icon="mdi-minus"
                    @click:prepend="
                      orcamentoItemEditing.qtde > 1
                        ? orcamentoItemEditing.qtde--
                        : ''
                    "
                  ></v-text-field>
                </div>
              </template>
              <template v-else>
                {{ item.qtde }}
              </template>
            </template>

            <template v-slot:item.preco="{ item, index }">
              <div
                v-show="
                  isOrcamentoItemEditing && index === indexOrcamentoItemEditing
                "
              >
                <div class="d-flex align-center justify-center">
                  <v-text-field
                    v-model="orcamentoItemEditingPreco"
                    placeholder="Preço"
                    hide-details
                    outlined
                    dense
                    class="input-low-height"
                    ref="orcamentoItemEditingPreco"
                    v-currency="vCurrencyOptions"
                    @keydown.enter="UpdateOrcamentoItem"
                  ></v-text-field>
                </div>
              </div>
              <div
                v-show="
                  !isOrcamentoItemEditing && index != indexOrcamentoItemEditing
                "
              >
                <span v-if="selectedMoeda.sigla === 'G$'">
                  {{ item.preco | guarani }}
                </span>
                <span v-else>{{ item.preco | currency }} </span>
              </div>
            </template>

            <template v-slot:item.subtotal="{ item, index }">
              <template
                v-if="
                  isOrcamentoItemEditing && index === indexOrcamentoItemEditing
                "
              >
                <span v-if="selectedMoeda.sigla === 'G$'">
                  {{
                    (orcamentoItemEditing.qtde * orcamentoItemEditing.preco)
                      | guarani
                  }}
                </span>
                <span v-else
                  >{{
                    (orcamentoItemEditing.qtde * orcamentoItemEditing.preco)
                      | currency
                  }}
                </span>
              </template>
              <template v-else>
                <span v-if="selectedMoeda.sigla === 'G$'">
                  {{ (item.qtde * item.preco) | guarani }}
                </span>
                <span v-else>{{ (item.qtde * item.preco) | currency }} </span>
              </template>
            </template>

            <template v-slot:item.actions="{ item, index }">
              <template
                v-if="
                  isOrcamentoItemEditing && index === indexOrcamentoItemEditing
                "
              >
                <v-btn
                  color="green lighten-1"
                  icon
                  small
                  @click.stop="UpdateOrcamentoItem"
                >
                  <v-icon size="20">mdi-content-save</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  :disabled="editar ? !POrcamentoEditar : false"
                  icon
                  small
                  @click.stop="StartEditOrcamentoItem(item, index)"
                >
                  <v-icon size="20">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  :disabled="editar ? !POrcamentoEditar : false"
                  color="red lighten-2"
                  icon
                  small
                  @click.stop="DeleteOrcamentoItem(index)"
                >
                  <v-icon size="20">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </template>
          </v-data-table>
          <div
            style="position: relative"
            class="tocs_gray_1 d-flex align-center flex-wrap pa-2"
          >
            <SearchProdutos
              :produto_sync.sync="selectedProduto"
              :lista_preco_id="orcamento.lista_preco_id"
              :moeda_id="orcamento.moeda_id"
              @clear-add-item="ClearAddOrcamentoItem"
              ref="buscaProduto"
              style="max-width: 45%"
              typeComp="orcamento"
              :disableAll="
                disableOrcamento ? true : false || desativaBuscaItems
              "
            />

            <!-- Quantidade -->
            <v-text-field
              v-model.number="orcamento_item.qtde"
              placeholder="Qtde"
              hide-details
              dense
              solo
              flat
              outlined
              class="mx-3 input-center"
              style="max-width: 15%"
              append-outer-icon="mdi-plus"
              :disabled="disableOrcamento ? true : false"
              @click:append-outer="orcamento_item.qtde++"
              prepend-icon="mdi-minus"
              @click:prepend="
                orcamento_item.qtde > 1 ? orcamento_item.qtde-- : ''
              "
            ></v-text-field>

            <!-- Preço -->
            <v-text-field
              v-model="orcamentoItemPreco"
              placeholder="Preço"
              hide-details
              dense
              solo
              flat
              outlined
              class="mr-3"
              style="max-width: 15%"
              @keydown.enter="AddOrcamentoItem"
              ref="orcamentoItemPreco"
              :disabled="disableOrcamento ? true : false"
              v-currency="vCurrencyOptions"
            ></v-text-field>

            <div
              v-if="
                selectedProduto && estoque_disponivel != null && !loadingEstoque
              "
            >
              <div v-if="estoque_disponivel > 0">
                <div class="text-caption">
                  {{ $tc("global.saldo") }} {{ $tc("global.disponivel") }}
                </div>

                <div class="text-caption">
                  {{ estoque_disponivel }}
                </div>
              </div>

              <div v-if="estoque_disponivel <= 0">
                <div class="text-caption red--text">Produto sem</div>
                <div class="text-caption red--text">Saldo Disponível</div>
              </div>
            </div>

            <div v-else-if="loadingEstoque">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>

            <v-btn
              depressed
              color="secondary"
              height="38px"
              absolute
              right
              :disabled="disableOrcamento || !selectedProduto ? true : false"
              :loading="loadingAddOrcamentoItem"
              @click="AddOrcamentoItem"
            >
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <div
          style="
            width: 200px;
            border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
          "
          class="
            tocs_gray_1
            d-flex
            flex-column
            align-stretch
            justify-space-between
          "
        >
          <!-- Totais -->
          <div
            class="
              px-2
              fill-height
              d-flex
              flex-column
              align-stretch
              justify-end
            "
          >
            <!-- SubTotal -->
            <div>
              <h3 class="text-h6 pb-0 mb-n4 tocs_gray_2--text">
                Subtotal {{ $tc("global.geral") }}:
              </h3>
              <h3 class="text-h5 text-right pt-4">
                <v-sheet
                  rounded
                  outlined
                  height="40px"
                  class="
                    blue-grey--text
                    d-flex
                    align-center
                    justify-space-between
                    text--lighten-1
                    pr-4
                  "
                >
                  <span class="ml-3 subtitle-1">
                    {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                  </span>

                  <span v-if="selectedMoeda ? selectedMoeda.sigla === 'G$' : ''"
                    >{{ subTotalGeral | guarani }}
                  </span>
                  <span v-else>{{ subTotalGeral | currency }} </span>
                </v-sheet>
              </h3>
            </div>

            <!-- Desconto -->
            <div class="mt-4">
              <h3 class="text-h6 tocs_gray_2--text">
                {{ $tc("global.desconto") }}:
              </h3>
              <v-text-field
                ref="inputDesconto"
                v-model="orcamentoDesconto"
                dense
                :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                outlined
                solo
                :error-messages="erroDesconto"
                height="40px"
                style="display: flex"
                class="remove-underline input-font-size input-right"
                :disabled="disableOrcamento ? true : false"
                v-currency="vCurrencyOptions"
                @blur="validaDesconto"
              ></v-text-field>
            </div>

            <!-- Total -->
            <div class="mt-0 mb-4">
              <h3 class="text-h6 tocs_gray_2--text">
                {{ $tc("global.total") }}:
              </h3>
              <h3 class="text-h5 white--text">
                <v-sheet
                  rounded
                  outlined
                  height="40px"
                  class="
                    blue-grey--text
                    d-flex
                    align-center
                    justify-space-between
                    text--darken-3
                    pr-4
                  "
                >
                  <span class="ml-3 subtitle-1">
                    {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                  </span>
                  <span v-if="selectedMoeda ? selectedMoeda.sigla === 'G$' : ''"
                    >{{ totalGeral | guarani }}
                  </span>
                  <span v-else>{{ totalGeral | currency }} </span>
                </v-sheet>
              </h3>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="editar"
          class="secondary white--text"
          depressed
          small
          outlined
          @click="$emit('update:dialogOrcamento', false)"
        >
          <v-icon left small class="mx-1">mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
        <!-- <v-btn
          v-if="!editar"
          class="error white--text"
          depressed
          small
          outlined
          @click="dialogOrcamento = true"
        >
          <v-icon left small class="mx-1">mdi-close</v-icon>
          Fechar
        </v-btn> -->
        <v-spacer></v-spacer>

        <v-btn
          v-if="orcamento.status != 1"
          depressed
          small
          color="button_2"
          class="white--text"
          @click="editar ? salvarOrcamento() : criarOrcamento()"
          :disabled="
            editar
              ? !POrcamentoEditar
              : false || invalidDesconto || orcamento_items.length <= 0
          "
        >
          <v-icon left small class="mx-1">mdi-content-save</v-icon>
          Salvar
        </v-btn>
        <!-- Finalizar Orçamento -->

        <v-btn
          v-if="orcamento.status != 1 && orcamento.id"
          small
          depressed
          color="button_1"
          class="white--text"
          :disabled="
            editar ? !POrcamentoEditar : false || validaFinalizarOrcamento
          "
          @click="finalizarOrcamento()"
        >
          <v-icon left small class="mx-1">mdi-check</v-icon>
          Finalizar
        </v-btn>
      </v-card-actions>
      <NovaVenda
        v-if="dialogNovaVenda"
        :dialogNovaVenda.sync="dialogNovaVenda"
        :item="venda"
        :orcamento="true"
        @fecha-dialog="$emit('update:dialogOrcamento', false)"
        @fecth-orcamentos="$emit('fetch-orcamentos')"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { searchClientes } from "@/api/clientes/clientes.js";
import { fetchLPCompletas } from "@/api/listas-precos/listasprecos.js";
import { inputRequired } from "@/helpers/utils.js";
import { mapState } from "vuex";
import {
  buscaProdutos,
  postEstoqueMinimo,
  getOneProduto,
} from "@/api/produtos/produtos.js";
import {
  postOrcamento,
  putOrcamento,
  postFinalizarOrcamento,
} from "@/api/orcamentos/orcamentos.js";
import { fetchOrcamentoItems } from "@/api/orcamentos/orcamento_items.js";
import { fetchDepositosList } from "@/api/produtos/produtos_depositos.js";
import { mapGetters } from "vuex";

export default {
  name: "DialogOrcamento",

  components: {
    NovaVenda: () =>
      import("@/views/dashboard/vendas/components/NovaVenda.vue"),
    SearchProdutos: () => import("@/components/fields/SearchProdutos.vue"),
  },

  props: {
    dialogOrcamento: {
      type: Boolean,
      default: false,
    },
    editar: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      loadingEstoque: false,
      orcamento: {
        desconto: 0,
        status: 0,
      },
      orcamento_items: [],
      orcamento_item: {
        qtde: 1,
      },
      selectedCliente: null,
      clientes: [],
      buscandoClientes: false,
      searchCliente: "",
      orcamentoDesconto: 0,
      listas_precos: [],
      selectedLista: null,
      selectedMoeda: null,
      selectedProduto: null,
      erroDesconto: "",
      produtos: [],
      buscandoProduto: false,
      buscaProduto: "",
      orcamentoItemPreco: null,
      estoque_disponivel: null,
      loadingAddOrcamentoItem: false,
      isOrcamentoItemEditing: false,
      orcamentoItemEditingPreco: null,
      orcamentoItemEditing: null,
      indexOrcamentoItemEditing: null,
      loadingOrcamento: false,
      invalidDesconto: false,
      loadingOrcamentoItems: false,
      dialogNovaVenda: false,
      venda: {},
      selectedDeposito: null,
      depositos: [],
      formRules: [inputRequired],
      debounce: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    POrcamentoEditar() {
      return this.getAccess("Orcamentos", "editar");
    },

    disableOrcamento() {
      let result = false;
      if (this.orcamento.status === 1) {
        result = true;
      }
      if (!this.POrcamentoEditar) {
        result = true;
      }
      return result;
    },

    orcamento_items_headers() {
      let headers = [
        {
          text: "",
          divider: true,
          width: "70px",
          value: "img",
        },
        {
          text: this.$tc("global.produto"),
          divider: true,
          value: "descricao",
        },
        {
          text: "Qtde",
          divider: true,
          width: "150px",
          value: "qtde",
          align: "center",
        },
        {
          text: this.$tc("global.preco"),
          divider: true,
          width: "150px",
          value: "preco",
          align: "end",
        },
        {
          text: "SubTotal",
          divider: true,
          width: "150px",
          value: "subtotal",
          align: "end",
        },
      ];
      if (this.orcamento.status == 0) {
        let actions = {
          text: "",
          width: "90px",
          align: "center",
          value: "actions",
        };

        headers.push(actions);
      }

      return headers;
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    subTotalGeral() {
      let result = 0;

      if (this.orcamento_items.length) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.preco * currentValue.qtde;
        };

        result = this.orcamento_items.reduce(reducer, 0);
      }
      return result;
    },

    totalGeral() {
      let result = 0;

      if (this.orcamento_items.length && this.orcamento) {
        result = Number(this.subTotalGeral) - Number(this.orcamento.desconto);
      }

      return result;
    },

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },

    validaFinalizarOrcamento() {
      let result = false;
      if (this.invalidDesconto) {
        result = true;
      }
      if (this.orcamento_items.length <= 0) {
        result = true;
      }
      if (!this.selectedCliente) {
        result = true;
      }

      return result;
    },

    desativaBuscaItems() {
      let result = false;
      if (!this.selectedLista) {
        result = true;
      }
      if (!this.selectedMoeda) {
        result = true;
      }
      if (this.orcamento.status !== 0) {
        result = true;
      }
      if (!this.selectedDeposito) {
        result = true;
      }
      return result;
    },
  },

  watch: {
    orcamentoItemPreco() {
      this.orcamento_item.preco = this.$ci.parse(
        this.orcamentoItemPreco,
        this.vCurrencyOptions
      );
    },

    selectedProduto() {
      if (this.selectedProduto) {
        this.getProduto();
      }
    },

    selectedCliente() {
      if (this.selectedCliente) {
        this.orcamento.cliente_id = this.selectedCliente.id;
        if (!this.editar) {
          this.selectedLista = this.listas_precos.find(
            (lista) => lista.id === this.selectedCliente.lista_preco_id
          );
          this.selectedMoeda = this.moedas.find(
            (moeda) => moeda.id_moeda === this.selectedCliente.moeda_id
          );
        }

        this.$nextTick().then(() => {
          this.$refs.buscaProduto.$refs.buscaProduto.focus();
        });
      }
    },

    selectedLista() {
      if (this.selectedLista) {
        this.orcamento.lista_preco_id = this.selectedLista.id;
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda) {
        this.orcamento.moeda_id = this.selectedMoeda.id_moeda;
      }
    },

    selectedDeposito() {
      if (this.selectedDeposito) {
        this.orcamento.deposito_id = this.selectedDeposito.id;
      }
    },

    searchCliente() {
      if (this.buscandoClientes) return;
      this.buscaClientes();
    },
  },

  methods: {
    async getListasPrecos() {
      const response = await fetchLPCompletas();
      this.listas_precos = response;
    },

    async getDepositos() {
      const response = await fetchDepositosList();
      this.depositos = response;
      if (!this.editar) {
        this.selectedDeposito = this.depositos[0];
      }
    },

    async initEditOrcamento() {
      this.loading = true;
      this.orcamento = { ...this.item };

      if (this.orcamento.cliente_id) {
        await this.searchClientes(`?search=id:${this.orcamento.cliente_id}`);
        this.selectedCliente = this.clientes[0];
      }

      this.selectedDeposito = this.depositos.find((item) => {
        return item.id === this.orcamento.deposito_id;
      });

      this.selectedLista = this.listas_precos.find((item) => {
        return item.id === this.orcamento.lista_preco_id;
      });

      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda === this.orcamento.moeda_id;
      });

      this.$ci.setValue(this.$refs.inputDesconto, this.orcamento.desconto);
      await this.getOrcamentoItems();
      this.loading = false;
    },

    async searchClientes(filtro) {
      this.buscandoClientes = true;
      const response = await searchClientes(filtro);
      this.clientes = response;
      this.buscandoClientes = false;
    },

    // async buscaClientes() {
    //   if (this.searchCliente) {
    //     if (this.searchCliente.length > 2) {
    //       this.buscandoClientes = true;
    //       await this.searchClientes(`?search=${this.searchCliente}`);
    //       this.buscandoClientes = false;
    //     }
    //   }
    // },

    async buscaClientes() {
      const filtro = `?search=${this.searchCliente}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.searchClientes(this.searchCliente ? filtro : "");
      }, 500);
    },

    async getProduto() {
      let filtro = `?lista_preco_id=${this.selectedLista.id}`;
      filtro += `&moeda_id=${this.selectedMoeda.id_moeda}`;

      const produto = await getOneProduto(this.selectedProduto.id, filtro);

      if (!produto) {
        this.$toast.warning(
          "Produto sem preço registrado nesta lista e com essa moeda!"
        );
        this.ClearAddOrcamentoItem();
        return;
      }

      this.$ci.setValue(this.$refs.orcamentoItemPreco, Number(produto.preco));

      this.orcamento_item.cif = Number(produto.cif);
      this.orcamento_item.produto_id = produto.id;
      this.orcamento_item.descricao = produto.descricao;
      if (produto.img) {
        this.orcamento_item.img = produto.img;
      }

      await this.controleEstoque(produto.id, this.orcamento_item.qtde);

      this.$refs.orcamentoItemPreco.focus();
    },

    async finalizarOrcamento() {
      // this.venda = { ...item };
      // this.venda.orcamento_id = item.id;
      // this.venda.venda_items = this.orcamento_items;
      // this.dialogNovaVenda = true;
      await this.salvarOrcamento();

      let orcamento = {};

      if (this.selectedCliente) {
        orcamento.cliente_id = this.selectedCliente.id;
        orcamento.cliente_nome = this.selectedCliente.nome;
        orcamento.cliente_endereco = this.selectedCliente.endereco;
        orcamento.cliente_estado = this.selectedCliente.departamento;
        orcamento.cliente_pais = this.selectedCliente.pais;
        if (this.selectedCliente.doc_ci) {
          orcamento.cliente_documento = this.selectedCliente.doc_ci;
        } else {
          orcamento.cliente_documento = this.selectedCliente.doc_ruc;
        }

        orcamento.cliente_cidade = this.selectedCliente.cidade;
      }
      orcamento.moeda_id = this.orcamento.moeda_id;
      orcamento.lista_preco_id = this.orcamento.lista_preco_id;
      orcamento.desconto = this.orcamento.desconto;
      orcamento.user_id = this.user_id;
      orcamento.valor = this.subTotalGeral;
      orcamento.total = this.totalGeral;
      orcamento.obs = this.orcamento.obs;
      orcamento.deposito_id = this.orcamento.deposito_id;
      orcamento.orcamento_items = this.orcamento_items;

      postFinalizarOrcamento(this.orcamento.id, orcamento)
        .then(() => {
          this.loadingOrcamento = false;
          this.$emit("update:dialogOrcamento", false);
          this.$emit("fetch-orcamentos");
        })
        .finally(() => {
          this.loadingOrcamento = false;
        });
    },

    validaDesconto() {
      let desconto = this.$ci.parse(
        this.orcamentoDesconto,
        this.vCurrencyOptions
      );

      if (desconto === null || desconto === undefined || desconto === "") {
        this.invalidDesconto = true;
      } else {
        if (desconto < 0 || desconto > this.subTotalGeral || isNaN(desconto)) {
          this.erroDesconto = "Valor de desconto inválido";
          this.invalidDesconto = true;
        } else {
          this.erroDesconto = "";

          this.orcamento.desconto = Number(desconto);

          this.invalidDesconto = false;
        }
      }
    },

    searchProdutos(event) {
      if (this.buscaProduto) {
        if (this.buscaProduto.length > 2 && event.key != "Backspace") {
          this.buscandoProduto = true;

          let filtro = `?search=${this.buscaProduto}`;
          filtro += `&lista_preco_id=${this.selectedLista.id}`;
          filtro += `&moeda_id=${this.selectedMoeda.id_moeda}`;

          buscaProdutos(filtro)
            .then((response) => {
              this.produtos = response;
            })
            .finally(() => (this.buscandoProduto = false));
        }
      }
    },

    AddOrcamentoItem() {
      this.loadingAddOrcamentoItem = true;

      if (
        this.orcamento_item.qtde >= 0 &&
        this.orcamentoItemPreco &&
        this.orcamento.deposito_id
      ) {
        let orcamento_item = { ...this.orcamento_item };
        this.orcamento_items.push(orcamento_item);

        this.$nextTick().then(() => {
          this.ClearAddOrcamentoItem();
          this.$refs.buscaProduto.$refs.buscaProduto.focus();
        });

        this.loadingAddOrcamentoItem = false;
      }
      if (!this.orcamento.deposito_id) {
        this.$toast.error("Favor inserir deposito!");
        this.loadingAddOrcamentoItem = false;
      }
      if (!this.orcamentoItemPreco) {
        this.$toast.error(
          "Favor inserir preço antes de adicionar um item na lista!"
        );
        this.loadingAddOrcamentoItem = false;
      }
      if (!this.orcamento_item.qtde || this.orcamento_item.qtde <= 0) {
        this.$toast.error("Quantidade minima para adicionar o item é 1!");
        this.orcamento_item.qtde = 1;
        this.loadingAddOrcamentoItem = false;
      }
    },

    async controleEstoque(item, qtde) {
      this.loadingEstoque = true;

      const produtoEstoque = {
        produtoId: item,
        depositoId: this.orcamento.deposito_id,
      };

      const response = await postEstoqueMinimo(produtoEstoque);
      this.estoque_disponivel = response.data.result;
      this.loadingEstoque = false;
    },

    ClearAddOrcamentoItem() {
      this.selectedProduto = null;
      this.orcamento_item.produto_id = null;
      this.orcamento_item.descricao = null;
      this.orcamento_item.qtde = 1;
      this.orcamento_item.preco = null;
      this.orcamento_item.cif = null;
      this.orcamento_item.img = null;

      this.$ci.setValue(this.$refs.orcamentoItemPreco, null);
    },

    StartEditOrcamentoItem(item, index) {
      this.isOrcamentoItemEditing = true;
      this.orcamentoItemEditing = { ...item };
      this.indexOrcamentoItemEditing = index;

      this.$ci.setValue(this.$refs.orcamentoItemEditingPreco, item.preco);
    },

    UpdateOrcamentoItem() {
      this.orcamento_items[this.indexOrcamentoItemEditing].preco =
        this.$ci.parse(this.orcamentoItemEditingPreco, this.vCurrencyOptions);

      this.orcamento_items[this.indexOrcamentoItemEditing].qtde =
        this.orcamentoItemEditing.qtde;

      this.isOrcamentoItemEditing = false;
      this.orcamentoItemEditing = null;
      this.indexOrcamentoItemEditing = null;
    },

    DeleteOrcamentoItem(index) {
      this.orcamento_items.splice(index, 1);
    },

    criarOrcamento() {
      this.loadingOrcamento = true;

      let orcamento = {};
      if (this.selectedCliente) {
        orcamento.cliente_id = this.selectedCliente.id;
      }
      orcamento.moeda_id = this.orcamento.moeda_id;
      orcamento.lista_preco_id = this.orcamento.lista_preco_id;
      orcamento.desconto = this.orcamento.desconto;
      orcamento.user_id = this.user_id;
      orcamento.valor = this.subTotalGeral;
      orcamento.total = this.totalGeral;
      orcamento.obs = this.orcamento.obs;
      orcamento.deposito_id = this.orcamento.deposito_id;
      orcamento.orcamento_items = this.orcamento_items;

      postOrcamento(orcamento)
        .then(() => {
          this.loadingOrcamento = false;
          this.$emit("update:dialogOrcamento", false);
          this.$emit("fetch-orcamentos");
        })
        .finally(() => {
          this.loadingOrcamento = false;
        });
    },

    salvarOrcamento() {
      this.loadingOrcamento = true;

      let orcamento = {};
      if (this.selectedCliente) {
        orcamento.cliente_id = this.selectedCliente.id;
      }
      orcamento.moeda_id = this.orcamento.moeda_id;
      orcamento.lista_preco_id = this.orcamento.lista_preco_id;
      orcamento.desconto = this.orcamento.desconto;
      orcamento.valor = this.subTotalGeral;
      orcamento.total = this.totalGeral;
      orcamento.obs = this.orcamento.obs;
      orcamento.deposito_id = this.orcamento.deposito_id;
      orcamento.orcamento_items = this.orcamento_items;

      return putOrcamento(this.orcamento.id, orcamento)
        .then(() => {
          this.loadingOrcamento = false;
          // this.$emit("update:dialogOrcamento", false);
          // this.$emit("fetch-orcamentos");
        })
        .finally(() => {
          this.loadingOrcamento = false;
        });
    },

    getOrcamentoItems() {
      this.loadingOrcamentoItems = true;
      return fetchOrcamentoItems(this.orcamento.id)
        .then((response) => {
          this.orcamento_items = response;
        })
        .finally(() => (this.loadingOrcamentoItems = false));
    },
  },

  async mounted() {
    this.loading = true;
    await this.getListasPrecos();
    await this.getDepositos();
    if (this.editar) {
      await this.initEditOrcamento();
    } else {
      await this.searchClientes(`?search=id:1`);
      this.selectedCliente = this.clientes[0];
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.remove-underline ::v-deep .v-input__slot::before {
  border-style: none !important;
}
.input-right ::v-deep input {
  text-align: right;
}
.input-font-size ::v-deep input {
  font-size: 1.5rem;
}
</style>
