const routes = [
  {
    path: "/super-admin/formas-de-pagamento",
    component: () =>
      import("@/views/super_admin/formas-pagamento/FormasPagamento.vue"),
    meta: {
      component: "FormasPagamentos",
      menu: "super_admin",
      title: "Formas de Pagamento",
      breadcrumb: [{ text: "global.home", to: { path: "/" } }],
    },
  },
];

export default routes;
