import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { i18n } from "@/plugins/i18n";
import { format, parseISO } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { layoutEmpresa } from "@/views/dashboard/relatorios/config/layoutEmpresa.js";
import { fetchTransferenciaEstoque } from "@/api/transferenciaEstoque";
import store from "@/store/modules/usuario.store.js";

export async function generateReciboTransferenciaEstoque(item_id) {
  const empresa = await fetchEmpresa(store.state.empresa.id);
  const recibo = await fetchTransferenciaEstoque(item_id);
  return new Promise((resolve, reject) => {
    let docDefinition = {};

    let recibo_info = recibo;

    let geral = [];

    recibo_info.groups.forEach((element, index) => {
      let table_single = {
        pageBreak: index > 0 ? "before" : "",
        margin: [0, 0, 0, 0],
        table: {
          widths: ["*"],
          body: [],
        },
        layout: "noBorders",
      };

      let cabecalho = {
        margin: [0, 0, 0, 0],
        table: {
          widths: ["*", "*", "*"],
          body: [
            [
              {
                // fillColor: "#F5F5F5",
                margin: [12, 5, 2, 2],
                border: [true, true, false, false],
                stack: [
                  {
                    image: empresa.logo64 ? empresa.logo64 : "",
                    width: 60,
                    height: 60,
                  },
                ],
              },
              {
                border: [false, true, true, false],

                stack: [
                  {
                    text: empresa.nome,
                    fontSize: 12,
                    bold: true,
                  },
                  {},
                  {
                    text: "E-mail: " + (empresa.email ? empresa.email : ""),
                  },
                  {
                    text:
                      "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                  },
                  {
                    text:
                      "Dirección: " +
                      (empresa.endereco ? empresa.endereco : " "),
                  },
                ],
                fontSize: 9,
                alignment: "left",
                margin: [-85, 10, 0, 0],
              },
              {
                border: [false, true, true, false],
                margin: [0, 15, 0, 5],
                stack: [
                  {
                    text:
                      "RUC: " + (empresa.documento ? empresa.documento : ""),

                    alignment: "center",
                    fontSize: 10,
                  },
                  {
                    text: i18n.tc("global.comprovante").toUpperCase(),
                    alignment: "center",
                    fontSize: 10,
                    margin: [0, 3, 0, 0],
                  },
                  {
                    text:
                      "FECHA: " +
                      format(parseISO(recibo_info.created_at), "dd/MM/yyyy"),
                    alignment: "center",
                    fontSize: 10,
                    margin: [0, 3, 0, 0],
                  },
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
        },
      };

      table_single.table.body.push([cabecalho]);

      let detalleDelRecibo = {
        colSpan: 3,
        alignment: "center",
        border: [true, false, true, false],
        margin: [58, 10, 60, 0],
        table: {
          widths: [50, "*", 50],
          body: [
            [
              {
                text: "ID",
                fontSize: 8,
                fillColor: "#EBEEF9",
              },
              {
                text: i18n.tc("global.produto").toUpperCase(),
                fontSize: 8,
                fillColor: "#EBEEF9",
              },
              {
                text: "QTDE",
                fontSize: 8,
                fillColor: "#EBEEF9",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          fillColor: function (rowIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      };

      if (element) {
        element.forEach((item) => {
          detalleDelRecibo.table.body.push([
            {
              text: item.produto_id,
              fontSize: 8,
              alignment: "center",
            },
            {
              text: item.descricao,
              fontSize: 8,
              alignment: "",
            },
            {
              text: item.qtde,
              fontSize: 8,
              alignment: "center",
            },
          ]);
        });
      }
      let corpo = {
        margin: [0, -4, 0, 0],
        table: {
          widths: [150, "*", 150],
          body: [
            [
              {
                border: [true, true, true, false],
                alignment: "center",
                fontSize: 9,
                margin: [0, 10, 0, 0],
                text:
                  i18n.tc("global.comprovante").toUpperCase() +
                  " " +
                  i18n.tc("global.transferencia").toUpperCase(),
                bold: true,
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                border: [true, false, false, false],
                alignment: "center",
                fontSize: 9,
                margin: [0, 10, 0, 0],
                text: "ORIGEM: " + recibo_info.deposito_origem,
              },
              {
                border: [false, false, false, false],
                text: "",
              },
              {
                border: [false, false, true, false],
                alignment: "center",
                fontSize: 9,
                margin: [0, 10, 0, 0],
                text: "DESTINO: " + recibo_info.deposito_destino,
              },
            ],
            [detalleDelRecibo, {}, {}],
            [
              {
                alignment: "start",
                text: "OBS: " + recibo_info.obs,
                border: [true, false, true, false],
                margin: [57, 10, 0, 0],
                fontSize: 9,
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                alignment: "start",
                text: recibo_info.justificativa
                  ? "JUST: " + recibo_info.justificativa
                  : "",
                border: [true, false, true, false],
                margin: [57, 10, 0, 0],
                fontSize: 9,
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              {
                colSpan: 3,
                alignment: "justify",
                margin: [0, 10, 0, 0],
                border: [true, false, true, false],
                columns: [
                  {
                    alignment: "center",
                    text:
                      "________________________________________ \n" +
                      recibo_info.usuario_criou,
                    margin: [0, 0, 0, 0],
                    fontSize: 9,
                  },
                  {
                    alignment: "center",
                    text: recibo_info.usuario_baixou
                      ? "________________________________________ \n" +
                        recibo_info.usuario_baixou
                      : "",
                    margin: [0, 0, 0, 0],
                    fontSize: 9,
                  },
                ],
              },
              {},
              {},
            ],
            [
              {
                fontSize: 8,
                // fillColor: "#F5F5F5",
                margin: [8, 10, 0, 0],
                border: [true, false, true, true],
                alignment: "right",
                text: "",
                bold: true,
                colSpan: 3,
              },
              {},
              {},
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          paddingBottom: function (i, node) {
            if (i === corpo.table.body.length - 1) {
              return 800 - node.positions[node.positions.length - 1].top;
            } else {
              return 0;
            }
          },
        },
      };

      table_single.table.body.push([corpo]);

      geral.push(table_single);
    });

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              // fillColor: "#F5F5F5",
              margin: [12, 5, 2, 2],
              border: [true, true, false, false],
              stack: [
                {
                  image: empresa.logo64 ? empresa.logo64 : "",
                  width: 60,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],

              stack: [
                {
                  text: empresa.nome,
                  fontSize: 12,
                  bold: true,
                },
                {},
                {
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  text:
                    "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  text:
                    "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                },
              ],
              fontSize: 9,
              alignment: "left",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "RUC: " + (empresa.documento ? empresa.documento : ""),

                  alignment: "center",
                  fontSize: 10,
                },
                {
                  text: i18n.tc("global.comprovante").toUpperCase(),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text:
                    "FECHA: " +
                    format(parseISO(recibo_info.created_at), "dd/MM/yyyy"),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    // ---------------------------------------------------------------------
    let detalleDelRecibo = {
      colSpan: 3,
      alignment: "center",
      border: [true, false, true, false],
      margin: [58, 10, 60, 0],
      table: {
        widths: [50, "*", 50],
        body: [
          [
            {
              text: "ID",
              fontSize: 8,
              fillColor: "#EBEEF9",
            },
            {
              text: "PRODUTO",
              fontSize: 8,
              fillColor: "#EBEEF9",
            },
            {
              text: "QNDE",
              fontSize: 8,
              fillColor: "#EBEEF9",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
        fillColor: function (rowIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };
    if (recibo_info.items) {
      recibo_info.items.forEach((item) => {
        detalleDelRecibo.table.body.push([
          {
            text: item.produto_id,
            fontSize: 8,
            alignment: "center",
          },
          {
            text: item.descricao,
            fontSize: 8,
            alignment: "center",
          },
          {
            text: item.qtde,
            fontSize: 8,
            alignment: "center",
          },
        ]);
      });
    }
    let corpo = {
      margin: [0, 0, 0, 0],
      table: {
        widths: [150, "*", 150],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "center",
              fontSize: 9,
              margin: [0, 10, 0, 0],
              text:
                i18n.tc("global.comprovante").toUpperCase() +
                " " +
                i18n.tc("global.transferencia").toUpperCase(),
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, false, false],
              alignment: "center",
              fontSize: 9,
              margin: [0, 10, 0, 0],
              text: "ORIGEM: " + recibo_info.deposito_origem,
            },
            {
              border: [false, false, false, false],
              text: "",
            },
            {
              border: [false, false, true, false],
              alignment: "center",
              fontSize: 9,
              margin: [0, 10, 0, 0],
              text: "DESTINO: " + recibo_info.deposito_destino,
            },
          ],
          [detalleDelRecibo, {}, {}],
          [
            {
              alignment: "start",
              text: "OBS: " + recibo_info.obs,
              border: [true, false, true, false],
              margin: [57, 10, 0, 0],
              fontSize: 9,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              colSpan: 3,
              alignment: "justify",
              margin: [0, 10, 0, 0],
              border: [true, false, true, false],
              columns: [
                {
                  alignment: "center",
                  text:
                    "________________________________________ \n" +
                    recibo_info.usuario_criou,
                  margin: [0, 0, 0, 0],
                  fontSize: 9,
                },
                {
                  alignment: "center",
                  text:
                    "________________________________________ \n" +
                    recibo_info.usuario_baixou,
                  margin: [0, 0, 0, 0],
                  fontSize: 9,
                },
              ],
            },
            {},
            {},
          ],
          [
            {
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [8, 10, 0, 0],
              border: [true, false, true, true],
              alignment: "right",
              text: "ORIGINAL",
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    docDefinition = {
      info: {
        title: "Recibo",
        author: "ADM",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [...geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
