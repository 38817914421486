<template>
  <v-select
    v-model="categoria"
    :items="categorias"
    :loading="loading"
    clearable
    outlined
    dense
    hide-details
    :label="$tc('global.categoria')"
    item-text="descricao"
    item-value="id"
    :disabled="disabled"
    @click:clear="clearCategoria"
    @change="updateCategoria"
  ></v-select>
</template>

<script>
import { getAllProClass } from "@/api/produtos/produtos.js";
import { mapState } from "vuex";

export default {
  name: "Categorias",

  data() {
    return {
      categoria: null,
      disabled: false,
      categorias: [],
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  methods: {
    updateCategoria() {
      if (this.categoria) {
        this.$store.commit("FiltrosRelatorios/UPDATE_CATEGORIA", this.categoria);
      }
    },

    clearCategoria() {
      this.$store.commit("FiltrosRelatorios/UPDATE_CATEGORIA", null);
      this.categoria = null;
    },
  },

  async created() {
    try {
      this.loading = true;
      this.categorias = await getAllProClass();
      this.filtros_componente.find((item) => {
        if (item.text == "Categoria") {
          this.categorias.find((categoria) => {
            if (categoria.id == item.default) {
              this.categoria = categoria;
            }
          });
          if (item.disabled) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
          this.updateCategoria();
        }
      });

      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
