<template>
  <v-dialog
    v-model="dialogVisualizarAlteracoesPrecos"
    @click:outside="$emit('update:dialogVisualizarAlteracoesPrecos', false)"
    @keydown.esc="$emit('update:dialogVisualizarAlteracoesPrecos', false)"
    scrollable
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-icon left>mdi-swap-horizontal</v-icon>
        {{ $tc("global.produtosPrecosAlterados") }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="$emit('update:dialogVisualizarAlteracoesPrecos', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-8 pb-8">
        <v-text-field
          class="py-4"
          v-model="search"
          dense
          outlined
          :placeholder="$tc('global.buscarProdutosPorDescricao')"
          ref="search"
          clearable
          hide-details
          autofocus
        >
        </v-text-field>
        <v-card outlined>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :search="search"
            :items="alteracoes_precos_items"
            :items-per-page="-1"
            hide-default-footer
            fixed-header
            height="calc(100vh - 345px)"
            class="data-tables"
          >
            <!-- preco_anterior  -->
            <template v-slot:item.preco_anterior="{ item }">
              <span v-if="selectedMoeda && selectedMoeda === 'G$'">
                {{ item.preco_anterior | guarani }}
              </span>
              <span v-else>{{ item.preco_anterior | currency }} </span>
            </template>

            <!--preco_editado -->
            <template v-slot:item.preco_editado="{ item }">
              <span v-if="selectedMoeda && selectedMoeda === 'G$'">
                {{ item.preco_editado | guarani }}
              </span>
              <span v-else>{{ item.preco_editado | currency }} </span>
            </template>

            <!-- preco_novoL  -->
            <template v-slot:item.preco_novo="{ item }">
              <span v-if="selectedMoeda && selectedMoeda === 'G$'">
                {{ item.preco_novo | guarani }}
              </span>
              <span v-else>{{ item.preco_novo | currency }} </span>
            </template>

            <!-- preco_finalL -->
            <template v-slot:item.preco_final="{ item }">
              <span v-if="selectedMoeda && selectedMoeda === 'G$'">
                {{ item.preco_final | guarani }}
              </span>
              <span v-else>{{ item.preco_final | currency }} </span>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchProdutosAlteracoesPrecosItems } from "@/api/produtos/produtos_alteracoes_precos.js";
export default {
  name: "DialogVisualizarAlteracoesPrecos",

  components: {},

  props: {
    dialogVisualizarAlteracoesPrecos: {
      type: Boolean,
      default: false,
    },
    alteracaoId: {
      type: [String, Number],
      required: true,
    },
    selectedMoeda: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      search: "",
      alteracoes_precos_items: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.produto"),
          value: "produto_descricao",
          align: "start",
          width: "120px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.precoAtual", 2),
          value: "preco_anterior",
          align: "right",
          width: "80px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.precoEditavel", 2),
          value: "preco_editado",
          align: "right",
          width: "40px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.precoFinal", 2),
          value: "preco_novo",
          align: "right",
          width: "80px",
          divider: true,
          sortable: false,
        },
        {
          text: "% " + this.$tc("global.aumentar"),
          value: "porcentagem_aumentar",
          align: "center",
          width: "80px",
          divider: true,
          sortable: false,
        },
        {
          text: "% " + this.$tc("global.diminuir"),
          value: "porcentagem_diminuir",
          align: "center",
          width: "80px",
          divider: true,
          sortable: false,
        },
      ];
    },
  },

  watch: {},

  methods: {
    getAlteracoesPrecosItems() {
      this.loading = true;
      fetchProdutosAlteracoesPrecosItems(this.alteracaoId).then((response) => {
        this.alteracoes_precos_items = response;
        this.loading = false;
      });
    },
  },

  async mounted() {
    this.getAlteracoesPrecosItems();
  },
};
</script>

<style></style>
