<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.caixa', 2)"
      icon="mdi-cash-register"
      dark
    />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <!--plano de contas -->
        <DialogPlanoContas
          v-if="dialogPlanoContas"
          :dialogPlanoContas.sync="dialogPlanoContas"
        />
        <v-btn
          color="tocs_gray_2"
          class="white--text mt-4 mt-sm-0 mr-3"
          @click="dialogPlanoContas = true"
        >
          <v-icon>mdi-file-tree-outline</v-icon>
          {{ $tc("global.planocontas", 2) }}
        </v-btn>

        <!-- add caixa -->
        <DialogCaixas
          v-if="dialogCaixas"
          :dialogCaixas.sync="dialogCaixas"
          :edit="edit"
          :item="caixa"
          @fetch-caixas="getCaixas"
        />

        <v-btn
          v-if="PCaixaAdicionar"
          @click="addCaixa"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="caixas"
          :items-per-page="10"
          :sort-by="['id']"
          class="data-tables"
          :class="{ 'data-tables__row-click': PCaixaVisualizar }"
          hide-default-footer
          @click:row="editCaixa"
        >
          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 0">inativo</span>
            <span v-if="item.status === 1"> ativo </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchCaixas } from "@/api/caixas/caixas.js";
import { mapGetters } from "vuex";
export default {
  name: "Caixas",

  components: {
    DialogCaixas: () => import("./components/DialogCaixas.vue"),
    DialogPlanoContas: () => import("./components/DialogPlanoContas.vue"),
  },

  data() {
    return {
      dialogPlanoContas: false,
      caixa: {},
      dialogCaixas: false,
      loading: false,
      caixas: [],
      caixa_original: {},
      search: "",
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PCaixaVisualizar() {
      return this.getAccess("Caixas", "visualizar");
    },
    PCaixaEditar() {
      return this.getAccess("Caixas", "editar");
    },
    PCaixaAdicionar() {
      return this.getAccess("Caixas", "adicionar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.tipo") + " " + this.$tc("global.caixa"),
          value: "tipo_caixa",
        },
        {
          text: this.$tc("global.moeda"),
          value: "sigla",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
      ];
    },
  },

  methods: {
    addCaixa() {
      this.dialogCaixas = true;
      this.edit = false;
    },

    editCaixa(item) {
      if (this.PCaixaEditar) {
        this.caixa = { ...item };
        this.dialogCaixas = true;
        this.edit = true;
      }
    },

    getCaixas() {
      this.loading = true;

      fetchCaixas()
        .then((response) => {
          this.caixas = response;
          this.caixa_original = { ...response };
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.getCaixas();
  },
};
</script>

<style></style>
