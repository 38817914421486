import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { listaDeVendasPrazoRecebidas } from "@/api/relatorios/relatorio_vendas.js";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import { format, parseISO } from "date-fns";

export async function generatePdf() {

    let result = {
        error: false,
        message: null,
        pdfData: null,
    };

    const bodyPDF = await layoutEmpresa();
    const filtro_get_extrato = bodyPDF.queryString;
    const vendas_info = await listaDeVendasPrazoRecebidas(filtro_get_extrato);
    const cabecalho = bodyPDF.cabecalho;
    const periodo = bodyPDF.periodo;



    return new Promise((resolve, reject) => {
        if (vendas_info.length == 0) {
            result.menssage = "Nenhuma venda encontrada";
        }

        let cabecalho_data = {
            margin: [0, 5, 0, 2],
            table: {
                widths: ["*"],
                body: [
                    [
                        {
                            text:
                                "VENDAS - PRAZO - RECEBIDAS " + periodo,
                            fillColor: "#E0E0E0",
                            border: [false, false, false, false],
                            bold: true,
                            alignment: "center",
                            // margin: [0, 4, 0, 2],
                            fontSize: 10,
                        },
                    ],
                ],
            },
        };

        let table_vendas = {
            margin: [0, 0, 0, 5],
            table: {
                widths: [20, "*", "*", "*", "*", "*", "*"],
                body: [
                    [
                        {
                            text: "COD",
                            border: [true, true, false, true],
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 9,
                        },

                        {
                            text: "DATA",
                            border: [false, true, false, true],
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 9,
                        },

                        {
                            text: "CLIENTE",
                            border: [false, true, false, true],
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 9,
                        },

                        {
                            text: "MOEDA",
                            border: [false, true, false, true],
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 9,
                        },

                        {
                            text: "VALOR",
                            border: [false, true, false, true],
                            bold: true,
                            alignment: "right",
                            margin: [0, 4, 0, 2],
                            fontSize: 9,
                        },
                        {
                            text: "DESCONTO",
                            border: [false, true, false, true],
                            bold: true,
                            alignment: "right",
                            margin: [0, 4, 0, 2],
                            fontSize: 9,
                        },
                        {
                            text: "TOTAL",
                            border: [false, true, true, true],
                            bold: true,
                            alignment: "right",
                            margin: [0, 4, 0, 2],
                            fontSize: 9,
                        },
                    ],
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i <= 1 || i === node.table.body.length ? 1 : 1;
                },
                hLineColor: function (i, node) {
                    return i === 0 || i === node.table.body.length
                        ? "#666666"
                        : "#666666";
                },
                vLineColor: function (i, node) {
                    return i === 0 || i === node.table.widths.length
                        ? "#666666"
                        : "#666666";
                },
                fillColor: function (rowIndex) {
                    return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
                },
            },
        };

        const row_font_size = 8;
        vendas_info.forEach((vendas, index) => {
            let table_row = [
                {
                    text: vendas.numero,
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 4, 0, 5],
                    border: [
                        true,
                        false,
                        false,
                        index != vendas_info.length - 1 ? false : true,
                    ],
                },

                {
                    text: format(parseISO(vendas.data), "dd/MM/yyyy"),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 4, 0, 5],
                    border: [
                        false,
                        false,
                        false,
                        index != vendas_info.length - 1 ? false : true,
                    ],
                },

                {
                    text: Vue.filter("excerpt")(vendas.cliente_nome, 15),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 4, 0, 5],
                    border: [
                        false,
                        false,
                        false,
                        index != vendas_info.length - 1 ? false : true,
                    ],
                },
                {
                    text: vendas.moeda_sigla,
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 4, 0, 5],
                    border: [
                        false,
                        false,
                        false,
                        index != vendas_info.length - 1 ? false : true,
                    ],
                },

                {
                    text: (
                        vendas.moeda_sigla == "G$"
                            ? Vue.filter("guarani")(vendas.valor)
                            : Vue.filter("currency")(vendas.valor)
                    ),
                    fontSize: row_font_size,
                    alignment: "right",
                    margin: [0, 4, 0, 5],
                    border: [
                        false,
                        false,
                        false,
                        index != vendas_info.length - 1 ? false : true,
                    ],
                },

                {
                    text: (
                        vendas.moeda_sigla == "G$"
                            ? Vue.filter("guarani")(vendas.desconto)
                            : Vue.filter("currency")(vendas.desconto)
                    ),
                    fontSize: row_font_size,
                    alignment: "right",
                    margin: [0, 4, 0, 5],
                    border: [
                        false,
                        false,
                        false,
                        index != vendas_info.length - 1 ? false : true,
                    ],
                },

                {
                    text: (
                        vendas.moeda_sigla == "G$"
                            ? Vue.filter("guarani")(vendas.total)
                            : Vue.filter("currency")(vendas.total)
                    ),
                    fontSize: row_font_size,
                    alignment: "right",
                    margin: [0, 4, 0, 5],
                    border: [
                        false,
                        false,
                        true,
                        index != vendas_info.length - 1 ? false : true,
                    ],
                },
            ];

            table_vendas.table.body.push(table_row);
        });

        let content = [cabecalho,
             cabecalho_data, 
             table_vendas
        ];

        let docDefinition = {
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: "portrait", //"portrait"
            info: {
                title: "Document",
            },
            defaultStyle: {
                font: "Roboto",
                columnGap: 20,
                lineHeight: 1.1,
            },
            content,
            // footer(currentPage, pageCount) {
            //   return [
            //     {
            //       columns: [
            //         {
            //           text: `Impresso por ${nome} as ${now}`,
            //           fontSize: 7,
            //           margin: [25, 0, 0, 0],
            //         },
            //         {
            //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
            //           margin: [0, 0, 25, 0],
            //           alignment: "right",
            //           fontSize: 7,
            //         },
            //       ],
            //     },
            //   ];
            // },
            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                },
                subheader: {
                    fontSize: 10,
                },
            },
        };

        pdfMake.fonts = {
            Roboto: {
                normal: "Roboto-Regular.ttf",
                bold: "Roboto-Medium.ttf",
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        // pdfDocGenerator.open();

        pdfDocGenerator.getDataUrl((data) => {
            result.pdfData = data;
            resolve(result);
        });
    });
}
