<template>
  <v-dialog
    v-model="dialogAbrirCaixa"
    @click:outside="$emit('update:dialogAbrirCaixa', false)"
    @keydown.esc="$emit('update:dialogAbrirCaixa', false)"
    width="350px"
  >
    <v-card :loading="loading">
      <v-card-title class="">
        {{ $tc("global.abrir") + " " + $tc("global.caixa") }}
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogAbrirCaixa', false)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-select
          v-model="selectedCaixa"
          :items="caixas"
          item-text="descricao"
          item-value="id"
          class="py-4"
          outlined
          dense
          hide-details
          :disabled="caixaSemValor"
          :label="$tc('global.caixaOrigem')"
        ></v-select>
        <v-select
          v-model="selectedTimbrado"
          :items="timbrados"
          item-text="timbrado"
          item-value="timbrado_id"
          class="py-4"
          outlined
          dense
          hide-details
          :label="$tc('global.timbrado')"
        >
          <template v-slot:selection="{ item }">
            <span>
              {{
                `${item.descricao ? item.descricao : ""} - ${item.timbrado} `
              }}
            </span>
          </template>
          <template v-slot:item="{ item }">
            <span>
              {{
                `${item.descricao ? item.descricao : ""} - ${item.timbrado} `
              }}
            </span>
          </template>
        </v-select>

        <v-text-field
          v-model="valorCaixaDolar"
          :label="$tc('global.valor') + ' ' + 'U$'"
          ref="valorCaixaDolar"
          outlined
          hide-details
          :disabled="caixaSemValor"
          dense
          class="input-right pb-4"
          v-currency="vCurrencyOptions"
        ></v-text-field>
        <v-text-field
          v-model="valorCaixaGuarani"
          :label="$tc('global.valor') + ' ' + 'G$'"
          ref="valorCaixaGuarani"
          outlined
          hide-details
          :disabled="caixaSemValor"
          dense
          class="input-right pb-4"
          v-currency="vCurrencyOptionsGuarani"
        ></v-text-field>
        <v-text-field
          v-model="valorCaixaReal"
          :label="$tc('global.valor') + ' ' + 'R$'"
          ref="valorCaixaReal"
          outlined
          hide-details
          dense
          :disabled="caixaSemValor"
          class="input-right pb-4"
          v-currency="vCurrencyOptions"
        ></v-text-field>

        <v-card class="d-flex justify-space-between align-center" flat outlined>
          <span class="tocs_gray_3--text ml-4">Abrir caixa sem valor</span>
          <v-switch
            :false-value="false"
            :true-value="true"
            v-model="caixaSemValor"
          ></v-switch>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_1"
          class="white--text"
          @click="abrirCaixa"
          :disabled="validateValorCaixa ? true : false"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.abrir") + " " + $tc("global.caixa") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchCaixasGeral } from "@/api/caixas/caixas.js";
import { postAbrirCaixa } from "@/api/caixas/caixas_af.js";
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";

export default {
  name: "DialogAbrirCaixa",
  props: {
    dialogAbrirCaixa: {
      type: Boolean,
      default: false,
    },
    caixa_id: {
      type: Number,
    },
  },

  data() {
    return {
      loading: true,
      valorCaixaDolar: 0,
      valorCaixaGuarani: 0,
      valorCaixaReal: 0,
      caixa_origem_id: null,
      caixas: [],
      selectedCaixa: null,
      caixaSemValor: false,
      timbrados: [],
      selectedTimbrado: null,
      timbrado_caixa_id: null,
    };
  },

  computed: {
    validateValorCaixa() {
      let result = false;
      let valor_guarani = this.$ci.parse(
        this.valorCaixaGuarani,
        this.vCurrencyOptionsGuarani
      );
      let valor_dolar = this.$ci.parse(
        this.valorCaixaDolar,
        this.vCurrencyOptions
      );
      let valor_real = this.$ci.parse(
        this.valorCaixaReal,
        this.vCurrencyOptions
      );

      if (
        valor_real < 0 ||
        valor_dolar < 0 ||
        valor_guarani < 0 ||
        !this.caixa_origem_id
      ) {
        result = true;
      }

      if (this.caixaSemValor) {
        result = false;
      }

      if (!this.selectedTimbrado) {
        result = true;
      }

      return result;
    },

    vCurrencyOptionsGuarani() {
      return {
        precision: 0,
        distractionFree: false,
      };
    },
    vCurrencyOptions() {
      return {
        precision: undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    selectedCaixa() {
      if (this.selectedCaixa) {
        this.caixa_origem_id = this.selectedCaixa;
      }
    },
    caixaSemValor() {
      if (this.caixaSemValor) {
        this.caixa_origem_id = null;
        this.selectedCaixa = null;
        this.valorCaixaDolar = 0;
        this.valorCaixaGuarani = 0;
        this.valorCaixaReal = 0;
      }
    },

    selectedTimbrado() {
      if (this.selectedTimbrado) {
        this.timbrado_caixa_id = this.selectedTimbrado;
      }
    },
  },

  methods: {
    abrirCaixa() {
      let abrir_caixa = {};

      abrir_caixa.timbrado_caixa_id = this.timbrado_caixa_id;
      abrir_caixa.caixa_id = this.caixa_id;
      if (!this.caixaSemValor) {
        abrir_caixa.valorCaixaDolar = this.$ci.parse(
          this.valorCaixaDolar,
          this.vCurrencyOptions
        );
        abrir_caixa.valorCaixaGuarani = this.$ci.parse(
          this.valorCaixaGuarani,
          this.vCurrencyOptionsGuarani
        );
        abrir_caixa.valorCaixaReal = this.$ci.parse(
          this.valorCaixaReal,
          this.vCurrencyOptions
        );

        abrir_caixa.caixa_origem_id = this.caixa_origem_id;
      } else {
        abrir_caixa.caixaSemValor = this.caixaSemValor;
      }

      postAbrirCaixa(abrir_caixa)
        .then((response) => {
          if (response.status == 200) {
            this.$emit("update:dialogAbrirCaixa", false);
            this.$router.push({ path: `/caixas-pdv/${this.caixa_id}` });
          }
        })
        .catch(() => {});
    },

    getCaixasOrigem() {
      this.loading = true;
      fetchCaixasGeral()
        .then((response) => {
          this.caixas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getTimbrados() {
      this.loading = true;

      fetchUserTimbrados()
        .then((response) => {
          this.timbrados = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getCaixasOrigem();
    this.getTimbrados();
  },
};
</script>

<style lang="scss" scoped>
.input-right ::v-deep input {
  text-align: right;
}
</style>
