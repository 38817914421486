var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000px","scrollable":""},on:{"click:outside":function($event){return _vm.$emit('update:DialogAddFatura', false)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('update:DialogAddFatura', false)}},model:{value:(_vm.DialogAddFatura),callback:function ($$v) {_vm.DialogAddFatura=$$v},expression:"DialogAddFatura"}},[_c('v-card',{attrs:{"tile":"","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',{staticClass:"subtitle-1 tocs_gray_1 px-4 py-3"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" "+_vm._s(_vm.$tc("global.fatura"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('update:DialogAddFatura', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-5"},[_c('v-select',{attrs:{"items":_vm.timbrados,"item-text":"timbrado","item-value":"timbrado_id","clearable":"","outlined":"","dense":"","hide-details":"","label":_vm.$tc('global.timbrado')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(((item.descricao ? item.descricao : "") + " - " + (item.timbrado) + " "))+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(((item.descricao ? item.descricao : "") + " - " + (item.timbrado) + " "))+" ")])]}}]),model:{value:(_vm.timbrado_id),callback:function ($$v) {_vm.timbrado_id=$$v},expression:"timbrado_id"}}),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-body-1 px-3 py-2 tocs_gray_1"},[_vm._v(" "+_vm._s(_vm.$tc("global.selecionar"))+" "+_vm._s(_vm.$tc("global.venda"))+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.vendas,"items-per-page":10,"item-key":"id","show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])]}}]),model:{value:(_vm.vendasSelected),callback:function ($$v) {_vm.vendasSelected=$$v},expression:"vendasSelected"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'mr-12',attrs:{"color":"button_2","disabled":_vm.disableAdd},on:{"click":function($event){$event.preventDefault();return _vm.novaFatura.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }