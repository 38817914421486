<template>
  <v-card class="ma-2" max-height="350px">
    <v-card-title class="tabs2">
      <span class="font-weight-bold primary--text">
        <v-icon color="primary">mdi-numeric-1-box-multiple-outline</v-icon>
        Parcelas
      </span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="parcelas_headers"
        :items="parcelas"
        :items-per-page="5"
        disable-sort
        fixed-header
        class="data-tables"
      >
        <template v-slot:item.data_vencimento="{ item, index }">
          <div class="my-2" v-if="isEditing && isEditingIndex === index">
            <DataField :data_sync.sync="dataEditing" :data_min="data_hoje">
            </DataField>
          </div>
          <span v-else>
            {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
          </span>
        </template>

        <template v-slot:item.valor="{ item }">
          {{ compra.moeda_sigla }}
          <span v-if="compra.moeda_sigla === 'G$'">
            {{ item.valor | guarani }}
          </span>
          <span v-else>
            {{ item.valor | currency }}
          </span>
        </template>

        <template v-slot:item.actions="{ item, index }">
          <span v-if="isEditing && isEditingIndex === index">
            <v-btn @click="saveEditing()" small icon>
              <v-icon color="success">mdi-content-save</v-icon>
            </v-btn>
          </span>
          <span v-else>
            <v-btn @click="editarVencimento(item, index)" small icon
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { format, parseISO, addMonths } from "date-fns";
export default {
  name: "TabelaParcelasCompras",

  props: {
    dialogParcelarCompras: {
      type: Boolean,
      default: false,
    },
    compra: {
      type: Object,
    },
    data_vencimento: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      parcelas: [],
      isEditing: false,
      isEditingIndex: null,
      dataEditing: null,
      data_hoje: format(new Date(), "yyyy-MM-dd"),
    };
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  computed: {
    parcelas_headers() {
      let headers = [
        {
          text: this.$tc("global.data-vencimento"),
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          align: "end",
        },
        {
          text: "",
          width: "90px",
          align: "center",
          value: "actions",
        },
      ];
      return headers;
    },
  },

  methods: {
    gerarParcelas() {
      this.loading = true;
      let valor_parcela = null;

      let data = this.data_vencimento;

      valor_parcela = Number(this.compra.total) / this.compra.numero_parcelas;

      for (let i = 1; i <= this.compra.numero_parcelas; i++) {
        let parcela = {};

        parcela.data_vencimento = format(parseISO(data), "yyyy-MM-dd");
        parcela.valor = valor_parcela;

        this.parcelas.push(parcela);

        data = format(addMonths(parseISO(data), 1), "yyyy-MM-dd");
      }
      this.$emit("update:parcelas_compra", this.parcelas);
      this.loading = false;
    },

    editarVencimento(item, index) {
      this.isEditing = true;
      this.isEditingIndex = index;
      this.dataEditing = item.data_vencimento;
    },
    saveEditing() {
      if (
        this.dataEditing !== null ||
        this.dataEditing !== undefined ||
        this.dataEditing !== ""
      ) {
        this.parcelas[this.isEditingIndex].data_vencimento = this.dataEditing;
      }

      this.isEditing = false;
      this.isEditingIndex = null;
      this.dataEditing = null;
      this.$emit("update:parcelas_compra", this.parcelas);
    },
  },

  mounted() {
    if (this.compra) {
      this.gerarParcelas();
    }
  },
};
</script>

<style></style>
