import api from "../axios_service.js";

export async function getOrcamentos(filtros) {
  const response = await api.get(`/orcamentos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function postOrcamento(body) {
  return api.post("/orcamentos/add", body);
}

export async function putOrcamento(id, venda) {
  return api.put(`/orcamentos/${id}`, venda);
}

export async function postFinalizarOrcamento(id, venda) {
  return api.put(`/orcamentos-finalizar/${id}`, venda);
}

export async function orcamentoPdfSimples(id) {
  const response = await api.get(`/orcamento-pdf-simples/${id}`);
  return response.data.result;
}

// export function finalizaVenda(id, venda) {
//   return api.post(`/finaliza-venda/${id}`, venda);
// }

// export async function cancelVenda(id, venda) {
//   return api.put(`/cancel-venda/${id}`, venda);
// }
