import api from "../axios_service.js";

export async function fetchSaldoDepositos(filtros) {
  const response = await api.get(
    `/produto-saldo-depositos${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchSaldoDeposito(filtros) {
  const response = await api.get(
    `/produto-saldo-deposito${filtros ? filtros : ""}`
  );
  return response.data.result;
}
