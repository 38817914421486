import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { getFechamentoCaixaAf } from "@/api/caixas/caixas_af.js";
import { i18n } from "../../../../plugins/i18n";
import store from "@/store/modules/usuario.store.js";

export async function generateFechamento(caixa_af_id, valores) {
  const empresa = await fetchEmpresa(store.state.empresa.id);

  const caixa_af = await getFechamentoCaixaAf(caixa_af_id);

  const caixa_af_fechado = caixa_af.result;
  const caixa_af_cobros = caixa_af.cobros;

  const total_vendas = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE VENDAS";
  });
  const total_cobros_efetivo = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS EFETIVO";
  });
  const total_gastos = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE GASTOS";
  });
  const total_cobros_credito = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS CREDITO";
  });
  const total_cobros_debito = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS DEBITO";
  });
  const total_cobros_cheque = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS CHEQUE";
  });

  return new Promise((resolve, reject) => {
    let docDefinition = {};
    let fontItem = 12;
    let geral = [];

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*"],
        body: [
          [
            {
              fillColor: "#F5F5F5",
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              columns: [
                {
                  stack: [
                    {
                      image: empresa.logo64 ? empresa.logo64 : "",
                      width: 80,
                      height: 80,
                    },
                  ],

                  width: 90,
                },
                {
                  stack: [
                    {
                      text: empresa.nome,
                      // color: "#009cde",
                      fontSize: 12,
                      bold: true,
                    },
                    {
                      text:
                        "Telefone: " +
                        (empresa.telefone ? empresa.telefone : ""),
                      // color: "#009cde",
                    },
                    {
                      text:
                        "Localização: " +
                        (empresa.localizacao ? empresa.localizacao : ""),
                      // color: "#009cde",
                    },
                  ],
                  fontSize: 10,
                  alignment: "left",
                  margin: [0, 17, 0, 0],
                },
              ],
            },
          ],
        ],
      },
    };

    let corpo = {
      margin: [0, 10, 0, 0],
      table: {
        widths: ["*", "*", "*", "*", "*"],
        body: [
          [
            {
              fillColor: "#EBEEF9",
              fontSize: 10,
              alignment: "center",
              margin: [0, 10, 0, 10],
              text:
                i18n.tc("global.operador") + ": " + caixa_af_fechado.user_nome,
            },
            {
              fillColor: "#EBEEF9",
              fontSize: 10,
              alignment: "center",
              margin: [0, 10, 0, 10],
              text:
                i18n.tc("global.abertura") +
                ": " +
                format(
                  parseISO(caixa_af_fechado.created_at),
                  "dd/MM/yyyy HH:mm"
                ),
            },
            {
              fillColor: "#EBEEF9",
              fontSize: 10,
              alignment: "center",
              margin: [0, 10, 0, 10],
              text:
                i18n.tc("global.fechamento") +
                ": " +
                format(
                  parseISO(caixa_af_fechado.updated_at),
                  "dd/MM/yyyy HH:mm"
                ),
            },
            {
              fillColor: "#EBEEF9",
              fontSize: 10,
              alignment: "center",
              margin: [0, 10, 0, 10],
              text:
                i18n.tc("global.origem") + ": " + caixa_af_fechado.origem_caixa,
            },
            {
              fillColor: "#EBEEF9",
              fontSize: 10,
              alignment: "center",
              margin: [0, 10, 0, 10],
              text:
                i18n.tc("global.destino") +
                ": " +
                caixa_af_fechado.destino_caixa,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let corpo_2 = {
      margin: [0, 10, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: i18n.tc("global.moeda").toUpperCase(),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: i18n.tc("global.saldo").toUpperCase(),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text:
                i18n.tc("global.saldo").toUpperCase() +
                " " +
                i18n.tc("global.final").toUpperCase(),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: i18n.tc("global.acerto").toUpperCase(),
            },
          ],

          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              text: "G$",
              fillColor: "#EBEEF9",
              bold: true,
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(valores.saldo_guarani),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(caixa_af_fechado.saldo_final_gs),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(caixa_af_fechado.acerto_gs),
            },
          ],

          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              text: "U$",
              fillColor: "#EBEEF9",
              bold: true,
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("currency")(valores.saldo_dolar),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("currency")(caixa_af_fechado.saldo_final_us),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("currency")(caixa_af_fechado.acerto_us),
            },
          ],

          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              text: "R$",
              fillColor: "#EBEEF9",
              bold: true,
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("currency")(valores.saldo_real),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("currency")(caixa_af_fechado.saldo_final_rs),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("currency")(caixa_af_fechado.acerto_rs),
            },
          ],

          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              text: i18n.tc("global.credito"),
              fillColor: "#EBEEF9",
              bold: true,
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(valores.saldo_credito),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(
                caixa_af_fechado.saldo_final_cartao_credito
              ),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(caixa_af_fechado.acerto_credito),
            },
          ],

          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              text: i18n.tc("global.debito"),
              fillColor: "#EBEEF9",
              bold: true,
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(valores.saldo_debito),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(
                caixa_af_fechado.saldo_final_cartao_debito
              ),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "right",
              text: Vue.filter("guarani")(caixa_af_fechado.acerto_debito),
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let assinatura = {
      margin: [0, 80, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              border: [false, false, false, false],
              margin: [40, 40, 40, 40],
              fontSize: 10,
              alignment: "center",
              text: `-----------------------------------------------------------------------------------\n${i18n.tc(
                "global.caixeiro"
              )}   `,
              bold: true,
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              margin: [40, 40, 40, 40],
              fontSize: 10,
              alignment: "center",
              text: `-----------------------------------------------------------------------------------\n${i18n.tc(
                "global.tesouraria"
              )}   `,
              bold: true,
              colSpan: 2,
            },
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let break_page = {
      text: "",
      pageBreak: "before",
    };

    let corpo_totais = {
      margin: [0, 10, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              fontSize: 10,
              margin: [10, 10, 10, 10],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text:
                i18n.tc("global.total").toUpperCase() +
                " " +
                i18n.tc("global.transacoes").toUpperCase(),
              colSpan: 4,
            },
            {},
            {},
            {},
          ],
          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: i18n.tc("global.tipo").toUpperCase(),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "U$",
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "G$",
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "R$",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let total_dolar = 0;
    let total_real = 0;
    let total_guarani = 0;

    if (total_cobros_efetivo) {
      total_dolar += Number(total_cobros_efetivo.dolar);
      total_real += Number(total_cobros_efetivo.real);
      total_guarani += Number(total_cobros_efetivo.guarani);
      let body_total_cobros_efetivo = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_efetivo.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_efetivo.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_efetivo.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_efetivo.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_efetivo);
    }

    if (total_cobros_credito) {
      total_dolar += Number(total_cobros_credito.dolar);
      total_real += Number(total_cobros_credito.real);
      total_guarani += Number(total_cobros_credito.guarani);
      let body_total_cobros_credito = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_credito.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_credito.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_credito.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_credito.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_credito);
    }

    if (total_cobros_debito) {
      total_dolar += Number(total_cobros_debito.dolar);
      total_real += Number(total_cobros_debito.real);
      total_guarani += Number(total_cobros_debito.guarani);
      let body_total_cobros_debito = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_debito.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_debito.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_debito.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_debito.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_debito);
    }
    if (total_cobros_cheque) {
      total_dolar += Number(total_cobros_cheque.dolar);
      total_real += Number(total_cobros_cheque.real);
      total_guarani += Number(total_cobros_cheque.guarani);
      let body_total_cobros_cheque = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_cheque.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_cheque.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_cheque.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_cheque.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_cheque);
    }
    if (total_vendas) {
      let body_total_vendas = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_vendas.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",

          text: Vue.filter("currency")(total_vendas.dolar) + " *",
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",

          text: Vue.filter("guarani")(total_vendas.guarani) + " *",
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",

          text: Vue.filter("currency")(total_vendas.real) + " *",
        },
      ];
      corpo_totais.table.body.push(body_total_vendas);
    }
    if (total_gastos) {
      let body_total_gastos = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_gastos.tipo,
          fillColor: "#f59f9f",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          fillColor: "#f59f9f",
          text: Vue.filter("currency")(total_gastos.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          fillColor: "#f59f9f",
          text: Vue.filter("guarani")(total_gastos.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          fillColor: "#f59f9f",
          text: Vue.filter("currency")(total_gastos.real),
        },
      ];
      corpo_totais.table.body.push(body_total_gastos);
    }

    let body_totais = [
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
        text: "TOTAIS",
        fillColor: "#EEEEEE",
        bold: true,
      },
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
        fillColor: "#EEEEEE",
        text: Vue.filter("currency")(total_dolar),
      },
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
        fillColor: "#EEEEEE",
        text: Vue.filter("guarani")(total_guarani),
      },
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
        fillColor: "#EEEEEE",
        text: Vue.filter("currency")(total_real),
      },
    ];
    corpo_totais.table.body.push(body_totais);

    let tabela = [
      cabecalho,
      corpo,
      corpo_2,
      assinatura,
      break_page,
      corpo_totais,
    ];
    geral.push(tabela);

    docDefinition = {
      info: {
        title: "FECHAMENTO DE CAIXA",
        author: "ADM",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "landscape", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [...geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
