<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.usuario', 2)"
      icon="mdi-account-circle"
      dark
    />

    <ListaUsuarios />
  </v-container>
  <!-- <NaoAutorizado v-else /> -->
</template>

<script>
export default {
  name: "Usuarios",
  components: {
    ListaUsuarios: () => import("./components/ListaUsuarios.vue"),
  },
  data: () => ({}),
  computed: {},
  mounted() {},
  created() {},
};
</script>

<style></style>
