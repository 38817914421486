import api from "../axios_service.js";

export async function fetchMarcas(filtros) {
  const response = await api.get(`/marcas${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchMarca(id) {
  const response = await api.get(`/marcas/${id}`);
  return response.data.result;
}

export function putMarca(id, marca) {
  return api.post(`/marcas/${id}`, marca);
}

export function postMarca(marca) {
  return api.post("/marcas/add", marca);
}
