import { login, profile } from "@/api/auth.js";
import { setEmpresaAtiva } from "@/api/usuarios/usuarios.js";
import Vue from "vue";

const initialState = () => ({
  auto_redirect: false,
  usuario: {
    avatar: "",
    name: "",
  },
  empresa: null,
  modulos: null,
});

const state = initialState();

const getters = {
};

const actions = {
  async getUsuario({ dispatch, commit }) {
    const response = await profile();

    commit("UPDATE_USUARIO", response.result);
    let empresa = Vue.ls.get("empresa");

    if (empresa) {
      let find_empresa = response.result.empresas.find(
        (item) => item.id == empresa
      );
      if (find_empresa) {
        await dispatch("setarEmpresa", find_empresa);
        await dispatch("updateEmpresaAtiva", find_empresa);
      }
    }


  },
  logarUsuario({ commit }, payload) {
    return login({
      email: payload.email,
      password: payload.senha,
    })
      .then((response) => {
        Vue.ls.set("token", `Bearer ${response.token}`);
        commit("UPDATE_USUARIO", response.user);
      })
      .catch((error) => {
        throw error;
      });
  },
  setarEmpresa({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_EMPRESA", payload);
      Vue.ls.set("empresa", payload.id);

      resolve();
    });
  },
  updateEmpresaAtiva({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let body = {};
      body.empresa_ativa = payload.id;
      setEmpresaAtiva(state.usuario.id, body).then((response) => {
        if (state.usuario.empresa_ativa != payload.id) {
          dispatch("getUsuario");
        }
        commit("UPDATE_MODULOS", response);
        resolve();
      });
    });
  },
  deslogarUsuario(context) {
    context.commit("RESET");
    context.dispatch('Componentes/reset', null, { root: true });
    Vue.ls.remove("current_route");
    Vue.ls.remove("token");
    Vue.ls.remove("empresa");
  },
  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_AUTO_REDIRECT(state, payload) {
    state.auto_redirect = payload;
  },
  UPDATE_USUARIO(state, payload) {
    state.usuario = Object.assign(state.usuario, payload);
  },
  UPDATE_EMPRESA(state, payload) {
    state.empresa = payload;
  },
  UPDATE_MODULOS(state, payload) {
    state.modulos = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
