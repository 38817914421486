<template>
  <div>
    <v-data-table
      :headers="produtos_headers"
      :items="produtos"
      :items-per-page="-1"
      disable-sort
      fixed-header
      show-expand
      :single-expand="true"
      :item-class="row_classes"
      hide-default-footer
      :key="i"
    >
      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          v-if="item.kit"
          icon
          small
          @click.stop="expand(isExpanded ? false : true)"
        >
          <v-icon>
            {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="12" class="px-0">
          <v-data-table
            :headers="headers"
            :items="item.produtos_kit"
            :loading="loading"
            :items-per-page="-1"
            disable-sort
            :key="i"
            hide-default-footer
            :item-class="row_classes_kit"
            class="data-tables mb-8 mt-2 mx-6"
          >
            <!-- Ediçao produto kit venda -->
            <template v-slot:item.qtde="{ item, index }">
              <template
                v-if="isProdutoEditing && index === indexProdutoEditingKit"
              >
                <div class="d-flex align-center justify-center">
                  <v-text-field
                    v-model.number="produtoEditing.qtde"
                    :placeholder="$tc('global.qtd')"
                    outlined
                    dense
                    class="input-low-height input-center"
                    hide-details
                    append-outer-icon="mdi-plus"
                    @click:append-outer="produtoEditing.qtde++"
                    prepend-icon="mdi-minus"
                    @click:prepend="
                      produtoEditing.qtde > 1 ? produtoEditing.qtde-- : ''
                    "
                  ></v-text-field>
                </div>
              </template>
              <template v-else>
                {{ item.qtde }}
              </template>
            </template>

            <template v-slot:item.preco="{ item, index }">
              <div
                v-show="isProdutoEditing && index === indexProdutoEditingKit"
              >
                <div class="d-flex align-center justify-center">
                  <v-text-field
                    v-model="produtoEditingPreco"
                    :placeholder="$tc('global.preco')"
                    hide-details
                    outlined
                    dense
                    class="input-low-height"
                    ref="produtoEditingPreco"
                    :error="invalidPrecoItemEdit"
                    :append-icon="
                      invalidPrecoItemEdit ? 'mdi-lock-alert-outline' : ''
                    "
                    @click:append="openDialogAuth(true)"
                    v-currency="vCurrencyOptions"
                    @keydown.enter.prevent="UpdateProduto"
                  ></v-text-field>
                </div>
              </div>
              <div
                v-show="!isProdutoEditing && index != indexProdutoEditingKit"
              >
                <span v-if="moeda_sigla === 'G$'">
                  {{ item.preco | guarani }}
                </span>
                <span v-else>{{ item.preco | currency }} </span>
              </div>
            </template>

            <template v-slot:item.total="{ item, index }">
              <template
                v-if="isProdutoEditing && index === indexProdutoEditingKit"
              >
                <span v-if="moeda_sigla === 'G$'">
                  {{ (produtoEditing.qtde * produtoEditing.preco) | guarani }}
                </span>
                <span v-else
                  >{{ (produtoEditing.qtde * produtoEditing.preco) | currency }}
                </span>
              </template>
              <template v-else>
                <span v-if="moeda_sigla === 'G$'">
                  {{ (item.qtde * item.preco) | guarani }}
                </span>
                <span v-else>{{ (item.qtde * item.preco) | currency }} </span>
              </template>
            </template>

            <!-- <template v-slot:item.actions="{ item, index }">
              <template
                v-if="isProdutoEditing && index === indexProdutoEditingKit"
              >
                <v-btn
                  color="green lighten-1"
                  icon
                  small
                  @click.stop="UpdateProdutoKit(item)"
                >
                  <v-icon size="20">mdi-content-save</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  icon
                  small
                  :disabled="editar ? !PVendaEditar : false"
                  @click.stop="StartEditProdutoKit(item, index)"
                >
                  <v-icon size="20">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  color="red lighten-2"
                  icon
                  small
                  :disabled="editar ? !PVendaEditar : false"
                  @click.stop="DeleteProdutoKit(index, item)"
                >
                  <v-icon size="20">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </template> -->
          </v-data-table>
        </td>
      </template>

      <template v-slot:item.qtde="{ item, index }">
        <template
          v-if="isProdutoEditing && index === indexProdutoEditing && !item.kit"
        >
          <div class="d-flex align-center justify-center">
            <v-text-field
              v-model.number="produtoEditing.qtde"
              :placeholder="$tc('global.qtd')"
              outlined
              dense
              class="input-low-height input-center"
              hide-details
              append-outer-icon="mdi-plus"
              @click:append-outer="produtoEditing.qtde++"
              prepend-icon="mdi-minus"
              @click:prepend="
                produtoEditing.qtde > 1 ? produtoEditing.qtde-- : ''
              "
            ></v-text-field>
          </div>
        </template>
        <template v-else>
          {{ item.qtde }}
        </template>
      </template>

      <template v-slot:item.fornecedor="{ item, index }">
        <div class="d-flex">
          <ServicosOrdemServico
            v-if="
              ordem_servico_status === 1 ||
              (editingFornecedor && index === indexFornecedorEditing)
            "
            :selectedFornecedor.sync="selectedFornecedor"
            @atualizarFornecedor="atualizaFornecedor(index)"
          />
          <v-btn
            v-if="editingFornecedor && index === indexFornecedorEditing"
            icon
            @click="closeEditarFornecedor"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          v-if="
            ordem_servico_status !== 1 &&
            (!editingFornecedor || index !== indexFornecedorEditing)
          "
        >
          {{ item.fornecedor_nome }}
          <v-btn
            v-if="ordem_servico_status === 2"
            icon
            @click="editarFornecedor(index)"
          >
            <v-icon size="16">{{
              item.fornecedor_id ? "mdi-pencil-outline" : "mdi-plus"
            }}</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.preco_servico="{ item, index }">
        <div v-show="editingPrecoServico && index === indexPrecoServico">
          <div class="d-flex align-center">
            <v-text-field
              v-model="produtoServicoPreco"
              :placeholder="$tc('global.preco') + ' ' + $tc('global.servico')"
              hide-details
              outlined
              dense
              class="input-low-height input-right py-2"
              ref="produtoServicoPreco"
              :prefix="moeda_sigla"
              v-currency="vCurrencyOptions"
              @keydown.enter.prevent="UpdateServico"
            ></v-text-field>
            <v-btn icon small @click="updatePrecoServico">
              <v-icon size="16">mdi-content-save</v-icon>
            </v-btn>
          </div>
        </div>

        <div v-show="!editingPrecoServico || indexPrecoServico != index">
          <span v-if="moeda_sigla === 'G$'">
            {{ item.preco_servico | guarani }}
          </span>
          <span v-else>{{ item.preco_servico | currency }} </span>
          <v-btn
            v-if="
              ordem_servico_status !== 3 &&
              ordem_servico_status !== 4 &&
              item.fornecedor_id
            "
            icon
            small
            @click="editarPrecoServico(item, index)"
          >
            <v-icon size="16">mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.preco="{ item, index }">
        <div
          v-show="
            isProdutoEditing && index === indexProdutoEditing && !item.kit
          "
        >
          <div class="d-flex align-center justify-center">
            <v-text-field
              v-model="produtoEditingPreco"
              :placeholder="$tc('global.preco')"
              hide-details
              outlined
              dense
              class="input-low-height"
              ref="produtoEditingPreco"
              :error="invalidPrecoItemEdit"
              :append-icon="
                invalidPrecoItemEdit ? 'mdi-lock-alert-outline' : ''
              "
              @click:append="openDialogAuth(true)"
              v-currency="vCurrencyOptions"
              @keydown.enter.prevent="UpdateProduto"
            ></v-text-field>
          </div>
        </div>
        <div v-show="!isProdutoEditing && index != indexProdutoEditing">
          <span v-if="moeda_sigla === 'G$'">
            {{ item.preco | guarani }}
          </span>
          <span v-else>{{ item.preco | currency }} </span>
        </div>
      </template>

      <template v-slot:item.subtotal="{ item, index }">
        <template v-if="isProdutoEditing && index === indexProdutoEditing">
          <span v-if="moeda_sigla === 'G$'">
            {{ (produtoEditing.qtde * produtoEditing.preco) | guarani }}
          </span>
          <span v-else
            >{{ (produtoEditing.qtde * produtoEditing.preco) | currency }}
          </span>
        </template>
        <template v-else>
          <span v-if="moeda_sigla === 'G$'">
            {{ (item.qtde * item.preco) | guarani }}
          </span>
          <span v-else>{{ (item.qtde * item.preco) | currency }} </span>
        </template>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <div v-if="!item.venda_item_id">
          <template
            v-if="
              isProdutoEditing && index === indexProdutoEditing && !item.kit
            "
          >
            <v-btn
              color="green lighten-1"
              icon
              small
              @click.stop="UpdateProduto"
            >
              <v-icon size="20">mdi-content-save</v-icon>
            </v-btn>
          </template>
          <template v-else-if="!item.kit">
            <v-btn
              icon
              small
              :disabled="editar ? !PVendaEditar : false"
              @click.stop="StartEditVendaItem(item, index)"
            >
              <v-icon size="20">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
              color="red lighten-2"
              icon
              small
              :disabled="editar ? !PVendaEditar : false"
              @click.stop="DeleteProduto(index, item)"
            >
              <v-icon size="20">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </div>
      </template>
    </v-data-table>
    <div
      class="d-flex py-6"
      v-if="ordem_servico_status == 0 || ordem_servico_status == 1"
    >
      <!-- Busca Produto -->
      <SearchProdutos
        :produto_sync.sync="selectedProduto"
        :lista_preco_id="1"
        :deposito_id="deposito_id"
        :moeda_id="2"
        tipo="simples"
        @clear-add-item="ClearAddProduto"
        ref="buscaProduto"
        typeComp="venda"
        style="max-width: 45%"
      />

      <!-- Quantidade -->
      <v-text-field
        v-model.number="produto_add.qtde"
        :placeholder="$tc('global.qtd')"
        hide-details
        dense
        solo
        flat
        outlined
        class="mx-3 input-center"
        style="max-width: 15%"
        append-outer-icon="mdi-plus"
        @click:append-outer="produto_add.qtde++"
        prepend-icon="mdi-minus"
        @click:prepend="produto_add.qtde > 1 ? produto_add.qtde-- : ''"
      ></v-text-field>
      <!-- Preço -->
      <v-text-field
        v-model="produtoPreco"
        :placeholder="$tc('global.preco')"
        hide-details
        dense
        solo
        flat
        outlined
        class="mr-3"
        style="max-width: 15%"
        :append-icon="invalidPrecoItem ? 'mdi-lock-alert-outline' : ''"
        @click:append="openDialogAuth(false)"
        :error-messages="erroPrecoItem"
        @keydown.enter.prevent="validaValorProduto"
        ref="produtoPreco"
        v-currency="vCurrencyOptions"
      >
        <template v-slot:append-item>
          <v-btn text block class="primary--text">
            <v-icon left>mdi-plus</v-icon>
            {{ $tc("global.add") + " Pais" }}
          </v-btn>
        </template>
      </v-text-field>

      <div
        v-if="selectedProduto && estoque_disponivel != null && !loadingEstoque"
      >
        <div v-if="estoque_disponivel > 0">
          <div class="text-caption">
            {{ $tc("global.saldo") }} {{ $tc("global.disponivel") }}
          </div>

          <div class="text-caption">
            {{ estoque_disponivel }}
          </div>
        </div>

        <div v-if="estoque_disponivel <= 0">
          <div class="text-caption red--text">
            {{ $tc("global.produto") }} sem
          </div>
          <div class="text-caption red--text">
            {{ $tc("global.saldo") }} {{ $tc("global.disponivel") }}
          </div>
        </div>
      </div>

      <div v-else-if="loadingEstoque">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>

      <v-btn
        depressed
        color="secondary"
        height="38px"
        absolute
        right
        :disabled="(editar ? !PVendaEditar : '') || disableAddProduto"
        :loading="loadingAddProduto"
        @click="validaValorProduto"
      >
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </div>
    <DialogAuth
      v-if="dialogAuth"
      :dialogAuth.sync="dialogAuth"
      :authTabela="authTabela"
      @add-produto="AddProduto"
      @edit-produto="UpdateVendaItemAuth"
    />
  </div>
</template>

<script>
import {
  postEstoqueMinimo,
  getOneProdutoPiscina,
} from "@/api/produtos/produtos.js";
export default {
  name: "ProdutosOrdemServico",

  props: {
    produtos: {
      type: Array,
      default: () => {},
    },
    ordem_servico_status: {
      type: [Number, String],
      default: null,
    },
    ordem_servico_id: {
      type: [Number, String],
      default: null,
    },
    editar: {
      type: Boolean,
      default: false,
    },
    moeda_sigla: {
      type: String,
    },
    deposito_id: {
      type: [Number, String],
      default: null,
    },
    lista_preco_id: {
      type: [Number, String],
      default: null,
    },
    moeda_id: {
      type: [Number, String],
      default: null,
    },
  },

  components: {
    SearchProdutos: () => import("@/components/fields/SearchProdutos.vue"),
    DialogAuth: () =>
      import("@/views/dashboard/vendas/components/DialogAuth.vue"),
    ServicosOrdemServico: () => import("./ServicosOrdemServico.vue"),
  },

  data() {
    return {
      loading: false,
      isProdutoEditing: false,
      indexProdutoEditingKit: null,
      indexProdutoEditing: null,
      produtoEditingPreco: null,
      produtoServicoPreco: null,
      produtoEditing: null,
      invalidPrecoItemEdit: false,
      loadingEstoque: false,
      produto_add: {
        qtde: 1,
      },
      selectedProduto: null,
      produtoPreco: null,
      invalidPrecoItem: false,
      erroPrecoItem: "",
      loadingAddProduto: false,
      estoque_disponivel: null,
      dialogAuth: false,
      authTabela: false,
      i: 0,
      editingPrecoServico: false,
      indexPrecoServico: null,
      servicoEditing: null,
      selectedFornecedor: null,
      fornecedores_servicos_os: [],
      fornecedor_id_atualiza: null,
      loading_fornecedores: false,
      editingFornecedor: false,
      indexFornecedorEditing: null,
    };
  },

  computed: {
    produtos_headers() {
      let headers = [
        {
          text: this.$tc("global.produto"),
          divider: true,
          value: "descricao",
        },
        {
          text: "Qtde",
          divider: true,
          width: "150px",
          value: "qtde",
          align: "center",
        },
        {
          text: this.$tc("global.preco"),
          divider: true,
          width: "150px",
          value: "preco",
          align: "end",
        },
        {
          text: this.$tc("global.prestadorServico"),
          divider: true,
          align: "center",
          value: "fornecedor",
        },
        {
          text: this.$tc("global.preco") + " " + this.$tc("global.servico"),
          divider: true,
          width: "250px",
          value: "preco_servico",
          align: "end",
        },
        {
          text: this.$tc("global.preco") + " " + this.$tc("global.produto"),
          divider: true,
          width: "150px",
          value: "preco",
          align: "end",
        },

        {
          text: "SubTotal",
          divider: true,
          width: "150px",
          value: "subtotal",
          align: "end",
        },
      ];
      if (!this.desabilitarEdicao) {
        let actions = {
          text: "",
          width: "90px",
          align: "center",
          value: "actions",
        };

        headers.push(actions);
      }

      if (this.hasDevolucao) {
        let devolucao = {
          text: "Qtde " + this.$tc("global.devolucao"),
          width: "100px",
          align: "center",
          divider: true,
          value: "qtde_devolucao",
        };

        headers.splice(3, 0, devolucao);
      }

      return headers;
    },

    headers() {
      let headers = [
        {
          text: this.$tc("global.id"),
          width: "80px",
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
        },
        {
          text: this.$tc("global.disponivel"),
          value: "estoque_disponivel",
        },
        {
          text: this.$tc("global.preco"),
          value: "preco",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
      ];

      if (!this.desabilitarEdicao) {
        let actions = {
          text: "",
          width: "90px",
          align: "center",
          value: "actions",
        };

        headers.push(actions);
      }

      return headers;
    },

    desabilitarEdicao() {
      if (
        this.ordem_servico_status == 2 ||
        this.ordem_servico_status == -1 ||
        this.ordem_servico_status == 3 ||
        this.ordem_servico_status == 5
      ) {
        return true;
      } else {
        return false;
      }
    },

    vCurrencyOptions() {
      return {
        precision: this.moeda_sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    disableAddProduto() {
      let result = false;

      if (this.selectedProduto == null) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    produtoEditingPreco() {
      if (this.isProdutoEditing) {
        this.produtoEditing.preco = this.$ci.parse(
          this.produtoEditingPreco,
          this.vCurrencyOptions
        );
      }
    },

    produtoServicoPreco() {
      if (this.editingPrecoServico) {
        this.servicoEditing.preco_servico = this.$ci.parse(
          this.produtoServicoPreco,
          this.vCurrencyOptions
        );
      }
    },

    selectedProduto() {
      if (this.selectedProduto) {
        this.getProduto();
      }
    },

    produtoPreco() {
      this.produto_add.preco = this.$ci.parse(
        this.produtoPreco,
        this.vCurrencyOptions
      );
    },
  },

  methods: {
    row_classes(linha) {
      if (
        this.produtos.filter(
          (produto) => produto.produto_id === linha.produto_id
        ).length > 1
      ) {
        return "tocs_red--text";
      }
    },

    row_classes_kit(linha) {
      if (linha.qtde > linha.estoque_disponivel && !linha.controle_estoque) {
        return "tocs_red--text";
      }
    },

    setProdutosKit() {
      let items = [];
      this.produtos.forEach((produto) => {
        if (produto.tipo == "ITEM") {
          produto.produtos_kit = [];
          items.push(produto);
        }
      });
      this.produtos.forEach((produto) => {
        if (produto.tipo == "SUB-ITEM") {
          let index = items.findIndex((produto_array) => {
            return produto_array.id === produto.produto_pai_id;
          });
          produto.index_produto = index;

          items[index].produtos_kit.push(produto);
        }
      });

      items.forEach((produto) => {
        if (produto.produtos_kit.length) {
          produto.kit = true;
        }
      });

      this.$emit("update:produtos", items);
    },

    StartEditProdutoKit(item, index) {
      this.isProdutoEditing = true;
      this.produtoEditing = { ...item };
      this.preco_minimo = item.preco;
      this.indexProdutoEditingKit = index;
      this.$ci.setValue(this.$refs.produtoEditingPreco, item.preco);
    },

    StartEditVendaItem(item, index) {
      this.isProdutoEditing = true;
      this.produtoEditing = { ...item };
      this.indexProdutoEditing = index;
      this.controleEstoque(this.produtoEditing.produto_id);
      this.preco_minimo = item.preco;
      this.$ci.setValue(this.$refs.produtoEditingPreco, item.preco);
    },

    editarPrecoServico(item, index) {
      this.editingPrecoServico = true;
      this.servicoEditing = { ...item };
      this.indexPrecoServico = index;
      this.$ci.setValue(this.$refs.produtoServicoPreco, item.preco_servico);
    },

    updatePrecoServico() {
      this.produtos[this.indexPrecoServico].preco_servico =
        this.servicoEditing.preco_servico;
      this.$emit("atualizarTotalServico");
      this.editingPrecoServico = false;
      this.servicoEditing = null;
      this.indexPrecoServico = null;
    },

    async controleEstoque(item, qtde) {
      this.loadingEstoque = true;

      const produtoEstoque = {
        produtoId: item,
        depositoId: this.deposito_id,
      };

      const response = await postEstoqueMinimo(produtoEstoque);
      this.estoque_disponivel = response.data.result;
      this.loadingEstoque = false;
    },

    UpdateProduto() {
      let limite_estoque = this.limiteEstoqueAdd(
        this.produtos[this.indexProdutoEditing].produto_id,
        this.produtoEditing.qtde,
        this.produtos[this.indexProdutoEditing].controle_estoque
      );

      let preco_minimo = this.$ci.parse(
        this.produtos[this.indexProdutoEditing].preco_minimo,
        this.vCurrencyOptions
      );

      let produto_preco = (this.produtos[this.indexProdutoEditing].preco =
        this.produtoEditing.preco);

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItemEdit = true;
      } else {
        if (
          produto_preco < 0 ||
          produto_preco < preco_minimo ||
          isNaN(produto_preco)
        ) {
          this.$toast.error("Valor do produto menor que o minimo do atacado");
          this.invalidPrecoItemEdit = true;
        } else {
          if (!limite_estoque) {
            this.produtos[this.indexProdutoEditing].preco =
              this.produtoEditing.preco;
            this.produtos[this.indexProdutoEditing].qtde =
              this.produtoEditing.qtde;

            this.isProdutoEditing = false;
            this.produtoEditing = null;
            this.indexProdutoEditing = null;
          } else {
            this.$toast.error("Quantidade de items indisponivel no estoque!");
          }
        }
      }
    },

    UpdateProdutoKit(item) {
      let produto_preco = (this.produtos[item.index_produto].produtos_kit[
        this.indexProdutoEditingKit
      ].preco = this.produtoEditing.preco);

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItemEdit = true;
      } else {
        if (produto_preco < 0 || isNaN(produto_preco)) {
          this.$toast.error("Valor do produto menor que o minimo do atacado");
          this.invalidPrecoItemEdit = true;
        } else {
          this.produtos[item.index_produto].produtos_kit[
            this.indexProdutoEditingKit
          ].preco = this.produtoEditing.preco;
          this.produtos[item.index_produto].produtos_kit[
            this.indexProdutoEditingKit
          ].qtde = this.produtoEditing.qtde;
          this.isProdutoEditing = false;
          this.produtoEditing = null;
          this.indexProdutoEditingKit = null;
          this.calcularTotalProdutoKit(item.index_produto);
        }
      }
    },

    limiteEstoqueAdd(id, qtde, controle_estoque) {
      if (controle_estoque == 0) {
        return false;
      } else {
        let totalProdutoItems = 0;

        let itemsSelecionado = this.produtos.filter(
          (item) => item.produto_id === id
        );

        const reducer = (accumulator, currentValue) => {
          return accumulator + Number(currentValue.qtde);
        };

        totalProdutoItems = itemsSelecionado.reduce(reducer, 0);

        if (this.isProdutoEditing) {
          let verificaLimite =
            this.estoque_disponivel -
            (totalProdutoItems - this.produtos[this.indexProdutoEditing].qtde) -
            qtde;

          verificaLimite =
            verificaLimite +
            Number(this.produtos[this.indexProdutoEditing].qtde);

          return verificaLimite < 0 ? true : false;
        } else {
          let verificaLimite = this.estoque_disponivel - qtde;

          return verificaLimite < 0 ? true : false;
        }
      }
    },

    ClearAddProduto() {
      this.selectedProduto = null;
      this.produto_add.produto_id = null;
      this.produto_add.descricao = null;
      this.produto_add.qtde = 1;
      this.produto_add.preco = null;
      this.produto_add.cif = null;
      this.produto_add.img = null;
      this.produto_add.kit = false;
      this.produto_add.produtos_kit = null;

      this.$ci.setValue(this.$refs.produtoPreco, null);
    },

    validaValorProduto() {
      let produto_preco = this.$ci.parse(
        this.produtoPreco,
        this.vCurrencyOptions
      );

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItem = true;
      } else {
        if (
          produto_preco < 0 ||
          produto_preco < this.produto_add.preco_minimo ||
          isNaN(produto_preco)
        ) {
          this.$toast.error("Valor do produto menor que o minimo do atacado");
          this.erroPrecoItem = "Valor do produto menor que o minimo do atacado";

          this.invalidPrecoItem = true;
        } else {
          this.erroPrecoItem = "";
          this.invalidDesconto = false;
          this.AddProduto();
        }
      }
    },
    openDialogAuth(authTabela) {
      this.authTabela = authTabela;
      this.dialogAuth = true;
    },

    async getProduto() {
      let filtro = `?lista_preco_id=${this.lista_preco_id}`;
      filtro += `&moeda_id=${this.moeda_id}`;
      filtro += `&deposito_id=${this.deposito_id}`;

      const produto = await getOneProdutoPiscina(
        this.selectedProduto.id,
        filtro
      );

      if (!produto) {
        this.$toast.warning(
          "Produto sem preço registrado nesta lista e com essa moeda!"
        );
        this.ClearAddProduto();
        return;
      }

      this.$ci.setValue(this.$refs.produtoPreco, Number(produto.preco));
      if (produto.produto_kit) {
        this.produto_add.kit = true;
      } else {
        this.produto_add.kit = false;
      }

      this.produto_add.cif = Number(produto.cif);
      this.produto_add.produto_id = produto.id;
      this.produto_add.descricao = produto.descricao;
      this.produto_add.preco_minimo = produto.preco_minimo;
      this.produto_add.controle_estoque = produto.controle_estoque;
      this.produto_add.fornecedor_id = null;
      this.produto_add.preco_servico = null;
      if (produto.img) {
        this.produto_add.img = produto.img;
      }
      if (produto.controle_estoque == 1) {
        await this.controleEstoque(produto.id, this.produto_add.qtde);
      } else {
        this.estoque_disponivel = null;
      }
      if (produto.produto_kit) {
        this.produto_add.produtos_kit = produto.produtos_kit;
      }

      this.$refs.produtoPreco.focus();
    },

    calculaValorItensKit(qtde, produtos) {
      produtos.forEach((produto) => {
        produto.qtde = Number(produto.qtde) * Number(qtde);
        produto.total = Number(produto.preco) * Number(qtde);
      });

      this.produto_add.produtos_kit = produtos;
    },

    calcularTotalProdutoKit(index_produto) {
      let valor_total = 0;
      this.produtos[index_produto].produtos_kit.forEach((produto) => {
        valor_total += produto.preco * produto.qtde;
      });
      this.produtos[index_produto].preco = valor_total;
    },

    async AddProduto() {
      this.loadingAddProduto = true;

      let limite_estoque = this.limiteEstoqueAdd(
        this.produto_add.produto_id,
        this.produto_add.qtde,
        this.produto_add.controle_estoque
      );

      if (this.produto_add.qtde >= 0 && this.produtoPreco && !limite_estoque) {
        if (this.produto_add.kit) {
          await this.calculaValorItensKit(
            this.produto_add.qtde,
            this.produto_add.produtos_kit
          );
        }
        let produto_add = { ...this.produto_add };
        this.produtos.push(produto_add);
        let ultimo_elemento_array = this.produtos.length - 1;

        if (produto_add.kit) {
          this.produtos[ultimo_elemento_array].produtos_kit.map((produto) => {
            produto.index_produto = ultimo_elemento_array;
          });
        }

        this.$nextTick().then(() => {
          this.ClearAddProduto();
          this.$refs.buscaProduto.$refs.buscaProduto.focus();
        });

        this.invalidPrecoItem = false;
        this.invalidPrecoItemEdit = false;
        this.invalidDesconto = false;
        this.erroPrecoItem = "";
        this.loadingAddProduto = false;
      }
      if (!this.produtoPreco) {
        this.$toast.error(
          "Favor inserir preço antes de adicionar um item na lista!"
        );
        this.loadingAddProduto = false;
      }
      if (!this.produto_add.qtde || this.produto_add.qtde <= 0) {
        this.$toast.error("Quantidade minima para adicionar o item é 1!");
        this.produto_add.qtde = 1;
        this.loadingAddProduto = false;
      }
      if (limite_estoque) {
        this.$toast.error("Quantidade de items indisponivel no estoque!");
        this.loadingAddProduto = false;
      }
    },

    DeleteProduto(index) {
      this.produtos.splice(index, 1);
    },

    DeleteProdutoKit(index, item) {
      this.i++;
      this.produtos[item.index_produto].produtos_kit.splice(index, 1);
      this.calcularTotalProdutoKit(item.index_produto);
      if (!this.produtos[item.index_produto].produtos_kit.length) {
        this.produtos.splice(item.index_produto, 1);
      }
    },

    atualizaFornecedor(index) {
      if (this.selectedFornecedor && !this.loading_fornecedores) {
        this.produtos[index].fornecedor_id = this.selectedFornecedor.id;
        this.produtos[index].preco_servico = 0;
        if (this.selectedFornecedor.servicos.length) {
          this.selectedFornecedor.servicos.forEach((servico) => {
            if (servico.produto_id == this.produtos[index].produto_id) {
              return (this.produtos[index].preco_servico = servico.valor);
            }
          });
        }
        this.$emit("atualizarTotalServico");
      }
    },

    editarFornecedor(index) {
      this.editingFornecedor = true;
      this.indexFornecedorEditing = index;
    },
    closeEditarFornecedor(index) {
      this.editingFornecedor = false;
      this.indexFornecedorEditing = null;
    },
  },

  mounted() {
    if (this.produtos && this.produtos.length) {
      this.setProdutosKit();
    }
  },
};
</script>

<style lang="scss" scoped>
.input-right ::v-deep input {
  text-align: right;
}
::v-deep .input-low-height {
  display: flex;
  align-items: center;
  justify-content: center;

  & .v-input__control {
    justify-content: center;
  }

  & .v-input__slot {
    height: 28px;
    min-height: 28px !important;
  }
}
</style>
