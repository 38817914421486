import api from "../axios_service.js";

export async function fetchBancos(filtros) {
  const response = await api.get(`/bancos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchBanco(id) {
  const response = await api.get(`/banco/${id}`);
  return response.data.result;
}

export function putBanco(id, banco) {
  return api.put(`/bancos/${id}`, banco);
}

export function postBanco(marca) {
  return api.post("/bancos/add", marca);
}

