<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.cliente', 2)"
      icon="mdi-clipboard-account-outline"
      dark
    />

    <ListaClientes />
  </v-container>
</template>

<script>
export default {
  name: "Clientes",
  components: {
    ListaClientes: () => import("./components/ListaClientes.vue"),
  },
  data: () => ({}),
  computed: {},
  methods: {},
  mounted() {},
  created() {},
};
</script>

<style></style>
