<template>
  <v-dialog
    v-model="dialogPaises"
    max-width="700px"
    @click:outside="$emit('update:dialogPaises', false)"
    @keydown.esc="$emit('update:dialogPaises', false)"
    scrollable
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{$tc('global.estado', 2)}}
        <v-spacer></v-spacer>

        <v-btn icon @click="$emit('update:dialogPaises', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <div class="d-flex align-center justify-space-between mb-6">
          <v-combobox
            :label="$tc('global.pais',2)"
            clearable
            :items="paises"
            item-text="nome"
            item-value="id"
            dense
            outlined
            solo
            flat
            hide-details
            v-model="selectedPais"
            style="max-width: 300px"
          >
            <template v-slot:append-item>
              <v-btn
                small
                @click="dialogAddPais = true"
                text
                block
                class="primary--text"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $tc("global.add")}} {{$tc('global.pais')}}
              </v-btn>
            </template>
          </v-combobox>

          <v-btn class="white--text" color="button_1" @click="addEstado()">
            <v-icon left>mdi-plus</v-icon>
             {{$tc('global.estado')}}
          </v-btn>
        </div>

        <v-data-table
          :items-per-page="8"
          :headers="headers"
          :items="estados"
          class="data-tables"
        >
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="editEstado(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <DialogEstados
        v-if="dialogEstado"
        :dialogEstado.sync="dialogEstado"
        :edit.sync="edit"
        :item="estado"
        :pais="pais"
        @fetch-estados="getEstados(selectedPais.id)"
      />
      <DialogAddPais
        v-if="dialogAddPais"
        :dialogAddPais.sync="dialogAddPais"
        @fetch-paises="getPaises"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchAllPaises } from "@/api/cidades/paises.js";
import { fetchAllEstados, fetchEstadoPorPais } from "@/api/cidades/estados.js";

export default {
  name: "DialogPaises",

  components: {
    DialogAddPais: () => import("./DialogAddPais.vue"),
    DialogEstados: () => import("./DialogEstados.vue"),
  },

  props: {
    dialogPaises: {
      type: Boolean,
      required: true,
    },
  },

  data: () => {
    return {
      loading: false,
      dialogEstado: false,
      dialogAddPais: false,
      edit: false,
      paises: [],
      pais: [],
      estados: [],
      selectedPais: null,
      estado: {},
    };
  },

  watch: {
    selectedPais() {
      if (!this.selectedPais) return;

      this.getEstados(this.selectedPais.id);
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Estado",
          value: "nome",
        },
        {
          text: "Sigla",
          value: "sigla",
        },
        {
          text: "",
          value: "action",
        },
      ];
    },
  },

  methods: {
    async getPaises() {
      try {
        this.loading = true;
        const response = await fetchAllPaises();
        this.paises = response;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getEstados(pais_id) {
      try {
        this.loading = true;
        const response = await fetchEstadoPorPais(pais_id);
        this.estados = response;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    addEstado() {
      this.dialogEstado = true;
      this.edit = false;
      this.pais.push(this.selectedPais);
    },

    editEstado(item) {
      this.dialogEstado = true;
      this.edit = true;
      this.estado = item;
      this.pais.push(this.selectedPais);
    },
  },

  async mounted() {
    this.loading = true;
    await this.getPaises();
    this.selectedPais = this.paises[0];
    this.loading = false;
  },
};
</script>

<style></style>
