import api from "../axios_service.js";

export async function all() {
  const response = await api.get("/cambios");
  return response.data.result;
}

export async function cambioDia() {
  const response = await api.get("/cambios/dia");

  return response.data.result;
}

export async function one(id) {
  const response = await api.get(`/cambios/${id}`);
  return response.data.result;
}
export function update(id, body) {
  return api.put(`/cambios/${id}`, body);
}
export function add(body) {
  return api.post("/cambios", body);
}

export async function del(id) {
  return api.delete(`/cambios/${id}`);
}
