<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.fornecedor', 2)"
      icon="mdi-truck-outline"
      dark
    />
    <ListaFornecedores />
  </v-container>
</template>

<script>
export default {
  name: "Fornecedores",
  components: {
    ListaFornecedores: () => import("./components/ListaFornecedores.vue"),
  },
};
</script>

<style lang="scss" scoped></style>
