import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { i18n } from "@/plugins/i18n.js";
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { fetchSaldoCaixa } from "@/api/relatorios/relatorio_caixas.js";
import store from "@/store/index.js";

export async function generatePdf() {
    let result = {
        error: false,
        message: null,
        pdfData: null,
    };

    const bodyPDF = await layoutEmpresa();

    const filtro_get_extrato = bodyPDF.queryString;
    const caixa_info = await fetchSaldoCaixa(filtro_get_extrato);
    const caixas_movement = caixa_info.result;
    const saldo_caixa_anterior = caixa_info.saldo_anterior_caixa;
    const caixa_descricao = caixa_info.caixa;
    const moeda_selecionada = caixa_info.moeda_id;
    const periodo = bodyPDF.periodo;
    const cabecalho = bodyPDF.cabecalho;

    return new Promise((resolve, reject) => {


        let content = [cabecalho];

        let moedas = store.state.Moedas.moedas;
        let moeda = moedas.find(moeda => moeda.id_moeda == moeda_selecionada);

        let cabecalho_saldo_caixa = {
            margin: [0, 0, 0, 5],
            table: {
                widths: ["*"],
                body: [
                    [
                        {
                            text:
                                caixa_descricao.descricao + ' - ' + moeda.sigla + " - SALDO - " + periodo,
                            fillColor: "#E0E0E0",
                            border: [false, false, false, false],
                            bold: true,
                            alignment: "center",
                            // margin: [0, 4, 0, 2],
                            fontSize: 10,
                        },
                    ],
                ],
            },
        };
        content.push(cabecalho_saldo_caixa);



        let row_font_size = 6;
        if (caixas_movement.length > 0) {

            let saldo = 0;
            if (caixas_movement[0].moeda_sigla === "G$") {
                saldo = saldo_caixa_anterior.saldo_guarani ? Number(saldo_caixa_anterior.saldo_guarani) : 0;
            } else if (caixas_movement[0].moeda_sigla === "U$") {
                saldo = saldo_caixa_anterior.saldo_dolar ? Number(saldo_caixa_anterior.saldo_dolar) : 0;
            } else if (caixas_movement[0].moeda_sigla === "R$") {
                saldo = saldo_caixa_anterior.saldo_real ? Number(saldo_caixa_anterior.saldo_real) : 0;
            }
            let saldo_final = 0;

            let saldo_caixa_anterior_formatado = saldo;

            let corpo_caixa = {
                table: {
                    headerRows: 2,
                    widths: ["auto", "auto", "auto", "*", "auto", "auto", "auto"],
                    body: [

                        [
                            {
                                fontSize: 8,
                                alignment: "center",
                                fillColor: "#EBEEF9",
                                bold: true,
                                text: i18n.tc("global.data").toUpperCase(),
                            },
                            {
                                fontSize: 8,
                                alignment: "center",
                                fillColor: "#EBEEF9",
                                bold: true,
                                text: i18n.tc("global.documento").toUpperCase(),
                            },
                            {
                                fontSize: 8,
                                alignment: "center",
                                fillColor: "#EBEEF9",
                                bold: true,
                                text: "OPERACIÓN",
                            },
                            {
                                fontSize: 8,
                                alignment: "center",
                                fillColor: "#EBEEF9",
                                bold: true,
                                text: i18n.tc("global.historico").toUpperCase(),
                            },

                            {
                                fontSize: 8,
                                alignment: "center",
                                fillColor: "#EBEEF9",
                                bold: true,
                                text: i18n.tc("global.entrada").toUpperCase(),
                            },
                            {
                                fontSize: 8,
                                alignment: "center",
                                fillColor: "#EBEEF9",
                                bold: true,
                                text: i18n.tc("global.saida").toUpperCase(),
                            },
                            {
                                fontSize: 8,
                                alignment: "center",
                                fillColor: "#EBEEF9",
                                bold: true,
                                text: i18n.tc("global.saldo").toUpperCase(),
                            },

                        ],
                        [
                            {
                                text: "SALDO ANTERIOR",
                                fontSize: 7,
                                alignment: "right",
                                bold: true,
                                fillColor: "#EBEEF9",
                                // margin: [0, 4, 0, 5],
                                border: [true, true, false, false],
                                colSpan: 6,
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {
                                text: caixas_movement[0].moeda_sigla === "G$"
                                    ? Vue.filter("guarani")(saldo_caixa_anterior_formatado)
                                    : Vue.filter("currency")(saldo_caixa_anterior_formatado),
                                fontSize: 7,
                                alignment: "right",
                                // margin: [0, 4, 0, 5],
                                border: [true, true, true, false],
                            }
                        ],

                    ],
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
                    },
                    hLineColor: function (i) {
                        return i === 1 ? "#666666" : "#666666";
                    },
                    vLineColor: function (i) {
                        return i === 1 ? "#666666" : "#666666";
                    },
                },
            };



            caixas_movement.forEach((caixa, index) => {
                let valor_caixa_entrada = Number(caixa.entrada);
                let valor_caixa_saida = Number(caixa.saida);

                if (valor_caixa_entrada > 0) {
                    if (index === 0) {
                        saldo_final = valor_caixa_entrada + saldo;
                    } else {
                        saldo_final = valor_caixa_entrada + saldo_final;
                    }
                } else if (valor_caixa_saida > 0) {
                    if (index === 0) {
                        saldo_final = saldo - valor_caixa_saida;
                    } else {
                        saldo_final = saldo_final - valor_caixa_saida;
                    }
                }

                let table_row = [
                    {
                        text: format(parseISO(caixa.data), "dd/MM/yyyy"),
                        fontSize: row_font_size,
                        alignment: "center",
                        // margin: [0, 4, 0, 5],
                        // border: [false, false, false, true],
                    },
                    {
                        text: caixa.documento,
                        fontSize: row_font_size,
                        alignment: "left",
                        // margin: [0, 4, 0, 5],
                        // border: [false, false, false, true],
                    },
                    {
                        text: caixa.plano_de_contas,
                        fontSize: row_font_size,
                        alignment: "left",
                        // margin: [0, 4, 0, 5],
                        // border: [false, false, true, true],
                    },
                    {
                        text: caixa.historico,
                        fontSize: row_font_size,
                        alignment: "left",
                        // margin: [0, 4, 0, 5],
                        // border: [false, false, true, true],
                    },
                    {

                        text: caixa.moeda_sigla == "G$"
                            ? Vue.filter("guarani")(caixa.entrada)
                            : Vue.filter("currency")(caixa.entrada),
                        color: "#1B5E20",
                        fontSize: row_font_size,
                        alignment: "right",
                        bold: true,
                        // margin: [0, 4, 0, 5],
                        // border: [false, false, true, true],
                    },
                    {
                        text: caixa.moeda_sigla == "G$"
                            ? Vue.filter("guarani")(caixa.saida)
                            : Vue.filter("currency")(caixa.saida),
                        color: "#B71C1C",
                        fontSize: row_font_size,
                        alignment: "right",
                        bold: true,
                        // margin: [0, 4, 0, 5],
                        // border: [false, false, true, true],
                    },
                    {
                        text: caixa.moeda_sigla == "G$"
                            ? Vue.filter("guarani")(saldo_final)
                            : Vue.filter("currency")(saldo_final),
                        fontSize: row_font_size,
                        alignment: "right",
                        bold: true,
                        // margin: [0, 4, 0, 5],
                        // border: [false, false, true, true],
                    },
                ];

                corpo_caixa.table.body.push(table_row);
            });

            content.push(corpo_caixa);
        }



        let now = format(new Date(), "dd/MM/yyyy - HH:mm:ss");
        let nome = store.state.Usuario.usuario.name;
        let docDefinition = {
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: "portrait", //"portrait"
            info: {
                title: "Document",
            },
            defaultStyle: {
                font: "Roboto",
                columnGap: 20,
                lineHeight: 1.1,
            },
            content,

            footer(currentPage, pageCount) {
                return [
                    {
                        columns: [
                            {
                                text: `Impresso por ${nome} as ${now}`,
                                fontSize: 7,
                                margin: [25, 0, 0, 0],
                            },
                            {
                                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                                margin: [0, 0, 25, 0],
                                alignment: "right",
                                fontSize: 7,
                            },
                        ],
                    },
                ];
            },
            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                },
                subheader: {
                    fontSize: 10,
                },
            },
        };

        pdfMake.fonts = {
            Roboto: {
                normal: "Roboto-Regular.ttf",
                bold: "Roboto-Medium.ttf",
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        // pdfDocGenerator.open();

        pdfDocGenerator.getDataUrl((data) => {
            result.pdfData = data;
            resolve(result);
        });
    });
}