<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.permissao', 2)"
      icon="mdi-account-lock"
      dark
    />

    <ListaPermissoes />
  </v-container>
</template>

<script>
export default {
  name: "Permissoes",

  components: {
    ListaPermissoes: () => import("./components/ListaPermissoes.vue"),
  },

  data() {
    return {
      dialogAddPermissao: false,
    };
  },

  mounted() {},

  created() {},
};
</script>

<style></style>
