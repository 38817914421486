<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.novo') + ' ' + $tc('global.cliente')"
      icon="mdi-clipboard-account-outline"
      dark
      voltar
    />
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 justify-space-between align-center"
        >{{ $tc("global.dados-gerais") }}
        <v-spacer></v-spacer>
        <v-btn
          @click="createCliente"
          :disabled="!validForm"
          color="button_1"
          class="white--text"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="2">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="logo">
                  <v-img :src="logo" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">
                        {{ $tc("global.arrastesolte") }}
                      </h3>
                      <div class="text-caption">
                        {{ $tc("global.cliqueselecionar") }}
                      </div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="10">
              <v-row dense>
                <!-- Nome -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.nome"
                    :label="$tc('global.nome')"
                    dense
                    outlined
                    :rules="formRules"
                    required
                  ></v-text-field>
                </v-col>
                <!-- Documento -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.doc_ci"
                    :label="$tc('global.documento') + ` CI`"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- Documento -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.doc_ruc"
                    :label="$tc('global.documento') + ` RUC`"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <!-- Data Nascimento -->
                <v-col cols="12" md="4">
                  <DataField
                    :data_sync.sync="cliente.data_nascimento"
                    :label="$tc('global.data-nascimento')"
                  />
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.email"
                    :label="$tc('global.email')"
                    dense
                    outlined
                    :rules="cliente.email ? emailRules : undefined"
                    validate-on-blur
                    clearable
                  ></v-text-field>
                </v-col>
                <!-- Gênero -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedGenero"
                    :label="$tc('global.genero')"
                    :items="tipos_genero"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <!-- celular -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.celular"
                    :label="$tc('global.celular')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- telefone -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.telefone"
                    :label="$tc('global.telefone')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- localizacao -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.localizacao"
                    :label="$tc('global.localizacao')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- LOCAL DE TRABALHO -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.local_trabalho"
                    :label="$tc('global.localTrabalho')"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- Cidade -->
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="selectedCidade"
                    :items="cidades"
                    :loading="buscandoCidades"
                    :search-input.sync="searchCidade"
                    @keyup="getCidades"
                    dense
                    outlined
                    hide-details
                    item-text="nome"
                    item-value="id"
                    :placeholder="$tc('global.cidade')"
                    append-icon=""
                    full-width
                    return-object
                    autocomplete="off"
                    class="remove-underline input-font-size"
                  ></v-autocomplete>
                </v-col>

                <!-- Lista Preço -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model.number="cliente.lista_preco_id"
                    :items="listas"
                    clearable
                    item-text="descricao"
                    item-value="id"
                    dense
                    outlined
                    required
                    :rules="formRules"
                    :label="$tc('global.listadepreco')"
                    :loading="loadingListas"
                  ></v-select>
                </v-col>

                <!-- Moeda -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedMoeda"
                    :items="moedas"
                    clearable
                    item-text="sigla"
                    item-value="id_moeda"
                    dense
                    outlined
                    required
                    return-object
                    :rules="formRules"
                    :label="$tc('global.moeda')"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    dense
                    outlined
                    readonly
                    :placeholder="$tc('global.fornecedor')"
                  >
                    <template v-slot:append>
                      <v-switch
                        v-model="selectedFornecedor"
                        :false-value="0"
                        :true-value="1"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Categoria -->
                <v-col v-if="selectedFornecedor" cols="12" md="4">
                  <v-combobox
                    v-model="selectedCategoria"
                    :label="
                      $tc('global.categoria') + ' ' + $tc('global.fornecedor')
                    "
                    :items="categorias"
                    dense
                    outlined
                    :rules="formRules"
                    required
                    item-text="descricao"
                    item-value="id"
                  ></v-combobox>
                </v-col>

                <!-- agente retentor -->
                <v-col cols="12" md="4">
                  <v-text-field
                    dense
                    outlined
                    readonly
                    :value="$tc('global.agenteRetentor')"
                  >
                    <template v-slot:append>
                      <v-switch
                        v-model="cliente.agente_retentor"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Credito Cliente -->
                <v-col cols="12" md="4">
                  <v-text-field
                    ref="valorCreditoCliente"
                    v-model="valorCreditoCliente"
                    label="Credito"
                    dense
                    required
                    :rules="formRules"
                    :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                    outlined
                    height="40px"
                    :disabled="!selectedMoeda"
                    style="display: flex"
                    class="remove-underline input-font-size input-right"
                    v-currency="vCurrencyOptions"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
  <!-- <NaoAutorizado v-else /> -->
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { fetchListasPrecos } from "@/api/listas-precos/listasprecos.js";
import { searchCidades } from "@/api/cidades/cidades.js";
import { postCliente } from "@/api/clientes/clientes.js";
import { mapState } from "vuex";
import { inputRequired, validaEmail } from "../../../helpers/utils.js";
import { getFornecedoresCategoriasAtivo } from "@/api/fornecedores/categorias_fornecedores.js";

export default {
  name: "NovoCliente",
  data: () => ({
    cliente: {
      agente_retentor: false,
    },
    cidades: [],
    validForm: true,
    formRules: [inputRequired],
    emailRules: [validaEmail],
    loading: false,
    logo: null,
    logoUpload: null,
    hoverFile: false,
    selectedGenero: null,
    tipos_genero: ["Masculino", "Feminino", "Não Declarar"],
    listas: [],
    loadingListas: false,
    buscandoCidades: false,
    searchCidade: "",
    selectedCidade: null,
    selectedFornecedor: 0,
    selectedCategoria: null,
    fornecedor_categoria_id: null,
    categorias: [],

    listaPreco: null,
    selectedMoeda: null,
    valorCreditoCliente: 0,
  }),

  components: {
    vueDropzone: vue2Dropzone,
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      lista_preco_empresa: (state) => state.empresa.lista_preco_id,
    }),

    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    selectedCategoria() {
      if (this.selectedCategoria) {
        this.fornecedor_categoria_id = this.selectedCategoria.id;
      }
    },

    selectedGenero() {
      if (this.selectedGenero) {
        this.cliente.genero = this.selectedGenero;
      }
    },
    selectedCidade() {
      if (this.selectedCidade) {
        this.cliente.cidade_id = this.selectedCidade.id;
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda) {
        this.cliente.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
  },

  methods: {
    fileAdded(file) {
      this.logoUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
      };
    },

    getListas() {
      this.loadingListas = true;
      fetchListasPrecos(this.url)
        .then((response) => {
          this.listas = response;
          this.listaPreco = this.listas.find(
            (lista) => lista.descricao === "VAREJO"
          );
          this.cliente.lista_preco_id = this.listaPreco.id;
          this.loadingListas = false;
        })
        .catch(() => {
          this.loadingListas = false;
        });
    },

    getCategorias() {
      getFornecedoresCategoriasAtivo().then((response) => {
        this.categorias = response;
      });
    },

    searchCidades(filtro) {
      return searchCidades(filtro).then((response) => {
        this.cidades = response;
      });
    },

    async getCidades() {
      if (this.searchCidade) {
        if (this.searchCidade.length > 2 && event.key != "Backspace") {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
    },

    createCliente() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        // if (!this.cliente.doc_ci && !this.cliente.doc_ci) {
        //   this.$toast.error("por favor inserte algún documento");
        //   this.loading = false;
        //   return;
        // }

        const cliente = new FormData();

        if (this.logoUpload) {
          cliente.append("logo", this.logoUpload, this.logoUpload.name);
          cliente.append("logo64", this.logo);
        }

        let cliente_2 = {};
        cliente_2.empresa_id = this.cliente.empresa_id;
        cliente_2.doc_ci = this.cliente.doc_ci;
        cliente_2.doc_ruc = this.cliente.doc_ruc;
        cliente_2.nome = this.cliente.nome;
        cliente_2.email = this.cliente.email;
        cliente_2.endereco = this.cliente.endereco;
        cliente_2.celular = this.cliente.celular;
        cliente_2.telefone = this.cliente.telefone;
        cliente_2.localizacao = this.cliente.localizacao;
        cliente_2.status = this.cliente.status;
        cliente_2.cidade_id = this.cliente.cidade_id;
        cliente_2.lista_preco_id = this.cliente.lista_preco_id;
        cliente_2.moeda_id = this.cliente.moeda_id;
        cliente_2.data_nascimento = this.cliente.data_nascimento;
        cliente_2.genero = this.cliente.genero;
        cliente_2.agente_retentor = this.cliente.agente_retentor;
        cliente_2.local_trabalho = this.cliente.local_trabalho;
        cliente_2.credito = this.$ci.parse(
          this.valorCreditoCliente,
          this.vCurrencyOptions
        );

        for (let key in cliente_2) {
          if (
            cliente_2[key] !== null &&
            cliente_2[key] !== undefined &&
            cliente_2[key] !== ""
          ) {
            cliente.append(key, cliente_2[key]);
          }
        }

        if (this.selectedFornecedor) {
          let fornecedor = {};

          fornecedor.nome = this.cliente.nome;
          fornecedor.endereco = this.cliente.endereco;
          fornecedor.email = this.cliente.email;
          fornecedor.moeda_id = this.cliente.moeda_id;
          fornecedor.celular = this.cliente.celular;
          fornecedor.telefone = this.cliente.telefone;
          fornecedor.documento = this.cliente.doc_ruc;
          fornecedor.categoria_id = this.fornecedor_categoria_id;
          fornecedor.cidade_id = this.cliente.cidade_id;

          cliente_2.fornecedor = fornecedor;
          cliente.append("fornecedor", JSON.stringify(cliente_2.fornecedor));
        }

        postCliente(cliente)
          .then(() => {
            this.$router.push({ path: "/clientes" });
          })
          .catch((error) => {
           this.$toast.error(error.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.selectedMoeda = this.moedas.find((moeda) => {
      return moeda.descricao === "GUARANI";
    });

    this.getCategorias();
  },
  created() {
    this.getListas();
    if (this.lista_preco_empresa) {
      this.cliente.lista_preco_id = this.lista_preco_empresa;
    }
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
