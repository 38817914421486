var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1500px"},on:{"click:outside":function($event){return _vm.$emit('update:dialogCaixaAbertoVisualizacao', false)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('update:dialogCaixaAbertoVisualizacao', false)}},model:{value:(_vm.dialogCaixaAbertoVisualizacao),callback:function ($$v) {_vm.dialogCaixaAbertoVisualizacao=$$v},expression:"dialogCaixaAbertoVisualizacao"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"tocs_gray_1"},[_vm._v(" "+_vm._s(_vm.$tc("global.movimentacao") + " " + _vm.$tc("global.caixa"))+" "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.$emit('update:dialogCaixaAbertoVisualizacao', false)}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.caixaAfMovimentacao,"items-per-page":15,"multi-sort":""},scopedSlots:_vm._u([{key:"item.circle",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([(item.tipo_af === 'E')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v(" mdi-plus-circle ")])]}}:null],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc("global.entrada"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([(item.tipo_af === 'S')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v(" mdi-minus-circle ")])]}}:null],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc("global.saida"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"darkblue"},scopedSlots:_vm._u([(item.tipo_af === 'N')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"tocs_gray_2"}},on),[_vm._v(" mdi-alpha-n-circle ")])]}}:null],null,true)},[_c('span',[_vm._v(" Essa entrada não acrescenta ao total do caixa ")])])]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [(item.sigla === 'G$')?_c('span',[_vm._v(_vm._s(_vm._f("guarani")(item.valor)))]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.valor)))])]}}])})],1),_c('v-card-actions',[_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }