<template>
  <v-card :loading="loading" >
    <v-card-title class="tocs_gray_1 ">
      <v-icon class="mr-2">mdi-cart-outline</v-icon>
      {{ $tc("global.aPagar") }}
      <v-spacer></v-spacer>
      {{ $tc("global.totalPagar") }}: &nbsp;
      <span class="px-2">
        G$ {{ totalGeralGuarani | guarani }}
      </span>
       <span class="px-2">
        U$ {{ totalGeralDolar | currency }}
      </span>
       <span class="px-2">
        R$ {{ totalGeralReal | currency }}
      </span>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="compras"
        :items-per-page="5"
        :sort-by="['data_vencimento']"
        class="data-tables data-tables__row-click"
        :hide-default-footer="compras.length > 5 ? false : true"
        :item-class="row_classes"
      >
        <template v-slot:item.data_vencimento="{ item }">
          {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
        </template>

        <template v-slot:item.data_lancamento="{ item }">
          {{ item.data_lancamento | dateFormat("dd/MM/yyyy") }}
        </template>

        <template v-slot:item.valor="{ item }">
          <div v-if="item.moeda_sigla === 'G$'">
            {{ item.moeda_sigla }} {{ item.valor | guarani }}
          </div>
          <div v-else>{{ item.moeda_sigla }} {{ item.valor | currency }}</div>
        </template>
        <template v-slot:item.valor_pago="{ item }">
          <div v-if="item.moeda_sigla === 'G$'">
            {{ item.moeda_sigla }} {{ item.valor_pago | guarani }}
          </div>
          <div v-else>{{ item.moeda_sigla }} {{ item.valor_pago | currency }}</div>
        </template>

        <template v-slot:item.saldo="{ item }">
          <div v-if="item.moeda_sigla === 'G$'">
            {{ item.moeda_sigla }} {{ item.saldo | guarani }}
          </div>
          <div v-else>{{ item.moeda_sigla }} {{ item.saldo | currency }}</div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getBoxContasPagar } from "@/api/contas-pagar/contas_pagar.js";
import { format } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "BoxContasPagar",

  data() {
    return {
      loading: false,
      compras: [],
    };
  },

  computed: {
    headers() {
      return [
         {
          text: this.$tc("global.vencimento"),
          value: "data_vencimento",
        },
        {
          text: this.$tc("global.data"),
          value: "data_lancamento",
        },
       
        {
          text: this.$tc("global.fornecedor"),
          value: "fornecedor_nome",
        },
        {
          text: this.$tc("global.valor"),
          align: "right",
          value: "valor",
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.pago"),
          align: "right",
          value: "valor_pago",
        },
        {
          text: this.$tc("global.saldo"),
          align: "right",
          value: "saldo",
        },
      ];
    },

    totalGeralGuarani() {
      let result = 0;
      if (this.compras.length) {
        let total = 0;
        const reducer = (accumulator, item) => {
             if (item.moeda_sigla == "G$"){
          total = accumulator +  Number(item.saldo);
          }
          return total;
        };
        result = this.compras.reduce(reducer, 0);
      }
      return result;
    },

    totalGeralDolar() {
        let result = 0;
      if (this.compras.length) {
        let total = 0;
        const reducer = (accumulator, item) => {
             if (item.moeda_sigla == "U$"){
          total = accumulator +  Number(item.saldo);
        }
        return total;
      };
        result = this.compras.reduce(reducer, 0);
      }
      return result;
    },

    totalGeralReal() {
        let result = 0;
      if (this.compras.length) {
        let total = 0;
        const reducer = (accumulator, item) => {
             if (item.moeda_sigla == "R$"){
          total = accumulator +  Number(item.saldo);
        }
        return total;
      };
        result = this.compras.reduce(reducer, 0);
      }
      return result;
    }

  },

  methods: {
    row_classes(item) {
     let dataNow = ''; 
     dataNow = format(new Date(), "yyyy-MM-dd");
      if (item.data_vencimento < dataNow) {
        return "tocs_red--text";
      } 
    },

  },

  async mounted() {
    this.loading = true;
    this.compras = await getBoxContasPagar(`?status=aberto&monthNow=true`);
    this.loading = false;
  },
};
</script>

<style></style>
