<template>
  <v-dialog
    v-model="dialogBaixaContas"
    @keydown.esc="fecharBaixaContas"
    width="90vw"
    persistent
  >
    <v-card tile>
      <v-card-title class="tocs_gray_1">
        <span class="headline">{{ $tc("global.baixaDeContas") }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="fecharBaixaContas" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <BaixaDeContasReceberCaixaAf
        v-if="tipo_baixa === 'receber'"
      ></BaixaDeContasReceberCaixaAf>

      <BaixaDeContasPagarCaixaAf
        v-if="tipo_baixa === 'pagar'"
      ></BaixaDeContasPagarCaixaAf>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogBaixaContas",

  props: {
    dialogBaixaContas: {
      type: Boolean,
      default: false,
    },
    tipo_baixa: {
      type: String,
    },
  },

  components: {
    BaixaDeContasPagarCaixaAf: () =>
      import("./baixa-de-contas/BaixaDeContasPagarCaixaAf.vue"),
    BaixaDeContasReceberCaixaAf: () =>
      import("./baixa-de-contas/BaixaDeContasReceberCaixaAf.vue"),
  },

  methods: {
    fecharBaixaContas() {
      this.$emit("update:dialogBaixaContas", false);
      this.$emit("fetch-movimentacoes");
    },
  },
};
</script>

<style></style>
