<template>
  <v-container fluid>
    <PageToolbar :title="$tc('global.moeda', 2)" icon="mdi-currency-usd" dark />

    <ListaMoedas :key="compMoedasKey" />
  </v-container>
</template>

<script>
export default {
  name: "Moedas",

  components: {
    ListaMoedas: () => import("./components/ListaMoedas.vue"),
  },

  data() {
    return {
      compMoedasKey: 0,
    };
  },

  computed: {},

  methods: {
    reloadNovaMoeda() {
      this.compMoedasKey += 1;
    },
  },
};
</script>

<style></style>
