<template>
  <v-dialog
    v-model="dialogMarcas"
    @click:outside="$emit('update:dialogMarcas', false)"
    @keydown.esc="$emit('update:dialogMarcas', false)"
    width="600px"
  >
    <v-card>
      <v-card>
        <v-card-title class="tocs_gray_1">
          <v-text-field
            style="max-width: 400px"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            clearable
            outlined
            solo
            flat
            dense
            hide-details
          >
          </v-text-field>
          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <DialogAddEditMarcas
            v-if="dialogAddEditMarcas"
            :dialogAddEditMarcas.sync="dialogAddEditMarcas"
            :edit="edit"
            :adding="adding"
            :item="marca"
            @fetch-marcas="getMarcas"
          />
          <v-btn
            color="button_1"
            @click="addMarca"
            class="white--text mt-4 mt-sm-0"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $tc("global.add") }}
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :search="search.length >= 3 ? search : ''"
            :items="marcas"
            :items-per-page="10"
            :sort-by="['created_at']"
            :sort-desc="true"
            class="data-tables data-tables__row-click"
            @click:row="goToMarca"
          >
            <template v-slot:item.status="{ item }">
              <span size="28" v-if="item.status === 0">inativo</span>
              <span v-if="item.status === 1"> ativo </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchMarcas } from "@/api/marcas/marcas.js";
export default {
  name: "DialogMarcas",

  components: {
    DialogAddEditMarcas: () => import("./components/DialogAddEditMarcas.vue"),
  },

  props: {
    dialogMarcas: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      marca: {},
      loading: false,
      marcas: [],
      dialogAddEditMarcas: false,
      marcas_original: {},
      search: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.nome"),
          value: "nome",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
          value: "status",
        },
      ];
    },
  },

  methods: {
    addMarca() {
      this.dialogAddEditMarcas = true;
      this.adding = true;
      this.edit = false;
    },
    goToMarca(item) {
      this.marca = { ...item };
      this.dialogAddEditMarcas = true;
      this.adding = false;
      this.edit = true;
    },

    getMarcas() {
      this.loading = true;
      this.marcas = [];
      fetchMarcas()
        .then((response) => {
          this.marcas = response;
          // this.marcas_original = { ...response };
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    this.getMarcas();
  },
};
</script>

<style></style>
