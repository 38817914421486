<template>
  <v-dialog
    v-model="dialogFiltrosRelatorio"
    @click:outside="$emit('update:dialogFiltrosRelatorio', false)"
    @keydown.esc="$emit('update:dialogFiltrosRelatorio', false)"
    max-width="700px"
    scrollable
  >
    <v-card v-if="item">
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.relatorio") }} - {{ item.title }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogFiltrosRelatorio', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5">
        <v-row v-if="checkIfFiltersExists()">
          <template v-for="(file, index) in filtersFolder">
            <v-col
              cols="12"
              md="6"
              :key="index"
              v-if="filterExists(clearCompName(file))"
            >
              <component :is="clearCompName(file)" />
            </v-col>
          </template>
        </v-row>
        <div v-else>Nenhum filtro encontrado!</div>
      </v-card-text>

      <v-card-actions
        class="pa-5"
        v-if="item && (item.excel_path || item.pdf_path)"
      >
        <v-spacer></v-spacer>

        <!-- <component
          v-if="item.excel_path"
          :disabled="disableButtons"
          :is="excelComponent"
          class="mr-3"
        /> -->

        <component
          v-if="item.pdf_path"
          :disabled="disableButtons"
          :is="pdfComponent"
        />
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-card-text class="pa-4"
        >Item não foi carregado com sucesso!</v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { parseISO, isBefore, isAfter } from "date-fns";
import { mapState, mapActions } from "vuex";

export default {
  name: "DialogFiltrosRelatorio",

  components: {},

  props: {
    dialogFiltrosRelatorio: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      filtersFolder: require.context("./filtros", false, /\.(vue)$/).keys(),
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      dataFromStore: (state) => state.dataFrom,
      dataToStore: (state) => state.dataTo,
      item: (state) => state.selectedItem,
      filtros: (state) => state.filtros,
    }),

    disableButtons() {
      let disable = false;

      if (!this.checkIfFiltersExists()) {
        disable = true;
      }
      if (
        isAfter(parseISO(this.filtros.datafrom), parseISO(this.filtros.datato))
      ) {
        disable = true;
      }
      this.item.filtros.forEach((filtro) => {
        if (!this.filtros[filtro.text.toLowerCase()] && filtro.required) {
          disable = true;
        }
      });

      return disable;
    },

    pdfComponent() {
      return this.item && this.item.pdf_path
        ? () => import(`../reports/${this.item.pdf_path}`)
        : null;
    },

    // excelComponent() {
    //   return this.item && this.item.excel_path
    //     ? () => import(`../reports/${this.item.excel_path}`)
    //     : null;
    // },
  },

  methods: {
    ...mapActions("FiltrosRelatorios", {
      reset: "reset",
    }),

    checkIfFiltersExists() {
      return this.filtersFolder.some((item) =>
        this.item.filtros.some(
          (filtro) => this.clearCompName(item) === filtro.text
        )
      );
    },

    clearCompName(comp) {
      comp = comp.replace(/^.*\//, "");
      comp = comp.replace(/\.vue$/, "");
      return comp;
    },

    filterExists(filter) {
      return this.item.filtros.some((item) => item.text === filter);
    },
  },

  created() {
    this.filtersFolder.forEach((file) => {
      const comp = this.clearCompName(file);

      this.$options.components[comp] = () => import(`./filtros/${comp}.vue`);
    });
  },

  beforeDestroy() {
    this.reset();
  },
};
</script>

<style></style>
