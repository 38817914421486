<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dataToTextField"
        :label="$tc('global.para') + ':'"
        outlined
        dense
        autocomplete="off"
        v-mask="`##/##/####`"
        hide-details
        @blur="parseDateFromTextField(dataToTextField)"
        :error="dataToError"
        clearable
        @click:clear="clearDataTo"
      >
        <template v-slot:append>
          <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="dataToPicker"
      no-title
      @input="menu1 = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { format, parseISO, isValid, isFriday } from "date-fns";
import { mapState } from "vuex";
export default {
  name: "DataTo",
  data() {
    return {
      menu1: false,
      dataToTextField: null,
      dataToPicker: null,
      dataTo: null,
      dataToError: false,
    };
  },
  computed: {
    ...mapState("Filtros", {
      dataToStore: (state) => state.filtros.dataTo,
    }),
    dataToQuery() {
      return this.$route.query.dataTo ? this.$route.query.dataTo : null;
    },
  },
  watch: {
    dataToPicker() {
      if (this.dataToPicker) {
        const [year, month, day] = this.dataToPicker.split("-");
        this.dataToTextField = `${day}/${month}/${year}`;
        this.dataTo = this.dataToPicker;
      }
    },
    dataTo() {
      if (this.dataTo) {
        this.$store.commit("Filtros/UPDATE_DATATO", this.dataTo);
      }
    },
    dataToQuery: {
      handler() {
        if (this.dataToQuery) {
          this.dataToPicker = this.dataToQuery;
          const [year, month, day] = this.dataToQuery.split("-");
          this.dataToTextField = `${day}/${month}/${year}`;
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearDataTo() {
      this.$store.commit("Filtros/UPDATE_DATATO", null);
      this.dataTo = null;
      this.dataToPicker = null;
    },
    parseDateFromTextField(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      let fixDate = parseISO(`${year}-${month}-${day}`);
      if (isValid(fixDate)) {
        let formatedDate = format(fixDate, "yyyy-MM-dd");
        this.dataToPicker = formatedDate;
        this.dataTo = formatedDate;
        this.dataToError = false;
      } else {
        this.$toast.error("Data invalida!");
        this.dataToError = true;
      }
    },
  },
};
</script>

<style></style>
