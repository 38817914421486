<template>
  <v-dialog
    v-model="dialogConta"
    persistent
    @click:outside="$emit('update:dialogConta', false)"
    @keydown.esc="$emit('update:dialogConta', false)"
    width="500px"
    max-width="500px"
    :scrollable="false"
  >
    <v-card flat>
      <v-card-title class="pa-4 secondary white--text">
        {{ $tc("global.conta") }}:{{ conta.id }}
        <v-spacer></v-spacer>
        {{ conta.ordem }}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('update:dialogConta', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="6">
                <DataField
                  :data_sync.sync="conta.data_vencimento"
                  label="Data Vencimento"
                  :obrigatorio="formRules ? true : false"
                  :data_min="today"
                />
              </v-col>

              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="conta.desconto"
                  :label="$tc('global.desconto')"
                  :placeholder="$tc('global.desconto')"
                  :rules="formRules"
                  flat
                  outlined
                  dense
                  hide-details
                  v-currency="vCurrencyOptions"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="conta.juros"
                  :label="$tc('global.juros')"
                  :placeholder="$tc('global.juros')"
                  v-currency="vCurrencyOptions"
                  outlined
                  :rules="formRules"
                  flat
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="conta.multa"
                  :label="$tc('global.multa')"
                  :placeholder="$tc('global.multa')"
                  v-currency="vCurrencyOptions"
                  outlined
                  :rules="formRules"
                  flat
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-text-field
                  v-model="conta.valor"
                  :label="$tc('global.valor')"
                  :placeholder="$tc('global.valor')"
                  :rules="formRules"
                  outlined
                  flat
                  dense
                  :disabled="conta.origem === 'MANUAL' ? false : true"
                  hide-details
                  v-currency="vCurrencyOptions"
                >
                </v-text-field>
              </v-col>
              <!-- DESCRICÃO -->
              <v-col
                v-if="
                  conta.juros !== valorJurosOriginal || conta.observacao_juros
                "
                class="pb-5"
                cols="12"
              >
                <v-textarea
                  v-model="conta.observacao_juros"
                  :label="$tc('global.observacao')"
                  dense
                  :rules="formRules"
                  rows="2"
                  hide-details
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="semMotivo"
          :loading="loading"
          depressed
          small
          @click="updateContaReceber()"
        >
          {{ $tc("global.atualizar") }}
          <v-icon right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { putContaReceber } from "@/api/contas-receber/contas_receber.js";
import { inputRequired } from "@/helpers/utils.js";
import { format } from "date-fns";
export default {
  name: "DialogContaReceberCliente",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    dialogConta: {
      type: Boolean,
      default: false,
    },
    conta: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      today: format(new Date(), "yyyy-MM-dd"),
      valorJurosOriginal: 0,
    };
  },

  computed: {
    semMotivo() {
      let result = false;
      if (this.conta.juros != this.valorJurosOriginal) {
        if (
          this.conta.observacao_juros === "" ||
          this.conta.observacao_juros === null ||
          this.conta.observacao_juros === undefined ||
          this.conta.observacao_juros.length < 10
        ) {
          result = true;
        }
      }

      return result;
    },
    vCurrencyOptions() {
      return {
        precision: this.conta?.moeda === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  methods: {
    updateContaReceber() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let conta = {};
        conta.id = Number(this.conta.id);
        conta.valor = this.$ci.parse(this.conta.valor, this.vCurrencyOptions);
        conta.juros = this.$ci.parse(this.conta.juros, this.vCurrencyOptions);
        conta.multa = this.$ci.parse(this.conta.multa, this.vCurrencyOptions);
        if (this.conta.juros != this.valorJurosOriginal) {
          conta.observacao_juros = this.conta.observacao_juros;
        }

        conta.desconto = this.$ci.parse(
          this.conta.desconto,
          this.vCurrencyOptions
        );
        conta.data_vencimento = this.conta.data_vencimento;
        putContaReceber(conta.id, conta)
          .then(() => {
            this.loading = false;
            this.$emit("fetch-contas");
            this.$emit("update:dialogConta", false);
          })
          .catch(() => {
            this.loading = false;
            this.$emit("update:dialogConta", false);
          });
      }
    },
  },
  mounted() {
    this.valorJurosOriginal = this.conta.juros;
  },
};
</script>

<style></style>
