var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"tocs_gray_1 "},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cart-outline")]),_vm._v(" "+_vm._s(_vm.$tc("global.aPagar"))+" "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.$tc("global.totalPagar"))+":   "),_c('span',{staticClass:"px-2"},[_vm._v(" G$ "+_vm._s(_vm._f("guarani")(_vm.totalGeralGuarani))+" ")]),_c('span',{staticClass:"px-2"},[_vm._v(" U$ "+_vm._s(_vm._f("currency")(_vm.totalGeralDolar))+" ")]),_c('span',{staticClass:"px-2"},[_vm._v(" R$ "+_vm._s(_vm._f("currency")(_vm.totalGeralReal))+" ")])],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.compras,"items-per-page":5,"sort-by":['data_vencimento'],"hide-default-footer":_vm.compras.length > 5 ? false : true,"item-class":_vm.row_classes},scopedSlots:_vm._u([{key:"item.data_vencimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_vencimento,"dd/MM/yyyy"))+" ")]}},{key:"item.data_lancamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_lancamento,"dd/MM/yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('div',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('div',[_vm._v(_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("currency")(item.valor)))])]}},{key:"item.valor_pago",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('div',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.valor_pago))+" ")]):_c('div',[_vm._v(_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("currency")(item.valor_pago)))])]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('div',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.saldo))+" ")]):_c('div',[_vm._v(_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("currency")(item.saldo)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }