<template>
  <v-dialog v-model="dialog" max-width="1100px" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn class="pa-0" icon v-on="on">
        <v-icon>mdi-currency-usd</v-icon>
      </v-btn>
    </template>

    <v-card style="overflow-y: hidden">
      <v-card-title class="pa-4 font-weight-bold secondary white--text">
        <v-icon dark large left> mid-currency-usd</v-icon>
        {{$tc('global.cambio')}}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- <v-divider></v-divider> -->

      <v-card-title class="pa-0">
        <v-tabs fixed-tabs background-color="tocs_gray_1" v-model="tab">
          <v-tab>{{$tc('global.historico')}}</v-tab>
          <v-tab>{{ add ? $tc('global.add') : $tc('global.editar') }}</v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text class="">
        <v-tabs-items v-model="tab">
          <!-- Tabela de cambios -->
          <v-tab-item class="pa-0">
            <v-row
              class="pt-4 d-flex justify-align-center align-center"
              v-if="cambio"
            >
              <v-col cols="12" md="3">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-subtitle-1">Dia</span>
                  <v-spacer></v-spacer>
                  <span class="pr-2 text-subtitle-1">
                    {{ cambio.created_at | dateFormat("dd/MM") }}
                  </span>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-subtitle-1">G$ -> U$</span>

                  <v-spacer></v-spacer>
                  <span class="pr-2 text-subtitle-1">
                    {{ cambio.guarani | guarani }}
                  </span>
                </v-card>
              </v-col>

              <v-col cols="12" md="3">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-subtitle-1">R$</span>

                  <v-spacer></v-spacer>
                  <span class="pr-2 text-subtitle-1">
                    {{ cambio.real | currency }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-subtitle-1">G$ {{$tc('global.credito')}}</span>

                  <v-spacer></v-spacer>
                  <span class="pr-2 text-subtitle-1">
                    {{ cambio.guarani_credito | guarani }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-subtitle-1">G$ {{$tc('global.debito')}}</span>

                  <v-spacer></v-spacer>
                  <span class="pr-2 text-subtitle-1">
                    {{ cambio.guarani_debito | guarani }}
                  </span>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card outlined class="pa-1 d-flex align-center">
                  <span class="pl-2 text-subtitle-1">G$ -> R$</span>

                  <v-spacer></v-spacer>
                  <span class="pr-2 text-subtitle-1">
                    {{ cambio.guarani_real | guarani }}
                  </span>
                </v-card>
              </v-col>
            </v-row>

            <template>
              <v-data-table
                v-if="cambios"
                calculate-widths
                :headers="headers"
                :items="cambios"
                :items-per-page="4"
                class="data-tables pt-2"
              >
                <template v-slot:item.created_at="{ item }">
                  {{ item.created_at | dateFormat("dd/MM/yyyy") }}
                </template>
                <template v-slot:item.real="{ item }">
                  R${{ item.real | currency }}
                </template>

                <template v-slot:item.guarani="{ item }">
                  G${{ item.guarani | guarani }}
                </template>
                <template v-slot:item.guarani_venda="{ item }">
                  G${{ item.guarani_venda | guarani }}
                </template>
                <template v-slot:item.real_venda="{ item }">
                  R${{ item.real_venda | currency }}
                </template>
                <template v-slot:item.guarani_credito="{ item }">
                  G${{ item.guarani_credito | guarani }}
                </template>
                <template v-slot:item.guarani_debito="{ item }">
                  G${{ item.guarani_debito | guarani }}
                </template>
                <!-- <template v-slot:item.actions="{ item }">
                  <v-btn x-small icon @click="delCambio(item.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template> -->
              </v-data-table>
            </template>
          </v-tab-item>

          <v-tab-item class="pa-0 pt-2">
            <v-row class="mb-1 d-flex">
              <v-col cols="12" md="3" class="px-2 pb-0">
                <v-text-field
                  v-model.number="cambioEdit.guarani"
                  label="Guarani"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="mt-4 pl-1"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model.number="cambioEdit.real"
                  label="Real"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="mt-4 pl-1"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model.number="cambioEdit.guarani_credito"
                  :label="'Guarani ' + $tc('global.credito')"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="mt-4 pl-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.number="cambioEdit.guarani_real"
                  :label="'Guarani ' + $tc('global.para') + ' Real'"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="mt-4 pr-1"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" >
                <v-text-field
                  v-model.number="cambioEdit.guarani_venda"
                  :label="'Guarani ' + $tc('global.venda')"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="mt-4 pr-1"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model.number="cambioEdit.real_venda"
                  :label="'Real '  + $tc('global.venda')"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="mt-4 pr-1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.number="cambioEdit.guarani_debito"
                  :label="'Guarani ' + $tc('global.debito')"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="mt-4 pr-1"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="px-0">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                small
                :disabled="disableButton"
                color="button_2"
                class="white--text"
                @click="add ? postCambio() : putCambio()"
              >
                {{ add ?  $tc('global.add') : $tc('global.editar') }}
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import { mapState, mapActions } from "vuex";
export default {
  name: "Cambio",

  components: {
    // DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      loading: true,
      dialog: false,
      tab: 0,
      cambioEdit: {},
      add: true,
    };
  },

  computed: {
    ...mapState("Cambio", {
      cambios: (state) => state.cambios,
      cambio: (state) => state.cambio,
    }),
    headers() {
      return [
        {
          text: this.$tc("global.data"),
          align: "center",
          with: "10px",
          sortable: false,
          value: "created_at",
        },

        {
          text: "Guarani",
          sortable: false,
          align: "center",
          with: "20px",
          value: "guarani",
        },

        {
          text: 'Guarani ' + this.$tc("global.venda"),
          sortable: false,
          align: "center",
          with: "20px",
          value: "guarani_venda",
        },

        {
          text: "  Real  ",
          align: "center",
          with: "30px",
          sortable: false,
          value: "real",
        },

        {
          text: "Real "  + this.$tc("global.venda"),
          sortable: false,
          with: "20px",
          align: "center",
          value: "real_venda",
        },
        {
          text: "Guarani "  + this.$tc("global.credito"),
          sortable: false,
          with: "20px",
          align: "center",
          value: "guarani_credito",
        },
        {
          text: "Real "  + this.$tc("global.debito"),
          sortable: false,
          with: "20px",
          align: "center",
          value: "guarani_debito",
        },

        // {
        //   sortable: false,
        //   text: "",
        //   value: "actions",
        //   with: "5px",
        // },
      ];
    },
    disableButton() {
      if (
        this.cambioEdit.real &&
        this.cambioEdit.guarani &&
        this.cambioEdit.guarani_venda &&
        this.cambioEdit.real_venda &&
        this.cambioEdit.guarani_real
      ) {
        return false;
      } else {
        return true;
      }
    },
  },

  watch: {
    tab() {
      if (this.tab === 0) {
        this.getAll();
        this.getCambioDiaa();
      }
    },
  },

  methods: {
    ...mapActions("Cambio", {
      fetchAll: "getAll",
      fetchCambioDia: "getCambioDia",
      adicionarCambio: "postCambio",
      updateCambio: "updateCambio",
      // deleteCambio: "delCambio",
    }),
    getCambioDiaa() {
      this.loading = true;
      return this.fetchCambioDia()
        .then(() => {
          if (
            format(parseISO(this.cambio.created_at), "dd/MM/yyyy") ==
            format(new Date(), "dd/MM/yyyy")
          ) {
            this.add = false;
            this.cambioEdit = { ...this.cambio };
          } else {
            this.add = true;
            this.cambioEdit = {};
          }

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAll() {
      this.loading = true;
      return this.fetchAll()
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // delCambio(id) {
    //   this.loading = true;
    //   return this.deleteCambio(id)
    //     .then(() => {
    //       this.$toast.success("Cambio deletado com sucesso!");
    //       this.loading = false;

    //       this.getAll();
    //       this.getCambioDiaa();
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },

    putCambio() {
      this.loading = true;
      return this.updateCambio({
        id: this.cambioEdit.id,
        cambio: this.cambioEdit,
      })
        .then(() => {
          this.$toast.success("Cambio atualizado com sucesso!");

          this.loading = false;

          this.getAll();
          this.getCambioDiaa();
          this.tab = 0;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postCambio() {
      this.loading = true;
      return this.adicionarCambio(this.cambioEdit)
        .then(() => {
          this.$toast.success("Cambio adicionado com sucesso!");
          this.loading = false;

          this.getAll();
          this.getCambioDiaa();
          this.tab = 0;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    if (this.tab === 0) {
      await this.getAll();
      await this.getCambioDiaa();
    }
  },
};
</script>

<style></style>
