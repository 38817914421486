<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card outlined>
        <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
          {{ $tc("global.moeda", 2) }}

          <v-spacer></v-spacer>
          <v-btn
            depressed
            fab
            x-small
            class="white--text"
            color="button_1"
            @click="addMoedaEmpresa"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider v-if="moedas.length"></v-divider>
        <v-card-text v-if="moedas.length" class="py-4">
          <v-chip
            class="ma-1"
            v-for="(moeda, index) in moedas"
            :key="index"
            close-icon="mdi-delete-outline"
            @click:close="delMoeda(moeda.id_pivo)"
            label
            :close="moedas.length > 1 ? true : false"
          >
            {{ moeda.descricao.toUpperCase() }} -
            {{ moeda.sigla }}
          </v-chip>
        </v-card-text>
        <p v-else class="d-flex justify-center pt-6">
          {{ $tc("global.nenhumamoedacadastrada") }}
        </p>
      </v-card>
      <DialogMoeda
        v-if="dialogMoedaEmpresa && moedasNotEqual.length"
        :dialogMoedaEmpresa.sync="dialogMoedaEmpresa"
        @fetch-moedas="getMoedas"
        @fetch-moedas-not-equal="getMoedasNotEqual"
        :moedasNotEqual="moedasNotEqual"
      />
    </v-col>
  </v-container>
</template>

<script>
import {
  fetchMoedasConfig,
  fetchMoedasNotEqual,
  deleteMoeda,
} from "@/api/moedas/moedas.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      dialogMoedaEmpresa: false,
      moedas: [],
      moedasNotEqual: [],
    };
  },

  components: {
    DialogMoeda: () => import("./DialogMoeda.vue"),
  },

  computed: {},

  methods: {
    addMoedaEmpresa() {
      if (this.moedasNotEqual.length) {
        this.dialogMoedaEmpresa = true;
      } else {
        this.$toast.warning("Todas moedas disponíveis, já estão cadastradas.");
      }
    },

    getMoedas() {
      fetchMoedasConfig()
        .then((reponse) => {
          this.moedas = reponse;
        })
        .catch(() => {});
    },

    getMoedasNotEqual() {
      fetchMoedasNotEqual()
        .then((reponse) => {
          this.moedasNotEqual = reponse;
        })
        .catch(() => {});
    },
    delMoeda(id) {
      if (this.moedas.length >= 2) {
        deleteMoeda(id)
          .then(() => {
            this.getMoedas();
            this.getMoedasNotEqual();
          })
          .catch(() => {});
      } else {
        this.$toast.warning(
          "Não é possivel deletar moeda cadastrada se ele for única!"
        );
      }
    },
  },
  mounted() {
    this.getMoedas();
    this.getMoedasNotEqual();
  },
};
</script>

<style></style>
