import { render, staticRenderFns } from "./ProdutosOrdemServico.vue?vue&type=template&id=0fab3b49&scoped=true&"
import script from "./ProdutosOrdemServico.vue?vue&type=script&lang=js&"
export * from "./ProdutosOrdemServico.vue?vue&type=script&lang=js&"
import style0 from "./ProdutosOrdemServico.vue?vue&type=style&index=0&id=0fab3b49&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fab3b49",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDataTable,VIcon,VProgressCircular,VTextField})
