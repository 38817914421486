import api from "../axios_service.js";

const fetchAntecipos = async (filtros) => {
  const response = await api.get(`/controle-antecipos${filtros ? filtros : ""}`);
  return response.data.result;
};

const fetchAntecipo = async (id) => {
  const response = await api.get(`/controle-antecipos/${id}`);
  return response.data.result;
};

const putAntecipo = async (id, body) => {
  return api.put(`/controle-antecipos/${id}`, body);
};

const postAntecipo = async (body) => {
  return api.post("/controle-antecipos/add", body);
};

export { fetchAntecipos, fetchAntecipo, putAntecipo, postAntecipo };
