var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageToolbar',{attrs:{"title":_vm.$tc('global.conta', 2) + ' ' + _vm.$tc('global.aPagar'),"icon":"mdi-cart-outline","dark":""}}),_c('v-card',[_c('v-card-title',{staticClass:"px-md-6 subtitle-1 tocs_gray_1"},[_c('Filtros',{attrs:{"P_periodo":"","P_moeda":"","P_status":"","P_statusArray":_vm.filterStatus}}),_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","solo":"","flat":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),(_vm.PContaPagarAdicionar)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"button_1","depressed":""},on:{"click":_vm.dialogContaPagarAdd}},[_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" ")]):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-6"},[_c('v-data-table',{staticClass:"data-tables",class:{
          'data-tables__row-click': _vm.PContaPagarEditar,
        },attrs:{"headers":_vm.headers,"items":_vm.contas,"search":_vm.search.length >= 3 ? _vm.search : '',"items-per-page":15,"loading":_vm.loading},on:{"click:row":_vm.dialogContaPagarEdit},scopedSlots:_vm._u([{key:"item.data_lancamento",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_lancamento,"dd/MM/yyyy"))+" ")]}},{key:"item.data_vencimento",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_vencimento,"dd/MM/yyyy"))+" ")]}},{key:"item.data_pagamento",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data_pagamento,"dd/MM/yyyy"))+" ")]}},{key:"item.desconto",fn:function(ref){
        var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")])]}},{key:"item.valor",fn:function(ref){
        var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.valor))+" ")])]}},{key:"item.valor_pago",fn:function(ref){
        var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.valor_pago))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.valor_pago))+" ")])]}},{key:"item.saldo",fn:function(ref){
        var item = ref.item;
return [(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.saldo))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.moeda_sigla)+" "+_vm._s(_vm._f("guarani")(item.saldo))+" ")])]}}])})],1)],1),(_vm.dialogContaPagar)?_c('DialogContaPagar',{attrs:{"dialogContaPagar":_vm.dialogContaPagar,"edit":_vm.edit,"conta_id":_vm.conta_id},on:{"update:dialogContaPagar":function($event){_vm.dialogContaPagar=$event},"update:dialog-conta-pagar":function($event){_vm.dialogContaPagar=$event},"fetch-contas":_vm.fetchContasPagar}}):_vm._e(),(_vm.dialogContaPagarViewr)?_c('DialogContaPagarViewr',{attrs:{"dialogContaPagarViewr":_vm.dialogContaPagarViewr,"conta_id":_vm.conta_id},on:{"update:dialogContaPagarViewr":function($event){_vm.dialogContaPagarViewr=$event},"update:dialog-conta-pagar-viewr":function($event){_vm.dialogContaPagarViewr=$event},"fetch-contas":_vm.fetchContasPagar}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }