<template>
  <v-dialog
    v-model="dialogAddPdfLayoutItem"
    @click:outside="$emit('update:dialogAddPdfLayoutItem', false)"
    @keydown.esc="$emit('update:dialogAddPdfLayoutItem', false)"
    width="800px"
    scrollable
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{ edit ? $tc("global.editar") : $tc("global.add") }}
        {{ $tc("global.pdf_layout_item") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddPdfLayoutItem', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-5 py-7">
        <v-row>
          <!-- Nome -->
          <v-col cols="12">
            <v-text-field
              v-model="pdf_layout_item.nome"
              :label="$tc('global.nome')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posição -->
          <!-- <v-col cols="12" md="6">
            <v-select
              v-model="pdf_layout_item.posicao"
              :items="positions"
              outlined
              clearable
              dense
              hide-details
              :label="$tc('global.posicao')"
            />
          </v-col> -->

          <!-- Grupo -->
          <!-- <v-col cols="12" md="6">
            <v-select
              v-model="pdf_layout_item.grupo"
              :items="grupos"
              outlined
              clearable
              dense
              hide-details
              :label="$tc('global.grupo')"
              @change="checkGrupo"
            />
          </v-col> -->

          <template v-if="pdf_layout_item.grupo != 'items'">
            <!-- ***** START Campos LABEL ***** -->
            <v-col cols="12">
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 pb-1 text-body-1"
              >
                {{ $tc("global.label") }}
              </v-subheader>
              <v-divider></v-divider>
            </v-col>

            <!-- LABEL Nome -->
            <v-col cols="12">
              <v-text-field
                v-model="pdf_layout_item.obj_label.text"
                :label="$tc('global.label')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Font Size -->
            <v-col cols="6" md="3">
              <v-text-field
                v-model.number="pdf_layout_item.obj_label.fontSize"
                :label="$tc('global.fontsize')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Length -->
            <v-col cols="6" md="2">
              <v-text-field
                v-model.number="pdf_layout_item.obj_label.length"
                :label="$tc('global.largura')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Posicoes -->
            <v-col cols="6" md="2">
              <v-text-field
                v-model.number="pdf_layout_item.obj_label.absolutePosition.x"
                label="X"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                v-model.number="pdf_layout_item.obj_label.absolutePosition.y"
                label="Y"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <!-- Status e Bold -->
            <v-col cols="6" md="1">
              <v-btn
                block
                small
                height="40"
                :depressed="!pdf_layout_item.obj_label.bold"
                :color="pdf_layout_item.obj_label.bold ? 'secondary' : ''"
                @click="
                  pdf_layout_item.obj_label.bold =
                    !pdf_layout_item.obj_label.bold
                "
              >
                <v-icon>mdi-format-bold</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" md="2">
              <v-btn
                block
                small
                height="40"
                :depressed="!pdf_layout_item.obj_label.status"
                :color="pdf_layout_item.obj_label.status ? 'secondary' : ''"
                @click="
                  pdf_layout_item.obj_label.status =
                    !pdf_layout_item.obj_label.status
                "
              >
                {{ pdf_layout_item.obj_label.status ? "ON" : "OFF" }}
              </v-btn>
            </v-col>

            <!-- ***** FIM Campos LABEL ***** -->
          </template>

          <!-- ***** START Campos DATA ***** -->
          <v-col cols="12">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 text-body-1"
            >
              {{ $tc("global.data") }}
            </v-subheader>
            <v-divider></v-divider>
          </v-col>

          <!-- Aletar DATA -->
          <v-col>
            <v-alert
              border="right"
              outlined
              colored-border
              type="error"
              elevation="0"
            >
              O valor do campo data abaixo deve ser exatamente o mesmo nome do
              campo da coluna no banco de dados. Caso nao saiba o nome do campo,
              entre em contato com o administrador do sistema.
            </v-alert>
          </v-col>

          <!-- DATA Nome -->
          <v-col cols="12">
            <v-text-field
              v-model="pdf_layout_item.obj_data.text"
              :label="$tc('global.data')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Font Size -->
          <v-col cols="6" md="3">
            <v-text-field
              v-model.number="pdf_layout_item.obj_data.fontSize"
              :label="$tc('global.fontsize')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Length -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model.number="pdf_layout_item.obj_data.length"
              :label="$tc('global.largura')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Posicoes -->
          <v-col cols="6" md="2">
            <v-text-field
              v-model.number="pdf_layout_item.obj_data.absolutePosition.x"
              label="X"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              v-model.number="pdf_layout_item.obj_data.absolutePosition.y"
              label="Y"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Status e Bold -->
          <v-col cols="6" md="1">
            <v-btn
              block
              small
              height="40"
              :depressed="!pdf_layout_item.obj_data.bold"
              :color="pdf_layout_item.obj_data.bold ? 'secondary' : ''"
              @click="
                pdf_layout_item.obj_data.bold = !pdf_layout_item.obj_data.bold
              "
            >
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" md="2">
            <v-btn
              block
              small
              height="40"
              :depressed="!pdf_layout_item.obj_data.status"
              :color="pdf_layout_item.obj_data.status ? 'secondary' : ''"
              @click="
                pdf_layout_item.obj_data.status =
                  !pdf_layout_item.obj_data.status
              "
            >
              {{ pdf_layout_item.obj_data.status ? "ON" : "OFF" }}
            </v-btn>
          </v-col>

          <!-- ***** FIM Campos DATA ***** -->
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="px-5 py-4">
        <v-btn
          color="primary"
          outlined
          @click="$emit('update:dialogAddPdfLayoutItem', false)"
        >
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="edit ? editPdfLayoutItem() : addPdfLayoutItem()"
          :disabled="disableAdd"
        >
          {{ edit ? $tc("global.atualizar") : $tc("global.salvar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  putPdfLayoutItem,
  postPdfLayoutItem,
} from "@/api/pdf-layout-items/pdf_layout_items.js";

export default {
  name: "AddPdfLayoutItem",

  props: {
    dialogAddPdfLayoutItem: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    pdfLayout: {
      type: Object,
      required: true,
    },
    posicao: {
      type: [Number, String],
    },
    grupo: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      pdf_layout_item: {
        layout_id: this.pdfLayout.id,
        nome: "",
        posicao: null,
        grupo: null,
        obj_label: {
          text: "",
          fontSize: null,
          length: null,
          bold: false,
          status: null,
          absolutePosition: {
            x: null,
            y: null,
          },
        },
        obj_data: {
          text: "",
          fontSize: null,
          length: null,
          bold: false,
          status: null,
          absolutePosition: {
            x: null,
            y: null,
          },
        },
      },
    };
  },

  computed: {
    positions() {
      let positions = [];

      if (this.pdfLayout.obj_imagem1 && this.pdfLayout.obj_imagem1.status) {
        positions.push({
          text: "Imagem 1",
          value: 1,
        });
      }

      if (this.pdfLayout.obj_imagem2 && this.pdfLayout.obj_imagem2.status) {
        positions.push({
          text: "Imagem 2",
          value: 2,
        });
      }

      if (this.pdfLayout.obj_imagem3 && this.pdfLayout.obj_imagem3.status) {
        positions.push({
          text: "Imagem 3",
          value: 3,
        });
      }

      return positions;
    },

    grupos() {
      return [
        {
          text: "Cabeçalho",
          value: "cabecalho",
        },
        {
          text: "Items",
          value: "items",
        },
        {
          text: "Rodapé",
          value: "rodape",
        },
      ];
    },

    disableAdd() {
      let result = false;
      const l_i = this.pdf_layout_item;

      if (
        !l_i.nome ||
        !l_i.posicao ||
        !l_i.grupo ||
        !l_i.obj_data.text ||
        !l_i.obj_data.absolutePosition.x ||
        !l_i.obj_data.absolutePosition.y
      ) {
        result = true;
      }

      if (this.loading) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    checkGrupo() {
      if (this.pdf_layout_item.grupo === "items") {
        this.pdf_layout_item.obj_label = {
          text: "",
          fontSize: null,
          length: null,
          bold: false,
          status: null,
          absolutePosition: {
            x: null,
            y: null,
          },
        };
      }
    },

    editPdfLayoutItem() {
      this.loading = true;
      putPdfLayoutItem(this.pdf_layout_item.id, this.pdf_layout_item)
        .catch((error) => {
          this.$toast.error(error.response.data.error_message);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("update:dialogAddPdfLayoutItem", false);
          this.$emit("fetch-layout-items");
        });
    },

    addPdfLayoutItem() {
      this.loading = true;
      postPdfLayoutItem(this.pdf_layout_item)
        .catch((error) => {
          this.$toast.error(error.response.data.error_message);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("update:dialogAddPdfLayoutItem", false);
          this.$emit("fetch-layout-items");
        });
    },
  },

  mounted() {
    if (this.posicao) {
      this.pdf_layout_item.posicao = this.posicao;
    }
    if (this.grupo) {
      this.pdf_layout_item.grupo = this.grupo;
    }
    if (this.edit) {
      this.pdf_layout_item = this.item;
    }
  },
};
</script>

<style></style>
