<template>
  <v-dialog
    v-model="dialogTimbrado"
    @click:outside="$emit('update:dialogTimbrado', false)"
    @keydown.esc="$emit('update:dialogTimbrado', false)"
    width="650px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.novo") + " " + $tc("global.timbrado")
            : $tc("global.atualizar") + " " + $tc("global.timbrado")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogTimbrado', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row class="pa-4">
            <v-col cols="12" md="6">
              <v-select
                v-model="selectedTipo"
                :label="$tc('global.tipo') + ' ' + $tc('global.timbrado')"
                :items="tipos_timbrado"
                dense
                hide-details
                outlined
                return-object
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                v-model="timbrado.timbrado"
                :label="$tc('global.timbrado')"
                dense
                v-mask="`########`"
                outlined
                hide-details
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <DataField
                :data_sync.sync="timbrado.data_inicio"
                :label="$tc('global.datainicio')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <DataField
                :data_sync.sync="timbrado.data_fim"
                :label="$tc('global.datafim')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sequencia_inicio"
                :label="$tc('global.sequenciainicio')"
                @blur="maskSequencia('sequencia_inicio', sequencia_inicio)"
                :loading="sequencia_loading"
                v-mask="`#######`"
                dense
                outlined
                :rules="formRules"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sequencia_fim"
                :label="$tc('global.sequenciafim')"
                @blur="maskSequencia('sequencia_fim', sequencia_fim)"
                :loading="sequencia_loading"
                v-mask="`#######`"
                dense
                outlined
                :rules="formRules"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="sequencia_atual"
                :label="$tc('global.ultimanumeracaoemitida')"
                @blur="maskSequencia('sequencia_atual', sequencia_atual)"
                :loading="sequencia_loading"
                v-mask="`#######`"
                dense
                outlined
                :rules="formRules"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="timbrado.ponto"
                :label="$tc('global.ponto')"
                dense
                v-mask="`###-###`"
                outlined
                hide-details
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>
            <!-- autoimpressor -->
            <v-col cols="12" md="6">
              <v-card
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-card-text> {{ $tc("global.autoimpressor") }}: </v-card-text>
                <v-switch
                  :false-value="false"
                  :true-value="true"
                  v-model="timbrado.auto_impressor"
                ></v-switch>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="timbrado.descricao"
                :label="$tc('global.descricao')"
                dense
                outlined
                hide-details
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>
            <!-- manual -->
            <v-col cols="12" md="12">
              <v-card
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-card-text> Manual: </v-card-text>
                <v-switch
                  v-model="timbrado.manual"
                  :false-value="false"
                  :true-value="true"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          @click:outside="$emit('update:dialogTimbrado', false)"
        >
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createTimbrado() : updateTimbrado()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { postTimbrado, putTimbrado } from "@/api/timbrado/timbrado.js";

export default {
  name: "DialogTimbrado",

  props: {
    dialogTimbrado: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
    },
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      sequencia_loading: true,
      timbrado: {
        data_inicio: null,
        data_fim: null,
      },
      sequencia_inicio: null,
      sequencia_fim: null,
      sequencia_atual: null,
      ponto: null,
      timbrado_original: {},
      loading: true,
      validForm: true,
      hoverFile: false,
      selectedTipo: {
        value: 1,
        text: "Fatura",
      },
      formRules: [inputRequired],
      tipos_timbrado: [
        {
          value: 1,
          text: "Fatura",
        },
        {
          value: 2,
          text: "Nota Crédito",
        },
      ],
    };
  },

  watch: {
    selectedTipo: {
      handler() {
        this.timbrado.tipo = this.selectedTipo.value;
      },
      immediate: true,
    },
  },
  methods: {
    maskSequencia(how, value) {
      if (value) {
        if (how === "sequencia_inicio") {
          this.timbrado.sequencia_inicio = Number(
            value.toString().replace(/[^0-9]/g, "0")
          );
          this.sequencia_inicio = value.toString().padStart(7, "0");
        } else if (how === "sequencia_fim") {
          this.timbrado.sequencia_fim = Number(
            value.toString().replace(/[^0-9]/g, "0")
          );
          this.sequencia_fim = value.toString().padStart(7, "0");
        } else if (how === "sequencia_atual") {
          this.timbrado.sequencia_atual = Number(
            value.toString().replace(/[^0-9]/g, "0")
          );
          this.sequencia_atual = value.toString().padStart(7, "0");
        }
      }
    },
    createTimbrado() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        postTimbrado(this.timbrado)
          .then(() => {
            this.$emit("fetch-timbrados");
            this.$emit("update:dialogTimbrado", false);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error("Timbrado já existe!");
            }
            console.log(error.response);
          })
          .finally(() => {
            this.loading = false;
            // this.isAdding = false;
          });
      }
    },

    updateTimbrado() {
      if (
        JSON.stringify(this.timbrado) === JSON.stringify(this.timbrado_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let timbrado = {};

        timbrado.tipo = this.timbrado.tipo;
        timbrado.data_inicio = this.timbrado.data_inicio;
        timbrado.data_fim = this.timbrado.data_fim;
        timbrado.sequencia_inicio = this.timbrado.sequencia_inicio;
        timbrado.sequencia_fim = this.timbrado.sequencia_fim;
        timbrado.sequencia_atual = this.timbrado.sequencia_atual;
        timbrado.ponto = this.timbrado.ponto;
        timbrado.timbrado = this.timbrado.timbrado;
        timbrado.auto_impressor = this.timbrado.auto_impressor;
        timbrado.manual = this.timbrado.manual;
        timbrado.descricao = this.timbrado.descricao;

        putTimbrado(this.timbrado.id, timbrado)
          .then(() => {
            this.timbrado_original = { ...this.timbrado };
            this.$emit("fetch-timbrados");
            this.$emit("update:dialogTimbrado", false);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  async mounted() {
    if (this.edit) {
      this.timbrado = { ...this.item };
      this.maskSequencia("sequencia_inicio", this.timbrado.sequencia_inicio);
      this.maskSequencia("sequencia_fim", this.timbrado.sequencia_fim);
      this.maskSequencia("sequencia_atual", this.timbrado.sequencia_atual);
      this.timbrado_original = { ...this.item };
      this.sequencia_loading = false;
    } else {
      this.sequencia_loading = false;
    }
  },
};
</script>

<style></style>
