<template>
  <v-dialog
    v-model="dialogDeleteDespesa"
    @click:outside="$emit('update:dialogDeleteDespesa', false)"
    @keydown.esc="$emit('update:dialogDeleteDespesa', false)"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        {{ $tc("global.deletarDespesa") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogDeleteDespesa', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-actions class="d-flex  pa-4">
        <v-spacer></v-spacer>
        <v-btn small dark @click="deletarDespesa" color="tocs_red">
          <v-icon left>mdi-delete-outline</v-icon> {{ $tc("global.del") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteDespesa } from "@/api/compras/compras_despesas.js";
export default {
  name: "DialogDeleteDespesa",

  props: {
    dialogDeleteDespesa: {
      type: Boolean,
      default: false,
    },
    despesa_id: {
      type: Number,
    },
  },

  methods: {
    deletarDespesa() {
      deleteDespesa(this.despesa_id).then((response) => {
        this.$emit("update:dialogDeleteDespesa", false);
        this.$emit("fetch-despesas");
      });
    },
  },
};
</script>

<style></style>
