<template>
  <v-dialog
    v-model="precoHistoricoDialog"
    @click:outside="$emit('update:precoHistoricoDialog', false)"
    max-width="450"
  >
    <v-card tile>
      <v-card-title class="text-subtitle-1 lightgray">
        {{ $tc("global.historicoPreco") }} {{ preco.moeda_sigla }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:precoHistoricoDialog', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-3">
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="precos"
          :items-per-page="10"
          disable-sort
          class="data-tables data-tables__row-click"
          :hide-default-footer="precos.length > 10 ? false : true"
        >
          <template v-slot:item.valor="{ item }">
            {{
              item.valor
                | currency({
                  thousandsSeparator: item.moeda_separador_milhar,
                  fractionCount: item.moeda_contador_decimal,
                  fractionSeparator: item.moeda_separador_decimal,
                })
            }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-icon size="28" v-if="item.status === -1"
              >mdi-toggle-switch</v-icon
            >
            <v-icon size="28" color="tocs_blue_1" v-if="item.status === 1">
              mdi-toggle-switch-off
            </v-icon>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
          </template>
        </v-data-table>
        <Carregando v-else />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchProdListasPrecos } from "@/api/produtos/prodlistasprecos.js";

export default {
  name: "PrecoHistorico",

  props: {
    precoHistoricoDialog: {
      type: Boolean,
      required: true,
    },
    preco: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      precos: [],
      loading: false,
    };
  },

  computed: {
    produto_id() {
      return this.$route.params.produtoId;
    },
    headers() {
      return [
        {
          text: "moeda",
          divider: true,
          align: "center",
          value: "moeda_sigla",
        },
        {
          text: "valor",
          divider: true,
          align: "right",
          value: "valor",
        },
        {
          text: "status",
          divider: true,
          align: "center",
          value: "status",
        },
        {
          text: "Data",
          value: "created_at",
        },
      ];
    },
  },

  methods: {
    getPrecos() {
      this.loading = true;
      let filtro = `?lista_preco_id=${this.preco.lista_preco_id}&moeda_id=${this.preco.moeda_id}&produto_id=${this.produto_id}`;
      return fetchProdListasPrecos(filtro)
        .then((response) => {
          this.precos = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getPrecos();
  },
};
</script>

<style></style>
