<template>
  <v-autocomplete
    v-model="cliente"
    :items="clientes"
    @keyup="buscaClientes"
    :search-input.sync="search"
    clearable
    @click:clear="clearCliente"
    dense
    outlined
    hide-details
    hide-no-data
    hide-selected
    item-text="nome"
    item-value="id"
    :label="$tc('global.cliente')"
    :placeholder="$tc('global.buscarCliente')"
    prepend-inner-icon="mdi-magnify"
  ></v-autocomplete>
</template>

<script>
import { searchClientes } from "@/api/clientes/clientes.js";

export default {
  name: "Cliente",

  data() {
    return {
      cliente: null,
      clientes: [],
      search: "",
    };
  },

  computed: {
    empresa() {
      return this.$store.state.empresa;
    },
  },

  watch: {
    cliente() {
      if (this.cliente) {
        this.$store.commit("FiltrosRelatorios/UPDATE_CLIENTE", this.cliente);
      }
    },
  },

  methods: {
    clearCliente() {
      this.$store.commit("FiltrosRelatorios/UPDATE_CLIENTE", null);
      this.cliente = null;
    },
    getClientes(filtros) {
      this.clientes = null;
      return searchClientes(filtros)
        .then((response) => {
          this.clientes = response;
        })
        .catch((error) => console.log(error));
    },
    buscaClientes(event) {
      if (this.search) {
        if (
          this.search.length > 2 &&
          event.key != "Backspace"
          // this.empresa != 3
        ) {
          this.getClientes(`?search=${this.search}`);
        }
      }
    },
  },

  mounted() {
    if (this.$route.query.cliente) {
      let filtro = `?search=id=${this.$route.query.cliente}`;
      searchClientes(filtro)
        .then((response) => {
          this.clientes = response;
          this.cliente = response[0].id;
        })
        .catch(() => {});
    }
  },
};
</script>

<style></style>
