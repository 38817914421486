import api from "../axios_service";
export async function fetchInventario(filtros) {
  const response = await api.get(
    `/relatorios/inventario${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchListaProdutos(filtros) {
  const response = await api.get(
    `/relatorios/produtos-lista${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchListaProdutosComValor(filtros) {
  const response = await api.get(
    `/relatorios/produtos-lista-com-valor${filtros ? filtros : ""}`
  );
  return response.data.result;
}
