<template>
  <v-expansion-panels class="mb-4" flat v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header
        style="min-height: 40px"
        class="py-0"
        color="lightgray"
      >
        <div>
          <v-icon left>mdi-filter-outline</v-icon>
          {{ this.$tc("global.filtrosAvancados") }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-divider></v-divider>
        <v-container fluid class="pb-2 pt-6 px-5">
          <v-row>
            <v-col cols="12" md="auto" v-if="P_periodo">
              <DataFrom :key="componentKey" />
            </v-col>

            <v-col cols="12" md="auto" v-if="P_periodo">
              <DataTo :key="componentKey" />
            </v-col>

            <!-- <v-col cols="12" md="auto" v-if="P_cliente">
              <Cliente :key="componentKey" />
            </v-col> -->

            <v-col cols="12" md="auto" v-if="P_status && P_statusArray">
              <Status :key="componentKey" :campos="P_statusArray" />
            </v-col>

            <v-col cols="12" md="auto" v-if="P_moeda">
              <Moedas :key="componentKey" />
            </v-col>

            <!-- <v-col cols="12" md="auto" v-if="P_cidade">
              <Cidades :key="componentKey" />
            </v-col> -->

            <!-- <v-col cols="12" md="auto" v-if="P_custom">
              <slot name="custom"></slot>
            </v-col> -->

            <!-- BOTOES -->
            <v-col cols="12" class="d-flex align-center justify-end">
              <v-btn
                text
                small
                class="mr-3"
                @click="resetQuery"
                :disabled="queryEmpty"
              >
                <v-icon left color="buttons2">mdi-arrow-u-left-top</v-icon>
                {{ this.$tc("global.resetar") }}
              </v-btn>

              <v-btn text small @click="filtrar" :disabled="!valid">
                <v-icon left color="globalBlue">mdi-filter-outline</v-icon>
                {{ this.$tc("global.filtrar") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Filtros",
  props: {
    P_periodo: {
      type: Boolean,
      default: false,
    },
    P_cidade: {
      type: Boolean,
      default: false,
    },
    P_status: {
      type: Boolean,
      default: false,
    },
    P_statusArray: {
      type: Array,
    },
    P_cliente: {
      type: Boolean,
      default: false,
    },
    P_moeda: {
      type: Boolean,
      default: false,
    },
    P_custom: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataFrom: () =>
      import(/* webpackChunkName: "filtros" */ "./components/DataFrom"),
    DataTo: () =>
      import(/* webpackChunkName: "filtros" */ "./components/DataTo"),
    // Cliente: () =>
    //   import(/* webpackChunkName: "filtros" */ "./components/Cliente"),
    Status: () =>
      import(/* webpackChunkName: "filtros" */ "./components/Status"),
    Moedas: () =>
      import(/* webpackChunkName: "filtros" */ "./components/Moedas"),
    // Cidades: () =>
    //   import(/* webpackChunkName: "filtros" */ "./components/Cidades"),
  },
  data() {
    return {
      showFilters: true,
      componentKey: 0,
      panel: null,
    };
  },
  computed: {
    ...mapState("Filtros", {
      filtros: (state) => state.filtros,
    }),
    queryEmpty() {
      return this.lodash.isEmpty(this.$route.query);
    },
    valid() {
      let value = false;
      for (let key in this.filtros) {
        if (this.filtros[key]) {
          value = true;
        }
      }
      return value;
    },
    route() {
      return this.$route;
    },
  },
  watch: {
    // filtros: {
    //   handler() {
    //   },
    //   deep: true,
    // },
  },
  methods: {
    ...mapActions("Filtros", {
      reset: "reset",
    }),
    reloadComponent() {
      this.componentKey = this.componentKey + 1;
    },
    filtrar() {
      let filtro = {};
      for (let key in this.filtros) {
        if (this.filtros[key]) {
          filtro[key] = this.filtros[key];
        }
      }
      if (this.lodash.isEqual(this.$route.query, filtro)) return;
      this.$router.push({ query: filtro });
    },
    getAll() {
      if (this.lodash.isEqual(this.$route.query, { load: "all" })) return;
      this.$router.push({ query: { load: "all" } });
    },
    resetQuery() {
      this.reloadComponent();
      this.$router.replace({ query: null });
      this.reset();
    },
  },
  mounted() {
    if (!this.queryEmpty) {
      this.panel = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
.theme--light ::v-deep .v-expansion-panel:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) !important;
}
.theme--dark ::v-deep .v-expansion-panel:before {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.13) !important;
}
</style>
