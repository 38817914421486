import api from "../axios_service.js";

export async function getFornecedoresCategorias(filtros) {
  const response = await api.get(
    `/fornecedores-categorias${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getFornecedoresCategoriasAtivo(filtros) {
  const response = await api.get(
    `/fornecedores-categorias/ativo${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getListFornecedoresCategorias(filtros) {
  const response = await api.post(`/listafornecedores-categorias${filtros}`);
  return response.data.result;
}

// Busca um fornecedores
export async function getFornecedorCategorias(id) {
  const response = await api.get(`/fornecedores-categorias/${id}`);
  return response.data.result;
}

// Atualiza um fornecedor existente
export function putFornecedoresCategorias(id, fornecedor_categoria) {
  let body = {};
  for (let key in fornecedor_categoria) {
    body[key] = fornecedor_categoria[key];
  }
  return api.put(`/fornecedores-categorias/${id}`, body);
}

// Cria um fornecedor novo
export function postFornecedorCategoria(fornecedor_categoria) {
  let body = {};
  for (let key in fornecedor_categoria) {
    body[key] = fornecedor_categoria[key];
  }
  return api.post("/fornecedores-categorias/add", body);
}
