<template>
  <v-dialog
    v-model="dialogComprasTiposDespesas"
    @click:outside="$emit('update:dialogComprasTiposDespesas', false)"
    @keydown.esc="$emit('update:dialogComprasTiposDespesas', false)"
    width="700px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? `${$tc("global.add")} ${$tc("global.planoDeGastos")}`
            : `${$tc("global.atualizar")} ${$tc("global.planoDeGastos")}`
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogComprasTiposDespesas', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="tipos_despesas.ordem"
                :label="$tc('global.ordem')"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="tipos_despesas.descricao"
                :label="$tc('global.descricao')"
                dense
                outlined
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>

            <!-- Status -->
            <v-col v-if="edit" cols="12">
              <v-text-field
                outlined
                dense
                readonly
                :value="$tc('global.status') + ':'"
              >
                <template v-slot:append>
                  <v-switch
                    v-model="tipos_despesas.status"
                    :false-value="0"
                    :true-value="1"
                    class="mt-0 pt-0"
                    hide-details
                  >
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          @click="$emit('update:dialogComprasTiposDespesas', false)"
        >
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="secondary"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createTipoDespesa() : updateTipoDespesa()"
        >
          {{ !edit ? $tc("global.add") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  putComprasTiposDespesas,
  postComprasTiposDespesas,
} from "@/api/compras/compras_tipos_despesas.js";
export default {
  name: "DialogComprasTiposDespesas",

  props: {
    dialogComprasTiposDespesas: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      loading: true,
      validForm: true,
      formRules: [inputRequired],
      tipos_despesas: {
        descricao: "",
        tipo: "",
      },
      tipos_despesas_original: {},
      editItem: null,
    };
  },

  components: {},

  computed: {},

  methods: {
    updateTipoDespesa() {
      if (
        JSON.stringify(this.tipos_despesas) ===
        JSON.stringify(this.tipos_despesas_original)
      ) {
        this.$toast.error(this.$tc("global.fazermudanca"));
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let tipos_despesas = {};
        tipos_despesas.descricao = this.tipos_despesas.descricao;
        tipos_despesas.ordem = this.tipos_despesas.ordem;
        tipos_despesas.status = this.tipos_despesas.status;

        putComprasTiposDespesas(this.tipos_despesas.id, tipos_despesas)
          .then(() => {
            this.$emit("getComprasTiposDespesas");
            this.$emit("update:dialogComprasTiposDespesas", false);
            
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error(this.$tc("global.jaexiste"));
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    createTipoDespesa() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let tipos_despesas = {};
        tipos_despesas.descricao = this.tipos_despesas.descricao;
        tipos_despesas.ordem = this.tipos_despesas.ordem;
        tipos_despesas.status = this.tipos_despesas.status;

        postComprasTiposDespesas(tipos_despesas)
          .then(() => {
            this.$emit("getComprasTiposDespesas");
            this.$emit("update:dialogComprasTiposDespesas", false);
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error(this.$tc("global.jaexiste"));
            }
          })
          .finally(() => {
            this.loading = false;
            this.isAdding = false;
          });
      }
    },
  },

  mounted() {
    if (this.edit) {
      this.tipos_despesas = { ...this.item };
      this.tipos_despesas_original = { ...this.item };
    }
  },
};
</script>

<style lang="scss" scoped></style>
