<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.compensacaoBanco')"
      icon="mdi-bank-check"
      dark
    />
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>

        <v-btn-toggle mandatory v-model="toggle_banco" class="ml-3">
          <v-btn value="abertos" class="text-caption" height="40px">
            {{ $tc("global.pendentes") }}
          </v-btn>
          <v-btn value="compensados" class="text-caption" height="40px">
            {{ $tc("global.compensacao", 2) }}
          </v-btn>
        </v-btn-toggle>

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          :disabled="!selectedBancos.length"
          color="button_1"
          @click="dialogCompensacaoBanco = true"
          class="white--text mt-4 mt-sm-0 mx-2"
        >
          <v-icon left>mdi-cash-sync</v-icon>
          {{ $tc("global.compensar") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-data-table
          v-model="selectedBancos"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="compBancos"
          :loading="loadingSelectedBancos || loading"
          :items-per-page="15"
          class="data-tables"
          :class="{ 'data-tables__row-click': CompensacaoBancoVisualizar }"
          disable-sort
          :show-select="toggle_banco == 'abertos' ? true : false"
          @item-selected="bancoSelected"
          @toggle-select-all="bancosSelected"
        >
          <template v-slot:item.valor="{ item }">
            <div v-if="item.moeda_sigla == 'G$'">
              {{ item.valor | guarani }}
            </div>
            <div v-else>
              {{ item.valor | currency }}
            </div>
          </template>

          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 2">{{
              $tc("global.compensacao", 2)
            }}</span>
            <span v-if="item.status === 1">
              {{ $tc("global.pendentes") }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogCompensacaoBancos
      v-if="dialogCompensacaoBanco"
      :dialogCompensacaoBanco.sync="dialogCompensacaoBanco"
      :selectedBancos="selectedBancos"
      @fetch-bancos="getCompBancos"
    />
  </v-container>
</template>

<script>
import { fetchCompBancos } from "@/api/compensacao-bancos/compensacao_bancos.js";
import { mapGetters } from "vuex";

export default {
  name: "CompensacaoBancos",

  components: {
    DialogCompensacaoBancos: () =>
      import("./components/DialogCompensacaoBancos.vue"),
  },

  data() {
    return {
      search: "",
      loading: false,
      compBancos: [],
      banco: {},
      selectedBancos: [],
      loadingSelectedBancos: false,
      dialogCompensacaoBanco: false,
      toggle_banco: "abertos",
      lengthSelected: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    CompensacaoBancoVisualizar() {
      return this.getAccess("CompensacaoBanco", "visualizar");
    },

    headers() {
      return [
        {
          text: this.$tc("global.caixa"),
          value: "caixa_descricao",
          sortable: false,
          // width: "70px",
        },
        {
          text: this.$tc("global.documento"),
          value: "documento",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.cliente"),
          value: "cliente_nome",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
          sortable: false,
          // width: "180px",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          // width: "100px",
        },
      ];
    },
  },

  watch: {
    toggle_banco() {
      this.getCompBancos();
    },
  },

  methods: {
    bancoSelected({ item, value }) {
      if (value) {
        this.compBancos.map((banco) => {
          if (banco.caixa_id != item.caixa_id) {
            banco.isSelectable = false;
          } else {
            banco.isSelectable = true;
          }
        });
      } else {
        let removeIndex = this.selectedBancos.indexOf(item);
        this.selectedBancos.splice(removeIndex, 1);

        if (this.selectedBancos.length == 0) {
          this.compBancos.map((banco) => {
            banco.isSelectable = true;
          });
        }
      }
    },

    bancosSelected({ items, value }) {
      if (value) {
        this.compBancos.map((banco) => {
          if (banco.caixa_id != items[0].caixa_id) {
            banco.isSelectable = false;
          } else {
            banco.isSelectable = true;
          }
        });
        this.$nextTick(() => {
          for (let index = 0; index < this.selectedBancos.length; index++) {
            if (this.selectedBancos[index].caixa_id != items[0].caixa_id) {
              this.selectedBancos.splice(index, 1);
              index--;
            }
          }
        });
      } else {
        this.selectedBancos = [];
        this.compBancos.map((banco) => {
          banco.isSelectable = true;
        });
      }
    },

    async getCompBancos() {
      this.loading = true;
      this.compBancos = [];
      this.selectedBancos = [];
      return fetchCompBancos(`?toggle_banco=${this.toggle_banco}`)
        .then((response) => {
          this.compBancos = response;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getCompBancos();
  },
};
</script>

<style></style>
