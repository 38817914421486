<template>
  <v-dialog v-model="dialogTiposDespesas" max-width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="button_2" dark v-bind="attrs" v-on="on">
        {{ $tc("global.planoDeGastos") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{ $tc("global.planoDeGastos") }}

        <v-spacer></v-spacer>

        <v-btn
          color="primary white--text"
          v-if="PDespesaAdicionar"
          @click="addTipoDespesa"
        >
          {{ $tc("global.add") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>

        <DialogComprasTiposDespesas
          v-if="dialogComprasTiposDespesas"
          :dialogComprasTiposDespesas.sync="dialogComprasTiposDespesas"
          @getComprasTiposDespesas="getComprasTiposDespesas"
          :edit="edit_tipo_despesa"
          :item="tipo_despesa"
        />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-data-table
          :headers="headers"
          :items="compras_tipos_despesas"
          :items-per-page="-1"
          class="data-tables"
          :class="{ 'data-tables__row-click': PDespesaEditar }"
          @click:row="editTipoDespesa"
        >
          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 0">{{ $tc("global.inativo") }}</span>
            <span v-if="item.status === 1">{{ $tc("global.ativo") }} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchAllComprasTiposDespesas } from "@/api/compras/compras_tipos_despesas.js";

export default {
  components: {
    DialogComprasTiposDespesas: () =>
      import("./components/DialogComprasTiposDespesas.vue"),
  },

  data() {
    return {
      dialogTiposDespesas: false,
      compras_tipos_despesas: [],
      edit_tipo_despesa: false,
      dialogComprasTiposDespesas: false,
      tipo_despesa: {},
    };
  },

  watch: {
    dialogTiposDespesas() {
      if (this.dialogTiposDespesas) {
        this.getComprasTiposDespesas();
      }
    },
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PDespesaAdicionar() {
      return this.getAccess("PlanoDespesas", "adicionar");
    },
    PDespesaEditar() {
      return this.getAccess("PlanoDespesas", "editar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.id"),
          value: "id",
        },
        {
          text: this.$tc("global.ordem"),
          value: "ordem",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
      ];
    },
  },

  methods: {
    async getComprasTiposDespesas() {
      const response = await fetchAllComprasTiposDespesas();
      this.compras_tipos_despesas = response;
    },

    addTipoDespesa() {
      this.edit_tipo_despesa = false;
      this.dialogComprasTiposDespesas = true;
    },

    editTipoDespesa(item) {
      if (this.PDespesaEditar) {
        this.edit_tipo_despesa = true;
        this.tipo_despesa = item;
        this.dialogComprasTiposDespesas = true;
      }
    },
  },

  mounted() {},
};
</script>

<style></style>
