import api from "../axios_service";

export async function fetchSaldoCaixa(filtros) {
    const response = await api.get(
        `/relatorios/caixa-saldo${filtros ? filtros : ""}`
    );
    return response.data;
}

export async function fetchExtratoCaixa(filtros) {
    const response = await api.get(
        `/relatorios/caixa-extrato${filtros ? filtros : ""}`
    );
    return response.data.result;
}