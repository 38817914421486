import api from "../axios_service.js";

export async function index(filtros) {
  const response = await api.get(
    `/empresas-variaveis${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function show(id) {
  const response = await api.get(`/empresas-variaveis/${id}`);
  return response.data.result;
}

export function update(id, body) {
  return api.put(`/empresas-variaveis/${id}`, body);
}

export function create(body) {
  return api.post("/empresas-variaveis/add", body);
}

export function remove(id) {
  return api.delete(`/empresas-variaveis/${id}`);
}

export async function getContratoPiscina() {
  const response = await api.get(`/contrato-piscina`);
  return response.data.result;
}
