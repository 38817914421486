<template>
  <v-container fluid>
    <PageToolbar
      :title="`${caixa_id}${caixa ? ' - ' + caixa.descricao : ''}`"
      icon="mdi-cash-register"
      dark
      voltar
    />

    <v-card>
      <v-card-title class="d-block">
        <div class="d-flex">
          <!--------------------------------------------------------------------------------->
          <!--BOTAO DO DIALOG DE GASTOS -->
          <v-btn
            color="tocs_gray_1"
            class="ml-4 vertical-button"
            @click="addCaixaAfItem"
            small
          >
            <v-icon class="pt-2" color="button_1" size="35">mdi-plus</v-icon>
            <span class="pb-2 font-weight-bold"> Gastos </span>
          </v-btn>
          <DialogCaixasAfItem
            v-if="dialogCaixasAfItem"
            :dialogCaixasAfItem.sync="dialogCaixasAfItem"
            @fetch-caixas-items="getRegistros"
          />
          <!--------------------------------------------------------------------------------->
          <!--BOTAO DO DIALOG DE VENDAS -->
          <v-btn
            color="tocs_gray_1"
            class="ml-4 vertical-button"
            small
            @click="dialogVendasCaixa = !dialogVendasCaixa"
          >
            <v-icon class="pt-2" color="button_2" size="35"
              >mdi-tag-outline</v-icon
            >
            <span class="pb-2 font-weight-bold">Vendas</span>
          </v-btn>

          <DialogVendasCaixa
            v-if="dialogVendasCaixa"
            :dialogVendasCaixa.sync="dialogVendasCaixa"
            @fetch-caixas-items="getRegistros"
          />
          <!--------------------------------------------------------------------------------->
          <!--BOTAO DO DIALOG DE CONTAS A RECEBER -->
          <v-btn
            color="tocs_gray_1"
            class="ml-4 vertical-button"
            @click="abrirReceberConta"
            small
          >
            <v-icon class="pt-2" color="tocs_gray_2" size="35"
              >mdi-account-cash-outline
            </v-icon>
            <span class="body font-weight-bold">{{
              $tc("global.conta", 2)
            }}</span>
            <span class="pb-2 body font-weight-bold">{{
              $tc("global.receber")
            }}</span>
          </v-btn>
          <!--------------------------------------------------------------------------------->
          <!--BOTAO DO DIALOG DE CONTAS A PAGAR -->
          <v-btn
            color="tocs_gray_1"
            class="ml-4 vertical-button"
            @click="abrirPagarConta"
            small
          >
            <v-icon class="pt-2" color="tocs_gray_2" size="35"
              >mdi-cash-minus
            </v-icon>
            <span class="body font-weight-bold">{{
              $tc("global.conta", 2)
            }}</span>
            <span class="pb-2 font-weight-bold">{{ $tc("global.pagar") }}</span>
          </v-btn>
          <DialogBaixaContas
            v-if="dialogBaixaContas"
            :dialogBaixaContas.sync="dialogBaixaContas"
            :tipo_baixa="tipo_baixa"
            @fetch-movimentacoes="getRegistros"
          />
          <v-spacer></v-spacer>
          <!--------------------------------------------------------------------------------->
          <!--BOTAO DO DIALOG DE TRANSFERENCIA -->
          <v-btn
            color="tocs_gray_1"
            class="ml-4 vertical-button"
            :class="{ 'px-15': sizeButtomTransf }"
            @click="dialogTransferencia = !dialogTransferencia"
            small
          >
            <v-icon class="pt-1" color="tocs_green" size="35">
              mdi-swap-horizontal
            </v-icon>
            <span class="pb-2 font-weight-bold">
              {{ $tc("global.transferencia") }}
            </span>
          </v-btn>

          <DialogTransferencia
            v-if="dialogTransferencia"
            :dialogTransferencia.sync="dialogTransferencia"
            :caixaPdv="true"
            @fetch-caixas-items="getRegistros"
          />
          <!--------------------------------------------------------------------------------->
          <!--BOTAO DO DIALOG DE SALDO -->
          <v-btn
            color="tocs_gray_1"
            class="ml-4 vertical-button"
            @click="dialogTotaisCaixa = !dialogTotaisCaixa"
            small
          >
            <v-icon class="pt-1" color="tocs_green" size="35"
              >mdi-cash-multiple</v-icon
            >
            <span class="pb-2 font-weight-bold">{{ $tc("global.saldo") }}</span>
          </v-btn>
        </div>
      </v-card-title>
      <!--TABELA DE REGISTRO DO CAIXA -->
      <v-card-text>
        <v-data-table
          :loading="loadingRegistros"
          :headers="headers"
          :items="registros"
          :items-per-page="15"
          multi-sort
          class="data-tables"
        >
          <template v-slot:item.circle="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo_af === 'E'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="success"> mdi-plus-circle </v-icon>
              </template>
              <span>
                {{ $tc("global.entrada") }}
              </span>
            </v-tooltip>
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo_af === 'S'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="error"> mdi-minus-circle </v-icon>
              </template>
              <span>
                {{ $tc("global.saida") }}
              </span>
            </v-tooltip>
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo_af === 'N'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="tocs_gray_2">
                  mdi-alpha-n-circle
                </v-icon>
              </template>
              <span> Essa entrada não acrescenta ao total do caixa </span>
            </v-tooltip>
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo_af === 'C'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="tocs_red"> mdi-alpha-c-circle </v-icon>
              </template>
              <span> Esse cancelamento não acrescenta ao total do caixa </span>
            </v-tooltip>
          </template>

          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>

          <template v-slot:item.valor="{ item }">
            <span v-if="item.sigla === 'G$'">{{ item.valor | guarani }}</span>
            <span v-else>{{ item.valor | currency }}</span>
          </template>

          <template v-slot:item.recibo_id="{ item }">
            <div v-if="item.recibo_id" class="d-flex align-center">
              <!-- <div class="pr-2">
                {{ item.recibo_id }}
              </div> -->
              <v-btn
                v-if="item.historico != 'TRANSFERÊNCIA'"
                icon
                @click.stop="openReciboPdf(item.recibo_id)"
              >
                <v-icon>mdi-alpha-r-box</v-icon>
              </v-btn>

              <v-btn
                v-else
                icon
                @click.stop="openReciboPdfTransferencia(item.recibo_id)"
              >
                <v-icon>mdi-alpha-t-box</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.fatura_id="{ item }">
            <div v-if="item.fatura_id" class="d-flex align-center">
              <v-btn
                icon
                @click.stop="
                  generateFatura(item.fatura_id, false, false, false, 'FATURAS')
                "
              >
                <v-icon>mdi-alpha-f-box</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    v-if="desabilitarBotaoCancelamentoVenda(item)"
                    color="red"
                    icon
                    small
                    @click="openCancelamentoVenda(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
              {{ $tc("global.cancelarVenda") }}
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            @click="openFechamentoCaixa"
            color="error"
            small
            class="white--text mt-2"
          >
            <v-icon left>mdi-close</v-icon>
            {{ $tc("global.fechar") + " " + $tc("global.caixa") }}
          </v-btn>
          <DialogFechamentoCaixa
            v-if="dialogFechamentoCaixa"
            :dialogFechamentoCaixa.sync="dialogFechamentoCaixa"
            :caixa_af_id="caixa_af.id"
          />
        </div>
      </v-card-text>
    </v-card>
    <DialogCancelarVendaCaixa
      v-if="dialogCancelarVendaCaixa"
      :dialogCancelarVendaCaixa.sync="dialogCancelarVendaCaixa"
      :registroCaixa="dadosParaCancelar"
      @fetch-movimentacoes="getRegistros"
    />

    <DialogTotaisCaixa
      v-if="dialogTotaisCaixa"
      :dialogTotaisCaixa.sync="dialogTotaisCaixa"
    />

    <DialogGerarRecibo
      v-if="dialogGerarRecibo"
      :dialogGerarRecibo.sync="dialogGerarRecibo"
      :item="item"
      origem="CAIXA-PDV"
      @fetch-caixa-mov="getRegistros"
    />

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title=""
    />
  </v-container>
</template>

<script>
const generateRecibo = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboPdf.js"
  );
const generateTransferenciaCaixa = () =>
  import(
    /* webpackChunkName: "transferenciaReciboPdf" */ "./reports/pdvTransferenciaPdf.js"
  );
const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );
const importFaturaAutoImpressor = (route) =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/" + route + ".js");

import { fetchFatura } from "@/api/faturas/faturas.js";
import { index } from "@/api/empresas/empresa_variaveis.js";
import { fetchRecibo } from "@/api/recibos/recibos.js";
import {
  fetchCaixaAf,
  fetchCaixasAfItems,
  // fetchCaixasAfItemsTotais,
} from "@/api/caixas/caixas_af.js";
import { fetchCaixa } from "@/api/caixas/caixas.js";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { format, parseISO } from "date-fns";
import { getUsuariosEmpresaAtivo } from "@/api/usuarios/usuarios.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "CaixaAf",

  data() {
    return {
      loading: true,
      loadingRegistros: true,
      caixa: null,
      registros: [],
      caixa_mov: {},
      caixa_mov_original: {},
      item: {},
      dialogCaixasAfItem: false,
      selectedMoeda: null,
      selectedFormaPagamento: null,
      usuarios: [],
      selectedUsuario: null,
      tipos: [
        {
          text: "Entrada",
          value: "E",
        },
        {
          text: "Saída",
          value: "S",
        },
      ],
      selectedTipo: null,
      dialogCancelarVendaCaixa: false,
      dadosParaCancelar: null,
      totais_caixa_af_items: [],
      pdfDialog: false,
      pdfData: null,
      dialogGerarRecibo: false,
      recibo: null,
      dialogVendasCaixa: false,
      caixa_af_id: null,
      dialogFechamentoCaixa: false,
      dialogTotaisCaixa: false,
      dialogBaixaContas: false,
      dialogTransferencia: false,
      tipo_baixa: null,
    };
  },

  components: {
    DialogGerarRecibo: () =>
      import(
        "@/views/dashboard/caixas/movimentacoes/components/DialogGerarRecibo.vue"
      ),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogVendasCaixa: () => import("./components/DialogVendasCaixa.vue"),
    DialogCaixasAfItem: () => import("./components/DialogCaixasAfItem.vue"),
    DialogFechamentoCaixa: () =>
      import("./components/DialogFechamentoCaixa.vue"),
    DialogTotaisCaixa: () => import("./components/DialogTotaisCaixa.vue"),
    DialogBaixaContas: () => import("./components/DialogBaixaContas.vue"),
    DialogTransferencia: () => import("./components/DialogTransferencia.vue"),
    DialogCancelarVendaCaixa: () =>
      import("./components/DialogCancelarVendaCaixa.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    // PCaixaEditar() {
    //   return this.getAccess("Caixas", "editar");
    // },
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
    caixa_id() {
      return this.$route.params.caixa_id;
    },

    headers() {
      let heads = [
        {
          text: "",
          value: "circle",
        },
        {
          text: "ID",
          width: "65px",
          value: "id",
        },
        {
          text: this.$tc("global.data"),
          value: "data",
        },
        {
          text: this.$tc("global.usuario"),
          width: "100px",
          value: "user_name",
        },
        {
          text: this.$tc("global.documento"),
          width: "125px",
          value: "documento",
        },
        {
          text: this.$tc("global.historico"),
          width: "125px",
          value: "historico",
        },
        {
          text: this.$tc("global.formapagamento"),
          sortable: false,
          value: "forma_pagamento_descricao",
        },
        {
          text: this.$tc("global.planocontas"),
          sortable: false,
          value: "plano_conta_descricao",
        },

        {
          text: this.$tc("global.recibo"),
          sortable: false,
          align: "center",
          value: "recibo_id",
        },
        {
          text: this.$tc("global.fatura"),
          sortable: false,
          align: "center",
          value: "fatura_id",
        },
        {
          text: this.$tc("global.moeda"),
          sortable: false,
          value: "sigla",
        },
      ];

      let multiMoedas = [
        {
          text: this.$tc("global.valor"),

          value: "valor",
        },
      ];

      let actionsCancelamento = [
        {
          text: "",
          sortable: false,
          value: "actions",
          width: "50px",
          align: "left",
        },
      ];

      heads = [...heads, ...multiMoedas, ...actionsCancelamento];

      return heads;
    },
    url() {
      let queryString = `?caixa=${this.caixa_id}`;
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      if (this.selectedUsuario) {
        queryString += `&usuario_id=${this.selectedUsuario.id}`;
      }
      if (this.selectedTipo) {
        queryString += `&tipo_af=${this.selectedTipo.value}`;
      }
      return queryString;
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    sizeButtomTransf() {
      return this.$vuetify.lang.current === "en" ? false : true;
    },
  },

  methods: {
    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      let fatura = await fetchFatura(fatura_id);
      let variaveis = await index(`?empresa_id=${fatura.empresa_id}`);
      let route_fatura_auto_impressor = variaveis.find(
        (variavel) => variavel.campo === "LAYOUT_AUTO_IMPRESSOR_FATURA"
      ).valor;

      if (!fatura.timbrado_auto_impressor) {
        try {
          importFatura()
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, print, download, unica, tipo).then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
      } else {
        try {
          importFaturaAutoImpressor(route_fatura_auto_impressor)
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, print, download, unica, tipo).then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });

          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    desabilitarBotaoCancelamentoVenda(item) {
      let result = false;

      if (
        item.origem == "VENDA" &&
        !["C", "S"].includes(item.tipo_af) &&
        ["VENTA AL CONTADO", "VENTA A PLAZO"].includes(
          item.plano_conta_descricao
        )
      ) {
        result = true;
      }

      return result;
    },

    // DIALOG DE CONTAS RECEBER
    abrirReceberConta() {
      this.dialogBaixaContas = true;
      this.tipo_baixa = "receber";
    },
    // DIALOG DE CONTAS A PAGAR
    abrirPagarConta() {
      this.dialogBaixaContas = true;
      this.tipo_baixa = "pagar";
    },
    // DIALOG DE CANCELAMENTO DO REGISTRO DO CAIXA
    openCancelamentoVenda(item) {
      if (item.data === format(new Date(), "yyyy-MM-dd")) {
        this.dadosParaCancelar = { ...item };
        this.dialogCancelarVendaCaixa = true;
      } else {
        this.$toast.error("Não permitido o cancelamento!");
      }
    },
    // DIALOG DE GASTOS
    addCaixaAfItem() {
      this.dialogCaixasAfItem = true;
    },
    // FUNCAO DE PUXAR O CAIXA
    async getCaixa() {
      const response = await fetchCaixa(this.caixa_id);
      this.caixa = response;
    },
    // FUNCAO DE PUXAR OS REGISTRO DO CAIXA
    async getRegistros() {
      this.loadingRegistros = true;
      this.registros = [];
      const response = await fetchCaixasAfItems(this.url);
      this.registros = response;

      this.loadingRegistros = false;
    },
    // FUNCAO DE ABRIR O RECIBO DA MOVIMENTACAO DO CAIXA
    async openRecibo(caixa_mov_recibo) {
      this.loading = true;
      this.dialogGerarRecibo = true;
      this.item = caixa_mov_recibo;
    },
    // FUNCAO DE ABRIR O RECIBO EM PDF
    async openReciboPdf(recibo_af) {
      await generateRecibo().then((module) => {
        let generate = module.generateRecibo;
        generate(recibo_af)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    // FUNCAO DE ABRIR O RECIBO DE TRANFERENCIA EM PDF
    async openReciboPdfTransferencia(recibo_af) {
      await fetchRecibo(recibo_af).then((response) => {
        this.recibo = response;
      });
      await generateTransferenciaCaixa().then((module) => {
        let generate = module.generateTransferencia;
        generate(this.recibo)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    // FUNCAO DE PUXAR OS USUARIOS DA EMPRESA ATIVA
    fetchUsuarios() {
      this.loading = true;
      getUsuariosEmpresaAtivo()
        .then((response) => {
          this.usuarios = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    // FUNCAO DE FECHAR O PDF DO CAIXA
    openFechamentoCaixa() {
      this.dialogFechamentoCaixa = true;
    },
  },

  async mounted() {
    this.loading = true;

    await this.getRegistros();
    await this.getCaixa();
    await this.fetchUsuarios();
    this.loading = false;
  },

  beforeMount() {
    this.$store.dispatch("CaixaAf/getCaixaAf", this.caixa_id);
  },
};
</script>

<style>
.vertical-button,
.vertical-button span.v-btn__content {
  height: 80px !important;
  width: 90px !important;
}
.vertical-button span.v-btn__content {
  flex-direction: column;
  justify-content: space-around;
}
</style>
