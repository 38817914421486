<template>
  <v-dialog
    v-model="dialogCaixas"
    @click:outside="$emit('update:dialogCaixas', false)"
    @keydown.esc="$emit('update:dialogCaixas', false)"
    width="650px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1">
        {{
          !edit
            ? $tc("global.novo") + " " + $tc("global.caixa")
            : $tc("global.atualizar") + " " + $tc("global.caixa")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogCaixas', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="caixa.descricao"
                :label="$tc('global.descricao')"
                dense
                outlined
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectedCaixa"
                :label="$tc('global.tipo') + ' ' + $tc('global.caixa')"
                :items="tipos_caixa"
                dense
                :disabled="edit"
                outlined
                required
                :rules="formRules"
                item-text="sigla"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col v-if="caixa.tipo_caixa == 'CONTA CORRENTE'" cols="12" md="6">
              <v-combobox
                v-model="selectedBanco"
                :items="bancos"
                :label="$tc('global.banco', 2)"
                dense
                outlined
                :loading="loadingBancos"
                required
                :rules="formRules"
                item-text="descricao"
                item-value="id"
              ></v-combobox>
            </v-col>
            <v-col v-if="caixa.tipo_caixa == 'CONTA CORRENTE'" cols="12" md="6">
              <v-combobox
                v-model="selectedMoeda"
                :label="$tc('global.moeda')"
                :items="moedas"
                dense
                required
                :disabled="edit"
                :rules="formRules"
                outlined
                item-text="sigla"
                item-value="id"
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="6" class="pb-6">
              <v-card
                v-if="edit"
                outlined
                height="40px"
                class="pa-3 d-flex justify-space-between align-center"
              >
                <v-subheader
                  style="height: auto !important"
                  class="ma-0 pa-0 body-1"
                >
                  Status
                  <span class="pl-1" v-if="caixa.status === 1">
                    {{ $tc("global.ativo") }}
                  </span>
                  <span class="pl-1" v-else>{{ $tc("global.inativo") }}</span>
                </v-subheader>
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  v-model="caixa.status"
                ></v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogCaixas', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm"
          depressed
          small
          @click="!edit ? createCaixa() : updateCaixa()"
        >
          {{ !edit ? $tc("global.criar") : $tc("global.atualizar") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { mapState } from "vuex";
import { postCaixa, putCaixa } from "@/api/caixas/caixas.js";
import { fetchBancos } from "@/api/bancos/bancos.js";

export default {
  name: "DialogCaixas",

  props: {
    dialogCaixas: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {
      caixa: {
        status: 1,
      },
      bancos: [],
      loadingBancos: false,
      caixa_original: {},
      loading: true,
      validForm: true,
      formRules: [inputRequired],
      selectedMoeda: null,
      selectedCaixa: null,
      selectedBanco: null,
      tipos_caixa: ["PDV", "CONTA CORRENTE", "GERAL"],
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  watch: {
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.caixa.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedCaixa() {
      if (this.selectedCaixa) {
        this.caixa.tipo_caixa = this.selectedCaixa;

        if (this.selectedCaixa === "Geral") {
          this.caixa.moeda_id = null;
        }
        if (this.selectedCaixa == "CONTA CORRENTE") {
          this.getBancos();
        }
      }
    },
    selectedBanco() {
      if (this.selectedBanco) {
        this.caixa.banco_id = this.selectedBanco.id;
      }
    },
  },

  methods: {
    createCaixa() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        postCaixa(this.caixa)
          .then(() => {
            this.$emit("fetch-caixas");
            this.$emit("update:dialogCaixas", false);
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateCaixa() {
      if (JSON.stringify(this.caixa) === JSON.stringify(this.caixa_original)) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        let caixa = {};

        caixa.tipo_caixa = this.caixa.tipo_caixa;
        caixa.descricao = this.caixa.descricao;
        caixa.status = this.caixa.status;
        if (this.caixa.tipo_caixa === "CONTA CORRENTE") {
          caixa.moeda_id = this.caixa.moeda_id;
        } else {
          caixa.moeda_id = null;
        }

        putCaixa(this.caixa.id, caixa)
          .then(() => {
            this.caixa_original = { ...this.caixa };
            this.$emit("fetch-caixas");
            this.$emit("update:dialogCaixas", false);
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },

    getBancos() {
      this.loadingBancos = true;
      return fetchBancos()
        .then((response) => {
          this.bancos = response;
          if (this.caixa.banco_id) {
            this.selectedBanco = this.bancos.find(
              (banco) => banco.id === this.caixa.banco_id
            );
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingBancos = false;
        });
    },
  },

  mounted() {
    if (this.edit) {
      this.caixa = { ...this.item };
      this.caixa_original = { ...this.item };
      this.selectedMoeda = this.moedas.find(
        (moeda) => moeda.id_moeda === this.caixa.moeda_id
      );
      this.selectedCaixa = this.caixa.tipo_caixa;
    }
  },
};
</script>

<style></style>
