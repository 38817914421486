const routes = [
  {
    path: "/estoque/compras",
    component: () => import("@/views/dashboard/compras/Compras.vue"),
    meta: {
      menu: "dashboard",

      title: { text: "global.compra", plural: true },
      icon: "mdi-cart-outline",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.compra" },
      ],
      permission: { componente: "Compras", }
    },
  },
];

export default routes;
