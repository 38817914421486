import api from "../axios_service.js";

//pega todos os Contatos
export async function all() {
  const response = await api.get("/cliente-referencias");
  return response.data.result;
}

// pega um contato
export async function fetchClienteReferencias(id) {
  const response = await api.get(`/cliente-referencias/${id}`);
  return response.data.result;
}

// Atualiza um contato existente
export function putClienteReferencias(id, ref) {
  return api.put(`/cliente-referencias/${id}`, ref);
}

// Cria um contato novo
export function postClienteReferencias(ref) {
  return api.post("/cliente-referencias/add", ref);
}

//Deleta um contato
export function deleteClienteReferencias(id) {
  return api.delete(`/cliente-referencias/${id}`);
}
