import api from "../axios_service.js";

export async function fetchOrdensServico(filtros) {
  const response = await api.get(`/ordens-servicos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchOrdemServico(id) {
  const response = await api.get(`/ordens-servicos/${id}`);
  return response.data.result;
}

export async function fetchOsFornecedoresServicos(id) {
  const response = await api.get(
    `/ordens-servicos-fornecedores-servicos/${id}`
  );
  return response.data.result;
}

export async function osProntaInstalacao(id, body) {
  return api.post(`/ordens-servicos-pronta-instalacao/${id}`, body);
}
export async function osEnviadoInstalacao(id, body) {
  return api.post(`/ordens-servicos-enviado-instalacao/${id}`, body);
}

export async function osDataInstalacao(id, body) {
  return api.post(`/ordens-servicos-data-instalacao/${id}`, body);
}

export async function osDataConclusao(id, body) {
  return api.post(`/ordens-servicos-data-conclusao/${id}`, body);
}
