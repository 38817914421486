import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import writtenNumber from "written-number";
import Vue from "vue";
import { format, parseISO } from "date-fns";
import store from "@/store/index.js";

import { fetchFatura } from "@/api/faturas/faturas.js";

export default async function generateFatura(
  fatura_id,
  print,
  download,
  unica,
  tipo
) {
  const cancelado_img = () =>
    import(/* webpackChunkName: "cancelado_img" */ "./cancelado_img");
  const logo_extenso = () =>
    import(/* webpackChunkName: "logo_extenso" */ "./logo_potable_extenso");

  let logo_extenso_source = null;

  logo_extenso().then((module) => {
    logo_extenso_source = module.default;
  });
  const empresa = store.state.Usuario.empresa;

  const fatura = await fetchFatura(fatura_id);

  return new Promise((resolve, reject) => {
    let content = [];

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              margin: [-20, -10, 0, 0],
              border: [true, true, false, false],
              stack: [
                {
                  image: logo_extenso_source,
                  width: 150,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],
              stack: [
                {
                  fontSize: 7,
                  text: empresa.endereco ? empresa.endereco : " ",
                },
                {
                  fontSize: 7,
                  text:
                    "Ciudad del Este - Paraguay  Tel: " +
                    (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  fontSize: 7,
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  fontSize: 7,
                  text: "Ciudad del Este - Paraguay",
                },
                {},
              ],
              fontSize: 9,
              alignment: "right",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "F  A  C  T  U  R  A",
                  alignment: "center",

                  bold: true,
                  fontSize: 18,
                },
                {
                  margin: [0, 5, 0, 0],
                  text:
                    fatura.ponto +
                    "-" +
                    fatura.numero.toString().padStart(7, "0"),

                  alignment: "center",
                  bold: true,
                  fontSize: 18,
                },

                {
                  margin: [12, 3, 0, 0],
                  text: "Timbrado Nº.: " + fatura.timbrado,
                  alignment: "left",
                  fontSize: 8,
                },
                {
                  margin: [12, 3, 0, 0],
                  text:
                    "Válido: " +
                    format(parseISO(fatura.fim_timbrado), "dd/MM/yyyy"),
                  alignment: "left",
                  fontSize: 8,
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let texto_extra = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-150, -40, 0, 0],
              border: [false, false, false, false],
              stack: [
                {
                  text: "Comercio al por mayor de productos quimicos industriales",
                  alignment: "center",

                  fontSize: 10,
                },
                {
                  text: "Comercio al por mayor de otros productos n.c.p.",
                  alignment: "center",

                  fontSize: 10,
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let infos_cliente = {
      margin: [0, -5, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              border: [true, true, false, false],
              margin: [0, 3, 0, 0],
              fontSize: 8,
              stack: [
                {
                  text: "NOMBRE O RAZÓN SOCIAL: " + fatura.cliente_nome,
                },
                {
                  margin: [0, 3, 0, 0],
                  text:
                    "DIRECCIÓN: " +
                    (fatura.cliente_endereco ? fatura.cliente_endereco : ""),
                },
                {
                  margin: [0, 3, 0, 0],
                  text: "CIUDAD: " + fatura.cliente_cidade,
                },
              ],
              colSpan: 2,
            },
            {},
            {
              border: [false, true, false, false],
              margin: [0, 0, 0, 0],
              stack: [
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 8,
                  text: "TELÉFONO: " + fatura.cliente_telefone,
                },
                {},
                {},
                {},
                {},
              ],
            },
            {
              border: [true, true, true, false],

              stack: [
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 8,
                  text:
                    "FECHA: " +
                    format(parseISO(fatura.data_emissao), "dd/MM/yyyy"),
                },
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 8,
                  text: "R.U.C / C.I.: " + fatura.cliente_documento,
                },
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 7,
                  text:
                    "CONDICION DE VENTA: " +
                    (fatura.condpag == 1 ? "CONTADO" : "PLAZO"),
                },
                {},
                {},
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let corpo = {
      table: {
        widths: [30, 240, 80, "*", 20, "*"],

        body: [
          [
            {
              margin: [0, 7, 0, 0],
              fontSize: 8,
              text: "CANT.",
              bold: true,
              alignment: "center",

              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "DESCRIPCIÓN",
              bold: true,
              alignment: "left",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "PRECIO UN.",
              bold: true,
              alignment: "center",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              text: "VALOR DE VENTA",
              bold: true,
              fillColor: "#91969c",
              alignment: "center",
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {},
            {},
            {},
            {
              text: "EXENTAS",
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "5%",
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "10%",
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let fatura_items = {
      table: {
        widths: [30, 240, 80, "*", 20, "*"],
        border: [true, false, true, false],
        body: [],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let filtro_moeda = "";
    if (fatura.moeda == "G$") {
      filtro_moeda = "guarani";
    } else {
      filtro_moeda = "currency";
    }

    fatura.items.forEach((fatura_item) => {
      let item = [
        {
          text: fatura_item.qntde,
          border: [true, false, true, false],
          alignment: "center",
          fontSize: 7,
        },
        {
          text: fatura_item.descricao,
          border: [true, false, true, false],
          alignment: "left",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_isento),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_5),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_10),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
      ];

      fatura_items.table.body.push(item);
    });
    let total_pagar_extenso = writtenNumber(Number(fatura.valor_total), {
      lang: "es",
    });
    if (
      Number(fatura.valor_total) > 1000000 &&
      Number(fatura.valor_total) < 2000000
    ) {
      total_pagar_extenso = total_pagar_extenso.substring(3);
      total_pagar_extenso = "UN" + total_pagar_extenso;
    }

    let footer = {
      table: {
        widths: [80, 190, 80, "*", 20, "*"],
        border: [true, true, true, true],
        body: [
          [
            {
              text: "DESCUENTOS CONDEDIDOS:",
              fontSize: 8,
              bold: true,
              colSpan: 4,
            },
            {},
            {},
            {},
            {
              text: Vue.filter(filtro_moeda)(fatura.desconto),
              bold: true,
              fontSize: 8,
              alignment: "center",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              text: "TOTAL A PAGAR:",
              fontSize: 8,
              bold: true,
            },
            {
              text: total_pagar_extenso.toLocaleUpperCase(),
              fontSize: 8,
            },
            {
              text: "SUB-TOTALES",
              fontSize: 8,
              alignment: "right",
              bold: true,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_exento),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado5),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado10),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
          ],
          [
            {
              text: "-------------------------------------",
              fontSize: 8,
              colSpan: 3,
            },
            {},
            {},
            {
              text: "TOTAL A PAGAR",
              fontSize: 6,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total),
              fontSize: 8,
              alignment: "right",
              fillColor: "#91969c",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [true, false, true, true],
              text: "LIQUIDACIÓN DEL IVA:",
              fontSize: 7,
              bold: true,
            },
            {
              border: [false, false, true, true],
              text:
                "(5%) " +
                Vue.filter(filtro_moeda)(fatura.iva_5) +
                " (10%) " +
                Vue.filter(filtro_moeda)(fatura.iva_10),
              fontSize: 8,
              colSpan: 2,
            },
            {},
            {
              text: "TOTAL IVA",
              fontSize: 7,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.total_iva),
              fontSize: 8,
              alignment: "right",
              fillColor: "#91969c",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [false, false, false, false],
              text: "EMITIDO POR: POTABLE S.A",
              fontSize: 7,
              colSpan: 3,
            },
            {},
            {},
            {
              border: [false, false, false, false],
              text: "ORIGINAL: CLIENTE",
              fontSize: 7,
              alignment: "right",
              colSpan: 3,
            },
            {},
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let cabecalho_2 = {
      margin: [0, 20, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              margin: [-20, -10, 0, 0],
              border: [true, true, false, false],
              stack: [
                {
                  image: logo_extenso_source,
                  width: 150,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],
              stack: [
                {
                  fontSize: 7,
                  text: empresa.endereco ? empresa.endereco : " ",
                },
                {
                  fontSize: 7,
                  text:
                    "Ciudad del Este - Paraguay  Tel: " +
                    (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  fontSize: 7,
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  fontSize: 7,
                  text: "Ciudad del Este - Paraguay",
                },
                {},
              ],
              fontSize: 9,
              alignment: "right",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "F  A  C  T  U  R  A",
                  alignment: "center",

                  bold: true,
                  fontSize: 18,
                },
                {
                  margin: [0, 5, 0, 0],
                  text:
                    fatura.ponto +
                    "-" +
                    fatura.numero.toString().padStart(7, "0"),

                  alignment: "center",
                  bold: true,
                  fontSize: 18,
                },

                {
                  margin: [12, 3, 0, 0],
                  text: "Timbrado Nº.: " + fatura.timbrado,
                  alignment: "left",
                  fontSize: 8,
                },
                {
                  margin: [12, 3, 0, 0],
                  text:
                    "Válido: " +
                    format(parseISO(fatura.fim_timbrado), "dd/MM/yyyy"),
                  alignment: "left",
                  fontSize: 8,
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let texto_extra_2 = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*"],
        body: [
          [
            {
              margin: [-150, -40, 0, 0],
              border: [false, false, false, false],
              stack: [
                {
                  text: "Comercio al por mayor de productos quimicos industriales",
                  alignment: "center",

                  fontSize: 10,
                },
                {
                  text: "Comercio al por mayor de otros productos n.c.p.",
                  alignment: "center",

                  fontSize: 10,
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let infos_cliente_2 = {
      margin: [0, -5, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              border: [true, true, false, false],
              margin: [0, 3, 0, 0],
              fontSize: 8,
              stack: [
                {
                  text: "NOMBRE O RAZÓN SOCIAL: " + fatura.cliente_nome,
                },
                {
                  margin: [0, 3, 0, 0],
                  text:
                    "DIRECCIÓN: " +
                    (fatura.cliente_endereco ? fatura.cliente_endereco : ""),
                },
                {
                  margin: [0, 3, 0, 0],
                  text: "CIUDAD: " + fatura.cliente_cidade,
                },
              ],
              colSpan: 2,
            },
            {},
            {
              border: [false, true, false, false],
              margin: [0, 0, 0, 0],
              stack: [
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 8,
                  text: "TELÉFONO: " + fatura.cliente_telefone,
                },
                {},
                {},
                {},
                {},
              ],
            },
            {
              border: [true, true, true, false],

              stack: [
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 8,
                  text:
                    "FECHA: " +
                    format(parseISO(fatura.data_emissao), "dd/MM/yyyy"),
                },
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 8,
                  text: "R.U.C / C.I.: " + fatura.cliente_documento,
                },
                {
                  margin: [0, 3, 0, 0],
                  fontSize: 7,
                  text:
                    "CONDICION DE VENTA: " +
                    (fatura.condpag == 1 ? "CONTADO" : "PLAZO"),
                },
                {},
                {},
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let corpo_2 = {
      table: {
        widths: [30, 240, 80, "*", 20, "*"],

        body: [
          [
            {
              margin: [0, 7, 0, 0],
              fontSize: 8,
              text: "CANT.",
              bold: true,
              alignment: "center",

              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "DESCRIPCIÓN",
              bold: true,
              alignment: "left",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              margin: [0, 7, 0, 0],
              text: "PRECIO UN.",
              bold: true,
              alignment: "center",
              rowSpan: 2,
            },
            {
              fontSize: 8,
              text: "VALOR DE VENTA",
              bold: true,
              fillColor: "#91969c",
              alignment: "center",
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {},
            {},
            {},
            {
              text: "EXENTAS",
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "5%",
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "10%",
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let fatura_items_2 = {
      table: {
        widths: [30, 240, 80, "*", 20, "*"],
        border: [true, false, true, false],
        body: [],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    fatura.items.forEach((fatura_item) => {
      let item = [
        {
          text: fatura_item.qntde,
          border: [true, false, true, false],
          alignment: "center",
          fontSize: 7,
        },
        {
          text: fatura_item.descricao,
          border: [true, false, true, false],
          alignment: "left",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_isento),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_5),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
        {
          text: Vue.filter(filtro_moeda)(fatura_item.valor_10),
          border: [true, false, true, false],
          alignment: "right",
          fontSize: 7,
        },
      ];

      fatura_items_2.table.body.push(item);
    });

    let footer_2 = {
      table: {
        widths: [80, 190, 80, "*", 20, "*"],
        border: [true, true, true, true],
        body: [
          [
            {
              text: "DESCUENTOS CONDEDIDOS:",
              fontSize: 8,
              bold: true,
              colSpan: 4,
            },
            {},
            {},
            {},
            {
              text: Vue.filter(filtro_moeda)(fatura.desconto),
              bold: true,
              fontSize: 8,
              alignment: "center",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              text: "TOTAL A PAGAR:",
              fontSize: 8,
              bold: true,
            },
            {
              text: total_pagar_extenso.toLocaleUpperCase(),
              fontSize: 8,
            },
            {
              text: "SUB-TOTALES",
              fontSize: 8,
              alignment: "right",
              bold: true,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_exento),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado5),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total_gravado10),
              bold: true,
              fontSize: 8,
              alignment: "right",
            },
          ],
          [
            {
              text: "-------------------------------------",
              fontSize: 8,
              colSpan: 3,
            },
            {},
            {},
            {
              text: "TOTAL A PAGAR",
              fontSize: 6,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.valor_total),
              fontSize: 8,
              alignment: "right",
              fillColor: "#91969c",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [true, false, true, true],
              text: "LIQUIDACIÓN DEL IVA:",
              fontSize: 7,
              bold: true,
            },
            {
              border: [false, false, true, true],
              text:
                "(5%) " +
                Vue.filter(filtro_moeda)(fatura.iva_5) +
                " (10%) " +
                Vue.filter(filtro_moeda)(fatura.iva_10),
              fontSize: 8,
              colSpan: 2,
            },
            {},
            {
              text: "TOTAL IVA",
              fontSize: 7,
            },
            {
              text: Vue.filter(filtro_moeda)(fatura.total_iva),
              fontSize: 8,
              alignment: "right",
              fillColor: "#91969c",
              colSpan: 2,
            },
            {},
          ],
          [
            {
              border: [false, false, false, false],
              text: "EMITIDO POR: POTABLE S.A",
              fontSize: 7,
              colSpan: 2,
            },
            {},
            {
              border: [false, false, false, false],
              text: "DUPLICADO: NO VALIDO COMO CREDITO FISCAL",
              fontSize: 7,
              alignment: "right",
              colSpan: 4,
            },
            {},
            {},
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let break_page = {
      text: "",
      pageBreak: "before",
    };

    content.push(
      cabecalho,
      texto_extra,
      infos_cliente,
      corpo,
      fatura_items,
      footer,
      fatura.items.length > 10 ? break_page : "",
      cabecalho_2,
      texto_extra_2,
      infos_cliente_2,
      corpo_2,
      fatura_items_2,
      footer_2
    );

    if (fatura.status == -1) {
      let cancelado_img_source = {};
      cancelado_img_source.absolutePosition = { x: 60, y: 50 };
      cancelado_img_source.opacity = 0.3;
      cancelado_img_source.width = 700;

      cancelado_img().then((module) => {
        cancelado_img_source.image = module.default;
        content.push(cancelado_img_source);
      });
    }

    let docDefinition = {
      info: {
        title: `FATURA_${fatura.ponto}-${fatura.numero
          .toString()
          .padStart(7, "0")}`,
        author: "ADM",
        subject: "subject of document",
        keywords: "keywords for document",
      },
      // pageSize: layout.obj_folha.pageSize,
      pageSize: "A4",

      pageOrientation: "portrait",
      content: content,
    };

    let backgroundImages = [];

    // adiciona as imagems ao docDefinition
    docDefinition.background = backgroundImages;

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (print) {
      pdfDocGenerator.print();
    } else if (download) {
      pdfDocGenerator.download(
        `FATURA_${fatura.ponto}-${fatura.numero
          .toString()
          .padStart(7, "0")}.pdf`
      );
    } else {
      // pdfDocGenerator.open();
      pdfDocGenerator.getDataUrl((data) => {
        resolve(data);
      });
    }
  });
}
