<template>
  <v-dialog
    v-model="dialogOrdemServico"
    @click:outside="$emit('update:dialogOrdemServico', false)"
    @keydown.esc="$emit('update:dialogOrdemServico', false)"
    max-width="1500px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.add") }} {{ $tc("global.ordensServicos") }}
        <v-spacer></v-spacer>

        <v-btn icon @click="dialogPrinter = true">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('update:dialogOrdemServico', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-tabs v-model="tab" height="40px">
        <v-tab class="caption">
          {{ $tc("global.dados") }}
        </v-tab>
        <v-tab class="caption">
          <v-icon small class="mr-1">mdi-image-plus</v-icon>
          {{ $tc("global.midia", 2) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text class="pt-5">
            <v-row>
              <v-col md="3">
                <DataField
                  :data_sync.sync="ordem_servico.data_criada"
                  :label="$tc('global.data-criacao')"
                  hide_details
                  obrigatorio
                  :disabled="
                    ordem_servico.status == 1 || ordem_servico.status == null
                      ? false
                      : true
                  "
                />
              </v-col>
              <v-col md="3">
                <DataField
                  :data_sync.sync="ordem_servico.data_pronta_instalacao"
                  :label="$tc('global.data-pronta-instalacao')"
                  hide_details
                  :disabled="ordem_servico.status == 2 ? false : true"
                  obrigatorio
                />
              </v-col>
              <v-col md="3">
                <DataField
                  :data_sync.sync="ordem_servico.data_instalacao"
                  :label="$tc('global.data-instalacao')"
                  hide_details
                  :disabled="ordem_servico.status == 3 ? false : true"
                  obrigatorio
                />
              </v-col>
              <v-col md="3">
                <DataField
                  :data_sync.sync="ordem_servico.data_conclusao"
                  :label="$tc('global.data-conclusao')"
                  hide_details
                  :disabled="ordem_servico.status == 4 ? false : true"
                  obrigatorio
                />
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-form
              @submit.prevent
              ref="form"
              v-model="validForm"
              lazy-validation
            >
              <v-row dense class="d-flex align-center">
                <!-- CLIENTE -->
                <v-col cols="12" md="3" v-if="ordem_servico.tipo != 'VENDAS'">
                  <SearchClientes :cliente_sync.sync="selectedCliente" />
                </v-col>
                <!-- CLIENTE -->
                <v-col cols="12" md="3" v-else>
                  <span class="font-weight-bold">
                    {{
                      $tc("global.cliente") +
                      ": " +
                      ordem_servico.cliente_nome +
                      " - " +
                      ordem_servico.cliente_doc
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="3">
                  <v-combobox
                    v-model="selectedDeposito"
                    :label="$tc('global.deposito', 2)"
                    :items="depositos"
                    item-text="deposito"
                    item-value="deposito_id"
                    :disabled="disableItemOrdemServico"
                    hide-details
                    required
                    dense
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-combobox
                    v-model="selectedLista"
                    :items="listas_precos"
                    :label="$tc('global.listadepreco')"
                    item-text="descricao"
                    item-value="id"
                    outlined
                    dense
                    :disabled="disableItemOrdemServico"
                    hide-details
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-combobox
                    v-model="selectedMoeda"
                    :items="moedas"
                    :label="$tc('global.moeda')"
                    item-text="sigla"
                    item-value="id"
                    :disabled="disableItemOrdemServico"
                    outlined
                    dense
                    hide-details
                  >
                  </v-combobox>
                </v-col>
                <!-- DATA INICIO -->
                <v-col cols="12" md="3">
                  <DataField
                    :data_sync.sync="previsao_inicio"
                    :label="$tc('global.previsaoInicio')"
                    hide_details
                    obrigatorio
                    :disabled="disableDatas"
                  />
                </v-col>
                <!-- DATA FIM -->
                <v-col cols="12" md="3">
                  <DataField
                    :data_sync.sync="previsao_fim"
                    :label="$tc('global.previsaoFim')"
                    hide_details
                    obrigatorio
                    :disabled="disableDatas"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="ordem_servico.medidas_piscina"
                    :label="$tc('global.medidaPiscina')"
                    dense
                    outlined
                    :disabled="ordem_servico.status >= 4 ? true : false"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-card flat>
                <v-card-title class="px-0">
                  {{ $tc("global.produto", 2) }}
                </v-card-title>
                <v-card-text class="px-0">
                  <ProdutosOrdemServico
                    v-if="!loading"
                    :produtos.sync="ordem_servico.produtos"
                    :ordem_servico_status="ordem_servico.status"
                    :ordem_servico_id="ordem_servico_id"
                    :editar="editar"
                    :moeda_sigla="ordem_servico.moeda_sigla"
                    :deposito_id="ordem_servico.deposito_id"
                    :moeda_id="ordem_servico.moeda_id"
                    :lista_preco_id="ordem_servico.lista_preco_id"
                    @atualizarTotalServico="i++"
                  />
                </v-card-text>
              </v-card>
              <div class="d-flex align-center">
                {{ $tc("global.total") }}
                {{ $tc("global.servico", 2) }}:
                {{ selectedMoeda ? selectedMoeda.sigla : "" }}
                <span v-if="selectedMoeda && selectedMoeda.sigla == 'G$'">{{
                  total_servicos | guarani
                }}</span>
                <span v-else>{{ total_servicos | currency }}</span>

                <v-spacer></v-spacer>

                <DataField
                  :data_sync.sync="ordem_servico.data_pagamento_servico"
                  :label="
                    $tc('global.data-pagamento') + ' ' + $tc('global.servico')
                  "
                  hide_details
                  :obrigatorio="total_servicos > 0 ? true : false"
                  :disabled="ordem_servico.status != 1 ? true : false"
                />
              </div>
            </v-form>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <InternFileManager
            v-if="tab === 1"
            origem="ORDEM_SERVICO"
            :origem_id="ordem_servico.id"
          />
        </v-tab-item>
      </v-tabs-items>
      <DialogPrinter
        v-if="dialogPrinter"
        :dialogPrinter.sync="dialogPrinter"
        :fatura_id.sync="ordem_servico.fatura_id"
        :venda_id.sync="ordem_servico.origem_id"
        :possui_contrato="ordem_servico.possui_contrato"
        :venda_condicao_pagamento.sync="ordem_servico.condicao_pagamento"
        @fechar-dialog="dialogPrinter = false"
      />
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn>cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="ordem_servico.status !== 5"
          class="primary"
          @click="osInstalacao"
          :disabled="disableOs"
          small
        >
          <v-icon left> mdi-content-save </v-icon>
          <span v-if="ordem_servico.status == 1">
            {{ $tc("global.pronto-instalacao") }}
          </span>
          <span v-if="ordem_servico.status == 2">
            {{ $tc("global.enviar-instalacao") }}
          </span>
          <span v-if="ordem_servico.status == 3">
            {{ $tc("global.data-inicio-instalacao") }}
          </span>
          <span v-if="ordem_servico.status == 4">
            {{ $tc("global.finalizarInstalacao") }}
          </span>
        </v-btn>
      </v-card-actions>
      <DialogTermoCheckList
        v-if="dialogTermoCheckList"
        :dialogTermoCheckList.sync="dialogTermoCheckList"
        :ordem_servico_id="ordem_servico.id"
        :venda_id="ordem_servico.origem_id"
        @closeOs="closeOs"
      />
    </v-card>
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      :title="$tc('global.ordensServicos')"
      @close="closeOs"
    />
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { inputRequired } from "@/helpers/utils.js";
import { fetchOrdemServico } from "@/api/ordem-servico/ordem_servicos.js";
import { fetchUsersDeposito } from "@/api/usuarios/usuarios.js";
import { fetchLPCompletas } from "@/api/listas-precos/listasprecos.js";
import { addDays, format } from "date-fns";
import {
  osProntaInstalacao,
  osEnviadoInstalacao,
  osDataInstalacao,
  osDataConclusao,
} from "@/api/ordem-servico/ordem_servicos.js";

const pdfGenerator = () =>
  import(/* webpackChunkName: "venda-pdf" */ "../reports/ordem_servico.js");

export default {
  name: "DialogOrdemServico",

  props: {
    dialogOrdemServico: {
      type: Boolean,
      default: false,
    },
    editar: {
      type: Boolean,
      default: false,
    },
    ordem_servico_id: {
      type: [Number, String],
    },
  },

  components: {
    SearchClientes: () => import("@/components/fields/SearchClientes.vue"),
    DialogTermoCheckList: () => import("./DialogTermoCheckList.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
    ProdutosOrdemServico: () => import("./ProdutosOrdemServico.vue"),
    DialogPrinter: () =>
      import("@/views/dashboard/caixas-pdv/components/DialogPrinter.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
  },

  data() {
    return {
      loading: false,
      loading_deposito: false,
      selectedCliente: null,
      ordem_servico: {
        medidas_piscina: "",
      },
      validForm: true,
      formRules: [inputRequired],
      selectedDeposito: null,
      depositos: [],
      selectedLista: null,
      selectedMoeda: null,
      listas_precos: [],
      previsao_inicio: null,
      previsao_fim: null,
      i: 0,
      total_servicos: null,
      dialogPrinter: false,
      pdfDialog: false,
      pdfData: "",
      loadingPdf: false,
      tab: 0,
      dialogTermoCheckList: false,
    };
  },

  watch: {
    i() {
      this.calcularTotalServico();
    },
    selectedDeposito() {
      if (this.selectedDeposito) {
        this.ordem_servico.deposito_id = this.selectedDeposito.deposito_id;
      }
    },

    selectedLista() {
      if (this.selectedLista) {
        this.ordem_servico.lista_preco_id = this.selectedLista.id;
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda) {
        this.ordem_servico.moeda_id = this.selectedMoeda.id_moeda;
      }
    },

    previsao_inicio() {
      if (this.previsao_inicio) {
        this.ordem_servico.previsao_inicio = this.previsao_inicio;
        this.ordem_servico.data_pagamento_servico = addDays(
          new Date(this.previsao_inicio),
          7
        );
      }
    },
    previsao_fim() {
      if (this.previsao_fim) {
        this.ordem_servico.previsao_fim = this.previsao_fim;
      }
    },
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      empresa: (state) => state.empresa,
    }),

    disableItemOrdemServico() {
      let result = false;

      if (
        (this.ordem_servico.produtos &&
          this.ordem_servico.produtos.length >= 1) ||
        this.ordem_servico.tipo === "VENDAS"
      ) {
        result = true;
      }

      return result;
    },
    disableOs() {
      let result = false;

      if (
        !this.ordem_servico.previsao_inicio ||
        !this.ordem_servico.previsao_fim
      ) {
        result = true;
      }
      if (
        this.ordem_servico.medidas_piscina == "" ||
        this.ordem_servico.medidas_piscina == null ||
        this.ordem_servico.medidas_piscina == undefined
      ) {
        result = true;
      }
      if (
        this.ordem_servico.status == 2 &&
        !this.ordem_servico.data_pronta_instalacao
      ) {
        result = true;
      }

      if (
        this.ordem_servico.status == 3 &&
        !this.ordem_servico.data_instalacao
      ) {
        result = true;
      }
      if (
        this.ordem_servico.produtos &&
        this.ordem_servico.produtos.length &&
        !this.ordem_servico.produtos.some((item) => item.fornecedor_id !== null)
      ) {
        result = true;
      }

      return result;
    },

    disableDatas() {
      let result = false;

      if (
        this.ordem_servico.status == 3 ||
        this.ordem_servico.status == 4 ||
        this.ordem_servico.status == 5
      ) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    calcularTotalServico() {
      if (this.ordem_servico.produtos && this.ordem_servico.produtos.length) {
        const reducer = (accumulator, currentValue) => {
          return (
            accumulator +
            Number(currentValue.preco_servico ? currentValue.preco_servico : 0)
          );
        };

        this.total_servicos = this.ordem_servico.produtos.reduce(reducer, 0);
      }
    },
    async getOrdemServico() {
      this.loading = true;

      await fetchOrdemServico(this.ordem_servico_id)
        .then((response) => {
          this.ordem_servico = response;
          this.loading = false;
          if (this.ordem_servico.tipo === "VENDAS" || this.editar) {
            this.selectedMoeda = this.moedas.find((moeda) => {
              return moeda.id_moeda == this.ordem_servico.moeda_id;
            });
          }
          if (this.ordem_servico.data_criada == null) {
            this.ordem_servico.data_criada = format(new Date(), "yyyy-MM-dd");
          }
          if (
            this.ordem_servico.status !== 1 ||
            this.ordem_servico.status !== 0
          ) {
            this.previsao_inicio = this.ordem_servico.previsao_inicio;
            this.previsao_fim = this.ordem_servico.previsao_fim;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getDepositos() {
      this.loading_deposito = true;
      return fetchUsersDeposito(`?users_id=${this.user_id}`)
        .then((response) => {
          this.depositos = response;

          if (this.ordem_servico.tipo === "VENDAS" || this.editar) {
            this.selectedDeposito = this.depositos.find((deposito) => {
              return deposito.id == this.ordem_servico.deposito_id;
            });
          } else {
            this.selectedDeposito = this.depositos.find((deposito) => {
              return deposito.principal == true;
            });
          }

          this.loading_deposito = false;
        })
        .catch(() => {
          this.loading_deposito = false;
        });
    },

    async getListasPrecos() {
      return fetchLPCompletas()
        .then((response) => {
          this.listas_precos = response;
          if (this.ordem_servico.tipo === "VENDAS" || this.editar) {
            this.selectedLista = this.listas_precos.find((lista_preco) => {
              return lista_preco.id == this.ordem_servico.lista_preco_id;
            });
          }
        })
        .catch((error) => {});
    },

    async osInstalacao() {
      let ordem_servico = {};

      ordem_servico.previsao_inicio = this.ordem_servico.previsao_inicio;
      ordem_servico.previsao_fim = this.ordem_servico.previsao_fim;
      ordem_servico.produtos = this.ordem_servico.produtos;
      ordem_servico.total_servicos = this.total_servicos;
      ordem_servico.medidas_piscina = this.ordem_servico.medidas_piscina;

      if (this.ordem_servico.status == 1) {
        ordem_servico.data_criada = this.ordem_servico.data_criada;
        ordem_servico.valor = this.ordem_servico.valor;
        ordem_servico.data_pagamento_servico =
          this.ordem_servico.data_pagamento_servico;
        console.log(ordem_servico.produtos);
        osProntaInstalacao(this.ordem_servico.id, ordem_servico).then(
          (response) => {
            if (response.status === 200) {
              this.gerarpdf(this.ordem_servico.id);
            }
          }
        );
      }

      if (this.ordem_servico.status == 2) {
        ordem_servico.data_pronta_instalacao =
          this.ordem_servico.data_pronta_instalacao;
        osEnviadoInstalacao(this.ordem_servico.id, ordem_servico).then(
          (response) => {
            if (response.status === 200) {
              this.$emit("update:dialogOrdemServico", false);
              this.$emit("fetch-ordens-servicos");
            }
          }
        );
      }

      if (this.ordem_servico.status == 3) {
        ordem_servico.data_instalacao = this.ordem_servico.data_instalacao;
        osDataInstalacao(this.ordem_servico.id, ordem_servico).then(
          (response) => {
            if (response.status === 200) {
              this.dialogTermoCheckList = true;
            }
          }
        );
      }
      if (this.ordem_servico.status == 4) {
        ordem_servico.data_conclusao = this.ordem_servico.data_conclusao;
        osDataConclusao(this.ordem_servico.id, ordem_servico).then(
          (response) => {
            if (response.status === 200) {
              this.$emit("update:dialogOrdemServico", false);
              this.$emit("fetch-ordens-servicos");
            }
          }
        );
      }
    },

    async gerarpdf(item) {
      try {
        this.loadingPdf = true;

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.empresa, item).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loadingPdf = false;
          });
      } catch (error) {
        this.loadingPdf = false;
      }
    },

    closeOs() {
      this.$emit("update:dialogOrdemServico", false);
      this.$emit("fetch-ordens-servicos");
    },
  },

  async mounted() {
    await this.getOrdemServico();
    await this.getDepositos();
    await this.getListasPrecos();
    this.calcularTotalServico();
  },
};
</script>

<style></style>
