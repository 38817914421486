import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// import Vue from "vue";

import { format, parseISO } from "date-fns";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import { fetchInventario } from "@/api/relatorios/relatorio_produtos.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };
  const bodyPDF = await layoutEmpresa();
  const cabecalho = bodyPDF.cabecalho;
  const filtro_get_extrato = bodyPDF.queryString;

  let lista_produtos = await fetchInventario(filtro_get_extrato);
  let lista_produtos_info = lista_produtos.data;
  const filtros_info = lista_produtos.filtros;
  let text_filtros = "";

  for (const [key, value] of Object.entries(filtros_info)) {
    if (value){ 
      text_filtros += key + ": " + value + "   ";
    }
  }

  return new Promise((resolve) => {
    let content = [];
    content.push(cabecalho);

    let cabecalho_filtros = {
      margin: [0, 0, 0, -3],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: text_filtros,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };
    if (!Object.values(filtros_info).every(value => value === null)) {
      content.push(cabecalho_filtros);
    }

    //-------------------------------
    let cabecalho_lista_produtos = {
      margin: [0, 5, 0, 5],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: "INVENTÁRIO",
              fillColor: "#F5F5F5",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };
    content.push(cabecalho_lista_produtos);

    //--------------------------------
    let table_produtos_color = "#CFD8DC";
    let table_produtos_font = 8;
    let table_produtos_font_row = 7;
    let table_produtos = {
      margin: [0, 0, 0, 0],
      table: {
        widths: [20, "auto", "auto", "auto", "auto", "auto", 30],
        body: [
          [
            {
              text: "CÓD",
              bold: true,
              // color: "#FFFFFF",
              fillColor: table_produtos_color,
              alignment: "center",
              fontSize: table_produtos_font,
            },
            {
              text: "DESCRIÇÃO",
              bold: true,
              fillColor: table_produtos_color,
              // color: "#FFFFFF",
              alignment: "center",
              fontSize: table_produtos_font,
            },
            {
              text: "FORNECEDOR",
              bold: true,
              fillColor: table_produtos_color,
              // color: "#FFFFFF",
              alignment: "center",
              fontSize: table_produtos_font,
            },
            {
              text: "CATEGORIA",
              fillColor: table_produtos_color,
              bold: true,
              // color: "#FFFFFF",
              alignment: "center",
              fontSize: table_produtos_font,
            },
            {
              text: "MARCA",
              bold: true,
              fillColor: table_produtos_color,
              // color: "#FFFFFF",
              alignment: "center",
              fontSize: table_produtos_font,
            },
            {
              text: "DEPOSITO",
              bold: true,
              fillColor: table_produtos_color,
              // color: "#FFFFFF",
              alignment: "center",
              fontSize: table_produtos_font,
            },
            {
              text: "QTDE",
              bold: true,
              fillColor: table_produtos_color,
              // color: "#FFFFFF",
              alignment: "center",
              fontSize: table_produtos_font,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? "#CCCCCC" : null;
        },
      },
    };
    content.push(table_produtos);

    lista_produtos_info.forEach((produto, index) => {
      let total_row = [
        {
          text: produto.id,
          alignment: "center",
          fontSize: table_produtos_font_row,
        },
        {
          text: produto.descricao,
          alignment: "center",
          fontSize: table_produtos_font_row,
        },
        {
          text: produto.fornecedor,
          alignment: "center",
          fontSize: table_produtos_font_row,
        },
        {
          text: produto.categoria,
          alignment: "center",
          fontSize: table_produtos_font_row,
        },
        {
          text: produto.marca,
          alignment: "center",
          fontSize: table_produtos_font_row,
        },
        {
          text: produto.deposito,
          alignment: "center",
          fontSize: table_produtos_font_row,
        },
        {
          text: produto.qtde_fisico,
          alignment: "center",
          fontSize: table_produtos_font_row,
        },
      ];

      table_produtos.table.body.push(total_row);
    });

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: "right",
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
