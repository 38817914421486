<template>
  <v-dialog v-model="dialogCategoria" width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mt-4 mt-sm-0 mr-2"
        color="tocs_gray_2"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon left>mdi-format-list-bulleted-type</v-icon>
        {{ $tc("global.categoria") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.categoria") }}
      </v-card-title>
      <v-divider></v-divider>
      <div class="px-4 pt-4 d-block d-sm-flex align-center">
        <v-text-field
          style="max-width: 200px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-form
          @submit.prevent
          class="d-flex"
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <div class="d-flex mt-4 mt-sm-0">
            <v-text-field
              v-if="isAdding"
              v-model="categoria.descricao"
              :label="$tc('global.categoria')"
              dense
              hide-details
              class="pr-4"
              ref="categoriaDescricao"
              :rules="formRules"
              required
            ></v-text-field>
            <v-btn
              v-if="!isAdding && PCategoriaAdicionar"
              color="button_1"
              class="white--text d-block"
              @click="isAdding = !isAdding"
            >
              <v-icon left> mdi-plus</v-icon>
              {{ $tc("global.add") }}
            </v-btn>
            <v-btn
              v-if="isAdding && PCategoriaAdicionar"
              color="button_1"
              class="white--text"
              @click="createClienteCategoria"
              :disabled="!validForm"
            >
              <v-icon left> mdi-check</v-icon>
              {{ $tc("global.salvar") }}
            </v-btn>
          </div>
        </v-form>
      </div>
      <v-card-text class="py-5">
        <v-data-table
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="categorias"
          :items-per-page="10"
          disable-sort
          class="data-tables"
        >
          <template v-slot:item.descricao="{ item, index }">
            <v-text-field
              v-if="editItem === index && isEditing"
              v-model="categoria_editar.descricao"
              dense
              hide-details
              ref="categoriaDescricao"
              :rules="formRules"
              required
              class="mb-2"
            ></v-text-field>
            <div v-else>{{ item.descricao }}</div>
          </template>

          <template v-slot:item.status="{ item, index }">
            <div class="d-flex align-center justify-center">
              <v-switch
                v-if="isEditing && index === editItem"
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                v-model="categoria_editar.status"
              ></v-switch>
              <v-switch
                v-else
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                disabled
                v-model="item.status"
              ></v-switch>
            </div>
          </template>

          <template v-slot:item.editar="{ item, index }">
            <!-- Editar -->
            <v-btn
              v-if="PCategoriaEditar && !isEditing && editItem != index"
              small
              icon
              class=""
              @click="editRow(item, index)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <div class="d-flex align-center justify-center">
              <!-- Salvar -->
              <v-btn
                v-if="PCategoriaEditar && isEditing && editItem === index"
                small
                icon
                class=""
                @click="salvarEdit(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>

              <!-- Cancelar -->
              <v-btn
                v-if="PCategoriaEditar && isEditing && editItem === index"
                small
                icon
                class="ml-1"
                @click="closeEditing(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getClientesCategorias,
  postClienteCategoria,
  putClientesCategorias,
} from "@/api/clientes/categorias_clientes.js";
import { mapGetters } from "vuex";
import { inputRequired } from "@/helpers/utils.js";
export default {
  data() {
    return {
      dialogCategoria: false,
      categorias: [],
      isAdding: false,
      isEditing: false,
      validForm: true,
      formRules: [inputRequired],
      categoria: {},
      categoria_editar: {},
      search: "",
      editItem: null,
      itemOriginal: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    headers() {
      return [
        {
          text: this.$tc("global.categoria"),
          value: "descricao",
        },
        {
          text: this.$tc("global.status"),
          sortable: false,
          width: "80px",
          align: "center",
          value: "status",
        },
        {
          text: this.$tc("global.editar"),
          sortable: false,
          width: "80px",
          align: "center",
          value: "editar",
        },
      ];
    },
    PCategoriaAdicionar() {
      return this.getAccess("ClienteCategorias", "adicionar");
    },
    PCategoriaEditar() {
      return this.getAccess("ClienteCategorias", "editar");
    },
  },

  methods: {
    getClientesCategorias() {
      this.loading = true;
      getClientesCategorias()
        .then((response) => {
          this.categorias = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    createClienteCategoria() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        postClienteCategoria(this.categoria)
          .then(() => {
            this.getClientesCategorias();
            this.isAdding = false;
            this.categoria.descricao = null;
          })
          .catch((error) => {
            this.$toast.error("Categoria já existente!");
            this.$refs.categoriaDescricao.reset();
            this.$refs.categoriaDescricao.focus();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    closeEditing(item) {
      this.itemOriginal = { ...item };
      this.isEditing = false;
      this.editItem = null;

      // this.editItem.descricao = this.itemOriginal.descricao;
    },
    editRow(item, index) {
      this.itemOriginal = { ...item };
      this.categoria_editar = { ...item };
      this.isEditing = true;
      this.isAdding = false;
      this.editItem = index;
    },

    salvarEdit() {
      if (
        JSON.stringify(this.itemOriginal) ===
        JSON.stringify(this.categoria_editar)
      ) {
        this.$toast.error("Por favor faça uma modificação antes de salvar");
        return;
      }
      if (
        this.categoria_editar.descricao === null ||
        this.categoria_editar.descricao === "" ||
        this.categoria_editar.descricao === undefined
      ) {
        this.$toast.error("Por favor preencha o campo de categoria!");
        return;
      }

      if (this.$refs.form.validate()) {
        this.loading = true;

        putClientesCategorias(this.categoria_editar.id, this.categoria_editar)
          .then(() => {
            this.loading = false;
            this.isEditing = false;
            this.editItem = null;
            this.getClientesCategorias();
          })
          .catch((error) => {
            this.$toast.error("Categoria já existente!");

            // this.isEditing = true;
          });
      }
    },
  },
  mounted() {
    this.getClientesCategorias();
  },
};
</script>

<style></style>
