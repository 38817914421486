const routes = [
  {
    path: "/estoque/produtos",
    component: () => import("@/views/dashboard/produtos/Produtos.vue"),
    meta: {
      menu: "dashboard",

      icon: "mdi-basket",
      title: { text: "global.produto", plural: true },
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.produto" },
      ],
      permission: {
        componente: "Produtos",
      },
    },
  },
  {
    path: "/estoque/produtos/adicionar",
    props: true,
    component: () => import("@/views/dashboard/produtos/NovoProduto.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Produto",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.produto", to: { path: "/estoque/produtos" } },
        { text: "global.add" },
      ],
      voltar: { path: "/estoque/produtos" },
      permission: {
        componente: "Produtos",
      },
    },
    beforeEnter: (to, from, next) => {
      if (to.meta.permission && to.meta.permission.adicionar) {
        next();
      } else {
        next("/");
      }
    },
  },
];

export default routes;
