<template>
  <v-dialog
    v-model="dialogTiposVendas"
    @click:outside="$emit('update:dialogTiposVendas', false)"
    @keydown.esc="$emit('update:dialogTiposVendas', false)"
    width="700px"
  >
    <v-card dense>
      <v-card-title>
        <span class="text-h6">{{ $tc("global.escolhaVenda") }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:dialogTiposVendas', false)" icon>
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <div no-gutters class="d-flex align-center justify-center px-8 py-4">
        <v-btn
          @click="openVendaComum"
          color="secondary"
          class="pa-6 mx-4 text-subtitle-1"
        >
          {{ $tc("global.vendaSimples") }}
        </v-btn>

        <v-btn
          @click="openVendaPiscina"
          color="secondary"
          class="pa-6 mx-4 text-subtitle-1"
          disabled
        >
          {{ $tc("global.vendaPiscina") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogTiposVendas",

  props: {
    dialogTiposVendas: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  methods: {
    openVendaPiscina() {
      this.$emit("update:dialogTiposVendas", false);
      this.$emit("venda-piscina");
    },

    openVendaComum() {
      this.$emit("update:dialogTiposVendas", false);
      this.$emit("venda-comum");
    },
  },
};
</script>

<style></style>
