<template>
  <v-card v-if="menus" flat :loading="loading" :disabled="loading">
    <v-list dense class="menu_dashboard pt-0">
      <v-list-item-group color="secondary">
        <div v-for="(menu, index) in menus" :key="index">
          <v-list-item
            v-if="menu.type === 'single' && menu.permission"
            :to="menu.no_action ? '' : { path: menu.path }"
            :disabled="menu.disabled"
            @click="menu.click_menu ? mudaMenu(menu.click_menu) : ''"
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="menu.type === 'group' && menu.permission"
            :group="menu.path"
            no-action
            :prepend-icon="menu.icon"
            :disabled="menu.disabled"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </template>
            <div v-for="(child, index) in menu.children" :key="index">
              <v-list-item
                v-if="child.type === 'single' && child.permission"
                :to="{ path: child.path }"
                :disabled="child.disabled"
                class="pl-8"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "MenuDashboard",

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    menus() {
      return [
        {
          type: "single",
          title: this.$tc("global.home"),
          path: "/",
          icon: "mdi-home-outline",
          disabled: false,
          permission: true,
        },
        {
          type: "single",
          title: this.$tc("global.cliente", 2),
          path: "/clientes",
          icon: "mdi-clipboard-account-outline",
          disabled: false,
          permission: this.getAccess("Cliente", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.fornecedor", 2),
          path: "/fornecedores",
          icon: "mdi-truck-outline",
          disabled: false,
          permission: this.getAccess("Fornecedor", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.orcamento", 2),
          path: "/orcamentos",
          icon: "mdi-file-percent-outline",
          disabled: false,
          hide: false,
          permission: this.getAccess("Orcamentos", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.ordensServicos", 2),
          path: "/ordens-servicos",
          icon: "mdi-toolbox-outline",
          disabled: false,
          hide: false,
          permission: this.getAccess("OrdensServicos", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.venda", 2),
          path: "/vendas",
          icon: "mdi-tag-outline",
          disabled: false,
          permission: this.getAccess("Vendas", "visualizar"),
        },
        {
          type: "single",
          title: this.$tc("global.caixa", 2),
          path: "/caixas",
          icon: "mdi-cash-multiple",
          disabled: false,
          hide: false,
          permission: this.getAccess("Caixas", "visualizar"),
        },
        {
          type: "group",
          title: this.$tc("global.estoque"),
          path: "/estoque",
          icon: "mdi-warehouse",
          disabled: false,
          permission: this.getAccess("Estoque", "visualizar"),
          children: [
            {
              type: "single",
              title: this.$tc("global.produto", 2),
              path: "/estoque/produtos",
              icon: "mdi-basket",
              disabled: false,
              permission: this.getAccess("Produtos", "visualizar"),
            },
            {
              type: "single",
              title: this.$tc("global.compra", 2),
              path: "/estoque/compras",
              icon: "mdi-cart-outline",
              disabled: false,
              permission: this.getAccess("Compras", "visualizar"),
            },

            {
              type: "single",
              title: this.$tc("global.transferenciaEstoque"),
              path: "/estoque/transferencia",
              icon: "mdi-swap-horizontal",
              disabled: false,
              hide: false,
              permission: this.getAccess("TransferenciaEstoque", "visualizar"),
            },
            {
              type: "single",
              title: this.$tc("global.devolucao"),
              path: "/estoque/devolucoes",
              icon: "mdi-truck-delivery-outline",
              disabled: false,
              hide: false,
              permission: this.getAccess("Devolucao", "visualizar"),
            },
          ],
        },

        {
          type: "group",
          title: this.$tc("global.financeiro"),
          path: "/financeiro",
          icon: "mdi-currency-usd",
          disabled: false,
          permission: this.getAccess("Financeiro", "visualizar"),
          children: [
            {
              type: "single",
              title: this.$tc("global.cheque", 2),
              path: "/financeiro/cheques",
              icon: "mdi-card-bulleted-outline",
              disabled: false,
              hide: false,
              permission: this.getAccess("Cheques", "visualizar"),
            },
            {
              type: "single",
              title: "Giros",
              path: "/financeiro/giros",
              icon: "mdi-sim",
              disabled: false,
              hide: false,
              permission: this.getAccess("GirosTigo", "visualizar"),
            },
            {
              type: "single",
              title:
                this.$tc("global.conta", 2) + " " + this.$tc("global.aPagar"),
              path: "/financeiro/contas-pagar",
              icon: "mdi-cash-minus",
              disabled: false,
              hide: false,
              permission: this.getAccess("ContasPagar", "visualizar"),
            },
            {
              type: "single",
              title:
                this.$tc("global.baixarconta") +
                " " +
                this.$tc("global.receber"),
              path: "/financeiro/baixa-contas-receber",
              icon: "mdi-account-cash-outline",
              disabled: false,
              hide: false,
              permission: this.getAccess("BaixaContas", "visualizar"),
            },
            {
              type: "single",
              title:
                this.$tc("global.baixarconta") + " " + this.$tc("global.pagar"),
              path: "/financeiro/baixa-contas-pagar",
              icon: "mdi-cash-minus",
              disabled: false,
              hide: false,
              permission: this.getAccess("BaixaContas", "visualizar"),
            },
            {
              type: "single",
              title:
                this.$tc("global.deposito") +
                " " +
                this.$tc("global.cartao", 2),
              path: "/financeiro/compensacao-cartoes",
              icon: "mdi-credit-card-check-outline",
              disabled: false,
              hide: false,
              permission: this.getAccess("CompensacaoCartao", "visualizar"),
            },
            {
              type: "single",
              title: this.$tc("global.compensacaoBanco"),
              path: "/financeiro/compensacao-bancos",
              icon: "mdi-bank-check",
              disabled: false,
              hide: false,
              permission: this.getAccess("CompensacaoBanco", "visualizar"),
            },
            {
              type: "single",
              title: this.$tc("global.antecipo"),
              path: "/financeiro/adiantamentos",
              icon: "mdi-cash-plus",
              disabled: false,
              hide: false,
              permission: this.getAccess("Antecipos", "visualizar"),
            },
          ],
        },

        {
          type: "group",
          title: this.$tc("global.contabilidade"),
          path: "/contabilidade",
          icon: "mdi-bookshelf",
          disabled: false,
          permission: this.getAccess("Contabilidade", "visualizar"),
          children: [
            {
              type: "single",
              title: this.$tc("global.fatura", 2),
              path: "/contabilidade/faturas",
              icon: "mdi-receipt",
              disabled: false,
              hide: false,
              permission: this.getAccess("Faturas", "visualizar"),
            },

            {
              type: "single",
              title: this.$tc("global.timbrado", 2),
              path: "/contabilidade/timbrado",
              icon: "mdi-newspaper-variant-outline",
              disabled: false,
              permission: this.getAccess("Timbrados", "visualizar"),
            },
            {
              type: "single",
              title: this.$tc("global.gasto", 2),
              path: "/contabilidade/gastos",
              icon: "mdi-chart-box-plus-outline",
              disabled: false,
              hide: false,
              permission: this.getAccess("Gastos", "visualizar"),
            },
          ],
        },
      ];
    },
  },

  methods: {
    mudaMenu(menu) {
      this.$store.commit("UPDATE_MENUDINAMICO", menu);
    },

    loadTitle(title) {
      const { text, plural } = title;

      if (plural) {
        return this.$tc(text, 2);
      } else {
        return this.$t(text);
      }
    },
  },

  async mounted() {},
};
</script>

<style lang="scss" scoped>
.menu_dashboard ::v-deep .v-list-item {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.menu_dashboard ::v-deep .v-list-item .v-list-item__title {
  font-size: 0.9rem !important;
  font-weight: 500;
  line-height: 1rem;
}
</style>
