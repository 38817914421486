import api from "../axios_service.js";

export function postBaixaContas(body) {
    return api.post("/baixar-contas", body);
}
export function postBaixaContasFornecedores(body) {
    return api.post("/baixar-contas-pagamento-fornecedores", body);
}
export function postBaixaContasFaturas(body) {
    return api.post("/baixar-contas-faturas", body);
}
export async function postCheckContasReceberPagas(body) {
    return api.post("/check-contas-receber", body);
}
export async function postCheckContasAPagarPagas(body) {
    return api.post("/check-contas-a-pagar", body);
}
