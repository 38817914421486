<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dataFromTextField"
        :label="$tc('global.de') + ':'"
        outlined
        dense
        autocomplete="off"
        v-mask="`##/##/####`"
        hide-details
        @blur="parseDateFromTextField(dataFromTextField)"
        :error="dataFromError"
        clearable
        @click:clear="clearDataFrom"
      >
        <template v-slot:append>
          <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="dataFromPicker"
      no-title
      @input="menu1 = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { format, parseISO, isValid } from "date-fns";
import { mapState } from "vuex";
export default {
  name: "DataFrom",
  data() {
    return {
      menu1: false,
      dataFromTextField: null,
      dataFromPicker: null,
      dataFrom: null,
      dataFromError: false,
    };
  },
  computed: {
    ...mapState("Filtros", {
      dataFromStore: (state) => state.filtros.dataFrom,
    }),
    dataFromQuery() {
      return this.$route.query.dataFrom ? this.$route.query.dataFrom : null;
    },
  },
  watch: {
    dataFromPicker() {
      if (this.dataFromPicker) {
        const [year, month, day] = this.dataFromPicker.split("-");
        this.dataFromTextField = `${day}/${month}/${year}`;
        this.dataFrom = this.dataFromPicker;
      }
    },
    dataFrom() {
      if (this.dataFrom) {
        this.$store.commit("Filtros/UPDATE_DATAFROM", this.dataFrom);
      }
    },
    dataFromQuery: {
      handler() {
        if (this.dataFromQuery) {
          this.dataFromPicker = this.dataFromQuery;
          const [year, month, day] = this.dataFromQuery.split("-");
          this.dataFromTextField = `${day}/${month}/${year}`;
        }
      },
      immediate: true,
    },
  },
  methods: {
    clearDataFrom() {
      this.$store.commit("Filtros/UPDATE_DATAFROM", null);
      this.dataFrom = null;
      this.dataFromPicker = null;
    },
    parseDateFromTextField(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      let fixDate = parseISO(`${year}-${month}-${day}`);
      if (isValid(fixDate)) {
        let formatedDate = format(fixDate, "yyyy-MM-dd");
        this.dataFromPicker = formatedDate;
        this.dataFrom = formatedDate;
        this.dataFromError = false;
      } else {
        this.$toast.error("Data invalida!");
        this.dataFromError = true;
      }
    },
  },
};
</script>

<style></style>
