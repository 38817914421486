<template>
  <v-dialog v-model="dialogProdutosSelecionados" scrollable persistent>
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-icon left>mdi-arrow-right-circle-outline</v-icon>
        {{ $tc("global.produto", 2) }}
        {{ $tc("global.selecionado", 2) }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogProdutosSelecionados', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense class="pt-4 d-flex justify-space-between">
          <!--   MOEDAS -->
          <v-col cols="12" md="5">
            <v-select
              ref="selectedMoeda"
              v-model="selectedMoeda"
              :items="moedas"
              item-text="sigla"
              item-value="id_moeda"
              clearable
              outlined
              dense
              hide-details
              return-object
              :label="$tc('global.moeda')"
            ></v-select>
          </v-col>
          <!--   LISTA DE PRECOS -->
          <v-col cols="12" md="7">
            <v-select
              ref="selectedListaPreco"
              v-model="selectedListaPreco"
              :items="listasPrecos"
              item-text="descricao"
              item-value="id"
              clearable
              outlined
              dense
              hide-details
              return-object
              single-line
              :label="$tc('global.listadepreco')"
            ></v-select>
          </v-col>

          <v-col v-if="selecionarMoedaListaPreco">
            <v-divider class="my-2"></v-divider>

            <!-- DIMINUIR VALOR PORCENTAGEM -->
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="diminiurValorPorcentagem"
                  dense
                  required
                  :prefix="`${$tc('global.diminuir')}: -`"
                  suffix="%"
                  v-mask="`###`"
                  type="number"
                  outlined
                  hide-details
                  style="display: flex"
                  @change="diminuirValor"
                  :disabled="aumentarValorPorcentagem > 0"
                  class="remove-underline input-font-size input-right"
                >
                </v-text-field>
              </v-col>
              <!-- AUMENTAR VALOR PORCENTAGEM -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="aumentarValorPorcentagem"
                  dense
                  required
                  :prefix="`${$tc('global.aumentar')}: +`"
                  suffix="%"
                  v-mask="`###`"
                  type="number"
                  hide-details
                  outlined
                  style="display: flex"
                  @change="aumentarValor"
                  :disabled="diminiurValorPorcentagem > 0"
                  class="remove-underline input-font-size input-right"
                >
                  <!-- height="40px" -->
                </v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  class="py-1"
                  v-model="search"
                  dense
                  outlined
                  :placeholder="$tc('global.buscarProdutosPorDescricao')"
                  ref="search"
                  clearable
                  hide-details
                  autofocus
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-card outlined>
                  <v-data-table
                    v-if="!loading"
                    :headers="headers"
                    :search="search"
                    :items="produtos_Selecteds"
                    :items-per-page="-1"
                    class="data-tables"
                    fixed-header
                    hide-default-footer
                    height="248px"
                  >
                    <!-- CUSTO MEDIO  -->
                    <template v-slot:item.custo_medio="{ item }">
                      <span
                        v-if="selectedMoeda && selectedMoeda.sigla === 'G$'"
                      >
                        {{ item.custo_medio | guarani }}
                      </span>
                      <span v-else>{{ item.custo_medio | currency }} </span>
                    </template>
                    <!-- PRECO ATUAL -->
                    <template v-slot:item.preco_atual="{ item }">
                      <span
                        v-if="selectedMoeda && selectedMoeda.sigla === 'G$'"
                      >
                        {{ item.preco_atual | guarani }}
                      </span>
                      <span v-else>{{ item.preco_atual | currency }} </span>
                    </template>

                    <!-- PRECO EDITAVEL -->
                    <template v-slot:item.preco_editado="{ item, index }">
                      <div class="d-flex align-right justify-right">
                        <v-text-field
                          v-model="item.preco_editado"
                          :placeholder="$tc('global.preco')"
                          hide-details
                          outlined
                          dense
                          v-currency="vCurrencyOptions"
                          @keyup.enter="atualizarValorFinal(item, index)"
                          @blur="atualizarValorFinal(item, index)"
                          class="input-personalizado"
                        >
                        </v-text-field>
                      </div>
                    </template>

                    <!-- PRECO FINAL -->
                    <template v-slot:item.preco_final="{ item }">
                      <v-tooltip bottom color="darkblue">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <span
                              v-if="
                                selectedMoeda && selectedMoeda.sigla === 'G$'
                              "
                            >
                              {{ item.preco_final | guarani }}
                            </span>
                            <span v-else
                              >{{ item.preco_final | currency }}
                            </span>
                          </div>
                        </template>
                        <span class="text-caption">{{
                          ` ${item.preco_editado}${
                            diminiurValorPorcentagem > 0 ? " - " : " + "
                          } ${item.preco_editado} * (${
                            diminiurValorPorcentagem > 0
                              ? diminiurValorPorcentagem
                              : aumentarValorPorcentagem
                          } / 100)`
                        }}</span>
                      </v-tooltip>
                    </template>

                    <!--  ACTIONS -->
                    <template v-slot:item.actions="{ item, index }">
                      <template>
                        <v-btn
                          color="red lighten-2"
                          icon
                          small
                          :disabled="false"
                          @click.stop="DeleteProdutoItem(index, item)"
                        >
                          <v-icon size="20">mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                    </template>
                  </v-data-table>
                  <Carregando v-else />
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" v-else>
            <span
              class="font-weight-bold d-flex align-center justify-center py-6"
            >
              {{ $tc("global.selecioneMoedaListaPreco") }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <div v-if="selecionarMoedaListaPreco">
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="tocs_blue"
            text
            @click="$emit('update:dialogProdutosSelecionados', false)"
          >
            {{ $tc("global.voltar") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogConfirmarAtualizacao" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loading"
                color="button_1"
                small
                class="white--text"
                v-bind="attrs"
                v-on="on"
                @click="vereficarValoresDiferentes"
              >
                <v-icon left>mdi-check</v-icon>
                {{ $tc("global.atualizar") }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="tocs_gray_1">
                <v-icon left>mdi-alert</v-icon>
                {{ $tc("global.confirmar") }} {{ $tc("global.atualizar") }}
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogConfirmarAtualizacao = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <div class="d-flex justify-start text-subtitle-1">
                  <v-card flat class="pa-3 my-3"
                    >{{ $tc("global.moeda") }} :
                    {{ selectedMoeda.sigla }}</v-card
                  >
                  <v-card flat class="pa-3 my-3 mx-2"
                    >{{ $tc("global.lista") + " " }}{{ $tc("global.preco") }} :
                    {{ selectedListaPreco.descricao }}</v-card
                  >
                </div>
                <v-card outlined>
                  <v-data-table
                    :loading="loading"
                    :headers="headersConfirmacao"
                    :items="array_produtos_alterados"
                    :items-per-page="-1"
                    class="data-tables"
                    fixed-header
                    hide-default-footer
                    height="350px"
                  >
                    <!-- CUSTO MEDIO  -->
                    <template v-slot:item.custo_medio="{ item }">
                      <span
                        v-if="selectedMoeda && selectedMoeda.sigla === 'G$'"
                      >
                        {{ item.custo_medio | guarani }}
                      </span>
                      <span v-else>{{ item.custo_medio | currency }} </span>
                    </template>

                    <!--PRECO ATUAL -->
                    <template v-slot:item.preco_atual="{ item }">
                      <span
                        v-if="selectedMoeda && selectedMoeda.sigla === 'G$'"
                      >
                        {{ item.preco_atual | guarani }}
                      </span>
                      <span v-else>{{ item.preco_atual | currency }} </span>
                    </template>

                    <!-- PRECO EDITAVEL  -->
                    <template v-slot:item.preco_editavel="{ item }">
                      <span
                        v-if="selectedMoeda && selectedMoeda.sigla === 'G$'"
                      >
                        {{ item.preco_editavel | guarani }}
                      </span>
                      <span v-else>{{ item.preco_editavel | currency }} </span>
                    </template>

                    <!-- PRECO FINAL -->
                    <template v-slot:item.preco_final="{ item }">
                      <span
                        v-if="selectedMoeda && selectedMoeda.sigla === 'G$'"
                      >
                        {{ item.preco_final | guarani }}
                      </span>
                      <span v-else>{{ item.preco_final | currency }} </span>
                    </template></v-data-table
                  >
                </v-card>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="error"
                  text
                  @click="dialogConfirmarAtualizacao = false"
                >
                  {{ $tc("global.voltar") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  @click="atualizarPrecos"
                  :loading="loading"
                  color="button_1"
                  small
                  class="white--text"
                >
                  <v-icon left>mdi-check</v-icon>
                  {{ $tc("global.atualizar") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  fetchProdutosSelecionados,
  postProdutosPrecosAlteracoes,
} from "@/api/produtos/produtos_alteracoes_precos.js";
export default {
  name: "DialogProdutosSelecionados",

  props: {
    dialogProdutosSelecionados: {
      type: Boolean,
      default: false,
    },
    produtoSelected: {
      type: Array,
      require: true,
    },
    listaPrecoId: {
      type: [String, Number],
      defaul: null,
    },
    moedaId: {
      type: [String, Number],
      defaul: null,
    },
  },

  data() {
    return {
      dialogConfirmarAtualizacao: false,
      search: "",
      loading: false,
      selectedListaPreco: null,
      selectedMoeda: null,
      diminiurValorPorcentagem: 0,
      aumentarValorPorcentagem: 0,
      cambio_consolidado: 0,
      moeda_custo_medio: "G$",
      produtos_Selecteds: [],
      produtos_Selecteds_original: [],
      array_produtos_alterados: [],
    };
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    ...mapState("ListasPreco", {
      listasPrecos: (state) => state.listas_precos,
    }),
    ...mapState("Cambio", {
      cambio: (state) => state.cambio,
    }),
    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),

    headers() {
      return [
        {
          text: "id",
          value: "id",
          align: "start",
          width: "70px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.custoMedio"),
          align: "right",
          width: "140px",
          divider: true,
          value: "custo_medio",
          sortable: false,
        },
        {
          text: this.$tc("global.precoAtual"),
          align: "right",
          width: "140px",
          divider: true,
          value: "preco_atual",
          sortable: false,
        },
        {
          text: this.$tc("global.precoEditavel"),
          align: "right",
          width: "140px",
          divider: true,
          value: "preco_editado",
          sortable: false,
        },
        {
          text: this.$tc("global.precoFinal"),
          align: "right",
          width: "140px",
          divider: true,
          value: "preco_final",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          width: "70px",
          sortable: false,
          value: "actions",
        },
      ];
    },
    headersConfirmacao() {
      return [
        {
          text: "id",
          value: "id",
          align: "start",
          width: "70px",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
          divider: true,
          sortable: false,
        },
        {
          text: this.$tc("global.custoMedio"),
          align: "right",
          width: "140px",
          divider: true,
          value: "custo_medio",
          sortable: false,
        },
        {
          text: this.$tc("global.precoAtual"),
          align: "right",
          width: "140px",
          divider: true,
          value: "preco_atual",
          sortable: false,
        },
        {
          text: this.$tc("global.precoEditavel"),
          align: "right",
          width: "140px",
          divider: true,
          value: "preco_editado",
          sortable: false,
        },
        {
          text: this.$tc("global.precoFinal"),
          align: "right",
          width: "140px",
          value: "preco_final",
          sortable: false,
        },
      ];
    },
    selecionarMoedaListaPreco() {
      let result = false;
      if (this.selectedMoeda && this.selectedListaPreco) {
        result = true;
      }
      return result;
    },
  },

  watch: {
    selectedListaPreco() {
      if (this.selectedMoeda && this.selectedListaPreco) {
        this.getProdutos();
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.setCambio();
      }
      if (this.selectedMoeda && this.selectedListaPreco) {
        this.getProdutos();
      }
    },
  },

  methods: {
    vereficarValoresDiferentes() {
      this.loading = true;
      this.array_produtos_alterados = [];
      let array_produtos_alterados = this.produtos_Selecteds.filter(
        (produtos) => {
          if (Number(produtos.preco_final) !== Number(produtos.preco_atual)) {
            return true;
          } else {
            return false;
          }
        }
      );
      this.array_produtos_alterados = array_produtos_alterados;
      if (this.array_produtos_alterados.length == 0) {
        this.$toast.warning(this.$tc("global.mudeAlgumPreco"));
        this.$nextTick().then(() => {
          this.dialogConfirmarAtualizacao = false;
        });
      }
      this.loading = false;
    },
    setCambio() {
      this.cambio_consolidado = 0;

      if (
        (this.selectedMoeda.sigla === "G$" &&
          this.moeda_custo_medio === "G$") ||
        (this.selectedMoeda.sigla === "R$" &&
          this.moeda_custo_medio === "R$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_custo_medio === "U$")
      ) {
        this.cambio_consolidado = 1;
      }

      if (
        (this.selectedMoeda.sigla === "G$" &&
          this.moeda_custo_medio === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_custo_medio === "G$")
      ) {
        if (this.formaPagamentoSelected?.id === 6) {
          this.cambio_consolidado = this.cambio.guarani_credito;
        } else if (this.formaPagamentoSelected?.id === 7) {
          this.cambio_consolidado = this.cambio.guarani_debito;
        } else {
          this.cambio_consolidado = this.cambio.guarani_venda;
        }
      }

      if (
        (this.selectedMoeda.sigla === "G$" &&
          this.moeda_custo_medio === "R$") ||
        (this.selectedMoeda.sigla === "R$" && this.moeda_custo_medio === "G$")
      ) {
        this.cambio_consolidado = this.cambio.guarani_real;
      }

      if (
        (this.selectedMoeda.sigla === "R$" &&
          this.moeda_custo_medio === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_custo_medio === "R$")
      ) {
        this.cambio_consolidado = this.cambio.real_venda;
      }
    },
    cambioResolve(moeda_origem, valor_origem, moeda_destino, valor_destino) {
      let result = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        result = valor_origem / valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        result = valor_destino * valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      if (
        (moeda_origem === "U$" && moeda_destino === "U$") ||
        (moeda_origem === "R$" && moeda_destino === "R$") ||
        (moeda_origem === "G$" && moeda_destino === "G$")
      ) {
        result = valor_origem * valor_destino;
      }

      return result ? result + Number.EPSILON : result;
    },

    diminuirValor() {
      this.produtos_Selecteds.forEach((produto) => {
        let valor_editado_parce = this.$ci.parse(
          produto.preco_editado,
          this.vCurrencyOptions
        );

        produto.preco_final =
          valor_editado_parce -
          valor_editado_parce * (Number(this.diminiurValorPorcentagem) / 100);
      });
    },

    aumentarValor() {
      this.produtos_Selecteds.forEach((produto) => {
        let valor_editado_parce = this.$ci.parse(
          produto.preco_editado,
          this.vCurrencyOptions
        );

        produto.preco_final =
          valor_editado_parce * (Number(this.aumentarValorPorcentagem) / 100) +
          valor_editado_parce;
      });
    },

    atualizarValorFinal(item, index) {
      let valor_editado = this.$ci.parse(
        item.preco_editado,
        this.vCurrencyOptions
      );

      if (this.diminiurValorPorcentagem > 0) {
        this.produtos_Selecteds[index].preco_final =
          valor_editado - valor_editado * (this.diminiurValorPorcentagem / 100);
      } else if (this.aumentarValorPorcentagem > 0) {
        this.produtos_Selecteds[index].preco_final =
          valor_editado + valor_editado * (this.aumentarValorPorcentagem / 100);
      } else {
        this.produtos_Selecteds[index].preco_final = valor_editado;
      }
    },

    DeleteProdutoItem(index) {
      this.produtos_Selecteds.splice(index, 1);
    },

    getProdutos() {
      if (this.selectedMoeda && this.selectedListaPreco && !this.loading) {
        this.loading = true;
        let produtosSelecionados = {};
        produtosSelecionados.produtoSelecionado = this.produtoSelected;
        produtosSelecionados.moeda_id = this.selectedMoeda.id_moeda;
        produtosSelecionados.lista_preco_id = this.selectedListaPreco.id;
        return fetchProdutosSelecionados(produtosSelecionados)
          .then((response) => {
            this.produtos_Selecteds = response.data.result;
            this.produtos_Selecteds.map((produto) => {
              if (produto.custo_medio) {
                let valor_cambio = this.cambioResolve(
                  "G$",
                  produto.custo_medio,
                  this.selectedMoeda.sigla,
                  this.cambio_consolidado
                );
                produto.custo_medio = valor_cambio;
              }
            });
            this.produtos_Selecteds_original = { ...this.produtos_Selecteds };
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    atualizarPrecos() {
      if (this.produtos_Selecteds) this.loading = true;
      let produtos_precos_alteracoes = {};
      if (this.array_produtos_alterados.length > 0) {
        this.array_produtos_alterados.forEach((produto) => {
          let produto_preco_editavel = this.$ci.parse(
            produto.preco_editado,
            this.vCurrencyOptions
          );
          produto.preco_editado = produto_preco_editavel;

          produto.aumentarValorPorcentagem = Number(
            this.aumentarValorPorcentagem
          );
          produto.diminiurValorPorcentagem = Number(
            this.diminiurValorPorcentagem
          );
        });

        produtos_precos_alteracoes.moeda_id = this.selectedMoeda.id_moeda;
        produtos_precos_alteracoes.lista_preco_id = this.selectedListaPreco.id;
        produtos_precos_alteracoes.produtos_alterados =
          this.array_produtos_alterados;

        return postProdutosPrecosAlteracoes(produtos_precos_alteracoes)
          .then(() => {})
          .catch((error) => {
            console.log(error);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
            this.dialogConfirmarAtualizacao = false;
            this.$emit("update:dialogProdutosSelecionados", false);
            this.$emit("fetch-precos");
          });
      }
    },
  },

  async mounted() {
    this.loading = true;
    if (this.moedaId && this.listaPrecoId) {
      this.selectedMoeda = this.moedas.find((moeda) => {
        return moeda.id_moeda === this.moedaId;
      });
      this.selectedListaPreco = this.listasPrecos.find((lista) => {
        return lista.id === this.listaPrecoId;
      });
    } else {
      if (this.moeda_empresa) {
        this.selectedMoeda = this.moedas.find((moeda) => {
          return moeda.id_moeda === this.moeda_empresa;
        });
        this.$nextTick().then(() => {
          this.$refs.selectedListaPreco.focus();
        });
      } else {
        this.$nextTick().then(() => {
          this.$refs.selectedMoeda.focus();
        });
      }
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-personalizado {
  .v-input__slot {
    min-height: 15px !important;
  }

  input {
    text-align: right !important;
    font-size: 0.65rem !important;
    height: 23px !important;
  }
}
</style>