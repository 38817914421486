<template>
  <v-dialog
    v-model="dialogContaAdd"
    persistent
    @click:outside="$emit('update:dialogContaAdd', false)"
    @keydown.esc="$emit('update:dialogContaAdd', false)"
    width="500px"
    max-width="500px"
    :scrollable="false"
  >
    <v-card flat>
      <v-card-title class="pa-4 secondary white--text">
        {{ $tc("global.add") }} {{ $tc("global.conta") }}
        {{ $tc("global.receber") }}
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('update:dialogContaAdd', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <DataField
                  :data_sync.sync="conta.data_vencimento"
                  label="Data Vencimento"
                  :obrigatorio="formRules ? true : false"
                  :data_min="today"
                />
              </v-col>
              <v-col cols="12" class="text-center">
                <v-textarea
                  v-model="conta.descricao"
                  :label="$tc('global.descricao')"
                  :placeholder="$tc('global.descricao')"
                  :rules="[rules.required, rules.max, rules.min]"
                  :rows="2"
                  required
                  outlined
                  dense
                  hide-details
                >
                </v-textarea>
              </v-col>
              <!-- MOEDA -->
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="selectedMoeda"
                  :label="$tc('global.moeda', 2)"
                  :placeholder="'Selecione a moeda...'"
                  :items="moedas"
                  dense
                  outlined
                  required
                  hide-details
                  :rules="formRules"
                  item-text="sigla"
                  item-value="id"
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="6" class="text-center">
                <v-text-field
                  v-model="valorConta"
                  :label="$tc('global.valor')"
                  :placeholder="$tc('global.valor')"
                  :rules="[rules.required]"
                  :disabled="!selectedMoeda"
                  outlined
                  flat
                  dense
                  hide-details
                  v-currency="vCurrencyOptions"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          :disabled="!validForm && valorConta > 0"
          :loading="loading"
          depressed
          small
          @click="addContaReceber()"
        >
          {{ $tc("global.add") }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postContaReceber } from "@/api/contas-receber/contas_receber.js";
import { inputRequired } from "@/helpers/utils.js";
import { format } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "DialogAddContaReceber",
  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },
  props: {
    dialogContaAdd: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      today: format(new Date(), "yyyy-MM-dd"),
      selectedMoeda: null,
      conta: {
        ordem: "1/1",
        cliente_id: null,
        data_vencimento: "",
        valor: null,
        descricao: "",
        moeda_id: null,
        origem: "MANUAL",
        origem_id: 0,
      },
      valorConta: null,
      rules: {
        required: (v) => !!v || "Campo obrigatório",
        max: (v) => v.length <= 100 || "Máximo de 100 caracteres",
        min: (v) => v.length >= 3 || "Mínimo de 3 caracteres",
        // maiorQueZero: (v) =>
        //   (Number(v) && this.conta.valor <= 0) ||
        //   "Valor deve ser maior que zero",
      },
    };
  },

  computed: {
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  watch: {
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.conta.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    valorConta() {
      this.conta.valor = this.$ci.parse(this.valorConta, this.vCurrencyOptions);
    },
  },

  methods: {
    addContaReceber() {
      if (this.$refs.form.validate()) {
        if (this.conta.valor <= 0) {
          this.$toast.error("Valor da conta deve ser maior que 0");
          return;
        }
        this.loading = true;
        postContaReceber(this.conta)
          .then(() => {
            this.$emit("update:dialogContaAdd", false);
            this.$emit("fetch-contas");
            this.$toast.success(
              this.$tc("global.conta") + " " + this.$tc("global.add")
            );
          })
          .catch((error) => {
            this.$toast.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    this.conta.cliente_id = this.cliente_id;
  },
};
</script>

<style>
</style>