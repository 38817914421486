<template>
  <v-card>
    <v-card-title class="pa-3 subtitle-1 tocs_gray_1">
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        solo
        flat
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-spacer class="d-none d-sm-flex"></v-spacer>
      <v-btn
        :to="{ path: '/super-admin/usuarios/adicionar' }"
        color="button_1"
        class="white--text mt-4 mt-sm-0"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $tc("global.add") }}
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="usuarios"
        :items-per-page="10"
        item-key="id"
        :search="search.length >= 3 ? search : ''"
        class="data-tables data-tables__row-click"
        @click:row="goToUsuario"
      >
        <template v-slot:item.status="{ item }">
          <span size="28" v-if="item.status === 0">inativo</span>
          <span v-if="item.status === 1"> ativo </span>
        </template>
      </v-data-table>
      <Carregando v-else />
    </v-card-text>
  </v-card>
</template>

<script>
import { getAllUsuarios } from "@/api/usuarios/usuarios.js";

export default {
  name: "ListaUsuarios",
  data: () => ({
    search: "",
    loading: true,
    usuarios: [],
  }),
  computed: {
    headers() {
      let headers = [
        {
          text: this.$tc("global.nome"),
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: this.$tc("global.celular"),
          value: "celular",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
      return headers;
    },
  },
  methods: {
    goToUsuario(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.name);
      this.$router.push({ path: `/super-admin/usuario/${item.id}` });
    },
    getUsuarios() {
      this.loading = true;

      getAllUsuarios()
        .then((response) => {
          this.usuarios = response;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getUsuarios();
  },
};
</script>

<style scoped lang="scss"></style>
