import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import Vue from "vue";
import store from "@/store/index.js";
import { format, parseISO } from "date-fns";

export function generatePdf(empresa, venda) {
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.usuario.name;

    let logo = {
      image: empresa.logo64,
      width: 80,
      height: 80,
      alignment: "center",
    };

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*"],
        body: [
          [
            {
              // fillColor: "#F5F5F5",
              border: [true, true, true, true],
              margin: [15, 0, 0, 0],
              columns: [
                {
                  stack: [
                    logo ? logo : "",
                  ],
                  width: 90,
                },
                {
                  stack: [
                    {
                      columns: [
                        {
                          text: empresa.nome,
                          // color: "#009cde",
                          alignment: "start",
                          margin: [0, 0, 0, 0],
                          fontSize: 12,
                          bold: true,
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: 'de: ' + empresa.responsavel,
                          // color: "#009cde",
                          alignment: "start",
                          margin: [0, 0, 0, 0],
                          fontSize: 11,
                        },
                      ],
                    },

                    {
                      text: empresa.descricao,
                      bold: true,
                      alignment: "start",
                    },
                    {
                      text: 'Cel.: ' + empresa.telefone + ' - ' + empresa.endereco + '\n' + empresa.cidade + ' - ' + empresa.estado + ' - ' + empresa.pais,
                      alignment: "start",
                      fontSize: 9,
                    },
                  ],
                  fontSize: 9,
                  margin: [0, 5, 0, 0],
                },
              ],
            },

          ],

        ],
      },
    };

    let venda_vencimento = {
      text:
        "Vencimento: " + venda.condicao_pagamento === 2
          ? format(parseISO(venda.data_vencimento), "dd/MM/yyyy")
          : "",
      alignment: "right",
      fontSize: 8,
    };

    let dados = [
      {
        // margin: [left, top, right, bottom]
        margin: [0, 10, 0, 10],
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: "CLIENTE",
                    alignment: "left",
                    // color: "#FFFFFF",
                    // fillColor: "#050505",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    stack: venda.cliente_id
                      ? [
                        {
                          text: venda.cliente_nome,
                          fontSize: 8,
                        },
                        {
                          text: `${venda.cliente_endereco}, ${venda.cliente_cidade}`,
                          fontSize: 8,
                        },
                        {
                          text: venda.cliente_documento,
                          fontSize: 8,
                        },
                      ]
                      : [],
                  },
                ],
              },
              {
                margin: [5, 5, 5, 5],
                stack: [
                  {
                    text: `PEDIDO Nº.: ${venda.numero}`,
                    alignment: "right",
                    // color: "#FFFFFF",
                    // fillColor: "#050505",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  },
                  {
                    text: format(
                      parseISO(venda.created_at),
                      "dd/MM/yyyy - HH:mm:ss"
                    ),
                    alignment: "right",
                    fontSize: 8,
                  },
                  venda.condicao_pagamento === 2 ? venda_vencimento : "",
                ],
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          // vLineWidth: function(i, node) {
          //   return i === 0 || i === node.table.widths.length ? 0 : 1;
          // },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
          },
        },
      },
    ];

    let tabela_items = {
      table: {
        widths: ["*", "auto", "auto", "auto"],
        headerRows: 1,
        body: [
          // Headers
          [
            {
              text: "PRODUTO(s)",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            {
              text: "QTDE",
              alignment: "center",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            {
              text: `PRECO (${venda.moeda})`,
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
            {
              text: "SUBTOTAL",
              fontSize: 8,
              fillColor: "#CFD8DC",
              // color: "#FFFFFF",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 0;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };

    let subtotal_geral = 0;
    let desconto_geral = 0;

    venda.items.forEach((item) => {
      let obj = { ...item };

      let subtotal = Number(obj.qtde) * Number(obj.preco);
      subtotal_geral += subtotal;

      if (obj.moeda == "G$") {
        obj.preco = Vue.filter("guarani")(Number(obj.preco));
        obj.subtotal = Vue.filter("guarani")(Number(subtotal));
      } else {
        obj.preco = Vue.filter("currency")(Number(obj.preco));
        obj.subtotal = Vue.filter("currency")(Number(subtotal));
      }

      let bodyLineFontSize = 8;
      let bodyLine = [
        { text: obj.descricao, fontSize: bodyLineFontSize },
        { text: obj.qtde, fontSize: bodyLineFontSize, alignment: "center" },
        { text: obj.preco, fontSize: bodyLineFontSize, alignment: "right" },
        { text: obj.subtotal, fontSize: bodyLineFontSize, alignment: "right" },
      ];

      tabela_items.table.body.push(bodyLine);
    });

    let total_geral = subtotal_geral - Number(venda.desconto);

    if (venda.moeda == "G$") {
      subtotal_geral = Vue.filter("guarani")(Number(subtotal_geral));
      desconto_geral = Vue.filter("guarani")(Number(venda.desconto));
      total_geral = Vue.filter("guarani")(Number(total_geral));
    } else {
      subtotal_geral = Vue.filter("currency")(Number(subtotal_geral));
      desconto_geral = Vue.filter("currency")(Number(venda.desconto));
      total_geral = Vue.filter("currency")(Number(total_geral));
    }

    let tabela_total = {
      // margin: [left, top, right, bottom]
      margin: [0, 20, 0, 0],
      columns: [
        {
          stack: [
            {
              text: venda.forma_pagamento ? "Forma de Pagamento:" : "",
              fontSize: 9,
              bold: true,
              // margin: [left, top, right, bottom]
              margin: [0, 20, 0, 0],
            },
            {
              text: venda.forma_pagamento,
              fontSize: 8,
            },
          ],
        },
        {
          stack: [
            {
              text: venda.forma_pagamento ? "Observação:" : "",
              fontSize: 9,
              bold: true,
              // margin: [left, top, right, bottom]
              margin: [0, 20, 0, 0],
            },
            {
              text: venda.obs ? venda.obs : "",
              fontSize: 8,
            },
          ],
        },

        {
          width: "*",
          stack: [
            {
              text: [
                `………………………………………………………\n`,
                `${venda.cliente_nome}\n`,
                `${venda.cliente_documento ? venda.cliente_documento : ''}\n`,
              ],
              alignment: `center`,
              fontSize: 8,
              bold: true,
              margin: [0, 20, 0, 0],
              // style: 'assinatura',
            },
          ]
        },
        {
          width: "auto",
          table: {
            widths: [75, 60],
            body: [
              [
                {
                  text: "SUBTOTAL",
                  alignment: "right",
                  fontSize: 8,
                  fillColor: "#CFD8DC",
                },
                {
                  text: subtotal_geral,
                  alignment: "right",
                  fontSize: 8,
                },
              ],
              [
                {
                  text: "DESCONTO",
                  alignment: "right",
                  fontSize: 8,
                  fillColor: "#CFD8DC",
                },
                {
                  text: desconto_geral,
                  alignment: "right",
                  fontSize: 8,
                },
              ],
              [
                {
                  text: "TOTAL",
                  alignment: "right",
                  fontSize: 8,
                  fillColor: "#CFD8DC",
                },
                {
                  text: total_geral,
                  alignment: "right",
                  fontSize: 8,
                },
              ],
            ],
          },
          layout: {
            // hLineWidth: function(i, node) {
            //   return i <= 1 || i === node.table.body.length ? 1 : 0;
            // },
            hLineColor: function (i, node) {
              return i === 0 || i === node.table.body.length
                ? "#666666"
                : "#666666";
            },
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "#666666"
                : "#666666";
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
            },
          },
        },
      ],
    };





    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.2,
      },
      content: [
        cabecalho,

        ...dados,
        tabela_items,
        tabela_total,

      ],
      footer:
        function (currentPage, pageCount) {
          return [
            {
              columns: [
                {
                  text: `${nome} as ${now}`,
                  fontSize: 7,
                  margin: [15, 0, 0, 0],
                  width: "25%",
                },
                {
                  text: "SOFTPAR - SOFTWARE A MEDIDA DO SEU NEGÓCIO",
                  fontSize: 7,
                  alignment: "center",
                  width: "*",
                },
                {
                  text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                  margin: [0, 0, 15, 0],
                  alignment: "right",
                  fontSize: 7,
                  width: "25%",
                },
              ],
            },
          ];
        },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        // assinatura: {
        //   margin: [0, 100, 0, 0],
        // },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
