<template>
  <v-container fluid v-if="!loading">
    <div class="mb-8">
      {{ produto.id }} - {{ produto.descricao }} - Ref.
      {{ produto.referencia }}
    </div>
    <v-row v-if="listasprecos.length">
      <v-col
        v-for="listaprecos in listasprecos"
        :key="listaprecos.id"
        cols="12"
        md="4"
      >
        <v-card outlined>
          <v-card-title class="py-2 lightgray text-subtitle-1">
            {{ listaprecos.descricao }}
            <v-spacer></v-spacer>
            <v-btn
              color="button_1"
              depressed
              x-small
              fab
              class="white--text"
              @click="openAddPreco(listaprecos)"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table
              v-if="!loading"
              :headers="headers"
              :items="listaprecos.items"
              :items-per-page="-1"
              disable-sort
              class="data-tables data-tables__row-click"
              @click:row="editPreco"
              hide-default-footer
              :hide-default-header="listaprecos.items.length ? false : true"
            >
              <template v-slot:item.valor="{ item }">
                {{
                  item.valor
                    | currency({
                      thousandsSeparator: item.moeda_separador_milhar,
                      fractionCount: item.moeda_contador_decimal,
                      fractionSeparator: item.moeda_separador_decimal,
                    })
                }}
              </template>
            </v-data-table>
            <Carregando v-else />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card outlined v-else>
      <v-card-title class="text-subtitle-1">
        {{ $tc("global.nenhumaLista") }}
        <v-spacer></v-spacer>
        <v-btn
          color="button_1"
          depressed
          dark
          :to="{ path: '/configuracoes/listas-precos' }"
        >
          {{ $tc("global.listadepreco") }}
          <v-icon right>mdi-application-import-outline</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>

    <PrecoHistorico
      v-if="precoHistoricoDialog"
      :precoHistoricoDialog.sync="precoHistoricoDialog"
      :preco="preco"
    />

    <AddPreco
      v-if="addPrecoDialog"
      :addPrecoDialog.sync="addPrecoDialog"
      :lista="lista"
      :produto="produto"
      @fetch-listas="getListasPrecos"
    />
  </v-container>
  <v-card v-else>
    <v-card-title>
      <Carregando />
    </v-card-title>
  </v-card>
</template>

<script>
import { fetchLPCompletas } from "@/api/listas-precos/listasprecos.js";

export default {
  name: "ProdutoListasPrecos",

  components: {
    AddPreco: () => import("./components/AddPreco.vue"),
    PrecoHistorico: () => import("./components/PrecoHistorico.vue"),
  },

  props: {
    produto: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      listasprecos: [],
      lista: {},
      preco: {},
      tab: 0,
      addPrecoDialog: false,
      precoHistoricoDialog: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.moeda"),
          divider: true,
          width: "100px",
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
      ];
    },
  },

  methods: {
    getListasPrecos() {
      let filtros = `?status=1&produto_id=${this.produto.id}`;
      return fetchLPCompletas(filtros)
        .then((response) => {
          this.listasprecos = response;
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    editPreco(item) {
      this.preco = { ...item };
      this.precoHistoricoDialog = true;
    },
    openAddPreco(item) {
      this.lista = { ...item };
      this.addPrecoDialog = true;
    },
  },

  async mounted() {
    try {
      this.loading = true;
      await this.getListasPrecos();

      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  },
};
</script>

<style></style>
