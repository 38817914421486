import api from "../axios_service.js";

export async function fetchCheques(filtros) {
  const response = await api.get(`/cheques${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchCheque(id) {
  const response = await api.get(`/cheques/${id}`);
  return response.data.result;
}

export function putCheque(id, cheque) {
  return api.put(`/cheques/${id}`, cheque);
}

export function postCheque(body) {
  return api.post("/cheques/add", body);
}

export function compensarCheques(body) {
  return api.post("/compensar-cheques", body);
}

export function chequeIsValid(body) {
  return api.post("/valida-cheque", body);
}

export async function getBoxChequesAbertos(filtros) {
  const response = await api.get(`/box-cheques-abertos${filtros ? filtros : ""}`);
  return response.data.result;
}

export function devolucaoCheque(body) {
  return api.post("/cheque-devolucao", body);
}
