<template>
  <v-dialog
    v-model="dialogAddProduto"
    @click:outside="$emit('update:dialogAddProduto', false)"
    @keydown.esc="$emit('update:dialogAddProduto', false)"
    width="1250px"
  >
    <v-card class="pa-sm-6">
      <v-card-title class="subtitle-1">
        {{ $tc("global.dados-gerais") }}
        <v-spacer></v-spacer>
        <v-btn
          @click="criarProduto"
          :disabled="!validForm"
          color="button_1"
          class="white--text"
        >
          <v-icon left>mdi-check</v-icon>
          Adicionar
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-7">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" md="3">
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
              >
                <v-card flat v-if="img">
                  <v-img :src="img" contain></v-img>
                </v-card>
                <div v-else class="fill-height" style="width: 100%">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :class="{ hoverFile: hoverFile }"
                    :use-custom-dropzone-options="true"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="fileAdded"
                    @vdropzone-drag-enter="hoverFile = true"
                    @vdropzone-drag-over="hoverFile = true"
                    @vdropzone-drag-leave="hoverFile = false"
                    :useCustomSlot="true"
                    :include-styling="false"
                  >
                    <div class="text-center">
                      <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                      <h3 class="text-subtitle-1">Arrastar e soltar</h3>
                      <div class="text-caption">Clique para selecionar</div>
                    </div>
                  </vue-dropzone>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="9">
              <v-row dense>
                <!-- Descrição -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="produto.descricao"
                    label="Descrição"
                    outlined
                    dense
                    :rules="formRules"
                    required
                  ></v-text-field>
                </v-col>
                <!-- Classificações -->
                <v-col cols="12" md="3">
                  <v-select
                    v-model="produto.produto_categoria_id"
                    :items="categorias"
                    :loading="loadingClass"
                    clearable
                    item-text="descricao"
                    item-value="id"
                    dense
                    outlined
                    label="Categoria"
                  ></v-select>
                </v-col>
                <!-- Referencia -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="produto.referencia"
                    label="Referencia"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Url -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="produto.url"
                    label="Url"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Moeda Custo -->
                <v-col cols="12" md="3">
                  <v-select
                    v-model="selectedMoeda"
                    :items="moedas"
                    clearable
                    dense
                    outlined
                    required
                    :rules="formRules"
                    item-text="sigla"
                    item-value="id"
                    :disabled="moeda_empresa ? true : false"
                    label="Moeda Custo"
                    return-object
                  ></v-select>
                </v-col>

                <!-- Fornecedor -->
                <v-col cols="12" md="3">
                  <v-select
                    v-model.number="produto.fornecedor_id"
                    :items="fornecedores"
                    clearable
                    item-text="nome"
                    item-value="id"
                    dense
                    outlined
                    :label="$tc('global.fornecedor')"
                  ></v-select>
                </v-col>
                <!-- Estoque Min -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="produto.estoque_min"
                    label="Estoque Min"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Estoque Max -->
                <v-col cols="12" md="2 ">
                  <v-text-field
                    v-model="produto.estoque_max"
                    label="Estoque Max"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Qtde por Caixa -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="produto.qtde_caixa"
                    label="Qtde por caixa"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Unidade de Medida -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model="produto.und"
                    :items="unidades"
                    clearable
                    dense
                    outlined
                    label="Unidade de Medida"
                  ></v-select>
                </v-col>
                <!-- tipo imposto -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model="produto.tipo_imposto"
                    :items="tipo_imposto"
                    clearable
                    dense
                    item-text="descricao"
                    item-value="value"
                    outlined
                    :label="$tc('global.tipo') + ' ' + $tc('global.imposto')"
                  ></v-select>
                </v-col>
                <!-- Descrição detalhada -->
                <v-col cols="12">
                  <v-textarea
                    v-model="produto.descricao_detalhada"
                    label="Descrição detalhada"
                    :rows="3"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { postProdutos, getAllProClassAtivo } from "@/api/produtos/produtos.js";
import { inputRequired } from "@/helpers/utils.js";
import { getFornecedores } from "@/api/fornecedores/fornecedores.js";
import { mapState } from "vuex";

export default {
  name: "DialogAddProduto",

  props: {
    dialogAddProduto: {
      type: Boolean,
      default: false,
    },
    descProduto: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loading: false,
      produto: {},
      validForm: true,
      formRules: [inputRequired],
      categorias: [],
      fornecedores: [],
      selectedMoeda: null,
      loadingClass: true,
      img: null,
      imgUpload: null,
      hoverFile: false,
      unidades: [
        {
          text: this.$tc("global.metros"),
          value: "MTS",
        },
        {
          text: this.$tc("global.unidade", 2),
          value: "UND",
        },
        {
          text: "KG",
          value: "KGS",
        },
      ],
      produtoPreco: null,

      buscaProduto: "",
      setarProduto: true,
      tipo_imposto: [
        {
          descricao: "ISENTO",
          value: "ISENTO",
        },
        {
          descricao: "GRAVADO 10 %",
          value: "GRAVADO_10",
        },
        {
          descricao: "GRAVADO 5 %",
          value: "GRAVADO_5",
        },
        {
          descricao: "DECRETO",
          value: "DECRETO",
        },
      ],
    };
  },

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
      moeda: (state) => state.moedas[0],
    }),
    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),

    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },

    vCurrencyOptions() {
      return {
        precision: this.moeda.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  methods: {
    fileAdded(file) {
      this.imgUpload = file;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.img = event.target.result;
      };
    },

    fetchFornecedores() {
      getFornecedores()
        .then((response) => {
          this.fornecedores = response;
        })
        .catch((error) => console.log(error));
    },

    getProClass() {
      getAllProClassAtivo()
        .then((response) => {
          this.categorias = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loadingClass = false;
        });
    },

    criarProduto() {
      if (this.$refs.form.validate()) {
        const produto = new FormData();

        if (this.imgUpload) {
          produto.append("img", this.imgUpload, this.imgUpload.name);
          // produto.append("img64", this.img);
        }

        let produto_2 = {};
        produto_2.empresa_id = this.produto.empresa_id;
        produto_2.produto_categoria_id = this.produto.produto_categoria_id;
        produto_2.descricao = this.produto.descricao;
        produto_2.descricao_detalhada = this.produto.descricao_detalhada;
        produto_2.referencia = this.produto.referencia;
        produto_2.fornecedor_id = this.produto.fornecedor_id;

        produto_2.custo_moeda_id = this.produto.custo_moeda_id;
        produto_2.preco = this.produto.preco;
        produto_2.und = this.produto.und;
        produto_2.qtde_caixa = this.produto.qtde_caixa;
        produto_2.estoque_min = this.produto.estoque_min;
        produto_2.estoque_max = this.produto.estoque_max;
        produto_2.url = this.produto.url;
        produto_2.tipo_imposto = this.produto.tipo_imposto;

        this.buscaProduto = this.produto.descricao;

        for (let key in produto_2) {
          if (
            produto_2[key] !== null &&
            produto_2[key] !== undefined &&
            produto_2[key] !== ""
          ) {
            produto.append(key, produto_2[key]);
          }
        }

        postProdutos(produto)
          .then(() => {
            this.produto = {};
            this.$emit("update:buscaProduto", this.buscaProduto);
            this.$emit("update:setarProduto", this.setarProduto);
            this.$emit("setaProduto");
            this.$emit("update:dialogAddProduto", false);
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
  },

  mounted() {
    this.getProClass();
    this.fetchFornecedores();
    if (this.descProduto) {
      this.produto.descricao = this.descProduto;
    }
    if (this.moeda_empresa) {
      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda === this.moeda_empresa;
      });
      this.produto.custo_moeda_id = this.selectedMoeda.id_moeda;
    }
  },
};
</script>

<style lang="scss" scoped>
.input-center ::v-deep input {
  text-align: center;
}

.input-right ::v-deep input {
  text-align: right;
}

.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
