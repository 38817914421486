import api from "../axios_service.js";

export async function fetchCaixasMov(filtros) {
  const response = await api.get(`/caixas-mov${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function fetchCaixaMov(id) {
  const response = await api.get(`/caixas-mov/${id}`);
  return response.data.result;
}

export function putCaixaMov(id, caixa_mov) {
  return api.post(`/caixas-mov/${id}`, caixa_mov);
}

export function postCaixaMov(caixa_mov) {
  return api.post("/caixas-mov/add", caixa_mov);
}

export async function fetchCaixaMovTotais(id) {
  const response = await api.get(`/caixas-mov-totais/${id}`);
  return response.data.result;
}
