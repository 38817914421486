import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { i18n } from "@/plugins/i18n.js";
import { format, parseISO } from "date-fns";
import { fetchContasCliente } from "@/api/relatorios/relatorio_clientes.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();

  const filtro_get_extrato = bodyPDF.queryString;
  const cliente_info = await fetchContasCliente(filtro_get_extrato);
  const cabecalho = bodyPDF.cabecalho;
  const cliente = cliente_info.cliente;
  const cliente_contas = cliente_info.contas;
  const tipo_conta = cliente_info.tipo;

  return new Promise((resolve, reject) => {
    let table_cliente = {
      margin: [0, 5, 0, 5],
      table: {
        widths: ["*", "*", "*", "*", "*"],
        body: [
          [
            {
              text: i18n.tc("global.nome").toUpperCase(),
              border: [true, true, false, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: i18n.tc("global.telefone").toUpperCase(),
              border: [false, true, false, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: i18n.tc("global.celular").toUpperCase(),
              bold: true,
              alignment: "center",
              border: [false, true, false, false],
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },

            {
              text: i18n.tc("global.documento").toUpperCase(),
              border: [false, true, false, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
            {
              text: "E-MAIL",
              border: [false, true, true, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: 8,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i <= 1 || i === node.table.body.length ? 1 : 1;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
        // fillColor: function (rowIndex) {
        //   return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        // },
      },
    };

    const row_font_size = 7;
    cliente.forEach((cliente) => {
      let table_row = [
        {
          text: cliente.nome,
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [true, false, false, true],
        },
        {
          text: cliente.telefone,
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [false, false, false, true],
        },
        {
          text: cliente.celular,
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [false, false, false, true],
        },
        {
          text: cliente.doc_ci ? cliente.doc_ci : cliente.doc_ruc,
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [false, false, false, true],
        },
        {
          text: cliente.email,
          fontSize: row_font_size,
          alignment: "center",
          margin: [0, 4, 0, 5],
          border: [false, false, true, true],
        },
      ];

      table_cliente.table.body.push(table_row);
    });

    let cabecalho_vendas = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: "EXTRACTO DE CUENTAS " + tipo_conta,
              fillColor: "#F5F5F5",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };

    let content = [cabecalho, table_cliente, cabecalho_vendas];


    let table_venda = {
      margin: [0, 5, 0, 0],
      // border: [false, false, false, false],
      table: {
        widths: ["*", "*", "*", "*", "*", "*"],
        body: [
          [
            {
              text: i18n.tc("global.data-emissao").toUpperCase(),
              // border: [true, true, false, false],
              bold: true,
              fillColor: "#EBEEF9",
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 7,
            },
            {
              text: i18n.tc("global.data-vencimento").toUpperCase(),
              // border: [false, true, true, false],
              bold: true,
              fillColor: "#EBEEF9",
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 7,
            },
            {
              text: i18n.tc("global.moeda").toUpperCase(),
              bold: true,
              fillColor: "#EBEEF9",
              alignment: "center",
              // border: [false, true, false, false],
              // margin: [0, 4, 0, 2],
              fontSize: 7,
            },
            {
              text: i18n.tc("global.valor").toUpperCase(),
              fillColor: "#EBEEF9",
              bold: true,
              alignment: "right",
              fontSize: 7,
              // border: [false, true, false, false],
              // margin: [0, 4, 0, 2],
            },
            {
              text: i18n.tc("global.desconto").toUpperCase(),
              // border: [false, true, true, false],
              bold: true,
              fillColor: "#EBEEF9",
              alignment: "right",
              // margin: [0, 4, 0, 2],
              fontSize: 7,
            },
            {
              text: i18n.tc("global.saldo").toUpperCase(),
              // border: [false, true, true, false],
              bold: true,
              fillColor: "#EBEEF9",
              alignment: "right",
              // margin: [0, 4, 0, 2],
              fontSize: 7,
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
        hLineColor: function (i) {
          return i === 1 ? "#666666" : "#666666";
        },
        vLineColor: function (i) {
          return i === 1 ? "#666666" : "#666666";
        },
        fillColor: function (rowIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };
    cliente_contas.forEach((item_contas) => {
      const row_font_size = 6;

      let table_venda_row = [
        {
          text: format(parseISO(item_contas.created_at), "dd/MM/yyyy"),
          fontSize: row_font_size,
          alignment: "center",
          // border: [true, false, false, true],
        },
        {
          text: format(parseISO(item_contas.data_vencimento), "dd/MM/yyyy"),
          fontSize: row_font_size,
          alignment: "center",
          // border: [false, false, false, true],
        },
        {
          text: item_contas.moeda_sigla,
          fontSize: row_font_size,
          alignment: "center",
          // border: [false, false, false, true],
        },
        {
          text:
            item_contas.moeda_sigla == "G$"
              ? Vue.filter("guarani")(item_contas.valor)
              : Vue.filter("currency")(item_contas.valor),
          fontSize: row_font_size,
          alignment: "right",
          // border: [false, false, false, true],
        },
        {
          text:
            item_contas.moeda_sigla == "G$"
              ? Vue.filter("guarani")(
                item_contas.desconto ? item_contas.desconto : 0
              )
              : Vue.filter("currency")(
                item_contas.desconto ? item_contas.desconto : 0
              ),
          fontSize: row_font_size,
          alignment: "right",
          // border: [false, false, false, true],
        },
        {
          text:
            item_contas.moeda_sigla == "G$"
              ? Vue.filter("guarani")(item_contas.saldo)
              : Vue.filter("currency")(item_contas.saldo),
          fontSize: row_font_size,
          alignment: "right",
          // border: [false, false, false, true],
        },
        // {
        //   text: Vue.filter("excerpt")(cliente.endereco,20),
        //   fontSize: row_font_size,
        // },

        // {
        //   text: format(parseISO(cliente.created_at), "dd/MM/yyyy"),
        //   fontSize: row_font_size,
        //   alignment: "center",
        // },
      ];

      table_venda.table.body.push(table_venda_row);

    });
    content.push(table_venda);

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: "right",
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
