const routes = [
  {
    path: "/financeiro/cheques",
    component: () => import("@/views/dashboard/cheques/Cheques.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.cheque", plural: true },
      icon: "mdi-home-analytics",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.cheque", plural: true },
      ],
      permission: { componente: "Cheques", },
    },
  },
];

export default routes;
