<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.formapagamento', 2)"
      icon="mdi-cash-plus"
      dark
    />
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-btn
          @click="addFormaPagamento"
          color="button_1"
          class="white--text mt-4 mt-sm-0"
        >
          <DialogFormasPagamento
            v-if="dialogFormasPagamento"
            :dialogFormasPagamento.sync="dialogFormasPagamento"
            :edit="edit"
            :item="forma_pagamento"
            @fetch-formas-pagamentos="getFormasPagamentos"
          />
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :search="search.length >= 3 ? search : ''"
          :items="formas_pagamentos"
          :items-per-page="15"
          class="data-tables data-tables__row-click"
          @click:row="goToFormaPagamento"
        >
          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 1"> {{ $tc("global.ativo") }} </span>
            <span v-else>{{ $tc("global.inativo") }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";

export default {
  name: "FormasPagamentos",

  components: {
    DialogFormasPagamento: () =>
      import("./components/DialogFormasPagamento.vue"),
  },

  data() {
    return {
      loading: false,
      search: "",
      dialogFormasPagamento: false,
      forma_pagamento: {},
      formas_pagamentos_original: {},
      formas_pagamentos: [],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.status"),
          value: "status",
        },
      ];
    },
  },

  methods: {
    addFormaPagamento() {
      this.dialogFormasPagamento = true;
      this.edit = false;
    },

    goToFormaPagamento(item) {
      this.forma_pagamento = { ...item };
      this.dialogFormasPagamento = true;
      this.edit = true;
    },

    getFormasPagamentos() {
      this.loading = true;

      fetchFormasPagamentos()
        .then((response) => {
          this.formas_pagamentos = response;
          this.formas_pagamentos_original = { ...response };
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.getFormasPagamentos();
  },
};
</script>

<style></style>
