<template>
  <v-container fluid class="pa-6">
    <div class="d-flex pt-0">
      <v-spacer></v-spacer>
      <v-btn @click="addRef()" color="button_1" class="white--text">
        <v-icon left>mdi-check</v-icon>
        {{ $tc("global.salvar") }}
      </v-btn>
    </div>
    <!-- Co-deudor -->
    <v-row>
      <v-col cols="12">
        <span class="text-h6"> Co-deudor </span>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="12">
        <v-row dense>
          <!-- Nome completo -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="referencia.nome_conjugue"
              label="Nome Completo"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <!-- Documento -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.doc_ci"
              :label="$tc('global.documento') + ` CI`"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <!-- Documento -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.doc_ruc"
              :label="$tc('global.documento') + ` RUC`"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- Gênero -->
          <v-col cols="12" md="3">
            <v-select
              return-object
              v-model="selectedGenero"
              :label="$tc('global.genero')"
              :items="tipos_genero"
              dense
              outlined
            ></v-select>
          </v-col>

          <!-- Nacionalidade -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.nacionalidade"
              :label="$tc('global.nacionalidade')"
              dense
              outlined
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.estado_civil"
              :label="$tc('global.estado_civil')"
              dense
              outlined
            >
            </v-text-field>
          </v-col>

          <!-- Endereco -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.end_conjugue"
              :label="$tc('global.endereco')"
              dense
              outlined
            >
            </v-text-field>
          </v-col>
          <!-- Telefone -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="referencia.tel_conjugue"
              label="Telefone"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- E-mail -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="referencia.email_conjugue"
              label="E-MAIL"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Local Trabalho -->
    <v-row>
      <v-col cols="12">
        <span class="text-h6"> Local Trabalho </span>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="12">
        <v-row dense>
          <!-- Nome -->
          <v-col cols="12" md="7">
            <v-text-field
              v-model="referencia.nome_trabalho"
              label="Nome Empresa"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- Telefone -->
          <v-col cols="12" md="5">
            <v-text-field
              v-model="referencia.tel_trabalho"
              label="Telefone"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- Endereco Completo -->
          <v-col cols="12" md="12">
            <v-text-field
              v-model="referencia.end_trabalho"
              label="Endereco Completo"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- Nome contato -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="referencia.nome_contato_trabalho"
              label="Nome Contato Interno da Empresa"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- Telefone contato -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="referencia.tel_contato_trabalho"
              label="Telefone Contato Interno da Empresa"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- COMERCIAIS -->
    <v-row>
      <v-col cols="12">
        <span class="text-h6"> Comerciais </span>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="12">
        <v-row dense>
          <!-- Empresa 1-->
          <v-col cols="12" md="5">
            <v-text-field
              v-model="referencia.nome_empresa1"
              label="Empresa 1"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- RUC -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.ruc_empresa1"
              label="RUC"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- Telefone -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="referencia.tel_empresa1"
              label="Telefone"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <!-- Empresa 2-->
          <v-col cols="12" md="5">
            <v-text-field
              v-model="referencia.nome_empresa2"
              label="Empresa 2"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <!-- RUC -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.ruc_empresa2"
              label="RUC"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <!-- Telefone -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="referencia.tel_empresa2"
              label="Telefone"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <!-- Empresa 3-->
          <v-col cols="12" md="5">
            <v-text-field
              v-model="referencia.nome_empresa3"
              label="Empresa 3"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <!-- RUC -->
          <v-col cols="12" md="3">
            <v-text-field
              v-model="referencia.ruc_empresa3"
              label="RUC"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <!-- Telefone -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="referencia.tel_empresa3"
              label="Telefone"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  postClienteReferencias,
  fetchClienteReferencias,
} from "@/api/clientes/clientes_refencias.js";

export default {
  name: "ClienteReferencia",

  props: {
    cliente_id: {
      type: Number,
    },
  },

  data() {
    return {
      referencia: {},
      referencia_original: [],
      selectedGenero: null,
      tipos_genero: ["MASCULINO", "FEMININO", "NÃO DECLARAR"],
    };
  },

  watch: {
    selectedGenero() {
      this.referencia.genero = this.selectedGenero;
    },
  },

  methods: {
    async getReferencia() {
      await fetchClienteReferencias(this.cliente_id)
        .then((response) => {
          this.referencia_original = response;
          if (this.referencia_original.length > 0) {
            this.referencia = this.referencia_original[0];
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    addRef() {
      let referencia_2 = {};
      referencia_2.cliente_id = this.cliente_id;
      referencia_2.nome_conjugue = this.referencia.nome_conjugue;
      referencia_2.num_cedula_conjugue = this.referencia.num_cedula_conjugue;
      referencia_2.tel_conjugue = this.referencia.tel_conjugue;
      referencia_2.email_conjugue = this.referencia.email_conjugue;
      referencia_2.end_conjugue = this.referencia.end_conjugue;
      referencia_2.nome_trabalho = this.referencia.nome_trabalho;
      referencia_2.tel_trabalho = this.referencia.tel_trabalho;
      referencia_2.end_trabalho = this.referencia.end_trabalho;
      referencia_2.nome_contato_trabalho =
        this.referencia.nome_contato_trabalho;
      referencia_2.tel_contato_trabalho = this.referencia.tel_contato_trabalho;
      referencia_2.nome_empresa1 = this.referencia.nome_empresa1;
      referencia_2.ruc_empresa1 = this.referencia.ruc_empresa1;
      referencia_2.tel_empresa1 = this.referencia.tel_empresa1;
      referencia_2.nome_empresa2 = this.referencia.nome_empresa2;
      referencia_2.ruc_empresa2 = this.referencia.ruc_empresa2;
      referencia_2.tel_empresa2 = this.referencia.tel_empresa2;
      referencia_2.nome_empresa3 = this.referencia.nome_empresa3;
      referencia_2.ruc_empresa3 = this.referencia.ruc_empresa3;
      referencia_2.tel_empresa3 = this.referencia.tel_empresa3;

      referencia_2.genero = this.referencia.genero;
      referencia_2.doc_ci = this.referencia.doc_ci;
      referencia_2.doc_ruc = this.referencia.doc_ruc;
      referencia_2.nacionalidade = this.referencia.nacionalidade;
      referencia_2.estado_civil = this.referencia.estado_civil;
      referencia_2.endereco = this.referencia.endereco;

      postClienteReferencias(referencia_2).catch((error) => {
        console.log(error.response);
      });
    },
  },

  async mounted() {
    await this.getReferencia();
    if (this.referencia.genero) {
      this.selectedGenero = this.referencia.genero;
    }
  },
};
</script>

<style></style>
