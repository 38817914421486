<template>
  <v-dialog
    v-model="dialogTransferencia"
    scrollable
    @click:outside="$emit('update:dialogTransferencia', false)"
    @keydown.esc="$emit('update:dialogTransferencia', false)"
    max-width="550px"
  >
    <v-card>
      <v-card-title class="tocs_gray_1">
        {{ $tc("global.transferencia") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogTransferencia', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6" v-if="totais_caixa">
        <v-row>
          <v-col cols="12" md="4">
            <v-card outlined class="pa-1 d-flex align-center">
              <span class="pl-2 text-subtitle-1">G$</span>

              <v-spacer></v-spacer>
              <span class="pr-2 text-subtitle-1">
                {{ totais_caixa.saldo_guarani | guarani }}
              </span>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined class="pa-1 d-flex align-center">
              <span class="pl-2 text-subtitle-1">U$</span>

              <v-spacer></v-spacer>
              <span class="pr-2 text-subtitle-1">
                {{ totais_caixa.saldo_dolar | currency }}
              </span>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card outlined class="pa-1 d-flex align-center">
              <span class="pl-2 text-subtitle-1">R$</span>

              <v-spacer></v-spacer>
              <span class="pr-2 text-subtitle-1">
                {{ totais_caixa.saldo_real | currency }}
              </span>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="selectCaixa"
              :items="caixas"
              :loading="loadingCaixas"
              label="Caixa Destino"
              item-text="descricao"
              item-value="id"
              dense
              hide-details
              outlined
              return-object
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="selectMoeda"
              :items="moedas"
              label="Moeda"
              item-text="sigla"
              item-value="id_moeda"
              :disabled="selectCaixa ? false : true"
              dense
              hide-details
              outlined
              return-object
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field
              ref="valor"
              label="Valor"
              v-model="valor"
              dense
              reverse
              outlined
              hide-details
              append-icon="mdi-cash"
              min="0"
              :disabled="selectMoeda ? false : true"
              :prefix="selectMoeda ? selectMoeda.sigla : ''"
              :rules="[rules.validador]"
              v-currency="vCurrencyOptions"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text small @click="$emit('update:dialogTransferencia', false)">
          {{ $tc("global.cancelar") }}
        </v-btn>

        <v-btn
          color="button_2"
          class="white--text"
          depressed
          small
          :loading="loadingTransferencia"
          :disabled="disableTransferencia"
          @click="iniciarTransferencia"
        >
          {{ $tc("global.transferencia") }}
          <v-icon right>mdi-swap-horizontal</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="Transferencia de Caixa"
      @close="finalizarTransferenciaCaixa()"
    />
  </v-dialog>
</template>

<script>
import { fetchCaixaAfTotais } from "@/api/caixas/caixas_af.js";
import { fetchCaixaMovTotais } from "@/api/caixas/caixas_mov.js";
import {
  fetchCaixas,
  postTransferenciaCaixaPdv,
  postTransferenciaCaixa,
} from "@/api/caixas/caixas.js";
import { mapState } from "vuex";

const generateTransferenciaCaixa = () =>
  import(
    /* webpackChunkName: "fechamentoPdf" */ "../reports/pdvTransferenciaPdf.js"
  );

export default {
  name: "DialogTransferencia",

  props: {
    dialogTransferencia: {
      type: Boolean,
      default: false,
    },
    caixaPdv: {
      type: Boolean,
      default: false,
    },
  },

  components: {},

  data() {
    return {
      loading: false,
      loadingCaixas: false,
      loadingTransferencia: false,
      totais_caixa: {},
      caixas: [],
      selectCaixa: null,
      valor: 0,
      selectMoeda: null,
      valorMax: 0,
      pdfDialog: false,
    };
  },

  computed: {
    caixa_id() {
      return this.$route.params.caixa_id;
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    rules() {
      return {
        validador: (valor) =>
          this.$ci.parse(valor, this.vCurrencyOptions) <= this.valorMax,
      };
    },
    vCurrencyOptions() {
      return {
        precision: this.selectMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    disableTransferencia() {
      let result = false;
      if (!this.selectCaixa || !this.selectMoeda || !this.valor) {
        result = true;
      }
      if (this.$ci.parse(this.valor, this.vCurrencyOptions) > this.valorMax) {
        result = true;
      }
      if (this.$ci.parse(this.valor, this.vCurrencyOptions) == 0) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    selectCaixa() {
      if (this.selectCaixa) {
        if (this.selectCaixa.moeda_id) {
          this.selectMoeda = this.moedas.find(
            (moeda) => moeda.id_moeda == this.selectCaixa.moeda_id
          );
          this.moedas.find((moeda) => {
            if (moeda.moeda_id == this.selectCaixa.moeda_id) {
              moeda.disabled = false;
            } else {
              moeda.disabled = true;
            }
          });
        } else {
          this.selectMoeda = null;
          this.moedas.find((moeda) => {
            moeda.disabled = false;
          });
        }
      }
    },

    selectMoeda() {
      if (this.selectMoeda) {
        if (this.selectMoeda.id_moeda == 1) {
          this.valorMax = Number(this.totais_caixa.saldo_real);
        } else if (this.selectMoeda.id_moeda == 2) {
          this.valorMax = Number(this.totais_caixa.saldo_guarani);
        } else if (this.selectMoeda.id_moeda == 3) {
          this.valorMax = Number(this.totais_caixa.saldo_dolar);
        }
      } else {
        this.valorMax = 0;
      }
    },
  },

  methods: {
    getCaixaMovPdvTotais() {
      this.loading = true;
      return fetchCaixaAfTotais(this.caixa_id)
        .then((response) => {
          this.totais_caixa = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getCaixaMovTotais() {
      this.loading = true;
      return fetchCaixaMovTotais(this.caixa_id)
        .then((response) => {
          this.totais_caixa = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getCaixas() {
      this.loadingCaixas = true;
      if (this.caixaPdv) {
        return fetchCaixas()
          .then((response) => {
            this.caixas = response;
            this.caixas.find((caixa) => {
              if (caixa.tipo_caixa == "PDV") {
                caixa.disabled = true;
              } else {
                caixa.disabled = false;
              }
            });
            this.loadingCaixas = false;
          })
          .catch((error) => {
            this.loadingCaixas = false;
          });
      } else {
        let query = `?exclude_caixa_id=${this.caixa_id}&caixas_aberto=true`;
        return fetchCaixas(query)
          .then((response) => {
            this.caixas = response;
            this.caixas.find((caixa) => {
              if (caixa.tipo_caixa == "PDV" && caixa.caixa_aberto == true) {
                caixa.disabled = false;
              } else if (
                caixa.tipo_caixa == "PDV" &&
                caixa.caixa_aberto == false
              ) {
                caixa.disabled = true;
              }
            });
            this.loadingCaixas = false;
          })
          .catch((error) => {
            this.loadingCaixas = false;
          });
      }
    },
    async iniciarTransferencia() {
      this.loadingTransferencia = true;
      let data_transferencia = {
        caixa_origem_id: this.caixa_id,
        caixa_destino_id: this.selectCaixa.id,
        moeda_id: this.selectMoeda.id_moeda,
        valor: this.$ci.parse(this.valor, this.vCurrencyOptions),
      };

      this.caixaPdv
        ? await postTransferenciaCaixaPdv(data_transferencia)
            .then(async (response) => {
              if (response.status == 200) {
                await this.openFechamentoPdf(response.data.recibo);
              }
            })
            .catch((error) => {})
            .finally(() => {
              this.loadingTransferencia = false;
            })
        : await postTransferenciaCaixa(data_transferencia)
            .then(async (response) => {
              if (response.status == 200) {
                await this.openFechamentoPdf(response.data.recibo);
              }
            })
            .catch((error) => {})
            .finally(() => {
              this.loadingTransferencia = false;
            });
    },

    openFechamentoPdf(recibo) {
      this.loadingTransferencia = true;
      return generateTransferenciaCaixa().then((module) => {
        let generate = module.generateTransferencia;
        generate([recibo])
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loadingTransferencia = false;
          })
          .catch(() => {
            this.loadingTransferencia = false;
          });
      });
    },
    finalizarTransferenciaCaixa() {
      this.$emit("update:dialogTransferencia", false);
      this.$emit("fetch-caixas-items");
      this.$toast.success(`Transferencia realizada com sucesso`);
    },
  },

  created() {},

  async mounted() {
    if (this.caixaPdv) {
      await this.getCaixaMovPdvTotais();
    } else {
      await this.getCaixaMovTotais();
    }
    await this.getCaixas();
  },
};
</script>

<style></style>
