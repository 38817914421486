const initialState = () => ({
  filtros: {
    dataFrom: null,
    dataTo: null,
    cidade: null,
    status: null,
    cliente: null,
    moeda: null,
  },
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_DATAFROM(state, payload) {
    state.filtros.dataFrom = payload;
  },
  UPDATE_DATATO(state, payload) {
    state.filtros.dataTo = payload;
  },
  UPDATE_CLIENTE(state, payload) {
    state.filtros.cliente = payload;
  },
  UPDATE_STATUS(state, payload) {
    state.filtros.status = payload;
  },
  UPDATE_MOEDA(state, payload) {
    state.filtros.moeda = payload;
  },
  UPDATE_CIDADE(state, payload) {
    state.filtros.cidade = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
