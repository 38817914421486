const routes = [
  {
    path: "/home",
    component: () => import("@/views/dashboard/home/Home.vue"),
    meta: {
      menu: "dashboard",
     
      title: { text: "global.home" },
      icon: "mdi-home-outline",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
      ],
    
     
    },
  },
];

export default routes;
