import api from "../axios_service.js";

export async function getClientesCategorias(filtros) {
  const response = await api.get(
    `/clientes-categorias${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getListClientesCategorias(filtros) {
  const response = await api.post(`/listaclientes-categorias${filtros}`);
  return response.data.result;
}

// Busca um clientes
export async function getClienteCategorias(id) {
  const response = await api.get(`/clientes-categorias/${id}`);
  return response.data.result;
}

// Atualiza um cliente existente
export function putClientesCategorias(id, cliente_categoria) {
  let body = {};
  for (let key in cliente_categoria) {
    body[key] = cliente_categoria[key];
  }
  return api.put(`/clientes-categorias/${id}`, body);
}

// Cria um cliente novo
export function postClienteCategoria(cliente_categoria) {
  let body = {};
  for (let key in cliente_categoria) {
    body[key] = cliente_categoria[key];
  }
  return api.post("/clientes-categorias/add", body);
}
