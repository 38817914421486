<template>
  <v-dialog
    v-model="dialogCancelarVendaCaixa"
    @click:outside="$emit('update:dialogCancelarVendaCaixa', false)"
    @keydown.esc="$emit('update:dialogCancelarVendaCaixa', false)"
    width="500"
  >
    <v-card>
      <v-card-title>
        <v-icon color="red" left>mdi-alert</v-icon>
        {{ $tc("global.cancelar").toUpperCase() }} -
        {{ registroCaixa.historico }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-5">
        <v-textarea
          v-model="motivo_cancelamento"
          label="Motivo para cancelamento esse registro"
          dense
          rows="2"
          hide-details
          outlined
          required
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="$emit('update:dialogCancelarVendaCaixa', false)">
          {{ $tc("global.voltar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :disabled="semMotivo"
          @click="deletarVenda"
          small
          color="red"
          class="white--text"
        >
          <v-icon left>mdi-delete</v-icon>
          {{ $tc("global.cancelar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { putCancelarRegistroVenda } from "@/api/caixas/caixas.js";
export default {
  name: "DialogCancelarVendaCaixa",

  props: {
    dialogCancelarVendaCaixa: {
      type: Boolean,
      default: false,
    },
    registroCaixa: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      motivo_cancelamento: "",
    };
  },

  computed: {
    semMotivo() {
      let result = false;

      if (
        this.motivo_cancelamento === "" ||
        this.motivo_cancelamento === null ||
        this.motivo_cancelamento === undefined ||
        this.motivo_cancelamento.length < 10
      ) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    deletarVenda() {
      this.loading = true;

      putCancelarRegistroVenda(this.registroCaixa.id, {
        motivo_cancelamento: this.motivo_cancelamento,
      })
        .then((response) => {
          if (response.status === 200) {
            this.$emit("fetch-movimentacoes");
            this.$emit("update:dialogCancelarVendaCaixa", false);
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
