<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.gasto', 2)"
      icon="mdi-chart-box-plus-outline"
      dark
    />

    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 350px"
          append-icon="mdi-magnify"
          v-model="search"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="gastos"
          :search="search"
          :items-per-page="10"
          class="data-tables data-tables__row-click"
          @click:row="goToGasto"
        >
          <template v-slot:item.valor="{ item }">
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>{{ item.valor | currency }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogGasto
      v-if="dialogGasto"
      :dialogGasto.sync="dialogGasto"
      :item="fatura_gastos"
    />
  </v-container>
</template>

<script>
import { fetchFaturasGastos } from "@/api/faturas-gastos/faturas-gastos.js";
export default {
  name: "Gastos",

  components: {
    DialogGasto: () => import("./components/DialogGasto.vue"),
  },

  data() {
    return {
      search: "",
      gastos: [],
      fatura_gastos: {},
      dialogGasto: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "center",
          value: "id",
          ortable: false,
        },
        {
          text: this.$tc("global.fatura"),
          align: "center",
          value: "numero_fatura",
        },
        {
          text: this.$tc("global.fornecedor"),
          align: "center",
          value: "fornecedor_nome",
        },
        {
          text: "RUC",
          value: "ruc",
        },

        {
          text: this.$tc("global.moeda"),
          align: "center",
          value: "moeda_sigla",
        },
        {
          text: this.$tc("global.valor"),
          align: "center",
          value: "valor",
        },
      ];
    },
  },

  methods: {
    goToGasto(item) {
      this.fatura_gastos = { ...item };
      this.dialogGasto = true;
    },

    getAll() {
      fetchFaturasGastos().then((response) => {
        this.gastos = response;
      });
    },
  },

  mounted() {
    this.getAll();
  },
};
</script>

<style></style>
