<template>
  <v-select
    v-model="status"
    :items="campos"
    clearable
    @click:clear="clearStatus"
    outlined
    dense
    hide-details
    item-text="text"
    item-value="value"
    label="Status"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Status",

  data() {
    return {
      status: null,
      campos: null,
    };
  },

  computed: {
    ...mapState("FiltrosRelatorios", {
      filtros_componente: (state) => state.filtros_componente,
    }),
  },

  watch: {
    status() {
      if (this.status) {
        this.$store.commit("FiltrosRelatorios/UPDATE_STATUS", this.status);
      }
    },
  },

  methods: {
    clearStatus() {
      this.$store.commit("FiltrosRelatorios/UPDATE_STATUS", null);
      this.status = null;
    },
  },

  async created() {
    this.filtros_componente.find((item) => {
      if (item.text == "Status") {
        return (this.campos = item.options);
      }
    });
  },
};
</script>

<style></style>
