<template>
  <v-dialog
    v-model="dialogCompensacaoCartao"
    @click:outside="$emit('update:dialogCompensacaoCartao', false)"
    @keydown.esc="$emit('update:dialogCompensacaoCartao', false)"
    :width="!selectedCaixa ? '65%' : '85%'"
    scrollable
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.compensacao") }} {{ $tc("global.cartao", 2) }}

        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogCompensacaoCartao', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-8" style="overflow: auto">
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-combobox
                ref="combobox"
                v-model="selectedCaixa"
                :items="caixas"
                :label="$tc('global.destino')"
                :readonly="caixas.length === 0 ? true : false"
                dense
                outlined
                :rules="formRules"
                :loading="loadingBancos"
                required
                hide-details
                item-value="id"
              >
                <template v-slot:selection="data">
                  <span class="caption">
                    {{ data.item.descricao }} - {{ data.item.banco_nome }}</span
                  >
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.descricao"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.banco_nome"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="referencia"
                ref="referencia"
                @blur="inputReferencia"
                :label="$tc('global.referencia')"
                :rules="formRules"
                :readonly="!selectedCaixa"
                dense
                outlined
                required
                hide-details
              ></v-text-field>
            </v-col>
            <v-col v-show="openTable" class="">
              <v-card outlined>
                <v-data-table
                  :headers="headersCartoes"
                  :items="selectedCartoes"
                  hide-default-footer
                  item-key="id"
                  class="data-table"
                >
                  <template v-slot:item.valor="{ item }">
                    <div v-if="item.moeda_sigla == 'G$'">
                      {{ item.valor | guarani }}
                    </div>
                    <div v-else>
                      {{ item.valor | currency }}
                    </div>
                  </template>

                  <template v-slot:item.comissao="{ item }">
                    <div v-if="item.moeda_sigla == 'G$'">
                      {{ item.comissao | guarani }}
                    </div>
                    <div v-else>
                      {{ item.comissao | currency }}
                    </div>
                  </template>

                  <template v-slot:item.comissao_iva="{ item }">
                    <div v-if="item.moeda_sigla == 'G$'">
                      {{ item.comissao_iva | guarani }}
                    </div>
                    <div v-else>
                      {{ item.comissao_iva | currency }}
                    </div>
                  </template>

                  <template v-slot:item.ret_renta="{ item }">
                    <div v-if="item.moeda_sigla == 'G$'">
                      {{ item.ret_renta | guarani }}
                    </div>
                    <div v-else>
                      {{ item.ret_renta | currency }}
                    </div>
                  </template>

                  <template v-slot:item.ret_iva="{ item }">
                    <div v-if="item.moeda_sigla == 'G$'">
                      {{ item.ret_iva | guarani }}
                    </div>
                    <div v-else>
                      {{ item.ret_iva | currency }}
                    </div>
                  </template>

                  <template v-slot:item.total="{ item }">
                    <div v-if="item.moeda_sigla == 'G$'">
                      {{ item.total | guarani }}
                    </div>
                    <div v-else>
                      {{ item.total | currency }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
              <v-card class="mt-2" outlined>
                <v-data-table
                  hide-default-footer
                  :items="gruopCartoes"
                  :loading="loadingGrupoCartoes"
                  :headers="headers"
                >
                  <template v-slot:item.total="{ item }">
                    <div>
                      {{ item.total | guarani }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!validForm"
          :loading="loading"
          color="button_2"
          @click="compensarCartao"
          class="white--text"
          depressed
          small
        >
          {{ $tc("global.compensar") }}
          <v-icon right>mdi-cash-sync</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import { fetchCaixasContaCorrente } from "@/api/caixas/caixas.js";
import { postCompensarCartoes } from "@/api/compensacao-cartoes/compensacao_cartoes.js";
export default {
  name: "DialogCompensacaoCartoes",

  props: {
    dialogCompensacaoCartao: {
      type: Boolean,
      default: false,
    },
    selectedCartoes: {
      type: Array,
      default: () => [],
    },
  },

  components: {},

  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      selectedCaixa: null,
      referencia: "",
      caixas: [],
      loadingBancos: true,
      gruopCartoes: [],
      loadingGrupoCartoes: false,
    };
  },

  watch: {
    selectedCaixa() {
      if (this.selectedCaixa) {
        this.$nextTick(() => this.$refs.referencia.focus());
      }
    },
  },

  computed: {
    openTable() {
      let open = false;
      if (this.selectedCaixa) {
        open = true;
      }
      return open;
    },
    headersCartoes() {
      return [
        {
          text:  this.$tc("global.data"),
          value: "created_at",
          sortable: false,
          // width: "70px",
        },
        {
          text:  this.$tc("global.cartao"),
          value: "cartao_bandeira",
          sortable: false,
          // width: "70px",
        },
        {
          text: this.$tc("global.codigoAutorizacao"),
          value: "codigo_autorizacao",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          sortable: false,
        },
        {
          text: this.$tc("global.valor") + ' ' +  this.$tc("global.cartao"),
          value: "valor",
          sortable: false,
          // width: "180px",
        },
        {
          text: this.$tc("global.comissao"),
          value: "comissao",
          align: "right",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.comissao") + ' IVA',
          value: "comissao_iva",
          align: "right",
          sortable: false,
          // width: "100px",
        },
        {
          text: "Ret. Renta",
          value: "ret_renta",
          align: "right",
          sortable: false,
          // width: "150px",
        },
        {
          text: "Ret. Iva",
          value: "ret_iva",
          align: "right",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
          align: "right",
          sortable: false,
          // width: "100px",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$tc("global.referencia"),
          value: "referencia",
          sortable: false,
          // width: "100px",
        },

        {
          text: this.$tc("global.destino"),
          value: "caixa_destino_nome",
          sortable: false,
          // width: "100px",
        },

        {
          text: this.$tc("global.banco"),
          value: "banco_nome",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          sortable: false,
          // width: "100px",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
          sortable: false,
          right: true,
          // width: "100px",
        },
      ];
    },
  },

  methods: {
    inputReferencia() {
      this.loadingGrupoCartoes = true;
      this.gruopCartoes = [];
      if (this.selectedCartoes.length > 0) {
        let total_valor = this.selectedCartoes.reduce(
          (accumulator, item) => accumulator + Number(item.total),
          0
        );
        let obj = {
          moeda_sigla: this.selectedCartoes[0].moeda_sigla,
          moeda_id: this.selectedCartoes[0].moeda_id,
          caixa_destino_id: this.selectedCaixa.id,
          caixa_destino_nome: this.selectedCaixa.descricao,
          banco_id: this.selectedCaixa.banco_id,
          banco_nome: this.selectedCaixa.banco_nome,
          referencia: this.referencia,
          total: total_valor,
        };
        this.gruopCartoes.push(obj);
      } else {
        this.gruopCartoes = [];
      }
      this.loadingGrupoCartoes = false;
    },
    getCaixasContaCorrent() {
      this.loadingBancos = true;
      return fetchCaixasContaCorrente()
        .then((response) => {
          response.find((item) => {
            if (item.moeda_id == this.selectedCartoes[0].moeda_id) {
              this.caixas.push(item);
            }
          });
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingBancos = false;
          if (this.caixas.length == 0) {
            this.$toast.error(this.$tc("global.nenhumcaixaencontradomoeda"));
          }
          this.$nextTick(() => this.$refs.combobox.focus());
        });
    },
    compensarCartao() {
      this.loading = true;

      if (this.$refs.form.validate()) {
        let data = {
          caixa_destino_id: this.selectedCaixa.id,
          cartao: this.gruopCartoes[0],
          cartoes: this.selectedCartoes,
        };
        postCompensarCartoes(data)
          .then((response) => {
            this.$emit("update:dialogCompensacaoCartao", false);
            this.$emit("fetched-cartoes");
          })
          .catch((error) => {
            this.$toast.error(this.$tc("global.erro"));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.getCaixasContaCorrent();
  },
};
</script>

<style>
</style>