import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import Vue from "vue";

import { format, parseISO } from "date-fns";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";
import { fetchExtratoFornecedor } from "@/api/relatorios/relatorio_fornecedor.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };
  const bodyPDF = await layoutEmpresa();
  const cabecalho = bodyPDF.cabecalho;
  const periodo = bodyPDF.periodo;

  const filtro_get_extrato = bodyPDF.queryString;

  const fornecedor_compra_info = await fetchExtratoFornecedor(
    filtro_get_extrato
  );

  const fornecedor = fornecedor_compra_info.fornecedor;
  const compras = fornecedor_compra_info.compras;

  // const cabecalho = await getCabecalho();
  return new Promise((resolve) => {
    let content = [];
    content.push(cabecalho);

    // -------------------------------------------- tabela fornecedor

    let table_font_size = 10;
    let row_font_size = 9;
    let table_fornecedor = {
      margin: [0, 5, 0, 5],
      table: {
        widths: [
          "*",
          "*",
          "*",
          "*",
          "*",
          // 120,
          // 120
        ],
        body: [
          [
            {
              text: "NOME",
              border: [true, true, false, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: table_font_size,
            },
            {
              text: "TELEFONE",
              border: [false, true, false, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: table_font_size,
            },
            {
              text: "CELULAR",
              bold: true,
              alignment: "center",
              border: [false, true, false, false],
              margin: [0, 4, 0, 2],
              fontSize: table_font_size,
            },

            {
              text: "DOCUMENTO",
              border: [false, true, false, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: table_font_size,
            },
            {
              text: "E-MAIL",
              border: [false, true, true, false],
              bold: true,
              alignment: "center",
              margin: [0, 4, 0, 2],
              fontSize: table_font_size,
            },
          ],
          [
            {
              text: fornecedor.nome,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [true, false, false, true],
            },
            {
              text: fornecedor.telefone,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [false, false, false, true],
            },
            {
              text: fornecedor.celular,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [false, false, false, true],
            },
            {
              text: fornecedor.documento,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [false, false, false, true],
            },
            {
              text: fornecedor.email,
              fontSize: row_font_size,
              alignment: "center",
              margin: [0, 4, 0, 5],
              border: [false, false, true, true],
            },
            // {
            //   text: Vue.filter("excerpt")(cliente.endereco,20),
            //   fontSize: row_font_size,
            // },

            // {
            //   text: format(parseISO(cliente.created_at), "dd/MM/yyyy"),
            //   fontSize: row_font_size,
            //   alignment: "center",
            // },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 2 : 1;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 2 : 1;
        },
        hLineColor: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? "#666666"
            : "#666666";
        },
        vLineColor: function (i, node) {
          return i === 0 || i === node.table.widths.length
            ? "#666666"
            : "#666666";
        },
      },
    };
    content.push(table_fornecedor);

    // -------------------------------------------- extrato compra

    let cabecalho_extrato_compra = {
      margin: [0, 0, 0, 5],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text:
                "EXTRATO - " + periodo,
              fillColor: "#E0E0E0",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };
    content.push(cabecalho_extrato_compra);

    // -------------------------------------------- compras item

    compras.forEach((compra) => {
      let principal = [];
      let table_header_font = 6;
      let row_header_font = 6;
      let table_compra_color = "#263238";
      let table_compra = {
        margin: [0, 0, 0, 0],
        // border: [false, false, false, false],
        table: {
          widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [
              {
                text: "FATURA N.",
                bold: true,
                color: "#FFFFFF",
                fillColor: table_compra_color,
                alignment: "center",
                fontSize: table_header_font,
              },
              {
                text: "DOCUMENTO",
                bold: true,
                fillColor: table_compra_color,
                color: "#FFFFFF",
                alignment: "center",
                fontSize: table_header_font,
              },
              {
                text: "USUARIO",
                fillColor: table_compra_color,
                bold: true,
                color: "#FFFFFF",
                alignment: "center",
                fontSize: table_header_font,
              },
              {
                text: "DATA COMPRA",
                bold: true,
                fillColor: table_compra_color,
                color: "#FFFFFF",
                alignment: "center",
                fontSize: table_header_font,
              },
              {
                text: "CONDIÇÃO",
                bold: true,
                color: "#FFFFFF",
                alignment: "center",
                fillColor: table_compra_color,
                fontSize: table_header_font,
              },
              {
                text: "TIPO COMPRA",
                bold: true,
                alignment: "center",
                color: "#FFFFFF",
                fillColor: table_compra_color,
                fontSize: table_header_font,
              },
              {
                text: "CAIXA",
                bold: true,
                fillColor: table_compra_color,
                color: "#FFFFFF",
                alignment: "center",
                fontSize: table_header_font,
              },

              {
                text: "VALOR CAMBIO",
                bold: true,
                fillColor: table_compra_color,
                color: "#FFFFFF",
                alignment: "center",
                fontSize: table_header_font,
              },
            ],
            [
              {
                text: compra.id,
                alignment: "center",
                fillColor: "#ECEFF1",
                fontSize: row_header_font,
              },
              {
                text:
                  compra.tipo_compra == 3 ? compra.invoice : compra.ncontrol,
                fillColor: "#ECEFF1",
                alignment: "center",
                fontSize: row_header_font,
              },
              {
                text: compra.usuario_nome,
                fontSize: row_header_font,
                alignment: "center",
                fillColor: "#ECEFF1",
              },
              {
                text: format(parseISO(compra.data_compra), "dd/MM/yyyy"),
                fontSize: row_header_font,
                alignment: "center",
                fillColor: "#ECEFF1",
              },
              {
                text: compra.condpago == 1 ? "À vista" : "À prazo",
                fontSize: row_header_font,
                alignment: "center",
                fillColor: "#ECEFF1",
              },
              {
                text:
                  compra.tipo_compra == 1
                    ? "Sem Fatura"
                    : compra.tipo_compra == 2
                      ? "Com Fatura"
                      : "Importação",
                fontSize: row_header_font,
                alignment: "center",
                fillColor: "#ECEFF1",
              },
              {
                text: compra.caixa_descricao,
                fontSize: row_header_font,
                alignment: "center",
                fillColor: "#ECEFF1",
              },
              {
                text: compra.cambio,
                fontSize: row_header_font,
                alignment: "center",
                fillColor: "#ECEFF1",
              },
              // {
              //   text: Vue.filter("excerpt")(cliente.endereco,20),
              //   fontSize: row_font_size,
              // },

              // {
              //   text: format(parseISO(cliente.created_at), "dd/MM/yyyy"),
              //   fontSize: row_font_size,
              //   alignment: "center",
              // },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
        },
      };

      principal.push(table_compra);

      // -------------------------------------------- compras items
      let total_items_compra = 0;
      let total_items_desconto = 0;
      if (compra.compra_items.length > 0) {
        let item_table_font = 5;
        let item_row_font = 5;
        let table_header_color = "#CFD8DC";
        let table_header_compra_item = {
          margin: [30, 3, 0, 0],
          table: {
            widths: ["*", "*", "*", "*"],
            headerRows: 2,
            body: [
              [
                {
                  text: "ITEMS",
                  fillColor: table_header_color,
                  bold: true,
                  colSpan: 4,
                  alignment: "center",
                  fontSize: item_table_font + 1,
                },
                {},
                {},
                {},
              ],
              [
                {
                  text: "DESCRIÇÃO",
                  bold: true,
                  // border: [true, true, true, true],
                  alignment: "center",
                  fontSize: item_table_font,
                  fillColor: "#EEEEEE",
                },
                {
                  text: "QUANTIDADE",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_font,
                  fillColor: "#EEEEEE",
                },
                {
                  text: "DESCONTO",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_font,
                  fillColor: "#EEEEEE",
                },
                {
                  text: "PREÇO",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_font,
                  fillColor: "#EEEEEE",
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
            },
            hLineColor: function (i) {
              return i === 1 ? "#666666" : "#666666";
            },
            vLineColor: function (i) {
              return i === 1 ? "#666666" : "#666666";
            },
          },
        };
        compra.compra_items.forEach((item, index) => {
          let table_row = [
            {
              text: item.produto_descricao,
              fontSize: item_row_font,
              alignment: "center",
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              border: [
                true,
                false,
                false,
                index != compra.compra_items.length - 1 ? false : true,
              ],
            },
            {
              text: item.qtde,
              fontSize: item_row_font,
              alignment: "center",
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              border: [
                false,
                false,
                false,
                index != compra.compra_items.length - 1 ? false : true,
              ],
            },
            {
              text:
                compra.moeda_sigla == "G$"
                  ? Vue.filter("guarani")(item.desconto)
                  : Vue.filter("currency")(item.desconto),
              fontSize: item_row_font,
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              alignment: "center",
              border: [
                false,
                false,
                false,
                index != compra.compra_items.length - 1 ? false : true,
              ],
            },
            {
              text:
                compra.moeda_sigla == "G$"
                  ? Vue.filter("guarani")(item.fob_origem)
                  : Vue.filter("currency")(item.fob_origem),
              fontSize: item_row_font,
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              alignment: "center",
              border: [
                false,
                false,
                true,
                index != compra.compra_items.length - 1 ? false : true,
              ],
            },
          ];

          table_header_compra_item.table.body.push(table_row);
        });

        total_items_desconto = compra.compra_items.reduce(
          (accumulator, item) => accumulator + Number(item.desconto),
          0
        );
        total_items_compra = compra.compra_items.reduce(
          (accumulator, item) => accumulator + Number(item.fob * item.qtde),
          0
        );

        let total_row = [
          {
            text: "TOTAL",
            fontSize: item_row_font,
            alignment: "center",
            colSpan: 2,
            // fillColor: table_header_color,
          },
          {},
          {
            text:
              compra.moeda_sigla == "G$"
                ? Vue.filter("guarani")(total_items_desconto)
                : Vue.filter("currency")(total_items_desconto),
            fontSize: item_row_font,
            alignment: "center",
            // fillColor: table_header_color,
          },
          {
            text:
              compra.moeda_sigla == "G$"
                ? Vue.filter("guarani")(total_items_compra)
                : Vue.filter("currency")(total_items_compra),
            fontSize: item_row_font,
            alignment: "center",
            // fillColor: table_header_color,
          },
        ];

        table_header_compra_item.table.body.push(total_row);
        principal.push(table_header_compra_item);
      }

      // -------------------------------------------- compras items depesas

      let total_items_despesas = 0;
      if (compra.compra_despesas.length > 0) {
        let item_table_despesa_font = 5;
        let item_row_despesa_font = 5;
        let table_header_despesa_color = "#CFD8DC";
        let table_header_compra_item_despesa = {
          margin: [30, 3, 0, 0],
          table: {
            headerRows: 2,
            widths: ["*", "*", "*", "*", "*"],
            body: [
              [
                {
                  text: "DESPESAS",
                  fillColor: table_header_despesa_color,
                  bold: true,
                  colSpan: 5,
                  alignment: "center",
                  fontSize: item_table_despesa_font + 1,
                },
                {},
                {},
                {},
                {},
              ],
              [
                {
                  text: "NUMERO",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_despesa_font,
                  fillColor: "#EEEEEE",
                },
                {
                  text: "TIPO DESPESA",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_despesa_font,
                  fillColor: "#EEEEEE",
                },
                {
                  text: "CONDIÇÃO",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_despesa_font,
                  fillColor: "#EEEEEE",
                },
                {
                  text: "MOEDA",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_despesa_font,
                  fillColor: "#EEEEEE",
                },
                {
                  text: "VALOR",
                  bold: true,
                  // border: [true, false, true, true],
                  alignment: "center",
                  fontSize: item_table_despesa_font,
                  fillColor: "#EEEEEE",
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
            },
            hLineColor: function (i) {
              return i === 1 ? "#666666" : "#666666";
            },
            vLineColor: function (i) {
              return i === 1 ? "#666666" : "#666666";
            },
          },
        };
        compra.compra_despesas.forEach((item, index) => {
          let table_row = [
            {
              text: item.ncontrole,
              fontSize: item_row_despesa_font,
              alignment: "center",
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              border: [
                true,
                false,
                false,
                index != compra.compra_despesas.length - 1 ? false : true,
              ],
            },
            {
              text: item.compras_tipos_despesas_descricao,
              fontSize: item_row_despesa_font,
              alignment: "center",
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              border: [
                false,
                false,
                false,
                index != compra.compra_despesas.length - 1 ? false : true,
              ],
            },
            {
              text: item.condpago == 1 ? "À vista" : "À prazo",
              fontSize: item_row_despesa_font,
              alignment: "center",
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              border: [
                false,
                false,
                false,
                index != compra.compra_despesas.length - 1 ? false : true,
              ],
            },
            {
              text: item.moeda_sigla,
              fontSize: item_row_despesa_font,
              alignment: "center",
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              border: [
                false,
                false,
                false,
                index != compra.compra_despesas.length - 1 ? false : true,
              ],
            },
            {
              text:
                compra.moeda_sigla == "G$"
                  ? Vue.filter("guarani")(item.valor)
                  : Vue.filter("currency")(item.valor),
              fontSize: item_row_despesa_font,
              alignment: "center",
              fillColor: index % 2 !== 0 ? "#E0E0E0" : null,
              border: [
                false,
                false,
                true,
                index != compra.compra_despesas.length - 1 ? false : true,
              ],
            },
          ];

          table_header_compra_item_despesa.table.body.push(table_row);
        });

        total_items_despesas = compra.compra_despesas.reduce(
          (accumulator, item) => accumulator + Number(item.valor),
          0
        );

        let total_row_despesa = [
          {
            text: "TOTAL",
            fontSize: item_table_despesa_font,
            alignment: "center",
            colSpan: 4,
            // fillColor: table_header_despesa_color,
          },
          {},
          {},
          {},
          {
            text:
              compra.moeda_sigla == "G$"
                ? Vue.filter("guarani")(total_items_despesas)
                : Vue.filter("currency")(total_items_despesas),
            fontSize: item_table_despesa_font,
            alignment: "center",
            // fillColor: table_header_color,
          },
        ];
        table_header_compra_item_despesa.table.body.push(total_row_despesa);
        principal.push(table_header_compra_item_despesa);
      }

      // -------------------------------------------- total

      let item_table_total = 5;

      // let table_total_color = "#B0BEC5";
      let table_total = {
        margin: [30, 3, 0, 0],
        table: {
          widths: ["*", "*"],
          body: [
            [
              {
                text:
                  "SUB TOTAL: " +
                  (compra.moeda_sigla == "G$"
                    ? Vue.filter("guarani")(total_items_compra)
                    : Vue.filter("currency")(total_items_compra)),
                // border: [true, false, true, true],
                // fillColor: table_total_color,
                bold: true,
                alignment: "center",
                // color: "#FFFFFF",
                fontSize: item_table_total,
              },
              {
                text:
                  "TOTAL: " +
                  (compra.moeda_sigla == "G$"
                    ? Vue.filter("guarani")(
                      total_items_compra + total_items_despesas
                    )
                    : Vue.filter("currency")(
                      total_items_compra + total_items_despesas
                    )),
                // border: [true, false, true, true],
                bold: true,
                // fillColor: table_total_color,
                alignment: "center",
                // color: "#FFFFFF",
                fontSize: item_table_total,
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length
              ? "#666666"
              : "#666666";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? "#666666"
              : "#666666";
          },
        },
      };

      principal.push(table_total);

      // -------------------------------------------- total

      let table_geral = {
        margin: [0, 0, 0, 20],
        table: {
          widths: ["*"],
          body: [[principal]],
          dontBreakRows: true,
        },

        fillColor: "#FAFAFA",
        layout: "noBorders",
      };

      // table_geral.table.body.push(principal);
      content.push(table_geral);
    });
    // --------------------------------------------
    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],

      pageOrientation: "portrait", //"portrait"
      info: {
        title: "Document",
      },
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      // pageBreakBefore: function(
      //   currentNode,
      //   followingNodesOnPage,
      //   nodesOnNextPage,
      //   previousNodesOnPage
      // ) {
      //   return (
      //     currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
      //   );
      // },
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: "right",
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
