<template>
  <v-dialog
    v-model="dialogAddMovimentacao"
    @click:outside="$emit('update:dialogAddMovimentacao', false)"
    @keydown.esc="$emit('update:dialogAddMovimentacao', false)"
    width="550px"
  >
    <v-card>
      <v-card-title class="subtitle-1 lightgray">
        {{ $tc("global.addmovimentacao") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddMovimentacao', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <!-- Qtde -->
        <v-text-field
          v-model="movimentacao.qtde"
          :label="this.$tc('global.qtd')"
          class="mt-4"
          outlined
          hide-details
          dense
        ></v-text-field>

        <!-- custo -->

        <v-text-field
          v-model="custoProduto"
          :label="this.$tc('global.custo')"
          outlined
          hide-details
          dense
          class="input-right mt-4"
        ></v-text-field>

        <!-- Depositos -->

        <v-select
          v-model="selectedDeposito"
          :items="depositos"
          class="mt-4"
          item-text="descricao"
          item-value="id"
          dense
          :loading="loadingDeposito"
          :disabled="desativaDeposito"
          outlined
          hide-details
          :label="this.$tc('global.deposito')"
        ></v-select>

        <!-- Operacao -->
        <v-card flat class="mt-4">
          <v-subheader
            style="height: auto !important"
            class="ma-0 pa-0 pb-1 body-1"
          >
            {{ $tc("global.operacao") }}
          </v-subheader>
          <v-btn-toggle
            v-model="movimentacao.op"
            mandatory
            style="width: calc(100% / 2)"
            dense
            :class="$vuetify.breakpoint.mdAndDown ? 'btn-toggle-mobile' : ''"
          >
            <v-btn small height="36" block value="E">
              {{ $tc("global.entrada") }}
            </v-btn>

            <v-btn small height="36" block value="S">
              {{ $tc("global.saida") }}
            </v-btn>
          </v-btn-toggle>
        </v-card>

        <v-textarea
          outlined
          hide-details
          class="mt-4"
          label="Obs"
          :rows="2"
          auto-grow
          v-model="movimentacao.obs"
        ></v-textarea>

        <span v-if="saldo_produto" class="d-flex pt-4">
          {{ $tc("global.saldo") + " " + $tc("global.deposito") }}
          <v-spacer></v-spacer>{{ saldo_produto.saldo }}
        </span>
      </v-card-text>

      <v-card-actions class="d-flex pb-3 pr-6">
        <v-spacer></v-spacer>
        <v-btn
          @click="postMovimentacao()"
          color="button_1"
          class="white--text"
          :disabled="disableAddMovimentacao"
          small
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchDepositos } from "@/api/produtos/produtos_depositos.js";
import {
  postProdutoMovimentacoes,
  fetchCustoProduto,
} from "@/api/produtos/produtos.js";
import { fetchSaldoDeposito } from "@/api/produtos/produtos_saldos.js";
import { mapState } from "vuex";

export default {
  name: "DialogAddMovimentacao",

  props: {
    dialogAddMovimentacao: {
      type: Boolean,
      default: false,
    },
    produto: {
      type: Object,
    },
  },

  data() {
    return {
      loading: true,
      loadingDeposito: true,
      depositos: [],
      depositos_origem: [],
      movimentacao: {},
      selectedDeposito: null,
      selectedDepositoOrigem: null,
      saldo_produto: null,
      selectedMoeda: null,
      custoProduto: 0,
    };
  },

  computed: {
    desativaDeposito() {
      let result = false;
      if (this.loadingDeposito) {
        result = true;
      }
      return result;
    },

    disableAddMovimentacao() {
      let result = true;

      if (
        this.movimentacao.op &&
        !isNaN(this.movimentacao.qtde) &&
        this.movimentacao.deposito_id &&
        this.movimentacao.obs
      ) {
        result = false;
        if (this.saldo_produto && this.movimentacao.op == "S") {
          if (
            Number(this.saldo_produto.saldo) <= 0 ||
            Number(this.saldo_produto.saldo) < this.movimentacao.qtde
          ) {
            result = true;
          }
        }
        if (this.movimentacao.qtde < 0) {
          result = true;
        }
      }

      return result;
    },
  },

  watch: {
    selectedDeposito() {
      if (this.selectedDeposito) {
        this.movimentacao.deposito_id = this.selectedDeposito;

        this.saldoEstoque();
      }
    },
    custoProduto() {
      if (this.custoProduto) {
        this.movimentacao.custo = this.custoProduto;
      }
    },
  },

  methods: {
    getDepositos() {
      this.loadingDeposito = true;

      fetchDepositos()
        .then((response) => {
          this.depositos = response;
          this.loadingDeposito = false;
        })
        .catch(() => {
          this.loadingDeposito = false;
        });
    },

    postMovimentacao() {
      this.loading = true;

      let movimentacao = {};

      movimentacao.produto_id = this.produto.id;
      movimentacao.qtde = this.movimentacao.qtde;
      movimentacao.op = this.movimentacao.op;
      movimentacao.obs = this.movimentacao.obs;
      movimentacao.deposito_id = this.selectedDeposito;
      movimentacao.custo = Number(this.movimentacao.custo);

      postProdutoMovimentacoes(movimentacao)
        .then((response) => {
          if (response.status == 201) {
            this.$emit("update:dialogAddMovimentacao", false);
            this.$emit("fetch-inventario");
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getCustoProduto() {
      this.loading = true;
      return fetchCustoProduto(this.produto.id)
        .then((response) => {
          this.custoProduto = response;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    saldoEstoque() {
      this.loading = true;
      fetchSaldoDeposito(
        `?produto_id=${this.produto.id}&deposito_id=${this.selectedDeposito}`
      )
        .then((response) => {
          this.saldo_produto = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getDepositos();
    await this.getCustoProduto();
  },
};
</script>

<style lang="scss" scoped>
.input-right ::v-deep input {
  text-align: right;
}
</style>
