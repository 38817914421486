<template>
  <v-dialog
    v-model="dialogContaPagar"
    @click:outside="$emit('update:dialogContaPagar', false)"
    @keydown.esc="$emit('update:dialogContaPagar', false)"
    width="1100px"
  >
    <v-card v-if="!loading">
      <v-card-title class="pa-4 font-weight-bold tocs_gray_1">
        {{ edit ? $tc("global.editar") : $tc("global.add") }}
        {{ $tc("global.conta") }}
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-icon depressed @click="$emit('update:dialogContaPagar', false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form ref="form" v-model="validForm" lazy-validation v-cloak>
          <v-row>
            <v-col cols="12" md="8">
              <SearchFornecedores
                v-if="!loading"
                :readonly="disabledButton"
                :fornecedor_async.sync="selectedFornecedor"
                :obrigatorio="formRules ? true : true"
                :fornecedor_nome="conta.fornecedor_nome"
                :fornecedor_id="conta.fornecedor_id"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                :label="$tc('global.documento')"
                outlined
                :readonly="disabledButton"
                dense
                :rules="formRules"
                hide-details
                v-model="conta.documento"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" :md="edit ? '4' : '6'">
              <DataField
                hide_details
                :readonly="disabledButton"
                :data_sync.sync="conta.data_lancamento"
                :label="$tc('global.data-lancamento')"
                :obrigatorio="formRules ? true : false"
              />
              <!-- :data_max="today" -->
            </v-col>
            <v-col cols="12" :md="edit ? '4' : '6'">
              <DataField
                hide_details
                :readonly="disabledButton"
                :data_sync.sync="conta.data_vencimento"
                :label="$tc('global.data-vencimento')"
                :obrigatorio="formRules ? true : false"
              />
              <!-- :data_min="today" -->
            </v-col>
            <v-col v-if="edit" cols="12" md="4">
              <DataField
                hide_details
                :readonly="disabledButton"
                :data_sync.sync="conta.data_pagamento"
                :label="$tc('global.data-pagamento')"
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                v-model="selectedMoeda"
                :items="moedas"
                item-text="sigla"
                item-value="id_moeda"
                outlined
                dense
                hide-details
                return-object
                :rules="formRules"
                :readonly="disabledButton"
                :label="$tc('global.moeda')"
              ></v-select>
            </v-col>
            <v-col v-if="edit" cols="12" md="3">
              <v-text-field
                v-if="edit"
                ref="contaDesconto"
                v-model="contaDesconto"
                :disabled="selectedMoeda ? false : true"
                :label="$tc('global.desconto')"
                dense
                outlined
                hide_details
                :readonly="disabledButton"
                v-currency="vCurrencyOptions"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="contaValor"
                v-model="conta.valor"
                :disabled="selectedMoeda ? false : true"
                :label="$tc('global.valor')"
                dense
                outlined
                hide_details
                :readonly="disabledButton"
                :rules="formRules"
                v-currency="vCurrencyOptions"
              ></v-text-field>
            </v-col>
            <v-col v-if="edit" cols="12" md="3">
              <v-text-field
                ref="contaValorPago"
                v-model="conta.valor_pago"
                :disabled="selectedMoeda ? false : true"
                :label="$tc('global.valor') + ' ' + $tc('global.pago')"
                dense
                outlined
                hide_details
                :readonly="true"
                v-currency="vCurrencyOptions"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12"> <v-divider></v-divider></v-col>
            <v-col
              class="py-0 ma-0"
              v-if="descontoJustificativa"
              cols="12"
              md="12"
            >
              <v-textarea
                ref="contaDescontoJustificativa"
                v-model="conta.desconto_justificativa"
                :label="$tc('global.justificativadesconto')"
                dense
                rows="2"
                outlined
                hide_details
                :readonly="disabledButton"
                :rules="formRulesCaracter"
              ></v-textarea>
            </v-col>
            <v-col class="py-0 ma-0" cols="12" md="12">
              <v-textarea
                ref="contaObservacao"
                v-model="conta.obs"
                :label="$tc('global.observacao')"
                dense
                rows="2"
                outlined
                hide_details
                :readonly="disabledButton"
                :rules="formRulesCaracter"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!validForm || disabledButton"
          small
          :loading="loadingButtom"
          class="white--text"
          color="button_2"
          @click="edit ? editarConta() : adicionarConta()"
        >
          {{ edit ? $tc("global.editar") : $tc("global.add") }}
          <v-icon right>
            {{ edit ? "mdi-pencil" : "mdi-plus" }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  postContaPagar,
  fetchContaPagar,
  putContaPagar,
} from "@/api/contas-pagar/contas_pagar.js";
import { format } from "date-fns";
import { mapState } from "vuex";
export default {
  name: "DialogContaPagar",

  props: {
    dialogContaPagar: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    conta_id: {
      type: Number,
      default: null,
    },
  },

  components: {
    SearchFornecedores: () =>
      import("@/components/fields/SearchFornecedores.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      selectedFornecedor: null,
      selectedMoeda: null,
      loading: true,
      loadingButtom: false,
      today: format(new Date(), "yyyy-MM-dd"),
      contaOriginal: {},
      conta: {
        obs: "",
        desconto_justificativa: "",
      },
      validForm: false,
      formRules: [inputRequired],
      contaDesconto: 0,
      formRulesCaracter: [
        (v) => !!v || this.$tc("global.obrigatorio"),
        (v) => v.length >= 5 || this.$tc("global.mincaracteres"),
        (v) => v.length <= 255 || "O campo deve ter no máximo 255 caracteres",
      ],
    };
  },

  watch: {
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.conta.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.conta.fornecedor_id = this.selectedFornecedor.id;
      }
    },
  },

  computed: {
    disabledButton() {
      return (this.edit && this.contaOriginal.data_pagamento) ||
        this.contaOriginal.saldo == 0
        ? true
        : false;
    },
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    descontoJustificativa() {
      return this.conta.desconto > 0 || this.conta.desconto > "0,00"
        ? true
        : false;
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    ...mapState("Usuario", {
      usuario_id: (state) => state.usuario.id,
      empresa_id: (state) => state.empresa.id,
    }),
  },

  methods: {
    adicionarConta() {
      if (this.$refs.form.validate()) {
        this.loadingButtom = true;
        this.conta.origem_tabela = "MANUAL";
        this.conta.origem_id = 0;
        this.conta.empresa_id = this.empresa_id;
        this.conta.valor = this.$ci.parse(
          this.conta.valor,
          this.vCurrencyOptions
        );
        // this.conta.valor_pago = this.$ci.parse(
        //   this.conta.valor_pago,
        //   this.vCurrencyOptions
        // );
        // this.conta.desconto = this.$ci.parse(
        //   this.conta.desconto,
        //   this.vCurrencyOptions
        // );
        // if (this.descontoJustificativa) {
        //   this.conta.desconto_usuario_id = this.usuario_id;
        // }
        postContaPagar(this.conta)
          .then((response) => {
            if (response.status === 201) {
              this.loadingButtom = false;
            }
          })
          .catch((error) => {
            this.loadingButtom = false;
            console.log(error);
          })
          .finally(() => {
            this.$emit("update:dialogContaPagar", false);
            this.$emit("fetch-contas");
          });
      }
    },
    editarConta() {
      if (this.$refs.form.validate()) {
        this.loadingButtom = true;
        this.conta.valor = this.$ci.parse(
          this.conta.valor,
          this.vCurrencyOptions
        );
        this.conta.valor_pago = this.$ci.parse(
          this.conta.valor_pago,
          this.vCurrencyOptions
        );
        this.conta.desconto = this.$ci.parse(
          this.conta.desconto,
          this.vCurrencyOptions
        );

        let conta = {};

        conta.id = this.conta.id;
        conta.fornecedor_id = this.conta.fornecedor_id;
        conta.empresa_id = this.empresa_id;
        conta.moeda_id = this.conta.moeda_id;
        conta.data_lancamento = this.conta.data_lancamento;
        conta.data_pagamento = this.conta.data_pagamento;
        conta.data_vencimento = this.conta.data_vencimento;
        conta.desconto = this.$ci.parse(
          this.contaDesconto,
          this.vCurrencyOptions
        );
        conta.valor_pago = this.conta.valor_pago;
        conta.valor = this.conta.valor;
        conta.documento = this.conta.documento;
        conta.desconto_justificativa = this.conta.desconto_justificativa;
        conta.desconto_usuario_id = this.conta.desconto_usuario_id;
        conta.obs = this.conta.obs;
        conta.origem_tabela = this.conta.origem_tabela;
        conta.origem_id = this.conta.origem_id;

        putContaPagar(conta.id, conta)
          .then((response) => {
            if (response.status === 200) {
              this.loadingButtom = false;
              this.$emit("update:dialogContaPagar", false);
              this.$emit("fetch-contas");
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingButtom = false;
          });
      }
    },
    getContaPagar() {
      return fetchContaPagar(this.conta_id)
        .then((response) => {
          this.contaOriginal = { ...response };
          this.conta = { ...response };
        })
        .catch(() => {});
    },
  },

  async mounted() {
    if (this.conta_id) {
      await this.getContaPagar();
      this.selectedMoeda = this.moedas.find(
        (moeda) => moeda.id_moeda === this.conta.moeda_id
      );
      this.loading = false;
    } else {
      this.loading = false;
    }
  },
};
</script>

<style></style>
