<template>
  <v-dialog
    v-model="dialogCancelarMovimentacao"
    @click:outside="$emit('update:dialogCancelarMovimentacao', false)"
    @keydown.esc="$emit('update:dialogCancelarMovimentacao', false)"
    width="850px"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1"
        >Motivo Cancelamento</v-card-title
      >
      <v-card-text>
        <v-textarea
          class="mt-2 "
          v-model="dadosCancelamento.porque_cancelou"
          dense
          outlined
        ></v-textarea
      ></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          depressed
          @click="$emit('update:dialogCancelarMovimentacao', false)"
          small
          >Voltar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="button_1"
          class="white--text"
          :disabled="!dadosCancelamento.porque_cancelou"
          depressed
          small
          @click="updateCaixaMov"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { putCaixaMov } from "@/api/caixas/caixas_mov.js";
import { mapState } from "vuex";
import { format } from "date-fns";
export default {
  name: "DialogCancelamento",

  props: {
    dialogCancelarMovimentacao: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      dadosCancelamento: {},
    };
  },

  computed: {
    ...mapState("Usuario", {
      usuario_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    updateCaixaMov() {
      this.loading = true;
      const caixa_mov = new FormData();

      let caixa_mov_2 = {};
      caixa_mov_2.usuario_cancelou_id = this.usuario_id;
      caixa_mov_2.porque_cancelou = this.dadosCancelamento.porque_cancelou;
      caixa_mov_2.data_cancelamento = format(new Date(), "yyyy-MM-dd");

      for (let key in caixa_mov_2) {
        if (
          caixa_mov_2[key] !== null &&
          caixa_mov_2[key] !== undefined &&
          caixa_mov_2[key] !== ""
        ) {
          caixa_mov.append(key, caixa_mov_2[key]);
        }
      }

      putCaixaMov(this.dadosCancelamento.id, caixa_mov)
        .then(() => {
          this.loading = false;
          this.$emit("update:dialogCancelarMovimentacao", false);
          this.$emit("fetch-caixa-mov");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.dadosCancelamento = { ...this.item };
  },
};
</script>

<style></style>
