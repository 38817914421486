var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageToolbar',{attrs:{"title":_vm.$tc('global.devolucao', 2),"icon":"mdi-truck-delivery-outline","dark":""}}),_c('v-card',[_c('v-card-title',{staticClass:"px-md-6 subtitle-1 tocs_gray_1"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('global.buscar'),"clearable":"","solo":"","flat":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn-toggle',{staticClass:"ml-3",attrs:{"mandatory":""},model:{value:(_vm.tipo_devolucao),callback:function ($$v) {_vm.tipo_devolucao=$$v},expression:"tipo_devolucao"}},[_c('v-btn',{staticClass:"text-caption",attrs:{"value":"vendas","height":"40px"}},[_vm._v(" "+_vm._s(_vm.$tc("global.venda", 2))+" ")]),_c('v-btn',{staticClass:"text-caption",attrs:{"value":"compras","disabled":"","height":"40px"}},[_vm._v(" "+_vm._s(_vm.$tc("global.compra", 2))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-6"},[_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.vendas,"search":_vm.search,"items-per-page":15,"loading":_vm.loading},on:{"click:row":_vm.openDevolucao},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd-MM-yyyy"))+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.desconto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.desconto))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.desconto))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.total))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])]}}])})],1)],1),(_vm.dialogDevolucao)?_c('DialogDevolucao',{attrs:{"dialogDevolucao":_vm.dialogDevolucao,"item_id":_vm.item_id,"tipo_devolucao":_vm.tipo_devolucao},on:{"update:dialogDevolucao":function($event){_vm.dialogDevolucao=$event},"update:dialog-devolucao":function($event){_vm.dialogDevolucao=$event},"fetch-devolucoes":_vm.fetchDevolucoes}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }