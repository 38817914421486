<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.conta', 2) + ' ' + $tc('global.receber')"
      icon="mdi-account-cash-outline"
      dark
    />
    <v-card class="">
      <v-card-text class="d-flex mt-8">
        <SearchClientes class="mr-2" :cliente_sync.sync="selectedCliente" />

        <!-- <v-text-field
          class="mx-2"
          :label="$tc('global.documento')"
          outlined
          v-model="documento"
          dense
          hide-details
        ></v-text-field> -->
        <v-text-field
          class="ml-2"
          :label="$t('global.descricao')"
          outlined
          dense
          v-model="descricao"
          hide-details
        ></v-text-field>
        <v-select
          v-if="existeJuros"
          v-model="selectedTimbrado"
          :items="timbrados"
          :loading="loadingTimbrado"
          item-text="timbrado"
          item-value="timbrado_id"
          class="mx-2"
          outlined
          dense
          hide-details
          :label="$tc('global.timbrado')"
        >
          <template v-slot:selection="{ item }">
            <span>{{
              `${item.descricao ? item.descricao : ""} ${item.timbrado} `
            }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span>{{
              `${item.descricao ? item.descricao : ""} ${item.timbrado} `
            }}</span>
          </template>
        </v-select>
      </v-card-text>
      <v-card
        flat
        tile
        exact
        elevation="0"
        class="pa-3 mb-3"
        v-if="contas.length"
      >
        <v-card-title class="tocs_gray_1 px-3 py-2">
          <v-text-field
            style="max-width: 250px"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            hide-details
            outlined
            solo
            flat
            dense
          ></v-text-field>
          <v-spacer></v-spacer>
          <span class="subtitle-1 font-weight-bold pr-16">
            {{
              selectedCliente
                ? selectedCliente.nome
                : $tc("global.baixaDeContas")
            }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            v-if="!loading"
            v-model="contasSelected"
            :headers="headers"
            :search="search.length >= 3 ? search : ''"
            :items="contas"
            item-key="id"
            sort-by="id"
            :show-select="
              cliente_baixa_processando || !selectedCliente ? false : true
            "
            :items-per-page="10"
            class="data-tables mb-5"
            @item-selected="contaSelected"
            :item-class="row_classes"
          >
            <template v-slot:item.fatura_id="{ item }">
              <div class="flex" v-if="item.fatura_id">
                {{ item.fatura_ponto }}
                {{ item.fatura_numero.toString().padStart(7, "0") }}
              </div>
              <span v-else>{{ $tc("global.semFatura") }}</span>
            </template>
            <template v-slot:item.data_vencimento="{ item }">
              <div>
                <div v-if="item.data_vencimento">
                  {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
                </div>
              </div>
            </template>
            <template v-slot:item.valor="{ item }">
              <div
                class="d-flex justify-space-between"
                v-if="item.moeda_sigla == 'G$' && item.valor"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.valor | guarani }}</div>
              </div>

              <div class="d-flex justify-space-between" v-else-if="item.valor">
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.valor | currency }}</div>
              </div>
            </template>

            <template v-slot:item.juros="{ item }">
              <div
                class="d-flex justify-space-between"
                v-if="item.moeda_sigla == 'G$' && item.juros"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.juros | guarani }}</div>
              </div>
              <div class="d-flex justify-space-between" v-else-if="item.juros">
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.juros | currency }}</div>
              </div>
            </template>

            <template v-slot:item.desconto="{ item }">
              <div
                class="d-flex justify-space-between"
                v-if="item.moeda_sigla == 'G$' && item.desconto"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.desconto | guarani }}</div>
              </div>
              <div
                class="d-flex justify-space-between"
                v-else-if="item.desconto"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.desconto | currency }}</div>
              </div>
            </template>

            <template v-slot:item.valor_pago="{ item }">
              <div
                class="d-flex justify-space-between"
                v-if="item.moeda_sigla == 'G$'"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.valor_pago | guarani }}</div>
              </div>
              <div
                class="d-flex justify-space-between"
                v-else-if="item.valor_pago"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.valor_pago | currency }}</div>
              </div>
            </template>

            <template v-slot:item.saldo="{ item }">
              <div
                class="d-flex justify-space-between"
                v-if="item.moeda_sigla == 'G$' && item.saldo"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.saldo | guarani }}</div>
              </div>
              <div class="d-flex justify-space-between" v-else-if="item.saldo">
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.saldo | currency }}</div>
              </div>
            </template>

            <template v-slot:item.multa="{ item }">
              <div
                class="d-flex justify-space-between"
                v-if="item.moeda_sigla == 'G$' && item.multa"
              >
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.multa | guarani }}</div>
              </div>
              <div class="d-flex justify-space-between" v-else>
                <div>{{ item.moeda_sigla }}</div>
                <div>{{ item.multa | currency }}</div>
              </div>
            </template>

            <template v-slot:item.cliente_nome="{ item }">
              <v-tooltip bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ item.cliente_nome | excerpt(24) }}
                  </div>
                </template>
                <span>{{ item.cliente_nome }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <Carregando v-else />
        </v-card-text>
      </v-card>
      <Carregando v-else-if="loading" />
      <v-card
        class="text-center mt-8 mb-4"
        flat
        v-if="cliente && contas.length == 0 && !loading"
      >
        {{ $tc("global.clientenaopossuicontabaixa") }}
      </v-card>
      <v-card
        outlined
        v-if="contasSelectedCliente.length"
        class="mt-5 mx-4 pa-4 d-flex"
      >
        <v-spacer></v-spacer>
        <v-btn depressed small color="primary" class="mr-2" @click="resetBaixa">
          reset
          <v-icon right>mdi-lock-reset</v-icon>
        </v-btn>
        <v-btn
          depressed
          small
          class="white--text button_1"
          :disabled="disableBaixarContas"
          @click="baixarContas"
        >
          {{ $tc("global.baixarconta") }}
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-card>
      <div class="mx-4" v-if="contasSelectedCliente">
        <template v-for="(contas_clientes, i) in contasSelectedCliente">
          <BaixarContas
            :key="i"
            :contas="contas_clientes.contas"
            :cliente_id="contas_clientes.cliente_id"
            :cliente_nome="contas_clientes.cliente_nome"
            :recibo="contas_clientes.recibo"
            :recibo_antecipo="contas_clientes.recibo_antecipo"
            tipo_baixa="recebimento"
            @updateContasBaixar="updateContasBaixar"
            @get-contas="getContas"
            @deleteContaCliente="deleteContaCliente"
            @editarConta="editarConta"
          />
        </template>
      </div>
      <v-overlay :value="overlay">
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-col>
          <v-col cols="12" c style="width: 800px !important">
            <v-progress-linear
              :value="valueDeterminate"
              :background-opacity="0.5"
              color="tocs_red"
              height="40px"
              rounded
              class="white--text"
            >
              <template v-slot="{}">
                <strong>
                  {{ contasBaixar.length + " " + $tc("global.contasRestante") }}
                </strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script>
import {
  contasReceberCliente,
  fetchContasReceberDia,
} from "@/api/contas-receber/contas_receber.js";
import { format } from "date-fns";
import { postBaixaContas } from "@/api/baixa-contas/baixa_contas.js";
import { inputRequired } from "@/helpers/utils.js";
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";
export default {
  name: "BaixaDeContasReceber",

  components: {
    SearchClientes: () => import("@/components/fields/SearchClientes.vue"),
    BaixarContas: () => import("./components/BaixarContas.vue"),
  },

  data() {
    return {
      loading: false,
      search: "",
      timbrados: [],
      selectedTimbrado: null,
      contasSelectedCliente: [],
      contasBaixar: [],
      selectedCliente: null,
      timbrado_id: null,
      cliente: null,
      contas: [],
      // documento: null,
      descricao: "",
      contasSelected: [],
      overlay: false,
      loadingTimbrado: false,
      valueDeterminate: 0,
      formRules: [inputRequired],
      cliente_baixa_processando: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          width: "40px",
          value: "id",
          divider: true,
        },
        {
          text: this.$tc("global.cliente"),
          width: "200px",
          align: "start",
          value: "cliente_nome",
          divider: true,
        },
        {
          text: this.$tc("global.vencimento"),
          width: "125px",
          value: "data_vencimento",
          divider: true,
        },
        {
          text: this.$tc("global.descricao"),
          width: "165px",
          value: "descricao",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          width: "120px",
          align: "right",
          value: "valor",
          divider: true,
        },

        {
          text: this.$tc("global.juros"),
          width: "100px",
          align: "right",
          value: "juros",
          divider: true,
        },

        {
          text: this.$tc("global.multa"),
          width: "100px",
          align: "right",
          value: "multa",
          divider: true,
        },

        {
          text: this.$tc("global.desconto"),
          width: "115px",
          align: "right",
          value: "desconto",
          divider: true,
        },
        {
          text: this.$tc("global.pago"),
          width: "110px",
          align: "right",
          value: "valor_pago",
          divider: true,
        },
        {
          text: this.$tc("global.saldo"),
          width: "110px",
          align: "right",
          value: "saldo",
          divider: true,
        },
        {
          text: this.$tc("global.fatura"),
          width: "150px",
          align: "center",
          value: "fatura_id",
        },
      ];
    },

    empresa_id() {
      return this.$route.params.empresa_id;
    },

    disableBaixarContas() {
      let result = true;
      if (this.contasBaixar.length === this.contasSelectedCliente.length) {
        result = false;
      }
      return result;
    },
    existeJuros() {
      let result = false;
      if (this.contasSelected.length > 0) {
        this.contasSelected.forEach((conta) => {
          if (conta.juros > 0) {
            result = true;
          }
        });
      }

      return result;
    },
  },

  watch: {
    selectedCliente() {
      if (this.selectedCliente) {
        this.cliente = this.selectedCliente;
        let cliente_baixa_existe = this.contasBaixar.some((conta_cliente) => {
          return conta_cliente.cliente_id === this.cliente.id;
        });
        if (cliente_baixa_existe) {
          this.cliente_baixa_processando = true;
        } else {
          this.cliente_baixa_processando = false;
        }
      }
    },

    async cliente() {
      if (this.cliente) {
        await this.getContas();
        await this.setContasSelecionadasCliente();
      }
    },

    contasSelected() {
      if (this.contasSelected.length) {
        this.putContasSelectedCliente();
      }
    },
    selectedTimbrado() {
      if (this.selectedTimbrado) {
        this.timbrado_id = this.selectedTimbrado;
      }
    },
  },

  methods: {
    row_classes(item) {
      let dataNow = "";
      dataNow = format(new Date(), "yyyy-MM-dd");
      if (item.data_vencimento < dataNow) {
        return "tocs_red--text";
      }
    },
    resetBaixa() {
      this.contasBaixar = [];
      this.contasSelectedCliente = [];
      this.contasSelected = [];
      this.selectedCliente = null;
      this.cliente = null;
    },
    async baixarContas() {
      try {
        if (this.contasBaixar.length === this.contasSelectedCliente.length) {
          if (this.existeJuros) {
            if (!this.selectedTimbrado) {
              this.$toast.error(this.$tc("global.SelecioneTimbrado"));
              return;
            }
          }
          let contas = [];
          this.contasBaixar.map((conta) => {
            // conta.documento = this.documento;
            conta.descricao_pagamento = this.descricao;
            if (this.existeJuros) {
              conta.timbrado_id = this.timbrado_id;
            }
            contas.push(conta);
          });
          this.overlay = true;
          let total = contas.length - 1;
          let i = 0;
          contas.forEach((conta) => {
            postBaixaContas(conta)
              .then((response) => {
                if (response.status === 200) {
                  this.$toast.success(
                    `${i + 1} de ${total + 1} efetuada com sucesso!`
                  );
                  i++;
                  this.valueDeterminate = (i / total) * 100;
                  this.contasSelectedCliente.map((cliente, index) => {
                    if (cliente.cliente_id == response.data.cliente_id) {
                      this.contasSelectedCliente[index].recibo =
                        response.data.recibo;
                    }
                    if (cliente.cliente_id == response.data.cliente_id) {
                      this.contasSelectedCliente[index].recibo_antecipo =
                        response.data.recibo_antecipo;
                    }
                  });
                }
              })
              .finally(() => {
                this.overlay = false;
                this.contasSelected = [];
                this.contasBaixar = [];
                this.getContas();
              });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    getTimbrados() {
      this.loadingTimbrado = true;

      return fetchUserTimbrados()
        .then((response) => {
          this.timbrados = response;
          this.loadingTimbrado = false;
        })
        .catch((error) => {
          this.loadingTimbrado = false;
        });
    },

    async getContas() {
      this.loading = true;
      await contasReceberCliente(this.cliente.id)
        .then((response) => {
          this.contas = response;
          this.contasSelected = [];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    putContasSelectedCliente() {
      let result_moeda = false;
      let result_fatura = false;

      this.contasSelected.some((conta) => {
        if (conta.moeda_sigla != this.contasSelected[0].moeda_sigla) {
          result_moeda = true;
        }
        if (
          (this.contasSelected[0].fatura_id && conta.fatura_id) ||
          (!this.contasSelected[0].fatura_id && !conta.fatura_id)
        ) {
          result_fatura = false;
        } else {
          result_fatura = true;
          return;
        }
      });

      if (result_moeda) {
        this.$toast.error(this.$tc("global.ContasDevemSerDoMesmoMoeda"));
        this.contasSelected = [];
        return;
      }
      if (result_fatura) {
        this.$toast.error(this.$tc("global.ContasDevemTerFatura"));
        this.contasSelected = [];
        return;
      }

      let cliente_contas = {};
      let cliente_existe = null;

      if (this.existeJuros) {
        cliente_contas.contas_juros = true;
      } else if (!this.existeJuros) {
        cliente_contas.contas_juros = false;
      }
      cliente_contas.cliente_id = this.cliente.id;
      cliente_contas.cliente_nome = this.cliente.nome;
      cliente_contas.contas = this.contasSelected;

      cliente_existe = this.contasSelectedCliente.some((conta_cliente) => {
        return conta_cliente.cliente_id === this.cliente.id;
      });
      if (cliente_existe) {
        let index = this.contasSelectedCliente.findIndex((conta_cliente) => {
          return conta_cliente.cliente_id === this.cliente.id;
        });

        this.contasSelectedCliente[index].contas = this.contasSelected;
        if (this.existeJuros) {
          this.contasSelectedCliente[index].contas_juros = true;
        } else if (!this.existeJuros) {
          this.contasSelectedCliente[index].contas_juros = false;
        }
      } else {
        this.contasSelectedCliente.push(cliente_contas);
      }
    },

    setContasSelecionadasCliente() {
      let contas_existem = null;
      contas_existem = this.contasSelectedCliente.some((conta_cliente) => {
        return conta_cliente.cliente_id === this.cliente.id;
      });

      if (contas_existem) {
        let index = null;

        index = this.contasSelectedCliente.findIndex((conta_cliente) => {
          return conta_cliente.cliente_id === this.cliente.id;
        });

        if (this.contas.length) {
          this.contasSelectedCliente[index].contas.forEach((conta) => {
            return this.contasSelected.push(conta);
          });
        }
      }
    },

    contaSelected({ item, value }) {
      if (value) {
        let result_moeda = false;
        let result_fatura = false;

        this.contasSelected.some((conta, index) => {
          if (conta.moeda_sigla != item.moeda_sigla) {
            result_moeda = true;
          }
          if (
            (item.fatura_id && conta.fatura_id) ||
            (!item.fatura_id && !conta.fatura_id)
          ) {
            result_fatura = false;
          } else {
            result_fatura = true;
            return true;
          }
        });

        if (result_moeda) {
          this.$nextTick(() => {
            const index = this.contasSelected.findIndex(
              (conta) => conta.id === item.id
            );
            if (index !== -1) this.contasSelected.splice(index, 1);
            this.$toast.error(this.$tc("global.ContasDevemSerDoMesmoMoeda"));
          });
        }
        if (result_fatura) {
          this.$nextTick(() => {
            const index = this.contasSelected.findIndex(
              (conta) => conta.id === item.id
            );
            if (index !== -1) this.contasSelected.splice(index, 1);
            this.$toast.error(this.$tc("global.ContasDevemTerFatura"));
          });
        }
      }
    },

    updateContasBaixar(body) {
      let conta_existe = this.contasBaixar.some((conta) => {
        return conta.cliente_id === body.cliente_id;
      });
      if (conta_existe) {
        let index = this.contasBaixar.findIndex((conta) => {
          return conta.cliente_id === body;
        });
        this.contasBaixar.splice(index, 1);
        this.contasBaixar.push(body);
        this.selectedCliente = null;
      } else {
        this.contasBaixar.push(body);
        this.selectedCliente = null;
      }
    },

    deleteContaCliente() {
      let index = this.contasSelectedCliente.findIndex((conta) => {
        return !conta.contas.length;
      });
      this.contasSelectedCliente.splice(index, 1);
    },

    editarConta(item) {
      let index = this.contasBaixar.findIndex((conta) => {
        return conta.cliente_id === item;
      });
      this.contasBaixar.splice(index, 1);
    },

    getContasReceberDia() {
      this.loading = true;
      fetchContasReceberDia()
        .then((response) => {
          this.contas = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getTimbrados();
    this.getContasReceberDia();
  },
};
</script>

<style></style>
