<template>
  <v-container fluid v-if="usuario">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          voltar
          dark
          icon="mdi-account-circle"
          :title="$tc('global.add') + ' ' + $tc('global.usuario')"
        />
      </v-col>

      <v-col cols="12">
        <v-card class="pa-2 pa-sm-6">
          <v-card-title class="subtitle-1 justify-space-between align-center">
            {{ $tc("global.dados-gerais") }}
            <v-btn
              @click.prevent="
                is_admin && $route.meta.menu == 'super_admin'
                  ? novoUsuarioAdmin()
                  : novoUsuario()
              "
              color="button_1"
              class="white--text"
              :disabled="!validForm"
            >
              <v-icon>mdi-check</v-icon>
              {{ $tc("global.add") }}
            </v-btn>
          </v-card-title>
          <v-form
            @submit.prevent
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" md="2">
                <v-card
                  flat
                  class="fill-height d-flex align-center justify-center"
                >
                  <v-card flat v-if="avatar">
                    <v-img :src="avatar" contain></v-img>
                  </v-card>
                  <div v-else class="fill-height" style="width: 100%">
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :class="{ hoverFile: hoverFile }"
                      :use-custom-dropzone-options="true"
                      :options="dropzoneOptions"
                      @vdropzone-file-added="fileAdded"
                      @vdropzone-drag-enter="hoverFile = true"
                      @vdropzone-drag-over="hoverFile = true"
                      @vdropzone-drag-leave="hoverFile = false"
                      :useCustomSlot="true"
                      :include-styling="false"
                    >
                      <div class="text-center">
                        <v-icon size="80" color="info"> mdi-image-plus </v-icon>
                        <h3 class="text-subtitle-1">
                          {{ $tc("global.arrastesolte") }}
                        </h3>
                        <div class="text-caption">
                          {{ $tc("global.cliqueselecionar") }}
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="10">
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="usuario.email"
                      :rules="formRules"
                      required
                      label="Username"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Status -->
                  <v-col
                    v-if="is_admin && $route.meta.menu != 'configuracoes'"
                    cols="12"
                    md="4"
                  >
                    <v-card
                      outlined
                      height="40px"
                      class="pa-3 d-flex justify-space-between align-center"
                    >
                      <v-card-text> Status: </v-card-text>
                      <v-switch
                        :false-value="0"
                        :true-value="1"
                        v-model="usuario.status"
                      ></v-switch>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model.lazy="usuario.name"
                      :label="$tc('global.nomecompleto')"
                      :rules="formRules"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model.lazy="usuario.celular"
                      :label="$tc('global.celular')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      name="senha"
                      :label="$tc('global.senha')"
                      :rules="formRules"
                      required
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                      dense
                      outlined
                      v-model="usuario.password"
                      class="input-group--focused"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      name="confirmarSenha"
                      :label="$tc('global.confirmsenha')"
                      :rules="formRules"
                      required
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                      dense
                      outlined
                      v-model="usuario.password_confirmation"
                      class="input-group--focused"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import { postUsuarioAdmin } from "@/api/usuarios/usuarios.js";
import { postUsuario } from "@/api/usuarios/usuarios.js";
import { inputRequired } from "@/helpers/utils/";

export default {
  name: "NovoUsuario",
  data: () => ({
    validForm: true,
    formRules: [inputRequired],
    avatar: null,
    avatarUpload: null,
    hoverFile: false,
    usuario: {
      status: 1,
    },
    show: false,
    loading: false,
  }),

  components: {
    vueDropzone: vue2Dropzone,
  },

  computed: {
    ...mapState("Usuario", {
      is_admin: (state) => state.usuario.is_admin,
    }),

    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },
  },

  methods: {
    fileAdded(file) {
      this.avatarUpload = file;

      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },

    novoUsuarioAdmin() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const usuario = new FormData();

        if (this.avatarUpload) {
          usuario.append("avatar", this.avatarUpload, this.avatarUpload.name);
        }
        usuario.append("name", this.usuario.name);
        usuario.append("email", this.usuario.email);
        usuario.append("celular", this.usuario.celular);
        usuario.append("status", this.usuario.status);
        usuario.append("password", this.usuario.password);
        usuario.append(
          "password_confirmation",
          this.usuario.password_confirmation
        );

        postUsuarioAdmin(usuario)
          .then(() => {
            this.$router.push({ path: "/super-admin/usuarios" });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    novoUsuario() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const usuario = new FormData();

        if (this.avatarUpload) {
          usuario.append("avatar", this.avatarUpload, this.avatarUpload.name);
        }
        usuario.append("name", this.usuario.name);
        usuario.append("email", this.usuario.email);
        usuario.append("celular", this.usuario.celular);
        usuario.append("status", this.usuario.status);
        usuario.append("password", this.usuario.password);
        usuario.append(
          "password_confirmation",
          this.usuario.password_confirmation
        );

        postUsuario(usuario)
          .then(() => {
            this.$router.push({ path: "/configuracoes/usuarios" });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}
#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
