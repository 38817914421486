<template>
  <v-card>
    <v-card-title class="tocs_gray_1">
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$tc('global.buscar')"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      ></v-text-field>
      <v-spacer class="d-none d-sm-flex"></v-spacer>
      <v-btn
        @click="dialogNovaMoeda = true"
        color="button_1"
        class="white--text mt-4 mt-sm-0"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $tc("global.add") }}
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :search="search"
        :items="moedas"
        :items-per-page="15"
        class="data-tables data-tables__row-click"
        @click:row="goToMoeda"
      >
        <template v-slot:item.status="{ item }">
          <span v-if="item.status === 1">
            {{ $tc("global.ativo") }}
          </span>
          <span v-else>{{ $tc("global.inativo") }}</span>
        </template>
      </v-data-table>
      <Carregando v-else />
    </v-card-text>

    <Moeda v-if="dialogMoeda" :dialogMoeda.sync="dialogMoeda" :item="moeda" />
    <NovaMoeda v-if="dialogNovaMoeda" :dialogNovaMoeda.sync="dialogNovaMoeda" />
  </v-card>
</template>

<script>
import { fetchMoedasAdmin } from "@/api/moedas/moedas.js";

export default {
  name: "ListaMoedas",

  components: {
    Moeda: () => import("./Moeda.vue"),
    NovaMoeda: () => import("./NovaMoeda.vue"),
  },

  data() {
    return {
      loading: false,
      search: "",
      moedas: [],
      display: false,
      dialogMoeda: false,
      dialogNovaMoeda: false,
      moeda: {},
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: "sigla",
          value: "sigla",
        },
        {
          text: "sep. milhar",
          value: "separador_milhar",
        },
        {
          text: "sep. decimal",
          value: "separador_decimal",
        },
        {
          text: "cont. decimal",
          value: "contador_decimal",
        },
        {
          text: "status",
          value: "status",
        },
        // {
        //   text: "",
        //   width: "70px",
        //   align: "right",
        //   value: "actions",
        // },
      ];
    },
  },

  methods: {
    goToMoeda(item) {
      this.moeda = { ...item };
      this.dialogMoeda = true;
    },

    getMoedas() {
      return fetchMoedasAdmin(`?status=1`)
        .then((response) => {
          this.moedas = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  mounted() {
    this.getMoedas();
  },
};
</script>

<style scoped lang="scss"></style>
