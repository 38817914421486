import api from "../axios_service.js";

//pega todos os enderecos
export async function all() {
  const response = await api.get("/cliente-enderecos");
  return response.data.result;
}

// pega um enderecos
export async function fetchEnderecosClientes(id) {
  const response = await api.get(`/cliente-enderecos?cliente_id=${id}`);
  return response.data.result;
}

// Atualiza um enderecos existente
export function putEnderecosClientes(id, endereco) {
  return api.put(`/cliente-enderecos/${id}`, endereco);
}

export function putEnderecoFavorito(id, endereco) {
  return api.put(`/cliente-enderecos/favorito/${id}`, endereco);
}

// Cria um enderecos novo
export function postEnderecosClientes(endereco) {
  return api.post("/cliente-enderecos/add", endereco);
}

//Deleta um enderecos
export function deleteEnderecos(id) {
  return api.delete(`/cliente-enderecos/${id}`);
}
