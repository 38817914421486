const routes = [
  {
    path: "/financeiro/giros",
    component: () =>
      import("@/views/dashboard/giros/Giros.vue"),
    meta: {
      menu: "dashboard",
      title: "Giros",
      icon: "mdi-sim",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "Giros" },
      ],
      permission: { componente: "GirosTigo" },
    },
  },
];

export default routes;
