import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import writtenNumber from "written-number";
import { getContrato } from "@/api/contratos/contratos.js";
import store from "@/store/modules/usuario.store.js";

export async function generatePdf(venda_id) {
  const contrato = await getContrato(venda_id);
  const empresa = await fetchEmpresa(store.state.empresa.id);

  return new Promise((resolve) => {
    let total_escrito = writtenNumber(Number(contrato.venda_total), {
      lang: "es",
    }).toUpperCase();
    if (
      Number(contrato.venda_total) > 1000000 &&
      Number(contrato.venda_total) < 2000000
    ) {
      total_escrito = total_escrito.substring(3);
      total_escrito = "UN" + total_escrito;
    }
    let moeda_escrita = "GUARANIES";
    if (contrato.venda_moeda === "U$") {
      moeda_escrita = "DÓLARES";
    }
    let content = [
      {
        margin: [25, 25, 25, 25],
        stack: [
          {
            text: [
              {
                text: `CONTRATO PRIVADO DE COMPRA-VENTA N: ${contrato.numero_venda}`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              `En Ciudad de Ciudad del Este, República del Paraguay, a los `,
              {
                text: `${contrato.dia_emissao} `,
                fontSize: 12,
                bold: true,
              },
              `del mes de `,
              {
                text: `${contrato.mes_emissao} del AÑO ${contrato.ano_emissao}`,
                fontSize: 12,
                bold: true,
              },
              `, por una parte, el Señor`,
              {
                text: `JOSE HORTOLAM JR`,
                fontSize: 12,
                bold: true,
              },
              `, brasilero, soltero, con Cédula `,
              `de Identidad `,
              {
                text: `Nº. 6.337.158`,
                fontSize: 12,
                bold: true,
              },
              ` , quien asiste al acto en nombre y representación de `,
              {
                text: `POTABLE S.A.`,
                fontSize: 12,
                bold: true,
              },
              `, con  `,
              {
                text: `RUC. Nº. 80069729-4`,
                fontSize: 12,
                bold: true,
              },
              ` en su carácter de PRESIDENTE de la misma, domiciliado en Ciudad del Este, que en adelante se denominará EL `,
              {
                text: `VENDEDOR,`,
                fontSize: 12,
                bold: true,
              },
              ` y por la otra parte de `,
              {
                text: `${contrato.cliente_genero} ${contrato.cliente_nome}, `,
                fontSize: 12,
                bold: true,
              },
              `${contrato.cliente_nacionalidade}, ${contrato.cliente_estado_civil}, con `,
              {
                text: `${contrato.cliente_tipo_doc}: ${contrato.cliente_documento}`,
                fontSize: 12,
                bold: true,
              },
              `, quien asiste al acto de la misma, domiciliado en la`,
              {
                text: `${contrato.cliente_endereco}`,
                fontSize: 12,
                bold: true,
              },
              `, que en adelante se denominará EL `,
              {
                text: `COMPRADOR `,
                fontSize: 12,
                bold: true,
              },

              `Son mayores de edad, hábiles para contratar, de común acuerdo convienen en celebrar el presente `,
              {
                text: `CONTRATO PRIVADO DE COMPRA VENTA DE PISCINA, `,
                fontSize: 12,
                bold: true,
              },
              `que se regirá por las cláusulas  y condiciones `,
              `siguientes.`,
              `\n\n`,
              {
                text: `DEL OBJETO DEL PRESENTE CONTRATO`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA PRIMERA: `,
                fontSize: 12,
                bold: true,
              },
              `El Señor`,
              {
                text: `JOSE HORTOLAM JR`,
                fontSize: 12,
                bold: true,
              },
              `, en el carácter invocado `,
              {
                text: `DICE`,
                fontSize: 12,
                bold: true,
              },

              `: Que, a través del `,
              `presente documento, y bajo su absoluta responsabilidad. `,
              {
                text: `VENDE`,
                fontSize: 12,
                bold: true,
              },
              ` a favor de ${contrato.cliente_genero} `,
              {
                text: `${contrato.cliente_nome},`,
                fontSize: 12,
                bold: true,
              },
              ` una `,
              {
                text: `PISCINA MARCA ${contrato.produto_marca}`,
                fontSize: 12,
                bold: true,
              },
              `, con código de identificación n°`,
              {
                text: `${contrato.produto_kit_id}, con los accesorios y características siguientes:`,
                fontSize: 12,
                bold: true,
              },

              `\n\n`,
              `${contrato.venda_items}`,
              `\n\n`,
              `Características y Accesorios que el comprador declara conocer y aceptar expresamente por el presente `,
              `contrato, teniendo en cuenta a las bases y condiciones del manual de construcción y procedimiento de `,
              `instalación de los accesorios correspondientes. `,
              `\n\n`,
              {
                text: `DE LA RESERVA DE PROPIEDAD`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA SEGUNDA: `,
                fontSize: 12,
                bold: true,
              },
              `En virtud de la reserva de la propiedad, establecida en este instrumento, queda reservado al `,
              {
                text: `VENDEDOR: `,
                fontSize: 12,
                bold: true,
              },
              `  el derecho de propiedad de los productos descriptos en la cláusula primera de `,
              `este contrato, hasta la total cancelación o pago total de las cuotas establecidas por las partes. `,
              `\n\n`,
              {
                text: `DE LA CONSERVACIÓN Y USO DEL BIEN`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA TERCERA: `,
                fontSize: 12,
                bold: true,
              },
              `Mientras no estén totalmente pagadas las cuotas, `,
              {
                text: `EL COMPRADOR `,
                fontSize: 12,
                bold: true,
              },
              `se obliga a velar por la conservación de los productos descriptos en la cláusula primera, proporcionando todos los recursos necesarios para la manutención y conservación de los mismos, siendo los gastos por cuenta del `,
              {
                text: `COMPRADOR, `,
                fontSize: 12,
                bold: true,
              },
              `obligándose por el presente contrato a cuidar la apariencia y el correcto funcionamiento de `,
              `los accesorios y el correspondiente motor instalado en la casa de máquinas, cuyas indicaciones de uso le `,
              `son entregados por el presente instrumento. `,
              `\n\n`,
              {
                text: `DEL PRECIO`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA CUARTA: `,
                fontSize: 12,
                bold: true,
              },
              `El precio de la venta fijan y acuerdan las partes en la suma de `,
              {
                text: `${contrato.venda_moeda}  ${
                  contrato.venda_moeda == "G$"
                    ? Vue.filter("guarani")(contrato.venda_total)
                    : Vue.filter("currency")(contrato.venda_total)
                },
                (${moeda_escrita} ${total_escrito}).`,
                fontSize: 12,
                bold: true,
              },
              `\n\n`,
              `\u00A0 \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Inc. 2. El comprador se obliga a abonar al vendedor el saldo de `,
              {
                text: `${
                  contrato.venda_moeda == "G$"
                    ? Vue.filter("guarani")(contrato.venda_total)
                    : Vue.filter("currency")(contrato.venda_total)
                } (${moeda_escrita} ${total_escrito})`,
                margin: [0, 0, 0, 0],
                bold: false,
              },
              `, pagadero de la siguiente forma: `,
              {
                text: `${contrato.parcelamento_texto}`,
                margin: [0, 0, 0, 0],
                bold: true,
              },

              `\n\n`,
              {
                text: `DE LA MORA`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA QUINTA:`,
                fontSize: 12,
                bold: true,
              },
              "El",
              {
                text: `${contrato.fiador_genero} ${contrato.fiador_nome},`,
                fontSize: 12,
                bold: true,
              },
              `${contrato.fiador_nacionalidade}, ${contrato.fiador_estado_civil}, con `,
              {
                text: `${contrato.fiador_tipo_doc}: ${contrato.fiador_documento}, `,
                fontSize: 12,
                bold: true,
              },
              "domiciliado en la",
              {
                text: ` ${contrato.fiador_endereco}, `,
                fontSize: 12,
                bold: true,
              },
              "quien firma también este contrato en prueba de conformidad, se constituye en Co-Deudor Solidario y pagador principal de todas las sumas que adeudare el Comprador, como asi también de todas las demás obligaciones que emanen del presente contrato, renunciando desde ya al beneficio de Exclusion, Esta fianza quedara subsistente hasta quedar extinguidas todas las obligaciones contraídas por el Comprador.",
              `\n\n`,
              {
                text: `CLAUSULA QUINTA:`,
                fontSize: 12,
                bold: true,
              },
              `En caso de mora o atraso en el pago de las cuotas establecidas en este contrato, el`,
              {
                text: `COMPRADOR `,
                fontSize: 12,
                bold: true,
              },

              `se compromete y se obliga a abonar a la fecha de cancelación de su cuota atrasada, un interés moratorio de`,
              {
                text: `2% (dos por ciento) `,
                fontSize: 12,
                bold: true,
              },
              `mensual, sumada a la multa`,
              {
                text: `contractual de 2% (dos por ciento).`,
                fontSize: 12,
                bold: true,
              },

              `\n\n`,
              {
                text: `CLAUSULA SEXTA: `,
                fontSize: 12,
                bold: true,
              },
              `En caso de producirse el atraso de una cuota por parte del `,
              {
                text: `COMPRADOR, `,
                fontSize: 12,
                bold: true,
              },
              `en los plazos estipulados, hará decaer los demás plazos inmediatamente de la interpelación judicial o extrajudicial correspondiente, de la rescisión del presente contrato. `,
              `\n\n`,
              {
                text: `CLAUSULA SEPTIMA: EL VENDEDOR `,
                fontSize: 12,
                bold: true,
              },
              `igualmente podrá rescindir el presente contrato, de pleno derecho, independientemente de cualquier notificación, interpelación judicial o extrajudicial, si el `,
              {
                text: `COMPRADOR `,
                fontSize: 12,
                bold: true,
              },
              `entra en estado de insolvencia o si es decretada su quiebra. `,
              `\n\n`,
              {
                text: `CLAUSULA OCTAVA: `,
                fontSize: 12,
                bold: true,
              },
              `En caso de rescisión del presente contrato,  de este contrato, el `,
              {
                text: `COMPRADOR `,
                fontSize: 12,
                bold: true,
              },
              `queda obligado a restituir los productos descriptos en la cláusula primera`,
              {
                text: `AL VENDEDOR, `,
                fontSize: 12,
                bold: true,
              },
              `en buen estado de conservación, autorizando expresamente AL VENDEDOR a solicitar judicialmente la devolución del bien vendido y su respectivo secuestro, comprometiéndose por este instrumento a solventar los gastos judiciales, intereses, costos y costas, además de los honorarios profesionales generados por la citada diligencia. `,
              `\n\n\n`,
              {
                text: `CONDICIONES GENERALES`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA NOVENA: `,
                fontSize: 12,
                bold: true,
              },
              `No podrá el`,
              {
                text: `COMPRADOR, `,
                fontSize: 12,
                bold: true,
              },
              `ceder el bien objeto de este contrato a terceros, sin la debida autorización por escrito del `,
              {
                text: `VENDEDOR, `,
                fontSize: 12,
                bold: true,
              },
              `ni constituir directa o indirectamente, prenda o caución o cualquier otro gravamen sobre el objeto de este contrato, mientras no esté totalmente saldada la obligación asumida por el comprador. `,
              `\n\n`,
              {
                text: `CLAUSULA DECIMA: EL VENDEDOR `,
                fontSize: 12,
                bold: true,
              },
              `queda facultado a ejercer cualquier acción que derive el presente contrato a los efectos de precautelar sus derechos sobre el bien enajenado por el presente instrumento, debiendo para el efecto prever cualquier acción que pudiera impedir el ejercicio de sus derechos. `,
              `\n\n`,
              {
                text: `CLAUSULA DECIMA PRIMERA: `,
                fontSize: 12,
                bold: true,
              },
              `El presente contrato es celebrado en atención a lo prescripto por el código civil el cual las partes se someten, manifestando estar en conocimiento de las disposiciones del Derecho sucesorio, que hace referencia a las obligaciones asumidas en vida, siendo responsables los herederos y sucesores del deudo, ya sea título universal o singular, frente a los acreedores. `,
              `\n\n`,
              {
                text: `CLAUSULA DECIMA SEGUNDA: `,
                fontSize: 12,
                bold: true,
              },
              `Para todos los efectos derivados del presente contrato, inclusive notificaciones privadas o judiciales, el Comprador fija domicilio especial en `,
              {
                text: `${contrato.cidade}, `,
                fontSize: 12,
                bold: true,
              },
              `donde se tendrán por validas todas las notificaciones como si hubiesen sido practicadas personalmente. `,
              `\n\n`,
              {
                text: `CLAUSULA DECIMA TERCERA: `,
                fontSize: 12,
                bold: true,
              },
              `El comprador declara bajo fe de juramente que el inmueble individualizado como `,
              {
                text: `${contrato.imovel}, `,
                fontSize: 12,
                bold: true,
              },
              `lugar donde se instalara la piscina es de su propiedad. `,
              `\n\n`,
              {
                text: `DE LAS INFORMACIONES GENERALES ACERCA DE LOS PRODUCTOS`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA DECIMA CUARTA: El COMPRADOR `,
                fontSize: 12,
                bold: true,
              },
              ` declara conocer y aceptar las siguientes informaciones respecto a los productos adquiridos, además de aquellas que constan en el Certificado de Garantía y manual de cada producto: `,
              `\n\n`,
              `-La piscina cuenta con 5 años de garantía en el casco de la misma, contra agujeros y/o perdidas o fuga de agua. La garantía no cubre: pies de gallina (rayaduras estéticas) en el gel, daños hechos por terceros, perdidas de agua no relacionadas a la estructura, accesorios (luces, calentadores) u otros equipos (motor, filtro). Cada aparato de la piscina tiene su garantía individual segun estipulado por el fabricante en el manual del producto. `,
              `\n\n`,
              `-La empresa provee 1 año de garantía de los productos relacionados con la piscina al momento de la compra: filtro, motor, luces, cascadas, etc. independiente de la garantía estipulada de la fábrica. `,
              `\n\n`,
              `-En caso de falta de pago, o incumplimiento por parte del cliente, la empresa no estará obligada a proveer servicios de reparación o asistencia en los equipos y/o piscina, hasta que el cliente se ponga al día con el pago de las cuotas (desde que este dentro del período de garantía); las asistencias solicitadas fuera del período de garantía pueden generar costos de mano de obra. `,
              `\n\n`,
              `-La empresa no cubre garantía por daños ocasionados en la piscina por terceros, por ejemplo: La colocación de pisos daña el borde de la piscina; La empresa no cubre garantía por equipos o materiales comprados por el cliente que puede tener desperfecto, por ejemplo, caños de baja calidad que vengan a romper, otros equipos que puedan dañar la piscina como manchas ocasionadas por ionizadores, etc. `,
              `\n\n`,
              `-La empresa ni la fábrica cubren garantía por el mal manejo de los equipos y/o mal manejo de productos químicos en la piscina, por ejemplo: exceso de cloro que puede dejar la piscina de fibra blanca, uso de ionizadores u otros aparatos que puedan manchar la piscina. `,
              `\n\n`,
              `-Una vez instalada la piscina, el cliente se compromete a instalar el contra piso alrededor de la misma en un plazo máximo de 3 días, la inobservancia de esta conlleva la supresión de la garantía de la piscina, y caso la piscina salga de nivel a consecuencia de esta, se cobrará para la reinstalación de la piscina; El cliente se compromete a instalar el contrapiso de la piscina con pendientes de mínimo 3% en cada lado para que el agua que caiga en el piso siempre corra para fuera de la piscina `,
              `\n\n`,
              {
                text: `INDICACIONES`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              `-La piscina no podrá ser vaciada sin el acompañamiento de un técnico especializado. `,
              `\n\n`,
              `-Puede ocurrir la entrada de aire en la tabulación, situación está que no cubre la garantía legal o contractual. `,
              `\n\n`,
              `-Con relación al motor de la casa de máquina, deben ser tomados los siguientes cuidados, bajo pena de perdida de la garantía legal o contractual: \n\n`,
            ],
            fontSize: 11,
            alignment: `justify`,
          },
          {
            stack: [
              {
                margin: [25, 0, 0, 0],
                ul: [
                  `No dejar la tapa de la casa de maquina abierta o semiabierta, lo que podrá inundar, mojar o mismo humectar los equipamientos con el roció o lluvia.\n\n`,
                  `No hacer funcionar el motor con los registros cerrados.\n\n`,
                  `No dejar el motor encendido con la manguera de aspiración llena de aire.\n\n`,
                ],
              },
              {
                margin: [0, 0, 0, 0],
                ul: [
                  `En caso de problemas con el motor, el cliente debe buscar directamente la asistencia técnica especializada.\n\n`,
                ],
              },
            ],
          },
          {
            text: [
              {
                text: `DE LA JURISDICCIÓN`,
                fontSize: 12,
                alignment: `center`,
                bold: true,
              },
              `\n\n`,
              {
                text: `CLAUSULA DECIMA QUINTA: `,
                fontSize: 12,
                bold: true,
              },
              `Para dirimir cualquier controversia oriunda del CONTRATO, las partes se someten a la jurisdicción de los Juzgados y Tribunales competentes de la Jurisdicción del Alto Paraná, con exclusión de cualquier otra jurisdicción que les pudiera corresponder. `,
              `\n\n`,
              {
                text: `CLAUSULA DECIMA SEXTA: `,
                fontSize: 12,
                bold: true,
              },
              `El comprador en forma irrevocable, faculta para que en el caso de un atraso superior a noventa (90) días en el pago del presente crédito o de cualquier otra deuda pendiente que mantenga con nuestra empresa, incluya mi nombre personal o razón social que represento, en el registro general de morosos de INFORMCONF. Esta autorización se extiende a fin de que pueda proveerse la información a terceros interesados. `,
              `\n\n`,
              {
                text: `BAJO TALES CLÁUSULAS Y CONDICIONES `,
                fontSize: 12,
                bold: true,
              },
              `queda formalizado el presente contrato a cuyo fiel cumplimiento se obligan las partes conforme y con arreglo a derecho, firman en prueba de conformidad en dos ejemplares de un mismo tenor y a un solo efecto en el mismo lugar y fecha de su otorgamiento. `,
            ],
            fontSize: 11,
            alignment: `justify`,
          },
          {
            margin: [0, 50, 0, 0],
            columns: [
              {
                text: [
                  `………………………………………………………\n`,
                  `${empresa.nome}\n`,
                  `RUC: ${empresa.documento}\n`,
                  `VENDEDOR`,
                ],
                alignment: `center`,
                fontSize: 11,
                bold: true,
              },
              {
                text: [
                  `………………………………………………………\n`,
                  `${contrato.fiador_nome}\n`,
                  `${contrato.fiador_tipo_doc} ${contrato.fiador_documento}\n`,
                  `CO-DEUDOR`,
                ],
                alignment: `center`,
                fontSize: 11,
                bold: true,
              },
              {
                text: [
                  `………………………………………………\n`,
                  `${contrato.cliente_nome}\n`,
                  `${contrato.cliente_tipo_doc} ${contrato.cliente_documento}\n`,
                  `COMPRADOR`,
                ],
                alignment: `center`,
                fontSize: 11,
                bold: true,
              },
            ],
          },
        ],
      },
    ];

    let docDefinition = {
      pageSize: `A4`,
      pageMargins: [15, 15, 15, 15],
      pageOrientation: `portrait`, //`portrait`
      info: {
        title: `Document`,
      },
      defaultStyle: {
        font: `Roboto`,
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      // footer(currentPage, pageCount) {
      //   return [
      //     {
      //       columns: [
      //         {
      //           text: `Impresso por ${nome} as ${now}`,
      //           fontSize: 7,
      //           margin: [25, 0, 0, 0],
      //         },
      //         {
      //           text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
      //           margin: [0, 0, 25, 0],
      //           alignment: `right`,
      //           fontSize: 7,
      //         },
      //       ],
      //     },
      //   ];
      // },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: `Roboto-Regular.ttf`,
        bold: `Roboto-Medium.ttf`,
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
