<template>
  <v-container fluid>
    <PageToolbar
      :title="cliente.nome"
      icon="mdi-clipboard-account-outline"
      dark
      voltar
    />

    <v-card class="px-0 pt-0 pb-8" :loading="loading" :disabled="loading">
      <v-tabs class="" v-model="tab">
        <v-tab class="caption font-weight-bold">
          {{ $tc("global.informacoes") }}
        </v-tab>
        <v-tab v-if="PClienteEditar" class="caption font-weight-bold">
          Referencia
        </v-tab>

        <v-tab v-if="PClienteEditar" class="caption font-weight-bold">
          Endereços
        </v-tab>

        <v-tab v-if="PClienteEditar" class="caption font-weight-bold">
          Contatos
        </v-tab>
        <v-tab v-if="PClienteEditar" class="caption font-weight-bold">
          {{ $tc("global.pedido", 2) }}
        </v-tab>
        <v-tab v-if="PClienteEditar" class="caption font-weight-bold">
          {{ $tc("global.anotacao") }}
        </v-tab>
        <v-tab v-if="PClienteEditar" class="caption font-weight-bold">
          {{ $tc("global.midia", 2) }}
        </v-tab>
        <v-tab v-if="PClienteContasReceber" class="caption font-weight-bold">
          {{ $tc("global.conta", 2) }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item class="pt-6">
          <v-card-title class="pt-0">
            <v-spacer></v-spacer>

            <v-btn
              @click="updateCliente"
              v-if="PClienteEditar"
              :disabled="!validForm"
              color="button_1"
              class="white--text"
            >
              <v-icon left>mdi-check</v-icon>
              {{ $tc("global.salvar") }}
            </v-btn>
          </v-card-title>
          <v-card-text class="px-4">
            <v-form
              @submit.prevent
              ref="form"
              v-model="validForm"
              lazy-validation
            >
              <v-row dense>
                <v-col cols="12" md="2" class="">
                  <div class="d-flex justify-center align-center">
                    <v-card
                      outlined
                      class="fill-height d-flex align-center justify-center"
                      style="position: relative"
                    >
                      <v-card flat>
                        <v-img v-if="logo" :src="logo" contain></v-img>
                        <v-img
                          v-else
                          :src="require('@/assets/pagina-default.png')"
                          contain
                        ></v-img>
                        <v-btn
                          fab
                          dark
                          x-small
                          absolute
                          bottom
                          right
                          :disabled="!PClienteEditar"
                          @click="onButtonClick"
                        >
                          <input
                            accept="image/*"
                            type="file"
                            class="d-none"
                            ref="uploader"
                            @change="onFileChanged"
                          />
                          <v-icon>mdi-image</v-icon>
                        </v-btn>
                      </v-card>
                    </v-card>
                  </div>
                  <v-btn
                    dark
                    x-small
                    bottom
                    right
                    v-if="cliente.fornecedor_id"
                    @click="diretect"
                    class="ml-3 mt-8"
                  >
                    <span class="dense">Cliente e Fornecedor</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="10" class="">
                  <v-card-text class="px-0">
                    <v-row dense>
                      <!-- Nome -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="cliente.nome"
                          :label="$tc('global.nome')"
                          dense
                          outlined
                          :readonly="!PClienteEditar"
                          :rules="formRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <!-- Documento -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="cliente.doc_ci"
                          :label="$tc('global.documento') + ` CI`"
                          :readonly="!PClienteEditar"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <!-- Documento -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="cliente.doc_ruc"
                          :label="$tc('global.documento') + ` RUC`"
                          :readonly="!PClienteEditar"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <!-- Data Nascimento -->
                      <v-col cols="12" md="4">
                        <DataField
                          :data_sync.sync="cliente.data_nascimento"
                          :disabled="!PClienteEditar"
                          :label="$tc('global.data-nascimento')"
                        />
                      </v-col>

                      <!-- Email -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="cliente.email"
                          :readonly="!PClienteEditar"
                          :label="$tc('global.email')"
                          dense
                          outlined
                          :rules="cliente.email ? emailRules : undefined"
                          validate-on-blur
                          clearable
                        ></v-text-field>
                      </v-col>
                      <!-- Gênero -->
                      <v-col cols="12" md="4">
                        <v-select
                          return-object
                          v-model="selectedGenero"
                          :label="$tc('global.genero')"
                          :readonly="!PClienteEditar"
                          :items="tipos_genero"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                      <!-- celular -->
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="cliente.celular"
                          :label="$tc('global.celular')"
                          :readonly="!PClienteEditar"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <!-- telefone -->
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="cliente.telefone"
                          :label="$tc('global.telefone')"
                          :readonly="!PClienteEditar"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <!-- localizacao -->
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="cliente.localizacao"
                          :label="$tc('global.localizacao') + 'imovel'"
                          :readonly="!PClienteEditar"
                          dense
                          outlined
                        >
                          <template v-slot:append>
                            <v-btn
                              small
                              icon
                              class="ml-3"
                              v-if="cliente.localizacao"
                              :readonly="!PClienteEditar"
                              @click="openDialogMaps"
                            >
                              <v-icon class="mb-1">mdi-map-marker</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-dialog v-model="dialogMaps" max-width="1000">
                          <GoogleMap
                            v-if="cliente.localizacao"
                            :center="center"
                            :markers="markers"
                          />
                        </v-dialog>
                      </v-col>
                      <!-- LOCAL DE TRABALHO -->
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="cliente.local_trabalho"
                          :label="$tc('global.localTrabalho')"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                      <!-- Cidade -->
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          v-model="selectedCidade"
                          :items="cidades"
                          :loading="buscandoCidades"
                          :search-input.sync="searchCidade"
                          :readonly="!PClienteEditar"
                          @keyup="getCidades"
                          dense
                          outlined
                          hide-details
                          item-text="nome"
                          item-value="id"
                          :placeholder="$tc('global.cidade')"
                          append-icon=""
                          full-width
                          return-object
                          autocomplete="off"
                          class="remove-underline input-font-size"
                        ></v-autocomplete>
                      </v-col>
                      <!-- Status -->
                      <v-col cols="12" md="4">
                        <v-text-field dense outlined readonly value="Status:">
                          <template v-slot:append>
                            <v-switch
                              v-model="cliente.status"
                              :false-value="0"
                              :true-value="1"
                              :readonly="!PClienteEditar"
                              class="mt-0 pt-0"
                              hide-details
                            >
                            </v-switch>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- Lista Preço -->
                      <v-col cols="12" md="4">
                        <v-select
                          v-model.number="cliente.lista_preco_id"
                          :items="listas"
                          clearable
                          item-text="descricao"
                          item-value="id"
                          dense
                          outlined
                          required
                          :readonly="!PClienteEditar"
                          :rules="formRules"
                          :label="$tc('global.listadepreco')"
                          :loading="loadingListas"
                        ></v-select>
                      </v-col>

                      <!-- Moeda -->
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="selectedMoeda"
                          :items="moedas"
                          clearable
                          item-text="sigla"
                          item-value="id_moeda"
                          dense
                          outlined
                          :rules="formRules"
                          :readonly="!PClienteEditar"
                          required
                          return-object
                          :label="this.$tc('global.moeda')"
                        ></v-select>
                      </v-col>

                      <!-- agente retentor -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          dense
                          outlined
                          readonly
                          :value="$tc('global.agenteRetentor')"
                        >
                          <template v-slot:append>
                            <v-switch
                              v-model="cliente.agente_retentor"
                              :false-value="false"
                              :true-value="true"
                              :readonly="!PClienteEditar"
                              class="mt-0 pt-0"
                              hide-details
                            >
                            </v-switch>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- Credito Cliente -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          ref="valorCreditoCliente"
                          v-model="valorCreditoCliente"
                          label="Credito"
                          dense
                          required
                          :rules="formRules"
                          :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                          outlined
                          height="40px"
                          :disabled="!selectedMoeda"
                          style="display: flex"
                          class="remove-underline input-font-size input-right"
                          v-currency="vCurrencyOptions"
                        >
                        </v-text-field>
                        <!-- Estado civil -->
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="cliente.estado_civil"
                          :label="$tc('global.estado_civil')"
                          dense
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                      <!-- Nacionalidade -->
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="cliente.nacionalidade"
                          :label="$tc('global.nacionalidade')"
                          dense
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions
            v-if="deletarPermitido && PClienteDeletar"
            class="pa-0 ml-4"
          >
            <v-btn small class="error" @click="deletarCliente">
              {{ $tc("global.del") + " " + $tc("global.cliente") }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item>
          <ClienteReferencia v-if="tab === 1" :cliente_id="cliente.id" />
        </v-tab-item>

        <v-tab-item>
          <DadosEndereco v-if="tab === 2" />
        </v-tab-item>

        <v-tab-item>
          <DadosContato v-if="tab === 3" />
        </v-tab-item>

        <v-tab-item>
          <HistoricoVendas v-if="tab === 4" />
        </v-tab-item>

        <v-tab-item>
          <ClienteObsList v-if="tab === 5" />
        </v-tab-item>

        <v-tab-item>
          <InternFileManager
            v-if="tab === 6"
            origem="clientes"
            :origem_id="cliente.id"
          />
        </v-tab-item>

        <v-tab-item>
          <ContasReceber v-if="tab === 7" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { fetchListasPrecos } from "@/api/listas-precos/listasprecos.js";
import { searchCidades } from "@/api/cidades/cidades.js";
import {
  getCliente,
  putCliente,
  getImages,
  allowDeleteCliente,
  deleteCliente,
} from "@/api/clientes/clientes.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Cliente",

  components: {
    GoogleMap: () => import("@/components/global/GoogleMap.vue"),
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
    ClienteObsList: () => import("./components/ClienteObsList.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
    HistoricoVendas: () => import("./components/HistoricoVendas.vue"),
    ContasReceber: () => import("./components/ContasReceber.vue"),
    DadosContato: () => import("./components/DadosContato.vue"),
    DadosEndereco: () => import("./components/DadosEndereco.vue"),
    ClienteReferencia: () => import("./components/ClienteReferencia.vue"),
  },

  data() {
    return {
      tab: 0,
      cliente: {},
      cliente_original: {},
      cidades: [],
      fornecedores: [],
      validForm: true,
      formRules: [inputRequired],
      emailRules: [validaEmail],
      loading: true,
      apiUrl: null,
      selectedGenero: null,
      tipos_genero: ["MASCULINO", "FEMININO", "NÃO DECLARAR"],
      logo: "",
      logoUpload: "",
      listas: [],
      loadingListas: false,
      buscandoCidades: false,
      buscandoFornecedores: false,
      searchCidade: "",
      selectedCidade: null,
      dialogMaps: false,
      gps: [],
      markers: [],
      center: {
        lat: 0,
        lng: 0,
      },
      deletarPermitido: false,
      valorCreditoCliente: 0,
      selectedMoeda: null,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PClienteContasReceber() {
      return this.getAccess("ClienteContas", "visualizar");
    },

    PClienteEditar() {
      return this.getAccess("Cliente", "editar");
    },

    PClienteDeletar() {
      return this.getAccess("Cliente", "deletar");
    },

    cliente_id() {
      return this.$route.params.id;
    },
    ...mapState({
      imagemSelecionada: (state) => state.imagemSelecionada,
    }),

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    selectedGenero() {
      this.cliente.genero = this.selectedGenero;
    },

    selectedCidade() {
      if (this.selectedCidade) {
        this.cliente.cidade_id = this.selectedCidade.id;
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda) {
        this.cliente.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    valorCreditoCliente() {
      if (this.valorCreditoCliente) {
        this.cliente.credito = this.$ci.parse(
          this.valorCreditoCliente,
          this.vCurrencyOptions
        );
      }
    },
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      this.logoUpload = file;
      this.cliente.logo = !this.cliente.logo ? "" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.logo = event.target.result;
        // vue.editFornecedor();
      };
    },

    diretect() {
      this.$router.push({
        path: `/fornecedores/editar/${this.cliente.fornecedor_id}`,
      });
    },

    getListas() {
      this.loadingListas = true;
      return fetchListasPrecos(this.url)
        .then((response) => {
          this.listas = response;
          this.loadingListas = false;
        })
        .catch(() => {
          this.loadingListas = false;
        });
    },
    async getCliente() {
      await getCliente(this.cliente_id).then((response) => {
        this.cliente = response;
        this.cliente_original = { ...response };
        this.logo = this.cliente.logo;
        this.selectedGenero = this.cliente.genero;
        this.selectedMoeda = this.moedas.find((moeda) => {
          return moeda.id_moeda === this.cliente.moeda_id;
        });
        this.$ci.setValue(this.$refs.valorCreditoCliente, this.cliente.credito);
        if (
          !this.$store.state.dynamic_crumb ||
          this.$store.state.dynamic_crumb != this.cliente.nome
        ) {
          this.$store.commit("UPDATE_DYNAMICCRUMB", this.cliente.nome);
        }
      });

      if (this.cliente.cidade_id) {
        await this.searchCidades(`?search=id=${this.cliente.cidade_id}`);
        this.selectedCidade = this.cidades[0];
      }
    },

    openDialogMaps() {
      this.gps = this.cliente.localizacao.split(",");
      this.center.lat = parseFloat(this.gps[0]);
      this.center.lng = parseFloat(this.gps[1]);
      const marker = {
        lat: parseFloat(this.gps[0]),
        lng: parseFloat(this.gps[1]),
      };
      this.markers.push({ position: marker });
      this.dialogMaps = true;
    },

    searchCidades(filtro) {
      return searchCidades(filtro).then((response) => {
        this.cidades = response;
      });
    },

    async getCidades() {
      if (this.searchCidade) {
        if (this.searchCidade.length > 2 && event.key != "Backspace") {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
    },

    updateCliente() {
      if (this.PClienteEditar) {
        if (
          JSON.stringify(this.cliente) === JSON.stringify(this.cliente_original)
        ) {
          this.$toast.error("Por favor, faça uma mudança antes de salvar!");
          return;
        }
        if (this.$refs.form.validate()) {
          this.loading = true;

          const cliente = new FormData();

          if (this.logoUpload) {
            cliente.append("logo", this.logoUpload, this.logoUpload.name);
            cliente.append("logo64", this.logo);
          }

          let cliente_2 = {};
          cliente_2.empresa_id = this.cliente.empresa_id;
          cliente_2.doc_ci = this.cliente.doc_ci;
          cliente_2.doc_ruc = this.cliente.doc_ruc;
          cliente_2.nome = this.cliente.nome;
          cliente_2.email = this.cliente.email;
          cliente_2.endereco = this.cliente.endereco;
          cliente_2.celular = this.cliente.celular;
          cliente_2.telefone = this.cliente.telefone;
          cliente_2.localizacao = this.cliente.localizacao;
          cliente_2.status = this.cliente.status;
          cliente_2.cidade_id = this.cliente.cidade_id;
          cliente_2.lista_preco_id = this.cliente.lista_preco_id;
          cliente_2.moeda_id = this.cliente.moeda_id;
          cliente_2.data_nascimento = this.cliente.data_nascimento;
          cliente_2.genero = this.cliente.genero;
          cliente_2.agente_retentor = this.cliente.agente_retentor;
          cliente_2.local_trabalho = this.cliente.local_trabalho;
          cliente_2.credito = this.cliente.credito;
          cliente_2.estado_civil = this.cliente.estado_civil;
          cliente_2.nacionalidade = this.cliente.nacionalidade;

          for (let key in cliente_2) {
            if (
              cliente_2[key] !== null &&
              cliente_2[key] !== undefined &&
              cliente_2[key] !== ""
            ) {
              cliente.append(key, cliente_2[key]);
            }
          }

          putCliente(this.cliente.id, cliente)
            .then(() => {
              this.$router.push({ path: "/clientes" });
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    getClienteImages() {
      return getImages(this.cliente_id)
        .then((response) => {
          this.images = response;
        })
        .catch(() => {});
    },
    updateAvatar(image) {
      this.cliente.avatar = image;
      this.updateCliente();
      this.dialogImages = false;
    },

    async verifyDeleteCliente() {
      const response = await allowDeleteCliente(this.cliente_id);
      this.deletarPermitido = response;
    },

    async deletarCliente() {
      const response = await deleteCliente(this.cliente_id);
      if (response.status == 200) {
        this.$router.push({ path: "/clientes" });
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.getClienteImages();
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    this.getCliente();
    await this.verifyDeleteCliente();
    await this.getListas();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btn-toggle-mobile {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  & > .v-btn.v-btn:not(:first-child) {
    border-left-width: thin;
  }
  & > .v-btn.v-btn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > .v-btn.v-btn:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
