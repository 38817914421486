<template>
  <v-card>
    <v-card-title class="pa-3 subtitle-1 tocs_gray_1">
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        solo
        flat
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <div class="d-flex justify-space-between">
        <DataField class="mx-2" :data_sync.sync="dataFrom" label="De" />
        <DataField class="mx-2" :data_sync.sync="dataTo" label="Até" />
        <v-btn icon @click="getAudits" class="mx-2" :disabled="disableSearch">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-6">
      <v-data-table
        v-if="!loading"
        :headers="headers"
        :items="audits"
        :items-per-page="15"
        :options.sync="options"
        :server-items-length="totalAudits"
        :search="search.length >= 3 ? search : ''"
        class="data-tables data-tables__row-click"
        @click:row="goToAudit"
      >
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFormat("dd/MM/yyyy, HH:mm:ss") }}
        </template>
      </v-data-table>
      <Carregando v-else />
    </v-card-text>

    <v-dialog v-model="dialog" scrollable width="700">
      <v-card class="pb-6 pt-3">
        <v-card-title class="font-weight-regular headline pt-0 px-6">
          Audit
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col v-if="audit.user_id" cols="12" sm="6">
              {{ $tc("global.usuariomodificou") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                {{ getUserName(audit.user_id) }}
              </v-sheet>
            </v-col>

            <v-col v-if="audit.ip_address" cols="12" sm="6">
              {{ $tc("global.ipusuario") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                {{ audit.ip_address }}
              </v-sheet>
            </v-col>

            <v-col v-if="audit.user_id" cols="12" sm="6">
              {{ $tc("global.tipoevento") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                {{ audit.event }}
              </v-sheet>
            </v-col>

            <v-col v-if="audit.ip_address" cols="12" sm="6">
              {{ $tc("global.tipo") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                {{ audit.auditable_type }}
              </v-sheet>
            </v-col>

            <v-col v-if="audit.ip_address" cols="12">
              {{ $tc("global.urlusada") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                {{ audit.url }}
              </v-sheet>
            </v-col>

            <v-col v-if="audit.old_values" cols="12">
              {{ $tc("global.valorantigo") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                <vue-json-pretty
                  :data="audit.old_values"
                  path="res"
                  :deep="3"
                  :collapsed-on-click-brackets="true"
                >
                </vue-json-pretty>
              </v-sheet>
            </v-col>

            <v-col v-if="audit.new_values" cols="12">
              {{ $tc("global.valornovo") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                <vue-json-pretty
                  :data="audit.new_values"
                  path="res"
                  :deep="3"
                  :collapsed-on-click-brackets="true"
                >
                </vue-json-pretty>
              </v-sheet>
            </v-col>

            <v-col v-if="audit.ip_address" cols="12" sm="6">
              {{ $tc("global.criado") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                {{ audit.created_at }}
              </v-sheet>
            </v-col>

            <v-col v-if="audit.ip_address" cols="12" sm="6">
              {{ $tc("global.atualizado") }}:
              <v-sheet
                class="pa-2"
                :color="light ? 'grey lighten-4' : 'grey darken-3'"
              >
                {{ audit.updated_at }}
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { getAudits, getAudit } from "@/api/audits/audits.js";
import { mapState } from "vuex";
import { format, startOfDay, endOfDay } from "date-fns";

export default {
  name: "ListaAudits",
  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    VueJsonPretty: () => import("vue-json-pretty"),
  },

  data() {
    return {
      dataFrom: format(startOfDay(new Date()), "yyyy-MM-dd"),
      dataTo: format(endOfDay(new Date()), "yyyy-MM-dd"),
      search: "",
      dialog: false,
      loading: false,
      audits: [],
      audit: {},
      usuarios: [],
      auditId: null,
      options: {
        sortBy: ["updated_at"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 15,
      },
      totalAudits: 0,
      debounce: null,
      pagina: 1,
    };
  },

  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    disableSearch() {
      let result = false;
      if (!this.dataFrom || !this.dataTo) {
        result = true;
      }
      return result;
    },
    headers() {
      return [
        {
          text: this.$tc("global.id"),
          align: "center",
          value: "id",
        },
        {
          text: this.$tc("global.usuario"),
          value: "user_name",
        },
        {
          text: "Model",
          value: "auditable_type",
        },
        {
          text: this.$tc("global.evento"),
          value: "event",
        },
        {
          text: this.$tc("global.ipusuario"),
          align: "center",
          value: "ip_address",
        },
        {
          text: this.$tc("global.atualizacao"),
          align: "right",
          value: "updated_at",
        },
      ];
    },
  },
  watch: {
    pagina() {
      this.getAudits();
    },

    options: {
      handler() {
        this.pagina = this.options.page;
      },
      deep: true,
    },
  },
  methods: {
    getPage() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.getAudits();
      }, 500);
    },
    goToAudit(item) {
      this.dialog = true;
      getAudit(item.id).then((response) => {
        this.audit = response;

        this.audit.old_values = JSON.parse(this.audit.old_values);
        this.audit.new_values = JSON.parse(this.audit.new_values);
      });
    },

    async getAudits() {
      try {
        this.loading = true;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const response = await getAudits(
          `?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&dataFrom=${this.dataFrom}&dataTo=${this.dataTo}`
        );

        this.audits = response.data;
        this.totalAudits = response.total;
        // if (this.options.itemsPerPage != Number(response.per_page)) {
        //   this.options.itemsPerPage = Number(response.per_page);
        // }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAudits();
  },
};
</script>

<style scoped lang="scss"></style>
